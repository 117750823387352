import React, { ReactElement } from "react";
import { View, StyleSheet } from "react-native";
import Tooltip from "react-tooltip-lite";
import {
  Text,
  TextSize,
  FontFamily,
  FontWeight
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

interface IProps {
  value?: string;
}

export const CustomToolTip = (props: IProps): ReactElement => {
  const { value } = props;

  return (
    <View>
      <Tooltip
        styles={{}}
        tipContentClassName=""
        distance={10}
        direction="left"
        background={colorPallete.cordioBeigeLight1}
        color="black"
        padding={10}
        content={
          <View style={{ width: 250 }}>
            <Text
              testId="testId"
              fontWeight={FontWeight.Regular}
              textSize={TextSize.Small}
              style={{
                fontFamily: FontFamily.Medium,
                fontSize: 12
              }}
            >
              {value}
            </Text>
          </View>
        }
      >
        {value}
      </Tooltip>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
