export const PDACountryList = [
  { id: 1, label: "India", value: "India" },
  { id: 2, label: "United States", value: "United States" },
  { id: 3, label: "Ãland Islands", value: "Ãland Islands" },
  { id: 4, label: "Afghanistan", value: "Afghanistan" },
  { id: 5, label: "Albania", value: "Albania" },
  { id: 6, label: "Algeria", value: "Algeria" },
  { id: 7, label: "American Samoa", value: "American Samoa" },
  { id: 8, label: "Andorra", value: "Andorra" },
  { id: 9, label: "Angola", value: "Angola" },
  { id: 10, label: "Anguilla", value: "Anguilla" },
  { id: 11, label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { id: 12, label: "Argentina", value: "Argentina" },
  { id: 13, label: "Armenia", value: "Armenia" },
  { id: 14, label: "Aruba", value: "Aruba" },
  { id: 15, label: "Australia", value: "Australia" },
  { id: 16, label: "Austria", value: "Austria" },
  { id: 17, label: "Azerbaijan", value: "Azerbaijan" },
  { id: 18, label: "Bahamas", value: "Bahamas" },
  { id: 19, label: "Bahrain", value: "Bahrain" },
  { id: 20, label: "Bangladesh", value: "Bangladesh" },
  { id: 21, label: "Barbados", value: "Barbados" },
  { id: 22, label: "Belarus", value: "Belarus" },
  { id: 23, label: "Belgium", value: "Belgium" },
  { id: 24, label: "Belize", value: "Belize" },
  { id: 25, label: "Benin", value: "Benin" },
  { id: 26, label: "Bermuda", value: "Bermuda" },
  { id: 27, label: "Bhutan", value: "Bhutan" },
  { id: 28, label: "Bolivia, Plurinational State of", value: "Bolivia, Plurinational State of" },
  {
    id: 29,
    label: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba"
  },
  { id: 30, label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { id: 31, label: "Botswana", value: "Botswana" },
  { id: 32, label: "Brazil", value: "Brazil" },
  { id: 33, label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { id: 34, label: "Bulgaria", value: "Bulgaria" },
  { id: 35, label: "Burkina Faso", value: "Burkina Faso" },
  { id: 36, label: "Burundi", value: "Burundi" },
  { id: 37, label: "CÃ´te d'Ivoire", value: "CÃ´te d'Ivoire" },
  { id: 38, label: "Cape Verde", value: "Cape Verde" },
  { id: 39, label: "Cambodia", value: "Cambodia" },
  { id: 40, label: "Cameroon", value: "Cameroon" },
  { id: 41, label: "Canada", value: "Canada" },
  { id: 42, label: "Cayman Islands", value: "Cayman Islands" },
  { id: 43, label: "Central African Republic", value: "Central African Republic" },
  { id: 44, label: "Chad", value: "Chad" },
  { id: 45, label: "Chile", value: "Chile" },
  { id: 46, label: "China", value: "China" },
  { id: 47, label: "Christmas Island", value: "Christmas Island" },
  { id: 48, label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { id: 49, label: "Colombia", value: "Colombia" },
  { id: 50, label: "Comoros", value: "Comoros" },
  { id: 51, label: "Congo", value: "Congo" },
  {
    id: 52,
    label: "Congo, the Democratic Republic of the",
    value: "Congo, the Democratic Republic of the"
  },
  { id: 53, label: "Cook Islands", value: "Cook Islands" },
  { id: 54, label: "Costa Rica", value: "Costa Rica" },
  { id: 55, label: "Croatia", value: "Croatia" },
  { id: 56, label: "Cuba", value: "Cuba" },
  { id: 57, label: "Curacao", value: "Curacao" },
  { id: 58, label: "Cyprus", value: "Cyprus" },
  { id: 59, label: "Czech Republic", value: "Czech Republic" },
  { id: 60, label: "Denmark", value: "Denmark" },
  { id: 61, label: "Djibouti", value: "Djibouti" },
  { id: 62, label: "Dominica", value: "Dominica" },
  { id: 63, label: "Dominican Republic", value: "Dominican Republic" },
  { id: 64, label: "Ecuador", value: "Ecuador" },
  { id: 65, label: "Egypt", value: "Egypt" },
  { id: 66, label: "El Salvador", value: "El Salvador" },
  { id: 67, label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { id: 68, label: "Eritrea", value: "Eritrea" },
  { id: 69, label: "Estonia", value: "Estonia" },
  { id: 70, label: "Swaziland", value: "Swaziland" },
  { id: 71, label: "Ethiopia", value: "Ethiopia" },
  { id: 72, label: "Falkland Islands (Malvinas)", value: "Falkland Islands (Malvinas)" },
  { id: 73, label: "Faroe Islands", value: "Faroe Islands" },
  { id: 74, label: "Fiji", value: "Fiji" },
  { id: 75, label: "Finland", value: "Finland" },
  {
    id: 76,
    label: "Former Yugoslav Republic of Macedonia",
    value: "Former Yugoslav Republic of Macedonia"
  },
  { id: 77, label: "France", value: "France" },
  { id: 78, label: "French Guiana", value: "French Guiana" },
  { id: 79, label: "French Polynesia", value: "French Polynesia" },
  { id: 80, label: "Gabon", value: "Gabon" },
  { id: 81, label: "Gambia", value: "Gambia" },
  { id: 82, label: "Guatemala", value: "Guatemala" },
  { id: 83, label: "Georgia", value: "Georgia" },
  { id: 84, label: "Germany", value: "Germany" },
  { id: 85, label: "Ghana", value: "Ghana" },
  { id: 86, label: "Gibraltar", value: "Gibraltar" },
  { id: 87, label: "Greece", value: "Greece" },
  { id: 88, label: "Greenland", value: "Greenland" },
  { id: 89, label: "Grenada", value: "Grenada" },
  { id: 90, label: "Guadeloupe", value: "Guadeloupe" },
  { id: 91, label: "Guam", value: "Guam" },
  { id: 92, label: "Guernsey", value: "Guernsey" },
  { id: 93, label: "Guinea", value: "Guinea" },
  { id: 94, label: "Guinea-Bissau", value: "Guinea-Bissau" },
  { id: 95, label: "Guyana", value: "Guyana" },
  { id: 96, label: "Haiti", value: "Haiti" },
  { id: 97, label: "Holy See (Vatican City State)", value: "Holy See (Vatican City State)" },
  { id: 98, label: "Honduras", value: "Honduras" },
  { id: 99, label: "Hong Kong", value: "Hong Kong" },
  { id: 100, label: "Hungary", value: "Hungary" },
  { id: 101, label: "Iceland", value: "Iceland" },
  { id: 102, label: "Indonesia", value: "Indonesia" },
  { id: 103, label: "Iran, Islamic Republic of", value: "Iran, Islamic Republic of" },
  { id: 104, label: "Iraq", value: "Iraq" },
  { id: 105, label: "Ireland", value: "Ireland" },
  { id: 106, label: "Isle of Man", value: "Isle of Man" },
  { id: 107, label: "Israel", value: "Israel" },
  { id: 108, label: "Italy", value: "Italy" },
  { id: 109, label: "Jamaica", value: "Jamaica" },
  { id: 110, label: "Japan", value: "Japan" },
  { id: 111, label: "Jersey", value: "Jersey" },
  { id: 112, label: "Jordan", value: "Jordan" },
  { id: 113, label: "Kazakhstan", value: "Kazakhstan" },
  { id: 114, label: "Kenya", value: "Kenya" },
  { id: 115, label: "Kiribati", value: "Kiribati" },
  {
    id: 116,
    label: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of"
  },
  { id: 117, label: "Korea, Republic of", value: "Korea, Republic of" },
  { id: 118, label: "Kuwait", value: "Kuwait" },
  { id: 119, label: "Kyrgyzstan", value: "Kyrgyzstan" },
  {
    id: 120,
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic"
  },
  { id: 121, label: "Latvia", value: "Latvia" },
  { id: 122, label: "Lebanon", value: "Lebanon" },
  { id: 123, label: "Lesotho", value: "Lesotho" },
  { id: 124, label: "Liberia", value: "Liberia" },
  { id: 125, label: "Libya", value: "Libya" },
  { id: 126, label: "Liechtenstein", value: "Liechtenstein" },
  { id: 127, label: "Lithuania", value: "Lithuania" },
  { id: 128, label: "Luxembourg", value: "Luxembourg" },
  { id: 129, label: "Macao", value: "Macao" },
  { id: 130, label: "Madagascar", value: "Madagascar" },
  { id: 131, label: "Malawi", value: "Malawi" },
  { id: 132, label: "Malaysia", value: "Malaysia" },
  { id: 133, label: "Maldives", value: "Maldives" },
  { id: 134, label: "Mali", value: "Mali" },
  { id: 135, label: "Malta", value: "Malta" },
  { id: 136, label: "Marshall Islands", value: "Marshall Islands" },
  { id: 137, label: "Martinique", value: "Martinique" },
  { id: 138, label: "Mauritania", value: "Mauritania" },
  { id: 139, label: "Mauritius", value: "Mauritius" },
  { id: 140, label: "Mayotte", value: "Mayotte" },
  { id: 141, label: "Mexico", value: "Mexico" },
  { id: 142, label: "Micronesia, Federated States of", value: "Micronesia, Federated States of" },
  { id: 143, label: "Moldova, Republic of", value: "Moldova, Republic of" },
  { id: 144, label: "Monaco", value: "Monaco" },
  { id: 145, label: "Mongolia", value: "Mongolia" },
  { id: 146, label: "Montenegro", value: "Montenegro" },
  { id: 147, label: "Montserrat", value: "Montserrat" },
  { id: 148, label: "Morocco", value: "Morocco" },
  { id: 149, label: "Mozambique", value: "Mozambique" },
  { id: 150, label: "Myanmar", value: "Myanmar" },
  { id: 151, label: "Namibia", value: "Namibia" },
  { id: 152, label: "Nauru", value: "Nauru" },
  { id: 153, label: "Nepal", value: "Nepal" },
  { id: 154, label: "Netherlands", value: "Netherlands" },
  { id: 155, label: "Netherlands Antilles", value: "Netherlands Antilles" },
  { id: 156, label: "New Caledonia", value: "New Caledonia" },
  { id: 157, label: "New Zealand", value: "New Zealand" },
  { id: 158, label: "Nicaragua", value: "Nicaragua" },
  { id: 159, label: "Niger", value: "Niger" },
  { id: 160, label: "Nigeria", value: "Nigeria" },
  { id: 161, label: "Niue", value: "Niue" },
  { id: 162, label: "Norfolk Island", value: "Norfolk Island" },
  {
    id: 163,
    label: "Macedonia, the Former Yugoslav Republic of",
    value: "Macedonia, the Former Yugoslav Republic of"
  },
  { id: 164, label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { id: 165, label: "Norway", value: "Norway" },
  { id: 166, label: "Oman", value: "Oman" },
  { id: 167, label: "Pakistan", value: "Pakistan" },
  { id: 168, label: "Palau", value: "Palau" },
  { id: 169, label: "Palestine, State of", value: "Palestine, State of" },
  { id: 170, label: "Panama", value: "Panama" },
  { id: 171, label: "Papua New Guinea", value: "Papua New Guinea" },
  { id: 172, label: "Paraguay", value: "Paraguay" },
  { id: 173, label: "Peru", value: "Peru" },
  { id: 174, label: "Philippines", value: "Philippines" },
  { id: 175, label: "Pitcairn", value: "Pitcairn" },
  { id: 176, label: "Poland", value: "Poland" },
  { id: 177, label: "Portugal", value: "Portugal" },
  { id: 178, label: "Puerto Rico", value: "Puerto Rico" },
  { id: 179, label: "Qatar", value: "Qatar" },
  { id: 180, label: "Reunion", value: "Reunion" },
  { id: 181, label: "Romania", value: "Romania" },
  { id: 182, label: "Russian Federation", value: "Russian Federation" },
  { id: 183, label: "Rwanda", value: "Rwanda" },
  { id: 184, label: "Saint Barthelemy", value: "Saint Barthelemy" },
  {
    id: 185,
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "Saint Helena, Ascension and Tristan da Cunha"
  },
  { id: 186, label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { id: 187, label: "Saint Lucia", value: "Saint Lucia" },
  { id: 188, label: "Saint Martin (French part)", value: "Saint Martin (French part)" },
  { id: 189, label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  {
    id: 190,
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines"
  },
  { id: 191, label: "Samoa", value: "Samoa" },
  { id: 192, label: "San Marino", value: "San Marino" },
  { id: 193, label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { id: 194, label: "Saudi Arabia", value: "Saudi Arabia" },
  { id: 195, label: "Senegal", value: "Senegal" },
  { id: 196, label: "Serbia", value: "Serbia" },
  { id: 197, label: "Seychelles", value: "Seychelles" },
  { id: 198, label: "Sierra Leone", value: "Sierra Leone" },
  { id: 199, label: "Singapore", value: "Singapore" },
  { id: 200, label: "Sint Maarten (Dutch part)", value: "Sint Maarten (Dutch part)" },
  { id: 201, label: "Slovakia", value: "Slovakia" },
  { id: 202, label: "Slovenia", value: "Slovenia" },
  { id: 203, label: "Solomon Islands", value: "Solomon Islands" },
  { id: 204, label: "Somalia", value: "Somalia" },
  { id: 205, label: "South Africa", value: "South Africa" },
  { id: 206, label: "South Sudan", value: "South Sudan" },
  { id: 207, label: "Spain", value: "Spain" },
  { id: 208, label: "Sri Lanka", value: "Sri Lanka" },
  { id: 209, label: "Sudan", value: "Sudan" },
  { id: 210, label: "Suriname", value: "Suriname" },
  { id: 211, label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { id: 212, label: "Swaziland", value: "Swaziland" },
  { id: 213, label: "Sweden", value: "Sweden" },
  { id: 214, label: "Switzerland", value: "Switzerland" },
  { id: 215, label: "Syrian Arab Republic", value: "Syrian Arab Republic" },
  { id: 216, label: "Taiwan, Province of China", value: "Taiwan, Province of China" },
  { id: 217, label: "Tajikistan", value: "Tajikistan" },
  { id: 218, label: "Tanzania, United Republic of", value: "Tanzania, United Republic of" },
  { id: 219, label: "Thailand", value: "Thailand" },
  { id: 220, label: "Timor-Leste", value: "Timor-Leste" },
  { id: 221, label: "Togo", value: "Togo" },
  { id: 222, label: "Tokelau", value: "Tokelau" },
  { id: 223, label: "Tonga", value: "Tonga" },
  { id: 224, label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { id: 225, label: "Tunisia", value: "Tunisia" },
  { id: 226, label: "Turkey", value: "Turkey" },
  { id: 227, label: "Turkmenistan", value: "Turkmenistan" },
  { id: 228, label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { id: 229, label: "Tuvalu", value: "Tuvalu" },
  { id: 230, label: "Uganda", value: "Uganda" },
  { id: 231, label: "Ukraine", value: "Ukraine" },
  { id: 232, label: "United Arab Emirates", value: "United Arab Emirates" },
  { id: 233, label: "United Kingdom", value: "United Kingdom" },
  {
    id: 234,
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands"
  },
  { id: 235, label: "Uruguay", value: "Uruguay" },
  { id: 236, label: "Uzbekistan", value: "Uzbekistan" },
  { id: 237, label: "Vanuatu", value: "Vanuatu" },
  {
    id: 238,
    label: "Venezuela, Bolivarian Republic of",
    value: "Venezuela, Bolivarian Republic of"
  },
  { id: 239, label: "Viet Nam", value: "Viet Nam" },
  { id: 240, label: "Virgin Islands, British", value: "Virgin Islands, British" },
  { id: 241, label: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
  { id: 242, label: "Wallis and Futuna", value: "Wallis and Futuna" },
  { id: 243, label: "Western Sahara", value: "Western Sahara" },
  { id: 244, label: "Yemen", value: "Yemen" },
  { id: 245, label: "Zambia", value: "Zambia" },
  { id: 246, label: "Zimbabwe", value: "Zimbabwe" }
];

export const PDAStateList = [
  { id: 1, label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { id: 2, label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { id: 3, label: "Assam", value: "Assam" },
  { id: 4, label: "Bihar", value: "Bihar" },
  { id: 5, label: "Chhattisgarh", value: "Chhattisgarh" },
  { id: 6, label: "Goa", value: "Goa" },
  { id: 7, label: "Gujarat", value: "Gujarat" },
  { id: 8, label: "Haryana", value: "Haryana" },
  { id: 9, label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { id: 10, label: "Jharkhand", value: "Jharkhand" },
  { id: 11, label: "Karnataka", value: "Karnataka" },
  { id: 12, label: "Kerala", value: "Kerala" },
  { id: 13, label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { id: 14, label: "Maharashtra", value: "Maharashtra" },
  { id: 15, label: "Manipur", value: "Manipur" },
  { id: 16, label: "Meghalaya", value: "Meghalaya" },
  { id: 17, label: "Mizoram", value: "Mizoram" },
  { id: 18, label: "Nagaland", value: "Nagaland" },
  {
    id: 19,
    label: "Odisha (formerly known as Orissa)",
    value: "Odisha (formerly known as Orissa)"
  },
  { id: 20, label: "Punjab", value: "Punjab" },
  { id: 21, label: "Rajasthan", value: "Rajasthan" },
  { id: 22, label: "Sikkim", value: "Sikkim" },
  { id: 23, label: "Tamil Nadu", value: "Tamil Nadu" },
  { id: 24, label: "Telangana", value: "Telangana" },
  { id: 25, label: "Tripura", value: "Tripura" },
  { id: 26, label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { id: 27, label: "Uttarakhand", value: "Uttarakhand" },
  { id: 28, label: "West Bengal", value: "West Bengal" }
];

export const PDALegalStatusList = [
  { id: 1, label: "Private Limited Company", value: "Private Limited Company" },
  { id: 2, label: "Limited Liability Partnership", value: "Limited Liability Partnership" },
  { id: 3, label: "Society", value: "Society" },
  { id: 4, label: "Proprietary Firm", value: "Proprietary Firm" },
  { id: 5, label: "CSR Organization", value: "CSR Organization" },
  { id: 6, label: "Funder", value: "Funder" }
];

export const PDAEmployeeCountList = [
  { id: 1, label: "1-15", value: "1-15" },
  { id: 2, label: "16-50", value: "16-50" },
  { id: 3, label: "51-100", value: "51-100" },
  { id: 4, label: "101-500", value: "101-500" },
  { id: 5, label: "501-above", value: "501-above" }
];
