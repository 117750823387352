import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, useWindowDimensions, ScrollView } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import { useTranslation } from "react-i18next";
import TopicParticitant from "@socion-cordio/common/src/components/organisms/topic/topicParticipant";
import TopicTrainer from "@socion-cordio/common/src/components/organisms/topic/topicTrainer";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {}

export default function TopicParticipantPage(props: Props): ReactElement {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history: any = useHistory();
  const [isParticipant, setIsParticipant] = useState(true);
  const [isTrainer, setIsTrainer] = useState(false);
  const [topicDetails, setTopicDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [selectedTopicName, setSelectedTopicName] = useState("");
  let topicName = location?.state?.topicName;

  useEffect(() => {
    LocalStorage.setStorage("isSidePanelCollapsed", true);
  }, []);

  useEffect(() => {
    setDetailsHandler();
    if (selectedTopicId != null) {
      getParticipantListData();
    }
  }, [location?.state, selectedTopicId]);

  const setDetailsHandler = () => {
    let str = location.search.replace("?", "").split("&");
    let id = formatStringHandler(str[0]);
    let name = formatStringHandler(str[1]);
    setSelectedTopicId(id);
    setSelectedTopicName(name);
  };

  const formatStringHandler = (str: string) => {
    if (str?.includes("%20")) {
      return str?.replaceAll("%20", " ").split("=")[1];
    }
    return str?.split("=")[1];
  };

  const getParticipantListData = async () => {
    setLoading(true);
    let topicId = selectedTopicId;
    let body = {
      topicIds: [topicId],
      userType: "TRAINEE"
    };
    const response = await ApiClient.post(topicEndPoints.getParticipantList(), body);
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      let data = response?.response?.topicData[topicId];
      let badgeDataList: any = [];
      let uniqueBadgeDataList;
      data?.forEach((topic: any, index: number) => {
        topic.serialNo = `${index + 1}.`;
        topic.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(topic);
        topic.verifiedRole =
          topic?.roleDetails.length !== 0
            ? auxiliaryMethods.getVerifiedRole(topic?.roleDetails)
            : "None";
        topic.verifiedQualification =
          topic?.qualificationTitle.length !== 0
            ? auxiliaryMethods.getVerifiedQualification(topic?.qualificationTitle)
            : "None";
        topic.assignedBadges =
          topic?.badgeDetails.length !== 0
            ? auxiliaryMethods.getAssignedBadges(topic?.badgeDetails)
            : "None";
        topic?.badgeDetails?.forEach((badge: any, index: number) => {
          topic[badge.badgeType] = auxiliaryMethods.getAssignedBadges(topic?.badgeDetails);
          badgeDataList.push({
            badgeType: badge.badgeType,
            badgeTypeId: badge.badgeTypeId
          });
        });
      });

      // uniqueBadgeDataList = [
      //   ...new Map(badgeDataList.map((item: any) => [item["badgeTypeId"], item])).values()
      // ];
      // data.badgeData = uniqueBadgeDataList;
      // setTopicDetails(data);
      // setLoading(false);

      if (Object.keys(response?.response?.topicData).length !== 0) {
        uniqueBadgeDataList = [
          ...new Map(badgeDataList.map((item: any) => [item["badgeTypeId"], item])).values()
        ];
        data.badgeData = uniqueBadgeDataList;
        setTopicDetails(data);
        setLoading(false);
      } else {
        let newData = [];
        newData.badgeData = [];
        setTopicDetails(newData);
        setLoading(false);
      }

      // setTopicDetails(data);
      // setLoading(false);
    } else {
      toast.error("Something went wrong...!!!");
      setLoading(false);
    }
  };

  const getTrainerListData = async () => {
    setLoading(true);
    let topicId = selectedTopicId;
    let body = {
      topicIds: [topicId],
      userType: "TRAINER"
    };
    const response = await ApiClient.post(topicEndPoints.getTrainersList(), body);
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      let data = response?.response?.topicData[topicId];
      let badgeDataList: any = [];
      let uniqueBadgeDataList;
      data?.forEach((topic: any, index: number) => {
        topic.serialNo = `${index + 1}.`;
        topic.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(topic);
        topic.verifiedRole =
          topic?.roleDetails.length !== 0
            ? auxiliaryMethods.getVerifiedRole(topic?.roleDetails)
            : "None";
        topic.verifiedQualification =
          topic?.qualificationTitle.length !== 0
            ? auxiliaryMethods.getVerifiedQualification(topic?.qualificationTitle)
            : "None";
        topic.assignedBadges =
          topic?.badgeDetails.length !== 0
            ? auxiliaryMethods.getAssignedBadges(topic?.badgeDetails)
            : "None";
        topic?.badgeDetails?.forEach((badge: any, index: number) => {
          topic[badge.badgeType] = auxiliaryMethods.getAssignedBadges(topic?.badgeDetails);
          badgeDataList.push({
            badgeType: badge.badgeType,
            badgeTypeId: badge.badgeTypeId
          });
        });
      });

      // uniqueBadgeDataList = [
      //   ...new Map(badgeDataList.map((item: any) => [item["badgeTypeId"], item])).values()
      // ];
      // data.badgeData = uniqueBadgeDataList;
      // setTopicDetails(data);
      // setLoading(false);

      if (Object.keys(response?.response?.topicData).length !== 0) {
        console.log("here");
        uniqueBadgeDataList = [
          ...new Map(badgeDataList.map((item: any) => [item["badgeTypeId"], item])).values()
        ];
        data.badgeData = uniqueBadgeDataList;
        setTopicDetails(data);
        setLoading(false);
      } else {
        let newData = [];
        newData.badgeData = [];
        setTopicDetails(newData);
        setLoading(false);
      }

      // setTopicDetails(data);
      // setLoading(false);
    } else {
      toast.error("Something went wrong...!!!");
      setLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.iconContainerHeader}>
            <Icon
              testID="down"
              name={IconNames.down}
              customStyle={styles.titleIcon}
              onPress={() => {
                // history.goBack();
                history.push(`${routeNames.app}${routeNames.TOPICS}`);
              }}
            />
          </View>
          <View>
            <Text
              fontWeight={FontWeight.Light}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerTextTitleSupport]}
            >
              {/* {topicName} */}
              {selectedTopicName}
            </Text>
          </View>
          <View>
            <Text
              onPress={() => {}}
              fontWeight={FontWeight.Regular}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
            >
              {">"}
            </Text>
          </View>
          <View>
            <Text
              onPress={() => {}}
              fontWeight={FontWeight.Regular}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerTextSupport]}
            >
              {t("topics:userListByTopic")}
            </Text>
          </View>
        </View>
        <View style={styles.toggleContainer}>
          {/* <ButtonGroup/> */}
          <ButtonTab
            type={isParticipant ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[styles.toggleButton, styles.borderLeftRadius]}
            textStyles={styles.textStyle}
            title={t("topics:participant")}
            onPress={() => {
              setIsParticipant(true);
              setIsTrainer(false);
              getParticipantListData();
            }}
          />
          <ButtonTab
            type={isTrainer ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[styles.toggleButton, styles.borderRightRadius]}
            textStyles={styles.textStyle}
            title={t("topics:Trainer")}
            onPress={async () => {
              setIsTrainer(true);
              setIsParticipant(false);
              await getTrainerListData();
            }}
          />
        </View>
        {loading ? (
          <Loader />
        ) : topicDetails && isParticipant ? (
          <TopicParticitant
            topicDetails={topicDetails}
            topicName={selectedTopicName}
            topicId={selectedTopicId}
          />
        ) : topicDetails && isTrainer ? (
          <TopicTrainer
            topicDetails={topicDetails}
            topicName={selectedTopicName}
            topicId={selectedTopicId}
          />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingBottom: 0,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    minHeight: "calc(100vh - 100px)"
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerAlignment: {
    paddingHorizontal: 25
  },
  headerContainer: {
    flexDirection: "row",
    // marginBottom: 20,
    alignItems: "center",
    padding: 18,
    paddingBottom: 0
  },
  iconContainerHeader: {
    marginRight: 20
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerTextSupport: {
    fontFamily: FontFamily.Regular,
    marginLeft: 10
  },
  borderLeftRadius: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5
  },
  borderRightRadius: {
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5
  }
});
