import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ProgressBar } from "@socion-cordio/common/src/components/atoms/progressBar";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import FilterQualificationPackModal from "@socion-cordio/common/src/components/organisms/qualification-packs/filterQualificationPackModal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

interface Props {
  metrics?: any;
  qualificationPackDetails?: any;
  participantCount?: Number;
  totalParticipantHandler?: Function;
  programDetails?: any;
}

const PARTICIPANT_COUNT = 2000;

export default function QualificationViewDetailsTable(props: Props): ReactElement {
  const {
    metrics,
    qualificationPackDetails,
    participantCount,
    totalParticipantHandler,
    programDetails
  } = props;
  const [qualificationPack, setQualificationPack] = useState(null);
  const [loading, setLoading] = useState(true);
  const [csvLoading, setCsvLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  // const [programDetails, setProgramDetails] = useState(null);
  const [isTotalParticipationEnabled, setIsTotalParticipationEnabled] = useState(false);
  // filter props
  const [openFilter, setOpenFilter] = useState(false);
  const [locationList, setLocationList] = useState(undefined);
  const [badgeRoleList, setBadgeRoleList] = useState(undefined);
  const [expandedList, setExpandedList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isLocationFliterApplied, setIsLocationFilterApplied] = useState(false);
  const [isBadgeFliterApplied, setIsBadgeFilterApplied] = useState(false);
  const [globalList, setGlobalList] = useState([]);
  const [allExpandedNodes, setAllExpandedNodes] = useState([]);
  const [selectedPackDetails, setSelectedPackDetails] = useState(null);
  const [commonData, setCommonData] = useState({
    badgeIds: [],
    locations: []
  });
  // const [commonDataSecondary, setCommonDataSecondary] = useState({
  //   badgeIds: [],
  //   locations: [],
  //   isNoneLocationSet: null,
  //   isNoBadgeAssignSet: null,
  //   locationNameList: [],
  //   selectedBadgeNameList: []
  // });

  const history: any = useHistory();

  useEffect(() => {
    convertMetrics();
  }, [metrics]);

  useEffect(() => {
    if (openFilter) disableScroll();
    else enableScroll();
  }, [openFilter]);

  const getFilterLocations = async () => {
    try {
      // console.log("programDetails-->> getFilterLocations", programDetails);
      const programId = programDetails.program.id;
      const payload = {
        programIds: [programId],
        locationType: "BASE_LOCATION"
      };
      const response = await ApiClient.post(
        qualificationPackEndPoints.getFilterLocations(programId),
        {}
      );
      // console.log("response------>>> loc", response);

      const responseData = response?.response?.programData[programId];
      responseData?.forEach((data: any, index: number) => {
        data.serialNo = `${index + 1}`;
        data.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(data);
        data.isSelected = false;
        data.bundleCompletedDate = dataHelper.formatDate(data.bundleCompletedDate);
      });
      setLocationList(responseData);
      // setLoading(false);
    } catch (error) {
      setLocationList([]);
      // setLoading(false);
      toast.error("Something went wrong.");
    }
  };

  const getBadgeRoles = async () => {
    try {
      const programId = programDetails.program.id;
      const response = await ApiClient.get(qualificationPackEndPoints.getBadgeRoles(), {
        id: programId
      });
      // console.log("response------>>> badgeeee", response);
      const responseData = response?.response[programId];
      responseData?.forEach((data: any, index: number) => {
        data.serialNo = `${index + 1}.`;
        // data.isSelected = false;
      });
      setBadgeRoleList(responseData);
    } catch (error) {
      toast.error("Something went wrong.");
      setBadgeRoleList([]);
    }
  };

  const metricsSum = () => {
    // to get the total sum in the metrics
    return Object.values(metrics)?.reduce((a: any, b: any) => a + b);
  };

  const convertingArrayToObjectArray = () => {
    // retuns array of array with the metrics data
    const metricsToArray = Object.entries(metrics);
    const arrayToObjectArray: any = [];
    metricsToArray.forEach((element) => {
      let tempObj = {};
      tempObj = Object.assign({}, element);
      arrayToObjectArray.push(tempObj);
    });
    return arrayToObjectArray;
  };

  const assigningValues = (arrayToObjectArray: any, sumValues: number) => {
    // looping to add the statement, no. of participant and
    // total percentage value to the remaining list.
    arrayToObjectArray?.forEach((obj: any, index: number) => {
      obj.statement = `${index} out of ${arrayToObjectArray.length} Selections Completed`;
      obj.noOfParticipants = `${arrayToObjectArray[index]["1"]}`;
      obj.percentage = `${sumValues}`;
    });
    return arrayToObjectArray;
  };

  const assignValueToRemovedMetric = (lastMetric: any, sumValues: number) => {
    // Assigning values to removed metric
    // and the no. of participant as key given by the response.
    lastMetric = {
      statement: "All Selections Completed",
      noOfParticipants: lastMetric[0]?.[1],
      percentage: `${sumValues}`
    };
    return lastMetric;
  };

  const convertMetrics = () => {
    try {
      // to get the total sum in the metrics
      let sumValues: any = 0;
      if (metrics.length !== 0) {
        sumValues = metricsSum();
      }
      const arrayToObjectArray: any = convertingArrayToObjectArray();

      // removing last element as to display on 'All selection completed"
      let lastMetric = arrayToObjectArray.splice(-1);
      const assignValuesList = assigningValues(arrayToObjectArray, sumValues);
      lastMetric = assignValueToRemovedMetric(lastMetric, sumValues);

      // looping list to get add the serial no.
      let list = assignValuesList.concat(lastMetric);

      const updatedList = listSelectionNoHandler(list);

      // To display the Total participants count
      let newList = [
        {
          noOfParticipants: participantCount,
          statement: "Total Participants",
          isTotalParticipants: true
        }
      ];

      setQualificationPack(updatedList.concat(newList));
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setFailed(true);
    }
  };

  const listSelectionNoHandler = (list: any[]) => {
    list.reverse();
    list?.forEach((obj: any, index: number) => {
      obj.serialNo = `${index + 1}.`;
      obj.selectionNo = `${index}`;
    });
    const newList = [...list];
    newList.reverse();
    newList?.forEach((obj: any, index: number) => {
      obj.selectionNoUpdated = `${index}`;
    });
    const updateNewList = [...newList];
    return updateNewList.reverse();
  };

  const navigivateViewDetailsHandler = (qualificationPack: any) => {
    history.push(
      `${routeNames.app}${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWPARTICIPANTMAPPING}`,
      {
        packDetails: qualificationPack,
        qualificationPackDetails: qualificationPackDetails
      }
    );
    googleWebAnalytics("QP_Completion_View_Participant_Count", "Button", "Qualification_Pack");
  };

  const downloadFilterHandler = async (qualiPackDetails: any) => {
    setOpenFilter(true);
    if (qualiPackDetails.isTotalParticipants) {
      setIsTotalParticipationEnabled(true);
      const payload = {
        ...qualiPackDetails,
        selectionNoUpdated: 0
      };
      setSelectedPackDetails(payload);
    } else {
      setIsTotalParticipationEnabled(false);
      setSelectedPackDetails(qualiPackDetails);
    }

    getFilterLocations();
    getBadgeRoles();
  };

  const getSelectedLocationList = (list: any, globalList: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    let result = auxiliaryMethods.locationDataConvert(newList);
    setCommonData({
      ...commonData,
      locations: result
    });
  };

  const getSelectedRoleBadgeList = (list: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    let result = newList.map((a: any) => a.badgeId);
    setCommonData({
      ...commonData,
      badgeIds: result
    });
  };

  const filterListToTrue = (list: any) => {
    return list.filter((x: any) => x.isSelected === true);
  };

  const toTitleCase = (phrase: string) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const participantDetailsCSV = async (payload: any) => {
    try {
      const bundleId = qualificationPackDetails?.bundle?.id;
      const selectionNo = selectedPackDetails?.selectionNoUpdated;

      let locationsList = payload.locations?.map((item: any) => {
        return {
          ...item,
          city: toTitleCase(item?.city),
          district: toTitleCase(item?.district),
          state: toTitleCase(item?.state),
          subDistrict: toTitleCase(item?.subDistrict),
          country: toTitleCase(item?.country)
        };
      });

      const requestPayload = {
        allUsers: isTotalParticipationEnabled ? true : false,
        disableLocationFilter: payload.isNoneLocationSet ? true : false,
        disableBadgeFilter: payload.isNoBadgeAssignSet ? true : false,
        badgeId: payload.badgeIds,
        locations: locationsList, //payload.locations, //auxiliaryMethods.joinFormatLocationDataConvert(payload.locations),
        locationNames: payload.isNoneLocationSet ? ["None"] : payload.locationNameList,
        badgeNames: payload.isNoBadgeAssignSet
          ? ["No Badge Assigned"]
          : auxiliaryMethods.convertBadgeIdsToNameHandler(payload)
      };
      const participantListCSVResponse = await ApiClient.post(
        qualificationPackEndPoints.getParticipantMappingCSV(bundleId, selectionNo),
        requestPayload
      );
      return participantListCSVResponse;
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong.");
    }
  };

  const downloadCsvList = async (payload: any) => {
    const { name } = programDetails.program;
    const data = await participantDetailsCSV(payload);
    let fileName = `${name}-Qualification-Pack-info`;
    auxiliaryMethods.handleDownload(data, fileName);
    clearAllFilter();
    setOpenFilter(false);
    setLoading(false);
  };

  const filteredDataHandler = (data: any) => {
    downloadCsvList(data);
  };

  const dataFilteredHandler = async (data: any) => {
    if (data.isFiltered === true) {
      setLoading(true);
      const locList = [...locationList];
      let newList = filterListToTrue(locList);
      const badgeList = [...badgeRoleList];
      filteredDataHandler({
        ...commonData,
        locations: newList,
        isNoneLocationSet: data.isNoneLocationSet,
        isNoBadgeAssignSet: data.isNoBadgeAssignSet,
        badgeList: badgeRoleList,
        locationNameList: globalList
      });
    }
  };

  const clearAllFilter = () => {
    // isModalClosed ? setLoading(true) : null;

    const locList = locationList.forEach((element: any) => {
      element.isSelected = false;
    });
    const badgeList = badgeRoleList.forEach((element: any) => {
      element.isSelected = false;
    });

    setLocationList(locList);
    setBadgeRoleList(badgeList);

    // filteredDataHandler({
    //   locations: [],
    //   badgeIds: [],
    //   isNoneLocationSet: null,
    //   isNoBadgeAssignSet: null
    // });
    setExpandedList([]);
    setCheckedList([]);

    LocalStorage.removeStorage("noneLocationListSet");
    LocalStorage.removeStorage("noAssignedBadgesSelected");
    LocalStorage.removeStorage("globalNameList");

    setCommonData({
      badgeIds: [],
      locations: []
    });

    getFilterLocations();
    getBadgeRoles();
  };

  const closeModalHandler = () => {};

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Light}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, qualiPack?.serialNo);
        }
      },
      {
        Header: " ",
        accessor: "name",
        width: 140,
        maxWidth: 140,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {
            // fontSize: qualiPack.isTotalParticipants ? 14 : 12
            // fontWeight: FontFamily.Bold
          };
          // return cellData("name", style, qualiPack?.statement);
          return (
            <Text
              fontWeight={FontWeight.Light}
              testId={"name"}
              textSize={TextSize.Small}
              style={[
                {
                  fontSize: 12,
                  fontFamily: FontFamily.Regular
                },
                qualiPack.isTotalParticipants && {
                  fontWeight: "600"
                }
              ]}
            >
              {qualiPack?.statement}
            </Text>
          );
        }
      },
      {
        Header: "Participants",
        accessor: "noOfParticipants",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = () => {
            qualiPack.isTotalParticipants
              ? totalParticipantHandler()
              : navigivateViewDetailsHandler(qualiPack);
          };
          // return cellData("noOfParticipants", style, qualiPack?.noOfParticipants, onPress);

          return (
            <View style={styles.participantContainer}>
              <View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId={"noOfParticipantsId"}
                  textSize={TextSize.Small}
                  style={[
                    {
                      fontSize: 12,
                      fontFamily: FontFamily.Medium,
                      textDecorationLine: "underline"
                    },
                    qualiPack.isTotalParticipants && {
                      fontFamily: FontFamily.Regular,
                      fontWeight: "600"
                    },
                    qualiPack?.noOfParticipants > PARTICIPANT_COUNT && {
                      textDecorationLine: "none"
                    }
                  ]}
                  onPress={() => {
                    qualiPack?.noOfParticipants > PARTICIPANT_COUNT
                      ? null
                      : qualiPack.isTotalParticipants
                      ? totalParticipantHandler()
                      : navigivateViewDetailsHandler(qualiPack);

                    LocalStorage.setStorage("isSidePanelCollapsed", true);
                  }}
                >
                  {qualiPack?.noOfParticipants}
                </Text>
              </View>
              {qualiPack?.noOfParticipants > PARTICIPANT_COUNT && (
                <TouchableOpacity
                  onPress={() => {
                    downloadFilterHandler(qualiPack);
                  }}
                  style={styles.iconContainer}
                >
                  <Icon name={IconNames.downloadList} customStyle={styles.iconStyle} />
                </TouchableOpacity>
              )}
            </View>
          );
        }
      },
      {
        Header: "Completion percentage",
        accessor: "percentage",
        width: 145,
        maxWidth: 145,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => (
          <View>
            {qualiPack.percentage === null && <View></View>}
            {qualiPack.percentage && (
              <View style={styles.progressBarContainer}>
                <ProgressBar
                  progressBarStyles={styles.progressBarStyles}
                  percentage={(+qualiPack.noOfParticipants / +qualiPack.percentage) * 100}
                />
                <Text
                  onPress={() => {}}
                  fontWeight={FontWeight.Regular}
                  testId="text"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerTextSupport]}
                >
                  {((+qualiPack.noOfParticipants / +qualiPack.percentage) * 100).toFixed(2) + " %"}
                </Text>
              </View>
            )}
          </View>
        )
      }
    ],
    []
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View style={styles.noFailedDataContainer}>Failed to load data</View>
      ) : (
        <View>
          <Table
            columns={columns}
            data={qualificationPack}
            showSearchField={false}
            eventAnalytics="QualPack_Details"
          />
        </View>
      )}

      {openFilter && (
        <View>
          <SocionModal
            modalVisible={openFilter}
            setModalVisible={() => setOpenFilter(!openFilter)}
            component={
              <>
                {/* {csvLoading ? (
                  <Loader />
                ) : ( */}
                <FilterQualificationPackModal
                  modalVisible={openFilter}
                  locationList={locationList}
                  badgeRoleList={badgeRoleList}
                  programDetails={programDetails}
                  dataFilteredHandler={dataFilteredHandler}
                  getSelectedLocationList={getSelectedLocationList}
                  getSelectedRoleBadgeList={getSelectedRoleBadgeList}
                  setModalVisible={() => {
                    setOpenFilter(!openFilter);
                    clearAllFilter();
                  }}
                  setExpandedList={setExpandedList}
                  expandedList={expandedList}
                  setCheckedList={setCheckedList}
                  checkedList={checkedList}
                  clearAllFilter={clearAllFilter}
                  setIsLocationFilterApplied={setIsLocationFilterApplied}
                  setIsBadgeFilterApplied={setIsBadgeFilterApplied}
                  // closeModalHandler={closeModalHandler}
                  setGlobalList={setGlobalList}
                  globalList={globalList}
                  setAllExpandedNodes={setAllExpandedNodes}
                  allExpandedNodes={allExpandedNodes}
                  closeModalHandler={closeModalHandler}
                  customHeaderTitle={"Download"}
                  customSubHeaderTitle={"Select filter for your download"}
                  applyButtonText={"Download"}
                  toCheckErrorMessageOnDownload={false}
                />
                {/* )} */}
              </>
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  addRoleButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  noFailedDataContainer: {
    padding: 25
  },
  progressBarStyles: {
    width: "73%"
  },
  progressBarContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium
  },
  headerTextSupport: {
    marginLeft: 10
  },
  participantContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconContainer: {
    alignSelf: "center"
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    fontSize: 14,
    marginRight: 5
  }
});
