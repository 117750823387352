import React, { useState, useEffect } from "react";
import { useAsyncDebounce } from "react-table";
import { View, TextInput, StyleSheet } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { FontFamily, TextSize } from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

export default function SearchFilter({
  globalFilter,
  setGlobalFilter,
  customStyle,
  customSearchIconStyle,
  customSearchBarContainerStyle,
  searchReset,
  placeholder
}: {
  globalFilter: any;
  setGlobalFilter: any;
  customStyle: any;
  customSearchIconStyle?: any;
  customSearchBarContainerStyle?: any;
  searchReset?: boolean;
  placeholder?: string;
}) {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (searchReset && globalFilter == undefined) {
      const data = document.getElementById("searchBar") as HTMLInputElement;
      data.value = "";
    }
  }, [globalFilter, searchReset]);

  return (
    <View style={[styles.searchBarContainer, customSearchBarContainerStyle]}>
      <Icon name={IconNames.search} customStyle={[styles.iconStyle, customSearchIconStyle]} />
      <TextField
        label=""
        name="searchBar"
        id="searchBar"
        value={value}
        handleChange={(value: any) => {
          // setValue(value);
          onChange(value);
        }}
        placeholder={placeholder ? placeholder : "Search"}
        inputStyle={[styles.searchBar, customStyle]}
        noFormik
        handleBlur={() => {
          googleWebAnalytics("Search_Table_Filter", "Button", "Table");
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight4,
    borderRadius: 5
  },
  searchBar: {
    border: "none",
    height: 40,
    width: 400,
    fontFamily: FontFamily.Medium,
    fontWeight: FontFamily.Bold,
    textSize: TextSize.Small,
    fontSize: 14
  },
  iconStyle: {
    marginLeft: 15,
    marginRight: 5,
    opacity: 0.5,
    fontSize: 16
  }
});
