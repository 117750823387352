import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Header from "@socion-cordio/common/src/components/organisms/accomplishment/components/header";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/accomplishment/components/programDropdown";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { toast } from "react-toastify";
import {
  springshedEndPoints,
  accomplishments
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { taskDetails } from "@socion-cordio/common/src/components/organisms/accomplishment/constants";
import axios from "axios";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import _ from "lodash";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { STATUS } from "@socion-cordio/common/src/network/constants";

export default function Tasks(): ReactElement {
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [showTasks, setShowTasks] = useState([]);
  const [dropDownSelectedProgram, setDropDownSelectedProgram] = useState({});
  const history: any = useHistory();
  const location: any = useLocation();
  const [taskList, setTaskList] = useState([]);
  const [usersId, setUsersId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [mainProgramList, setMainProgramList] = useState([]);
  const [isBackEnabled, setIsBackEnabled] = useState(location?.state?.isBackEnabled ?? false);
  const [filteredSelectedProgram, setFilteredSelectedProgram] = useState(null);

  // const { isBackEnabled = false } = location.state;
  const { t } = useTranslation();

  useEffect(() => {
    getAllUserPrograms();
  }, []);

  useEffect(() => {
    if (dropDownSelectedProgram?.length != 0) {
      LocalStorage.setStorage("selectedProgram", dropDownSelectedProgram);
      setDropDownSelectedProgram(dropDownSelectedProgram);
    }
  }, [dropDownSelectedProgram]);

  useEffect(() => {
    if (selectedProgram && selectedProgram.value) {
      getTaskData();
    }
  }, [dropDownSelectedProgram]);

  const getAllUserPrograms = async () => {
    try {
      setIsLoading(true);
      const urlParams = window.location.pathname.split("/").reverse();
      const programId = urlParams[2];
      const userId: any = urlParams[1];
      const accessToken: any = urlParams[0];
      setUsersId(userId);
      setAccessToken(accessToken);

      await LocalStorage.setStorage("accessToken", accessToken);

      try {
        const response = await ApiClient.post(accomplishments.getAllProgramsByUsers(), {});
        if (response?.response) {
          setMainProgramList(response?.response);
          let optionsData = response?.response.map((i: any) => {
            return { label: i.programName, value: i.programId };
          });
          setOptions(optionsData);
          let filteredId = optionsData?.filter((k: any) => k.value == programId);
          let filteredProgram = response?.response?.filter((k: any) => k.programId == programId);
          console.log(filteredId[0], filteredProgram[0]);
          setFilteredSelectedProgram(filteredProgram[0]);
          setSelectedProgram(filteredId[0]);
          setDropDownSelectedProgram(filteredId[0]);
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.");
      setIsLoading(false);
    }
  };

  const getSelectedProgram = async () => {
    let program = await LocalStorage.getStorage("selectedProgram");
    setDropDownSelectedProgram(program);
  };

  const getTaskData = async () => {
    setDropDownSelectedProgram(selectedProgram);
    setIsLoading(true);
    let token = await LocalStorage.getStorage("accessToken");
    const payload = {
      programId: [selectedProgram.value],
      userId: usersId
    };
    const headers = {
      "access-token": token,
      accept: "application/json"
    };
    const getTaskResponse: any = await axios.post(
      "https://task-api-stage.socion.io/taskservice/api/v1/task/search",
      payload,
      { headers: headers }
    );

    // const sortedArray = _.orderBy(
    //   getTaskResponse?.data?.result,
    //   (o: any) => {
    //     return Moment(o?.submissionTime).format("DD MMM YYYY HH:mm");
    //   },
    //   ["desc"]
    // );

    const sortedArray = getTaskResponse?.data?.result.sort(function (a: any, b: any) {
      var c: any = new Date(a.submissionTime);
      var d: any = new Date(b.submissionTime);
      return d - c;
    });

    let taskIdArray = sortedArray.map((i: any) => {
      return i.taskId;
    });
    taskIdArray = [...new Set(taskIdArray)];
    let result: any = [];
    taskIdArray.map((i: number) => {
      let temp = sortedArray?.filter((k: any) => k.taskId == i);
      if (temp.length > 0) {
        result.push(temp);
      }
    });

    getTaskResponse.data.formattedTaskMapping = result;
    setTaskList(getTaskResponse.data?.formattedTaskMapping);
    if (getTaskResponse.data.formattedTaskMapping?.length > 0) {
      setShowTasks(Array(getTaskResponse.data.formattedTaskMapping?.length).fill(true));
    }
    setIsLoading(false);
  };

  const handleBack = () => {
    history.push(
      `${routeNames.app}${routeNames.ACCOMPLISHMENT}/${selectedProgram.value}/${usersId}/${accessToken}`
    );
  };

  const handleOpen = (index: number) => {
    let temp = [...showTasks];
    temp[index] = !temp[index];
    setShowTasks(temp);
  };

  const handleTaskDetails = (data: any) => {
    // let options = location?.state?.programOptions;
    history.push(`${routeNames.app}${routeNames.ACCOMPLISHMENTTASKSDETAILS}`, {
      taskDetails: data,
      programOptions: options,
      programData: selectedProgram,
      userId: usersId,
      accessToken: accessToken,
      mainProgramList: mainProgramList
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <View style={styles.body}>
          <ProgramDropdown
            selectedProgram={dropDownSelectedProgram}
            setSelectedProgram={setSelectedProgram}
            setDropDownSelectedProgram={setDropDownSelectedProgram}
            options={options}
          />
          <View style={{ zIndex: -1 }}>
            <View style={styles.headingContainer}>
              {isBackEnabled && (
                <View style={styles.mt2}>
                  <TouchableOpacity onPress={handleBack}>
                    <Icon name={IconNames.leftArrow} customStyle={styles.leftarrow} />
                  </TouchableOpacity>
                </View>
              )}
              <View style={isBackEnabled ? styles.ml20 : { marginLeft: 0 }}>
                <Text
                  testId="qualificationPack"
                  fontWeight={FontWeight.ExtraBold}
                  textSize={TextSize.Large}
                  style={[styles.headerText]}
                >
                  {t("accomplishment:tasks")}
                </Text>
              </View>
            </View>
            <View style={styles.mt8}>
              {taskList.length > 0 ? (
                taskList.map((i, key: number) => (
                  <View>
                    <View style={[styles.mt12, styles.flexContainer]}>
                      <Text
                        testId="qualificationPack"
                        fontWeight={FontWeight.ExtraBold}
                        textSize={TextSize.Large}
                        style={[styles.cardHeader]}
                      >
                        {i[0].taskName}
                      </Text>
                    </View>
                    <View style={[styles.mt8, styles.flexContainer]}>
                      <Text
                        testId="qualificationPack"
                        fontWeight={FontWeight.ExtraBold}
                        textSize={TextSize.Large}
                        style={[styles.cardSubHeader]}
                      >
                        ({i?.length} {t("accomplishment:tasks")})
                      </Text>
                      <View
                        style={
                          showTasks[key]
                            ? styles.alignLeftContainer
                            : [styles.alignLeftContainer, styles.mr12]
                        }
                      >
                        <TouchableOpacity onPress={() => handleOpen(key)}>
                          <Icon
                            name={IconNames.down}
                            size={10}
                            customStyle={showTasks[key] ? styles.cardIconInverted : styles.cardIcon}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                    {showTasks[key] ? (
                      <View style={{ marginTop: 14 }}>
                        <View
                          style={{
                            borderRadius: 10,
                            backgroundColor: colorPallete.white,
                            width: "100%",
                            paddingHorizontal: 14,
                            paddingBottom: 30
                          }}
                        >
                          {i?.map((data: any, index: number) => (
                            <TouchableOpacity onPress={() => handleTaskDetails(i[index])}>
                              <View style={styles.pt14}>
                                <Text
                                  testId="qualificationPack"
                                  fontWeight={FontWeight.Light}
                                  textSize={TextSize.Regular}
                                  style={[styles.topicName]}
                                >
                                  {auxiliaryMethods.fromatBaseTaskLocation(data)}
                                </Text>
                              </View>
                              <View
                                style={{
                                  marginTop: 5,
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: data?.status ? "space-between" : "flex-start"
                                }}
                              >
                                <Text
                                  testId="qualificationPack"
                                  fontWeight={FontWeight.Light}
                                  textSize={TextSize.Regular}
                                  style={[styles.topicCompletionDate]}
                                >
                                  {dataHelper.formatDateTime(data.submissionTime)}
                                </Text>
                                <Text
                                  testId="qualificationPack"
                                  fontWeight={FontWeight.Light}
                                  textSize={TextSize.Regular}
                                  style={[
                                    styles.topicCompletionDate,
                                    {
                                      color:
                                        data?.status === STATUS.SUBMITTED
                                          ? colorPallete.textBlack
                                          : data.status === STATUS.APPROVED
                                          ? colorPallete.cordioGreen
                                          : data.status === STATUS.REJECTED
                                          ? colorPallete.cordioRedDark5
                                          : colorPallete.textBlack
                                    }
                                  ]}
                                >
                                  {data?.status === STATUS.SUBMITTED
                                    ? data?.isVerificationRequired
                                      ? STATUS.PENDING_VERIFICATION
                                      : STATUS.SUBMITTED
                                    : data?.status}
                                </Text>
                              </View>
                              {index + 1 !== i.length ? <View style={styles.divider}></View> : null}
                            </TouchableOpacity>
                          ))}
                        </View>
                      </View>
                    ) : null}
                    <View style={styles.divider}></View>
                  </View>
                ))
              ) : (
                <View style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {t("accomplishment:noData")}
                </View>
              )}
            </View>
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: colorPallete.cordioBeige,
    paddingHorizontal: 16,
    minHeight: "100vh"
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20
  },
  ml20: {
    marginLeft: 20
  },
  mt2: {
    marginTop: 2
  },
  headerText: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 24,
    color: colorPallete.cordioTaupe
  },
  leftarrow: {
    color: colorPallete.cordioTaupe,
    width: 6,
    height: 12
  },
  tableHeaders: {
    marginTop: 28,
    display: "flex",
    flexDirection: "row"
  },
  tableText: {
    fontFamily: FontFamily.Regular,
    fontSize: 14,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  alignLeftContainer: {
    marginLeft: "auto"
  },
  cardHeader: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  cardSubHeader: {
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.cordioTaupe
  },
  cardIcon: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6
  },
  cardIconInverted: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6,
    transform: [{ rotate: "180deg" }]
  },
  mt8: {
    marginTop: 8
  },
  mt12: {
    marginTop: 12
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  mr12: {
    marginRight: 12
  },
  divider: {
    border: "1px solid #675850",
    opacity: 0.1,
    marginTop: 14
  },
  topicName: {
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.textBlack
  },
  topicCompletionDate: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 14,
    color: colorPallete.cordioRed
  },
  mt14: {
    marginTop: 14
  },
  whiteCard: {
    borderRadius: 10,
    backgroundColor: colorPallete.white
  },
  orContainer: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 16,
    color: colorPallete.textBlack
  },
  pt14: {
    paddingTop: 14
  }
});
function t(arg0: string): any {
  throw new Error("Function not implemented.");
}
