import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { useHistory } from "react-router-dom";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { BadgesActions } from "@socion-cordio/common/src/modules/badges/actions/actions";
import AddBadgeTypes from "@socion-cordio/common/src/components/organisms/badges/badge-types/addBadgeTypes";
import ViewBadgeTypes from "@socion-cordio/common/src/components/organisms/badges/badge-types/viewBadgeType";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import {
  BADGE_SETTING_TYPES,
  BADGE_TYPE_STATUS,
  USER_ROLES
} from "@socion-cordio/common/src/network/constants";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
interface Props {
  programDetails?: any;
  viewSelectedBadgeTypeHandler?: Function;
}

export default function BadgeTypesTable(props: Props): ReactElement {
  const { programDetails, viewSelectedBadgeTypeHandler } = props;
  const [failed, setFailed] = useState(false);
  const [addBadgeTypeModal, setAddBadgeTypeModal] = useState(false);
  const [viewBadgeTypeModal, setViewBadgeTypeModal] = useState(false);
  const [selectedBadgeType, setSelectedBadgeType] = useState(null);
  const [isUserProgramAdmin, setIsUserProgramAdmin] = useState(false);
  const [updatedColumns, setUpdatedColumns] = useState([]);

  const history: any = useHistory();
  const dispatch = useDispatch();

  const {
    badgeTypesData: badgeTypesData,
    loading: { getBadgeTypes: loading }
  } = useSelector((state: any) => state.badges);

  useEffect(() => {
    getBadgeTypeTable();
    checkUserProgramAdmin();
  }, []);

  useEffect(() => {
    if (addBadgeTypeModal) disableScroll();
    else enableScroll();
  }, [addBadgeTypeModal]);

  useEffect(() => {
    if (viewBadgeTypeModal) disableScroll();
    else enableScroll();
  }, [viewBadgeTypeModal]);

  useEffect(() => {
    checkColumnsHandler();
  }, [badgeTypesData]);

  const checkColumnsHandler = () => {
    let columnsList = [...columns];
    setUpdatedColumns(columnsList);
    if (badgeTypesData !== null && badgeTypesData?.response?.length !== 0) {
      const list = badgeTypesData?.response?.filter(
        (item: any) => item.status === BADGE_TYPE_STATUS.DRAFT
      );
      if (badgeTypesData?.response?.length === list?.length) {
        columnsList.splice(4, 1);
        setUpdatedColumns(columnsList);
      }
      updateBadgeTypeData();
    }
  };

  const updateBadgeTypeData = () => {
    const { response } = badgeTypesData;
    response.forEach((badge: any) => {
      const date =
        badge.status === BADGE_TYPE_STATUS.DRAFT
          ? "-"
          : badge.status === BADGE_TYPE_STATUS.PUBLISHED
          ? badge.publishedDate
            ? dataHelper.formatDate(badge.publishedDate, "DD MMM YYYY")
            : "NA"
          : badge.status === BADGE_TYPE_STATUS.DEACTIVATED
          ? badge.unPublishedDate
            ? dataHelper.formatDate(badge.unPublishedDate, "DD MMM YYYY")
            : "NA"
          : "NA";
      const name =
        badge.status === BADGE_TYPE_STATUS.DRAFT
          ? "-"
          : badge.status === BADGE_TYPE_STATUS.PUBLISHED
          ? badge.publishedByName ?? "NA"
          : badge.status === BADGE_TYPE_STATUS.DEACTIVATED
          ? badge.unPublishedByName ?? "NA"
          : "NA";
      // const updatedBy = date === "NA" ? "-" : `${date} (${name})`;
      const updatedBy = date === "NA" ? "NA" : `${date} (${name})`;
      const dateNameString = updatedBy.includes("-") ? "-" : updatedBy;
      badge.updatedBy = dateNameString;
    });
  };

  const getBadgeTypeTable = async () => {
    const { program } = props?.programDetails;
    dispatch(BadgesActions.getBadgeTypes(program.id));
  };

  const viewBadgeTypeHandler = (data: any) => {
    setSelectedBadgeType(data);
    setViewBadgeTypeModal(!viewBadgeTypeModal);
  };

  const checkUserProgramAdmin = async () => {
    const userRoles: any = await LocalStorage.getStorage("userRoles");
    const roles = userRoles?.userRolesData?.response;
    roles.length !== 0 && roles.includes(USER_ROLES.PROGRAM_ADMIN)
      ? setIsUserProgramAdmin(true)
      : setIsUserProgramAdmin(false);
  };

  const onPublishClickedHandler = (value: Object) => {
    viewBadgeTypeHandler(value);
    setViewBadgeTypeModal(true);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <View>
      <TouchableOpacity
        disabled={onPress ? false : true}
        onPress={onPress ? () => onPress() : null}
      >
        <Text
          fontWeight={FontWeight.Bold}
          testId={testId}
          textSize={TextSize.Small}
          style={[
            {
              fontSize: 12,
              fontFamily: FontFamily.Regular,
              fontWeight: FontFamily.Light,
              textSize: TextSize.Small,
              color: colorPallete.textBlack
            },
            style
          ]}
        >
          {data}
        </Text>
      </TouchableOpacity>
    </View>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, badge?.serialNo);
        }
      },
      {
        Header: "Badge Types",
        accessor: "badgeClassName",
        width: 130,
        maxWidth: 130,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          const onPress = () => {
            viewBadgeTypeHandler(badge);
          };
          return cellData("badgeClassName", style, badge?.badgeClassName, onPress);
        }
      },
      {
        Header: "Assigned/Declared",
        accessor: "declaredType",
        width: 75,
        maxWidth: 75,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          let declaredType =
            badge.declaredType === BADGE_SETTING_TYPES.SELF_DECLARED ||
            badge.declaredType === "Self-Declared"
              ? "Self-Declared"
              : badge.declaredType === BADGE_SETTING_TYPES.ASSIGN
              ? BADGE_SETTING_TYPES.ASSIGNED
              : auxiliaryMethods.capitalizeFirstLetter(badge?.declaredType);
          return cellData("declaredType", style, declaredType);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 55,
        maxWidth: 55,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => (
          <View>
            {badge.status === BADGE_TYPE_STATUS.DRAFT ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.draftStatusColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {BADGE_TYPE_STATUS.DRAFT}
                </Text>
              </View>
            ) : badge.status === BADGE_TYPE_STATUS.PUBLISHED ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.activeColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {BADGE_TYPE_STATUS.PUBLISHED}
                </Text>
              </View>
            ) : badge.status === BADGE_TYPE_STATUS.DEACTIVATED ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.inactiveColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {BADGE_TYPE_STATUS.UNPUBLISHED}
                </Text>
              </View>
            ) : null}
          </View>
        )
      },
      {
        Header: `Updated On`,
        accessor: "updatedBy",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("createdAt", style, badge.updatedBy);
        }
      },
      {
        Header: "No. of Badges",
        accessor: "badgeCount",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = () => {
            // viewNoOfBadgesHandler(badge);
            viewSelectedBadgeTypeHandler(badge);
          };
          return cellData("badgeCount", style, badge.badgeCount, onPress);
        }
      }
    ],
    []
  );

  const searchbarAndAddBadge = () => (
    <View>
      <View style={styles.searchBarContainer}>
        <View pointerEvents={"none"}>
          <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
        </View>
        <View>
          {isUserProgramAdmin && (
            <TouchableOpacity
              style={styles.addButtonIcon}
              onPress={() => setAddBadgeTypeModal(!addBadgeTypeModal)}
            >
              <Image
                testId="secondaryAddImg"
                source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                imageStyle={{
                  width: 30,
                  height: 30
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={styles.headerText}
      >
        {"There are no Badge Types created for this program."}
      </Text>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          {badgeTypesData?.response?.length === 0 ? (
            <View>{searchbarAndAddBadge()} </View>
          ) : (
            <Table
              columns={updatedColumns}
              data={badgeTypesData !== null ? badgeTypesData?.response : []}
              searchBarCustomStyle={styles.customWidthStyle}
              addIcon={
                isUserProgramAdmin && (
                  <TouchableOpacity onPress={() => setAddBadgeTypeModal(!addBadgeTypeModal)}>
                    <Image
                      testId="primaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                      imageStyle={{
                        width: 30,
                        height: 30
                      }}
                    />
                  </TouchableOpacity>
                )
              }
            />
          )}

          {viewBadgeTypeModal && (
            <View>
              <SocionModal
                modalVisible={viewBadgeTypeModal}
                setModalVisible={() => setViewBadgeTypeModal(false)}
                customPadding={20}
                component={
                  <>
                    <ViewBadgeTypes
                      onClose={() => setViewBadgeTypeModal(false)}
                      programDetails={programDetails}
                      selectedBadgeType={selectedBadgeType}
                      onPublishClickedHandler={onPublishClickedHandler}
                      isUserProgramAdmin={isUserProgramAdmin}
                    />
                  </>
                }
              />
            </View>
          )}
        </View>
      )}

      {addBadgeTypeModal && (
        <View>
          <SocionModal
            modalVisible={addBadgeTypeModal}
            setModalVisible={() => setAddBadgeTypeModal(!addBadgeTypeModal)}
            customPadding={20}
            component={
              <>
                <AddBadgeTypes
                  onClose={() => setAddBadgeTypeModal(!addBadgeTypeModal)}
                  programDetails={programDetails}
                />
              </>
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  customLoadingContainer: {
    width: 630,
    height: 180
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  addRoleButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: -4
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  draftStatusColor: {
    backgroundColor: colorPallete.cordioLightGreyFive
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.yellowOne
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 12
  },
  // Search bar disabled
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 25,
    marginBottom: 10
  },
  customWidthStyle: {
    width: 323
  },
  addButtonIcon: {},
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700",
    paddingHorizontal: 25,
    marginBottom: 30
  }
});
