import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { BADGE_STATUS, HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UnassignBadgeModal from "@socion-cordio/common/src/components/organisms/badges/unassignBadgeModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import IndeterminateCheckbox from "@socion-cordio/common/src/utils/tableHelperRowSelection";
import Tooltip from "react-tooltip-lite";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import jsonToCsv from "@socion-cordio/common/src/utils/jsonToCsvDownload";
import { useTranslation } from "react-i18next";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { BADGE_SETTING_TYPES } from "@socion-cordio/common/src/network/constants";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";

interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

export default function UnassignBadgePage(props: Props): ReactElement {
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [badgeUsersList, setBadgeUsersList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [selectedRows, setSelectedRows] = useState(undefined);
  const [assignBadgeModal, setAssignBadgeModal] = useState(false);
  const [assignBadgeData, setAssignBadgeData] = useState(undefined);
  const [badgeDetailsState, setBadgeDetailsState] = useState(null);
  const [loadingBadgeModal, setLoadingBadgeModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [displaySelectAllCheckbox, setDisplaySelectAllCheckbox] = useState(false);
  const [isBadgeSelfDeclared, setIsBadgeSelfDeclared] = useState(false);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [badgeName, setBadgeName] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState([]);
  const { t } = useTranslation();

  const history: any = useHistory();
  const location: any = useLocation();

  useEffect(() => {
    setSelectedBadge(location?.state?.selectedBadge);
    setBadgeDetailsState(location?.state);
    getBadgeUsersList();
  }, []);

  useEffect(() => {
    checkBadgeDetails();
  }, [selectedBadge]);

  const checkBadgeDetails = () => {
    const columnsList = [...columns];
    setUpdatedColumns(columnsList);
    if (
      selectedBadge !== null &&
      selectedBadge.declaredType === BADGE_SETTING_TYPES.SELF_DECLARED
    ) {
      setIsBadgeSelfDeclared(true);
      columnsList.splice(0, 1); // removes the first element, updates the list
      columnsList.splice(3, 2); // removes 2 elements from position 3 from updated list
      setUpdatedColumns(columnsList);
    }
  };

  useEffect(() => {
    if (assignBadgeModal) disableScroll();
    else enableScroll();
  }, [assignBadgeModal]);

  useEffect(() => {
    if (viewButtonDetails) disableScroll();
    else enableScroll();
  }, [viewButtonDetails]);

  const getBadgeUsersList = async () => {
    try {
      const badgeId = location?.state?.selectedBadge?.badgeId;
      const queryParams = {
        badgeId: badgeId
      };
      const response = await ApiClient.get(badgesEndPoints.getAssigedBadgeUser(), queryParams);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        const data = response?.response;
        const userDetails = data?.[badgeId]?.userDetails;
        userDetails?.forEach((data: any, index: number) => {
          data.serialNo = `${index + 1}.`;
          data.listIndex = Number(`${index + 1}`);
          data.userDetails.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(
            data.userDetails
          );
          data.badgeStartDate = data.startDate ? dataHelper.formatDate(data.startDate) : "NA";
          data.badgeEndDate = data.endDate ? dataHelper.formatDate(data.endDate) : "NA";
          data.status =
            location?.state?.selectedBadge?.declaredType === BADGE_SETTING_TYPES.SELF_DECLARED &&
            data.status === BADGE_STATUS.ASSIGNED
              ? BADGE_SETTING_TYPES.SELF_DECLARED
              : data.status;
        });
        const list = flattenObjectHandler(userDetails);
        setBadgeUsersList(list);
        setBadgeName(data?.[badgeId]?.badgeName);

        // show select all checkbox only when atleast one badge is assigned state
        const newList = list?.filter(
          (item: any) => item.status === "ASSIGNED" || item.status === "Assigned"
        );
        newList.length === 0
          ? setDisplaySelectAllCheckbox(false)
          : setDisplaySelectAllCheckbox(true);
      } else {
        toast.error("Something went wrong.");
      }
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setLoading(false);
      setFailed(true);
    }
  };

  const flattenObjectHandler = (detailsList: any) => {
    let newRows: any = [];
    const list = detailsList.forEach((element: any) => {
      let rowObject: any = {};
      rowObject = auxiliaryMethods.flattenObjectHandler(element);
      newRows.push(rowObject);
    });
    return newRows;
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const downloadCsvList = async (data: any) => {
    try {
      setLoading(true);
      const response = await ApiClient.get(
        badgesEndPoints.downloadBadgeCsvList(location?.state?.selectedBadge?.badgeId)
      );
      if (response) {
        auxiliaryMethods.handleDownload(response, badgeName);
        const eventType: string = "Download Participant List";
        updateTelemetryEvent(eventType);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const updateTelemetryEvent = async (eventType: string) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const { selectedBadge } = location?.state;
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,
      badgeId: selectedBadge.badgeId,
      badgeName: selectedBadge.name,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programDetails, user, false);
    googleWebAnalytics("Download_Assign_Badge_List", "Telemetry_Event", "Badges");
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const renderBadgeStatusText = (status: any) => (
    <Text
      fontWeight={FontWeight.Light}
      testId="testId"
      textSize={TextSize.Small}
      textStyle={styles.textAlign}
      onPress={() => {}}
    >
      {status}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        id: "selection",
        Header: ({ getToggleAllPageRowsSelectedProps, getToggleAllRowsSelectedProps }: any) => (
          <div style={{ marginLeft: 10 }}>
            {selectedBadge?.declaredType !== BADGE_SETTING_TYPES.SELF_DECLARED && (
              // &&
              // displaySelectAllCheckbox
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            )}
          </div>
        ),
        Cell: ({ row }: any) => (
          <div style={{ marginLeft: 10 }}>
            {selectedBadge?.declaredType !== BADGE_SETTING_TYPES.SELF_DECLARED && (
              <>
                {row?.values?.status === "ASSIGNED" && !row?.original?.deletedByUser && (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                )}
              </>
            )}
          </div>
        )
      },
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, badge?.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 120,
        maxWidth: 120,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(badge)}>
                {badge?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Location",
        accessor: "formattedBaseLocation",
        width: 140,
        maxWidth: 140,
        filterable: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(badge) !== "None" ? (
                <CustomTooltip
                  hoverValue={badge.formattedBaseLocation}
                  textValue={auxiliaryMethods.getUserFirstLocationName(badge)}
                />
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Start",
        accessor: "startDate",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("startDate", style, badge.badgeStartDate);
        }
      },
      {
        Header: "End",
        accessor: "endDate",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("endDate", style, badge.badgeEndDate);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => (
          <View>
            {badge.status === "ASSIGNED" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.activeColor]}></View>
                {renderBadgeStatusText(badge?.status)}
              </View>
            ) : badge.status === "UNASSIGNED" ? (
              <View>
                <View style={styles.statusContainer}>
                  <View style={[styles.statusColor, styles.inactiveColor]}></View>
                  {renderBadgeStatusText(badge?.status)}
                </View>
                <View>
                  <Tooltip
                    styles={{ zIndex: 99 }}
                    tipContentClassName=""
                    distance={10}
                    direction="left"
                    background={colorPallete.cordioBeigeLight1}
                    color="black"
                    padding={10}
                    content={
                      <View style={{ width: 250 }}>
                        <Text
                          testId="testId"
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.Small}
                          style={{
                            fontFamily: FontFamily.Medium,
                            fontSize: 12
                          }}
                        >
                          {badge?.reason}
                        </Text>
                      </View>
                    }
                  >
                    <Icon testID="down" name={IconNames.email} customStyle={styles.messageIcon} />
                  </Tooltip>
                </View>
              </View>
            ) : badge.status === "EXPIRED" || badge.status === "Expired" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.inactiveColorTwo]}></View>
                {renderBadgeStatusText(badge?.status)}
              </View>
            ) : badge.status === BADGE_SETTING_TYPES.SELF_DECLARED ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.activeColor]}></View>
                {renderBadgeStatusText(BADGE_SETTING_TYPES.SELF_DECLARED_SECONDARY.toUpperCase())}
              </View>
            ) : null}
          </View>
        )
      }
    ],
    [selectedBadge, displaySelectAllCheckbox]
  );

  const handleSelectedRows = (selectedRows: any) => {
    if (selectedRows) {
      setSelectedRows(selectedRows);
    }
  };

  const handleBadgeAssign = async () => {
    const keys = Object.keys(selectedRowIndex);
    let list: any = [];
    keys?.forEach((element: any) => {
      list.push(badgeUsersList[+element]);
    });
    setSelectedRows(list);
    setAssignBadgeModal(!assignBadgeModal);
    googleWebAnalytics("UnAssign_Badge_Open_Unassign_Modal", "Button", "UnAssign_Badge");
  };

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <>
          <View style={styles.container}>
            <View style={styles.rightContainer}>
              <View style={styles.subContainer}>
                <View style={styles.subContainerOne}>
                  <View style={styles.headerContainer}>
                    <View style={styles.iconContainerHeader}>
                      <Icon
                        testID="down"
                        name={IconNames.down}
                        customStyle={styles.titleIcon}
                        onPress={() => {
                          history.goBack();
                        }}
                      />
                    </View>
                    <View style={styles.headingContainer}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                      >
                        {selectedBadge?.name}
                      </Text>
                    </View>
                    <View>
                      <Text
                        onPress={() => {}}
                        fontWeight={FontWeight.Regular}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
                      >
                        {">"}
                      </Text>
                    </View>
                    <View>
                      <Text
                        onPress={() => {}}
                        fontWeight={FontWeight.Regular}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextSupport]}
                      >
                        {isBadgeSelfDeclared ? "Self-Declared Badge" : "Assigned Badge"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableContainer}>
                    <Table
                      columns={updatedColumns}
                      data={badgeUsersList}
                      eventAnalytics={"BadgeUsers"}
                      handleSelectedRows={handleSelectedRows}
                      noDataMessage="No users under this Badge"
                      searchReset={true}
                      setSelectedRowIndex={setSelectedRowIndex}
                      download={
                        <TouchableOpacity onPress={() => downloadCsvList(badgeUsersList)}>
                          <View style={{ flexDirection: "row" }}>
                            <Icon name={IconNames.downloadList} customStyle={styles.iconStyle} />
                            <View>
                              <Text
                                testId="testId"
                                fontWeight={FontWeight.Bold}
                                textSize={TextSize.Small}
                                style={[
                                  styles.textAlignment,
                                  {
                                    fontFamily: FontFamily.Regular,
                                    fontSize: 12
                                  }
                                ]}
                              >
                                {"Download"}
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      }
                    />
                  </View>
                </View>
              </View>
              {!isBadgeSelfDeclared && (
                <View style={styles.bottomContainer}>
                  <View style={styles.buttonContainer}>
                    <View>
                      <Button
                        type={ButtonType.Secondary}
                        buttonStyles={styles.button}
                        textStyles={styles.buttonFont}
                        title="Cancel"
                        onPress={() => {
                          history.push("/new/app/badges");
                          googleWebAnalytics(
                            "UnAssign_Badge_Cancel_Button",
                            "Button",
                            "UnAssign_Badge"
                          );
                        }}
                      />
                    </View>
                    <View>
                      <Button
                        type={ButtonType.Primary}
                        buttonStyles={styles.button}
                        textStyles={styles.buttonFont}
                        title="Unassign"
                        onPress={handleBadgeAssign}
                        disabled={selectedRows?.length == 0}
                      />
                    </View>
                  </View>
                </View>
              )}
            </View>
            {assignBadgeModal && (
              <View>
                <SocionModal
                  modalVisible={assignBadgeModal}
                  setModalVisible={() => setAssignBadgeModal(!assignBadgeModal)}
                  customContainer={{ padding: 0 }}
                  customPadding={5}
                  component={
                    <>
                      {/* {loadingBadgeModal ? (
                        <Loader customLoadingContainer={styles.badgesModalLoader} />
                      ) : ( */}
                      <UnassignBadgeModal
                        onClose={() => setAssignBadgeModal(false)}
                        assignBadgeData={assignBadgeData}
                        badgeDetailsState={badgeDetailsState}
                        getBadgeUsersList={getBadgeUsersList}
                        selectedRows={selectedRows}
                      />
                      {/* )} */}
                    </>
                  }
                />
              </View>
            )}

            {viewButtonDetails && (
              <View>
                <SocionModal
                  modalVisible={viewButtonDetails}
                  setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                  component={
                    <ViewUserDetails
                      onClose={() => {
                        setViewButtonDetails(false);
                      }}
                      selectedUserDetails={selectedUserDetails}
                      piiFilter={true}
                    />
                  }
                />
              </View>
            )}
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row"
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    width: "100%"
    // width: "calc(100% - 240px)"
  },
  leftContainer: {
    marginTop: -60,
    flex: 1,
    backgroundColor: colorPallete.cordioRedDark2,
    minHeight: "100vh"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  messageIcon: {
    fontSize: 18,
    color: colorPallete.textLight
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    // padding: 20,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    marginBottom: 30
  },
  subContainerOne: {},

  //Header Container
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerContainer: {
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0,
    alignItems: "center"
  },
  headerTextSupport: {
    fontWeight: "400",
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 20
  },

  tableContainer: {},
  bottomContainer: {
    flexDirection: "row",
    width: "100%"
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 10,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  fontAlign: {
    color: colorPallete.textBlack
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: 40,
    flexWrap: "wrap"
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    margin: 4,
    marginLeft: 10
  },
  badgesModalLoader: {
    width: 800,
    height: 300
  },
  buttonFont: {
    fontSize: 12
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.lightGreyTwo
  },
  inactiveColorTwo: {
    backgroundColor: colorPallete.cordioRed
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 12
  },
  iconStyle: {
    fontSize: 17,
    color: colorPallete.cordioTaupe,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  headingContainer: {
    maxWidth: 461
  }
});
