import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import ViewSessionParticipantPage from "@socion-cordio/common/src/components/organisms/session/viewSessionParticipantPage";
import ViewSessionTrainerPage from "@socion-cordio/common/src/components/organisms/session/viewSessionTrainerPage";
import { useLocation, useHistory } from "react-router-dom";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import CompletedSessionParticipationPage from "@socion-cordio/common/src/components/organisms/session//completedSessionParticipationPage";
import ViewSessionOthersPage from "@socion-cordio/common/src/components/organisms/session/viewSessionOthersPage";
import { useTranslation } from "react-i18next";

interface Props {
  sessionDetails?: any;
  getSessionParticipationList?: Function;
  setLoadingSessionParticipation?: Function;
  isLoadingSessionParticipation?: boolean;
  getTableColumnsCount?: Function;
}

const TRAINER = "Trainer";
const OTHERS = "Others";

export default function SessionParticipantPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const {
    sessionDetails,
    getSessionParticipationList,
    setLoadingSessionParticipation,
    isLoadingSessionParticipation,
    getTableColumnsCount
  } = props;
  const [isParticipantList, setIsParticipantList] = useState<boolean>(true);
  const [isTrainerList, setIsTrainerList] = useState<boolean>(false);
  const [isOthersList, setIsOthersList] = useState<boolean>(false);
  const [isLiveSession, setIsLiveSession] = useState<boolean>(false);
  const [isUpcomingSession, setIsUpcomingSession] = useState<boolean>(false);
  const [isCompletedSession, setIsCompletedSession] = useState<boolean>(false);

  const location: any = useLocation();

  useEffect(() => {
    checkRouteUrl();
  }, [location.pathname]);

  const checkRouteUrl = () => {
    if (location.pathname.includes("live")) {
      setIsLiveSession(true);
      setIsUpcomingSession(false);
      setIsCompletedSession(false);
    }
    if (location.pathname.includes("upcoming")) {
      setIsLiveSession(false);
      setIsUpcomingSession(true);
      setIsCompletedSession(false);
    }
    if (location.pathname.includes("completed")) {
      setIsLiveSession(false);
      setIsUpcomingSession(false);
      setIsCompletedSession(true);
    }
  };

  useEffect(() => {
    redirectionTrainerHandler();
  }, [location]);

  const redirectionTrainerHandler = () => {
    location?.state?.sessionParticipantType === TRAINER
      ? [setIsParticipantList(false), setIsTrainerList(true), setIsOthersList(false)]
      : location?.state?.sessionParticipantType === OTHERS
      ? [setIsParticipantList(false), setIsTrainerList(false), setIsOthersList(true)]
      : [setIsParticipantList(true), setIsTrainerList(false), setIsOthersList(false)];
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        {/* <View style={styles.subtContainerOne}>
          <View style={styles.textStylesContainer}>
            <Text
              onPress={() => {}}
              fontWeight={FontWeight.Regular}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerSupport]}
            >
              {t("session:sessionParticipation")}
            </Text>
          </View>
        </View> */}

        {isLiveSession && (
          <View>
            {/* <View style={styles.toggleContainer}>
              <ButtonTab
                type={
                  isParticipantList ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
                }
                buttonStyles={[
                  styles.toggleButton,
                  { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
                ]}
                textStyles={styles.textStyle}
                title={t("session:participant")}
                onPress={() => {
                  setIsParticipantList(true);
                  setIsTrainerList(false);
                  setIsOthersList(false);
                }}
              />
              <ButtonTab
                type={isTrainerList ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
                buttonStyles={[
                  styles.toggleButton
                ]}
                textStyles={styles.textStyle}
                title={t("session:trainer")}
                onPress={() => {
                  setIsParticipantList(false);
                  setIsTrainerList(true);
                  setIsOthersList(false);
                }}
              />
              <ButtonTab
                type={isOthersList ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
                buttonStyles={[
                  styles.toggleButton,
                  { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
                ]}
                textStyles={styles.textStyle}
                title={"Other Members"}
                onPress={() => {
                  setIsOthersList(true);
                  setIsParticipantList(false);
                  setIsTrainerList(false);
                }}
              />
            </View> */}
            {isParticipantList ? (
              <View style={styles.sessionParticipantContainer}>
                <ViewSessionParticipantPage
                  sessionDetails={sessionDetails}
                  getSessionParticipationList={getSessionParticipationList}
                  setLoadingSessionParticipation={setLoadingSessionParticipation}
                  isLoadingSessionParticipation={isLoadingSessionParticipation}
                  getTableColumnsCount={getTableColumnsCount}
                />
              </View>
            ) : isTrainerList ? (
              <View style={styles.sessionParticipantContainer}>
                <ViewSessionTrainerPage
                  sessionDetails={sessionDetails}
                  getTableColumnsCount={getTableColumnsCount}
                />
              </View>
            ) : isOthersList ? (
              <View style={styles.sessionParticipantContainer}>
                <ViewSessionOthersPage
                  sessionDetails={sessionDetails}
                  getTableColumnsCount={getTableColumnsCount}
                />
              </View>
            ) : null}
          </View>
        )}

        {isUpcomingSession && (
          <View>
            {" "}
            <View style={{ minHeight: 110, justifyContent: "center", alignItems: "center" }}>
              <Text
                onPress={() => {}}
                fontWeight={FontWeight.Regular}
                testId="text"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.headerSupport, styles.headerAlign]}
              >
                This session has not yet started
              </Text>
            </View>
          </View>
        )}

        {isCompletedSession && (
          <View>
            {" "}
            <CompletedSessionParticipationPage sessionDetails={sessionDetails} />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "100%"
    // width: "calc(100vw - 515px)"
  },
  subContainer: {},
  subtContainerOne: {
    paddingLeft: 18,
    paddingRight: 18
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  toggleContainer: {
    flexDirection: "row",
    paddingLeft: 18,
    paddingRight: 18
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  headerSupport: {
    marginBottom: 18
  },
  headerAlign: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textLight
  },
  sessionParticipantContainer: {
    width: "100%",
    backgroundColor: colorPallete.white,
    // position: "relative",
    // position: "absolute", // very important for table secondary
    // shadowColor: colorPallete.cordioRedDark1,
    // shadowOpacity: 0.1,
    // shadowOffset: {
    //   height: 10,
    //   width: 5
    // },
    // shadowRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  textStylesContainer: {
    width: 135
  }
});
