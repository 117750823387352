import React, { ReactElement, useEffect, useState, useRef } from "react";
import { StyleSheet, useWindowDimensions, View, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { DownloadQR } from "@socion-cordio/common/src/components/atoms/downloadQR";
import { GenerateQR } from "@socion-cordio/common/src/components/atoms/generateQR";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Moment from "moment";
import { toast } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewQrCodeDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewQrCodeModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { FlatList } from "react-native";

interface Props {
  onSubmit?: (data: { otp: number }) => void;
  sessionData?: any;
  sessionId?: number;
  isSessionCompleted?: boolean;
  isMember?: boolean;
  getCompleteSessionDetails?: Function;
  isLiveSession?: any;
  isCompletedSession?: any;
}

const Styles = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: inline-grid;
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
    width: 210% !important;
    /* margin-left: -20%; */
  }
  .react-datepicker__input-container {
    input {
      border: 1px solid #675850;
      border-radius: 5px;
      font-family: Montserrat-Medium;
      font-size: 10px;
      height: 40px;
      padding: 7px;
      font-weight: lighter;
      width: 162px;
    }
  }
  .datePicker {
    color: "black";
    background-color: white;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal !important;
  }
`;

// const calculateMinTime = (date: any) => {
//   let isToday = Moment(date).isSame(Moment(), "day");
//   if (isToday) {
//     let nowAddOneHour = Moment(new Date()).toDate();
//     return nowAddOneHour;
//   }
//   return Moment().startOf("day").toDate();
// };

export default function SessionSideBarPanelPage(props: Props): ReactElement {
  const { isMember = false, getCompleteSessionDetails, isLiveSession, isCompletedSession } = props;
  const { t } = useTranslation();
  const { sessionData, sessionId, isSessionCompleted } = props;
  const [sessionStartDate, setSessionStartDate] = useState(null);
  const [sessionEndDate, setSessionEndDate] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(null);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [sessionAddress, setSessionAddress] = useState(null);
  const [isDeleteSession, setIsDeteleSession] = useState(false);
  const [isEditSession, setIsEditSession] = useState(false);
  const [displayDeleteButton, setDisplayDeleteButton] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(sessionData || null);
  const [isEndDateTimeCheck, setIsEndDateTimeCheck] = useState(false);
  const [isStartDateEditable, setIsStartDateEditable] = useState(false);
  const [isSessionCreator, setIsSessionCreator] = useState(false);
  const [user, setUser] = useState(null);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionDeleteLoading, setSessionDeleteLoading] = useState(false);
  const [carousel, setCarousel] = useState({ left: true, right: false });
  const [dateChanged, setDateChanged] = useState({ start: false, end: false });
  const [isEditable, setIsEditable] = useState(isMember);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [minTime, setMinTime] = useState(null);
  const [showLabel, setShowLabel] = useState(true);
  const history: any = useHistory();
  const location: any = useLocation();
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(null);
  const [items, setItems] = useState([]);
  const [dropdownPlaceholderVal, setDropdownPlaceholderVal] = useState(null);
  const [timeZoneValue, setTimeZoneValue] = useState(null);
  const [selectedTimeZoneValue, setSelectedTimeZoneValue] = useState(null);
  const [convertedSessionStartDate, setConvertedSessionStartDate] = useState(null);
  const [convertedSessionEndDate, setConvertedSessionEndDate] = useState(null);
  const [selectedTimeZoneName, setSelectedTimeZoneName] = useState(null);
  const [timeZoneName, setTimeZoneName] = useState(null);
  const [startDateSelected, setStartDateSelected] = useState(true);
  const [endDateSelected, setEndDateSelected] = useState(true);
  const [startDateCalendarOpened, setStartDateCalendarOpened] = useState(false);
  const [endDate2, setEndDate2] = useState(null);
  const [initialStateStartDate, setInitialStateStartDate] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [isStartDateCalenderOpen, setIsStartDateCalenderOpen] = useState(false);
  const [changeTimeZone, setChangeTimeZone] = useState(false);
  const [showCountryCodeList, setShowCountryCodeList] = useState(false);
  const [buttonTitleValue, setbuttonTitleValue] = useState("Select your country");
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [isTimeZoneChanged, setIsTimeZoneChanged] = useState(false);
  const [startDatePlaceholder, setStartDatePlaceholder] = useState("Select Start Date");
  const [endDatePlaceholder, setEndDatePlaceholder] = useState("Select End Date");

  const [isTimeZoneChangedAfterDateSelection, setIsTimeZoneChangedAfterDateSelection] =
    useState(false);
  const endDateInputRef = useRef(null);
  const startDateInputRef = useRef(null);
  const userRolesData: any = useSelector((state: any) => state?.userRolesProgram);

  useEffect(() => {
    setDetails();
    getUserDetails();
    let defaultTimeZone = Moment.tz.guess();
    setTimeZone();
    setTimeZoneName(defaultTimeZone);
    setCurrentDateTime(new Date());
    setMinTime(calculateMinTime(new Date(), defaultTimeZone, true));
  }, []);

  const convertUtctoLocal = (date: any) => {
    let datetime = new Date(Moment.utc(date));
    // let newDate = Moment(datetime).format("YYYY-MM-DDTHH:mm");
    return datetime;
  };

  useEffect(() => {
    if (viewButtonDetails || isDeleteSession) disableScroll();
    else enableScroll();
  }, [viewButtonDetails, isDeleteSession]);

  useEffect(() => {
    if (
      isMember ||
      isSessionCreator ||
      userRolesData?.userRolesData?.response?.includes("ENTITY_ADMIN") ||
      userRolesData?.userRolesData?.response?.includes("PROGRAM_ADMIN")
    )
      setIsEditable(true);
    else setIsEditable(false);
  }, [isMember, isSessionCreator, userRolesData?.userRolesData]);

  useEffect(() => {
    // console.log(displayDeleteButton, "displayDeleteButton");
  }, [displayDeleteButton]);

  const setTimeZone = async () => {
    let timezone_list = Moment.tz
      .names()
      .sort((a, b) => Moment.tz(a).utcOffset() - Moment.tz(b).utcOffset());

    let selectedTimeZone = sessionDetails?.sessionTimeZone?.split(" ");
    setSelectedTimeZoneName(sessionDetails?.currentSelectedTimezone);
    let browsersTimezone = Moment.tz.guess();
    let selectedIndex: any = null;
    const modifiedTimeZoneList: any = timezone_list?.map((timeZone: any, id: any) => {
      if (browsersTimezone === timeZone) {
        selectedIndex = id;
      }
      return { label: `(GMT${Moment.tz(timeZone).format("Z")}) ${timeZone}`, value: id };
    });
    modifiedTimeZoneList?.map((timeZone: any, id: any) => {
      if (selectedIndex === id) {
        setDropdownPlaceholderVal(timeZone.label);
        setVal(timeZone.value);
        setbuttonTitleValue(timeZone.label);
      }
    });
    setItems(modifiedTimeZoneList);
  };

  const convertTZ = (date: any, tzString: any) => {
    let newConvertedDate = new Date(date.toLocaleString("en-US", { timeZone: tzString }));
    return newConvertedDate;
  };

  const convertEndTimeTZ = (date: any, tzString: any) => {
    let newConvertedDate;
    if (
      endDateInputRef.current.props.selected == "" ||
      endDateInputRef.current.props.selected == null
    ) {
      if (date) {
        newConvertedDate = new Date(date);
        newConvertedDate.setMinutes(newConvertedDate.getMinutes() + 60);
        setIsEndDateTimeCheck(false);
        return newConvertedDate;
      } else {
        let currentDate = new Date();
        newConvertedDate = new Date(currentDate.toLocaleString("en-US", { timeZone: tzString }));
        setIsEndDateTimeCheck(false);
        return "";
      }
    } else {
      setIsEndDateTimeCheck(false);
      return endDate;
    }
  };

  const startDateOpenden = (dateChack: any) => {
    // setStartDateCalendarOpened(true);
    if (
      !startDateCalendarOpened &&
      (startDateInputRef.current.props.selected == "" ||
        startDateInputRef.current.props.selected == null)
    ) {
      toast.error("Select Start date first!!");
      setIsEndDateTimeCheck(true);
      setIsTimeZoneChangedAfterDateSelection(false);
    } else {
      setIsEndDateTimeCheck(false);
    }
  };

  const setTimeZoneNameOnChange = (val: any) => {
    let tzName;
    items?.map((timeZone: any, id: any) => {
      if (val === id) {
        let name = timeZone.label.split(" ");
        tzName = name[1];
        setTimeZoneName(name[1]);
        setbuttonTitleValue(timeZone.label);
      }
    });
    const date = new Date();
    let setNewDate = convertTZ(date, tzName);
    if (tzName !== timeZoneName) {
      setChangeTimeZone(true);
    }
    // setMinTime(calculateMinTimeOnChange(setNewDate, tzName));
    // setCurrentDateTime(Moment().format("YYYY-MM-DDTHH:mm"));
    setCurrentDateTime(setNewDate);
    setMinTime(calculateMinTimeOnChange(setNewDate, tzName));
    setSessionStartDate(setNewDate);
    // setEndDate(convertEndTimeTZ(date, tzName));
    return;
  };

  const calculateMinTimeOnChange = (date: any, timeZoneName: any) => {
    // if (!date) {
    //   date = Moment(convertTZ(new Date(), timeZoneName)).tz(timeZoneName);
    //   console.log("When date is not there", date);
    // }

    let isToday = Moment(date).isSame(
      Moment(convertTZ(new Date(), timeZoneName)).tz(timeZoneName),
      "day"
    );
    if (isToday) {
      let nowAddOneHour = Moment(convertTZ(new Date(), timeZoneName)).tz(timeZoneName).toDate();
      return nowAddOneHour;
    }
    return Moment().startOf("day").toDate();
  };

  const calculateMinTime = (date: any, timeZoneName: any, isFirstTime?: boolean) => {
    let isToday = Moment(date).isSame(Moment(), "day");
    const startDate = convertUtctoLocal(sessionDetails?.sessionStartDate);
    const isStartDateToday = Moment(startDate).isSame(Moment(), "day");
    const endDate = convertUtctoLocal(sessionDetails?.sessionEndDate);
    const isEndDateToday = Moment(endDate).isSame(Moment(), "day");

    if (isFirstTime) {
      if (isEndDateToday && isStartDateToday) {
        let nowAddOneHour = Moment(new Date()).toDate();
        return nowAddOneHour;
      } else {
        return Moment().startOf("day").toDate();
      }
    }
    if (isToday) {
      let nowAddOneHour = Moment(new Date()).toDate();
      return nowAddOneHour;
    }
    return Moment().startOf("day").toDate();
  };

  const getUserDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setUser(user);
    checkCanUserDeleteSession(user);
    checkCurrentUser(user);
  };

  const handleCopy = (data: string) => {
    navigator.clipboard.writeText(data);
    toast.success("Copied");
  };

  const setDetails = () => {
    //imp value/min/max format :: 2018-06-12T19:30
    const startDate = convertUtctoLocal(sessionDetails?.sessionStartDate);
    const endDate = convertUtctoLocal(sessionDetails?.sessionEndDate);
    setSessionStartDate(startDate);
    setSessionEndDate(endDate);
    const noAddress = "None";
    sessionDetails?.address === null ? setSessionAddress(noAddress) : sessionDetails?.address;
    setSessionAddress(sessionDetails?.address);
  };

  const checkCurrentUser = (user: any) => {
    const userId = user?.userId;
    const sessionCreatorId = sessionDetails?.sessionCreatorProfile.userId;
    if (userId === sessionCreatorId) {
      setIsSessionCreator(true);
    } else {
      setIsSessionCreator(false);
    }
  };

  const isDateChangedCheck = (date: any) => {
    //date can be start date, end date, or address/venue
    const startDate = Moment(sessionDetails.sessionStartDate).format("YYYY-MM-DDTHH:mm");
    const endDate = Moment(sessionDetails.sessionEndDate).format("YYYY-MM-DDTHH:mm");
    const address = sessionDetails?.address;
    if (date !== startDate || date !== endDate || date !== address) {
      // toast.error("date changed");
      setIsDateChanged(true);
    } else {
      setIsDateChanged(false);
    }
  };

  const startSessionDateCheck = (startDate: any) => {
    const sessionStartDate = Moment(startDate).format("YYYY-MM-DDTHH:mm");
    const sessionEndDateCalcualte = Moment(sessionEndDate).format("YYYY-MM-DDTHH:mm");
    let tempCurrent = Moment(convertTZ(new Date(), timeZoneName)).format("YYYY-MM-DDTHH:mm");
    if (tempCurrent > sessionStartDate) {
      toast.error(
        "Start date and time should be earlier than end date and time & later than current date and time!"
      );
      setIsEndDateTimeCheck(true);
    } else {
      setIsEndDateTimeCheck(false);
    }
    if (sessionStartDate > sessionEndDateCalcualte) {
      toast.error(
        "Start date and time should be earlier than end date and time & later than current date and time!!"
      );
      setIsEndDateTimeCheck(true);
    } else {
      setIsEndDateTimeCheck(false);
    }
  };

  const isDateCorrectCheck = (date: any) => {
    const endDate = Moment(date).format("YYYY-MM-DDTHH:mm");
    const startDate = Moment(sessionStartDate).format("YYYY-MM-DDTHH:mm");
    if (endDate < startDate) {
      toast.error("End date and time should be later than the start and current date and time");
      setIsEndDateTimeCheck(true);
    } else {
      setIsEndDateTimeCheck(false);
    }
  };

  const cancelDetailsHandler = () => {
    setDetails();
    setIsDateChanged(false);
    let defaultTimeZone = Moment.tz.guess();
    setTimeZone();
    setTimeZoneName(defaultTimeZone);
    setCurrentDateTime(new Date());
    setIsTimeZoneChanged(false);
    setIsTimeZoneChangedAfterDateSelection(false);
  };

  const getTime = (data: any) => {
    let splitTime = data?.toString().split("T");
    return splitTime[1];
  };

  const checkCanUserDeleteSession = (user: any) => {
    const userId = user?.userId;
    const sessionCreatorId = sessionDetails?.sessionCreatorProfile.userId;
    const sessionStartDate = Moment(convertUtctoLocal(sessionDetails?.sessionStartDate)).format(
      "YYYY-MM-DDTHH:mm"
    );
    const currentDateTime = Moment().format("YYYY-MM-DDTHH:mm");
    const currentTime = getTime(Moment(new Date()).format("YYYY-MM-DDTHH:mm"));
    const startTime = getTime(Moment(new Date(sessionStartDate)).format("YYYY-MM-DDTHH:mm"));
    if (userId === sessionCreatorId && !isLiveSession && !isCompletedSession) {
      setDisplayDeleteButton(true);
    } else {
      setDisplayDeleteButton(false);
    }
    // to check if start time is greater than current time,
    // then only disable the edit date time.
    if (sessionStartDate > currentDateTime) {
      setIsStartDateEditable(true);
    } else {
      setIsStartDateEditable(false);
    }
  };

  const deleteSessionHandler = async () => {
    setIsDeteleSession(true);
  };

  const deleteHandler = async (value: any) => {
    try {
      if (value == true) {
        setSessionDeleteLoading(true);
        const sessionId = sessionDetails.sessionId;
        const response = await ApiClient.delete(sessionPackEndPoints.deleteSession(sessionId));
        if (response?.responseCode === 200) {
          toast.success(response?.message);
          updateTelemetryDeleteSession();
          navigate();
          setSessionDeleteLoading(false);
        } else {
          toast.error(response?.message);
          setIsDeteleSession(false);
          navigate();
          setSessionDeleteLoading(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setIsDeteleSession(false);
      setSessionDeleteLoading(false);
    }
  };

  const updateTelemetryDeleteSession = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      deleted: "false",
      eventType: "Delete Session",
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      updatedAt: Moment().toLocaleString(),
      sessionStartDate: sessionData.sessionStartDate,
      sessionEndDate: sessionData.sessionEndDate,
      sessionId: sessionData.sessionId,
      sessionName: sessionData.sessionName,
      venue: sessionData.address,
      topicId: sessionData.topicInfo.topic.id,
      topicName: sessionData.topicInfo.topic.name,
      programId: sessionData.topicInfo.topic.programId,
      programName: sessionData.topicInfo.program.name
      // establishmentName: user.establishmentName
    };
    AddTelemetryService(body, undefined, user, true);
    googleWebAnalytics("Delete_Session", "Telemetry_Event", "Session");
  };

  const navigate = () => {
    history.push(`${routeNames.app}${routeNames.SESSION}`, {
      locationData: { pathname: "upcoming" }
    });
  };

  const DATE_FORMAT = {
    UTC: "YYYY-MM-DD HH:mm:ss.SSS",
    dddMMMDDYYYYhmmA: "ddd MMM DD,YYYY h:mm A",
    dddMMMDDYYYY: "ddd MMM DD, YYYY",
    hhmmAMMMDDYYY: "hh:mm A | MMM DD, YYYY",
    hhmmA: "hh:mm A",
    YYYYMMdd: "YYYY-MM-DD",
    DDMMYYYY: "DD-MM-YYYY",
    DDMMMYYYY: "DD - MMM - YYYY",
    DDMMYYYYhhmmA: "DD-MM-YYYY hh:mm A",
    DDMMMYYYYhhmmA: "DD-MMM-YYYY hh:mm A",
    HHmmss: "HH:mm:ss",
    DDMMMMYYYY: "DD-MMMM-YYYY",
    DDMMMMDDYYYYhmmA: "DD-MMMM-YYYY   h:mm A",
    DDMMYYYYhmmA: "DD-MM-YYYY h:mm A",
    DDMMYYYYhmmssA: "DD-MM-YYYY h:mm:ss A",
    DDMMMYYYYhmmssA: "DD-MMM-YYYY h:mm:ss A",
    hmmA: "h:mm A",
    zeroFormat: "00:00:00.000"
  };

  const saveSessionHandler = async () => {
    // let converted_start_date;
    // let converted_end_date;

    // const start_date = Moment(sessionStartDate).format(DATE_FORMAT.UTC);
    // const end_date = Moment(sessionEndDate).format(DATE_FORMAT.UTC);

    // converted_start_date = Moment.tz(start_date, DATE_FORMAT.UTC, timeZoneName)
    //   .utc()
    //   .format(DATE_FORMAT.UTC);
    // converted_end_date = Moment.tz(end_date, DATE_FORMAT.UTC, timeZoneName)
    //   .utc()
    //   .format(DATE_FORMAT.UTC);

    // setConvertedSessionStartDate(converted_start_date);
    // setConvertedSessionEndDate(converted_end_date);
    const endDate = Moment(sessionEndDate).format("YYYY-MM-DDTHH:mm");
    const startDate = Moment(sessionStartDate).format("YYYY-MM-DDTHH:mm");

    if (startDate >= endDate) {
      toast.error("End date and time should be later than the start and current date and time");
      return;
    }
    if (
      startDate <= Moment(convertTZ(new Date(), timeZoneName)).format("YYYY-MM-DDTHH:mm") &&
      dateChanged.start
    ) {
      toast.error(
        "Start date and time should be earlier than end date and time & later than current date and time!"
      );
      return;
    }
    if (
      new Date(sessionStartDate).getTime() !==
        new Date(convertUtctoLocal(sessionData.sessionStartDate)).getTime() ||
      new Date(sessionEndDate).getTime() !==
        new Date(convertUtctoLocal(sessionData.sessionEndDate)).getTime()
    ) {
      setIsEditSession(true);
    } else {
      saveSessionDataHandler();
    }
  };

  const saveSessionDataHandler = async () => {
    try {
      setIsEditSession(false);
      setLoading(true);
      let timezone_offset;
      let newTimeZoneOffset;
      let timezone_name;
      let selectedTimeZone;

      items?.map((timeZone: any, id: any) => {
        if (val === id) {
          var timezone_val = timeZone.label.split(" ");
          timezone_offset = timezone_val[0];
          newTimeZoneOffset = timezone_offset.replace("(", "").replace(")", "");
          timezone_name = timezone_val[1];
          let timezone_abbr = auxiliaryMethods.getLocalTimezoneName(
            newTimeZoneOffset,
            timezone_name
          );
          selectedTimeZone = timezone_abbr;
          setSelectedTimeZoneValue(selectedTimeZone);
        }
      });

      let converted_start_date;
      let converted_end_date;

      const start_date = Moment(sessionStartDate).format(DATE_FORMAT.UTC);
      const end_date = Moment(sessionEndDate).format(DATE_FORMAT.UTC);

      converted_start_date = Moment.tz(start_date, DATE_FORMAT.UTC, timeZoneName)
        .utc()
        .format(DATE_FORMAT.UTC);
      converted_end_date = Moment.tz(end_date, DATE_FORMAT.UTC, timeZoneName)
        .utc()
        .format(DATE_FORMAT.UTC);
      console.log("changeTimeZone", changeTimeZone);

      let sessionDetailsCopy = { ...sessionDetails };
      sessionDetailsCopy["address"] = sessionAddress;
      sessionDetailsCopy["sessionStartDate"] = converted_start_date;
      sessionDetailsCopy["sessionEndDate"] = converted_end_date;
      sessionDetailsCopy["sessionTimeZone"] = selectedTimeZone;
      sessionDetailsCopy["currentSelectedTimezone"] = changeTimeZone
        ? timeZoneName
        : sessionDetails.currentSelectedTimezone;
      // Confirm again later, we're sending the whole body.
      // may cause errors in future.
      // const newBody = {
      //   sessionStartDate: sessionDetailsCopy["sessionStartDate"],
      //   sessionEndDate: sessionDetailsCopy["sessionEndDate"],
      //   address: sessionAddress,
      //   sessionName: sessionDetailsCopy["sessionName"]
      // };
      const response = await ApiClient.put(
        sessionPackEndPoints.editSessionDetails(),
        sessionDetailsCopy
      );
      if (response?.responseCode === HTTP_STATUS_CODES.ok) {
        toast.success(response?.message);
        getCompleteSessionDetails();
        getSessionDetails();
        setIsDateChanged(false);
        updateSessionTelemetry(sessionDetailsCopy);
      } else {
        toast.error(response?.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoading(false);
      setIsEditSession(false);
    }
  };

  const updateSessionTelemetry = async (data: any) => {
    const programData: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Edit session",
      sessionStartDate: data.sessionStartDate,
      sessionEndDate: data.sessionEndDate,
      sessionId: data.sessionId,
      sessionName: data.sessionName,
      venue: data.address,
      topicId: data.topicInfo.topic.id,
      topicName: data.topicInfo.topic.name,
      programId: programData?.program?.id,
      programName: programData?.program?.name,
      entityId: null,
      entityname: null
    };
    AddTelemetryService(body, undefined, user, true);
    googleWebAnalytics("Edit_Session", "Telemetry_Event", "Session");
  };

  const getSessionDetails = async () => {
    try {
      const response = await ApiClient.get(
        sessionPackEndPoints.getCompleteSessionDetails(sessionId)
      );
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        setSessionDetails(response?.response);
        setLoading(false);
        setFailed(false);
      } else {
        toast.error(response?.message);
        setLoading(false);
        setFailed(true);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoading(false);
      setFailed(true);
    }
  };

  const getFileName = (name: string) => {
    let data = name.split("/session-qr/");
    return data[data.length - 1];
  };

  const actionOnRow = (item: any) => {
    setShowCountryCodeList(false);
    setVal(item.value);
    setTimeZoneValue(item.value);
    setTimeZoneNameOnChange(item.value);
    setEndDateSelected(false);
    setStartDateSelected(false);
    setEndDateSelected(false);
    setStartDateCalendarOpened(false);
    setEndDate2("");
    setSessionStartDate("");
    setSessionEndDate("");
    setIsTimeZoneChanged(true);
    setIsEndDateTimeCheck(false);
    setStartDatePlaceholder("Select Start Date");
    setEndDatePlaceholder("Select End Date");
    setIsTimeZoneChangedAfterDateSelection(false);
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <Loader customLoadingContainer={{ width: 200, justifyContent: "center" }} />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          <View style={styles.subContainer}>
            <View style={styles.alignContainer}>
              <Text
                fontWeight={FontWeight.Light}
                testId="text"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.headerTextTitleSupport]}
              >
                {sessionDetails?.sessionName}
              </Text>
            </View>
            <ScrollContainer
              height="100px"
              scrollable={sessionDetails?.sessionDescription?.length > 120 ? true : false}
            >
              <View style={[styles.alignContainer, styles.containerWidth]}>
                <Text
                  onPress={() => {}}
                  fontWeight={FontWeight.Regular}
                  testId="text"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerTextSupport, styles.descriptionStyle]}
                >
                  {sessionDetails?.sessionDescription}
                </Text>
              </View>
            </ScrollContainer>
            {!isSessionCompleted &&
              (location?.state?.completed == true ? null : (
                <>
                  <View
                    style={[
                      styles.alignContainer,
                      { marginTop: 25, marginBottom: 20, alignSelf: "center" }
                    ]}
                  >
                    <Text
                      fontWeight={FontWeight.Bold}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.headerText,
                        styles.headerTextSupport,
                        { fontWeight: "700", fontSize: 14, alignSelf: "center" }
                      ]}
                    >
                      {sessionDetails?.sessionScanMode === "SINGLE_SCAN"
                        ? "SINGLE SCAN"
                        : carousel.right
                        ? "End"
                        : "Start"}
                    </Text>

                    <View style={{ flexDirection: "row", marginTop: 15 }}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.headerText,
                          styles.headerTextSupport,
                          { opacity: 0.6, fontSize: 10 }
                        ]}
                      >
                        {t("common:uid")}
                        {" : "}
                      </Text>

                      <Text
                        fontWeight={FontWeight.Bold}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.headerText,
                          styles.headerTextSupport,
                          { fontWeight: "700", fontSize: 10 }
                        ]}
                      >
                        {carousel.right ? sessionDetails?.endUid : sessionDetails?.startUid}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.qrContainer}>
                    <View style={styles.subQrContainer}>
                      {carousel.right && (
                        <TouchableOpacity
                          onPress={() => {
                            setCarousel({ left: true, right: false });
                          }}
                        >
                          <View style={styles.iconContainerHeader}>
                            <Icon
                              testID="down"
                              name={IconNames.down}
                              customStyle={[styles.titleIcon, styles.leftArrowIcon]}
                            />
                          </View>
                        </TouchableOpacity>
                      )}
                      <View>
                        <View style={styles.qrCode} nativeID="generateQR">
                          <View style={styles.displayQR} nativeID="displayQR">
                            {sessionDetails?.sessionScanMode === "SINGLE_SCAN" ? (
                              <>
                                UID: {sessionDetails?.startUid}
                                <br />
                                {sessionDetails?.sessionScanMode}
                                <br />
                                START-DATE: {sessionDetails?.sessionStartDate}
                                <br />
                                END-Date: {sessionDetails?.sessionEndDate}
                                <br />
                                {sessionDetails?.sessionName}
                              </>
                            ) : (
                              <>
                                UID:{" "}
                                {carousel.right ? sessionDetails?.endUid : sessionDetails?.startUid}
                                <br />
                                {carousel.right
                                  ? `END-DATE: ${sessionDetails?.sessionEndDate}`
                                  : `START-DATE: ${sessionDetails?.sessionStartDate}`}
                                <br />
                                {sessionDetails?.sessionName}
                              </>
                            )}
                          </View>
                          <View nativeID="divider"> </View>
                          <View nativeID="qrCodeContainer">
                            <TouchableOpacity onPress={() => setViewButtonDetails(true)}>
                              <Image
                                testId="sessionQR"
                                source={
                                  carousel.right
                                    ? sessionDetails?.endQrImage
                                    : sessionDetails?.startQrImage
                                }
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                      {carousel.left && sessionDetails?.sessionScanMode !== "SINGLE_SCAN" && (
                        <TouchableOpacity
                          onPress={() => {
                            setCarousel({ left: false, right: true });
                          }}
                        >
                          <View style={styles.iconContainerHeader}>
                            <Icon
                              testID="down"
                              name={IconNames.down}
                              customStyle={[styles.titleIcon, styles.alignArrowIcon]}
                            />
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>
                    {/* <View style={styles.qrActionsContainer}>
                      <TouchableOpacity>
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.share}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          handleCopy(
                            carousel.right
                              ? `${sessionDetails?.endQrcode}`
                              : `${sessionDetails?.startQrcode}`
                          )
                        }
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.link}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          mediaHelper.mediaDownloadQRHandler(
                            "session-qr",
                            getFileName(
                              carousel.right
                                ? sessionDetails?.endQrcode
                                : sessionDetails?.startQrcode
                            )
                          )
                        }
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.downloadList}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                    </View> */}
                    {/* </View> */}
                    <View style={styles.qrActionsContainer}>
                      <TouchableOpacity
                        onPress={() => {
                          setViewButtonDetails(true);
                          googleWebAnalytics("View_Session_View_QR", "Button", "Session");
                        }}
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.link}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          handleCopy(
                            carousel.right
                              ? `${sessionDetails?.endQrcode}`
                              : `${sessionDetails?.startQrcode}`
                          );
                          googleWebAnalytics("View_Session_Copy_QR", "Button", "Session");
                        }}
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.contentCopy}
                            customStyle={[styles.titleIcon, styles.alignCopyIcon]}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          mediaHelper.mediaDownloadQRHandler(
                            "session-qr",
                            getFileName(
                              carousel.right
                                ? sessionDetails?.endQrcode
                                : sessionDetails?.startQrcode
                            )
                          );
                          googleWebAnalytics("View_Session_Download_QR", "Button", "Session");
                        }}
                      >
                        <View style={styles.iconContainer}>
                          <Icon
                            testID="close"
                            name={IconNames.downloadList}
                            customStyle={styles.titleIcon}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </>
              ))}
            <View style={styles.inputContainer}>
              <View
                style={[
                  styles.alignUserInputFields,
                  { position: "absolute", zIndex: 1, width: "88%", alignItems: "flex-start" }
                ]}
              >
                <View style={{ width: 37, marginTop: 5 }}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="internal"
                    textSize={TextSize.Small}
                    textStyle={[styles.subheaderText]}
                  >
                    {t("common:timeZone")}
                    {/* {showLabel ? t("common:timeZone") : t("")} */}
                  </Text>
                </View>
                <View style={styles.buttonContainer}>
                  {/* <Button
                    type={ButtonType.Secondary}
                    buttonStyles={
                      !showCountryCodeList
                        ? styles.button
                        : [styles.button, { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }]
                    }
                    textStyles={{ color: colorPallete.textBlack, fontSize: 10 }}
                    title={buttonTitleValue}
                    onPress={() => {
                      setShowCountryCodeList(!showCountryCodeList);
                    }}
                  /> */}
                  <TouchableOpacity
                    style={
                      !showCountryCodeList
                        ? styles.button
                        : [styles.button, { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }]
                    }
                    onPress={() => {
                      setShowCountryCodeList(!showCountryCodeList);
                    }}
                  >
                    <View style={{ width: 132, flexDirection: "row" }}>
                      <Text
                        fontWeight={FontWeight.Medium}
                        testId="forgot"
                        textSize={TextSize.Small}
                        textStyle={[styles.buttonListText, { paddingBottom: 0 }]}
                      >
                        {buttonTitleValue}
                      </Text>
                    </View>
                    <View>
                      <Icon
                        testID="close"
                        size={7}
                        customStyle={{ color: colorPallete.cordioGray }}
                        name={showCountryCodeList ? IconNames.up : IconNames.down}
                      />
                    </View>
                  </TouchableOpacity>

                  {showCountryCodeList && (
                    <View style={styles.flatListContainer}>
                      <FlatList
                        data={items}
                        renderItem={({ item }) => (
                          <TouchableOpacity onPress={() => actionOnRow(item)}>
                            <View style={styles.flatview}>
                              <Text
                                fontWeight={FontWeight.Medium}
                                testId="forgot"
                                textSize={TextSize.Small}
                                textStyle={styles.flatListText}
                              >
                                {item.label}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        )}
                        showsVerticalScrollIndicator={true}
                        keyExtractor={(item) => item.value}
                        extraData={selectedSequence}
                        contentContainerStyle={{}}
                        nativeID="customScrollBarId"
                      />
                    </View>
                  )}
                </View>
                {/* <View style={{ maxWidth: "92%", minWidth: "92%" }}>
                  <Dropdown
                    open={open}
                    dropdownValue={val}
                    items={items}
                    setOpen={setOpen}
                    setValue={setVal}
                    dropdownPlaceholder={dropdownPlaceholderVal}
                    setItems={setItems}
                    containerWidthStyle={styles.timeZonecontainerStyle}
                    containerRadius={styles.dropDownContainerStyle}
                    closeAfterSelecting={true}
                    listItems={styles.listItems}
                    containerStyle={styles.ContainerStyle2}
                    onChangeValue={(val) => [
                      setTimeZoneValue(val),
                      setTimeZoneNameOnChange(val),
                      setEndDateSelected(false),
                      setStartDateSelected(false),
                      setStartDateCalendarOpened(false),
                      setEndDate2("")
                    ]}
                    textStyle={styles.dropDownTextStyle}
                    arrowIconStyle={styles.arrowIconStyle}
                    pickerStyle={{ paddingVertical: 5, marginLeft: 4 }}
                    disabled={!isStartDateEditable || isSessionCompleted || !isEditable}
                  />
                </View> */}
              </View>
              <View style={{ marginTop: 60 }}>
                <View style={[styles.alignUserInputFields, { justifyContent: "space-between" }]}>
                  <View style={styles.alignSubTextLabel}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="internal"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText]}
                    >
                      {showLabel ? t("common:start") : null}
                    </Text>
                  </View>
                  <View style={styles.dateTimePickerContainer}>
                    <Styles>
                      <DatePicker
                        showTimeSelect
                        selected={sessionStartDate}
                        onChange={(date: any) => {
                          setSessionStartDate(date);
                          isDateChangedCheck(date);
                          startSessionDateCheck(date);
                          setDateChanged({ ...dateChanged, start: true });
                          setMinTime(calculateMinTimeOnChange(date, timeZoneName));
                          setChangeTimeZone(true);
                          setIsTimeZoneChangedAfterDateSelection(true);
                          setIsTimeZoneChanged(true);
                        }}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        timeIntervals={"15"}
                        popperPlacement="left"
                        placeholderText={startDatePlaceholder}
                        minDate={currentDateTime}
                        minTime={minTime}
                        maxTime={Moment().endOf("day").toDate()}
                        className="datePicker"
                        disabled={!isStartDateEditable || isSessionCompleted || !isEditable}
                        onCalendarClose={() => [
                          setShowLabel(true),
                          setEndDate2(sessionStartDate),
                          setIsStartDateCalenderOpen(false)
                        ]}
                        onCalendarOpen={() => {
                          setShowLabel(false);
                          setStartDateSelected(false);
                          setMinTime(calculateMinTimeOnChange(sessionStartDate, timeZoneName));
                          setStartDateCalendarOpened(true);
                          if (
                            !startDateSelected &&
                            (startDateInputRef.current.props.selected == "" ||
                              startDateInputRef.current.props.selected == null)
                          ) {
                            const date = new Date();
                            let setNewDate = convertTZ(date, timeZoneName);
                            setMinTime(calculateMinTimeOnChange(setNewDate, timeZoneName));
                            setSessionStartDate(setNewDate);
                            console.log("set selected start date only when sd is empty");
                            // console.log("setNewDate", setNewDate);
                            // console.log(startDateSelected, sessionStartDate);
                          }
                        }}
                        ref={startDateInputRef}
                      />
                    </Styles>
                  </View>
                </View>
              </View>
              <View>
                <View style={[styles.alignUserInputFields, { justifyContent: "space-between" }]}>
                  <View style={styles.alignSubTextLabel}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="internal"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText]}
                    >
                      {showLabel ? t("common:end") : null}
                    </Text>
                  </View>
                  <View style={styles.dateTimePickerContainer}>
                    <Styles>
                      <DatePicker
                        showTimeSelect
                        selected={sessionEndDate}
                        onChange={(date: any) => {
                          setSessionEndDate(date);
                          isDateChangedCheck(date);
                          isDateCorrectCheck(date);
                          setDateChanged({ ...dateChanged, end: true });
                          setMinTime(calculateMinTimeOnChange(date, timeZoneName));
                          setChangeTimeZone(true);
                          setIsTimeZoneChangedAfterDateSelection(true);
                          setIsTimeZoneChanged(true);
                        }}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        timeIntervals={"15"}
                        popperPlacement="left"
                        minDate={currentDateTime}
                        placeholderText={endDatePlaceholder}
                        className="datePicker"
                        disabled={isSessionCompleted || !isEditable || location?.state?.completed}
                        minTime={minTime}
                        maxTime={Moment().endOf("day").toDate()}
                        onCalendarClose={() => setShowLabel(true)}
                        onCalendarOpen={() => {
                          setShowLabel(false);
                          setEndDateSelected(false);
                          setMinTime(calculateMinTimeOnChange(sessionEndDate, timeZoneName));
                          isDateCorrectCheck(sessionEndDate);
                          startDateOpenden(true);
                          if (
                            !endDateSelected &&
                            (endDateInputRef.current.props.selected == "" ||
                              endDateInputRef.current.props.selected == null)
                          ) {
                            const date = new Date();
                            let setNewDate = convertEndTimeTZ(endDate2, timeZoneName);
                            setMinTime(
                              calculateMinTimeOnChange(
                                convertEndTimeTZ(endDate2, timeZoneName),
                                timeZoneName
                              )
                            );
                            setSessionEndDate(setNewDate);
                            setIsEndDateTimeCheck(false);
                            setIsTimeZoneChangedAfterDateSelection(true);
                            console.log("set selected end date only when ed is empty");
                            // console.log("setNewDate", setNewDate);
                            // console.log(endDateSelected, sessionEndDate);
                          } else {
                            setSessionEndDate(sessionEndDate);
                          }
                        }}
                        ref={endDateInputRef}
                      />
                    </Styles>
                  </View>
                </View>
              </View>
              <View style={styles.zIndex}>
                <UserInputSecondary
                  textValue={t("common:venue")}
                  handleChange={(address: any) => {
                    setSessionAddress(address);
                    isDateChangedCheck(address);
                    setIsTimeZoneChanged(true);
                    setIsTimeZoneChangedAfterDateSelection(true);
                  }}
                  handleBlur={() => {}}
                  value={sessionAddress}
                  // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                  placeholder={t("common:venuePlaceholder")}
                  name="Venue"
                  id="Venue"
                  userStyle={
                    isSessionCreator && !isSessionCompleted
                      ? [styles.alignTextFieldWidth]
                      : [styles.alignTextFieldWidth, { color: colorPallete.disabledGrey }]
                  }
                  userStyleText={styles.alignTextWidth}
                  titleInputContainerStyles={{ marginTop: 0 }}
                  // editable={isSessionCreator && !isSessionCompleted}
                  editable={
                    !isSessionCompleted &&
                    isEditable &&
                    (location?.state?.completed === true ? false : true)
                  }
                  noFormik
                />
              </View>
            </View>
          </View>
          <View style={styles.submitButtonContainer}>
            {!isDateChanged && displayDeleteButton && !isTimeZoneChanged && (
              <View style={{ zIndex: -1 }}>
                {" "}
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbutton}
                  title={t("session:deleteSession")}
                  onPress={() => deleteSessionHandler()}
                  textStyles={{ fontSize: 12 }}
                />
              </View>
            )}
          </View>
          <View style={{ zIndex: -1 }}>
            {(isDateChanged || isTimeZoneChanged) && (
              <View style={{ flexDirection: "row", justifyContent: "space-evenly", zIndex: -1 }}>
                {" "}
                {isTimeZoneChanged && (
                  <Button
                    type={ButtonType.Secondary}
                    buttonStyles={styles.submitbuttonSecondary}
                    title={t("common:cancel")}
                    onPress={() => cancelDetailsHandler()}
                    textStyles={{ fontSize: 12 }}
                  />
                )}
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbuttonSecondary}
                  title={t("common:save")}
                  onPress={() => saveSessionHandler()}
                  textStyles={{ fontSize: 12 }}
                  disabled={
                    isEndDateTimeCheck ||
                    sessionAddress === null ||
                    sessionAddress === "" ||
                    !isTimeZoneChangedAfterDateSelection ||
                    startDateInputRef.current.props.selected == "" ||
                    endDateInputRef.current.props.selected == "" ||
                    startDateInputRef.current.props.selected == null ||
                    endDateInputRef.current.props.selected == null
                  }
                />{" "}
              </View>
            )}
          </View>
          {/* <View style={styles.duplicateButtonContainer}>
            {isSessionCompleted && (
              <View>
                {" "}
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbutton}
                  title="Duplicate session"
                  onPress={() => {}}
                  textStyles={{ fontSize: 12 }}
                />
              </View>
            )}
          </View> */}
        </View>
      )}
      {isDeleteSession && (
        <View>
          <SocionModal
            modalVisible={isDeleteSession}
            setModalVisible={() => setIsDeteleSession(!isDeleteSession)}
            component={
              <>
                {sessionDeleteLoading ? (
                  <Loader customLoadingContainer={styles.customLoadingContainer} />
                ) : (
                  <RemoveLinkModal
                    modalVisible={isDeleteSession}
                    selectedData={deleteHandler}
                    textValue={t("session:deleteSessionConfirm")}
                    setModalVisible={() => setIsDeteleSession(!isDeleteSession)}
                  />
                )}
              </>
            }
          />
        </View>
      )}
      {isEditSession && (
        <View>
          <SocionModal
            modalVisible={isEditSession}
            setModalVisible={() => setIsEditSession(!isEditSession)}
            component={
              <RemoveLinkModal
                modalVisible={isEditSession}
                selectedData={saveSessionDataHandler}
                textValue={
                  sessionDetails?.sessionScanMode === "SINGLE_SCAN"
                    ? t("session:editSingleSessionDates")
                    : t("session:editSessionDates")
                }
                setModalVisible={() => [setIsEditSession(!isEditSession), cancelDetailsHandler()]}
              />
            }
          />
        </View>
      )}
      {viewButtonDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonDetails}
            setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
            component={
              <ViewQrCodeDetails
                onClose={() => {
                  setViewButtonDetails(false);
                }}
                sessionData={sessionDetails}
                carouselHandler={carousel}
                selectedTimeZoneName={selectedTimeZoneName}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: colorPallete.cordioRedDark2
  },
  customLoadingContainer: {
    width: 655,
    height: 185
  },
  subContainer: {},
  alignContainer: {
    marginBottom: 15,
    marginRight: 5
  },
  containerWidth: {
    width: 170
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    color: colorPallete.white,
    width: "20ch"
  },
  headerTextSupport: {
    fontSize: 10,
    lineHeight: 15,
    color: colorPallete.white
  },
  descriptionStyle: {
    opacity: 0.6,
    width: "27ch"
  },
  qrContainer: {
    alignItems: "center"
  },
  qrCode: {
    flex: 100,
    maxWidth: 130,
    minHeight: 110
  },
  qrActionsContainer: {
    width: 120,
    marginTop: 20,
    marginVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconContainer: {},
  titleIcon: {
    color: "#fff",
    fontSize: 15
  },
  alignCopyIcon: {
    fontSize: 18
  },
  inputContainer: {
    marginTop: 40
  },
  alignTextFieldWidth: {
    width: "100%",
    fontSize: 10,
    backgroundColor: colorPallete.white
  },
  alignTextWidth: {
    flex: 19,
    fontSize: 10,
    color: colorPallete.white
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 40,
    zIndex: -1
  },
  duplicateButtonContainer: {
    alignItems: "center"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  submitbuttonSecondary: {
    width: "80px",
    height: "50px",
    borderRadius: 10
  },
  alignUserInputFields: {
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center"
  },
  subheaderText: {
    // flex: 12,
    fontWeight: "400",
    fontSize: 10,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.white
    // marginTop: 13
  },
  alignSubTextLabel: {},
  dateTimePickerContainer: {
    // width:"100%"
    zIndex: -1
  },
  subQrContainer: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainerHeader: {},
  alignArrowIcon: {
    position: "absolute",
    fontSize: 9,
    transform: [{ rotate: "270deg" }],
    top: 55,
    left: 20
  },
  leftArrowIcon: {
    position: "absolute",
    fontSize: 9,
    transform: [{ rotate: "90deg" }],
    top: 55,
    left: -40
  },
  displayQR: {
    display: "none",
    color: colorPallete.textLight
  },
  descriptionContainer: {
    height: "100px",
    overflowY: "scroll"
  },
  zIndex: {
    zIndex: -1
  },
  listItems: {
    padding: 10
  },
  ContainerStyle2: {
    borderColor: colorPallete.lightGreyTwo,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    minHeight: 40,
    marginBottom: 0,
    justifyContent: "center",
    backgroundColor: colorPallete.white
  },
  timeZonecontainerStyle: {
    width: "100%"
  },
  dropDownTextStyle: {
    fontSize: 12
  },
  arrowIconStyle: {
    width: 15,
    height: 16,
    marginLeft: 3
  },
  dropDownContainerStyle: {
    borderWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  buttonContainer: {
    width: 164
  },
  flatListContainer: {
    borderWidth: 0,
    height: 242,
    width: 164,
    borderTopWidth: 0,
    borderRadius: 0,
    top: 3,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: colorPallete.white
  },
  flatListText: {
    fontWeight: "400",
    fontSize: 10,
    position: "relative",
    zIndex: -1,
    display: "flex",
    paddingBottom: 10,
    paddingRight: 10,
    flex: 0.8,
    width: 144,
    wordBreak: "break-word"
  },
  buttonListText: {
    fontWeight: "400",
    fontSize: 10,
    position: "relative",
    zIndex: -1,
    // display: "flex",
    paddingBottom: 10,
    paddingRight: 10,
    // flex: 0.8,
    width: 126,
    lineHeight: 15
  },
  textIcon: {
    color: colorPallete.textLight,
    marginBottom: 10,
    marginTop: 5
  },
  button: {
    marginTop: 0,
    // height: 50,
    maxWidth: 162,
    zIndex: -1,
    borderRadius: 5,
    minHeight: 40,
    flexDirection: "row",
    backgroundColor: colorPallete.white,
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 5
  },
  flatview: {
    paddingTop: 0,
    borderRadius: 2,
    paddingLeft: 10
  }
});
