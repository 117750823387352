export enum TABS {
  Entity = "entity",
  Network = "network",
  entity = "Entity",
  network = "Network",
  programs = "Programs",
  Programs = "programs",
  entities = "Entities",
  Entities = "entities",
  networks = "Networks",
  Networks = "networks"
}

export enum STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive"
  // unverified = "Draft",
  // verified = "Active",
  // rejected = "Closed",
  // Draft = "unverified",
  // Active = "verified",
  // Closed = "rejected"
}

export const REGISTRY_TYPE = {
  entity: "entity",
  entity_program: "entity_program"
};
