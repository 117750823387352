import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Linking } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Header from "@socion-cordio/common/src/components/organisms/accomplishment/components/header";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/accomplishment/components/programDropdown";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { toast } from "react-toastify";
import {
  springshedEndPoints,
  accomplishments
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import styled from "styled-components";
import VerifyPage from "../verify/verifyPage";
import axios from "axios";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";
import { STATUS } from "@socion-cordio/common/src/network/constants";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function TaskDetails(): ReactElement {
  const location: any = useLocation();
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [details, setDetails] = useState(location?.state?.taskDetails);
  const [taskPdfUrl, setTaskPdfUrl] = useState("");
  const history: any = useHistory();
  const { taskDetails, programData, userId, accessToken, mainProgramList } = location.state;
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);

  const PDFDocumentWrapper = styled.div`
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  `;

  useEffect(() => {
    getSelectedProgram();
    getTaskSubmissionDetails();
  }, []);

  useEffect(() => {
    if (selectedProgram?.length != 0) LocalStorage.setStorage("selectedProgram", selectedProgram);
  }, [selectedProgram]);

  const getSelectedProgram = async () => {
    let program = await LocalStorage.getStorage("selectedProgram");
    setSelectedProgram(program);
  };

  const getTaskSubmissionDetails = async () => {
    try {
      let token = await LocalStorage.getStorage("accessToken");
      const headers = {
        "access-token": token,
        accept: "application/json"
      };
      const getTaskSubmissionDetailsResponse: any = await axios.get(
        `https://task-api-stage.socion.io/taskservice/api/v1/submissions/${taskDetails?.submissionId}`,
        { headers: headers }
      );

      setDetails(getTaskSubmissionDetailsResponse?.data?.result?.data);
      if ("pdfUrl" in getTaskSubmissionDetailsResponse?.data?.result?.data) {
        if (getTaskSubmissionDetailsResponse?.data?.result?.data?.pdfUrl !== "") {
          generateViewURL(
            "pdf",
            getFileName(getTaskSubmissionDetailsResponse?.data?.result?.data?.pdfUrl),
            getFilePath(getTaskSubmissionDetailsResponse?.data?.result?.data?.pdfUrl),
            "view"
          );
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const generateViewURL = async (type: string, name: string, filePath: string, opType: string) => {
    const bucketname = "task";
    const response = await ApiClient.get(
      accomplishments.getDocumentPresignedUrl(filePath, name, bucketname, opType)
    );
    if (response) {
      var encodedUrl = encodeURIComponent(response);
      var iFrameUrl = "https://docs.google.com/viewer?url=" + encodedUrl + "&embedded=true";
      setTaskPdfUrl(response);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getFileName = (url: string) => {
    let name = url.split("/");
    return name[name.length - 1];
  };

  const getFilePath = (name: string) => {
    let path = name.substring(0, name.lastIndexOf("/"));
    if (path.includes("http://54.234.158.84:8989/")) {
      return path.replace("http://54.234.158.84:8989/", "");
    } else if (path.includes("https://task-api-stage.socion.io/")) {
      return path.replace("https://task-api-stage.socion.io/", "");
    } else {
      return path.replace("https://pda-stage.socion.io/", "");
    }
  };

  const handleBack = () => {
    // let options = location?.state?.programOptions;
    history.push(
      `${routeNames.app}${routeNames.ACCOMPLISHMENTTASKS}/${selectedProgram.value}/${userId}/${accessToken}`,
      {
        programData: selectedProgram,
        userId: userId,
        accessToken: accessToken,
        programOptions: options,
        mainProgramList: mainProgramList
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <View style={styles.body}>
          <View style={{ zIndex: -1 }}>
            <View style={styles.headingContainer}>
              <View style={styles.mt2}>
                <TouchableOpacity onPress={handleBack}>
                  <Icon name={IconNames.leftArrow} customStyle={styles.leftarrow} />
                </TouchableOpacity>
              </View>
              <View style={[styles.ml20, { flex: 1 }]}>
                <Text
                  testId="qualificationPack"
                  fontWeight={FontWeight.ExtraBold}
                  textSize={TextSize.Large}
                  style={[styles.headerText]}
                >
                  {details.taskName}
                </Text>
              </View>
            </View>
            <View style={styles.locationDetails}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ paddingRight: 36 }}>{t("accomplishment:location")}</View>
                <View style={{ width: "74%" }}>
                  {auxiliaryMethods.fromatBaseTaskSubmissionLocation(details)}
                </View>
              </View>
              <View style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
                <View style={{ paddingRight: 59 }}>{t("accomplishment:date")}</View>
                <View>{dataHelper.formatDateTime(details?.submissionTime)}</View>
              </View>
              <View style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
                <View style={{ paddingRight: 51 }}>{t("accomplishment:Status")}</View>
                <View style={{ alignSelf: "center", flex: 1 }}>
                  <Text
                    testId="status"
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.Regular}
                    style={{
                      marginTop: 2,
                      color:
                        details?.status === STATUS.SUBMITTED
                          ? colorPallete.cordioTaupeDark3
                          : details.status === STATUS.APPROVED
                          ? colorPallete.cordioGreen
                          : details.status === STATUS.REJECTED
                          ? colorPallete.cordioRedDark5
                          : colorPallete.cordioTaupeDark3
                    }}
                  >
                    {details?.status === STATUS.SUBMITTED
                      ? details?.isVerificationRequired
                        ? STATUS.PENDING_VERIFICATION
                        : STATUS.SUBMITTED
                      : details?.status}
                  </Text>
                  {details?.verifierComments && (
                    <Text
                      testId="verifierComments"
                      fontWeight={FontWeight.Regular}
                      textSize={TextSize.Regular}
                      style={{
                        fontSize: 12,
                        marginTop: 15,
                        color:
                          details?.status === STATUS.SUBMITTED
                            ? colorPallete.cordioTaupeDark3
                            : details.status === STATUS.APPROVED
                            ? colorPallete.cordioGreen
                            : details.status === STATUS.REJECTED
                            ? colorPallete.cordioRedDark5
                            : colorPallete.cordioTaupeDark3
                      }}
                    >
                      {details?.verifierComments}
                    </Text>
                  )}
                </View>
              </View>
            </View>

            {/* <View style={styles.pdf}>
              {taskPdfUrl?.length !== 0 ? (
                <iframe
                  src={taskPdfUrl}
                  frameBorder="0"
                  // scrolling="auto"
                  height="145%"
                  // width="100%"
                  // allowFullScreen={true}
                />
              ) : (
                <View style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {t("accomplishment:NoPDFAvailable")}
                </View>
              )}
            </View> */}
            <View style={styles.pdf}>
              <PDFDocumentWrapper>
                <Document
                  file={taskPdfUrl}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  onLoadError={(error) => console.log("Inside Error", error)}
                  noData={
                    <View
                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                      {t("accomplishment:NoPDFAvailable")}
                    </View>
                  }
                >
                  {/* <Page pageNumber={2} width={200} /> */}
                  {Array.apply(null, Array(numPages))
                    .map((x: any, i: any) => i + 1)
                    .map((page: any) => (
                      <Page pageNumber={page} width={200} />
                    ))}
                </Document>
              </PDFDocumentWrapper>
            </View>
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: colorPallete.cordioBeige,
    paddingHorizontal: 16,
    minHeight: "100vh"
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20
  },
  ml20: {
    marginLeft: 20
  },
  mt2: {
    marginTop: 2
  },
  headerText: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 24,
    color: colorPallete.cordioTaupe
  },
  leftarrow: {
    color: colorPallete.cordioTaupe,
    width: 6,
    height: 12
  },
  locationDetails: {
    backgroundColor: colorPallete.white,
    minHeight: 100,
    marginTop: 20,
    padding: 14
  },
  pdf: {
    backgroundColor: colorPallete.white,
    // height: "145%",
    marginTop: 14
  },
  container: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 25
  }
});
