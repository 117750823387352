const getUserData = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return;
};

const getUserId = () => {
  const user = getUserData();
  return user?.userId;
};

const getUserName = () => {
  const user = getUserData();
  return user?.name;
};

const getUName = () => {
  const user = getUserData();
  return user?.userName;
};

export const UserHelper = {
  getUserData,
  getUserId,
  getUserName,
  getUName
};
