import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { useSelector } from "react-redux";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useDispatch } from "react-redux";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import CreateEntityBtn from "@socion-cordio/common/src/components/organisms/registry/entity/CreateEntityBtn";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { TABS, STATUS, REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import CreateEntityModal from "@socion-cordio/common/src/components/organisms/createEntityModal";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import {
  dispatchAllEntityList,
  dispatchEntityList
} from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";
import { EntityActions } from "@socion-cordio/common/src/modules/registry/entity/actions/actions";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { capitalize } from "lodash";
import { EntityRepository } from "@socion-cordio/common/src/repositories/registry/entity/entity";

interface IProps {}

const RegistryWorkspace = (props: IProps) => {
  const [activeTab, setActiveTab] = useState(TABS.Entity);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);
  const history = useHistory();
  const [showList, setShowList] = useState(null);
  const [entityNames, setEntityNames] = useState([]);
  const [tabList, setTabList] = useState({
    entities: [],
    networks: []
  });
  let isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));

  console.log("isSuperAdmin", isSuperAdmin);

  const dispatch = useDispatch();

  const {
    entities: entities,
    allEntities: allEntities,
    loading: { getEntities: loading, getAllEntities: allLoading },
    error: { getEntities: error, getAllEntities: allError }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    setIsLoading(true);
    if (isSuperAdmin) {
      dispatchAllEntityList(dispatch);
    } else {
      dispatchEntityList(dispatch);
    }
  }, []);

  useEffect(() => {
    if (isSuperAdmin) {
      setIsLoading(allLoading);
    } else {
      setIsLoading(loading);
    }
  }, [loading, allLoading]);

  useEffect(() => {
    if (error || allError) {
      setIsLoading(false);
      setShowList(false);
      setTabList({ ...tabList, entities: [] });
    }
  }, [error, allError]);

  useEffect(() => {
    console.log("entities", entities);
    if (isSuperAdmin) {
      if (allEntities !== null) {
        sortEntityList(REGISTRY_TYPE.entity, allEntities);
      }
    } else {
      if (entities !== null) {
        sortEntityList(REGISTRY_TYPE.entity, entities);
      }
    }
  }, [entities, allEntities]);

  // useEffect(() => {
  //   console.log("allEntities", allEntities);

  // }, [allEntities])

  const sortEntityList = (type: any, list: any) => {
    switch (type) {
      case REGISTRY_TYPE.entity:
        const names: any[] = [];
        const tabListData = list.map((item: any, index: number) => {
          item.serialNo = index + 1;
          names.push(item.name.toLowerCase());
          return item;
        });
        setEntityNames(names);
        setShowList(list.length > 0);
        setTabList({ ...tabList, entities: tabListData });
        setIsLoading(false);
        break;
    }
  };

  const dispatchTabEvent = (tab: string, isDispatch: boolean = false) => {
    if (UserHelper.getUserData() && activeTab !== tab) {
      if (tab === TABS.Entity && isDispatch) {
        dispatchEntityList(dispatch);
      }
    }
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          textSize: TextSize.Small,
          fontWeight: FontFamily.Light
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );
  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 40,
        maxWidth: 40,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, data.serialNo);
        }
      },
      {
        Header: "Entity Name",
        accessor: "name",
        width: 300,
        maxWidth: 330,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          return cellData("name", style, data?.name, async () => {
            const url = `${routeNames.app}${routeNames.ENTITY}`;
            await handleRowClick(data, url);
          });
        }
      },
      {
        Header: "Programs",
        accessor: "programCount",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = async () => {
            const url = `${routeNames.app}${routeNames.PROGRAM}`;
            await handleRowClick(data, url);
          };
          return cellData("programCount", style, data?.programCount || 0, onPress);
        }
      },
      {
        Header: "Partnerships",
        accessor: "partnerCount",
        width: 90,
        maxWidth: 90,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = async () => {
            const url = `${routeNames.app}${routeNames.PARTNERSHIP}`;
            await handleRowClick(data, url);
          };
          return cellData("partnerCount", style, data?.partnerCount || 0, onPress);
        }
      },
      {
        Header: "Registered On",
        accessor: "osCreatedAt",
        width: 90,
        maxWidth: 90,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          const date = data?.osCreatedAt ? dataHelper.formatDate(data?.osCreatedAt) : "";
          return cellData("osCreatedAt", style, date);
        }
      },
      {
        Header: "Registered By",
        accessor: "createdBy",
        width: 200,
        maxWidth: 250,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return cellData("createdBy", style, data?.createdBy);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Light}
              testId="deleted"
              textSize={TextSize.Small}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Regular,
                color: colorPallete.textBlack
              }}
            >
              <View
                style={[
                  styles.status,
                  data?.active ? styles.active : !data?.active ? styles.inActive : null
                ]}
              />
              {capitalize(data?.active ? "Active" : "In Active")}
            </Text>
          );
        }
      }
    ],
    [tabList]
  );

  const createEntityModal = () => {
    return (
      <View>
        <SocionModal
          modalVisible={showCreateEntityModal}
          setModalVisible={() => setShowCreateEntityModal(!showCreateEntityModal)}
          component={
            <CreateEntityModal
              onClose={() => setShowCreateEntityModal(false)}
              // entityNames={entityNames}
              dispatchEvent={(type: string) => {
                if (type === TABS.Entity) {
                  dispatchEntityList(dispatch);
                }
              }}
            />
          }
        />
      </View>
    );
  };

  const handleRowClick = async (entity: any, url: any) => {
    await LocalStorage.setStorage("selectedEntity", entity);
    dispatch(EntityActions.getEntity(entity?.osid));
    history.push(url, {
      entityId: entity.osid
    });
  };

  const getNetworkTabData = () => {
    return (
      <View>
        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText]}
        >
          No Networks Available
        </Text>
      </View>
    );
  };

  const searchTheGlobalRegistry = async (searchParam: string) => {
    const query = {
      name: searchParam
    };
    const result = await EntityRepository.searchEntitiesGlobal(query);
    sortEntityList(REGISTRY_TYPE.entity, result);
  };

  const getEntityTabData = () => {
    return (
      <>
        <>
          <View style={styles.searchDataContainer}>
            <View style={styles.searchContainer}>
              <SearchFilterSeondary
                editable={true}
                placeholder={"Search the global registry..."}
                filterList={(value: any) => {
                  if (value?.length > 3) {
                    searchTheGlobalRegistry(value.trim());
                  } else if (!value) {
                    dispatchEntityList(dispatch);
                  }
                }}
              />
            </View>
            {!isSuperAdmin && (
              <TouchableOpacity onPress={() => setShowCreateEntityModal(!showCreateEntityModal)}>
                <Image
                  testId="primaryAddImg"
                  source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                  imageStyle={{
                    width: 30,
                    height: 30
                  }}
                />
              </TouchableOpacity>
            )}
          </View>
          {/* {showList !== null && !showList  && (
            <View>
              <CreateEntityBtn
                isSuperAdmin={isSuperAdmin}
                onClick={() => setShowCreateEntityModal(!showCreateEntityModal)}
              ></CreateEntityBtn>
            </View>
          )} */}
        </>

        {showList && tabList?.entities?.length > 0 && (
          <View>
            <Table
              isSuperAdmin={isSuperAdmin}
              columns={columns}
              data={tabList.entities}
              showSearchField={true}
              customSearchBarContainerStyle={{ marginLeft: -20 }}
              paginationAndSearch={false}
              // handleRowClick={(row: any) => handleRowClick(row)}
              tableTitle={"Select Entity"}
              placeholder="Search the global registry..."
              addIcon={
                <TouchableOpacity onPress={() => setShowCreateEntityModal(!showCreateEntityModal)}>
                  <Image
                    testId="primaryAddImg"
                    source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                    imageStyle={{
                      width: 30,
                      height: 30
                    }}
                  />
                </TouchableOpacity>
              }
            />
          </View>
        )}
      </>
    );
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <View style={styles.toggleContainer}>
              <ButtonTab
                type={
                  activeTab === "entity"
                    ? ButtonTypeSecondary.Secondary
                    : ButtonTypeSecondary.Primary
                }
                buttonStyles={[styles.toggleButton, styles.buttonLeftRadius]}
                textStyles={styles.textStyle}
                title="Entities"
                onPress={() => {
                  setActiveTab(TABS.Entity);
                  dispatchTabEvent(TABS.Entity, false);
                }}
              />
              <ButtonTab
                type={
                  activeTab === "network"
                    ? ButtonTypeSecondary.Secondary
                    : ButtonTypeSecondary.Primary
                }
                buttonStyles={[styles.toggleButton, styles.buttonRightRadius]}
                textStyles={styles.textStyle}
                title="Networks"
                onPress={() => {
                  dispatchTabEvent(TABS.Network);
                  setActiveTab(TABS.Network);
                }}
              />
            </View>

            <View style={styles.dataContainer}>
              {activeTab === TABS.Entity && getEntityTabData()}
              {activeTab === TABS.Network && getNetworkTabData()}

              {showCreateEntityModal && createEntityModal()}
            </View>
          </>
        )}
      </View>
    </View>
  );
};

export default RegistryWorkspace;

const styles = StyleSheet.create({
  mainContainer: {
    padding: 20,
    paddingTop: 18,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    padding: 18,
    borderRadius: 10,
    backgroundColor: colorPallete.white,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  container: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.cordioGreen,
    marginBottom: 18,
    borderRadius: 10
  },
  toggleContainer: {
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  buttonRightRadius: {
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5
  },
  buttonLeftRadius: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5
  },
  textStyle: {
    fontSize: 12
  },
  dataContainer: {
    padding: 18,
    marginBottom: 180
  },
  searchDataContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  searchContainer: {
    width: 535,
    marginVertical: 25,
    maxWidth: "60%"
  },
  textAlignment: {
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    textDecorationLine: "underline"
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  upcoming: {
    backgroundColor: colorPallete.yellowOne
  },
  inActive: {
    backgroundColor: colorPallete.cordioRed
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  }
});
