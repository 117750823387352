import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import ParticipantAttestation from "@socion-cordio/common/src/components/organisms/participation-details/participantAttestation";
import TrainerAttestation from "@socion-cordio/common/src/components/organisms/participation-details/trainerAttestation";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {}

export default function AttestationPage(props: Props): ReactElement {
  const [isParticipantAttestation, setIsParticipantAttestation] = useState<boolean>(false);
  const [isTrainerAttestation, setIsTrainerAttestation] = useState<boolean>(false);
  const [programDetails, setProgramDetails] = useState(null);

  useEffect(() => {
    getProgramDetails();
    setIsParticipantAttestation(true);
  }, []);

  const getProgramDetails = async () => {
    const programDetails = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.toggleContainer}>
          <ButtonTab
            type={
              isParticipantAttestation ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
            }
            buttonStyles={[styles.toggleButton, styles.buttonLeftRadius]}
            textStyles={styles.textStyle}
            title="Participant Attestation"
            onPress={() => {
              LocalStorage.removeStorage("noneLocationListSet");
              LocalStorage.removeStorage("noAssignedBadgesSelected");
              setIsParticipantAttestation(true);
              setIsTrainerAttestation(false);
            }}
          />

          <ButtonTab
            type={
              isTrainerAttestation ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
            }
            buttonStyles={[styles.toggleButton, styles.buttonRightRadius]}
            textStyles={styles.textStyle}
            title="Trainer Attestation"
            onPress={() => {
              LocalStorage.removeStorage("noneLocationListSet");
              LocalStorage.removeStorage("noAssignedBadgesSelected");
              setIsParticipantAttestation(false);
              setIsTrainerAttestation(true);
            }}
          />
        </View>
        {programDetails && isParticipantAttestation ? (
          <ParticipantAttestation programDetails={programDetails} />
        ) : isTrainerAttestation ? (
          <TrainerAttestation programDetails={programDetails} />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  buttonLeftRadius: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5
  },
  buttonRightRadius: {
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5
  }
});
