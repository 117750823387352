import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { toast } from "react-toastify";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { useDispatch, useSelector } from "react-redux";
import { ParticipantDetailsActions } from "@socion-cordio/common/src/modules/participationDetails/actions/actions";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  participationDetailsEndPoints,
  qualificationPackEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import FilterModal from "@socion-cordio/common/src/components/organisms/participation-details/filterModal";
import CancelButton from "@socion-cordio/common/src/components/atoms/cancelButton";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import TableSecondary from "@socion-cordio/web/src/components/molecules/table/tableSecondary";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";
interface Props {
  programDetails: any;
}

export default function Participant(props: Props): ReactElement {
  const { programDetails } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [participantData, setParticipantData] = useState(null);
  const [participantHeaders, setParticipantHeaders] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [locationList, setLocationList] = useState(undefined);
  const [badgeRoleList, setBadgeRoleList] = useState(undefined);
  const [selectedBadgeRoleList, setSelectedBadgeRoleList] = useState([]);
  const [commonData, setCommonData] = useState({
    badgeIds: [],
    locations: [],
    isNoneLocationSet: null,
    isNoBadgeAssignSet: null
  });
  const [checkedList, setCheckedList] = useState([]);
  const [selectedLocationList, setSelectedLocationList] = useState([]);
  const [expandedList, setExpandedList] = useState([]);
  const [isLocationFliterApplied, setIsLocationFilterApplied] = useState(false);
  const [isBadgeFliterApplied, setIsBadgeFilterApplied] = useState(false);
  const [locationListSecondry, setLocationListSecondry] = useState([]);
  const [locationListButton, setLocationListButton] = useState([]);
  const [badgeRoleListButton, setBadgeRoleListButton] = useState([]);
  const [noneLocationSelected, setNoneLocationSelected] = useState(false);
  const [noAssignedBadgesSelected, setNoAssignedBadgesSelected] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(false);
  const [locationNameList, setLocationNameList] = useState([]);
  const [selectedBadgeNameList, setSelectedBadgeNameList] = useState([]);
  const [allExpandedNodes, setAllExpandedNodes] = useState([]);
  const [globalList, setGlobalList] = useState([]);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [errormessage, seterrormessage] = useState("No Participant(s) under this Program.");
  const [checkedListCopy, setCheckedListCopy] = useState([]);
  const [checkedListCopyBadges, setCheckedListCopyBadges] = useState([]);
  const dispatch = useDispatch();
  let participationDetails: any = useSelector((state: any) => state?.participationDetails);

  useEffect(() => {
    getParticipantData();
  }, []);

  useEffect(() => {
    leftNavCollapseHandler();
  });

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  const getParticipantData = async (location?: any, badgeData?: any) => {
    let isLocation = await LocalStorage.getStorage("noneLocationListSet");
    let isBadge = await LocalStorage.getStorage("noAssignedBadgesSelected");
    dispatch(
      ParticipantDetailsActions.getParticipantList({
        programId: programDetails?.program?.id,
        userType: "TRAINEE",
        badgeId: badgeData !== undefined ? badgeData : commonData.badgeIds,
        locations: location !== undefined ? location : formatLocation(commonData.locations),
        disableLocationFilter: isLocation === null ? false : isLocation,
        disableBadgeFilter: isBadge === null ? false : isBadge
      })
    );
  };

  useEffect(() => {
    processParticipantListData();
  }, [participationDetails]);

  useEffect(() => {
    if (viewButtonDetails) disableScroll();
    else enableScroll();
  }, [viewButtonDetails]);

  const capitalizeFirstLetter = (input: any) => {
    if (input !== "") {
      let words = input.split(" ");
      let CapitalizedWords: any = [];
      words.forEach((element: any) => {
        CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
      });
      return CapitalizedWords.join(" ");
    }
  };

  const formatLocation = (location: any) => {
    let newArray = location.map((i: any) => {
      return {
        ...i,
        city: capitalizeFirstLetter(i.city),
        district: capitalizeFirstLetter(i.district),
        state: capitalizeFirstLetter(i.state),
        subDistrict: capitalizeFirstLetter(i.subDistrict)
      };
    });
    return newArray;
  };

  const participantDetailsCSV = async () => {
    let isLocation = await LocalStorage.getStorage("noneLocationListSet");
    let isBadge = await LocalStorage.getStorage("noAssignedBadgesSelected");
    const participantListCSVResponse = await ApiClient.post(
      participationDetailsEndPoints.downloadCsv(),
      {
        programId: programDetails?.program?.id,
        userType: "TRAINEE",
        badgeId: commonData.badgeIds,
        locations: formatLocation(commonData.locations),
        disableLocationFilter: isLocation === null ? false : isLocation,
        disableBadgeFilter: isBadge === null ? false : isBadge
      }
    );
    setLoading(false);
    return participantListCSVResponse;
  };

  const downloadCsvList = async () => {
    setLoading(true);
    const data = await participantDetailsCSV();
    auxiliaryMethods.handleDownload(data, `${programDetails?.program?.name}-Participants`);
    auxiliaryMethods.updateTelemetryEvent("Download Participant List");
  };

  const processParticipantListData = () => {
    setParticipantHeaders(participationDetails?.participantListData?.badgeTypeDtoSet);
    if (participationDetails?.participantListData?.userRoleDetailsProgramDtos) {
      let data = participationDetails?.participantListData?.userRoleDetailsProgramDtos;
      const formattedData = formatData(data);
      let headerData = participationDetails?.participantListData?.badgeTypeDtoSet;
      const formattedHeaderData = formatHeaderData(headerData);
      setParticipantData(formattedData);

      setLoading(false);
    }
  };

  const formatData = (data: any) => {
    data?.forEach((participationList: any, index: number) => {
      participationList.serialNo = `${index + 1}.`;
      participationList.formattedBaseLocation =
        auxiliaryMethods.fromatBaseLocation(participationList);
      participationList.verifiedRole = auxiliaryMethods.getVerifiedRole(
        participationList.roleDetails
      );
      participationList.verifiedQualification = auxiliaryMethods.getVerifiedQualification(
        participationList.qualificationTitle
      );
      participationList?.badgeDetails?.forEach((badge: any, index: number) => {
        participationList[badge.badgeType] = auxiliaryMethods.getAssignedBadges(
          participationList?.badgeDetails
        );
      });
    });
    return data;
  };

  const formatHeaderData = (data: any) => {
    let columnsList = [...columns];
    columnsList.splice(7);
    data?.forEach((participationBadge: any, index: number) => {
      columnsList.push({
        Header: `${participationBadge.badgeType} Badge`,
        accessor: participationBadge.badgeType,
        width: 150,
        maxWidth: 150,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const newBadgeData: any = null;
          const newbadgeList: any = [];
          participant.badgeDetails?.forEach((badge: any, index: number) => {
            if (participant.badgeDetails[index]?.badgeTypeId == participationBadge.badgeTypeId) {
              newBadgeData == participant.badgeDetails[index];
              newbadgeList.push(participant.badgeDetails[index]);
            }
          });
          const assignedBadges = auxiliaryMethods.getAssignedBadges(newbadgeList) || "None";
          return cellData("assignedBadges", style, assignedBadges);
        }
      });
    });
    setUpdatedColumns(columnsList);
    return columnsList;
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, participant?.serialNo);
        }
      },

      {
        Header: "Name",
        accessor: "name",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(participant)}>
                {participant?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Base Location",
        accessor: "formattedBaseLocation",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(participant) !== "None" ? (
                <CustomTooltip
                  hoverValue={participant.formattedBaseLocation}
                  textValue={auxiliaryMethods.getUserFirstLocationName(participant)}
                />
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Establishment Name",
        accessor: "establishmentName",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          let establishmentName: string = participant?.establishmentName;
          if (participant?.establishmentName === "") {
            establishmentName = "None";
          }
          return cellData("establishmentName", style, establishmentName);
        }
      },
      {
        Header: "Verified Role",
        accessor: "verifiedRole",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          return cellData("verifiedRole", style, participant?.verifiedRole);
        }
      },
      {
        Header: "Verified Qualification",
        accessor: "verifiedQualification",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          return cellData("verifiedQualification", style, participant?.verifiedQualification);
        }
      },
      {
        Header: "Attestation Received",
        accessor: "attestations",
        width: 120,
        maxWidth: 120,
        Cell: ({ row: { original: participantAttestation } }: { row: { original: any } }) => {
          const style = {};
          return cellData("attestationReceived", style, participantAttestation?.attestations);
        }
      }
    ],
    []
  );

  const getFilterLocations = async () => {
    try {
      const programId = programDetails.program.id;
      const payload = {
        programIds: [programId],
        locationType: "BASE_LOCATION"
      };
      const response = await ApiClient.post(
        participationDetailsEndPoints.getFilterLocation(programId),
        {}
      );
      const responseData = response?.response?.programData[programId];
      responseData?.forEach((data: any, index: number) => {
        data.serialNo = `${index + 1}`;
        data.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(data);
        data.isSelected = false;
        data.bundleCompletedDate = dataHelper.formatDate(data.bundleCompletedDate);
      });
      setLocationList(responseData);
      // filterLocationByGroups(responseData);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      toast.error("Something went wrong.");
      setLocationList([]);
    }
  };

  const getBadgeRoles = async () => {
    try {
      const programId = programDetails.program.id;
      const response = await ApiClient.get(
        qualificationPackEndPoints.getBadgeRoles(),

        { id: programId }
      );
      const responseData = response?.response[programId];
      responseData?.forEach((data: any, index: number) => {
        data.serialNo = `${index + 1}.`;
        // data.isSelected = false;
      });
      setBadgeRoleList(responseData);
    } catch (error) {
      toast.error("Something went wrong.");
      setBadgeRoleList([]);
    }
  };

  const openFilterHandler = () => {
    setOpenFilter(true);
    if (selectedLocationList.length === 0 && selectedBadgeRoleList.length === 0) {
      getFilterLocations();
      getBadgeRoles();
    } else {
      // Object overwritten, (doubt)
      // checkArraysForChanges(locationList, badgeRoleList);
      const listLoc = [...locationList];
      setLocationList(listLoc);
    }
  };

  const handleOpenFilterModal = () => {
    setShowFilterModal(true);
    // getFilterLocations();
    // getBadgeRoles();
    openFilterHandler();
  };

  const getSelectedRoleBadgeList = (list: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    // setBadgeRoleListButton(newList); // added while applied button onClick
    let result = newList.map((a: any) => a.badgeId);
    if (result.length) setSelectedBadgeRoleList(result);
    setCommonData({
      ...commonData,
      badgeIds: result
    });
  };

  const checkListHandler = (data: any, checkedListItems: any) => {
    const newCheckedList = checkedListItems.filter(
      (item: any) =>
        // item.includes(data.serialNo) ? false : true
        item !== data.serialNo
    );
    setCheckedList(newCheckedList);
  };

  const getSelectedLocationList = (list: any, globalList: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    setSelectedLocationList(list);
    let result = locationDataConvert(newList);
    setCommonData({
      ...commonData,
      locations: result
    });
  };

  const locationDataConvert = (newList: any) => {
    return newList.map(({ city, subDistrict, district, state, country }: any) => ({
      city,
      subDistrict,
      district,
      state,
      country
    }));
  };

  const filterListToTrue = (list: any) => {
    return list.filter((x: any) => x.isSelected === true);
  };

  const convertBadgeIdsToNameHandler = (data: any) => {
    const badgeIdsList = data.badgeIds;
    const badgesList = data.badgeList;
    const badgeNamesList = badgesList
      ?.filter((item: any) => badgeIdsList?.includes(item.badgeId))
      .map((element: any) => element.name);
    setSelectedBadgeNameList(badgeNamesList);
  };

  const filteredDataHandler = (data: any) => {
    convertBadgeIdsToNameHandler(data);
    setLocationNameList(data.locationNameList);
    setCommonData({
      badgeIds: data.badgeIds,
      locations: data.locations,
      isNoneLocationSet: data.isNoneLocationSet,
      isNoBadgeAssignSet: data.isNoBadgeAssignSet
    });
  };

  const dataFilteredHandler = async (data: any) => {
    if (data.isFiltered === true) {
      commonData.badgeIds.length === 0 &&
      commonData.locations.length === 0 &&
      data.isNoneLocationSet === null &&
      data.isNoBadgeAssignSet === null
        ? [setIsLocationFilterApplied(false), setIsBadgeFilterApplied(false)]
        : null;

      const locList = [...locationList];
      let newList = filterListToTrue(locList);
      setLocationListSecondry(locList);
      setLocationListButton(globalList);
      const badgeList = [...badgeRoleList];
      let newBadgeList = filterListToTrue(badgeList);
      setCheckedListCopyBadges(newBadgeList);
      setNoneLocationSelected(data.isNoneLocationSet === null ? false : true);
      setNoAssignedBadgesSelected(data.isNoBadgeAssignSet === null ? false : true);
      filteredDataHandler({
        ...commonData,
        locations: newList,
        isNoneLocationSet: data.isNoneLocationSet,
        isNoBadgeAssignSet: data.isNoBadgeAssignSet,
        badgeList: badgeRoleList,
        locationNameList: globalList
      });
      setLoading(true);
    }
  };

  const removeLocationList = (data: any) => {
    // find element in the all locations list, locationListSecondry: true list
    const list = locationListSecondry.filter((item: any) =>
      item.formattedBaseLocation.includes(data)
    );
    let tempLocations = commonData.locations;
    let filteredData = tempLocations.filter((k) => !k.formattedBaseLocation.includes(data));
    setCommonData({ ...commonData, locations: filteredData });
    // mark the removed element it as false
    let checkList = [...checkedList];
    list.forEach((element: any) => {
      element.isSelected = false;
      const index = checkList.indexOf(element.serialNo);
      let data = checkList.splice(index, 1);
    });
    setCheckedList([...checkList]);
    // remove the location from display
    const updatedLocList = locationListButton.filter((item: any) => item !== data);
    setLocationListButton(updatedLocList);
    LocalStorage.setStorage("globalNameList", updatedLocList);
    let resultFinalBadgeRole = [...badgeRoleListButton];
    filteredDataHandler({
      locations: filterListToTrue(locationListSecondry),
      badgeIds: resultFinalBadgeRole.map((a: any) => a.badgeId),
      isNoneLocationSet: noneLocationSelected,
      isNoBadgeAssignSet: noAssignedBadgesSelected,
      locationNameList: updatedLocList,
      badgeList: badgeRoleList
    });
    getParticipantData(filteredData);
  };

  const removeFilteredListButton = (data: any, value: string) => {
    data.isSelected = !data.isSelected;
    let resultBadge = [];
    let result = [];
    let resultFinalLocation = [...locationListButton];
    let resultFinalBadgeRole = [...badgeRoleListButton];
    let resultFinalBadgeRoleTemp = [];
    if (value === "location") {
      // Updating only when location is removed from the filter list.
      let newList = locationListButton.filter((x: any) => x.isSelected === true);
      setLocationListButton(newList);
      result = locationDataConvert(newList);
      // setResultLocation(result); // previous result
      resultFinalLocation = result;
      checkListHandler(data, checkedList);
      resultFinalBadgeRoleTemp = resultFinalBadgeRole.map((a: any) => a.badgeId);

      filteredDataHandler({
        locations: resultFinalLocation,
        badgeIds: resultFinalBadgeRoleTemp,
        isNoneLocationSet: noneLocationSelected,
        noAssignedBadgesSelected: noAssignedBadgesSelected,
        locationNameList: newList
      });
    }

    if (value === "badgeRole") {
      // Updating only when a badge is removed from the filter list.
      let temp = { ...commonData };
      let newListBadge = badgeRoleListButton.filter((x: any) => x.isSelected === true);
      setBadgeRoleListButton(newListBadge);
      resultBadge = newListBadge.map((a: any) => a.badgeId);
      resultFinalBadgeRole = resultBadge;
      // setResultBadgeRole(resultBadge);
      let newArray = temp.badgeIds.filter((i) => i !== data.badgeId);
      setSelectedBadgeRoleList(newArray);
      setCommonData({ ...commonData, badgeIds: newArray });
      filteredDataHandler({
        // locations: resultFinalLocation,
        locations: filterListToTrue(locationListSecondry),
        badgeIds: resultFinalBadgeRole,
        isNoneLocationSet: noneLocationSelected,
        isNoBadgeAssignSet: noAssignedBadgesSelected,
        // new global list names
        locationNameList: locationListButton,
        badgeList: badgeRoleList
      });
      getParticipantData(undefined, resultFinalBadgeRole);
      // setLocationListSecondry(globalList);
    }

    if (value === "noLocation") {
      setNoneLocationSelected(false);
      filteredDataHandler({
        locations: resultFinalLocation,
        badgeIds: resultFinalBadgeRole.map((a: any) => a.badgeId),
        isNoneLocationSet: false,
        isNoBadgeAssignSet: noAssignedBadgesSelected,
        locationNameList: [],
        badgeList: badgeRoleList
      });
      LocalStorage.removeStorage("noneLocationListSet");
      getParticipantData();
    }

    if (value === "noAssignedBadge") {
      // setLocationListSecondry(globalList);
      setNoAssignedBadgesSelected(false);
      filteredDataHandler({
        // locations: resultFinalLocation,
        locations: filterListToTrue(locationListSecondry),
        badgeIds: resultFinalBadgeRole,
        isNoneLocationSet: noneLocationSelected,
        isNoBadgeAssignSet: false,
        locationNameList: locationListButton,
        badgeList: []
      });
      LocalStorage.removeStorage("noAssignedBadgesSelected");
      getParticipantData();
    }
  };

  const removeLocationNoneSelectedLocalStorage = () => {
    setNoneLocationSelected(false);
    setNoAssignedBadgesSelected(false);
    LocalStorage.removeStorage("noneLocationListSet");
    LocalStorage.removeStorage("noAssignedBadgesSelected");
    LocalStorage.removeStorage("globalNameList");
  };

  const clearAllFilter = (isModalClosed: boolean = true) => {
    isModalClosed ? setLoading(true) : null;

    const locList = locationList.forEach((element: any) => {
      element.isSelected = false;
    });
    const badgeList = badgeRoleList.forEach((element: any) => {
      element.isSelected = false;
    });

    setLocationList(locList);
    setBadgeRoleList(badgeList);

    // setLoading(true);
    setSelectedLocationList([]);
    setSelectedBadgeRoleList([]);
    setLocationListButton([]);
    setBadgeRoleListButton([]);
    filteredDataHandler({
      locations: [],
      badgeIds: [],
      isNoneLocationSet: null,
      isNoBadgeAssignSet: null
    });
    setExpandedList([]);
    setCheckedList([]);
    setIsLocationFilterApplied(false);
    setIsBadgeFilterApplied(false);
    removeLocationNoneSelectedLocalStorage();

    setCommonData({
      ...commonData,
      badgeIds: [],
      locations: []
    });

    // getFilterLocations();
    // getBadgeRoles();
    getParticipantData([], []);

    setCheckedListCopy([]);
    setCheckedListCopyBadges([]);
  };

  const filterButtonsList = () => (
    <>
      {(isLocationFliterApplied || isBadgeFliterApplied) &&
        (noneLocationSelected ||
          noAssignedBadgesSelected ||
          locationListButton.length !== 0 ||
          badgeRoleListButton.length !== 0) && (
          <View style={styles.filteredButtonsContainer}>
            {isLocationFliterApplied &&
              locationListButton?.map((prop, key) => {
                return (
                  <View key={key}>
                    <CancelButton
                      data={prop}
                      functionOfButtonProp={"location"}
                      functionOfButton={() => removeLocationList(prop)}
                      // textValue={prop.formattedBaseLocation}
                      textValue={prop}
                    />
                  </View>
                );
              })}
            {isBadgeFliterApplied &&
              badgeRoleListButton?.map((prop, key) => {
                return (
                  <View key={key}>
                    <CancelButton
                      data={prop}
                      functionOfButtonProp={"badgeRole"}
                      functionOfButton={() => removeFilteredListButton(prop, "badgeRole")}
                      textValue={prop.name}
                    />
                  </View>
                );
              })}
            {noneLocationSelected && (
              <CancelButton
                data={[]}
                functionOfButtonProp={"noLocation"}
                functionOfButton={() => removeFilteredListButton([], "noLocation")}
                textValue={"No Location"}
              />
            )}
            {noAssignedBadgesSelected && (
              <CancelButton
                data={[]}
                functionOfButtonProp={"noAssignedBadge"}
                functionOfButton={() => removeFilteredListButton([], "noAssignedBadge")}
                textValue={"No Assigned Badges"}
              />
            )}
            {(isLocationFliterApplied || isBadgeFliterApplied) &&
              (noneLocationSelected ||
                noAssignedBadgesSelected ||
                locationListButton.length !== 0 ||
                badgeRoleListButton.length !== 0) && (
                <TouchableOpacity onPress={() => clearAllFilter(true)}>
                  <Text
                    testId="testId"
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.Small}
                    style={[styles.clearAllButtonText]}
                  >
                    {"Clear All"}
                  </Text>
                </TouchableOpacity>
              )}
          </View>
        )}
    </>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : participantData.length === 0 ? (
        <View>
          <View style={styles.searchBarContainer}>
            <View pointerEvents={"none"}>
              <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
            </View>
            <View style={styles.filterIconContainer}>
              <TouchableOpacity onPress={handleOpenFilterModal}>
                <View style={styles.filterContainer}>
                  <Icon name={IconNames.filter} customStyle={styles.iconStyleFilter} />
                </View>
              </TouchableOpacity>
            </View>
          </View>
          <View>
            <View>{filterButtonsList()}</View>
          </View>
          <Text
            fontWeight={FontWeight.Regular}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.noData}
          >
            {errormessage}
          </Text>
        </View>
      ) : (
        <View
          style={leftSidePanelCollapse ? styles.parentTableContainer : styles.parentTableContainer2}
        >
          <Table
            columns={updatedColumns}
            showFilter={true}
            handleOpenFilterModal={handleOpenFilterModal}
            filterButtons={<View>{filterButtonsList()}</View>}
            data={participantData}
            eventAnalytics="ParticipantProg"
            noDataMessage="No Participant(s) under this Program."
            download={
              <TouchableOpacity onPress={() => downloadCsvList()}>
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name={IconNames.downloadList}
                    customStyle={styles.iconStyle}
                    onPress={() => downloadCsvList()}
                  />
                  <View>
                    <Text
                      testId="testId"
                      fontWeight={FontWeight.Bold}
                      textSize={TextSize.Small}
                      style={[
                        styles.textAlignment,
                        {
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }
                      ]}
                    >
                      {"Download"}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            }
          />
          {viewButtonDetails && (
            <View>
              <SocionModal
                modalVisible={viewButtonDetails}
                setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                component={
                  <ViewUserDetails
                    onClose={() => {
                      setViewButtonDetails(false);
                    }}
                    selectedUserDetails={selectedUserDetails}
                    piiFilter={true}
                  />
                }
              />
            </View>
          )}
        </View>
      )}
      {showFilterModal && (
        <>
          <SocionModal
            modalVisible={showFilterModal}
            setModalVisible={() => setShowFilterModal(!showFilterModal)}
            component={
              <FilterModal
                globalList={globalList}
                setGlobalList={setGlobalList}
                locationList={locationList}
                badgeRoleList={badgeRoleList}
                setCheckedList={setCheckedList}
                getSelectedLocationList={getSelectedLocationList}
                setExpandedList={setExpandedList}
                programDetails={programDetails}
                expandedList={expandedList}
                checkedList={checkedList}
                dataFilteredHandler={dataFilteredHandler}
                setShowFilterModal={setShowFilterModal}
                setModalVisible={() => setOpenFilter(!openFilter)}
                setIsLocationFilterApplied={setIsLocationFilterApplied}
                // updatedFilteredLocationList={updatedFilteredLocationList}
                getSelectedRoleBadgeList={getSelectedRoleBadgeList}
                setIsBadgeFilterApplied={setIsBadgeFilterApplied}
                getParticipantAttestationData={getParticipantData}
                allExpandedNodes={allExpandedNodes}
                setAllExpandedNodes={setAllExpandedNodes}
                seterrormessage={seterrormessage}
                eventAnalytics={"Participant_List"}
                setCheckedListCopy={setCheckedListCopy}
                checkedListCopy={checkedListCopy}
                setCheckedListCopyBadges={setCheckedListCopyBadges}
                checkedListCopyBadges={checkedListCopyBadges}
                selectedBadgeRoleList={selectedBadgeRoleList}
                setBadgeRoleListButton={setBadgeRoleListButton}
                noAssignedBadgesSelected={noAssignedBadgesSelected}
              />
            }
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  sessionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  noDataContainer: {
    height: 100
  },
  alignNoDataFound: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    padding: 18
  },
  filteredButtonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 18,
    paddingTop: 0
  },
  clearAllButtonText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    textDecorationLine: "underline"
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 18
  },
  customWidthStyle: {
    width: 400
  },
  filterIconContainer: {
    marginLeft: 10
  },
  filterContainer: {
    width: 50,
    height: 42,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.cordioTaupeLight4,
    justifyContent: "center",
    alignItems: "center"
  },
  iconStyleFilter: {
    color: colorPallete.textBlack,
    fontSize: 14
  },
  noData: {
    padding: 25,
    fontSize: 14,
    fontFamily: FontFamily.Bold,
    display: "flex",
    color: colorPallete.cordioTaupe
  },
  parentTableContainer: {
    display: "flex",
    width: "calc(100vw - 100px)"
  },
  parentTableContainer2: {
    display: "flex",
    width: "calc(100vw - 290px)"
  }
});
