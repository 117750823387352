import { combineReducers } from "redux";
import profileReducer from "@socion-cordio/common/src/modules/profile/reducers/reducer";
import { IFluxStandardAction, IState } from "./interfaces";
import loginReducer from "@socion-cordio/common/src/modules/login/reducers/loginReducer";
import signupReducer from "@socion-cordio/common/src/modules/signup/reducers/signupReducer";
import programReducer from "@socion-cordio/common/src/modules/program/reducers/reducer";
import TopicReducer from "@socion-cordio/common/src/modules/topic/reducers/reducer";
import userRolesProgramReducer from "@socion-cordio/common/src/modules/user-role/reducers/reducer";
import sessionsReducer from "@socion-cordio/common/src/modules/session/reducers/reducer";
import participationDetailsReducer from "@socion-cordio/common/src/modules/participationDetails/reducers/participationDetailsReducer";
import qualificationPackReducer from "@socion-cordio/common/src/modules/qualificationPacks/reducers/reducer";
import BadgesReducer from "@socion-cordio/common/src/modules/badges/reducers/reducer";
import EntitiesReducer from "@socion-cordio/common/src/modules/registry/entity/reducers/reducer";

// export default combineReducers({ profile: profileReducer });
const appReducer = combineReducers({
  profile: profileReducer,
  login: loginReducer,
  signup: signupReducer,
  programs: programReducer,
  topics: TopicReducer,
  userRolesProgram: userRolesProgramReducer,
  completeSessionData: sessionsReducer,
  participationDetails: participationDetailsReducer,
  bundleData: qualificationPackReducer,
  badges: BadgesReducer,
  entities: EntitiesReducer
});
const rootReducer = (state: IState, action: IFluxStandardAction<any>) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
