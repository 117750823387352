import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TableThird from "@socion-cordio/web/src/components/molecules/table/tableThird";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { participationDetailsEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import Tooltip from "react-tooltip-lite";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import _ from "lodash";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {
  sessionDetails?: any;
  getSessionParticipationList?: Function;
  setLoadingSessionParticipation?: Function;
  isLoadingSessionParticipation?: boolean;
  getTableColumnsCount?: Function;
}
const SINGLE_SCAN = "SINGLE_SCAN";
const MULTI_SCAN = "MULTI_SCAN";
const SCAN_IN = "Scan In";
const SCAN_OUT = "Scan Out";
const LIVE_SESSION = "Live Session";

export default function ViewSessionParticipantPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const {
    sessionDetails,
    getSessionParticipationList,
    setLoadingSessionParticipation,
    isLoadingSessionParticipation,
    getTableColumnsCount
  } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [sessionParticipantList, setSessionParticipantList] = useState([]);
  const [sessionParticipantData, setSessionParticipantData] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [displayRefreshButton, setDisplayRefreshButton] = useState(true);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [scanInTimeCount, setScanInTimeCount] = useState(0);
  const [scanOutTimeCount, setScanOutTimeCount] = useState(0);
  const [isAccessPresent, setIsAccessPresent] = useState(false);

  const location: any = useLocation();
  useEffect(() => {
    validateUserAccess();
  }, [sessionDetails, location?.pathname]);

  useEffect(() => {
    if (viewButtonDetails) disableScroll();
    else enableScroll();
  }, [viewButtonDetails]);

  const validateUserAccess = async () => {
    let userData: any = await LocalStorage.getStorage("user");
    let tempUserRolesData: any = await LocalStorage.getStorage("userRoles");
    const userRoles = tempUserRolesData?.userRolesData?.response;
    const { members } = sessionDetails;
    const isUserPartOfSession = members?.filter((item: any) => item.userId === userData.userId);
    const isAccessPresent =
      userData?.userId === sessionDetails?.sessionCreatorProfile?.userId ||
      userRoles.includes("ENTITY_ADMIN") ||
      userRoles.includes("PROGRAM_ADMIN") ||
      isUserPartOfSession.length > 0;
    if (sessionDetails !== null && isAccessPresent) {
      getSessionParticipantDetails(false);
      validateRefreshButtonHandler();
      setIsAccessPresent(true);
    } else {
      setSessionParticipantList([]);
      setLoading(false);
      setIsAccessPresent(false);
    }
  };

  const getSessionParticipantDetails = async (isRefreshClicked?: boolean) => {
    try {
      setLoading(true);
      const sessionId = sessionDetails?.sessionId;
      const newBody = {
        sessionIds: [sessionId],
        userType: "TRAINEE",
        sessionStatus: "UPCOMING"
      };
      const response = await ApiClient.post(
        sessionPackEndPoints.getSessionParticipationList(),
        newBody
      );
      if (response?.responseCode === HTTP_STATUS_CODES.ok) {
        setSessionParticipantData(response?.response);
        const scanMode = response?.response[0]?.sessionScanModes[sessionId];
        let list = response?.response[0]?.sessionData[sessionId];
        list = getFormattedList(list);
        // getSessionParticipationList(list);
        checkScanOutPresent(list);
        setLoadingSessionParticipation(false);
        isRefreshClicked
          ? formatHeaderData(
              response?.response[0]?.sessionData[sessionId].badgeData,
              scanMode,
              isRefreshClicked
            )
          : checkListModes(response.response[0], sessionId);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoadingSessionParticipation(false);
      setFailed(true);
      setLoading(false);
    }
  };

  const getFormattedList = (list: any) => {
    let badgeDataList: any = [];
    let uniqueBadgeDataList;
    // By default sort by scan out time, which will display user with no scanout time first
    // let newList: any = _.orderBy(list, "scanOutTime", "desc");
    list.forEach((session: any, index: number) => {
      session.serialNo = `${index + 1}.`;
      session.formattedLocation =
        auxiliaryMethods.fromatBaseLocation(session) !== ""
          ? auxiliaryMethods.fromatBaseLocation(session)
          : "None";
      session.verifiedRole =
        session?.roleDetails.length !== 0
          ? auxiliaryMethods.getVerifiedRole(session?.roleDetails)
          : "None";
      session.verifiedQualification =
        session?.qualificationTitle.length !== 0
          ? auxiliaryMethods.getVerifiedQualification(session?.qualificationTitle)
          : "None";
      session.assignedBadges =
        session?.badgeDetails.length !== 0
          ? auxiliaryMethods.getAssignedBadges(session?.badgeDetails)
          : "None";
      session.scanInTime = dataHelper.formatDateTimeUtc(session?.scanInTime);
      session.scanOutTime = dataHelper.formatDateTimeUtc(session?.scanOutTime);
      session?.badgeDetails?.forEach((badge: any, index: number) => {
        session[badge.badgeType] = auxiliaryMethods.getAssignedBadges(session?.badgeDetails);
        badgeDataList.push({
          badgeType: badge.badgeType,
          badgeTypeId: badge.badgeTypeId
        });
      });
    });
    uniqueBadgeDataList = [
      ...new Map(badgeDataList.map((item: any) => [item["badgeTypeId"], item])).values()
    ];
    list.badgeData = uniqueBadgeDataList;
    return list;
  };

  const formatHeaderData = (data: any, scanMode: string, isRefreshClicked?: boolean) => {
    let columnsList = [...columns];
    if (scanMode === "SINGLE_SCAN") {
      columnsList.splice(6);
    } else {
      columnsList.splice(7);
    }
    // badgeData
    data?.forEach((participationBadge: any, index: number) => {
      columnsList.push({
        Header: `${participationBadge.badgeType} Badge`,
        accessor: participationBadge.badgeType,
        width: 170,
        maxWidth: 170,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const newBadgeData: any = null;
          const newbadgeList: any = [];
          topic.badgeDetails?.forEach((badge: any, index: number) => {
            if (topic.badgeDetails[index]?.badgeTypeId == participationBadge.badgeTypeId) {
              newBadgeData == topic.badgeDetails[index];
              newbadgeList.push(topic.badgeDetails[index]);
            }
          });
          const assignedBadges = auxiliaryMethods.getAssignedBadgesString(newbadgeList) || "None";
          // const assignedBadges = newbadgeList[0]?.badgeName || "None";
          // return cellData("badgeDetails", style, assignedBadges);
          return (
            <>
              {assignedBadges !== "None" ? (
                <Tooltip
                  styles={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inherit",
                    fontFamily: FontFamily.Regular,
                    fontSize: 12,
                    color: colorPallete.textBlack
                  }}
                  tipContentClassName=""
                  distance={10}
                  direction="bottom"
                  background={colorPallete.cordioBeigeLight1}
                  color="black"
                  padding={10}
                  content={
                    <View style={{ width: 250 }}>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.Small}
                        style={styles.fontStyles}
                      >
                        {assignedBadges}
                      </Text>
                    </View>
                  }
                >
                  {assignedBadges}
                </Tooltip>
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      });
    });
    isRefreshClicked ? setUpdatedColumns(columnsList) : null; //only on refresh click
    getTableColumnsCount(columnsList?.length);
    return columnsList;
  };

  // To only display button from live session view.
  // Disabling button when redirection from live session.
  const validateRefreshButtonHandler = () => {
    const checkPath = location?.state?.redirectionFrom;
    checkPath === "Completed Session"
      ? setDisplayRefreshButton(false)
      : setDisplayRefreshButton(true);
  };

  // Checks session data on the basis of scan mode,
  // If single scan mode, removing scan out time and
  // replacing scan in time header with Scan Time
  const checkListModes = (response: any, sessionId: number) => {
    const scanMode = response.sessionScanModes[sessionId];
    if (scanMode === "SINGLE_SCAN") {
      // const newCols = columns.splice(3, 1);
      const scanInCol = columns.filter((item: any) => item.Header === "Scan In");
      // console.log(scanInCol,"scanInCol",columns,"columns")
      if (scanInCol?.length > 0) {
        const newCols = columns.splice(3, 1);
        scanInCol[0].Header = "Scan";
        setUpdatedColumns(columns);
      } else {
        setUpdatedColumns(columns);
      }
    } else {
      setUpdatedColumns(columns);
    }
    let newColumnList = formatHeaderData(response.sessionData[sessionId].badgeData, scanMode);
    setUpdatedColumns(newColumnList);
  };

  // It checks the scanIn or scanOut number and filters the data accordingly
  // based on the path and clicked on scan in/out or session name in live/completed tab
  const checkScanOutPresent = (data: any) => {
    // delete data?.badgeData;
    if (
      location?.pathname ===
      `${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live/scanIn`
    ) {
      // show all data when on completed session name clicked
      if (location?.state?.isSessionNameClicked) {
        // setSessionParticipantList(data);
        sessionScanInOutCountHandler(data);
      } else {
        const filteredData = data?.filter((x: any) => x.scanInTime !== null);
        // setSessionParticipantList(filteredData);
        sessionScanInOutCountHandler(filteredData);
      }
    }
    if (
      location?.pathname ===
      `${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live/scanOut`
    ) {
      const filteredData = data?.filter((x: any) => x.scanOutTime !== (null || "Invalid date"));
      // setSessionParticipantList(filteredData);
      sessionScanInOutCountHandler(filteredData);
    }
    if (
      location?.pathname === `${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live`
    ) {
      // setSessionParticipantList(data);
      sessionScanInOutCountHandler(data);
      // setSessionParticipantList(DUMMY_OBJECT);
    }
    if (location?.pathname.includes("sessions")) {
      const list = location?.state?.completedSessionData;
      let listA = list?.filter((x: any) => x.scanOutTime !== null);
      listA = getFormattedList(listA);
      setSessionParticipantList(listA);
    }
  };

  const sessionScanInOutCountHandler = (data: any) => {
    getSessionParticipationList(data);
    let scanInCount = 0;
    let scanOutCount = 0;
    // By default sort by scan out time, which will display user with no scanout time first
    let newList: any = _.orderBy(data, "scanOutTime", "desc");
    newList?.forEach((session: any, index: number) => {
      session.serialNo = `${index + 1}.`;
      if (session?.scanInTime !== "Invalid date") {
        scanInCount++;
      }
      if (session?.scanOutTime !== "Invalid date") {
        scanOutCount++;
      }
    });
    setSessionParticipantList(newList);
    setScanInTimeCount(scanInCount);
    setScanOutTimeCount(scanOutCount);
  };

  const participantDetailsCSV = async (data: any) => {
    const participantListCSVResponse = await ApiClient.post(
      participationDetailsEndPoints.getParticipantListCSV(),
      {
        sessionIds: [sessionDetails.sessionId],
        userType: "TRAINEE",
        sessionStatus: data.sessionStatus,
        timezone: auxiliaryMethods.getLocalTimezone()
      }
    );
    return participantListCSVResponse;
  };

  const downloadCsvList = async (data: any) => {
    setLoadingSessionParticipation(true);
    location.state.redirectionFrom === LIVE_SESSION
      ? downloadCSVLiveSession()
      : downloadCSVCompletedSession();
  };

  const downloadCSVLiveSession = async () => {
    let result;
    location.state?.sessionScanMode === SINGLE_SCAN
      ? (result = await participantDetailsCSV({ sessionStatus: "UPCOMING" }))
      : location.state?.scanType === SCAN_IN
      ? (result = await participantDetailsCSV({ sessionStatus: "UPCOMING" }))
      : (result = await participantDetailsCSV({ sessionStatus: "COMPLETED" }));
    auxiliaryMethods.handleDownload(
      result,
      `${sessionDetails.sessionName}-${sessionDetails.sessionId}-Participants`
    );
    const session: any = {
      topicId: sessionDetails.topicInfo.topic.id,
      topicName: sessionDetails.topicInfo.topic.name,
      sessionId: sessionDetails.sessionId,
      sessionName: sessionDetails.sessionName,
      sessionStartDate: sessionDetails.sessionStartDate,
      sessionEndDate: sessionDetails.sessionEndDate
    };
    auxiliaryMethods.updateSessionParticipantTelemetryEvent("Download Participant List", session);
    setLoadingSessionParticipation(false);
  };

  const downloadCSVCompletedSession = async () => {
    let result;
    location.state?.sessionScanMode === SINGLE_SCAN
      ? (result = await participantDetailsCSV({ sessionStatus: "COMPLETED" }))
      : location.state?.scanType === SCAN_IN
      ? (result = await participantDetailsCSV({ sessionStatus: "UPCOMING" }))
      : (result = await participantDetailsCSV({ sessionStatus: "COMPLETED" }));
    auxiliaryMethods.handleDownload(
      result,
      `${sessionDetails.sessionName}-${sessionDetails.sessionId}-Participants`
    );
    const session: any = {
      topicId: sessionDetails.topicInfo.topic.id,
      topicName: sessionDetails.topicInfo.topic.name,
      sessionId: sessionDetails.sessionId,
      sessionName: sessionDetails.sessionName,
      sessionStartDate: sessionDetails.sessionStartDate,
      sessionEndDate: sessionDetails.sessionEndDate
    };
    auxiliaryMethods.updateSessionParticipantTelemetryEvent("Download Participant List", session);
    setLoadingSessionParticipation(false);
  };

  const refreshListHandler = (isRefreshClick?: boolean) => {
    getSessionParticipantDetails(isRefreshClick);
    setLoadingSessionParticipation(true);
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const getUserFirstLocationName = (data: any) => {
    let userLocation = "";
    if (data?.city !== "") {
      userLocation = data?.city;
      return userLocation;
    }
    if (data?.subDistrict !== "") {
      userLocation = data?.subDistrict;
      return userLocation;
    }
    if (data?.district !== "") {
      userLocation = data?.district;
      return userLocation;
    }
    if (data?.state !== "") {
      userLocation = data?.state;
      return userLocation;
    }
    if (data?.country !== "") {
      userLocation = data?.country;
      return userLocation;
    }
    return "None";
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        style,
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        }
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, session?.serialNo);
        }
      },
      {
        Header: "Participant Name",
        accessor: "name",
        width: 175,
        maxWidth: 175,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(session)}>
                {session?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Scan In",
        accessor: "scanInTime",
        width: 170,
        maxWidth: 170,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const scanIn = dataHelper.formatDateTimeUtc(session?.scanInTime);
          if (scanIn === "Invalid date") {
            const scanIn = "None";
            return cellData("scanInTime", style, scanIn);
          } else {
            return cellData("scanInTime", style, session?.scanInTime);
          }
        }
      },
      {
        Header: "Scan Out",
        accessor: "scanOutTime",
        width: 170,
        maxWidth: 170,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const scanOut = dataHelper.formatDateTimeUtc(session?.scanOutTime);
          if (scanOut === "Invalid date") {
            const scanOut = "None";
            return cellData("scanOutTime", style, scanOut);
          } else {
            return cellData("scanOutTime", style, session?.scanOutTime);
          }
        }
      },
      {
        Header: "Base Location",
        accessor: "formattedLocation",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          return (
            <>
              {getUserFirstLocationName(session) !== "None" ? (
                <Tooltip
                  styles={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inherit",
                    fontFamily: FontFamily.Regular,
                    fontSize: 12,
                    color: colorPallete.textBlack
                  }}
                  tipContentClassName=""
                  distance={10}
                  direction="bottom"
                  background={colorPallete.cordioBeigeLight1}
                  color="black"
                  padding={10}
                  content={
                    <View style={{ width: 250 }}>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.Small}
                        style={{
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }}
                      >
                        {session?.formattedLocation}
                      </Text>
                    </View>
                  }
                >
                  {getUserFirstLocationName(session)}
                </Tooltip>
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Establishment Name",
        accessor: "establishmentName",
        width: 180,
        maxWidth: 180,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const establishment = session?.establishmentName ? session?.establishmentName : "None";
          // return cellData("establishment", style, establishment);
          return (
            <>
              {establishment !== "None" ? (
                <Tooltip
                  styles={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inherit",
                    fontFamily: FontFamily.Regular,
                    fontSize: 12,
                    color: colorPallete.textBlack
                  }}
                  tipContentClassName=""
                  distance={10}
                  direction="bottom"
                  background={colorPallete.cordioBeigeLight1}
                  color="black"
                  padding={10}
                  content={
                    <View style={{ width: 250 }}>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.Small}
                        style={styles.fontStyles}
                      >
                        {establishment}
                      </Text>
                    </View>
                  }
                >
                  {establishment}
                </Tooltip>
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Assisted Scan",
        accessor: "Assisted Scan",
        width: 130,
        maxWidth: 130,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const assistedScan = session?.assistedScan?.assistedScan ? "Yes" : "No";
          return cellData("email", style, assistedScan);
        }
      }
    ],
    []
  );

  return (
    <View style={styles.mainContainer}>
      {loading && isLoadingSessionParticipation ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          {sessionParticipantList.length === 0 ? (
            <View style={styles.refreshContainer}>
              <View style={[styles.flexDirectionRow, styles.refreshSubContainer]}>
                {location?.state?.completed ? null : (
                  <>
                    {isAccessPresent && (
                      <TouchableOpacity
                        style={styles.flexDirectionRow}
                        onPress={() => refreshListHandler(false)}
                      >
                        <Icon
                          name={IconNames.refresh}
                          customStyle={styles.iconStyle}
                          onPress={() => refreshListHandler(false)}
                        />
                        <View>
                          <Text
                            testId="testId"
                            fontWeight={FontWeight.Bold}
                            textSize={TextSize.Small}
                            style={[
                              styles.textAlignment,
                              {
                                fontFamily: FontFamily.Regular,
                                fontSize: 12
                              }
                            ]}
                          >
                            {t("table:refreshList")}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </>
                )}
              </View>
              <Text
                testId="testId"
                fontWeight={FontWeight.Bold}
                textSize={TextSize.Small}
                style={[styles.alignNoDataFound]}
              >
                {isAccessPresent
                  ? "No Participant(s) under this Session."
                  : "Session Members only can access the Participants list."}
              </Text>
            </View>
          ) : (
            <TableThird
              columns={updatedColumns}
              data={sessionParticipantList}
              isPaginationReqdForTable={false}
              searchBarCustomStyle={{ width: 200 }}
              displayScanInOutCount={{
                scanInTimeCount,
                scanOutTimeCount,
                isSingleScan: sessionDetails?.sessionScanMode === "SINGLE_SCAN" ? true : false
              }}
              noData={
                isAccessPresent
                  ? "No Participant(s) under this Session."
                  : "Session Members only can access the Participants list."
              }
              refreshList={
                location?.state?.completed === true ? null : (
                  <>
                    {isAccessPresent && (
                      <TouchableOpacity onPress={() => {}}>
                        <Icon
                          name={IconNames.refresh}
                          customStyle={styles.iconStyle}
                          onPress={() => refreshListHandler(true)}
                        />
                      </TouchableOpacity>
                    )}
                  </>
                )
              }
              numberOfLinesHeader={1}
              tooltipForHeaderLengthCount={19}
              refreshListHandler={() => refreshListHandler(true)}
              displayRefreshButton={isAccessPresent && displayRefreshButton}
              download={
                <TouchableOpacity onPress={() => downloadCsvList(sessionParticipantList)}>
                  <View style={{ flexDirection: "row" }}>
                    <Icon
                      name={IconNames.downloadList}
                      customStyle={styles.iconStyle}
                      onPress={() => downloadCsvList(sessionParticipantList)}
                    />
                    <View>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Bold}
                        textSize={TextSize.Small}
                        style={[
                          styles.textAlignment,
                          {
                            fontFamily: FontFamily.Regular,
                            fontSize: 12
                          }
                        ]}
                      >
                        {t("table:download")}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              }
            />
          )}
        </View>
      )}

      {viewButtonDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonDetails}
            setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
            component={
              <ViewUserDetails
                onClose={() => {
                  setViewButtonDetails(false);
                }}
                selectedUserDetails={selectedUserDetails}
                piiFilter={true}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%"
  },
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  refreshContainer: {
    height: 100
  },
  refreshSubContainer: {
    justifyContent: "flex-end",
    marginHorizontal: 40
  },
  flexDirectionRow: {
    flexDirection: "row"
  },
  alignNoDataFound: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    padding: 18,
    marginTop: 18
  },
  fontStyles: {
    fontFamily: FontFamily.Regular,
    fontSize: 12
  }
});
