import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useTranslation } from "react-i18next";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";

interface Props {
  programDetails: any;
  getSelectedRoleBadgeStatusList: Function;
  dataFilteredHandler: Function;
  setModalVisible: any;
  updatedBadgesStatusList: Function;
  setErrorFilterMessage?: Function;
  setIsNoBadgeSelected?: Function;
  badgeStatusObject?: any[];
}

const BadgeStatus = forwardRef((props: Props, ref): ReactElement => {
  const { t } = useTranslation();
  const {
    programDetails,
    getSelectedRoleBadgeStatusList,
    dataFilteredHandler,
    setModalVisible,
    updatedBadgesStatusList,
    setErrorFilterMessage,
    setIsNoBadgeSelected,
    badgeStatusObject
  } = props;
  const [selectedList, setSelectedList] = useState([]);
  const [badgeRoleListsArray, setBadgeRoleListsArray] = useState([]);
  const [noBadgesSelect, setNoBadgesSelect] = useState(false);
  const [displayBadgeSwitch, setDisplayBadgeSwitch] = useState(true);
  const [badgeRoleStatusArray, setBadgeRoleStatusArray] = useState([]);

  useEffect(() => {
    setBadgeRoleStatusArray(badgeStatusObject);
  }, [badgeStatusObject]);

  useEffect(() => {
    updatedBadgesStatusList(badgeStatusObject);
  }, [selectedList]);

  const selectedCheckBoxHandler = (data: any) => {
    data.isSelected = !data.isSelected;
    checkSelectedList(data);
    setErrorFilterMessage(false);
  };

  useEffect(() => {
    checkNoAssignedBagedHandler();
  }, []);

  const checkNoAssignedBagedHandler = async () => {
    const check = await LocalStorage.getStorage("noAssignedBadgesSelected");
    check
      ? [setNoBadgesSelect(true), setIsNoBadgeSelected(true)]
      : [setNoBadgesSelect(false), setIsNoBadgeSelected(false)];
  };

  const checkSelectedList = (data: any) => {
    setNoBadgesSelect(false);
    removeNoAssignedBadgesLocalStorage();
    let list: any = [...selectedList];
    let isPresent = selectedList.filter((x: any) => x.serialNo === data.serialNo);
    if (isPresent.length !== 0) {
      let removeList = selectedList.filter((x: any) => x.serialNo !== data.serialNo);
      setSelectedList(removeList);
      getSelectedRoleBadgeStatusList(badgeRoleStatusArray);
    } else {
      list.push(data);
      setSelectedList(list);
      getSelectedRoleBadgeStatusList(badgeRoleStatusArray);
    }
  };

  const filterList = (value: any) => {
    let str = value.toLowerCase();
    let newList: any[] = [];
    badgeStatusObject
      .filter((item) => item.name.toLowerCase().includes(str))
      .map((name) => {
        return newList.push(name);
      });
    const NO_BADGE_ASSIGNED = "No Assigned Badges";
    NO_BADGE_ASSIGNED.toLowerCase().includes(value.toLowerCase())
      ? setDisplayBadgeSwitch(true)
      : setDisplayBadgeSwitch(false);
    if (newList?.length === 0) setDisplayBadgeSwitch(true);
    setBadgeRoleStatusArray(newList);
  };

  const noBadgesSelectHandler = () => {
    let badgeList = [...badgeRoleStatusArray];
    let list: any = badgeList.forEach((item: any) => {
      item.isSelected = false;
    });
    LocalStorage.setStorage("noAssignedBadgesSelected", true);
    // setBadgeRoleStatusArray(list);
    getSelectedRoleBadgeStatusList([]);
    setErrorFilterMessage(false);
    setIsNoBadgeSelected((prevState: boolean) => !prevState);
  };

  const applyFilter = () => {
    noBadgesSelect
      ? dataFilteredHandler({ filtered: true, noAssignedBadges: true })
      : dataFilteredHandler({ filtered: true, noAssignedBadges: false });
    setModalVisible();
  };

  const clearFilter = () => {
    let badgeList = [...badgeRoleStatusArray];
    let list: any = badgeList.forEach((item: any) => {
      item.isSelected = false;
    });
    removeNoAssignedBadgesLocalStorage();
    getSelectedRoleBadgeStatusList(badgeList);
    // setBadgeRoleStatusArray([]);
  };

  const removeNoAssignedBadgesLocalStorage = () => {
    setNoBadgesSelect(false);
    LocalStorage.removeStorage("noAssignedBadgesSelected");
    setIsNoBadgeSelected(false);
  };

  return (
    <View style={styles.mainContainer}>
      {badgeRoleStatusArray === null || badgeRoleStatusArray === undefined ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : dataHelper.isEmptyArray(badgeRoleStatusArray) ? (
        <View style={styles.noDataContainer}>
          <View style={styles.searchContainer}>
            <SearchFilterSeondary filterList={filterList} />
          </View>
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            {t("common:noData")}
          </Text>
        </View>
      ) : (
        <View>
          <View style={styles.searchContainer}>
            <SearchFilterSeondary filterList={filterList} />
          </View>
          <View>
            <View style={styles.locationListContainer}>
              <ScrollContainer height="290px">
                {badgeRoleStatusArray?.map((prop, key) => {
                  return (
                    <View key={key}>
                      <View style={styles.checkBoxContainer}>
                        <Checkbox
                          testId={"checkBox"}
                          onPress={() => selectedCheckBoxHandler(prop)}
                          selected={prop.isSelected}
                          type={CheckboxType.Square}
                          text={prop.name}
                          textSize={TextSize.Small}
                          textFontWeight={FontWeight.Regular}
                          textStyle={styles.textStyleCheckBox}
                          iconSize={16}
                        />
                      </View>
                    </View>
                  );
                })}
              </ScrollContainer>
            </View>
            <View style={[styles.header]}>
              <View style={[styles.buttonContainer, styles.alignClearButton]}>
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Clear"
                  onPress={() => clearFilter()}
                />
              </View>
              {/* <View style={styles.buttonContainer}>
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Apply"
                  onPress={() => [applyFilter()]}
                />
              </View>  */}
            </View>
          </View>
        </View>
      )}
    </View>
  );
});

export default BadgeStatus;

const styles = StyleSheet.create({
  mainContainer: {},
  searchContainer: {
    marginBottom: 15
  },

  // Loader
  customLoadingContainer: {
    height: 300
  },

  // Check Box LocationList
  locationListContainer: {
    height: 300
  },
  checkBoxContainer: {
    marginBottom: 8
  },
  textStyleCheckBox: {
    marginStart: 15
  },

  // No Data Container
  noDataContainer: {
    height: 200
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    fontWeight: "700"
    // marginBottom: 5
  },

  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  alignClearButton: {
    marginRight: 110,
    marginTop: 11
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  }
});
