import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Entity } from "@socion-cordio/common/src/models/registry/entity";
import { RegistryApiClient } from "@socion-cordio/common/src/network/registryApiClient";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  registryEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { omit } from "lodash";

class PartnersRepository {
  getEntityProgramPartnersList = async (reqBody: any): Promise<any> => {
    const request = omit(reqBody, "programId");
    let data = await RegistryApiClient.post(
      registryEndPoints.programPartnersList(reqBody?.programId),
      request
    );
    return data?.result || [];
  };
  createEntityProgramPartner = async (reqBody: {}): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.addProgramPartner(), reqBody);
    return data?.result || [];
  };

  toggleEntityProgramPartner = async (
    reqBody: {},
    partnerId: string,
    isActivate: boolean
  ): Promise<any> => {
    const url = isActivate
      ? registryEndPoints.activateProgramPartner(partnerId)
      : registryEndPoints.deactivateProgramPartner(partnerId);
    let data = await RegistryApiClient.patch(url, reqBody);
    return data?.result || [];
  };
  getEntityPartnersList = async (reqBody: any): Promise<any> => {
    const request = omit(reqBody, "entityId");
    let data = await RegistryApiClient.post(
      registryEndPoints.entityPartnersList(reqBody?.entityId),
      request
    );
    return data?.result || [];
  };
}
const partnersRepository = new PartnersRepository();
export { partnersRepository as PartnersRepository };
