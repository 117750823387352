import React, { ReactElement, useEffect, useState } from "react";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { View, StyleSheet, TouchableOpacity, useWindowDimensions } from "react-native";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { toast } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import "react-datepicker/dist/react-datepicker.css";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";

interface IProps {
  // noFormik?: boolean;
  // sessionDetails?: any;
  // sessionData?: any;
  // sessionId?: number;
  // isSessionCompleted?: boolean;
  // isMember?: boolean;
  // getCompleteSessionDetails?: Function;
  // isLiveSession?: any;
  // isCompletedSession?: any;,
  // sessionLinks:
  sessionLinks?: any;
  sessionId?: number;
  sessionDetails?: any;
  isSessionCompleted?: boolean;
  setLinksCollapse?: Function;
  linksCollapse?: boolean;
  setTabClicked?: Function;
  getCompleteSessionDetails?: Function;
}

export default function AddLinksSidePanel(props: IProps): ReactElement {
  const { t } = useTranslation();
  const {
    sessionLinks,
    sessionId,
    sessionDetails,
    isSessionCompleted,
    setLinksCollapse,
    linksCollapse,
    setTabClicked,
    getCompleteSessionDetails
  } = props;
  const { width, height } = useWindowDimensions();
  // const { sessionLinks, sessionId, sessionDetails, isSessionCompleted } = props;
  const [additionalLinksList, setAdditionalLinksList] = useState(sessionLinks || null);
  const [link, setLink] = useState(null);
  const [user, setUser] = useState(null);
  const [failed, setFailed] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [isDeleteLink, setIsDeteleLink] = useState(false);
  const [removeLinkData, setRemoveLinkData] = useState(null);
  const [sessionData, setSessionData] = useState(sessionDetails || null);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isUrlCorrect, setIsUrlCorrect] = useState(false);
  const [showAdditionalLinks, setShowAdditionLinks] = useState(true);
  const [displayAdditionalLinks, setDisplayAdditionalLinks] = useState(false);
  // const [linksCollapse, setLinksCollapse] = useState(false); // default collapse
  const userRolesData: any = useSelector((state: any) => state?.userRolesProgram);

  const location: any = useLocation();

  useEffect(() => {
    getUserDetails();
    additionalLinksAddDisplayCheck();
  }, []);

  useEffect(() => {
    if (isDeleteLink) disableScroll();
    else enableScroll();
  }, [isDeleteLink]);

  useEffect(() => {
    if (sessionLinks !== null) {
      setAdditionalLinksList(sessionLinks);
      isMemberAllowedToEdit(); // which means sessionDetails is present
    }
  }, [sessionLinks]);

  const additionalLinksAddDisplayCheck = () => {
    // returns completed: false or true,
    if (!location?.state?.completed) {
      setDisplayAdditionalLinks(true);
    } else {
      setDisplayAdditionalLinks(false);
    }
  };

  const getUserDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setUser(user);
  };

  const getSessionDetails = async () => {
    try {
      const response = await ApiClient.get(
        sessionPackEndPoints.getCompleteSessionDetails(sessionId)
      );
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        setLink("");
        setAdditionalLinksList(response?.response?.sessionLinks);
        isMemberAllowedToEdit();
        setLoadingList(false);
        setFailed(false);
      } else {
        toast.error(response?.message);
        setLoadingList(false);
        setFailed(true);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoadingList(false);
      setFailed(true);
    }
  };

  const isMemberAllowedToEdit = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const userId = user?.userId;
    const sessionMembers = sessionDetails.members;
    const isAlreadyExistsCheck = sessionMembers.find(
      (x: { userId: string }) => x.userId === userId
    );
    const sessionCreatorId = sessionDetails?.sessionCreatorProfile.userId;
    if (isAlreadyExistsCheck || userId === sessionCreatorId) {
      setIsAllowed(true);
    } else {
      setIsAllowed(false);
    }
  };

  const addAddtionalLinksHandler = async () => {
    const reg_pattern = /(http(s)?:\\)?([\w-]+\.)+[\w-]+[.com|.in|.org]+(\[\?%&=]*)?/;
    if (link === "" || link === null) {
      setIsUrlCorrect(true);
      return;
    }
    if (!reg_pattern.test(link)) {
      setIsUrlCorrect(true);
      return;
    }
    try {
      setLoadingList(true);
      const addResult = {
        url: link,
        userId: user.userId
      };
      const response = await ApiClient.post(
        sessionPackEndPoints.addSessionAddtionalLinks(sessionId),
        addResult
      );
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        // getSessionDetails();
        getCompleteSessionDetails();
        toast.success(response.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoadingList(false);
      setFailed(true);
    }
  };

  const removeAddtionalLink = async (data: any) => {
    setRemoveLinkData(data);
    setIsDeteleLink(true);
  };

  const removeLinkHandler = async (value: any) => {
    try {
      setLoadingList(true);
      const sessionId = removeLinkData.sessionId;
      const linkId = removeLinkData.id;
      if (value === true) {
        const response = await ApiClient.delete(
          sessionPackEndPoints.deleteAdditionalLink(sessionId, linkId)
        );
        if (response?.responseCode === HTTP_STATUS_CODES.ok) {
          toast.success(response?.message);
          // getSessionDetails();
          getCompleteSessionDetails();
          setIsDeteleLink(false);
        } else {
          toast.error(response?.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setIsDeteleLink(false);
      setLoadingList(false);
      setFailed(true);
    }
  };

  useEffect(() => {
    let temp;
    let membersArray = props?.sessionDetails?.members?.map((i: any) => {
      return i.userId;
    });
    if (
      userRolesData?.userRolesData?.response.includes("PROGRAM_ADMIN") ||
      userRolesData?.userRolesData?.response.includes("ENTITY_ADMIN")
    ) {
      temp = false;
      if (props?.sessionDetails?.isSessionCreator == true || membersArray?.includes(user?.userId)) {
        temp = true;
      }
    } else if (
      props?.sessionDetails?.isSessionCreator == true ||
      membersArray?.includes(user?.userId)
    ) {
      temp = true;
    }
    // else temp = true;
    setShowAdditionLinks(temp);
  }, [userRolesData, props?.sessionDetails, user]);

  const clearHandler = () => {
    setLink("");
  };

  return (
    <View style={styles.container}>
      {/* <CollapseItem
        headerStyles={[styles.collapseHeaderContainer]}
        accordianIconStyle={styles.accordianIconStyle}
        isExpanded={false}
        bodyStyles={styles.bodyStyles}
        headerComponent={
          <View style={[styles.headerContainer, { marginLeft: 15 }]}>
            <Icon testID="program" name={IconNames.program} customStyle={styles.iconStyles} />
            <Text
              fontWeight={FontWeight.Medium}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={styles.textStyles}
            >
              Links
            </Text>
          </View>
        }
        children={ */}
      <TouchableOpacity
        onPress={() => {
          setLinksCollapse((prevState) => !prevState);
          setTabClicked("links");
          auxiliaryMethods.scrollToTopHandler();
        }}
        style={styles.mainContainer}
      >
        <View style={[styles.headerContainer, { marginLeft: 15 }]}>
          <Icon testID="links" name={IconNames.links} customStyle={styles.iconStyles} />
          <Text
            fontWeight={FontWeight.Medium}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={{ fontSize: 12, color: colorPallete.cordioBeige }}
          >
            Links
          </Text>
        </View>
        <View>
          <Icon
            name={linksCollapse ? IconNames.up : IconNames.down}
            size={10}
            customStyle={[{ color: colorPallete.cordioBeige, fontSize: 8 }]}
          />
        </View>
      </TouchableOpacity>
      {linksCollapse && (
        <View style={styles.childContainer}>
          {loadingList ? (
            <Loader customLoadingContainer={{ height: 250 }} />
          ) : failed ? (
            <View>Failed to load data</View>
          ) : (
            <View style={styles.collapseContainer}>
              {/* {!isSessionCompleted && ( */}
              {displayAdditionalLinks && (
                <View>
                  {isAllowed && (
                    <View style={styles.subCollapseContainer}>
                      <View style={styles.subCollapseContainerTwo}>
                        <View
                          style={[
                            styles.textFieldContianer,
                            styles.textFieldContianerAlignment,
                            styles.flex1
                          ]}
                        >
                          <TextField
                            label=""
                            handleChange={(link: string) => {
                              [setLink(link), setIsUrlCorrect(false)];
                            }}
                            handleBlur={() => {}}
                            inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                            placeholder={t("session:addLinks")}
                            name="addLinks"
                            id="addLinks"
                            noFormik
                            value={link}
                          />
                        </View>
                        <View style={styles.subContainer}>
                          <View>
                            <TouchableOpacity onPress={() => addAddtionalLinksHandler()}>
                              <Image
                                testId="primaryAddImg"
                                source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                                imageStyle={{
                                  width: 27,
                                  height: 27
                                }}
                              />
                            </TouchableOpacity>
                            {/* {(!isUrlCorrect || link === "") && (
                              <TouchableOpacity
                                onPress={() => {
                                  {
                                    // link !== "" && link !== null &&
                                    addAddtionalLinksHandler();
                                  }
                                }}
                              >
                                <Image
                                  testId="primaryAddImg"
                                  source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                                  imageStyle={{
                                    width: 27,
                                    height: 27
                                  }}
                                />
                              </TouchableOpacity>
                            )} */}
                            {/* when link is not valid */}
                            {/* {isUrlCorrect && link !== null && link !== "" && (
                              <TouchableOpacity onPress={() => {}}>
                                <Image
                                  testId="primaryAddImg"
                                  source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                                  imageStyle={{
                                    width: 27,
                                    height: 27,
                                    opacity: 0.5
                                  }}
                                />
                              </TouchableOpacity>
                            )} */}
                          </View>
                        </View>
                      </View>

                      {isUrlCorrect &&
                        ((link !== null && link !== "") || link === null || link === "") && (
                          <View>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.errorText]}
                            >
                              {t("session:validUrl")}
                            </Text>
                          </View>
                        )}
                      {/* {isUrlCorrect && (link === null || link === "") && (
                        <View>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="internal"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.errorText]}
                          >
                            {t("session:validUrl")}
                          </Text>
                        </View>
                      )} */}
                    </View>
                  )}
                </View>
              )}
              <View style={styles.linksListContainer}>
                {additionalLinksList?.map((prop: any, key: React.Key) => {
                  return (
                    <View>
                      <View key={key} style={{ flexDirection: "row" }}>
                        <View style={styles.linkListItem}>
                          <View style={styles.linkContainer}>
                            <a
                              href={
                                prop.sessionUrl.includes("http") ||
                                prop.sessionUrl.includes("https")
                                  ? prop.sessionUrl
                                  : `//${prop.sessionUrl}`
                              }
                              target="_blank"
                            >
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="addRoleText"
                                textSize={TextSize.Small}
                                textStyle={[styles.headerText]}
                              >
                                {prop.sessionUrl}
                              </Text>
                            </a>
                          </View>
                          {!isSessionCompleted && showAdditionalLinks && (
                            <View>
                              <TouchableOpacity onPress={() => removeAddtionalLink(prop)}>
                                <Image
                                  testId="remove"
                                  source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                                  imageStyle={{
                                    width: 20,
                                    height: 20
                                  }}
                                />
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                        {/* <View style={{ flex: 10 }}></View> */}
                      </View>
                      <View
                        style={
                          key === additionalLinksList.length - 1
                            ? { display: "none" }
                            : styles.borderContainer
                        }
                      ></View>
                    </View>
                  );
                })}
                {additionalLinksList?.length === 0 && (
                  <View style={styles.alignNoLinksContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText]}
                    >
                      {t("session:noLinks")}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}
          {isDeleteLink && (
            <View>
              <SocionModal
                modalVisible={isDeleteLink}
                setModalVisible={() => setIsDeteleLink(!isDeleteLink)}
                component={
                  <RemoveLinkModal
                    modalVisible={isDeleteLink}
                    selectedData={removeLinkHandler}
                    textValue={t("session:removeLink")}
                    setModalVisible={() => setIsDeteleLink(!isDeleteLink)}
                  />
                }
              />
            </View>
          )}
        </View>
      )}
      {/* }
      /> */}
      <View style={styles.dividerStyles}></View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  mainContainer: {
    paddingTop: 10,
    paddingBottom: 2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  dividerStyles: {
    borderColor: colorPallete.cordiolightGreyFour,
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 10
  },
  iconStyles: {
    fontSize: 20,
    color: colorPallete.cordioBeige,
    marginRight: 15
  },
  accordianIconStyle: {
    paddingHorizontal: 0,
    color: colorPallete.white,
    fontSize: 8,
    marginTop: -5
  },
  bodyStyles: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  childContainer: {
    backgroundColor: colorPallete.cordioRedDark2,
    marginTop: 10
  },
  collapseHeaderContainer: {
    backgroundColor: colorPallete.cordioRedDark2,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    paddingHorizontal: 0,
    paddingVertical: 10
  },
  collapseContainer: {},
  subCollapseContainer: {
    minHeight: 60
  },
  subCollapseContainerTwo: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  flex1: {
    flex: 1
  },
  subContainer: {
    // flex: 10,
    marginLeft: 10,
    alignContent: "center",
    alignItems: "center"
  },
  //textField
  textFieldContianer: {
    marginLeft: 15,
    borderColor: colorPallete.cordioTaupe
  },
  textFieldContianerAlignment: {
    marginLeft: 0,
    flex: 100
  },
  textStyles: {
    fontSize: 12,
    color: colorPallete.cordioBeige
  },
  inputStyling: {
    height: 40,
    borderRadius: 5,
    padding: 7,
    fontSize: 10,
    fontFamily: FontFamily.Medium,
    color: colorPallete.white,
    borderColor: colorPallete.cordioRedDark2
  },
  inputStylingAlignment: {
    width: "100%",
    color: colorPallete.cordioBeige,
    // backgroundColor: colorPallete.white
    // backgroundColor: colorPallete.cordioTaupe,
    // opacity: 0.1
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  },
  addLinkIcon: {},

  //links List
  linksListContainer: {
    // marginTop: 5
  },
  linkListItem: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 15
  },
  linkContainer: {
    flex: 1,
    marginRight: 5
  },
  borderContainer: {
    borderBottomColor: colorPallete.cordioBeige,
    borderBottomWidth: 1,
    borderStyle: "solid",
    opacity: 0.1
  },
  crossCircleIcon: {
    opacity: 0.5
  },

  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    color: colorPallete.cordioBeige
    // marginBottom: 5
  },
  errorText: {
    // alignSelf: "center",
    color: colorPallete.cordioRed,
    marginTop: 5
  },
  iconMainContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: 12
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    fontSize: 17
  },
  alignNoLinksContainer: {
    marginLeft: 16,
    marginVertical: 10
  }
});
