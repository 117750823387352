import React, { ReactElement, useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import _ from "lodash";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import FilterLocationPage from "@socion-cordio/common/src/components/organisms/qualification-packs/filterLocationPage";
import FilterBadgeRolePage from "@socion-cordio/common/src/components/organisms/qualification-packs/filterBadgeRolePage";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import LocationFilter from "@socion-cordio/common/src/components/organisms/participation-details/locationFilter";
import BadgeFilter from "@socion-cordio/common/src/components/organisms/participation-details/badgeFilter";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import BadgeStatus from "@socion-cordio/common/src/components/organisms/participation-details/badgeStatus";

interface Props {
  globalList?: any;
  setGlobalList?: any;
  locationList?: any;
  badgeRoleList?: any;
  setCheckedList?: any;
  getSelectedLocationList?: any;
  setExpandedList?: any;
  programDetails?: any;
  expandedList?: any;
  checkedList?: any;
  dataFilteredHandler?: any;
  setModalVisible?: any;
  setIsLocationFilterApplied?: any;
  getSelectedRoleBadgeList?: any;
  setIsBadgeFilterApplied?: any;
  getParticipantAttestationData?: any;
  setShowFilterModal?: any;
  setAllExpandedNodes?: any;
  allExpandedNodes?: any;
  seterrormessage?: any;
  eventAnalytics?: string;
  setCheckedListCopy?: Function;
  checkedListCopy?: any;
  setCheckedListCopyBadges?: Function;
  checkedListCopyBadges?: any;
  isShowBadgeStatus?: boolean;
  badgeStatusObject?: any;
  getSelectedRoleBadgeStatusList?: any;
  setSelectedBadgeStatusListFinal?: Function;
  setUpdatedBadgeStatusListPrimary?: Function;
  selectedBadgeRoleList?: any;
  setBadgeRoleListButton?: any;
  noAssignedBadgesSelected: any;
}

export default function FilterModal(props: Props): ReactElement {
  const {
    globalList,
    setGlobalList,
    locationList,
    badgeRoleList,
    setCheckedList,
    getSelectedLocationList,
    setExpandedList,
    programDetails,
    expandedList,
    checkedList,
    dataFilteredHandler,
    setModalVisible,
    setIsLocationFilterApplied,
    getSelectedRoleBadgeList,
    setIsBadgeFilterApplied,
    getParticipantAttestationData,
    setShowFilterModal,
    setAllExpandedNodes,
    allExpandedNodes,
    seterrormessage,
    eventAnalytics,
    setCheckedListCopy,
    checkedListCopy,
    setCheckedListCopyBadges,
    checkedListCopyBadges,
    isShowBadgeStatus = false,
    badgeStatusObject = [],
    getSelectedRoleBadgeStatusList,
    setSelectedBadgeStatusListFinal,
    setUpdatedBadgeStatusListPrimary,
    selectedBadgeRoleList,
    setBadgeRoleListButton,
    noAssignedBadgesSelected
  } = props;
  const [isLocationSelect, setIsLocationSelect] = useState(true);
  const [isBadgeRoleSelect, setIsBadgeRoleSelect] = useState(false);
  const [updatedBadgeList, setUpdatedBadgeList] = useState([]);
  const [updatedLocationList, setUpdatedLocationList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [badgeRoleListsArray, setBadgeRoleListsArray] = useState<Array<Object>>([]);
  const [errorFilterMessage, setErrorFilterMessage] = useState(false);
  const [isNoLocationSelected, setIsNoLocationSelected] = useState(false);
  const [isNoBadgeSelected, setIsNoBadgeSelected] = useState(false);
  const [isBadgeStatusSelect, setIsBadgeStatusSelect] = useState(false);
  const [updatedBadgeStatusList, setUpdatedBadgeStatusList] = useState([]);
  const [checkLocationPath, setCheckLocationPath] = useState(false);
  const [checkedBadges, setCheckedBadges] = useState([]);

  useEffect(() => {
    validateCheckedListHandler();
    setInitailTabHandler();

    if (window.location.pathname.includes("badges/assign")) {
      setCheckLocationPath(true);
    } else {
      setCheckLocationPath(false);
    }
  }, []);
  useEffect(() => {
    handleBadgeData();
  }, [badgeRoleList]);

  const setInitailTabHandler = () => {
    setTimeout(() => {
      if (isShowBadgeStatus) {
        setIsLocationSelect(false);
        setIsBadgeRoleSelect(true);
      }
    }, 0);
  };

  const closeModalHandler = () => {
    LocalStorage.removeStorage("isBadgeClearAllClicked");
    LocalStorage.removeStorage("isLocationClearAllClicked");
  };

  const validateCheckedListHandler = async () => {
    setCheckedList(checkedListCopy);
    badgeRoleList?.forEach((element: any) => {
      element.isSelected = false;
    });
    const newList: any[] = checkedListCopyBadges?.forEach((element: any) => {
      element.isSelected = true;
    });
    if (badgeRoleList !== undefined) {
      const list1 = badgeRoleList.map((element: any) => {
        const tempList = checkedListCopyBadges.filter(
          (item: any) => item.badgeId === element.badgeId
        );
        if (tempList.length !== 0) {
          element.isSelected = tempList[0].isSelected;
        }
      });
    }
  };

  const updatedFilteredLocationList = (locationList: any, bool: any) => {
    setUpdatedLocationList(locationList);
  };

  const updatedBadgesStatusList = (badgeStatusList: any) => {
    setUpdatedBadgeStatusList(badgeStatusList);
  };

  const handleBadgeData = () => {
    const sortedVal = _.mapValues(_.groupBy(badgeRoleList, "badgeClassName"), (clist) =>
      clist.map((badge) => _.omit(badge, "badgeClassName"))
    );
    let output = Object.entries(sortedVal).map(([badgeClass, children]) => ({
      badgeClass,
      children
    }));
    let tempoArray: any = [];
    if (noAssignedBadgesSelected) {
      setBadgeRoleListsArray(output);
      getSelectedRoleBadgeList([]);
      return;
    }
    output.forEach((badgeClassObj: any) => {
      let allChildrenSelected = true;
      badgeClassObj.children.forEach((childBadgeObj: any) => {
        if (selectedBadgeRoleList?.includes(childBadgeObj.badgeId)) {
          tempoArray.push(childBadgeObj);
          childBadgeObj.isSelected = true;
        } else {
          allChildrenSelected = false;
        }
      });
      if (allChildrenSelected) {
        badgeClassObj.isSelected = true;
      }
    });
    setCheckedBadges(tempoArray);
    setBadgeRoleListsArray(output);
  };
  const applyFilterHandler = async () => {
    const badgeStatuslist: any[] = updatedBadgeStatusList?.filter(
      (item: any) => item.isSelected === true
    );

    if (updatedBadgeStatusList.length !== 0) {
      setSelectedBadgeStatusListFinal(updatedBadgeStatusList);
      setUpdatedBadgeStatusListPrimary(updatedBadgeStatusList);
    }

    if (
      checkedList?.length === 0 &&
      checkedBadges.length === 0 &&
      !isNoLocationSelected &&
      !isNoBadgeSelected &&
      badgeStatuslist.length === 0
    ) {
      //display error
      setErrorFilterMessage(true);
      return;
    }
    if (checkedList !== undefined) {
      setCheckedListCopy(checkedList);
    }
    setBadgeRoleListButton(checkedBadges);

    LocalStorage.setStorage("globalNameList", globalList);
    const isNoneLocationSet = await LocalStorage.getStorage("noneLocationListSet");
    const isNoBadgeAssignSet = await LocalStorage.getStorage("noAssignedBadgesSelected");
    dataFilteredHandler({
      isFiltered: true,
      isNoneLocationSet: isNoneLocationSet,
      isNoBadgeAssignSet: isNoBadgeAssignSet,
      badgeStatusFinal: updatedBadgeStatusList
    });
    setIsLocationFilterApplied(true);
    setIsBadgeFilterApplied(true);
    seterrormessage("No users mapped to the selected Role Badge and/or Location");
    setShowFilterModal(false);
    googleWebAnalytics(`${eventAnalytics}_Apply_Filter`, "Button", "Participant_list");
    getParticipantAttestationData();
  };

  const renderBadgesSidePanel = () => (
    <TouchableOpacity
      onPress={() => {
        setIsLocationSelect(false);
        setIsBadgeRoleSelect(true);
        setIsBadgeStatusSelect(false);
      }}
    >
      <View style={styles.alignLeftContainer}>
        <View>
          <Icon
            testID="establishment"
            name={IconNames.badges}
            customStyle={
              isBadgeRoleSelect ? styles.leftIconContainerDark : styles.leftIconContainer
            }
          />
        </View>
        <Text
          fontWeight={FontWeight.Light}
          testId="addRoleText"
          textSize={TextSize.Small}
          textStyle={
            isBadgeRoleSelect
              ? [styles.headerText, styles.headerTextPrimary]
              : [styles.headerTextSecondary]
          }
        >
          Badge
        </Text>
      </View>
    </TouchableOpacity>
  );

  const renderLocationSidePanel = () => (
    <TouchableOpacity
      onPress={() => {
        setIsLocationSelect(true);
        setIsBadgeRoleSelect(false);
        setIsBadgeStatusSelect(false);
      }}
    >
      <View style={styles.alignLeftContainer}>
        <View>
          <Icon
            testID="baseLocation"
            name={IconNames.baseLocation}
            customStyle={isLocationSelect ? styles.leftIconContainerDark : styles.leftIconContainer}
          />
        </View>
        <Text
          fontWeight={FontWeight.Light}
          // fontWeight={FontWeight.Bold}
          testId="addRoleText"
          textSize={TextSize.Small}
          textStyle={
            isLocationSelect
              ? [styles.headerText, styles.headerTextPrimary]
              : [styles.headerTextSecondary]
          }
        >
          Location
        </Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Filter
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              onPress={() => [setShowFilterModal(false), closeModalHandler()]}
            />
          </View>
        </View>
        <View style={styles.subContainer}>
          <View style={styles.leftContainer}>
            <View>
              {checkLocationPath ? renderBadgesSidePanel() : renderLocationSidePanel()}
              {isShowBadgeStatus && (
                <TouchableOpacity
                  onPress={() => {
                    setIsLocationSelect(false);
                    setIsBadgeRoleSelect(false);
                    setIsBadgeStatusSelect(true);
                  }}
                >
                  <View style={styles.alignLeftContainer}>
                    <View>
                      <Icon
                        testID="establishment"
                        name={IconNames.badges}
                        customStyle={
                          isBadgeStatusSelect
                            ? styles.leftIconContainerDark
                            : styles.leftIconContainer
                        }
                      />
                    </View>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={
                        isBadgeStatusSelect
                          ? [styles.headerText, styles.headerTextPrimary]
                          : [styles.headerTextSecondary]
                      }
                    >
                      Badge Status
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
              {!checkLocationPath ? renderBadgesSidePanel() : renderLocationSidePanel()}
            </View>
          </View>
          <View style={styles.dividerContainer}></View>
          <View style={styles.rightContainer}>
            {errorFilterMessage && (
              <View style={{ position: "absolute", top: -10 }}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, { color: colorPallete.cordioRed }]}
                >
                  {"Please select either locations or the badges to apply the filters!"}
                </Text>
              </View>
            )}
            {isLocationSelect ? (
              <LocationFilter
                setGlobalList={setGlobalList}
                globalList={globalList}
                locationList={locationList}
                setCheckedList={setCheckedList}
                getSelectedLocationList={getSelectedLocationList}
                setExpandedList={setExpandedList}
                programDetails={programDetails}
                expandedList={expandedList}
                checkedList={checkedList}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsLocationFilterApplied={setIsLocationFilterApplied}
                updatedFilteredLocationList={updatedFilteredLocationList}
                allExpandedNodes={allExpandedNodes}
                setAllExpandedNodes={setAllExpandedNodes}
                setErrorFilterMessage={setErrorFilterMessage}
                setIsNoLocationSelected={setIsNoLocationSelected}
                eventAnalytics={eventAnalytics}
              />
            ) : isBadgeStatusSelect ? (
              <BadgeStatus
                badgeStatusObject={badgeStatusObject}
                getSelectedRoleBadgeStatusList={getSelectedRoleBadgeStatusList}
                programDetails={programDetails}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                updatedBadgesStatusList={updatedBadgesStatusList}
                setErrorFilterMessage={setErrorFilterMessage}
                setIsNoBadgeSelected={setIsNoBadgeSelected}
                // setSelectedList={setSelectedList}
                // selectedList={selectedList}
              />
            ) : (
              <BadgeFilter
                badgeRoleList={badgeRoleList}
                programDetails={programDetails}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsBadgeFilterApplied={setIsBadgeFilterApplied}
                setErrorFilterMessage={setErrorFilterMessage}
                setIsNoBadgeSelected={setIsNoBadgeSelected}
                eventAnalytics={eventAnalytics}
                selectedBadgeRoleList={selectedBadgeRoleList}
                getSelectedRoleBadgeList={getSelectedRoleBadgeList}
                noAssignedBadgesSelected={noAssignedBadgesSelected}
                checkedBadges={checkedBadges}
                setCheckedBadges={setCheckedBadges}
                badgeRoleListsArrayData={badgeRoleListsArray}
                setBadgeRoleListsArrayData={setBadgeRoleListsArray}

                // setSelectedList={setSelectedList}
                // selectedList={selectedList}
              />
            )}
            {/* {programDetails && isLocationSelect ? (
              <FilterLocationPage
                getSelectedLocationList={getSelectedLocationList}
                locationList={locationList}
                programDetails={programDetails}
                setExpandedList={setExpandedList}
                expandedList={expandedList}
                setCheckedList={setCheckedList}
                checkedList={checkedList}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsLocationFilterApplied={setIsLocationFilterApplied}
                updatedFilteredLocationList={updatedFilteredLocationList}
                setGlobalList={setGlobalList}
                globalList={globalList}
              />
            ) : isBadgeRoleSelect ? (
              <FilterBadgeRolePage
                getSelectedRoleBadgeList={getSelectedRoleBadgeList}
                badgeRoleList={badgeRoleList}
                programDetails={programDetails}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsBadgeFilterApplied={setIsBadgeFilterApplied}
                updatedBadgesList={updatedBadgesList}
              />
            ) : null} */}
          </View>
        </View>
        <View style={[styles.mainButtonContainer]}>
          <View style={styles.buttonContainer}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.button}
              textStyles={styles.buttonFont}
              title="Apply"
              onPress={() => applyFilterHandler()}
              disabled={
                badgeRoleList === null ||
                badgeRoleList === undefined ||
                badgeStatusObject == null ||
                badgeStatusObject == undefined
              }
            />
          </View>
        </View>
      </View>
      {/* }
      ></Modal> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655,
    height: 450
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextSecondary: {
    fontSize: 12,
    marginLeft: 15,
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  headerTextPrimary: {
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 15
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginTop: 15,
    marginBottom: 15,
    flexDirection: "row"
  },

  //left container
  leftContainer: {
    flex: 20,
    padding: 10,
    paddingLeft: 0
  },
  alignLeftContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 25
  },
  // alignLabelContainer: {
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  //   height: "95%"
  // },
  leftIconContainer: {
    fontSize: 16,
    color: colorPallete.textLight
  },
  leftIconContainerDark: {
    fontSize: 16,
    color: colorPallete.textBlack
  },

  //divider
  dividerContainer: {
    flex: 1,
    minHeight: 450,
    borderRightColor: colorPallete.cordioTaupe,
    borderRightWidth: 1,
    borderStyle: "solid",
    opacity: 0.1
  },

  //right container
  rightContainer: {
    flex: 79,
    padding: 10,
    paddingLeft: 25
  },
  //text field
  inputStyling: {
    height: 40,
    // outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400"
  },
  inputStylingAlignment: {},
  //checkBox
  checkBoxContainer: {
    marginTop: 27
  },
  textStyleCheckBox: {
    marginStart: 20
  },
  mainButtonContainer: {
    position: "absolute",
    flexDirection: "row",
    bottom: 0,
    right: 0
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  }
});
