import React, { ReactElement, useState, useEffect } from "react";
import { ScrollView, StyleSheet, View, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast, ToastContainer } from "react-toastify";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { BadgesRepository } from "@socion-cordio/common/src/repositories/badges/badges";
import { ScrollContainer } from "../scrollWrapper";

interface Props {
  onSubmit?: (data: any) => void;
  onClose?: Function;
  selectedBadge?: any;
  getBadges?: Function;
  programDetails?: any;
  updateTelemetryBadgesEvent?: Function;
}

export default function BadgeDetailsModal(props: Props): ReactElement {
  const { onClose, selectedBadge, getBadges, programDetails, updateTelemetryBadgesEvent } = props;
  const [isBadgeEnabled, setIsBadgeEnabled] = useState<boolean>(selectedBadge.status === "ACTIVE");
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(null);
  const [items, setItems] = useState(selectedBadge?.badgeTypeItems);
  const [showError, setShowError] = useState(false);

  const [newEditValues, setNewEditValues] = useState({
    name: selectedBadge?.name,
    description: selectedBadge?.description,
    awardingCriteria: selectedBadge?.awardingCriteria
  });

  const changeHandler = (name: string, value: string) => {
    setNewEditValues({ ...newEditValues, [name]: value });
  };

  useEffect(() => {
    if (showDeleteModal || showRemoveModal) disableScroll();
    else enableScroll();
  }, [showRemoveModal, showDeleteModal]);

  const updateBadge = async () => {
    setLoading(true);
    const payload = {
      ...newEditValues,
      // badgeTypeId: selectedBadge.badgeTypeId,
      badgeClassId: selectedBadge.badgeClassId,
      programId: programDetails?.program?.id,
      badgeId: selectedBadge.badgeId
    };
    let response = await ApiClient.put(badgesEndPoints.updateBadge(), payload);
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      onClose();
      getBadges();
      toast.success(response.message);
      googleWebAnalytics("Badges_Save_Badge_Details", "Button", "Badge");
      setLoading(false);
    } else if (response.responseCode == HTTP_STATUS_CODES.conflict) {
      toast.error(response.message);
      setLoading(false);
    } else {
      toast.error("Something went wrong...!!!");
      setLoading(false);
    }
  };

  const handleToggle = () => {
    setShowRemoveModal(true);
  };

  const handleActivateDeactivate = () => {
    setIsBadgeEnabled((isBadgeEnabled) => !isBadgeEnabled);
    hanldleActivateReactivate(!isBadgeEnabled);
    setShowRemoveModal(false);
  };

  const hanldleActivateReactivate = async (badgeEnabled: boolean) => {
    const activateBadge = badgeEnabled || false;
    let response = await ApiClient.put(
      badgesEndPoints.activateReactivateBadge(selectedBadge.badgeId),
      {},
      { activateBadge }
    );
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      onClose();
      getBadges();
      toast.success(response.message);
      updateTelemetryBadgesEvent(
        activateBadge ? "Badge Activated" : "Badge Deactivated",
        selectedBadge
      );
    } else {
      toast.error("Something went wrong...!!!");
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    let response = BadgesRepository.deleteBadge(selectedBadge.badgeId);
    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          onClose();
          getBadges();
          toast.success("Badge deleted successfully");
          googleWebAnalytics("Badges_Delete_Badge", "Button", "Badge");
          setLoading(false);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(true);
      });
  };

  return (
    <View>
      <ToastContainer />
      <View style={styles.container}>
        {loading ? (
          <Loader customLoadingContainer={styles.customLoadingContainer} />
        ) : (
          <>
            <View style={styles.header}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Regular}
                textStyle={styles.headerText}
              >
                Badge details
              </Text>
              <View style={styles.crossCircleContainer}>
                <Icon
                  testID="close"
                  name={IconNames.crossCircle}
                  customStyle={styles.crossCircle}
                  onPress={() => onClose()}
                />
              </View>
              <View style={styles.divider} />
            </View>
            <View style={styles.editDeleteIconsContainer}>
              {selectedBadge.status === "ACTIVE" && !isEditable && !selectedBadge.badgeLinked && (
                <TouchableOpacity>
                  <Icon
                    testID="delete-icon"
                    name={IconNames.deleteFile}
                    customStyle={styles.penPaperIcon}
                    onPress={() => {
                      setShowDeleteModal(true);
                    }}
                  />
                </TouchableOpacity>
              )}
              {selectedBadge.status === "ACTIVE" && !isEditable && !selectedBadge.badgeLinked && (
                <TouchableOpacity>
                  <Icon
                    testID="edit-icon"
                    name={IconNames.penPaper}
                    customStyle={styles.penPaperIcon}
                    onPress={() => {
                      setIsEditable(true);
                      googleWebAnalytics("Badges_Edit_Badge_Details", "Button", "Badge");
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.badgeDetailsContainer}>
              <View style={styles.badgeDetials}>
                {!isEditable && (
                  <View style={!isEditable ? styles.marginAlign : null}>
                    <View style={styles.textRowContainer}>
                      <View style={styles.leftTextContainer}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerAlignment]}
                        >
                          Badge Name
                        </Text>
                      </View>
                      <View>
                        <ScrollContainer height="70px" width="450px">
                          <Text
                            fontWeight={FontWeight.Bold}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={styles.headerText}
                          >
                            {selectedBadge?.name}
                          </Text>
                        </ScrollContainer>
                      </View>
                    </View>

                    <View style={styles.textRowContainer}>
                      <View style={styles.leftTextContainer}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerAlignment]}
                        >
                          Created on
                        </Text>
                      </View>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.text, styles.fontSizeText]}
                      >
                        {dataHelper.formatDate(selectedBadge?.createdAt, "DD MMM YYYY")}
                      </Text>
                    </View>
                    <View style={styles.textRowContainer}>
                      <View style={styles.leftTextContainer}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerAlignment]}
                        >
                          Badge Type
                        </Text>
                      </View>
                      <View>
                        <ScrollContainer height="70px" width="450px">
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.colorText]}
                          >
                            {selectedBadge?.badgeTypeName || selectedBadge?.badgeClassName}
                          </Text>
                        </ScrollContainer>
                      </View>
                    </View>

                    <View style={styles.textRowContainer}>
                      <View style={styles.leftTextContainer}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerAlignment]}
                        >
                          Description
                        </Text>
                      </View>
                      <View>
                        <ScrollContainer height="70px" width="450px">
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Regular}
                            textStyle={[styles.headerText, styles.colorText]}
                          >
                            {selectedBadge?.description}
                          </Text>
                        </ScrollContainer>
                      </View>
                    </View>

                    <View style={styles.textRowContainer}>
                      <View style={styles.leftTextContainer}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerAlignment]}
                        >
                          Awarding criteria
                        </Text>
                      </View>
                      <View>
                        <ScrollContainer height="70px" width="450px">
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Regular}
                            textStyle={[styles.headerText, styles.colorText]}
                          >
                            {selectedBadge?.awardingCriteria}
                          </Text>
                        </ScrollContainer>
                      </View>
                    </View>
                  </View>
                )}

                {isEditable && (
                  <View style={styles.editableContainer}>
                    <View>
                      <View>
                        <UserInputSecondary
                          textValue="Badge name"
                          handleChange={(value: any) => changeHandler("name", value)}
                          handleBlur={() => {}}
                          value={newEditValues?.name}
                          // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                          placeholder="Enter Badge name"
                          name="badgeName"
                          id="badgeName"
                          userStyle={styles.inputStylingAlignment}
                          userStyleText={styles.textHeaderField}
                          textInputContainerStyles={{ marginLeft: 15 }}
                          noFormik
                        />
                      </View>

                      <View style={styles.dateContainerForEditable}>
                        <View style={styles.dateContainerForEditableBlank}></View>
                        <View style={styles.dateContainerForEditableDateField}>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="dateLabel"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.headerAlignment,
                              styles.fontSizeText,
                              { marginRight: 10 }
                            ]}
                          >
                            Created on
                          </Text>
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="date"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.fontSizeText]}
                          >
                            {dataHelper.formatDate(selectedBadge?.createdAt, "DD MMM YYYY")}
                          </Text>
                        </View>
                      </View>
                      <View>
                        <UserInputTextArea
                          textValue="Description"
                          label="Description"
                          placeholder="Write a description"
                          name="description"
                          id="description"
                          handleChange={(value: any) => changeHandler("description", value)}
                          handleBlur={() => {}}
                          value={newEditValues?.description}
                          numberOfLines={5}
                          multiline={true}
                          userStyle={styles.inputStylingAlignment}
                          userStyleText={styles.textHeaderField}
                          noFormik
                        />
                      </View>
                      <View>
                        <UserInputTextArea
                          textValue="Awarding criteria"
                          label="Awarding criteria"
                          placeholder="Add criteria"
                          name="awardCriteria"
                          id="awardCriteria"
                          handleChange={(value: any) => changeHandler("awardingCriteria", value)}
                          handleBlur={() => {}}
                          value={newEditValues?.awardingCriteria}
                          numberOfLines={5}
                          multiline={true}
                          userStyle={styles.inputStylingAlignment}
                          userStyleText={styles.textHeaderField}
                          noFormik
                        />
                      </View>
                    </View>
                  </View>
                )}

                {/* {selectedBadge.badgeLinked && ( */}
                <View
                  style={
                    !isEditable
                      ? [styles.marginAlign, styles.badgeStatusContainer]
                      : styles.badgeStatusContainer
                  }
                >
                  <View style={styles.leftTextContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlignment, { marginBottom: 0 }]}
                    >
                      Badge status
                    </Text>
                  </View>

                  <View style={styles.switchContainer}>
                    <Switch
                      isOn={isBadgeEnabled}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={handleToggle}
                    />
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="addRoleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.toggleButtonText]}
                    >
                      {isBadgeEnabled ? "Active" : "Inactive"}
                    </Text>
                  </View>
                </View>
                {/* )} */}

                <View
                  style={[styles.alignButtonContainer]}
                  // style={{ width: '100px' }}
                >
                  {isEditable && (
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.submitbutton}
                      title="Save changes"
                      textStyles={styles.buttonFont}
                      onPress={() => updateBadge()}
                      // disabled={true}
                    />
                  )}
                  {showRemoveModal && (
                    <View>
                      <SocionModal
                        modalVisible={showRemoveModal}
                        setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                        component={
                          <RemoveLinkModal
                            modalVisible={showRemoveModal}
                            selectedData={handleActivateDeactivate}
                            textValue={
                              isBadgeEnabled
                                ? "You will not be able to assign this badge anymore. Are you sure you want to deactivate this badge?"
                                : "Are you sure you want to reactivate this badge?"
                            }
                            setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                          />
                        }
                      />
                    </View>
                  )}
                  {showDeleteModal && (
                    <View>
                      <SocionModal
                        modalVisible={showDeleteModal}
                        setModalVisible={() => setShowDeleteModal(!showDeleteModal)}
                        component={
                          <RemoveLinkModal
                            modalVisible={showDeleteModal}
                            selectedData={handleDelete}
                            textValue={"Are you sure you want to delete this badge?"}
                            setModalVisible={() => setShowDeleteModal(!showDeleteModal)}
                          />
                        }
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
  },
  customLoadingContainer: {
    height: 350
  },
  header: {
    marginBottom: 5
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    marginBottom: 5
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  colorText: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    fontSize: 12
  },
  crossCircle: {
    fontSize: 18
  },
  crossCircleContainer: { position: "absolute", right: 0, top: 0 },
  badgeDetailsContainer: {
    flexDirection: "row",
    // marginTop: 15,
    marginBottom: 10
  },
  titleIcon: {
    fontSize: 15
  },
  badgeDetials: {
    flex: 100
    // marginLeft: 35,
    // marginRight: 35
  },
  marginAlign: {
    marginLeft: 35,
    marginRight: 35
  },
  badgeHeaderAlignmnet: {
    marginBottom: 0,
    flex: 20
  },
  badgeHeaderAlignment: {
    marginBottom: 5
  },
  subHeaderAlignment: {
    // flexDirection: "row",
    marginTop: 5,
    marginBottom: 10
  },
  dateCreatedContainer: {},
  badgeStatusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 80
  },
  toggleButtonText: {
    marginLeft: 30,
    color: "#7B7979"
  },
  alignButtonContainer: {
    marginTop: 25,
    // marginBottom: 10,
    justifyContent: "center",
    alignSelf: "center"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  textHeaderField: {
    flex: 20,
    fontSize: 12
  },
  editableContainer: {
    marginBottom: 15
  },
  dateContainerForEditable: {
    flexDirection: "row"
  },
  dateContainerForEditableBlank: {
    flex: 20
  },
  dateContainerForEditableDateField: {
    flex: 80,
    flexDirection: "row",
    marginLeft: 15,
    marginTop: 10
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonFont: {
    fontSize: 12
  },
  penPaperIcon: {
    fontSize: 15,
    opacity: 0.6
    // marginLeft: 25
  },
  text: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    marginBottom: 5,
    color: "rgb(35, 31, 32)"
  },

  editDeleteIconsContainer: {
    width: 50,
    flexDirection: "row",
    position: "absolute",
    justifyContent: "space-between",
    right: 0,
    top: 60
  },
  leftTextContainer: { width: 170 },
  textRowContainer: { flexDirection: "row", marginBottom: 20 },
  containerStyle: {
    width: "100%"
  },
  dropDownContainerStyle: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: colorPallete.lightGreyTwo
  },
  ContainerStyle2: {
    borderColor: colorPallete.cordioTaupe,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    height: 40,
    marginBottom: 0
  },
  listItems: {
    padding: 10
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13,
    flex: 20,
    paddingRight: 60
  },
  titleInputContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 100,
    marginTop: 10,
    zIndex: 1
  },
  divider: {
    borderBottomColor: "rgba(35, 31, 32, 1)",
    borderBottomWidth: 1,
    marginHorizontal: 5,
    marginTop: 16,
    marginBottom: 30,
    opacity: 0.45
  }
});
