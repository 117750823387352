import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import QualificationPackTable from "@socion-cordio/common/src/components/organisms/qualification-packs/qualificationPackTable";
import { useSelector, useDispatch } from "react-redux";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { toast } from "react-toastify";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { useHistory, useLocation } from "react-router-dom";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {}

export default function AddQualificationPackPage(props: Props): ReactElement {
  let userRolesProgram: any = useSelector((state: any) => state?.userRolesProgram);
  const {} = props;
  const [loading, setLoading] = useState(false);
  const [isNameExist, setIsNameExist] = useState(false);
  const [programData, setProgramData] = useState(null);
  const [packDescription, setPackDescription] = useState("");
  const [qualificationPackName, setQualificationPackName] = useState("");

  const location: any = useLocation();
  const history: any = useHistory();

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    setProgramData(programDetails);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const body = {
        name: qualificationPackName.trim(),
        description: packDescription,
        programId: programData.program.id
      };
      const addResponse = await ApiClient.post(
        qualificationPackEndPoints.addQualificationPack(),
        body
      );
      if (addResponse.responseCode === HTTP_STATUS_CODES.ok) {
        setLoading(false);
        toast.success("Qualification Pack added successfully");
        updateTelemetry(addResponse?.response);
        history.push(
          `${routeNames.app}${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWQUALIFICATIONPACKDETAILS}`,
          {
            createdQualificationPack: addResponse.response,
            qpBundleId: addResponse.response.id,
            qualificationPackList: location.state.qpAllList
          }
        );
        googleWebAnalytics("QualificationPack_Added", "Button", "Qualification_Pack");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
      setIsNameExist(false);
    }
  };

  const updateTelemetry = async (data: any) => {
    // const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const body: any = {
      timestamp: moment().toLocaleString(),
      createdAt: moment().toLocaleString(),
      deleted: "false",
      updatedAt: moment().toLocaleString(),
      eventType: "QP Added",
      noOfSelections: 0,
      bundleId: data.id,
      bundleName: data.name,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programData, user, true);
    googleWebAnalytics("QP_Added", "Telemetry_Event", "Program");
  };

  const submitHandlerCheck = () => {
    const { qualificationPackList } = location.state;
    const qpNames = qualificationPackList?.map((item: any) => item.bundle.name.trim());
    const value = qpNames.includes(qualificationPackName.trim());
    setIsNameExist(value);
    !value ? handleSubmit() : null;
  };

  const cancelHandler = () => {
    history.push(`${routeNames.app}${routeNames.QUALIFICATIONPACKS}`);
    googleWebAnalytics("QP_Cancel_Add_QP", "Button", "Qualification_Pack");
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        {loading ? (
          <Loader />
        ) : (
          <View style={styles.mainContainer}>
            <View style={styles.header}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addqualification"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.fontSize14]}
              >
                Add qualification pack
              </Text>
              <View>
                <TouchableOpacity onPress={() => cancelHandler()}>
                  <Icon
                    testID="close"
                    name={IconNames.crossCircle}
                    customStyle={styles.crossCircle}
                    onPress={() => cancelHandler()}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.alignContainer}>
              <View style={styles.userInputContainer}>
                <UserInputSecondary
                  textValue="Qualification pack name"
                  handleChange={(qualificationPackName: string) => {
                    [setQualificationPackName(qualificationPackName), setIsNameExist(false)];
                  }}
                  handleBlur={() => {
                    googleWebAnalytics("QP_Add_QP_Name", "Button", "Qualification_Pack");
                  }}
                  value={qualificationPackName}
                  placeholder="Enter name"
                  name="enterQualificationName"
                  id="enterQualificationName"
                  noFormik
                  userStyle={[styles.inputStylingAlignment, styles.fontSize14]}
                  userStyleText={[styles.textHeaderField, styles.fontSize16]}
                  textInputContainerStyles={styles.textInputContainerStyles}
                />
                {isNameExist && (
                  <View style={styles.errorContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="internal"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, styles.errorText]}
                    >
                      {`Qualification Pack ${qualificationPackName.trim()} already exists!`}
                    </Text>
                  </View>
                )}
              </View>
            </View>

            <View style={styles.alignContainer}>
              <UserInputTextArea
                textValue="Description"
                label="Description"
                placeholder="Write a description"
                name="Description"
                id="description"
                handleChange={(description: string) => {
                  setPackDescription(description);
                }}
                handleBlur={() => {
                  googleWebAnalytics("QP_Add_QP_Description", "Button", "Qualification_Pack");
                }}
                value={packDescription}
                numberOfLines={5}
                multiline={true}
                noFormik
                userStyle={[styles.inputStylingAlignment, styles.fontSize14]}
                userStyleText={[styles.textHeaderField, styles.fontSize16]}
              />
            </View>
            <View style={[styles.alignButtonContainer]}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.submitbutton}
                title="Save"
                onPress={() => {
                  submitHandlerCheck();
                }}
                textStyles={styles.fontSize14}
                disabled={qualificationPackName === "" || packDescription === "" || isNameExist}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2
    // minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    minHeight: "calc(100vh - 140px)"
  },

  // card container
  mainContainer: {
    margin: 18,
    padding: 18,
    borderColor: colorPallete.cordioGreen,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },

  // fontSizes
  fontSize12: {
    fontSize: 12
  },
  fontSize14: {
    fontSize: 14
  },
  fontSize16: {
    fontSize: 16
  },

  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  crossCircle: {
    fontSize: 15
  },
  alignContainer: {
    marginBottom: 18
  },
  userInputContainer: {
    flexDirection: "column"
  },
  textHeaderField: {
    flex: 25,
    fontSize: 12
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  textInputContainerStyles: {
    marginLeft: 15
  },
  alignButtonContainer: {
    justifyContent: "center",
    alignSelf: "flex-end"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginLeft: 15
  },

  // Error container
  errorContainer: {
    position: "absolute",
    top: 50,
    left: 252
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  errorText: {
    marginTop: 4,
    justifyContent: "center",
    color: colorPallete.cordioRed,
    fontSize: 14,
    marginRight: 0
  }
});
