import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import Select from "react-select";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";
import styled from "styled-components";

export const SelectContainer = styled.div`
  // .select__input {
  //   color: transparent;
  // }
`;

interface Props {
  setSelectedProgram?: any;
  selectedProgram?: any;
  options?: any;
  setDropDownSelectedProgram?: Function;
}

export default function ProgramDropdown(props: Props): ReactElement {
  const { selectedProgram, setSelectedProgram, options, setDropDownSelectedProgram } = props;

  const customStyles = {
    control: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: "white",
      marginTop: 2,
      height: 40,
      boxShadow: "none",
      border: "none",
      fontSize: 12,
      fontFamily: FontFamily.Regular,
      ":hover": {
        border: "none",
        marginTop: 2
      }
    }),
    menuList: (base: any) => ({
      ...base,
      height: 150,
      "::-webkit-scrollbar": {
        width: 5
      },
      "::-webkit-scrollbar-track": {
        background: "#D1D1D1",
        borderRadius: 10
      },
      "::-webkit-scrollbar-thumb": {
        background: "#808080",
        borderRadius: 10
      }
    }),
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor:
          "isTopicActive" in data ? (data.isTopicActive ? "white" : "#B7504A") : "white",
        borderRadius: 50,
        border: "1px solid #B7504A",
        padding: 2,
        maxWidth: 500
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      fontSize: 14
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "black",
      borderRadius: 50,
      marginTop: 2,
      marginLeft: 7,
      marginRight: 3,
      height: 22,
      ":hover": {
        backgroundColor: "black"
      }
    })
  };

  return (
    <>
      <View style={styles.dropdownContainer}>
        <SelectContainer>
          <Select
            isClearable={false}
            value={selectedProgram}
            removeSelected={true}
            hideSelectedOptions={true}
            onChange={(data: any) => [setSelectedProgram(data), setDropDownSelectedProgram(data)]}
            placeholder={"Select Program"}
            options={options}
            openMenuOnClick={true}
            menuPosition={"fixed"}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null
            }}
            classNamePrefix="select"
            isSearchable={false}
          />
        </SelectContainer>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  dropdownContainer: {
    marginTop: 12,
    width: "100%",
    height: 40
  }
});
