import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import ChangePasswordNew from "@socion-cordio/common/src/components/organisms/changePasswordNew";
import PushNotification from "@socion-cordio/common/src/components/organisms/pushNotification";
import DeleteAccount from "@socion-cordio/common/src/components/organisms/deleteAccount";

interface Props {
  fetchResetPassword?: Function;
  // profileData: Profile;
  // loading: IProfileLoading;
  updateResetPassword?: Function;
}
export default function MyAccount(props: Props): ReactElement {
  const [expandChangePassword, setExpandChangePassword] = useState(false);
  const [expandPushNotification, setExpandPushNotification] = useState(false);
  const [expandDeleteAccount, setExpandDeleteAccount] = useState(false);

  return (
    <View style={styles.mainContainer}>
      <View>
        <View style={styles.subContainer}>
          <ChangePasswordNew
            expandChangePassword={expandChangePassword}
            setExpandPushNotification={setExpandPushNotification}
            setExpandChangePassword={setExpandChangePassword}
            setExpandDeleteAccount={setExpandDeleteAccount}
          />
        </View>
        <View style={styles.subContainer}>
          <PushNotification
            expandPushNotification={expandPushNotification}
            setExpandPushNotification={setExpandPushNotification}
            setExpandChangePassword={setExpandChangePassword}
            setExpandDeleteAccount={setExpandDeleteAccount}
          />
        </View>
        <View style={styles.subContainer}>
          <DeleteAccount
            expandDeleteAccount={expandDeleteAccount}
            setExpandPushNotification={setExpandPushNotification}
            setExpandChangePassword={setExpandChangePassword}
            setExpandDeleteAccount={setExpandDeleteAccount}
          />
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colorPallete.white,
    padding: 20,
    // height: "79vh",
    borderRadius: 20,
    margin: 20,
    minHeight: "calc(100vh - 50px)"
  },
  collapseHeaderContainer: {
    width: 548,
    backgroundColor: "rgba(238, 229, 218, 0.5)",
    borderRadius: 0
  },
  accordionStyles: {
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowColor: colorPallete.white,
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0
  },
  subContainer: {
    marginBottom: 10,
    width: 546
  }
});
