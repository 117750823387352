import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { useLocation, useHistory } from "react-router-dom";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useTranslation } from "react-i18next";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
interface Props {
  sessionDetails?: any;
}

export default function CompletedSessionParticipationPage(props: Props): ReactElement {
  const SINGLE_SCAN = "SINGLE_SCAN";
  const { t } = useTranslation();
  const { sessionDetails } = props;
  const [loadingState, setLoadingState] = useState(true);
  const [failed, setFailed] = useState(false);
  const [trainer, setTrainer] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [signIn, setSignIn] = useState([]);
  const [signOut, setSignOut] = useState([]);
  const [trainerCount, setTrainerCount] = useState([]);
  const [adminCount, setAdminCount] = useState([]);
  const [otherCount, setOtherCount] = useState([]);
  const [user, setUser] = useState(null);
  const [userRolesData, setUserRolesData] = useState(null);
  const location: any = useLocation();
  const history: any = useHistory();

  useEffect(() => {
    if (sessionDetails !== null) {
      getSessionCompletedDetails();
      countMembersHandler();
    }
  }, []);

  useEffect(() => {
    (async () => {
      let userData = await LocalStorage.getStorage("user");
      let tempUserRolesData: any = await LocalStorage.getStorage("userRoles");
      setUser(userData);
      setUserRolesData(tempUserRolesData?.userRolesData?.response);
    })();
  }, []);

  const getSessionCompletedDetails = async () => {
    try {
      const sessionId = sessionDetails?.sessionId;
      // const sessionId = 67327;
      const newBody = {
        sessionIds: [sessionId],
        userType: "TRAINEE",
        sessionStatus: "UPCOMING"
      };
      const response = await ApiClient.post(
        sessionPackEndPoints.getSessionParticipationList(),
        newBody
      );
      if (response?.responseCode === HTTP_STATUS_CODES.ok) {
        const list = response?.response[0]?.sessionData[sessionId];
        list.forEach((session: any, index: number) => {
          session.serialNo = `${index + 1}.`;
        });
        const trainer = list?.filter((member: any) => member.userRole === "TRAINEE");
        const admin = list?.filter((member: any) => member.userRole === "ADMIN");
        setTrainer(trainer);
        setAdmin(admin);

        const signOut = list.filter((x: any) => x.scanOutTime !== null);
        setSignOut(signOut);
        const signIn = [...list];
        setSignIn(signIn);
        setLoadingState(false);
      }
    } catch (error) {
      setLoadingState(false);
      setFailed(true);
    }
  };

  const countMembersHandler = () => {
    const memberRoles = sessionDetails.members.map((x: any) => x.roles);
    const trainer = memberRoles.filter((x: any) => x.trainer === true);
    const admin = memberRoles.filter((x: any) => x.admin === true);
    const other = memberRoles.filter((x: any) => x.other === true);
    setTrainerCount(trainer);
    setAdminCount(admin);
    setOtherCount(other);
  };

  const signInHandler = () => {
    if (
      user?.userId == props?.sessionDetails?.sessionCreatorProfile?.userId ||
      userRolesData.includes("ENTITY_ADMIN") ||
      userRolesData.includes("PROGRAM_ADMIN") ||
      props?.sessionDetails?.member
    ) {
      history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live`, {
        completedSessionData: signIn,
        redirectionFrom: "Completed Session",
        sessionScanMode: sessionDetails.sessionScanMode,
        scanType: "Scan In",
        completed: true
      });
      leftSidePanelCollapseHandler();
    }
  };

  const signOutHandler = () => {
    if (
      user?.userId == props?.sessionDetails?.sessionCreatorProfile?.userId ||
      userRolesData.includes("ENTITY_ADMIN") ||
      userRolesData.includes("PROGRAM_ADMIN") ||
      props?.sessionDetails?.member
    ) {
      history.push(
        `${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live/sessions`,
        {
          completedSessionData: signIn,
          sessionScanMode: sessionDetails.sessionScanMode,
          scanType: "Scan Out",
          completed: true
        }
      );
      leftSidePanelCollapseHandler();
    }
  };

  const handleAdminOthers = () => {
    if (
      user?.userId == props?.sessionDetails?.sessionCreatorProfile?.userId ||
      userRolesData.includes("ENTITY_ADMIN") ||
      userRolesData.includes("PROGRAM_ADMIN") ||
      props?.sessionDetails?.member
    ) {
      history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live`, {
        completedSessionData: signIn,
        redirectionFrom: "Completed Session",
        sessionScanMode: sessionDetails.sessionScanMode,
        // scanType: "Scan In",
        showOthers: true,
        completed: true,
        sessionParticipantType: "Others"
      });
      leftSidePanelCollapseHandler();
    }
  };

  const trainerRedirectionHandler = () => {
    if (
      user?.userId == props?.sessionDetails?.sessionCreatorProfile?.userId ||
      userRolesData.includes("ENTITY_ADMIN") ||
      userRolesData.includes("PROGRAM_ADMIN") ||
      props?.sessionDetails?.member
    ) {
      history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live`, {
        completedSessionData: signIn,
        redirectionFrom: "Completed Session",
        sessionScanMode: sessionDetails.sessionScanMode,
        scanType: "Scan In",
        sessionParticipantType: "Trainer",
        completed: true
      });
      leftSidePanelCollapseHandler();
    }
  };

  const participantListRedirectHandler = (participantFlag: boolean) => {
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.PARTICIPATIONLIST}`, {
      sessionDetails: sessionDetails,
      // To either show participant list or trainer list.
      // true: participant list
      // false: trainer list
      isParticipantListDisplay: participantFlag
    });
  };

  const leftSidePanelCollapseHandler = () => {
    // Close the left side panel when on click of view session
    LocalStorage.setStorage("isSidePanelCollapsed", true);
  };

  return (
    <View style={styles.container}>
      {loadingState ? (
        <Loader customLoadingContainer={{ height: 150 }} />
      ) : failed ? (
        <View>Failed to load</View>
      ) : (
        <View>
          <View style={styles.subContainer}>
            <View style={styles.alignSubContainer}>
              <View style={styles.alignContainer}>
                <View style={styles.labelContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerAlign]}
                  >
                    <TouchableOpacity onPress={() => signInHandler()}>
                      {sessionDetails.sessionScanMode === SINGLE_SCAN ? "Scan" : "Scan In"}
                    </TouchableOpacity>
                  </Text>
                </View>
                <View style={styles.labelValueContainer}>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="entity"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText]}
                  >
                    <TouchableOpacity onPress={() => signInHandler()}>
                      {": " + signIn?.length}
                    </TouchableOpacity>
                  </Text>
                </View>
              </View>
              {sessionDetails.sessionScanMode !== SINGLE_SCAN && (
                <View style={styles.alignContainer}>
                  <View style={styles.labelContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerAlign]}
                    >
                      <TouchableOpacity onPress={() => signOutHandler()}>
                        {t("common:scanOut")}
                      </TouchableOpacity>
                    </Text>
                  </View>
                  <View style={styles.labelValueContainer}>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="entity"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText]}
                    >
                      <TouchableOpacity onPress={() => signOutHandler()}>
                        {": " + signOut?.length}
                      </TouchableOpacity>
                    </Text>
                  </View>
                </View>
              )}
              <View style={styles.alignContainer}>{/* Blank */}</View>
              <View></View>
            </View>
          </View>

          <View style={styles.borderContainer}></View>

          <View style={styles.subContainer}>
            <View style={styles.alignSubContainer}>
              <View style={styles.alignContainer}>
                <View style={styles.labelContainer}>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerAlign]}
                  >
                    {t("common:admin")}
                  </Text>
                </View>
                <View style={styles.labelValueContainer}>
                  <Text
                    onPress={() => {
                      handleAdminOthers();
                    }}
                    fontWeight={FontWeight.Light}
                    testId="entity"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText]}
                  >
                    {": " + adminCount?.length}
                  </Text>
                </View>
              </View>
              <View style={styles.alignContainer}>
                <View style={styles.labelContainer}>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerAlign]}
                  >
                    <TouchableOpacity onPress={() => trainerRedirectionHandler()}>
                      {t("common:trainer")}
                    </TouchableOpacity>
                  </Text>
                </View>
                <View style={styles.labelValueContainer}>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Light}
                    testId="entity"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText]}
                  >
                    <TouchableOpacity onPress={() => trainerRedirectionHandler()}>
                      {": " + trainerCount?.length}
                    </TouchableOpacity>
                  </Text>
                </View>
              </View>
              <View style={styles.alignContainer}>
                {" "}
                <View style={styles.labelContainer}>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerAlign]}
                  >
                    {t("common:other")}
                  </Text>
                </View>
                <View style={styles.labelValueContainer}>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="entity"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText]}
                  >
                    <TouchableOpacity onPress={() => handleAdminOthers()}>
                      {": " + otherCount?.length}
                    </TouchableOpacity>
                  </Text>
                </View>
              </View>
              <View>
                {/* <Icon
                  testID="close"
                  name={IconNames.penPaper}
                  customStyle={styles.penPaperIcon}
                  onPress={() => participantListRedirectHandler(false)}
                /> */}
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    marginBottom: 20
    // marginLeft: 35
  },
  subContainer: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 13,
    paddingBottom: 13
  },
  alignSubContainer: {
    flexDirection: "row",
    marginLeft: 35
    // width:'60%'
  },
  alignContainer: {
    flexDirection: "row",
    width: "20%"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerAlign: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textLight
  },
  penPaperIcon: {
    fontSize: 15,
    opacity: 0.6
  },
  labelContainer: {
    flex: 45
  },
  labelValueContainer: {
    flex: 55
  },
  borderContainer: {
    borderBottomColor: colorPallete.cordioTaupe,
    borderBottomWidth: 1,
    borderStyle: "solid",
    opacity: 0.1,
    width: "100%"
  }
});
