import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Entity } from "@socion-cordio/common/src/models/registry/entity";
import { RegistryApiClient } from "@socion-cordio/common/src/network/registryApiClient";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  registryEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";

class EntityRepository {
  getEntities = async (reqBody: { userId: string }): Promise<Entity> => {
    let data = await RegistryApiClient.post(registryEndPoints.getEntities(), reqBody);
    console.log("list api", data);
    return data?.result || [];
  };
  getAllEntities = async (reqBody?: { userId: string }): Promise<Entity> => {
    let data = await RegistryApiClient.post(
      `${registryEndPoints.getEntities()}?all=true&fields=entityId,name`,
      reqBody
    );
    return data?.result || [];
  };
  searchEntitiesGlobal = async (reqBody?: any): Promise<Entity> => {
    let data = await RegistryApiClient.post(
      `${registryEndPoints.searchEntities()}?all=true`,
      reqBody
    );
    return data?.result || [];
  };
  createEntity = async (reqBody: {}): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.registerEntity(), reqBody);
    return data?.result || [];
  };
  readEntity = async (entityId: string): Promise<any> => {
    let data = await RegistryApiClient.get(registryEndPoints.readEntity(entityId));
    console.log("readEntityreadEntity", data);
    return data?.result || [];
  };
  updateEntity = async (reqBody: {}, entityId: string): Promise<any> => {
    let data = await RegistryApiClient.patch(registryEndPoints.updateEntity(entityId), reqBody);
    return data?.result || [];
  };

  toggleEntity = async (reqBody: {}, entityId: string, isActivate: boolean): Promise<any> => {
    const url = isActivate
      ? registryEndPoints.activateEntity(entityId)
      : registryEndPoints.deactivateEntity(entityId);
    let data = await RegistryApiClient.patch(url, reqBody);
    return data?.result || [];
  };
}
const entityRepository = new EntityRepository();
export { entityRepository as EntityRepository };
