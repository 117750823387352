import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  dispatchAllEntityList,
  dispatchEntityAdmins,
  dispatchEntityDetails,
  dispatchEntityList,
  dispatchEntityPartners,
  dispatchEntityPrograms,
  EntityHelper
} from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";

interface Props {
  callbackFromParentHandler?: Function;
  isDropdownOpen?: boolean;
  setIsDropdownOpen?: any;
  isBanner?: boolean;
}

export default function EntityDropdown(props: Props) {
  const { callbackFromParentHandler, setIsDropdownOpen, isDropdownOpen, isBanner } = props;
  const [entityDetails, setEntityDetails] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);
  const [val, setVal] = useState(null);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history: any = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  let isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));

  const {
    entities: entities,
    entity: entity,
    allEntities: allEntities,
    loading: { getEntities: loading, getAllEntities: allLoading },
    error: { getEntities: error, getAllEntities: allError }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    setIsLoading(true);
    if (isSuperAdmin) {
      dispatchAllEntityList(dispatch);
    } else {
      dispatchEntityList(dispatch);
    }
  }, []);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    if (entities !== null || allEntities !== null) {
      getSelectedEntityDetails();
    }
  }, [entities, allEntities]);

  useEffect(() => {
    if (isSuperAdmin) {
      setIsLoading(allLoading);
    } else {
      setIsLoading(loading);
    }
  }, [loading, allLoading]);

  useEffect(() => {
    if (error || allError) {
      setIsLoading(false);
    }
  }, [error, allError]);

  const getSelectedEntityDetails = async () => {
    const selectedEntity: any = await EntityHelper.getSelectedEntityDetails();
    setEntityDetails(selectedEntity);
    setEntityHandler(selectedEntity);
  };

  const setEntityHandler = (selectedEntity: any) => {
    const entitiesList = isSuperAdmin ? allEntities : entities;
    const list: any = entitiesList?.map((data: any, id: any) => {
      return { label: data.name, value: data.osid };
    });
    console.log("entities", entitiesList);
    const entityData = selectedEntity ? selectedEntity : entitiesList[0];
    setVal(entityData?.osid);
    setContainerWidth(entityData?.name?.length);
    setItems(list);
  };

  const onChangeValue = async () => {
    const entitiesList = isSuperAdmin ? allEntities : entities;
    if (entitiesList !== null || entitiesList?.length > 0) {
      const selectedEntity = entitiesList.filter((item: any) => item?.osid === val);
      if (selectedEntity) {
        setContainerWidth(selectedEntity[0].name.length);
        dispatchEvents(selectedEntity[0]);
        callbackFromParentHandler(selectedEntity[0].name);
        await LocalStorage.setStorage("selectedEntity", selectedEntity[0]);
      }
    }
  };

  const dispatchEvents = async (selectedEntity: any) => {
    const selEntity: any = await EntityHelper.getSelectedEntityDetails();
    // console.log("selEntityselEntityselEntity", selEntity);
    // const entityData = entity ? entity : selEntity;
    // console.log("selEntityselEntityselEntity entuiw", selectedEntity, selEntity, entityData);
    if (!selEntity || (selEntity && selectedEntity?.osid !== selEntity?.osid)) {
      if (location.pathname.includes("/entity")) {
        dispatchEntityDetails(dispatch, selectedEntity);
      }
      if (location.pathname.includes("/admin")) {
        console.log("locatuoibsnbds", selectedEntity);
        dispatchEntityAdmins(dispatch, selectedEntity);
      }
      if (location.pathname.includes("/program")) {
        dispatchEntityPrograms(dispatch, selectedEntity);
      }

      if (location.pathname.includes("/partnership")) {
        dispatchEntityPartners(dispatch, selectedEntity);
      }

      if (
        location.pathname.includes("/program/partners") ||
        location.pathname.includes("/program/details")
      ) {
        history.push(`${routeNames.app}${routeNames.PROGRAM}`);
      }
    }
  };

  const redirectionHandler = async () => {
    history.push(`${routeNames.app}${routeNames.workspace}`);
  };

  return (
    <>
      <View style={[styles.mainContainer2, { maxWidth: "100%" }]}>
        <View>
          <TouchableOpacity
            style={isLoading ? styles.alignIcon : styles.alignIcon2}
            onPress={() => redirectionHandler()}
          >
            <Icon testID="close" name={IconNames.backToWorkspace} customStyle={styles.iconStyle} />
          </TouchableOpacity>
        </View>
        <>
          {isLoading && (
            <View style={isBanner ? { padding: 11 } : { padding: 15 }}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="headerText"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.headerFontSize]}
              >
                {"Loading Entity Names . . ."}
              </Text>
            </View>
          )}

          {!isLoading && (
            <Dropdown
              open={isDropdownOpen}
              dropdownValue={val}
              items={items}
              setOpen={setIsDropdownOpen}
              setValue={setVal}
              setItems={setItems}
              onChangeValue={onChangeValue}
              containerWidthStyle={[
                styles.containerStyle,
                containerWidth <= 50
                  ? { width: "37ch" }
                  : containerWidth > 100 && containerWidth <= 200
                  ? { width: "92ch" }
                  : { width: "90%" }
              ]}
              containerRadius={styles.dropDownContainerStyle}
              pickerStyle={styles.alignTextHeader}
              labelStyles={styles.labelStyles}
              placeholderStyle={styles.placeholderStyle}
              customItemContainerStyle={styles.customItemContainerStyle}
              listItems={styles.listItems}
              listItemLabelStyle={styles.listItemLabelStyle}
              arrowIconStyle={styles.arrowIconStyle}
              closeAfterSelecting={true}
              // programDropDown={true}
            />
          )}
        </>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer2: {
    zIndex: 1,
    flexDirection: "row",
    // padding: 20,
    alignItems: "center",
    paddingBottom: 5
  },

  alignIcon: {
    marginRight: 30
  },
  alignIcon2: {
    marginRight: 30,
    paddingTop: 23
  },
  iconStyle: {
    fontSize: 18,
    paddingLeft: 20
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerFontSize: {
    fontSize: 14
  },
  containerStyle: {
    marginBottom: 0,
    alignItems: "flex-start"
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "center",
    marginBottom: 0
  },

  alignTextHeader: {
    paddingTop: 25,
    marginLeft: 0,
    marginRight: 0,
    padding: 5
  },
  labelStyles: {
    paddingLeft: 0,
    fontFamily: FontFamily.Bold,
    fontWeight: FontWeight.Bold,
    maxWidth: 1200,
    flex: 1
  },
  placeholderStyle: {},
  customItemContainerStyle: {
    padding: 5
  },
  listItems: {
    padding: 5
  },
  listItemLabelStyle: {
    fontFamily: FontFamily.Bold,
    fontWeight: FontWeight.Bold
  },
  arrowIconStyle: {
    width: 25,
    height: 19,
    marginLeft: 5
  }
});
