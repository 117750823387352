import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View, Linking, TouchableOpacity } from "react-native";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import TopicTaskPreview from "@socion-cordio/common/src/components/organisms/topic/topicTaskPreview";
interface Props {
  topicDetails: any;
  getTopicDetails: Function;
  userRoles: any[];
  isTaskAccessible: any;
  applicationId?: any;
  isAssessmentAccessible?: boolean;
  superAdmin?: boolean;
}

export default function TopicDetailsAssessments(props: Props): ReactElement {
  const {
    topicDetails,
    getTopicDetails,
    userRoles,
    isTaskAccessible,
    applicationId,
    isAssessmentAccessible,
    superAdmin
  } = props;
  const [surveyDtoLink, setSurveyDtoLink] = useState(null);
  const [surveyLink, setSurveyLink] = useState(null);
  const [isLinkDeleted, setIsLinkDeleted] = useState(false);
  const [topicStatus, setTopicStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [redirectToCustom, setRedirectToCustom] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const messageTextValue = "Are you sure you want to delete this Assessment?";
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAssessmentItem, setSelectedAssessmentItem] = useState(null);
  const [showCreateAssessment, setShowCreateAssessment] = useState(false);

  useEffect(() => {
    getAssessmentDetails();
  }, [window.location.pathname, isLoading, topicDetails]);

  const getAssessmentDetails = async () => {
    try {
      setAssessmentDetails(null);
      setLoading(true);
      let response = await ApiClient.get(
        topicEndPoints.getAssessmentDetails(props?.topicDetails?.id)
      );

      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        const res = response?.response?.response;
        setAssessmentDetails(res);
        setLoading(false);
        setIsLoading(false);
        for (let item of res) {
          if (item?.status === "DRAFT" || item?.status === "ACTIVE") {
            setShowCreateAssessment(false);
            return;
          }
        }
        setShowCreateAssessment(true);
      } else if (response.responseCode === HTTP_STATUS_CODES.notFound) {
        setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    topicStatusHandler(topicDetails);
  }, [topicDetails]);

  const topicStatusHandler = (topicDetails: any) => {
    let status = topicDetails?.status === "ACTIVE" ? true : false;
    setTopicStatus(status);
  };

  useEffect(() => {
    if (isLinkDeleted) disableScroll();
    else enableScroll();
  }, [isLinkDeleted]);

  const addSurveyLinkHandler = async () => {
    // setLoading(true);
    // const payload = {
    //   topicId: topicDetails.id,
    //   url: surveyLink,
    //   taskType: "EXTERNAL",
    //   applicationId: applicationId
    // };
    // const response = await ApiClient.post(topicEndPoints.addTaskLink(), payload);
    // if (response.responseCode === 201) {
    //   toast.success("Task created successfully");
    //   setTaskId(response.response.taskId);
    //   setAssessmentDetails(response?.response);
    //   getAssessmentDetails();
    //   getTopicDetails(props?.topicDetails?.id);
    // } else if (response.responseCode === 404) {
    //   toast.error(response.message);
    //   setLoading(false);
    // } else {
    //   toast.error("Something went Wrong.");
    //   setLoading(false);
    // }
  };

  const removeSurveyLinkHandler = async (isDeleted: boolean) => {
    if (selectedAssessmentItem?.formType !== "CUSTOM") {
      if (isDeleted === true) {
        setIsLinkDeleted(false);
        setLoading(true);
        const payload = {
          topicId: props?.topicDetails?.id,
          taskId: selectedAssessmentItem?.assessmentId,
          taskType: "EXTERNAL",
          status: "DELETED"
        };
        const response = await ApiClient.put(topicEndPoints.addTaskLink(), payload);
        if (response.responseCode === 200) {
          toast.success("Deleted successfully");
          getAssessmentDetails();
          getTopicDetails(props?.topicDetails?.id);
          setAssessmentDetails(null);
          setSurveyLink(null);
        } else {
          toast.error("Something went Wrong.");
          setLoading(false);
        }
      }
    } else {
      if (isDeleted) {
        setIsLinkDeleted(false);
        setLoading(true);
        try {
          let user: any = await LocalStorage.getStorage("user");
          let token = await LocalStorage.getStorage("accessToken");
          let response = await axios.delete(
            `https://pda-a.socion.io/taskservice/api/v1/assessment/delete/${assessmentDetails?.taskTemplateId}`,
            {
              headers: {
                "access-token": token,
                "Content-Type": "application/json"
              },
              data: {
                deletedBy: user.userId,
                topicId: props?.topicDetails?.id,
                status: "CLOSED"
              }
            }
          );
          if (response.data.code === HTTP_STATUS_CODES.ok) {
            toast.success("Deleted successfully");
            getAssessmentDetails();
            getTopicDetails(props?.topicDetails?.id);
            setAssessmentDetails(null);
            setSurveyLink(null);
          }
        } catch (error) {
          toast.error("Something went wrong");
          setLoading(false);
        }
      }
    }
  };

  const clearHandler = () => {
    // setSurveyLink("");
  };

  const handleCreateCustom = () => {
    window.location.href = `https://pda-stage.socion.io/tasks/new/app/assessment/create?programId=${props?.topicDetails?.programId}&topicId=${props?.topicDetails?.id}&topicName=${props?.topicDetails?.name}`;
  };
  const handleEdit = (assessmentItem: any) => {
    window.location.href = `https://pda-stage.socion.io/tasks/new/app/assessment/update?programId=${props?.topicDetails?.programId}&assessmentId=${assessmentItem?.assessmentTemplateId}&topicId=${props?.topicDetails?.id}&topicName=${props?.topicDetails?.name}`;
  };

  const renderAssessmentItem = (assessmentItem: any) => {
    return (
      assessmentDetails !== null && (
        <View style={[styles.container, styles.alignTaskLinkContainer]}>
          <View style={styles.subContainerOne}>
            <View style={styles.createCustomTaskContainer}>
              <View style={[styles.alignCreateTaskHeader, styles.alignTaskName]}>
                <TouchableOpacity
                  style={{ width: "86%" }}
                  // onPress={() => Linking.openURL(assessmentDetails?.url)}
                  onPress={() => {
                    setSelectedAssessmentItem(assessmentItem);
                    setShowPreview(true);
                  }}
                >
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.fontSize14, styles.headerTextBold, styles.alignText]}
                  >
                    Assessment_{props?.topicDetails?.name}
                  </Text>
                </TouchableOpacity>
                <View style={{ alignSelf: "center" }}>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={styles.publishStatus}
                  >
                    {assessmentItem?.status === "ACTIVE" ? "PUBLISHED" : assessmentItem?.status}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {assessmentItem?.status !== "CLOSED" &&
            isAssessmentAccessible &&
            topicStatus &&
            !superAdmin && (
              <View style={styles.iconsContainer}>
                {assessmentItem !== null && (
                  <TouchableOpacity onPress={() => handleEdit(assessmentItem)}>
                    <Icon name={IconNames.penPaper} customStyle={styles.edit} />
                  </TouchableOpacity>
                )}
              </View>
            )}
        </View>
      )
    );
  };
  const renderCreateAssessment = () => {
    return (
      <View style={styles.container}>
        <View style={styles.subContainerCreateTask}>
          <TouchableOpacity onPress={handleCreateCustom}>
            <View style={styles.createTaskContainer}>
              <Text
                fontWeight={FontWeight.Light}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.headerTextNormal, styles.fontSize14, styles.fontColorWhite]}
              >
                {"Create Assessment"}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.subContainerTwo}></View>
      </View>
    );
  };
  return loading ? (
    <Loader customLoadingContainer={styles.loader} />
  ) : (
    <View style={styles.mainContainer}>
      {assessmentDetails?.map((item: Object) => renderAssessmentItem(item))}
      {topicStatus && showCreateAssessment && !superAdmin && renderCreateAssessment()}
      {isLinkDeleted && (
        <View>
          <SocionModal
            modalVisible={isLinkDeleted}
            setModalVisible={() => setIsLinkDeleted(!isLinkDeleted)}
            component={
              <RemoveLinkModal
                modalVisible={isLinkDeleted}
                selectedData={removeSurveyLinkHandler}
                textValue={messageTextValue}
                setModalVisible={() => setIsLinkDeleted(!isLinkDeleted)}
              />
            }
          />
        </View>
      )}
      {showPreview && (
        <View>
          <SocionModal
            modalVisible={showPreview}
            customPadding={0}
            setModalVisible={() => setShowPreview(!showPreview)}
            component={
              <TopicTaskPreview
                url={selectedAssessmentItem?.url + "&formType=Assessment"}
                setShowPreview={setShowPreview}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {},
  container: {
    display: "flex",
    flexDirection: "row"
  },
  subContainerOne: {
    flex: 90
  },
  subContainerTwo: {
    flex: 10,
    justifyContent: "center"
  },
  alignTaskLinkContainer: {
    marginTop: 15
  },

  // input field
  titleInputContainerStyles: {
    marginTop: 0
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 14,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Regular
  },
  textHeaderField: {
    flex: 20,
    fontSize: 14
  },
  alignContainerButton: {
    fontSize: 17,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25,
    width: 18,
    height: 18
  },

  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },
  headerTextNormal: {
    fontFamily: FontFamily.Medium,
    lineHeight: 17
    // marginBottom: 5
  },

  // font sizes
  fontSize14: {
    fontSize: 14
  },

  // font colors
  fontColorWhite: {
    color: colorPallete.white
  },

  headerTextBold: {
    fontFamily: FontFamily.Bold
  },
  orContainer: {
    marginVertical: 20,
    alignSelf: "center"
  },
  alignText: {
    marginVertical: 5
  },

  // Create Custome Container
  createCustomTaskContainer: {
    minHeight: 57,
    borderColor: colorPallete.cordioTaupe,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  alignCreateTaskHeader: {
    paddingHorizontal: 20,
    flex: 1,
    flexWrap: "wrap"
  },
  alignTaskName: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  createTaskContainer: {
    height: 49,
    width: 160,
    borderRadius: 5,
    backgroundColor: colorPallete.cordioRed,
    marginHorizontal: 4,
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center"
  },
  iconMainContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: 12
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    fontSize: 17
  },

  // Loader
  loader: {
    height: 150
    // transform: [{ scale: 0.8 }]
  },
  edit: {
    color: colorPallete.cordioTaupe,
    fontSize: 17,
    marginLeft: 30
    // marginRight: 44
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  publishStatus: {
    fontSize: 14,
    fontFamily: FontFamily.Bold,
    fontStyle: "italic",
    color: colorPallete.textBlack
    // alignSelf: "center"
  },
  subContainerCreateTask: {
    flex: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});
