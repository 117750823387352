import React, { ReactElement, useEffect, useState, useRef } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import CreateSessionSidePanelPage from "@socion-cordio/common/src/components/organisms/session/createSessionSidePanelPage";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  programEndPoints,
  sessionPackEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import AddViewRemoveMemberModal from "@socion-cordio/common/src/components/organisms/session/addViewRemoveMember";
import RemoveMemberModal from "@socion-cordio/common/src/components/organisms/common-modals/removeMember";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Moment from "moment-timezone";
// import Moment from "moment";
import moment from "moment";
import CancelSessionModal from "../common-modals/cancelSessionModal";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { useSelector } from "react-redux";
import { StoreProviderService } from "@socion-cordio/common/src/services/StoreProviderService";
import { ProgramActions } from "@socion-cordio/common/src/modules/program/actions/actions";
interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

const Styles = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: inline-grid;
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
    width: 350px;
  }
  .react-datepicker__input-container {
    input {
      border: 1px solid lightgrey;
      border-radius: 5px;
      font-family: Montserrat-Medium;
      font-size: 12px;
      height: 40px;
      padding: 7px;
      font-weight: lighter;
    }
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal !important;
  }
`;

const calculateMinTime = (date: any) => {
  let isToday = Moment(date).isSame(Moment(date).tz(), "day");
  if (isToday) {
    let nowAddOneHour = Moment(new Date()).toDate();
    return nowAddOneHour;
  }
  return Moment().startOf("day").toDate();
};

export default function CreateSessionPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [sessionDetail, setSessionDetail] = useState(null);
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const [userTrainerList, setUserTrainerList] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [name, setName] = useState(null);
  const [displayUserListTrainers, setdisplayUserListTrainers] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [editSelectedUser, setEditSelectedUser] = useState(false);
  const [cancelCreateSession, setCancelCreateSession] = useState(false);
  const [isEditButtonClicked, setIsEditButtonClicked] = useState(false);
  const [sessionStartDate, setSessionStartDate] = useState(null);
  const [sessionEndDate, setSessionEndDate] = useState(null);
  const [sessionVenue, setSessionVenue] = useState(null);
  const [isRemoveMember, setIsRemoveMember] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState(null);
  const [isStartDateTimeCheck, setIsStartDateTimeCheck] = useState(false);
  const [isEndDateTimeCheck, setIsEndDateTimeCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTrainer, setIsTrainer] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minTime, setMinTime] = useState(null);
  const [showLabel, setShowLabel] = useState(true);
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(null);
  const [items, setItems] = useState([]);
  const [dropdownPlaceholderVal, setDropdownPlaceholderVal] = useState(null);
  const [timeZoneValue, setTimeZoneValue] = useState(null);
  const [timeZoneName, setTimeZoneName] = useState(null);
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [endDateSelected, setEndDateSelected] = useState(false);
  const [startDateCalendarOpened, setStartDateCalendarOpened] = useState(false);
  const [endDate2, setEndDate2] = useState(null);
  const [initialStateStartDate, setInitialStateStartDate] = useState(0);
  const endDateInputRef = useRef(null);
  const [isStartDateCalenderOpen, setIsStartDateCalenderOpen] = useState(false);

  // const [userMemberRole,setUserMemberRole]=useState(null)
  const location: any = useLocation();
  const history: any = useHistory();
  const selectedProgramDetails = useSelector(
    (state: any) => state.programs?.selectedProgramDetails
  );

  useEffect(() => {
    setSessionDetail(location?.state?.sessionData);
    getUserList(location?.state?.sessionData?.programId);
    getUserDetails();
    let defaultTimeZone = Moment.tz.guess();
    setTimeZone();
    setTimeZoneName(defaultTimeZone);
    // setCurrentDateTime(Moment().format("YYYY-MM-DDTHH:mm"));
    setCurrentDateTime(new Date());
    setMinTime(calculateMinTime(new Date(), defaultTimeZone));
  }, []);

  useEffect(() => {
    if (cancelCreateSession || isRemoveMember || editSelectedUser) disableScroll();
    else enableScroll();
  }, [cancelCreateSession, isRemoveMember, editSelectedUser]);

  const convertTZ = (date: any, tzString: any) => {
    let newConvertedDate = new Date(date.toLocaleString("en-US", { timeZone: tzString }));
    return newConvertedDate;
  };

  const convertEndTimeTZ = (date: any, tzString: any) => {
    let newConvertedDate;
    if (endDateInputRef.current.props.selected == "") {
      if (date) {
        newConvertedDate = new Date(date);
        newConvertedDate.setMinutes(newConvertedDate.getMinutes() + 60);
        setIsEndDateTimeCheck(false);
        return newConvertedDate;
      } else {
        let currentDate = new Date();
        newConvertedDate = new Date(currentDate.toLocaleString("en-US", { timeZone: tzString }));
        setIsEndDateTimeCheck(false);
        return "";
      }
    } else {
      setIsEndDateTimeCheck(false);
      return endDate;
    }
  };

  const setTimeZoneNameOnChange = (val: any) => {
    let tzName;
    items?.map((timeZone: any, id: any) => {
      if (val === id) {
        let name = timeZone.label.split(" ");
        tzName = name[1];
        setTimeZoneName(name[1]);
      }
    });
    const date = new Date();
    let setNewDate = convertTZ(date, tzName);
    setMinTime(calculateMinTimeOnChange(setNewDate, tzName));
    // setCurrentDateTime(Moment().format("YYYY-MM-DDTHH:mm"));
    setCurrentDateTime(setNewDate);
    setStartDate(setNewDate);
    // setEndDate(convertEndTimeTZ(date, tzName));
    return;
  };

  const calculateMinTime = (date: any, timeZoneName: any) => {
    let isToday = Moment(date).isSame(Moment(date).tz(timeZoneName), "day");
    if (isToday) {
      let nowAddOneHour = Moment(date).tz(timeZoneName).toDate();
      return nowAddOneHour;
    }
    return Moment().startOf("day").toDate();
  };

  const calculateMinTimeOnChange = (date: any, timeZoneName: any) => {
    // if (!date) {
    //   date = Moment(convertTZ(new Date(), timeZoneName)).tz(timeZoneName);
    //   console.log("When date is not there", date);
    // }

    let isToday = Moment(date).isSame(
      Moment(convertTZ(new Date(), timeZoneName)).tz(timeZoneName),
      "day"
    );
    if (isToday) {
      let nowAddOneHour = Moment(convertTZ(new Date(), timeZoneName)).tz(timeZoneName).toDate();
      return nowAddOneHour;
    }
    return Moment().startOf("day").toDate();
  };

  const startSessionDateCheck = (startDate: any) => {
    const sessionStartDate = Moment(startDate).format("YYYY-MM-DDTHH:mm");
    const sessionEndDateCalcualte = Moment(endDate).format("YYYY-MM-DDTHH:mm");
    let tempCurrent = Moment(convertTZ(new Date(), timeZoneName)).format("YYYY-MM-DDTHH:mm");
    if (tempCurrent > sessionStartDate) {
      toast.error(
        "Start date and time should be earlier than end date and time & later than current date and time!"
      );
      setIsStartDateTimeCheck(true);
    } else {
      setIsStartDateTimeCheck(false);
    }
    if (sessionStartDate > sessionEndDateCalcualte) {
      toast.error(
        "Start date and time should be earlier than end date and time & later than current date and time!!"
      );
      setIsStartDateTimeCheck(true);
    } else {
      setIsStartDateTimeCheck(false);
    }
  };

  const sessionEndDateCheck = (date: any) => {
    const sessionStartDateCalculate = Moment(startDate).format("YYYY-MM-DDTHH:mm");
    const sessionEndDate = Moment(date).format("YYYY-MM-DDTHH:mm");
    if (sessionStartDateCalculate >= sessionEndDate && sessionEndDate <= currentDateTime) {
      toast.error("End date and time should be later than the start and current date and time");
      setIsEndDateTimeCheck(true);
    } else if (sessionStartDateCalculate >= sessionEndDate) {
      // toast.error("End date and time should be greater than start date and time!");
      toast.error("End date and time should be later than the start and current date and time!");
      setIsEndDateTimeCheck(true);
    } else {
      setIsEndDateTimeCheck(false);
    }
  };

  const startDateOpenden = (dateChack: any) => {
    // setStartDateCalendarOpened(true);
    if (!startDateCalendarOpened) {
      toast.error("Select Start date first!!");
      setIsEndDateTimeCheck(true);
    } else {
      setIsEndDateTimeCheck(true);
    }
  };

  const getUserList = async (id: number) => {
    try {
      const response = await ApiClient.get(programEndPoints.getUserList(id), {});
      const trainers = response?.users.filter((member: any) => member.roles.includes("TRAINER"));
      setTrainers(trainers);
      setLoadingRoles(false);
    } catch (error) {
      setLoadingRoles(false);
      toast.error("Something went wrong.");
    }
  };

  const getUserDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setCurrentUserDetails(user);
  };

  const setTimeZone = async () => {
    let timezone_list = Moment.tz
      .names()
      .sort((a, b) => Moment.tz(a).utcOffset() - Moment.tz(b).utcOffset());
    let browsersTimezone = Moment.tz.guess();
    let selectedIndex: any = null;
    const modifiedTimeZoneList: any = timezone_list?.map((timeZone: any, id: any) => {
      if (browsersTimezone === timeZone) {
        selectedIndex = id;
      }
      return { label: `(GMT${Moment.tz(timeZone).format("Z")}) ${timeZone}`, value: id };
    });
    modifiedTimeZoneList?.map((timeZone: any, id: any) => {
      if (selectedIndex === id) {
        setDropdownPlaceholderVal(timeZone.label);
        setVal(timeZone.value);
      }
    });
    setItems(modifiedTimeZoneList);
  };

  const searchUserHandler = (value: any) => {
    setName(value);
    value?.length !== 0 ? setdisplayUserListTrainers(true) : setdisplayUserListTrainers(false);
    const user: any = trainers.filter(
      (u) =>
        u.name.toLowerCase().includes(value.toLowerCase()) ||
        u.phoneNumber.includes(value) ||
        u.emailId.includes(value)
    );
    setUserTrainerList(user);
  };

  const addUserHandler = (data: any) => {
    setName(""); // clear search field
    setIsEditButtonClicked(false);
    setdisplayUserListTrainers(false);
    setSelectedUserData(data); // selected user data object
    const isAlreadyExistsCheck = selectedUserList.find((x) => x.userId === data.userId);
    if (isAlreadyExistsCheck) {
      toast.error("This user is already registered in the system.!");
    } else {
      setEditSelectedUser(true); //modal open and passing the selected data
      googleWebAnalytics("Create_Session_Add_Member_Modal", "Button", "Session");
    }
  };

  const setUserMemberRole = (roles: any) => {
    const newSelectedUserBody = {
      ...selectedUserData,
      roles
    };
    if (isEditButtonClicked === false) {
      const list = selectedUserList.concat(newSelectedUserBody);
      setSelectedUserList(list);
    }
    if (isEditButtonClicked === true) {
      const user = [...selectedUserList];
      let index = user.findIndex((obj) => obj.userId === newSelectedUserBody.userId);
      user[index] = newSelectedUserBody;
      setSelectedUserList(user);
    }
    setIsEditButtonClicked(false);
    setEditSelectedUser(false); //modal close
  };

  const formatRoles = (data: any) => {
    let formatString = "";
    let otherText = "";
    Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (key === "trainer") {
          formatString += "Trainer ";
        }
        if (key === "admin") {
          formatString += "Admin ";
        }
        if (key === "otherRoleNames") {
          otherText = `${value}`;
        }
      })
    );
    let newString = formatString.split(" ").join(", ").replace(/,\s*$/, "");
    let finalString = newString !== "" ? `${newString},${otherText}` : otherText;
    return otherText == "" ? newString : finalString;
  };

  const editSelectedUserHandler = (data: any) => {
    setSelectedUserData(data);
    setEditSelectedUser(true); //modal open
    setIsEditButtonClicked(true);
    googleWebAnalytics("Create_Session_Add_Member_Modal", "Button", "Session");
  };

  const deleteSelectedUserHandler = (data: any) => {
    setIsRemoveMember(true);
    setSelectedUserData(data);
    googleWebAnalytics("Create_Session_Delete_Member_Modal", "Button", "Session");
  };

  const deleteMemberResponse = (val: any) => {
    try {
      if (val === true) {
        const user = [...selectedUserList];
        const removeUser = user.filter((x) => x.userId !== selectedUserData.userId);
        setSelectedUserList(removeUser);
        setIsRemoveMember(false);
        googleWebAnalytics("CreateSession_RemoveMemberSession", "Button", "Session");
      }
    } catch (error) {
      setIsRemoveMember(false);
      toast.error("Something went wrong.");
    }
  };

  const getTimeZone = () => {
    let newData = new Date().toTimeString().split("(");
    let tempArray = newData[newData.length - 1].split(" ");
    let arr: any = [];
    tempArray.map((i) => {
      i.split("");
      arr.push(i[0]);
    });
    return `${newData[0].slice(9)}(${arr.join("")})`;
  };

  const DATE_FORMAT = {
    UTC: "YYYY-MM-DD HH:mm:ss.SSS",
    dddMMMDDYYYYhmmA: "ddd MMM DD,YYYY h:mm A",
    dddMMMDDYYYY: "ddd MMM DD, YYYY",
    hhmmAMMMDDYYY: "hh:mm A | MMM DD, YYYY",
    hhmmA: "hh:mm A",
    YYYYMMdd: "YYYY-MM-DD",
    DDMMYYYY: "DD-MM-YYYY",
    DDMMMYYYY: "DD - MMM - YYYY",
    DDMMYYYYhhmmA: "DD-MM-YYYY hh:mm A",
    DDMMMYYYYhhmmA: "DD-MMM-YYYY hh:mm A",
    HHmmss: "HH:mm:ss",
    DDMMMMYYYY: "DD-MMMM-YYYY",
    DDMMMMDDYYYYhmmA: "DD-MMMM-YYYY   h:mm A",
    DDMMYYYYhmmA: "DD-MM-YYYY h:mm A",
    DDMMYYYYhmmssA: "DD-MM-YYYY h:mm:ss A",
    DDMMMYYYYhmmssA: "DD-MMM-YYYY h:mm:ss A",
    hmmA: "h:mm A",
    zeroFormat: "00:00:00.000"
  };

  const submitSessionHandler = async () => {
    setLoading(true);
    let timeZone = getTimeZone();
    let selectedTimeZone;
    let timezone_name;
    try {
      const memberList = selectedUserList.map(({ roles, userId }) => ({ roles, userId }));
      const rolesList = selectedUserList.map(({ roles }) => ({ roles }));
      const isTrainerPresent = rolesList.find((x) => x.roles.hasOwnProperty("trainer"));
      let timezone_offset;
      let newTimeZoneOffset;
      items?.map((timeZone: any, id: any) => {
        if (val === id) {
          var timezone_val = timeZone.label.split(" ");
          timezone_offset = timezone_val[0];
          newTimeZoneOffset = timezone_offset.replace("(", "").replace(")", "");
          timezone_name = timezone_val[1];
          let timezone_abbr = auxiliaryMethods.getLocalTimezoneName(
            newTimeZoneOffset,
            timezone_name
          );
          selectedTimeZone = timezone_abbr;
        }
      });

      const start_date = Moment(startDate).format(DATE_FORMAT.UTC);
      const end_date = Moment(endDate).format(DATE_FORMAT.UTC);

      let converted_start_date_utc = Moment.tz(start_date, DATE_FORMAT.UTC, timeZoneName)
        .utc()
        .format(DATE_FORMAT.UTC);
      let converted_end_date_utc = Moment.tz(end_date, DATE_FORMAT.UTC, timeZoneName)
        .utc()
        .format(DATE_FORMAT.UTC);

      const currentTimeWhileSubmitting = Moment(convertTZ(new Date(), timeZoneName)).format(
        "YYYY-MM-DDTHH:mm"
      );
      const startTime = Moment(startDate).format("YYYY-MM-DDTHH:mm");
      if (currentTimeWhileSubmitting >= startTime) {
        toast.error(
          "Start date and time should be earlier than end date and time & later than current date and time!"
        );
        setLoading(false);
        return;
      } else {
        if (isTrainerPresent) {
          const response = await ApiClient.post(sessionPackEndPoints.updateSessionDetails(), {
            address: sessionVenue,
            members: memberList,
            sessionDescription: location?.state?.sessionData?.description,
            sessionName: location?.state?.sessionData?.name,
            sessionStartDate: converted_start_date_utc,
            sessionEndDate: converted_end_date_utc,
            sessionTimeZone: selectedTimeZone,
            topicId: location?.state?.sessionData?.id,
            currentSelectedTimezone: timezone_name
          });
          if (response.responseCode === HTTP_STATUS_CODES.ok) {
            setLoading(false);
            updateTelemetry(response, converted_start_date_utc, converted_end_date_utc);
            toast.success("Session created Successfully!!!");
            history.push(`${routeNames.app}${routeNames.SESSION}`);
            redirectToViewSessionHandler(response.response);
            googleWebAnalytics("Create_Session_Submit_Session", "Button", "Session");
          } else {
            toast.error("Unable to create session");
            setLoading(false);
            backRedirectionHandler();
          }
        } else {
          setLoading(false);
          toast.error("At least one trainer should be added.");
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong.");
      backRedirectionHandler();
    }
  };
  const backRedirectionHandler = () => {
    history.goBack();
    history.goBack();
    StoreProviderService.dispatch(
      ProgramActions.getSelectedProgramData({
        ...selectedProgramDetails,
        isCreateSessionBlocked: true
      })
    );
  };

  const redirectToViewSessionHandler = (data: any) => {
    history.push("/new/app/session/view-session/upcoming", {
      sessionData: { session: { id: data.sessionId } }
    });
  };

  const updateTelemetry = async (
    data: any,
    converted_start_date_utc: any,
    converted_end_date_utc: any
  ) => {
    const programData: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    // const programDetails: {} = { name: programData.program.name, id: programData.program.id };
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Create session",
      sessionStartDate: converted_start_date_utc,
      sessionEndDate: converted_end_date_utc,
      sessionId: data.response.sessionId,
      sessionName: location?.state?.sessionData?.name,
      venue: sessionVenue,
      topicId: sessionDetail.id,
      topicName: sessionDetail.name,
      programId: programData?.program?.id,
      programName: programData?.program?.name,
      entityId: "null",
      entityname: "null"
    };
    AddTelemetryService(body, undefined, user, true);
    googleWebAnalytics("Create_Session", "Telemetry_Event", "Sessions");
  };

  const cancelCreateSessionHandler = () => {
    setCancelCreateSession(true);
    googleWebAnalytics("Create_Session_Modal_Cancel_Session", "Button", "Session");
  };

  const cancel = (value: any) => {
    if (value === true) {
      setCancelCreateSession(false);
      history.push(`${routeNames.app}${routeNames.SESSION}`);
      googleWebAnalytics("Create_Session_Cancel_Session", "Button", "Session");
    }
  };

  // useEffect(() => {
  //   let tempArray: boolean[] = [];
  //   selectedUserList.map((i) => {
  //     if (i.roles.hasOwnProperty("trainer")) tempArray.push(true);
  //     else tempArray.push(false);
  //   });
  //   if (tempArray.includes(true)) setIsTrainer(true);
  //   else setIsTrainer(false);
  // }, [selectedUserList]);

  return (
    <View style={styles.mainContainer}>
      {loading ? (
        <View style={styles.loaderContainer}>
          <View style={styles.subLoaderContainer}>
            <Loader />
          </View>
        </View>
      ) : (
        <View style={styles.container}>
          <View style={styles.rightContainer}>
            <View style={styles.subContainer}>
              <View style={styles.subContainerOne}>
                <View style={styles.headerContainer}>
                  <View style={styles.iconContainerHeader}>
                    <Icon
                      testID="down"
                      name={IconNames.down}
                      customStyle={styles.titleIcon}
                      onPress={() => {
                        history.goBack();
                      }}
                    />
                  </View>
                  <View>
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                    >
                      {t("session:createSession")}
                    </Text>
                  </View>
                  <View>
                    <Text
                      onPress={() => {}}
                      fontWeight={FontWeight.Regular}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
                    >
                      {">"}
                    </Text>
                  </View>
                  <View style={styles.headerNameContainer}>
                    <Text
                      onPress={() => {}}
                      fontWeight={FontWeight.Regular}
                      testId="text"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.headerTextSupport]}
                    >
                      {sessionDetail?.name}
                    </Text>
                  </View>
                </View>

                <View
                  style={
                    width > 768 ? styles.addMemberScheduleContainer : { flexDirection: "column" }
                  }
                >
                  <View style={styles.addMemberContainer}>
                    <View style={styles.addMemberSubContainer} pointerEvents="none">
                      <ButtonTab
                        type={ButtonTypeSecondary.Secondary}
                        iconName={IconNames.addMember}
                        buttonStyles={styles.addMemberButton}
                        textStyles={{ fontSize: 12 }}
                        title={"Add member"}
                        // disabled={}
                      />
                    </View>

                    <View style={styles.wrapperContainer}>
                      <View style={styles.inputContainer}>
                        {loadingRoles ? (
                          <View style={{ height: 40 }}>
                            {" "}
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="text"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                            >
                              {t("session:loadingMemberRoles")}
                            </Text>
                          </View>
                        ) : (
                          <UserInput
                            handleChange={(value: any) => searchUserHandler(value)}
                            handleBlur={() => {}}
                            value={name}
                            placeholder={t("common:searchByNameOrMobile")}
                            name="countryInput"
                            id="countryInput"
                            userStyle={styles.inputStylingAlignment}
                            input={styles.inputStyle1}
                            noFormik
                            editable={true}
                            showEditIcon={false}
                          />
                        )}
                        <View style={{ position: "absolute", top: 40, width: "100%", height: 100 }}>
                          <ScrollContainer height={"110px"} scrollable={true}>
                            {displayUserListTrainers &&
                              userTrainerList?.map((prop: any, key: any) => {
                                return (
                                  <View key={key} style={{ display: "flex", flexDirection: "row" }}>
                                    {/* <View style={{ flex: 50 }}></View> */}
                                    <TouchableOpacity
                                      onPress={() => addUserHandler(prop)}
                                      style={[
                                        styles.listDropdownContainer,
                                        { backgroundColor: "white" }
                                      ]}
                                    >
                                      <View key={key}>
                                        <Text
                                          fontWeight={FontWeight.Light}
                                          testId="text"
                                          textSize={TextSize.Small}
                                          textStyle={[
                                            styles.headerText,
                                            styles.headerTextTitleSupport,
                                            { marginBottom: 2 }
                                          ]}
                                        >
                                          {prop.name}
                                        </Text>
                                        <Text
                                          fontWeight={FontWeight.Light}
                                          testId="text"
                                          textSize={TextSize.Small}
                                          textStyle={[
                                            styles.headerText,
                                            styles.headerColor,
                                            { fontSize: 10 }
                                          ]}
                                        >
                                          {prop.countryCode}
                                          {"-"}
                                          {prop.phoneNumber} / {prop.emailId}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                  </View>
                                );
                              })}
                          </ScrollContainer>
                        </View>
                      </View>
                      <View style={styles.userListMainContainer}>
                        <View style={styles.memberContainer}>
                          <View style={styles.profilePictureContainer}>
                            <View>
                              <Image
                                testId="socion"
                                imageStyle={styles.profileImage}
                                source={
                                  currentUserDetails?.photo ||
                                  require("@socion-cordio/common/src/assets/images/user_circle.svg")
                                }
                              />
                            </View>
                          </View>
                          <View style={styles.nameContainer}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="text"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                            >
                              {currentUserDetails?.name}
                            </Text>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="text"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerColor, styles.fontSize12]}
                            >
                              {currentUserDetails?.piiInfo
                                ? `${currentUserDetails?.countryCode}-${currentUserDetails?.userName}`
                                : "Contact Number : NA"}
                            </Text>
                            {currentUserDetails?.piiInfo ? (
                              currentUserDetails?.emailId === "" ? (
                                "Contact Email : "
                              ) : (
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="text"
                                  textSize={TextSize.Small}
                                  textStyle={[
                                    styles.headerText,
                                    styles.headerColor,
                                    styles.fontSize12
                                  ]}
                                >
                                  {currentUserDetails?.emailId}
                                </Text>
                              )
                            ) : currentUserDetails?.emailId === "" ? (
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="text"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.headerText,
                                  styles.headerColor,
                                  styles.fontSize12
                                ]}
                              >
                                {"Contact Email : NA"}
                              </Text>
                            ) : (
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="text"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.headerText,
                                  styles.headerColor,
                                  styles.fontSize12
                                ]}
                              >
                                {"Contact Email : NA"}
                              </Text>
                            )}
                          </View>
                          <View style={styles.memberDesignationContainer}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="text"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerColor, { fontSize: 10 }]}
                            >
                              {t("session:sessionCreator")}
                            </Text>
                          </View>
                        </View>
                        {auxiliaryMethods.fromatBaseLocation(currentUserDetails) !== "" ? (
                          <View style={styles.alignLocationContainer}>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="text"
                              textSize={TextSize.Small}
                              textStyle={[
                                styles.headerText,
                                styles.headerColor,
                                styles.headerTextAlign,
                                styles.fontSize10
                              ]}
                            >
                              {t("common:baseLocation")}
                            </Text>
                            <Text
                              fontWeight={FontWeight.Light}
                              testId="text"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText, styles.headerColor, styles.fontSize10]}
                            >
                              {auxiliaryMethods.fromatBaseLocation(currentUserDetails)}
                            </Text>
                          </View>
                        ) : null}
                      </View>

                      {selectedUserList?.map((prop: any, key: React.Key) => {
                        return (
                          <View key={key} style={styles.userListMainContainer}>
                            <View style={styles.miniIconContainer}>
                              <Icon
                                onPress={() => {
                                  deleteSelectedUserHandler(prop);
                                }}
                                testID="delete"
                                name={IconNames.deleteFile}
                                customStyle={[styles.alignContainerButton]}
                              />
                              <Icon
                                onPress={() => {
                                  editSelectedUserHandler(prop);
                                }}
                                testID="edit"
                                name={IconNames.edit}
                                customStyle={[styles.alignContainerButton]}
                              />
                            </View>
                            {/* <View
                              style={{
                                flexDirection: "row",
                                flex: 1,
                                alignItems: "center",
                                marginBottom: 15
                              }}
                            > */}
                            <View style={styles.memberSubContainer}>
                              <View style={styles.profilePictureContainer}>
                                <View>
                                  <Image
                                    testId="socion"
                                    imageStyle={styles.profileImage}
                                    source={
                                      prop?.photo ||
                                      require("@socion-cordio/common/src/assets/images/user_circle.svg")
                                    }
                                  />
                                </View>
                              </View>
                              <View style={styles.nameContainer}>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="text"
                                  textSize={TextSize.Small}
                                  textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                                >
                                  {prop.name}
                                </Text>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="text"
                                  textSize={TextSize.Small}
                                  textStyle={[
                                    styles.headerText,
                                    styles.headerColor,
                                    styles.fontSize12
                                  ]}
                                >
                                  {prop?.piiInfo
                                    ? `${prop.countryCode}-${prop.phoneNumber}`
                                    : "Contact Number : NA"}
                                </Text>
                                {prop.piiInfo ? (
                                  prop.emailId === "" ? null : (
                                    <Text
                                      fontWeight={FontWeight.Light}
                                      testId="text"
                                      textSize={TextSize.Small}
                                      textStyle={[
                                        styles.headerText,
                                        styles.headerColor,
                                        styles.fontSize12
                                      ]}
                                    >
                                      {prop.emailId}
                                    </Text>
                                  )
                                ) : prop.emailId === "" ? (
                                  <Text
                                    fontWeight={FontWeight.Light}
                                    testId="text"
                                    textSize={TextSize.Small}
                                    textStyle={[
                                      styles.headerText,
                                      styles.headerColor,
                                      styles.fontSize12
                                    ]}
                                  >
                                    {"Contact Email : NA"}
                                  </Text>
                                ) : (
                                  <Text
                                    fontWeight={FontWeight.Light}
                                    testId="text"
                                    textSize={TextSize.Small}
                                    textStyle={[
                                      styles.headerText,
                                      styles.headerColor,
                                      styles.fontSize12
                                    ]}
                                  >
                                    {"Contact Email : NA"}
                                  </Text>
                                )}
                              </View>
                              <View style={styles.memberDesignationContainer}>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="text"
                                  textSize={TextSize.Small}
                                  textStyle={[
                                    styles.headerText,
                                    styles.headerColor,
                                    { fontSize: 10 }
                                  ]}
                                >
                                  {formatRoles(prop?.roles)}
                                </Text>
                              </View>
                            </View>
                            {auxiliaryMethods.fromatBaseLocation(prop) !== "" ? (
                              <View style={styles.alignLocationContainer}>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="text"
                                  textSize={TextSize.Small}
                                  textStyle={[
                                    styles.headerText,
                                    styles.headerColor,
                                    styles.headerTextAlign,
                                    styles.fontSize10
                                  ]}
                                >
                                  {t("common:baseLocation")}
                                </Text>
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="text"
                                  textSize={TextSize.Small}
                                  textStyle={[
                                    styles.headerText,
                                    styles.headerColor,
                                    styles.fontSize10
                                  ]}
                                >
                                  {auxiliaryMethods.fromatBaseLocation(prop)}
                                </Text>
                              </View>
                            ) : null}
                          </View>
                        );
                      })}
                    </View>
                  </View>
                  <View>
                    {editSelectedUser && (
                      <AddViewRemoveMemberModal
                        modalVisible={editSelectedUser}
                        selectedUserData={selectedUserData}
                        setUserMemberRole={setUserMemberRole}
                        isEditButtonClicked={isEditButtonClicked}
                        setModalVisible={() => setEditSelectedUser(!editSelectedUser)}
                      />
                    )}
                    {/* {editSelectedUser && (
                      <View>
                        <SocionModal
                          modalVisible={editSelectedUser}
                          setModalVisible={() => setEditSelectedUser(!editSelectedUser)}
                          component={
                            <AddViewRemoveMemberModal
                              modalVisible={editSelectedUser}
                              selectedUserData={selectedUserData}
                              setUserMemberRole={setUserMemberRole}
                              isEditButtonClicked={isEditButtonClicked}
                              setModalVisible={() => setEditSelectedUser(!editSelectedUser)}
                            />
                          }
                        />
                      </View>
                    )} */}
                  </View>

                  <View>
                    {/* {isRemoveMember && (
                      <RemoveMemberModal
                        modalVisible={isRemoveMember}
                        selectedUserData={deleteMemberResponse}
                        textValue={t("session:removeMemberFromSession")}
                        setModalVisible={() => setIsRemoveMember(!isRemoveMember)}
                      />
                    )} */}
                    {isRemoveMember && (
                      <View>
                        <SocionModal
                          modalVisible={isRemoveMember}
                          setModalVisible={() => setIsRemoveMember(!isRemoveMember)}
                          component={
                            <RemoveMemberModal
                              modalVisible={isRemoveMember}
                              selectedUserData={deleteMemberResponse}
                              textValue={t("session:removeMemberFromSession")}
                              setModalVisible={() => setIsRemoveMember(!isRemoveMember)}
                            />
                          }
                        />
                      </View>
                    )}
                  </View>
                  <View style={styles.dividerContainer}>{/* Divider */}</View>

                  <View style={styles.addScheduleContainer}>
                    <View style={styles.addMemberSubContainer} pointerEvents="none">
                      <ButtonTab
                        type={ButtonTypeSecondary.Primary}
                        iconName={IconNames.schedule}
                        buttonStyles={styles.addMemberButton}
                        textStyles={{ fontSize: 12 }}
                        title={t("common:schedule")}
                      />
                    </View>

                    <View>
                      <View
                        style={[styles.wrapperContainer, { paddingLeft: 25, position: "relative" }]}
                      >
                        <View
                          style={[
                            styles.alignUserInputFields,
                            styles.alignUserInputFieldsTimeZone,
                            showLabel ? { zIndex: 1 } : { zIndex: -1 }
                          ]}
                        >
                          <View style={{ flex: 30 }}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText]}
                            >
                              {showLabel ? t("common:timeZone") : t("")}
                            </Text>
                          </View>
                          <View style={{ flex: 80 }}>
                            <Dropdown
                              open={open}
                              dropdownValue={val}
                              items={items}
                              setOpen={setOpen}
                              setValue={setVal}
                              dropdownPlaceholder={dropdownPlaceholderVal}
                              setItems={setItems}
                              containerWidthStyle={styles.timeZonecontainerStyle}
                              containerRadius={styles.dropDownContainerStyle}
                              closeAfterSelecting={true}
                              listItems={styles.listItems}
                              containerStyle={styles.ContainerStyle2}
                              onChangeValue={(val) => [
                                setTimeZoneValue(val),
                                setTimeZoneNameOnChange(val),
                                setEndDateSelected(false),
                                setStartDateSelected(false),
                                setStartDateCalendarOpened(false),
                                setEndDate2(""),
                                setInitialStateStartDate(0),
                                setEndDate("")
                              ]}
                              textStyle={styles.dropDownTextStyle}
                              arrowIconStyle={styles.arrowIconStyle}
                              pickerStyle={{ paddingVertical: 5, marginLeft: 4 }}
                              labelStyles={{ width: 100 }}
                            />
                          </View>
                        </View>

                        <View style={[styles.alignUserInputFields, styles.alignUserInputFieldsTwo]}>
                          <View style={styles.alignSubTextLabel}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText]}
                            >
                              {t("common:start")}
                            </Text>
                          </View>

                          <View style={styles.dateTimePickerContainer}>
                            <Styles>
                              <DatePicker
                                showTimeSelect
                                selected={startDateSelected ? startDate : ""}
                                onChange={(date: any) => {
                                  setStartDate(date);
                                  startSessionDateCheck(date);
                                  setMinTime(calculateMinTimeOnChange(date, timeZoneName));
                                  setInitialStateStartDate(initialStateStartDate + 1);
                                  googleWebAnalytics(
                                    "Create_Session_Start_Date",
                                    "Button",
                                    "Session"
                                  );
                                }}
                                dateFormat="dd/MM/yyyy h:mm aa"
                                timeIntervals={"15"}
                                popperPlacement="left"
                                placeholderText={"Select Start Date"}
                                minDate={currentDateTime}
                                minTime={minTime}
                                maxTime={Moment().endOf("day").toDate()}
                                onCalendarClose={() => [
                                  setShowLabel(true),
                                  setEndDate2(startDate),
                                  setIsStartDateCalenderOpen(false)
                                ]}
                                onCalendarOpen={() => [
                                  setShowLabel(false),
                                  setStartDateSelected(true),
                                  setStartDateCalendarOpened(true),
                                  // startDateOpenden(true)
                                  setInitialStateStartDate(initialStateStartDate + 1),
                                  startSessionDateCheck(startDate),
                                  setIsStartDateCalenderOpen(true)
                                ]}
                              />
                            </Styles>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.alignUserInputFields,
                            styles.alignUserInputFieldsTwo,
                            isStartDateCalenderOpen && { zIndex: -1 }
                          ]}
                        >
                          <View style={[styles.alignSubTextLabel, { zIndex: -50 }]}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="internal"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText]}
                            >
                              {showLabel ? t("common:end") : null}
                            </Text>
                          </View>

                          <View style={styles.dateTimePickerContainer}>
                            <Styles>
                              <DatePicker
                                showTimeSelect
                                selected={endDateSelected ? endDate : ""}
                                onChange={(date: any) => {
                                  setEndDate(date);
                                  sessionEndDateCheck(date);
                                  setMinTime(calculateMinTimeOnChange(date, timeZoneName));
                                  googleWebAnalytics(
                                    "Create_Session_End_Date",
                                    "Button",
                                    "Session"
                                  );
                                }}
                                dateFormat="dd/MM/yyyy h:mm aa"
                                timeIntervals={"15"}
                                popperPlacement="left"
                                placeholderText={"Select End Date"}
                                minDate={currentDateTime}
                                minTime={minTime}
                                maxTime={Moment().endOf("day").toDate()}
                                onCalendarClose={(date: any) => [
                                  setShowLabel(true)
                                  // sessionEndDateCheck(endDate)
                                ]}
                                onCalendarOpen={() => [
                                  setShowLabel(false),
                                  setEndDateSelected(true),
                                  startDateOpenden(true),
                                  setEndDate(convertEndTimeTZ(endDate2, timeZoneName)),
                                  setMinTime(
                                    calculateMinTimeOnChange(
                                      convertEndTimeTZ(endDate2, timeZoneName),
                                      timeZoneName
                                    )
                                  )
                                ]}
                                ref={endDateInputRef}
                              />
                            </Styles>
                          </View>
                        </View>
                        <View style={[styles.alignUserInputFields, { zIndex: -1 }]}>
                          <UserInputSecondary
                            textValue={"Venue"}
                            handleChange={(sessionVenue: string) => {
                              setSessionVenue(sessionVenue);
                            }}
                            handleBlur={() => {
                              googleWebAnalytics("Create_Session_Venue", "Button", "Session");
                            }}
                            value={sessionVenue}
                            // value={formikProps.values.qualificationTitle}
                            // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                            placeholder={t("common:enterVenue")}
                            name="countryInput"
                            id="countryInput"
                            titleInputContainerStyles={{ marginTop: 0 }}
                            userStyle={styles.inputStylingAlignment}
                            userStyleText={[styles.textHeaderField, { flex: 30 }]}
                            noFormik
                          />
                        </View>
                        <View style={[styles.alignButtonContainer, { zIndex: -1 }]}>
                          <Button
                            type={ButtonType.Secondary}
                            buttonStyles={styles.submitbutton}
                            textStyles={styles.submitButtonText}
                            title={t("common:cancel")}
                            onPress={() => cancelCreateSessionHandler()}
                            // disabled={true}
                          />
                          <Button
                            type={ButtonType.Primary}
                            buttonStyles={styles.submitbutton}
                            textStyles={styles.submitButtonText}
                            title={t("session:createSession")}
                            onPress={() => submitSessionHandler()}
                            disabled={
                              sessionVenue === null ||
                              sessionVenue === "" ||
                              startDate === null ||
                              endDate === null ||
                              isStartDateTimeCheck ||
                              isEndDateTimeCheck
                            }
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {cancelCreateSession && (
            <View>
              <SocionModal
                modalVisible={cancelCreateSession}
                setModalVisible={() => setCancelCreateSession(!cancelCreateSession)}
                component={
                  <CancelSessionModal
                    modalVisible={cancelCreateSession}
                    sendData={cancel}
                    setModalVisible={() => setCancelCreateSession(!cancelCreateSession)}
                  />
                }
              />
            </View>
          )}
          {/* {cancelCreateSession && (
            <View>
              <CancelSessionModal
                modalVisible={cancelCreateSession}
                sendData={cancel}
                setModalVisible={() => setCancelCreateSession(!cancelCreateSession)}
              />
            </View>
          )} */}
          <View style={styles.leftContainer}>
            <View style={{ marginTop: 60 }}>
              <CreateSessionSidePanelPage sessionDetail={sessionDetail} />
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    height: "100%"
  },
  container: {
    flexDirection: "row",
    height: "100%"
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    flex: 3.2,
    height: "100%"
  },
  loaderContainer: {
    padding: 20
  },
  subLoaderContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    padding: 20
  },
  leftContainer: {
    // height: "calc(100vh - 50px)",
    marginTop: -50,
    flex: 1,
    backgroundColor: colorPallete.cordioRedDark2
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    marginRight: 10,
    transform: [{ rotate: "90deg" }]
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  fontSize10: {
    fontSize: 10
  },
  fontSize12: {
    fontSize: 12
  },
  headerColor: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  headerTextAlign: {
    fontFamily: FontFamily.Bold
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    padding: 18,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    height: "92%"
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  subContainerOne: {
    height: "92%"
  },

  //Header Container
  headerNameContainer: {
    width: "81%"
  },
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerContainer: {
    flexDirection: "row",
    marginBottom: 20,
    alignItems: "center"
  },
  headerTextSupport: {
    fontWeight: "400",
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 10
  },

  //addMemberScheduleContainer
  addMemberScheduleContainer: {
    flexDirection: "row",
    height: "100%"
  },
  dividerContainer: {
    borderRightColor: colorPallete.cordioTaupe,
    borderRightWidth: 1,
    borderStyle: "solid",
    opacity: 0.1
  },
  addMemberContainer: {
    flex: 1.2,
    marginBottom: 20,
    padding: 10
  },
  addMemberSubContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 50
  },
  addMemberButton: {
    width: 137,
    height: 77,
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 12
  },
  textHeaderField: {
    flex: 45,
    fontSize: 12
  },
  textHeaderFieldUserInputDateTime: {
    flex: 35,
    fontSize: 12
  },
  wrapperContainer: {
    paddingLeft: 15,
    paddingRight: 15
  },
  inputContainer: {
    marginBottom: 15
  },
  titleInputContainerStyles: {
    marginTop: 0
  },
  userListMainContainer: {
    zIndex: -1, //so that the searchdropdown appears on top
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupe,
    borderRadius: 10,
    marginTop: 15,
    minHeight: 77,
    // alignItems: "center",
    paddingRight: 5,
    paddingLeft: 5
  },
  memberContainer: {
    zIndex: -1, //so that the searchdropdown appears on top
    flexDirection: "row",
    marginTop: 10
  },
  memberSubContainer: {
    flexDirection: "row",
    flex: 1,
    marginBottom: 15
  },
  profilePictureContainer: {
    flex: 20,
    alignItems: "center"
  },
  nameContainer: {
    flex: 59
  },
  memberDesignationContainer: {
    marginTop: 10,
    flex: 24
  },
  alignLocationContainer: {
    alignItems: "flex-end",
    textAlign: "end",
    marginRight: 5,
    marginBottom: 5
  },

  //addScheduleContainer
  addScheduleContainer: {
    flex: 1,
    marginBottom: 20,
    padding: 10
  },
  alignUserInputFields: {
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center"
  },
  alignUserInputFieldsTimeZone: {
    marginTop: "9%",
    display: "flex",
    justifyContent: "space-between"
  },
  alignUserInputFieldsTwo: {
    justifyContent: "space-between"
  },
  alignButtonContainer: {
    marginTop: 30,
    marginBottom: 30,
    justifyContent: "space-between",
    flexDirection: "row"
  },
  submitbutton: {
    minWidth: "120px",
    height: "50px",
    borderRadius: 10
    // marginLeft: 5,
    // marginRight: 5
  },
  submitButtonText: {
    fontSize: 12
  },
  profileImage: {
    width: 33,
    height: 33,
    borderRadius: 50
  },
  listDropdownContainer: {
    borderColor: "lightgrey",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 5,
    paddingLeft: 10,
    flex: 80
  },
  miniIconContainer: {
    flexDirection: "row-reverse",
    marginTop: 5
  },
  alignContainerButton: {
    fontSize: 12,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25
  },
  subheaderText: {
    // flex: 12,
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
    // marginTop: 13
  },
  alignSubTextLabel: {
    // flex: 20
    // width:'22%'
  },
  dateTimePickerContainer: {
    // flex:80,
    width: "73%"
  },
  inputStyle: {
    width: "100%"
  },
  inputStyle1: {
    width: "100%",
    fontSize: 12
  },
  listItems: {
    padding: 10
  },
  ContainerStyle2: {
    borderColor: colorPallete.lightGreyTwo,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    minHeight: 40,
    marginBottom: 0,
    justifyContent: "center"
  },
  timeZonecontainerStyle: {
    width: "100%"
  },
  dropDownTextStyle: {
    fontSize: 12
  },
  arrowIconStyle: {
    width: 15,
    height: 16,
    marginLeft: 3
  }
});
