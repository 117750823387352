import React from "react";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import MapRoutes from "navigation/mapRoutes";
import { ROLES } from "@socion-cordio/common/src/config/roles";
import Profile from "@socion-cordio/web/src/modules/profile/index";
import ProfilePage from "@socion-cordio/web/src/modules/profile/components/profilePage";
import { Roles } from "@socion-cordio/common/src/constants/roles";
import ChangePassword from "@socion-cordio/common/src/components/organisms/changePassword";
import About from "@socion-cordio/common/src/components/organisms/about";
import PrivacyPolicy from "@socion-cordio/common/src/components/organisms/privacyPolicy";
import TermsOfUse from "@socion-cordio/common/src/components/organisms/termsOfUse";
import Settings from "@socion-cordio/common/src/components/organisms/settings";
import AdditionalProfileInfo from "@socion-cordio/common/src/components/organisms/badges/mobile-badge-class/additionalProfileInfo";
import MyAccount from "@socion-cordio/common/src/components/organisms/myAccount";

interface Props {}
const profileRoutes = () => [
  {
    path: routeNames.PROFILE,
    component: Profile,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess],
    children: [
      {
        path: routeNames.PERSONALINFO,
        component: ProfilePage,
        isExact: false,
        permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
      },
      {
        path: routeNames.MYACCOUNT,
        component: MyAccount,
        isExact: false,
        permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
      },
      {
        path: routeNames.ABOUT,
        component: About,
        isExact: false,
        permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
      },
      {
        path: routeNames.PRIVACYPOLICY,
        component: PrivacyPolicy,
        isExact: false,
        permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
      },
      {
        path: routeNames.TERMSOFUSE,
        component: TermsOfUse,
        isExact: false,
        permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
      },
      {
        path: routeNames.SETTINGS,
        component: Settings,
        isExact: false,
        permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
      },
      {
        path: routeNames.ADDITIONALPROFILEINFO,
        component: AdditionalProfileInfo,
        isExact: false,
        permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
      }
    ]
  }
];

const profileRouter = () => {
  return profileRoutes();
};
export default profileRouter;
