import React, { ReactElement } from "react";
import { StyleSheet, View, StyleProp, ViewStyle, TextStyle } from "react-native";
import Select from "react-select";

const styles = StyleSheet.create({
  container: {}
});

interface IProps {
  containerStyle?: StyleProp<ViewStyle>;
  customStyles?: any;
  optionsList: any[];
  value: any[];
  onChange?: Function;
  placeHolderText?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
}

export default function DropdownPicker(props: IProps): ReactElement {
  const {
    containerStyle,
    customStyles,
    optionsList,
    value,
    onChange,
    placeHolderText,
    isSearchable = false,
    isClearable = false
  } = props;

  let placeHolderTextValue = placeHolderText ?? "Select a value";

  return (
    <View style={[styles.container, containerStyle]}>
      <Select
        isClearable={isClearable}
        value={value}
        onChange={(item, e) => onChange(item, e)}
        placeholder={placeHolderTextValue}
        options={optionsList}
        menuPortalTarget={document.body}
        isSearchable={isSearchable}
        styles={customStyles}
        components={{
          // divider between text and dropdown arrow key
          IndicatorSeparator: () => null
        }}
      />
    </View>
  );
}
