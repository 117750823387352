import React, { useEffect } from "react";
const querystring = require("querystring");
import { Route, Redirect } from "react-router-dom";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import axios from "axios";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

const AuthenticatedRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        //TODO - don't use localStorage
        const token: any = localStorage.getItem("accessToken");
        const user = JSON.parse(localStorage.getItem("user"));
        if (token && user) {
          return <Component {...props} user={user} />;
        } else if (
          window.location.pathname.includes("accomplishment") ||
          window.location.pathname.includes("additionalProfileInfo")
        ) {
          return <Component {...props} user={user} />;
        } else {
          LocalStorage.clearStorage();
          return (
            <Redirect
              to={{
                pathname: routeNames.HOME,
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
export default AuthenticatedRoute;
