import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

class UserRepository {
  getUserDetails = async (userId: number, params: { piiFilter: boolean }): Promise<any> => {
    const response = await ApiClient.get(profileEndPoints.getSelectedUserDetails(userId), params);
    return response;
  };
}
const userRepository = new UserRepository();
export { userRepository as UserRepository };
