import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { verifyEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import VerifyRoleModal from "@socion-cordio/common/src/components/organisms/verify/verifyRoleQualificationModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { toast } from "react-toastify";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";

interface Props {
  programDetails: any;
  userRoles: any[];
}

export default function RoleVerification(props: Props): ReactElement {
  const { userRoles } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [rolesVerificationResponse, setRolesVerificationResponse] = useState(null);
  const [verifyRoleModal, setVerifyRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    getRolesVerification();
  }, []);

  useEffect(() => {
    if (verifyRoleModal) disableScroll();
    else enableScroll();
  }, [verifyRoleModal]);

  const getRolesVerification = async () => {
    try {
      const { program } = props?.programDetails;
      const response = await ApiClient.get(verifyEndPoints.getRolesVerification(program.id));
      response?.response.forEach((role: any, index: number) => {
        role.serialNo = `${index + 1}.`;
        role.submittedOn = dataHelper.formatDate(role?.requestedDate);
        role.formattedLocation = formatLocation(role?.person);
        role.verificationDate = role?.responseDate ? dataHelper.formatDate(role?.responseDate) : "";
      });
      // setRolesResponse(rolesResponse);
      setRolesVerificationResponse(response?.response);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setRolesVerificationResponse([]);
      setLoading(false);
      setFailed(true);
    }
  };
  const handleRowClick = (data: any) => {
    if (data?.deleted === false) {
      setSelectedRole(data);
      setVerifyRoleModal(!verifyRoleModal);
      googleWebAnalytics("Role_Verification_View_Details", "Button", "Role_Verification");
    }
  };

  const formatLocation = (data: any) => {
    let formattedLocation: string = "";
    // city, subdist, district, state, country
    if (data?.city) {
      formattedLocation += `${data?.city}, `;
    }
    if (data?.subDistrict) {
      formattedLocation += `${data?.subDistrict}, `;
    }
    if (data?.district) {
      formattedLocation += `${data?.district}, `;
    }
    if (data?.state) {
      formattedLocation += `${data?.state}, `;
    }
    if (data?.country) {
      formattedLocation += `${data?.country} `;
    }
    return formattedLocation;
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, role?.serialNo);
        }
      },

      {
        Header: "Name",
        accessor: "person.name",
        width: 50,
        maxWidth: 50,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return cellData("name", style, role?.person?.name);
        }
      },
      {
        Header: "Role",
        accessor: "roleTitle",
        width: 40,
        maxWidth: 40,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {};
          return cellData("roleTitle", style, role?.roleTitle);
        }
      },
      {
        Header: "Submitted on",
        accessor: "requestedDate",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {};
          return cellData("creationDate", style, role?.submittedOn);
        }
      },
      {
        Header: "Location",
        accessor: "formattedLocation",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {};
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(role.person) !== "None" ? (
                <CustomTooltip
                  hoverValue={role.formattedLocation}
                  textValue={auxiliaryMethods.getUserFirstLocationName(role.person)}
                />
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Establishment",
        accessor: "person.establishmentName",
        width: 65,
        maxWidth: 65,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {};
          const establishmentName = role?.person?.establishmentName
            ? role?.person?.establishmentName
            : "None";
          return cellData("establishmentName", style, establishmentName);
        }
      },
      {
        Header: "Verification date",
        accessor: "responseDate",
        width: 78,
        maxWidth: 78,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {};
          return cellData("responseDate", style, role?.verificationDate);
        }
      },
      {
        Header: "Verified by",
        accessor: "approverDetails.name",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => {
          const style = {};
          return cellData("approvedName", style, role?.approverDetails?.name);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 55,
        maxWidth: 55,
        Cell: ({ row: { original: role } }: { row: { original: any } }) => (
          <View>
            {role.status === "APPROVED" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.approvedColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {role?.status}
                </Text>
              </View>
            ) : role.status === "PENDING" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.pendingColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {role?.status}
                </Text>
              </View>
            ) : role.status === "REJECTED" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.pendingColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {role?.status}
                </Text>
              </View>
            ) : role.status === "DELETED BY USER" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.declineColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {role?.status}
                </Text>
              </View>
            ) : null}
          </View>
        )
      }
    ],
    []
  );

  const noDataFound = () => (
    <View style={styles.noData}>
      <View style={styles.margin15}>
        <Text
          fontWeight={FontWeight.Thin}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.headerAlignment]}
        >
          {"No Role Verification(s) under this Program."}
        </Text>
      </View>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          {/* <VerifyRoleQualificationModal /> */}

          {verifyRoleModal && (
            <View>
              <SocionModal
                modalVisible={verifyRoleModal}
                setModalVisible={() => setVerifyRoleModal(!verifyRoleModal)}
                component={
                  <VerifyRoleModal
                    selectedRole={selectedRole}
                    onClose={() => {
                      setVerifyRoleModal(false);
                      googleWebAnalytics(
                        "Role_Verification_Close_Detail",
                        "Button",
                        "Role_Verification"
                      );
                    }}
                    programDetails={props?.programDetails}
                    getRolesVerification={() => getRolesVerification()}
                    userRoles={userRoles}
                  />
                }
              />
            </View>
          )}
          {rolesVerificationResponse.length == 0 ? (
            noDataFound()
          ) : (
            <Table
              columns={columns}
              data={rolesVerificationResponse}
              handleRowClick={handleRowClick}
              eventAnalytics="RoleVerification"
            />
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 12
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  headerAlignment: {
    paddingHorizontal: 25
  },
  noData: {
    height: "100vh"
  },
  margin15: {
    marginTop: "15px"
  }
});
