import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { StyleSheet, useWindowDimensions, View, TouchableOpacity } from "react-native";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  BADGE_STATUS,
  HTTP_STATUS_CODES,
  BADGE_STATUS_OBJECT
} from "@socion-cordio/common/src/network/constants";
import {
  badgesEndPoints,
  qualificationPackEndPoints,
  participationDetailsEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import AssignBadgeSideBarPanelPage from "@socion-cordio/common/src/components/organisms/badges/assignBadgeSideBarPanel";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
// import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import AssignBadgeModal from "@socion-cordio/common/src/components/organisms/badges/assignBadgeModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import Moment from "moment";
import IndeterminateCheckbox from "@socion-cordio/common/src/utils/tableHelperRowSelection";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import AddBadgeModal from "./addBadgeModal";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";
import CancelButton from "@socion-cordio/common/src/components/atoms/cancelButton";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import FilterModal from "@socion-cordio/common/src/components/organisms/participation-details/filterModal";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import _ from "lodash";

const Styles = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: inline-grid;
  }
  .react-datepicker-popper {
    /* z-index: 9999 !important; */
    /* width: 200% !important; */
    /* margin-left: 16%; */
  }
  .react-datepicker__input-container {
    input {
      border: 1px solid lightgrey;
      border-radius: 5px;
      font-family: Montserrat-Medium;
      font-size: 12px;
      height: 40px;
      padding: 7px;
      font-weight: lighter;
      width: 162px;
    }
    input:focus {
      border: 1px solid lightgrey;
    }
  }
`;

interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

export default function AssignBadgePage(props: Props): ReactElement {
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [badgeUsersList, setBadgeUsersList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [selectedRows, setSelectedRows] = useState(undefined);
  const [assignBadgeModal, setAssignBadgeModal] = useState(false);
  const [assignBadgeData, setAssignBadgeData] = useState(undefined);
  const [badgeDetailsState, setBadgeDetailsState] = useState(null);
  const [loadingBadgeModal, setLoadingBadgeModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateValidity, setStartDateValidty] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [badgeRoleList, setBadgeRoleList] = useState(undefined);
  const [selectedLocationList, setSelectedLocationList] = useState([]);
  const [locationList, setLocationList] = useState(undefined);
  const [selectedBadgeNameList, setSelectedBadgeNameList] = useState([]);
  const [selectedBadgeRoleList, setSelectedBadgeRoleList] = useState([]);
  const [isLocationFliterApplied, setIsLocationFilterApplied] = useState(false);
  const [isBadgeFliterApplied, setIsBadgeFilterApplied] = useState(false);
  const [locationListSecondry, setLocationListSecondry] = useState([]);
  const [locationListButton, setLocationListButton] = useState([]);
  const [badgeRoleListButton, setBadgeRoleListButton] = useState([]);
  const [noneLocationSelected, setNoneLocationSelected] = useState(false);
  const [noAssignedBadgesSelected, setNoAssignedBadgesSelected] = useState(false);
  const [errormessage, seterrormessage] = useState("No users under this Badge.");
  const [checkedListCopy, setCheckedListCopy] = useState([]);
  const [checkedListCopyBadges, setCheckedListCopyBadges] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [programDetails, setProgramDetails] = useState(null);
  const [badgeStatusList, setBadgeStatusList] = useState([]);
  const [updatedBadgeStatusListSecondary, setUpdatedBadgeStatusListSecondary] = useState([]);
  const [isBadgeStatusFliterApplied, setIsBadgeStatusFilterApplied] = useState(false);
  const [badgeRoleLStatusListButton, setBadgeRoleStatusListButton] = useState([]);
  const [badgeStatusListAfterFilter, setBadgeStatusListAfterFilter] = useState([]);
  const [badgeStatusListCopy, setBadgeStatusListCopy] = useState([]);
  const [selectedBadgeStatusListFinal, setSelectedBadgeStatusListFinal] = useState([]);
  const [updatedBadgeStatusListPrimary, setUpdatedBadgeStatusListPrimary] = useState([]);
  const [appliedLocationList, setAppliedLocationList] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState([]);
  const [dateFields, setDateFields] = useState({
    startDate: undefined,
    endDate: undefined
  });
  const [commonData, setCommonData] = useState({
    badgeIds: [],
    locations: [],
    isNoneLocationSet: null,
    isNoBadgeAssignSet: null,
    badgeStatus: []
  });
  const [checkedList, setCheckedList] = useState([]);
  const [expandedList, setExpandedList] = useState([]);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(false);
  const [locationNameList, setLocationNameList] = useState([]);
  const [allExpandedNodes, setAllExpandedNodes] = useState([]);
  const [globalList, setGlobalList] = useState([]);
  // const [isShowBadgeStatus, setShowIsBadgeStatus] = useState(true);
  const history: any = useHistory();
  const location: any = useLocation();

  useEffect(() => {
    getProgramDetails();
    setSelectedBadge(location?.state?.selectedBadge);
    setBadgeDetailsState(location?.state);
    getBadgeUsersList();
  }, []);

  useEffect(() => {
    if (viewButtonDetails) disableScroll();
    else enableScroll();
  }, [viewButtonDetails]);

  useEffect(() => {
    if (assignBadgeModal) disableScroll();
    else enableScroll();
  }, [assignBadgeModal]);

  useEffect(() => {
    if (showFilterModal) disableScroll();
    else enableScroll();
  }, [showFilterModal]);

  useEffect(() => {
    LocalStorage.removeStorage("noneLocationListSet");
    LocalStorage.removeStorage("noAssignedBadgesSelected");
  }, []);

  const getProgramDetails = async () => {
    const programDetails = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };
  const getBadgeUsersList = async (location_d?: any, badgeData?: any) => {
    try {
      scrollToTop();
      const queryParams = {
        badgeId: location?.state?.selectedBadge?.badgeId
        // userListWithoutFilter: true
      };
      const badgeId = location?.state?.selectedBadge?.badgeId;
      let isLocation = await LocalStorage.getStorage("noneLocationListSet");
      let isBadge = await LocalStorage.getStorage("noAssignedBadgesSelected");
      let newListBadgeStatusName = [];
      let badgeStatusNewData: any[] = badgeStatusList.filter((item) => {
        return item.isSelected === true;
      });
      if (badgeStatusNewData.length !== 0) {
        newListBadgeStatusName = badgeStatusNewData.map((a: any) => a.name);
      }
      const payload = {
        locations: location_d !== undefined ? location_d : formatLocation(commonData.locations),
        badgeIds: badgeData !== undefined ? badgeData : commonData.badgeIds,
        disableLocationFilter: isLocation === null ? false : isLocation,
        disableBadgeFilter: isBadge === null ? false : isBadge,
        badgeStatus: badgeStatusNewData.length === 0 ? [] : newListBadgeStatusName
      };
      const response = await ApiClient.post(badgesEndPoints.getBadgeUsersList(badgeId), payload);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        const data = response?.response?.userDetails;
        // data?.forEach((attestationData: any, index: number) => {
        //   attestationData.serialNo = `${index + 1}.`;
        //   attestationData.formattedBaseLocation =
        //     auxiliaryMethods.fromatBaseLocation(attestationData);
        //   attestationData.assignedBadges =
        //     attestationData?.badgeTitles.length !== 0
        //       ? auxiliaryMethods.getVerifiedQualification(attestationData?.badgeTitles)
        //       : "None";
        // });
        // console.log("data-->>1", data);
        sortByBadgesHandler(data);
        // setBadgeUsersList(data);
      } else {
        toast.error("Something went wrong...!!!");
      }
      setLoading(false);
      // setEndDate("YYYY-MM-DD");
      // setStartDate("YYYY-MM-DD");
    } catch (error) {
      toast.error("Something went wrong...!!!");
      setLoading(false);
      setFailed(true);
    }
  };

  const sortByBadgesHandler = (data: any) => {
    data?.forEach((element: any, index: number) => {
      element.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(element);
      element.filteredBadgeDetails = _.orderBy(element.badgeDetails, "badgeTitle", "asc");
      element.badgeTitlesArray = element.filteredBadgeDetails.map((item: any) => item.badgeTitle);
      element.badgeTitlesArrayList =
        element?.badgeTitlesArray.length !== 0
          ? auxiliaryMethods.getVerifiedQualification(element?.badgeTitlesArray)
          : "None";
    });

    // const list = data.sort((a: any, b: any) => {
    //   // return (b.badgeTitlesArrayList - a.badgeTitlesArrayList);
    //   if (a.badgeTitlesArrayList < b.badgeTitlesArrayList) {
    //     return -1;
    //   }
    //   if (a.badgeTitlesArrayList > b.badgeTitlesArrayList) {
    //     return 1;
    //   }
    //   return 0;
    // });
    // console.log("list-->>", list);
    const listNew = _.orderBy(data, "name");
    // listNew?.forEach((element: any, index: number) => {
    //   element.serialNo = `${index + 1}.`;
    // });
    // const listNew = _.orderBy(data, "badgeTitlesArrayList");
    listNew?.forEach((element: any, index: number) => {
      element.serialNo = `${index + 1}.`;
    });
    setBadgeUsersList(listNew);
  };

  const sortByBadgesHandler2 = (data: any) => {
    // console.log("data-->> 2", data);
    const finalAllAssigned: any = [];
    const finalAllExpired: any = [];
    const finalAllBoth: any = [];

    data.forEach((item: any) => {
      let expiredCount = 0;
      let assignedCount = 0;
      item?.badgeDetails?.forEach((item2: any) => {
        expiredCount = item2.badgeStatus === "EXPIRED" ? expiredCount + 1 : expiredCount;
        assignedCount = item2.badgeStatus === "ASSIGNED" ? assignedCount + 1 : assignedCount;
      });
      if (assignedCount > 0 && expiredCount === 0) {
        finalAllAssigned.push({ ...item, expiredCount, assignedCount });
      } else if (assignedCount === 0 && expiredCount > 0) {
        finalAllExpired.push({ ...item, expiredCount, assignedCount });
      } else {
        finalAllBoth.push({ ...item, expiredCount, assignedCount });
      }
    });
    finalAllBoth.sort((a: any, b: any) => {
      return b.assignedCount - b.expiredCount - (a.assignedCount - a.expiredCount);
    });
    let final: any = [...finalAllAssigned, ...finalAllBoth, ...finalAllExpired];
    let minimum: any;
    final.forEach((element: any, index: number) => {
      element.serialNo = `${index + 1}.`;
      element.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(element);
      element.assignedCount2 = element.assignedCount - element.expiredCount;
      if (index === 0) {
        minimum = element.assignedCount2;
      } else {
        if (minimum > element.assignedCount2) {
          minimum = element.assignedCount2;
        }
      }
    });
    final.forEach((element: any, index: number) => {
      element.finalCount = element.assignedCount2 + Math.abs(minimum);
    });
    setBadgeUsersList(final);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const renderTextAndHoverElement = (badge: any, tooltipIcon: any) => {
    const startDate = dataHelper.formatDate(badge.startDate);
    const endDate = dataHelper.formatDate(badge.endDate);
    let hoverValue = "";

    if (badge.startDate === null || badge.startDate === null) {
      hoverValue = "Open Ended";
    } else {
      hoverValue = startDate + " - " + endDate;
    }

    return (
      <View style={[styles.subdateContainer, { flex: 1, flexWrap: "wrap" }]}>
        <Text
          fontWeight={FontWeight.Regular}
          testId="badgeTitle"
          textSize={TextSize.Small}
          textStyle={{
            fontSize: 12,
            fontFamily: FontFamily.Regular
          }}
        >
          {`${badge.badgeTitle} `}
        </Text>
        <CustomTooltip
          hoverValue={hoverValue}
          textValue={
            <Icon
              testID="iconHover"
              name={tooltipIcon}
              customStyle={{
                marginRight: 5
              }}
              size={17}
              color={
                badge.badgeStatus === BADGE_STATUS.ASSIGNED
                  ? colorPallete.cordioGreen
                  : colorPallete.cordioRed
              }
            />
          }
          hoverContainerStyle={{ width: 125, justifyContent: "center" }}
        />
      </View>
    );
  };

  const renderAssignedBadges: any = (badge: any) => {
    const list: any[] = badge?.filteredBadgeDetails.filter(
      (element: any) => element.badgeStatus !== BADGE_STATUS.UNASSIGNED
    );
    const badgeListLength = list?.length;
    return (
      <>
        {list.map((element: any, index: number) => (
          <View key={index} style={{ flexDirection: "row", alignItems: "baseline" }}>
            {element.badgeStatus === BADGE_STATUS.ASSIGNED ? (
              <>{renderTextAndHoverElement(element, IconNames.clockGreenColourTwo)}</>
            ) : element.badgeStatus === BADGE_STATUS.EXPIRED ? (
              <>{renderTextAndHoverElement(element, IconNames.clockRedColourTwo)}</>
            ) : null}
            {badgeListLength - 1 === index ? "" : ", "}
          </View>
        ))}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        id: "selection",
        Header: ({ getToggleAllPageRowsSelectedProps, getToggleAllRowsSelectedProps }: any) => (
          <div style={{ marginLeft: 8 }}>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: any) => (
          <div style={{ marginLeft: 8 }}>
            {!row?.original?.alreadyAssigned && (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            )}
          </div>
        )
      },
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, badge?.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 100,
        maxWidth: 100,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return cellData("name", style, badge?.name, () => {
            displayUserDetails(badge);
          });
        }
      },
      {
        Header: "Badges",
        accessor: "badgeTitlesArrayList",
        width: 130,
        maxWidth: 130,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          // let badgeTitles = auxiliaryMethods.getVerifiedQualification(badge.badgeTitles);
          // if (badgeTitles.length === 0) {
          //   badgeTitles = "None";
          // }
          // return cellData("assignedBadges", style, badgeTitles);
          let badgeDetails: any = badge.filteredBadgeDetails;
          if (badgeDetails !== null) {
            badgeDetails = renderAssignedBadges(badge);
          } else {
            badgeDetails = "None";
          }
          return cellData("assignedBadges", style, badgeDetails);
        }
      },
      {
        Header: "Base Location",
        accessor: "formattedBaseLocation",
        width: 90,
        maxWidth: 90,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(badge) !== "None" ? (
                <CustomTooltip
                  hoverValue={badge.formattedBaseLocation}
                  textValue={auxiliaryMethods.getUserFirstLocationName(badge)}
                />
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      }
    ],
    []
  );

  const handleSelectedRows = (selectedRows: any) => {
    if (selectedRows) {
      const list = selectedRows?.filter((item: any) => item.alreadyAssigned === false);
      setSelectedRows(list);
    }
  };

  const hanldeDateChange = (name: string, value: string) => {
    setDateFields({
      ...dateFields,
      [name]: dataHelper.formatDate(value, "YYYY-MM-DD")
    });
  };

  const formatSelectedRows = (rows: any) => {
    let newRows: any = [];
    rows.forEach((row: any) => {
      let rowObject: any = {};
      rowObject.userId = row.userId;
      rowObject.startDate = selectedBadge?.validity ? Moment(startDate).format("YYYY-MM-DD") : null;
      rowObject.endDate = selectedBadge?.validity ? Moment(endDate).format("YYYY-MM-DD") : null;
      newRows.push(rowObject);
    });
    return newRows;
  };

  const selecteRowsHandler = () => {
    const keys = Object.keys(selectedRowIndex);
    let list: any = [];
    keys?.forEach((element: any) => {
      list.push(badgeUsersList[+element]);
    });
    const newList = list.filter((item: any) => item.alreadyAssigned === false);
    return newList;
  };

  const handleBadgeAssign = async () => {
    try {
      const list = selecteRowsHandler();
      setLoadingBadgeModal(true);
      setAssignBadgeModal(!assignBadgeModal);
      const formattedSelectedRows = formatSelectedRows(list); //selectedRows
      const data = {
        badgeId: location?.state?.selectedBadge?.badgeId,
        programId: location?.state?.programId,
        badgeList: formattedSelectedRows
      };
      const response = await ApiClient.post(badgesEndPoints.previewBadgesUsers(), data);
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        setAssignBadgeData(response);
        setLoadingBadgeModal(false);
      } else {
        // toast.error(response.message);
        // conflict data here
        setAssignBadgeData(response);
        setLoadingBadgeModal(false);
      }
    } catch (error) {
      toast.error("Something went wrong...!!!");
      setLoadingBadgeModal(false);
    }
  };

  const checkStartDate = (date: any, days: any) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    // const newDate = Moment(result).format("YYYY-MM-DD");
    setStartDateValidty(result);
  };

  const capitalizeFirstLetter = (input: any) => {
    if (input !== "") {
      let words = input.split(" ");
      let CapitalizedWords: any = [];
      words.forEach((element: any) => {
        CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
      });
      return CapitalizedWords.join(" ");
    } else {
      return "";
    }
  };

  const formatLocation = (location: any) => {
    let newArray = location.map((i: any) => {
      return {
        ...i,
        city: capitalizeFirstLetter(i.city),
        district: capitalizeFirstLetter(i.district),
        state: capitalizeFirstLetter(i.state),
        subDistrict: capitalizeFirstLetter(i.subDistrict)
      };
    });
    return newArray;
  };

  const removeLocationNoneSelectedLocalStorage = () => {
    setNoneLocationSelected(false);
    setNoAssignedBadgesSelected(false);
    LocalStorage.removeStorage("noneLocationListSet");
    LocalStorage.removeStorage("noAssignedBadgesSelected");
    LocalStorage.removeStorage("globalNameList");
  };

  const filterListToTrue = (list: any) => {
    return list.filter((x: any) => x.isSelected === true);
  };

  const convertBadgeIdsToNameHandler = (data: any) => {
    const badgeIdsList = data.badgeIds;
    const badgesList = data.badgeList;
    const badgeNamesList = badgesList
      ?.filter((item: any) => badgeIdsList?.includes(item.badgeId))
      .map((element: any) => element.name);
    setSelectedBadgeNameList(badgeNamesList);
  };

  const filteredDataHandler = (data: any) => {
    convertBadgeIdsToNameHandler(data);
    setLocationNameList(data.locationNameList);
    setCommonData({
      badgeIds: data.badgeIds,
      locations: data.locations,
      isNoneLocationSet: data.isNoneLocationSet,
      isNoBadgeAssignSet: data.isNoBadgeAssignSet,
      badgeStatus: data.badgeStatus
    });
  };

  const removeLocationList = (data: any) => {
    // find element in the all locations list, locationListSecondry: true list
    const list = locationListSecondry.filter((item: any) =>
      item.formattedBaseLocation.includes(data)
    );

    const appList = [...appliedLocationList];
    const list1 = appList.filter((item: any) => !item.formattedBaseLocation.includes(data));

    // Making all from the list1 as true
    list1.forEach((item: any) => {
      item.isSelected = true;
    });

    setAppliedLocationList(list1);
    // let tempLocations = commonData.locations;
    // let filteredData = tempLocations.filter((k) => !k.formattedBaseLocation.includes(data));
    // setCommonData({ ...commonData, locations: filteredData });
    // Extracting only serial nos as they are used for check/uncheck box
    const serialNoList = list1.map((item: any) => item.serialNo);

    // mark the removed element it as false
    let checkList = [...checkedList];
    // list.forEach((element: any) => {
    //   element.isSelected = false;
    //   const index = checkList.indexOf(element.serialNo);
    //   let data = checkList.splice(index, 1);
    // });
    setCheckedListCopy(serialNoList);
    setCheckedList(serialNoList);
    // remove the location from display
    const updatedLocList = locationListButton.filter((item: any) => item !== data);
    setLocationListButton(updatedLocList);
    LocalStorage.setStorage("globalNameList", updatedLocList);
    let resultFinalBadgeRole = [...badgeRoleListButton];
    filteredDataHandler({
      locations: list1, //locationListSecondry
      badgeIds: resultFinalBadgeRole.map((a: any) => a.badgeId),
      isNoneLocationSet: noneLocationSelected,
      isNoBadgeAssignSet: noAssignedBadgesSelected,
      locationNameList: updatedLocList,
      badgeList: badgeRoleList
    });
    getBadgeUsersList(list1);
  };

  const locationDataConvert = (newList: any) => {
    return newList.map(({ city, subDistrict, district, state, country }: any) => ({
      city,
      subDistrict,
      district,
      state,
      country
    }));
  };

  const checkListHandler = (data: any, checkedListItems: any) => {
    const newCheckedList = checkedListItems.filter(
      (item: any) =>
        // item.includes(data.serialNo) ? false : true
        item !== data.serialNo
    );
    setCheckedList(newCheckedList);
  };

  const removeFilteredListButton = (data: any, value: string) => {
    data.isSelected = !data.isSelected;
    let resultBadge = [];
    let result = [];
    let resultFinalLocation = [...locationListButton];
    let resultFinalBadgeRole = [...badgeRoleListButton];
    let resultFinalBadgeStatus = [...badgeStatusListAfterFilter];
    let resultFinalBadgeRoleTemp = [];
    const list = [...appliedLocationList];
    list.forEach((item: any) => {
      item.isSelected = true;
    });

    if (value === "location") {
      // Updating only when location is removed from the filter list.
      let newList = locationListButton.filter((x: any) => x.isSelected === true);
      setLocationListButton(newList);
      result = locationDataConvert(newList);
      // setResultLocation(result); // previous result
      resultFinalLocation = result;
      checkListHandler(data, checkedList);
      resultFinalBadgeRoleTemp = resultFinalBadgeRole.map((a: any) => a.badgeId);

      filteredDataHandler({
        locations: resultFinalLocation,
        badgeIds: resultFinalBadgeRoleTemp,
        isNoneLocationSet: noneLocationSelected,
        noAssignedBadgesSelected: noAssignedBadgesSelected,
        locationNameList: newList
      });
    }

    if (value === "badgeRole") {
      // Updating only when a badge is removed from the filter list.
      let temp = [...selectedBadgeRoleList];
      let newListBadge = badgeRoleListButton.filter((x: any) => x.isSelected === true);
      setBadgeRoleListButton(newListBadge);
      //setCheckedListCopyBadges(newListBadge);
      resultBadge = newListBadge.map((a: any) => a.badgeId);
      resultFinalBadgeRole = resultBadge;
      // setResultBadgeRole(resultBadge);
      let newArray = temp.filter((i) => i !== data.badgeId);
      setSelectedBadgeRoleList(newArray);
      setCommonData({ ...commonData, badgeIds: newArray });
      filteredDataHandler({
        // locations: resultFinalLocation,
        locations: list,
        badgeIds: resultFinalBadgeRole,
        isNoneLocationSet: noneLocationSelected,
        isNoBadgeAssignSet: noAssignedBadgesSelected,
        // new global list names
        locationNameList: locationListButton,
        badgeList: badgeRoleList
      });
      getBadgeUsersList(list, resultFinalBadgeRole);
      // setLocationListSecondry(globalList);
    }

    if (value === "badgeStatus") {
      // Updating only when a badge status is removed from the filter list.
      let newListBadgeStatus = resultFinalBadgeStatus.filter((x: any) => x.isSelected === true);
      setBadgeRoleStatusListButton(newListBadgeStatus);
      let newListBadgeStatusName = newListBadgeStatus.map((a: any) => a.name);
      setUpdatedBadgeStatusListSecondary(newListBadgeStatusName);
      setBadgeStatusListAfterFilter(newListBadgeStatus);
      resultFinalBadgeRoleTemp = resultFinalBadgeRole.map((a: any) => a.badgeId);
      filteredDataHandler({
        locations: list,
        badgeIds: resultFinalBadgeRoleTemp,
        isNoneLocationSet: noneLocationSelected,
        isNoBadgeAssignSet: noAssignedBadgesSelected,
        // new global list names
        locationNameList: locationListButton,
        badgeList: badgeRoleList,
        badgeStatus: newListBadgeStatusName
      });
      getBadgeUsersList(list);
    }

    if (value === "noLocation") {
      setNoneLocationSelected(false);
      filteredDataHandler({
        locations: list,
        badgeIds: resultFinalBadgeRole.map((a: any) => a.badgeId),
        isNoneLocationSet: false,
        isNoBadgeAssignSet: noAssignedBadgesSelected,
        locationNameList: [],
        badgeList: badgeRoleList
      });
      LocalStorage.removeStorage("noneLocationListSet");
      getBadgeUsersList(list);
    }

    if (value === "noAssignedBadge") {
      // setLocationListSecondry(globalList);
      setNoAssignedBadgesSelected(false);
      filteredDataHandler({
        // locations: resultFinalLocation,
        locations: list,
        badgeIds: resultFinalBadgeRole,
        isNoneLocationSet: noneLocationSelected,
        isNoBadgeAssignSet: false,
        locationNameList: locationListButton,
        badgeList: []
      });
      LocalStorage.removeStorage("noAssignedBadgesSelected");
      getBadgeUsersList(list);
    }
  };

  const clearAllFilter = (isModalClosed: boolean = true) => {
    isModalClosed ? setLoading(true) : null;

    const locList = locationList.forEach((element: any) => {
      element.isSelected = false;
    });
    const badgeList = badgeRoleList.forEach((element: any) => {
      element.isSelected = false;
    });
    const badgeStatus = badgeStatusList.forEach((element: any) => {
      element.isSelected = false;
    });

    setLocationList(locList);
    setBadgeRoleList(badgeList);
    setBadgeStatusList([]);
    setUpdatedBadgeStatusListSecondary([]);
    setBadgeStatusListAfterFilter([]);

    // setLoading(true);
    setSelectedLocationList([]);
    setSelectedBadgeRoleList([]);
    setLocationListButton([]);
    setBadgeRoleListButton([]);
    setBadgeRoleStatusListButton([]);
    setSelectedBadgeStatusListFinal([]);
    filteredDataHandler({
      locations: [],
      badgeIds: [],
      isNoneLocationSet: null,
      isNoBadgeAssignSet: null,
      badgeStatus: []
    });
    setExpandedList([]);
    setCheckedList([]);
    setIsLocationFilterApplied(false);
    setIsBadgeFilterApplied(false);
    setIsBadgeStatusFilterApplied(false);
    removeLocationNoneSelectedLocalStorage();

    setCommonData({
      ...commonData,
      badgeIds: [],
      locations: [],
      badgeStatus: []
    });

    // getFilterLocations();
    // getBadgeRoles();
    getBadgeUsersList([], []);

    setCheckedListCopy([]);
    setCheckedListCopyBadges([]);
  };

  const getFilterLocations = async () => {
    try {
      const programId = location?.state?.programId;
      const payload = {
        programIds: [programId],
        locationType: "BASE_LOCATION"
      };
      // const response = await ApiClient.post(
      //   participationDetailsEndPoints.getFilterLocation(),
      //   payload
      // );
      const response = await ApiClient.post(badgesEndPoints.getFilterLocation(programId), {});
      const responseData = response?.response?.programData[programId];
      responseData?.forEach((data: any, index: number) => {
        data.serialNo = `${index + 1}`;
        data.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(data);
        data.isSelected = false;
        data.bundleCompletedDate = dataHelper.formatDate(data.bundleCompletedDate);
      });
      setLocationList(responseData);
      // filterLocationByGroups(responseData);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      toast.error("Something went wrong.");
      setLocationList([]);
    }
  };

  const getBadgeRoles = async () => {
    try {
      const programId = location?.state?.programId;
      const response = await ApiClient.get(
        qualificationPackEndPoints.getBadgeRoles(),

        { id: programId }
      );
      const responseData = response?.response[programId];
      responseData?.forEach((data: any, index: number) => {
        data.serialNo = `${index + 1}.`;
        // data.isSelected = false;
      });
      setBadgeRoleList(responseData);
    } catch (error) {
      toast.error("Something went wrong.");
      setBadgeRoleList([]);
    }
  };

  const openFilterHandler = () => {
    setOpenFilter(true);
    if (selectedLocationList.length === 0 && selectedBadgeRoleList.length === 0) {
      getFilterLocations();
      getBadgeRoles();
    } else {
      // Object overwritten, (doubt)
      // checkArraysForChanges(locationList, badgeRoleList);
      const listLoc = [...locationList];
      setLocationList(listLoc);
    }

    if (badgeStatusListAfterFilter.length === 0) {
      const BADGE_STATUS_OBJECT_SECONDARY = [
        {
          name: "ASSIGNED",
          id: 1,
          serialNo: "1."
        },
        {
          name: "EXPIRED",
          id: 2,
          serialNo: "2."
        }
      ];
      setBadgeStatusList(BADGE_STATUS_OBJECT_SECONDARY);
    } else {
      let resultName = badgeStatusListAfterFilter.map((a: any) => a.name);
      const newFilteredListStausSecondary = [...selectedBadgeStatusListFinal];
      const newFilteredListStatus = newFilteredListStausSecondary.forEach((item) => {
        if (resultName.includes(item.name)) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      });
      setBadgeStatusList(newFilteredListStausSecondary);
    }
  };

  const handleOpenFilterModal = () => {
    setShowFilterModal(true);
    // getFilterLocations();
    // getBadgeRoles();
    openFilterHandler();
  };

  const filterButtonsList = () => (
    <>
      {(isLocationFliterApplied || isBadgeFliterApplied || isBadgeStatusFliterApplied) &&
        (noneLocationSelected ||
          noAssignedBadgesSelected ||
          locationListButton.length !== 0 ||
          badgeRoleListButton.length !== 0 ||
          badgeRoleLStatusListButton.length !== 0) && (
          <View style={styles.filteredButtonsContainer}>
            {isLocationFliterApplied &&
              locationListButton?.map((prop, key) => {
                return (
                  <View key={key}>
                    <CancelButton
                      data={prop}
                      functionOfButtonProp={"location"}
                      functionOfButton={() => removeLocationList(prop)}
                      // textValue={prop.formattedBaseLocation}
                      textValue={prop}
                    />
                  </View>
                );
              })}
            {isBadgeFliterApplied &&
              badgeRoleListButton?.map((prop, key) => {
                return (
                  <View key={key}>
                    <CancelButton
                      data={prop}
                      functionOfButtonProp={"badgeRole"}
                      functionOfButton={() => removeFilteredListButton(prop, "badgeRole")}
                      textValue={prop.name}
                    />
                  </View>
                );
              })}
            {isBadgeStatusFliterApplied &&
              badgeStatusListAfterFilter?.map((prop, key) => {
                return (
                  <>
                    <View key={key}>
                      <CancelButton
                        data={prop}
                        functionOfButtonProp={"badgeStatus"}
                        functionOfButton={() => removeFilteredListButton(prop, "badgeStatus")}
                        textValue={prop.name}
                      />
                    </View>
                  </>
                );
              })}
            {noneLocationSelected && (
              <CancelButton
                data={[]}
                functionOfButtonProp={"noLocation"}
                functionOfButton={() => removeFilteredListButton([], "noLocation")}
                textValue={"No Location"}
              />
            )}
            {noAssignedBadgesSelected && (
              <CancelButton
                data={[]}
                functionOfButtonProp={"noAssignedBadge"}
                functionOfButton={() => removeFilteredListButton([], "noAssignedBadge")}
                textValue={"No Assigned Badges"}
              />
            )}
            {(isLocationFliterApplied || isBadgeFliterApplied || isBadgeStatusFliterApplied) &&
              (noneLocationSelected ||
                noAssignedBadgesSelected ||
                locationListButton.length !== 0 ||
                badgeRoleListButton.length !== 0 ||
                badgeRoleLStatusListButton.length !== 0) && (
                <TouchableOpacity onPress={() => clearAllFilter(true)}>
                  <Text
                    testId="testId"
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.Small}
                    style={[styles.clearAllButtonText]}
                  >
                    {"Clear All"}
                  </Text>
                </TouchableOpacity>
              )}
          </View>
        )}
    </>
  );

  const getSelectedLocationList = (list: any, globalList: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    setSelectedLocationList(list);
    let result = locationDataConvert(newList);
    setCommonData({
      ...commonData,
      locations: result
    });
  };

  const dataFilteredHandler = async (data: any) => {
    if (data.isFiltered === true) {
      commonData.badgeIds.length === 0 &&
      commonData.locations.length === 0 &&
      data.isNoneLocationSet === null &&
      data.isNoBadgeAssignSet === null &&
      commonData.badgeStatus.length == 0
        ? [
            setIsLocationFilterApplied(false),
            setIsBadgeFilterApplied(false),
            setIsBadgeStatusFilterApplied(false)
          ]
        : null;

      const locList = [...locationList];
      let newList = filterListToTrue(locList);
      setLocationListSecondry(locList);
      setLocationListButton(globalList);
      const badgeList = [...badgeRoleList];
      let newBadgeList = filterListToTrue(badgeList);
      setCheckedListCopyBadges(newBadgeList);
      setAppliedLocationList(newList);
      //Badge Status logic.
      let result: any[];
      let badgeStatusListCopy2: any[];
      if (updatedBadgeStatusListPrimary.length !== 0 && data.badgeStatusFinal.length == 0) {
        setSelectedBadgeStatusListFinal(updatedBadgeStatusListPrimary);
        let newList2 = updatedBadgeStatusListPrimary.filter((x: any) => x.isSelected === true);
        // let newList2 = selectedBadgeStatusListFinal.filter((x: any) => x.isSelected === true);
        result = newList2.map((a: any) => a.name);
        badgeStatusListCopy2 = [...updatedBadgeStatusListPrimary];
      } else {
        setSelectedBadgeStatusListFinal(data.badgeStatusFinal);
        let newList2 = data.badgeStatusFinal.filter((x: any) => x.isSelected === true);
        // let newList2 = selectedBadgeStatusListFinal.filter((x: any) => x.isSelected === true);
        result = newList2.map((a: any) => a.name);
        badgeStatusListCopy2 = [...data.badgeStatusFinal];
      }
      // setSelectedBadgeStatusListFinal(data.badgeStatusFinal);
      // let newList2 = data.badgeStatusFinal.filter((x: any) => x.isSelected === true);
      // let newList2 = selectedBadgeStatusListFinal.filter((x: any) => x.isSelected === true);
      // let result = newList2.map((a: any) => a.name);
      setUpdatedBadgeStatusListSecondary(result);
      const badgeStatusListButton = [...result];
      // badgeStatusListCopy2 = [...data.badgeStatusFinal];
      setBadgeRoleStatusListButton(badgeStatusListButton); // set buttons after filter
      let newFilteredStatusList: any[] = [];
      let badgeStatusListFiltered = badgeStatusListCopy2.map((item) => {
        if (badgeStatusListButton.includes(item.name)) {
          newFilteredStatusList.push(item);
        }
      });
      setBadgeStatusList(newFilteredStatusList); //This state is used to set main badge status list.
      setBadgeStatusListAfterFilter(newFilteredStatusList); // set only after filter is applied.
      setIsBadgeStatusFilterApplied(true);
      setNoneLocationSelected(data.isNoneLocationSet === null ? false : true);
      setNoAssignedBadgesSelected(data.isNoBadgeAssignSet === null ? false : true);
      filteredDataHandler({
        ...commonData,
        locations: newList,
        isNoneLocationSet: data.isNoneLocationSet,
        isNoBadgeAssignSet: data.isNoBadgeAssignSet,
        badgeList: badgeRoleList,
        locationNameList: globalList,
        badgeStatus: updatedBadgeStatusListSecondary
      });
      setLoading(true);
    }
  };

  const getSelectedRoleBadgeList = (list: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    // setBadgeRoleListButton(newList); // added while applied button onClick
    let result = newList.map((a: any) => a.badgeId);
    if (result.length) setSelectedBadgeRoleList(result);
    setCommonData({
      ...commonData,
      badgeIds: result
    });
  };

  const getSelectedRoleBadgeStatusList = (list: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    let result = newList.map((a: any) => a.name);
    // setUpdatedBadgeStatusListSecondary(result); //This is set whenever there is check-unchek in badgeFilter component.
    // setBadgeStatusList(list);
    // setBadgeStatusListCopy(list);
    setCommonData({
      ...commonData,
      badgeStatus: result
    });
  };

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <>
          <View style={styles.container}>
            <View style={styles.rightContainer}>
              <View style={styles.subContainer}>
                <View style={styles.subContainerOne}>
                  <View style={styles.headerContainer}>
                    <View style={styles.iconContainerHeader}>
                      <Icon
                        testID="down"
                        name={IconNames.down}
                        customStyle={styles.titleIcon}
                        onPress={() => {
                          history.goBack();
                        }}
                      />
                    </View>
                    <View style={styles.headingContainer}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                      >
                        {selectedBadge?.name}
                      </Text>
                    </View>
                    <View>
                      <Text
                        onPress={() => {}}
                        fontWeight={FontWeight.Regular}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
                      >
                        {">"}
                      </Text>
                    </View>
                    <View>
                      <Text
                        onPress={() => {}}
                        fontWeight={FontWeight.Regular}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerTextSupport]}
                      >
                        Assign Badge
                      </Text>
                    </View>
                  </View>
                  <View>
                    {badgeUsersList.length === 0 ? (
                      <View>
                        <View style={styles.searchBarContainer}>
                          <View pointerEvents={"none"}>
                            <SearchFilterSeondary
                              customWidthStyle={styles.customWidthStyle}
                              editable={false}
                            />
                          </View>
                          <View style={styles.filterIconContainer}>
                            <TouchableOpacity onPress={handleOpenFilterModal}>
                              <View style={styles.filterContainer}>
                                <Icon
                                  name={IconNames.filter}
                                  customStyle={styles.iconStyleFilter}
                                />
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View>
                          <View>{filterButtonsList()}</View>
                        </View>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={styles.noData}
                        >
                          {errormessage}
                        </Text>
                      </View>
                    ) : (
                      <View style={styles.tableContainer}>
                        <Table
                          columns={columns}
                          showFilter={true}
                          handleOpenFilterModal={handleOpenFilterModal}
                          filterButtons={<View>{filterButtonsList()}</View>}
                          data={badgeUsersList}
                          handleSelectedRows={handleSelectedRows}
                          noDataMessage="No users under this Badge"
                          searchReset={true}
                          setSelectedRowIndex={setSelectedRowIndex}
                          eventAnalytics="BadgeUsers"
                        />
                      </View>
                    )}
                  </View>
                </View>
              </View>
              <View style={styles.bottomContainer}>
                {selectedBadge?.validity && (
                  <View style={styles.dateContainer}>
                    <View>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="addRoleTitleText"
                        textSize={TextSize.Small}
                        textStyle={styles.subheaderText}
                      >
                        Valid through
                      </Text>
                    </View>
                    <View style={styles.subdateContainer}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="addRoleTitleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.subheaderText, styles.fontAlign]}
                      >
                        Start
                      </Text>
                      <View style={styles.datePicker}>
                        <Styles>
                          <DatePicker
                            onChange={(date: any) => [
                              setStartDate(date),
                              setEndDate(""),
                              checkStartDate(date, 1),
                              googleWebAnalytics(
                                "Assign_Badge_Start_Date",
                                "Date_Picker",
                                "Assign_Badge"
                              )
                            ]}
                            selected={startDate}
                            dateFormat="dd/MM/yyyy"
                            popperPlacement="top"
                            placeholderText="dd/mm/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </Styles>
                      </View>
                    </View>

                    <View style={styles.subdateContainer}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="addRoleTitleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.subheaderText, styles.fontAlign]}
                      >
                        End
                      </Text>
                      <View style={styles.datePicker}>
                        <Styles>
                          <DatePicker
                            minDate={startDateValidity}
                            onChange={(date: any) => [
                              setEndDate(date),
                              googleWebAnalytics(
                                "Assign_Badge_End_Date",
                                "Date_Picker",
                                "Assign_Badge"
                              )
                            ]}
                            selected={endDate}
                            dateFormat="dd/MM/yyyy"
                            popperPlacement="top"
                            placeholderText="dd/mm/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </Styles>
                      </View>
                    </View>
                  </View>
                )}

                <View style={styles.buttonContainer}>
                  <View>
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Cancel"
                      onPress={() => history.push("/new/app/badges")}
                    />
                  </View>
                  <View>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Assign"
                      onPress={handleBadgeAssign}
                      disabled={
                        // When it is open ended, validity is false
                        !selectedBadge.validity
                          ? selectedRows?.length == 0
                          : selectedRows?.length == 0 ||
                            startDate == "YYYY-MM-DD" ||
                            endDate == "YYYY-MM-DD" ||
                            startDate == null ||
                            startDate == "" ||
                            endDate == null ||
                            endDate == ""
                      }
                    />
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.leftContainer}>
              <View style={{ marginTop: 60 }}>
                <AssignBadgeSideBarPanelPage selectedBadge={selectedBadge} />
              </View>
            </View>

            {assignBadgeModal && (
              <View>
                <SocionModal
                  modalVisible={assignBadgeModal}
                  setModalVisible={() => setAssignBadgeModal(!assignBadgeModal)}
                  customPadding={5}
                  component={
                    <>
                      {loadingBadgeModal ? (
                        <Loader customLoadingContainer={styles.badgesModalLoader} />
                      ) : (
                        <AssignBadgeModal
                          onClose={() => setAssignBadgeModal(false)}
                          assignBadgeData={assignBadgeData}
                          formatSelectedRows={formatSelectedRows}
                          badgeDetailsState={badgeDetailsState}
                          getBadgeUsersList={getBadgeUsersList}
                          calendarStartDate={setStartDate}
                          calendarEndDate={setEndDate}
                        />
                      )}
                    </>
                  }
                />
              </View>
            )}

            {viewButtonDetails && (
              <View>
                <SocionModal
                  modalVisible={viewButtonDetails}
                  setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                  component={
                    <ViewUserDetails
                      onClose={() => {
                        setViewButtonDetails(false);
                      }}
                      selectedUserDetails={selectedUserDetails}
                      piiFilter={true}
                    />
                  }
                />
              </View>
            )}

            {showFilterModal && (
              <>
                <SocionModal
                  isCustomStyling={true}
                  modalVisible={showFilterModal}
                  setModalVisible={() => setShowFilterModal(!showFilterModal)}
                  component={
                    <FilterModal
                      globalList={globalList}
                      setGlobalList={setGlobalList}
                      locationList={locationList}
                      badgeRoleList={badgeRoleList}
                      setCheckedList={setCheckedList}
                      getSelectedLocationList={getSelectedLocationList}
                      setExpandedList={setExpandedList}
                      programDetails={programDetails}
                      expandedList={expandedList}
                      checkedList={checkedList}
                      dataFilteredHandler={dataFilteredHandler}
                      setShowFilterModal={setShowFilterModal}
                      setModalVisible={() => setOpenFilter(!openFilter)}
                      setIsLocationFilterApplied={setIsLocationFilterApplied}
                      selectedBadgeRoleList={selectedBadgeRoleList}
                      // updatedFilteredLocationList={updatedFilteredLocationList}
                      getSelectedRoleBadgeList={getSelectedRoleBadgeList}
                      setIsBadgeFilterApplied={setIsBadgeFilterApplied}
                      getParticipantAttestationData={getBadgeUsersList}
                      allExpandedNodes={allExpandedNodes}
                      setAllExpandedNodes={setAllExpandedNodes}
                      seterrormessage={seterrormessage}
                      setCheckedListCopy={setCheckedListCopy}
                      checkedListCopy={checkedListCopy}
                      setCheckedListCopyBadges={setCheckedListCopyBadges}
                      checkedListCopyBadges={checkedListCopyBadges}
                      isShowBadgeStatus={true}
                      badgeStatusObject={badgeStatusList}
                      getSelectedRoleBadgeStatusList={getSelectedRoleBadgeStatusList}
                      setSelectedBadgeStatusListFinal={setSelectedBadgeStatusListFinal}
                      setUpdatedBadgeStatusListPrimary={setUpdatedBadgeStatusListPrimary}
                      setBadgeRoleListButton={setBadgeRoleListButton}
                      noAssignedBadgesSelected={noAssignedBadgesSelected}
                    />
                  }
                />
              </>
            )}
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row"
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    width: "calc(100% - 240px)"
  },
  leftContainer: {
    marginTop: -50,
    flex: 1,
    backgroundColor: colorPallete.cordioRedDark2,
    minHeight: "100vh"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    marginBottom: 30
  },
  containerStyle: {
    width: "30%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  subContainerOne: {},

  //Header Container
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerContainer: {
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0,
    alignItems: "center"
  },
  headerTextSupport: {
    fontWeight: "400",
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 20
  },

  // collapse Container
  containerCollapse: {
    marginTop: 30,
    flexDirection: "column"
  },
  memberViewCollapseContainer: {
    flexDirection: "row"
  },
  collapseContainer: {
    marginBottom: 20
  },
  tableContainer: {},
  bottomContainer: {
    flexDirection: "row",
    width: "100%"
  },
  dateContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 60
  },
  subdateContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 10,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  fontAlign: {
    color: colorPallete.textBlack
  },
  datePicker: {
    marginLeft: 5
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 40,
    flexWrap: "wrap"
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    margin: 4,
    marginLeft: 10
  },
  badgesModalLoader: {
    width: 800,
    height: 300
  },
  buttonFont: {
    fontSize: 12
  },
  filteredButtonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 18,
    paddingTop: 0
  },
  //For secondary serach filter
  clearAllButtonText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    textDecorationLine: "underline"
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 18
  },
  customWidthStyle: {
    width: 400
  },
  filterIconContainer: {
    marginLeft: 10
  },
  filterContainer: {
    width: 50,
    height: 42,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.cordioTaupeLight4,
    justifyContent: "center",
    alignItems: "center"
  },
  iconStyleFilter: {
    color: colorPallete.textBlack,
    fontSize: 14
  },
  noData: {
    padding: 25,
    fontSize: 14,
    fontFamily: FontFamily.Bold,
    display: "flex",
    color: colorPallete.cordioTaupe
  },
  headingContainer: {
    maxWidth: 461
  }
});
