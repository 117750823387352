import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import * as Yup from "yup";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  dashboardEndPoints,
  applicationAccessEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useHistory } from "react-router-dom";
import { allRoutesNames } from "../../../../../web/src/navigation/allRouteNames";
import { useSelector } from "react-redux";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { toast } from "react-toastify";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import axios from "axios";
import { CONFIG } from "@socion-cordio/common/src/config/envConstant";

interface Props {}

export default function DashboardMainPage(props: Props): ReactElement {
  const [loading, setLoading] = useState<boolean>(true);
  const [response, setResponse] = useState([]);
  const [showExternal, setShowExternal] = useState(false);
  const history: any = useHistory();
  const userRolesData: any = useSelector((state: any) => state?.userRolesProgram);
  const [memberRole, setMemberRoles] = useState([]);

  useEffect(() => {
    getDashboardResponse();
    getDasboardAccess();
  }, []);

  useEffect(() => {
    const memberRole = userRolesData?.userRolesData?.response;
    setMemberRoles(memberRole);
  }, []);

  const getDashboardResponse = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const programId = programDetails?.program?.id;
    const sessionsInfoService = ApiClient.post(dashboardEndPoints.getSessionsInfo(programId), {});
    const badgesService = ApiClient.get(dashboardEndPoints.getBadgesInfo(programId));
    let token = await LocalStorage.getStorage("accessToken");
    const taskHeaders = {
      "access-token": token,
      accept: "application/json"
    };
    const taskPayload = {
      programId: programId
    };
    const rolesAndQualificationsService = ApiClient.post(
      dashboardEndPoints.getRolesAndQualificationsInfo(),
      { programId }
    );
    const qpInfoService = ApiClient.get(dashboardEndPoints.getQpInfo(programId));
    const payload = {
      programId: programDetails.program.id,
      applicationId: CONFIG.REACT_APP_BUNDLE_ID
    };
    const qpAccess = await ApiClient.get(
      applicationAccessEndPoints.getApplicationAccess(),
      payload
    );
    const tasksService: any = await axios.post(
      `https://task-api-stage.socion.io/taskservice/api/v1/submissions/verification/metrics`,
      taskPayload,
      { headers: taskHeaders }
    );

    let sessionsResponse: any = {};
    let badgesResponse: any = {};
    let rolesAndQualificationsResponse: any = {};
    let qpResponse: any = {};
    let qpAccessResponse: any = {};
    let tasksResponse: any = {};
    [
      sessionsResponse,
      badgesResponse,
      rolesAndQualificationsResponse,
      qpResponse,
      qpAccessResponse,
      tasksResponse
    ] = await Promise.allSettled([
      sessionsInfoService,
      badgesService,
      rolesAndQualificationsService,
      qpInfoService,
      qpAccess,
      tasksService
    ]);
    const response = [
      {
        id: 1,
        heading: "Topics",
        label1: "Active topics",
        value1:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.topics
            : "--",
        label2: `Contents delivered`,
        value2:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.contents
            : "--",
        isAdmin: false
      },
      {
        id: 2,
        heading: "Sessions",
        label1: "Completed sessions",
        value1:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.completedSessions
            : "--",
        label2: "Upcoming sessions",
        value2:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.upcomingSessions
            : "--",
        label3: "Live sessions",
        value3:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.liveSessions
            : "--",
        isAdmin: false
      },
      {
        id: 3,
        heading: "Participation",
        label1: "Participants",
        value1:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.participants
            : "--",
        label2: "Trainers",
        value2:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.trainers
            : "--",
        isAdmin: false
      },
      {
        id: 4,
        heading: "Interactions",
        label1: "Attestations issued",
        value1:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.memberAttestations +
              sessionsResponse.value.response.participantsAttestations
            : "--",
        label2: "Contents consumed",
        value2:
          sessionsResponse.value && sessionsResponse.value.response
            ? sessionsResponse.value.response.contentUsed
            : "--",
        isAdmin: false
      }
    ];

    if (
      userRolesData?.userRolesData?.response?.includes("PROGRAM_ADMIN") ||
      userRolesData?.userRolesData?.response?.includes("ENTITY_ADMIN")
    ) {
      response.push({
        id: 5,
        heading: "Badges",
        label1: "Active badges",
        value1: badgesResponse.value ? badgesResponse.value.activeBadges : "--",
        label2: "Badges issued",
        value2: badgesResponse.value ? badgesResponse.value.badgesIssued : "--",
        isAdmin: false
      });
    }

    if (qpAccessResponse?.value?.response.length > 0) {
      response.push({
        id: 8,
        heading: "Qualification packs",
        label1: "Active qualification packs",
        value1:
          qpResponse.value && qpResponse.value.response
            ? qpResponse.value.response.bundleCount
            : "--",
        label2: "Tagged topics",
        value2:
          qpResponse.value && qpResponse.value.response
            ? qpResponse.value.response.topicCount
            : "--",
        isAdmin: false
      });
    }

    if (
      userRolesData?.userRolesData?.response?.includes("PROGRAM_ADMIN") ||
      userRolesData?.userRolesData?.response?.includes("ENTITY_ADMIN") ||
      userRolesData?.userRolesData?.response?.includes("VERIFIER")
    ) {
      if (programDetails?.program?.acceptingRoles) {
        response.push({
          id: 7,
          heading: "Roles Verification",
          label1: "Verification completed",
          value1:
            rolesAndQualificationsResponse.value && rolesAndQualificationsResponse.value.response
              ? rolesAndQualificationsResponse.value.response.acceptedRole +
                rolesAndQualificationsResponse.value.response.declinedRole
              : "--",
          label2: "Verification pending",
          value2:
            rolesAndQualificationsResponse.value && rolesAndQualificationsResponse.value.response
              ? rolesAndQualificationsResponse.value.response.pendingRole
              : "--",

          isAdmin: false
        });
      }
      if (programDetails?.program?.acceptingQualifications) {
        response.push({
          id: 6,
          heading: "Qualifications Verification",
          label1: "Verification completed",
          value1:
            rolesAndQualificationsResponse.value && rolesAndQualificationsResponse.value.response
              ? rolesAndQualificationsResponse.value.response.acceptedQualification +
                rolesAndQualificationsResponse.value.response.declinedQualification
              : "--",
          label2: "Verification pending",
          value2:
            rolesAndQualificationsResponse.value && rolesAndQualificationsResponse.value.response
              ? rolesAndQualificationsResponse.value.response.pendingQualification
              : "--",
          isAdmin: false
        });
      }
      if (programDetails.program.paTaskVerificationEnabled) {
        response.push({
          id: 9,
          heading: "Tasks Verification",
          label1: "Verification completed",
          value1: tasksResponse.value.data.result.verificationMetrics
            ? tasksResponse.value.data.result.verificationMetrics.completed +
              tasksResponse.value.data.result.verificationMetrics.rejected
            : "--",
          label2: "Verification pending",
          value2: tasksResponse.value.data.result.verificationMetrics
            ? tasksResponse.value.data.result.verificationMetrics.pending
            : "--",
          isAdmin: false
        });
      }
    }
    setResponse(response);
    setLoading(false);
  };

  const getDasboardAccess = async () => {
    try {
      const programDetails: any = await LocalStorage.getStorage("programDetails");
      const payload = {
        programId: programDetails.program.id,
        applicationId: CONFIG.REACT_APP_DASHBOARD_ID
      };
      const dasboardAccessResponse = await ApiClient.get(
        applicationAccessEndPoints.getApplicationAccess(),
        payload
      );
      if (dasboardAccessResponse?.response?.length != 0) {
        setShowExternal(true);
      } else setShowExternal(false);
    } catch (error) {
      toast.error("Something went wrong");
      setShowExternal(false);
    }
  };

  const setToken = async () => {
    setLoading(true);
    let token = await LocalStorage.getStorage("accessToken");
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    let payload = JSON.stringify({
      request: { token: token, selectedProgram: programDetails.program.name }
    });
    try {
      fetch(`https://reports-stage.socion.io/setToken`, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: payload
      })
        .then((response) => response.json())
        .then((responseData) => {
          setLoading(false);
          var a = document.createElement("a");
          a.href = `https://reports-stage.socion.io/superset/login`;
          a.target = "_blank";
          a.click();
          googleWebAnalytics("Dashboard_Advanced_Dashboard", "Button", "Dashboard");
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong");
        });
    } catch {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const handleRoute = (module: string) => {
    switch (module) {
      case "Active topics":
        history.push(`${allRoutesNames.app}${allRoutesNames.TOPICS}`),
          googleWebAnalytics("Dashboard_Redirect_Topic", "Button", "Dashboard");
        break;
      case "Upcoming sessions":
        history.push(`${allRoutesNames.app}${allRoutesNames.SESSION}`, {
          locationData: { pathname: "upcoming" },
          activeTab: "UPCOMING"
        }),
          googleWebAnalytics("Dashboard_Redirect_Upcoming_Session", "Button", "Dashboard");
        break;
      case "Completed sessions":
        history.push(`${allRoutesNames.app}${allRoutesNames.SESSION}`, {
          locationData: { pathname: "completed" },
          activeTab: "COMPLETED"
        }),
          googleWebAnalytics("Dashboard_Redirect_Completed_Session", "Button", "Dashboard");
        break;
      case "Live sessions":
        history.push(`${allRoutesNames.app}${allRoutesNames.SESSION}`, {
          locationData: { pathname: "live" },
          activeTab: "LIVE"
        }),
          googleWebAnalytics("Dashboard_Redirect_Live_Session", "Button", "Dashboard");
        break;
      case "Active badges":
        history.push(`${allRoutesNames.app}${allRoutesNames.BADGES}`),
          googleWebAnalytics("Dashboard_Redirect_Badges", "Button", "Dashboard");
        break;
      case "Qualifications Verification":
        history.push(`${allRoutesNames.app}${allRoutesNames.VERIFY}`, {
          activeTab: "QUALIFICATION"
        }),
          googleWebAnalytics("Dashboard_Redirect_Qualification", "Button", "Dashboard");
        break;
      case "Roles Verification":
        history.push(`${allRoutesNames.app}${allRoutesNames.VERIFY}`, {
          activeTab: "ROLES"
        }),
          googleWebAnalytics("Dashboard_Redirect_Roles", "Button", "Dashboard");
        break;
      case "Attestations issued":
        if (memberRole?.includes("PROGRAM_ADMIN") || memberRole?.includes("ENTITY_ADMIN")) {
          history.push(`${allRoutesNames.app}${allRoutesNames.PARTICIPATIONATTESTATION}`, {
            activeTab: "COMPLETED"
          });
          googleWebAnalytics("Dashboard_Redirect_Attestation_Issued", "Button", "Dashboard");
          LocalStorage.setStorage("isSidePanelCollapsed", true);
        }
        break;
      case "Participants":
        if (memberRole?.includes("PROGRAM_ADMIN") || memberRole?.includes("ENTITY_ADMIN")) {
          history.push(`${allRoutesNames.app}${allRoutesNames.PARTICIPATIONDETAILS}`, {
            activeTab: "Participants"
          });
          googleWebAnalytics("Dashboard_Redirect_Participant", "Button", "Dashboard");
          LocalStorage.setStorage("isSidePanelCollapsed", true);
        }
        break;
      case "Trainers":
        if (memberRole?.includes("PROGRAM_ADMIN") || memberRole?.includes("ENTITY_ADMIN")) {
          history.push(`${allRoutesNames.app}${allRoutesNames.PARTICIPATIONDETAILS}`, {
            activeTab: "Trainers"
          });
          googleWebAnalytics("Dashboard_Redirect_Trainers", "Button", "Dashboard");
          LocalStorage.setStorage("isSidePanelCollapsed", true);
        }
        break;
      case "Active qualification packs":
        history.push(`${allRoutesNames.app}${allRoutesNames.QUALIFICATIONPACKS}`),
          googleWebAnalytics("Dashboard_Redirect_QP", "Button", "Dashboard");
        break;
      case "Tasks Verification":
        history.push(`${allRoutesNames.app}${allRoutesNames.VERIFY}`, {
          activeTab: "TASKS"
        });
        break;
    }
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View style={styles.subContainer}>
          <Loader customLoadingContainer={styles.loaderStyle} />
        </View>
      ) : (
        <View style={styles.subContainer}>
          {showExternal && (
            <View style={styles.externalLink}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.buttonStyles}
                textStyles={styles.advancedDashboard}
                title="Advanced Dashboard"
                onPress={() => setToken()}
              />
            </View>
          )}
          {response?.map((prop, key) => {
            return (
              <View key={key} style={styles.miniContainer}>
                <View style={styles.alignVertical}>
                  <Text
                    fontWeight={FontWeight.Medium}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextAlign, { fontWeight: "600" }]}
                  >
                    {prop.heading}
                  </Text>
                </View>
                <View style={[styles.alignLabelInfoContainer, styles.alignVertical]}>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                  >
                    {prop.label1}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[
                      styles.headerText,
                      styles.alignText,
                      styles.flexLabelContent,
                      styles.headerFontAlign,
                      styles.textUnderline
                    ]}
                  >
                    <>
                      {prop.heading === "Qualifications Verification" ||
                      prop.heading === "Roles Verification" ||
                      prop.heading === "Tasks Verification" ? (
                        <TouchableOpacity onPress={() => handleRoute(prop.heading)}>
                          {prop.value1}
                        </TouchableOpacity>
                      ) : (
                        <TouchableOpacity onPress={() => handleRoute(prop.label1)}>
                          {prop.value1}
                        </TouchableOpacity>
                      )}
                    </>
                  </Text>
                  {/* <View style={styles.nextIconContainer}>
                      <TouchableOpacity onPress={() => handleRoute(prop.label1)}>
                        <Icon
                          testID="rightArrow"
                          name={IconNames.leftArrow}
                          customStyle={[styles.alignNextButton]}
                        />
                      </TouchableOpacity>
                    </View> */}
                </View>
                <View style={[styles.alignLabelInfoContainer, styles.alignVertical]}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                  >
                    {prop.label2}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[
                      styles.headerText,
                      styles.alignText,
                      styles.flexLabelContent,
                      styles.headerFontAlign,
                      prop.label2 !== "Contents delivered" &&
                      prop.label2 !== "Contents consumed" &&
                      prop.label2 !== "Badges issued" &&
                      prop.label2 !== "Tagged topics"
                        ? styles.textUnderline
                        : null
                    ]}
                  >
                    {prop.label2 !== "Contents delivered" &&
                    prop.label2 !== "Contents consumed" &&
                    prop.label2 !== "Badges issued" &&
                    prop.label2 !== "Tagged topics" ? (
                      <>
                        {prop.heading === "Qualifications Verification" ||
                        prop.heading === "Roles Verification" ||
                        prop.heading === "Tasks Verification" ? (
                          <TouchableOpacity onPress={() => handleRoute(prop.heading)}>
                            {prop.value2}
                          </TouchableOpacity>
                        ) : (
                          <TouchableOpacity onPress={() => handleRoute(prop.label2)}>
                            {prop.value2}
                          </TouchableOpacity>
                        )}
                      </>
                    ) : (
                      <> {prop.value2}</>
                    )}
                  </Text>
                  {/* <View style={styles.nextIconContainer}>
                      {prop.label2 !== "Contents delivered" &&
                      prop.label2 !== "Contents consumed" &&
                      prop.label2 !== "Badges issued" &&
                      prop.label2 !== "Tagged topics" ? (
                        <TouchableOpacity onPress={() => handleRoute(prop.label2)}>
                          <Icon
                            testID="rightArrow"
                            name={IconNames.leftArrow}
                            customStyle={[styles.alignNextButton]}
                          />
                        </TouchableOpacity>
                      ) : null}
                    </View> */}
                </View>
                <View style={[styles.alignLabelInfoContainer, styles.alignVertical]}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.alignText, styles.flexLabel]}
                  >
                    {prop.label3}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[
                      styles.headerText,
                      styles.alignText,
                      styles.flexLabelContent,
                      styles.headerFontAlign,
                      styles.textUnderline
                    ]}
                  >
                    <>
                      {prop.heading === "Qualifications Verification" ||
                      prop.heading === "Roles Verification" ||
                      prop.heading === "Tasks Verification" ? (
                        <TouchableOpacity onPress={() => handleRoute(prop.heading)}>
                          {prop.value3}
                        </TouchableOpacity>
                      ) : (
                        <TouchableOpacity onPress={() => handleRoute(prop.label3)}>
                          {prop.value3}
                        </TouchableOpacity>
                      )}
                    </>
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  loaderStyle: {
    width: "100%"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  alignHeader: {
    marginBottom: 30
  },
  iconStyle: {
    fontSize: 18,
    marginRight: 35
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    padding: 18,
    flexDirection: "row",
    flexWrap: "wrap",
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  alignVertical: {
    marginBottom: 8,
    marginTop: 8
  },
  miniContainer: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupe,
    borderRadius: 10,
    width: 273,
    padding: 15,
    paddingHorizontal: 25,
    marginRight: 30,
    marginBottom: 18
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
    // marginBottom: 5
  },
  headerFontAlign: {
    fontSize: 14,
    color: colorPallete.textBlack,
    textAlign: "right"
  },
  textUnderline: {
    textDecorationLine: "underline"
  },
  headerTextAlign: {
    fontSize: 14,
    lineHeight: 19.5
  },
  alignLabelInfoContainer: {
    flexDirection: "row"
  },
  alignText: {
    color: colorPallete.textLight
  },
  flexLabel: {
    flex: 75
  },
  flexLabelContent: {
    flex: 25
  },
  nextIconContainer: {
    flex: 15
  },
  alignNextButton: {
    fontSize: 14,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 5,
    transform: [{ rotate: "180deg" }]
  },
  externalLink: {
    fontSize: 10,
    paddingBottom: 18,
    flexBasis: "100%",
    flexDirection: "row-reverse"
  },
  advancedDashboard: {
    fontSize: 14
    // width: "154px"
  },
  buttonStyles: {
    height: 50,
    width: "184px"
  }
});
