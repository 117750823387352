import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, Pressable, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Otp } from "@socion-cordio/common/src/components/atoms/otp";
import { useDispatch, useSelector } from "react-redux";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import AddEmail from "@socion-cordio/common/src/components/organisms/addEmail";
import AddMobile from "@socion-cordio/common/src/components/organisms/addMobile";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { toast, ToastContainer } from "react-toastify";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { ProfileRepository } from "@socion-cordio/common/src/repositories/profile/profile";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import _ from "lodash";
import { setNewBearerToken, setSuperAdmin } from "@socion-cordio/common/src/network/authHelper";

interface Props {
  onClose: Function;
  showEmail?: boolean;
  modalTitle: string;
  headerText: string;
  newPhoneOtp?: boolean;
  sendOtpNewPhone?: Function;
  subHeaderText?: string;
  phoneNumberDetails?: any;
  isEmailChangeClicked?: boolean;
  emailOtpHandler?: Function;
  isValidatePhoneEnabled?: boolean;
  checkToCallResend?: boolean;
  newEmail?: any;
}

export default function OtpValidate(props: Props): ReactElement {
  const {
    subHeaderText = "Haven’t received a message?",
    phoneNumberDetails,
    showEmail,
    isEmailChangeClicked,
    emailOtpHandler,
    onClose,
    isValidatePhoneEnabled,
    checkToCallResend,
    newEmail
  } = props;
  const [otpHandle, setOtpHandle] = useState(false);
  const [enterEmailHandler, setEnterEmailHandler] = useState(false);
  const [enterMobileHandler, setEnterMobileHandler] = useState(false);
  const [mobileNumberVerified, setMobileNumberVerified] = useState(false);
  const [isChangeEmailClicked, setIsChangeEmailClicked] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [incorrectOtpMsg, setIncorrectOtpMsg] = useState(null);
  const [otp, setOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const profileState = useSelector((state: any) => state.profile);
  const {
    userProfileData: { response }
  } = profileState;
  const history: any = useHistory();
  const dispatch = useDispatch();
  const [countryCodeObj, setCountryCodeObj] = useState({});

  useEffect(() => {
    if (props.showEmail) {
      isEmailChangeClicked ? null : sendEmailOtp();
    } else if (props.showEmail === false) {
      sendMobileOtp(false);
    }
  }, []);

  const sendMobileOtp = async (isResendOtpClicked: boolean) => {
    let payload = {
      userName: response.phoneNumber ? response.phoneNumber : response.emailId,
      countryCode: response.countryCode,
      verificationType: response.phoneNumber ? "PHONE_NUMBER" : "EMAIL_ID",
      typeOfOtp: "UpdatePhone-OTP"
    };
    const otpResponse = ProfileRepository.sendOtpMobile(payload);
    otpResponse
      .then((res) => {
        // console.log("res", res);
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          isResendOtpClicked && toast.success("OTP sent successfully");
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
    let countryCodeObject: any = await LocalStorage.getStorage("countryCodeObj");
    setCountryCodeObj(countryCodeObject);
  };

  const sendOtpToPhoneNumberHandler = (isResendClicked?: boolean) => {
    const payload = {
      userName: response.phoneNumber,
      countryCode: response.countryCode,
      verificationType: "PHONE_NUMBER",
      typeOfOtp: "UpdateEmail-OTP"
    };
    const validateOtpResponse = ProfileRepository.sendPhoneNumberOtp(payload);
    validateOtpResponse
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          isResendClicked && toast.success("OTP sent successfully");
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const sendEmailOtp = async (isResendClicked?: boolean) => {
    if (isValidatePhoneEnabled) {
      sendOtpToPhoneNumberHandler(isResendClicked);
    }
  };

  const resendOtp = () => {
    if (checkToCallResend) {
      // to allow for resend email after email input
      emailOtpHandler(true);
    }

    if (props.showEmail) {
      isEmailChangeClicked ? emailOtpHandler(true) : sendEmailOtp(true);
    } else if (props.showEmail === false) {
      sendMobileOtp(true);
    } else if (props.sendOtpNewPhone) {
      props.sendOtpNewPhone();
    }
  };

  const validateMobileOtp = async () => {
    setLoading(true);
    const typeOfOTP = props.newPhoneOtp ? "NewPhone-OTP" : "UpdatePhone-OTP";
    const aesUtil: AesUtil = new AesUtil();
    const userCurrentPhoneNumber =
      typeOfOTP === "NewPhone-OTP"
        ? response.phoneNumber
        : showEmail === false
        ? response.phoneNumber
        : phoneNumberDetails.mobileNumber;
    const payload = {
      userName: userCurrentPhoneNumber ? userCurrentPhoneNumber : response.emailId,
      otp: aesUtil.encrypt(otp.toString()),
      typeOfOtp: typeOfOTP,
      countryCode:
        showEmail === false ? response.countryCode : `+${phoneNumberDetails.countryCode}`,
      verificationType: response.phoneNumber ? "PHONE_NUMBER" : "EMAIL_ID"
    };
    let user: any = await LocalStorage.getStorage("user");
    const validateOtpResponse = ProfileRepository.optValidate(payload);
    validateOtpResponse
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setLoading(false);
          if (props.newPhoneOtp) {
            updateTelemetryPhoneChange();
            user.phoneNumber = phoneNumberDetails.mobileNumber;
            LocalStorage.setStorage("user", user);
            setMobileNumberVerified(true);
            setOtpHandle(true);
            setEnterMobileHandler(false);
            setEnterEmailHandler(false);
            toast.success("Mobile number updated successfully");
          } else {
            setOtpHandle(true);
            setEnterMobileHandler(true);
            setEnterEmailHandler(false);
          }
        } else {
          setLoading(false);
          // toast.error(res.message);
          setIncorrectOtpMsg(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  const updateTelemetryPhoneChange = () => {
    const body = {
      createdAt: Moment(),
      deleted: "false",
      updatedAt: Moment(),
      eventType: "Profile edit-Phone Number change"
    };
    const userDetails = response;
    AddTelemetryService(body, undefined, userDetails);
    googleWebAnalytics("Profile_Edit_Phone_Number", "Telemetry_Event", "Profile");
  };

  const handleOtp = (otp: string) => {
    // let isValidOtp = /^\d+$/.test(otp);
    // if (isValidOtp) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
    setOtp(otp);
    setIncorrectOtpMsg(null);
    handleValidate({ values: null });
    setError(false);
  };

  const validateEmailOtp = async () => {
    if (isEmailChangeClicked) {
      validateEmailUpdateClickedHandler();
    } else {
      validateEmailOtpHandler();
    }
  };

  const validateEmailUpdateClickedHandler = async () => {
    const aesUtil: AesUtil = new AesUtil();
    const payload = {
      otp: aesUtil.encrypt(otp.toString()),
      typeOfOtp: "UpdateNewEmail-OTP",
      // phoneNumber: response.phoneNumber,
      userName: response.emailId ? response.emailId : response.phoneNumber, // if no email, send phone number
      countryCode: response.countryCode,
      verificationType: response.emailId ? "EMAIL_ID" : "PHONE_NUMBER" // or PHONE_NUMBER
    };
    setLoading(true);
    let user: any = await LocalStorage.getStorage("user");
    const validateOtpResponse = ProfileRepository.optValidate(payload);
    validateOtpResponse
      .then((res: any) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setNewBearerToken(res?.response?.accessToken);
          getPayload(res?.response?.accessToken);
          dispatch(ProfileActions.getUserProfile());
          user.emailId = newEmail;
          LocalStorage.setStorage("user", user);
          setLoading(false);
          updateTelemetryEmail();
          onClose();
          toast.success("Email updated successfully");
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  const getPayload = (token: string) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let payload = JSON.parse(
      decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
    if (payload?.realm_access) {
      const { roles } = payload.realm_access;
      const isAdmin = roles.includes("admin");
      setSuperAdmin(isAdmin);
    }
  };

  const validateMobileOtpHandler = () => {
    const aesUtil: AesUtil = new AesUtil();
    const payload = {
      userName: response.phoneNumber,
      otp: aesUtil.encrypt(otp.toString()),
      typeOfOtp: "UpdateEmail-OTP",
      countryCode: response.countryCode,
      verificationType: "PHONE_NUMBER"
    };
    const validateOtpResponse = ProfileRepository.validatePhoneNumberOtp(payload);
    validateOtpResponse
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          // isResendClicked && toast.success("OTP sent successfully");
          setOtpHandle(true);
          setEnterEmailHandler(true);
          setIsChangeEmailClicked(true);
        } else {
          setIncorrectOtpMsg(res?.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const validateEmailOtpHandlerSecondary = async () => {
    const aesUtil: AesUtil = new AesUtil();
    const payload = {
      userName: response?.phoneNumber ? response.phoneNumber : response?.emailId, //updatedUserEmail, //response.phoneNumber
      otp: aesUtil.encrypt(otp.toString()),
      typeOfOtp: "UpdateNewEmail-OTP",
      countryCode: response.countryCode,
      verificationType: response?.phoneNumber ? "PHONE_NUMBER" : "EMAIL_ID"
    };
    let user: any = await LocalStorage.getStorage("user");
    const validateOtpResponse = ProfileRepository.validatePhoneNumberOtp(payload);
    validateOtpResponse
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          props.onClose();
          user.emailId = newEmail;
          LocalStorage.setStorage("user", user);
          updateTelemetryEmail();
          dispatch(ProfileActions.getUserProfile());
          toast.success("Email ID updated successfully");
        } else {
          setIncorrectOtpMsg(res?.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const validateEmailOtpHandler = async () => {
    if (isValidatePhoneEnabled) {
      validateMobileOtpHandler();
    } else {
      validateEmailOtpHandlerSecondary();
    }
  };

  const updateTelemetryEmail = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      createdAt: Moment(),
      deleted: "false",
      updatedAt: Moment(),
      eventType: "Profile edit-Email ID change",
      establishmentName: user.establishmentName
    };
    const userDetails = user;
    AddTelemetryService(body, undefined, userDetails);
    googleWebAnalytics("Profile_Edit_Email_ID", "Telemetry_Event", "Profile");
  };

  const handleLogout = () => {
    setTimeout(() => {
      dispatch({ type: "USER_LOGOUT" });
      history.push("/new/iam/login");
      LocalStorage.removeStoredKeys();
    }, 1000);
  };

  const handleValidate = (values: any) => {
    let errors: any = {};
    const regex = /^\d+$/;
    if (!values.otp || values?.otp === null || values?.otp?.length !== 6) {
      errors.otp = "Please enter otp";
    }
    if (values.otp && !regex.test(values?.otp)) {
      errors.otp = "Invalid characters";
    }
    setErrorMessage(errors?.otp);
    return errors;
  };

  const submitOtpHandler = () => {
    const valueErrorObject = handleValidate({ otp: otp });
    if (valueErrorObject.otp !== null || valueErrorObject.otp !== "") {
      setError(true);
      if (_.isEmpty(valueErrorObject)) {
        setError(false);
        showEmail ? validateEmailOtp() : validateMobileOtp();
      }
    }
  };

  return (
    <View>
      <ToastContainer />
      {!otpHandle && (
        <View>
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {props.modalTitle}
            </Text>
            <View>
              <Pressable onPress={() => props.onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View style={styles.headerContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleTitleText"
              textSize={TextSize.Small}
              textStyle={styles.subheaderText}
            >
              {props.headerText}
            </Text>
          </View>
          <View style={styles.otpContainer}>
            <Otp
              id="otp"
              name="otp"
              value={otp}
              handleTextChange={(otp: string) => handleOtp(otp)}
              noFormik
            />
          </View>
          <View style={styles.requiredMessageContainer}>
            {error && (
              <Text
                testId="otpError"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                {errorMessage}
              </Text>
            )}
            {!error && incorrectOtpMsg && (
              <Text
                testId="otpError"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                {incorrectOtpMsg}
              </Text>
            )}
          </View>
          <View style={styles.resendContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="addRoleTitleText"
              textSize={TextSize.Small}
              textStyle={[styles.subheaderText, styles.subheaderResendText]}
            >
              {subHeaderText}
            </Text>
            <TouchableOpacity>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleTitleText"
                textSize={TextSize.Small}
                textStyle={[styles.subheaderText, styles.resendText]}
                onPress={resendOtp}
              >
                Resend OTP
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.submitButtonContainer}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.submitbutton}
              title="Verify and Proceed"
              onPress={() => {
                submitOtpHandler();
              }}
              disabled={loading}
              // disabled={error || otp?.length !== 6 || loading}
            />
          </View>
        </View>
      )}
      {otpHandle && enterEmailHandler && (
        <AddEmail
          onClose={props.onClose}
          modalTitle={props.modalTitle}
          isChangeEmailClicked={isChangeEmailClicked}
          userProfileData={response}
        />
      )}
      {otpHandle && enterMobileHandler && (
        <AddMobile onClose={props.onClose} countryCodeObj={countryCodeObj} />
      )}
      {otpHandle && mobileNumberVerified && (
        <View>
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {props.modalTitle}
            </Text>
            <View>
              <Pressable
                onPress={() => {
                  handleLogout();
                }}
              >
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View>
            Your Phone Number has been changed successfully. You will be prompted to login with your
            new Phone Number
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 15
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  headerContainer: {
    alignItems: "center"
  },
  requiredMessageContainer: {
    alignSelf: "center"
  },
  otpContainer: {
    marginTop: 5
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  resendContainer: {
    alignItems: "center"
  },
  subheaderResendText: {
    marginTop: 20
  },
  resendText: {
    color: "#B7504A",
    textDecorationLine: "underline",
    marginTop: 3
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  }
});
