import React, { useState, useEffect } from "react";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { REGISTRY_STATUS } from "@socion-cordio/common/src/network/constants";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import { isEmpty } from "lodash";
import { ProgramsRepository } from "@socion-cordio/common/src/repositories/registry/entity/programs";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import Documents from "@socion-cordio/common/src/components/organisms/entity/documents";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";

interface Props {
  onClose: Function;
  // parent?: any;
  // parentType?: string;
  // dispatchEvent?: Function;
  // programNames: string[];
  partnerProgram?: any;
  modalVisible?: any;
  setModalVisible?: Function;
}

export default function PartnerProgramDetailsModal(props: Props) {
  const { partnerProgram, modalVisible, setModalVisible, onClose } = props;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [program, setProgram] = useState(null);

  useEffect(() => {
    getPartnerProgramEntityDetails();
  }, []);

  const getPartnerProgramEntityDetails = async () => {
    setIsLoading(true);
    console.log("getPartnerEntityDetails getPartnerProgramEntityDetails", partnerProgram);
    const programDetails = await ProgramsRepository.readProgram(partnerProgram?.programId).catch(
      (err) => {
        setIsLoading(false);
      }
    );
    console.log("entityDetailsentityDetails getPartnerProgramEntityDetails", programDetails);
    setProgram({ ...programDetails, programId: programDetails?.osid });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader customLoadingContainer={styles.loaderContiner} />
      ) : (
        <View style={styles.container}>
          <ToastContainer />
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addEmailText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {"Partner Program Details"}
            </Text>
            <View>
              <Pressable onPress={() => onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <ScrollContainer height="500px">
            <View>
              <View style={styles.titleContainer}>
                <View style={styles.textFieldContianer}>
                  <View style={styles.labelTextContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="programNameTitleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, styles.textTitle]}
                    >
                      Program Name
                    </Text>
                  </View>
                  <View style={styles.widthContainer}>
                    <View style={styles.contentContainer}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="qualificationTitleText"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.subheaderText,
                          { marginTop: 2, marginLeft: 10 },
                          styles.valueData
                        ]}
                      >
                        {program?.name}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={[styles.titleContainer]}>
                <View style={styles.textFieldContianer}>
                  <View style={styles.labelTextContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="qualificationTitleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, styles.textTitle]}
                    >
                      Description
                    </Text>
                  </View>
                  <View style={styles.widthContainer}>
                    <View style={styles.contentContainer}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="qualificationTitleText"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.subheaderText,
                          { marginTop: 2, marginLeft: 10 },
                          styles.valueData
                        ]}
                      >
                        {program?.description}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              {/* <View style={styles.containerSecondary}> */}
              {/* <View style={{}}> */}
              <View style={styles.textFieldContianer}>
                <View style={styles.labelSecondaryContainer}>
                  <View style={[styles.labelTextSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="startDateTitleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 11 }, styles.textTitle]}
                    >
                      Start Date
                    </Text>
                  </View>
                  <View style={styles.contentSecondaryContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="qualificationTitleText"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.subheaderText,
                        { marginTop: 2, marginLeft: 10 },
                        styles.valueData
                      ]}
                    >
                      {program?.startDate
                        ? dataHelper.formatDate(program?.startDate, "DD, MMM, YYYY")
                        : ""}
                    </Text>
                  </View>
                </View>
              </View>
              {/* </View> */}

              {/* <View style={{}}> */}
              <View style={[styles.textFieldContianer]}>
                <View style={styles.labelSecondaryContainer}>
                  <View style={[styles.labelTextSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="endDateTitleText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 11 }, styles.textTitle]}
                    >
                      End Date
                    </Text>
                  </View>
                  <View style={[styles.contentSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="qualificationTitleText"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.subheaderText,
                        { marginTop: 2, marginLeft: 10 },
                        styles.valueData
                      ]}
                    >
                      {program?.endDate
                        ? dataHelper.formatDate(program?.endDate, "DD, MMM, YYYY")
                        : ""}
                    </Text>
                  </View>
                </View>
              </View>
              {/* </View> */}
              {/* </View> */}
              <View style={styles.detailsSubContainerTwo}>
                {/* {selectedProgram && selectedProgram?.docs ? ( */}
                <Documents
                  ids={[]}
                  taggedId={program?.osid}
                  selectedItem={program}
                  itemType={REGISTRY_TYPE.entity_program}
                  showUploadIcon={false}
                  // uploadText={"Upload Program Documents"}
                />
                {/* ) : null} */}
              </View>
            </View>
          </ScrollContainer>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "655px"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 25
  },
  headerText: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },

  titleContainer: {
    display: "flex",
    flexDirection: "row"
    // flex: 50,
    // minHeight: 70
  },
  containerSecondary: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
    // minHeight: 45
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  textFieldContianer: {
    flexDirection: "row",
    width: "100%",
    display: "flex",
    alignItems: "baseline"
  },
  labelTextContainer: {
    flex: 18,
    width: "50%"
  },
  contentContainer: {
    // flex: 85
  },
  labelSecondaryContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "baseline"
  },
  labelTextSecondaryContainer: {
    // flex: 28
    width: "50%"
    // marginRight: 8
  },
  contentSecondaryContainer: {
    // flex: 58
    width: "50%"
  },

  inputMobile: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "100%"
  },
  entityContainer: {
    fontFamily: FontFamily.Medium,
    fontWeight: "400",
    padding: 0
  },

  textTitle: {
    color: colorPallete.black,
    fontWeight: "500",
    fontSize: 14
  },
  valueData: {
    fontWeight: "500",
    fontSize: 12
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },

  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.5rem"
  },

  qualificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    height: "80px",
    marginRight: "5px",
    padding: 15,
    marginTop: 20,
    borderColor: "#3FA202",
    borderStyle: "solid",
    borderWidth: 1
  },

  textAreaALign: {
    borderRadius: 5,
    padding: 7,
    paddingTop: 10,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "100%"
  },

  uploadDocIcon: {
    opacity: 0.5
  },
  loaderContiner: {
    width: 655,
    height: 438
  },

  widthContainer: {
    width: "50%"
  },

  docsContainer: {
    marginTop: 10
  },
  docNameContainer: {
    width: 600
  },
  qualificationTitle: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  detailsSubContainerTwo: {
    marginTop: 20
    // flex: 50
  }
});
