import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, useWindowDimensions, View, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";
import ScanSessionSidePanel from "@socion-cordio/common/src/components/organisms/session/side-bar-panel/scanSessionSidePanel";
import AddLinksSidePanel from "@socion-cordio/common/src/components/organisms/session/side-bar-panel/addLinksSidePanel";
import ViewContentSidePanel from "@socion-cordio/common/src/components/organisms/session/side-bar-panel/viewContentSidePanel";
import ViewMembersSidePanel from "@socion-cordio/common/src/components/organisms/session/side-bar-panel/viewMembersSidePanel";
import TasksSidePanel from "@socion-cordio/common/src/components/organisms/session/side-bar-panel/tasksSidePanel";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";

interface Props {
  // For session scan details
  sessionData?: any;
  sessionId?: number;
  isSessionCompleted?: boolean;
  isMember?: boolean;
  getCompleteSessionDetails?: Function;
  isLiveSession?: any;
  isCompletedSession?: any;

  // For session links
  sessionLinks?: any;
  // sessionId?: number;
  sessionDetails?: any;
  // isSessionCompleted?: boolean;

  // For members list
  sessionMembers?: any;
  setIsLoader?: Function;

  // For menu collapse
  menuCollapseHandler?: Function;
  isMenuCollapsed?: boolean;
}

export default function SideBarPanelSession(props: Props): ReactElement {
  const {
    isMember = false,
    getCompleteSessionDetails,
    isLiveSession,
    isCompletedSession,
    sessionData,
    sessionId,
    isSessionCompleted,
    sessionLinks,
    sessionMembers,
    setIsLoader,
    menuCollapseHandler,
    isMenuCollapsed
  } = props;

  const [isMenuCollapsedSidePanel, setIsMenuCollapsedSidePanel] = useState(true); //by default close the menu
  const [scanSessionClicked, setScanSessionClicked] = useState(false);
  const [sessionCollapse, setSessionCollapse] = useState(false); // default session collapse
  const [linksCollapse, setLinksCollapse] = useState(false); // default links collapse
  const [tasksCollapse, setTasksCollapse] = useState(false); // default tasks collapse
  const [membersCollapse, setMembersCollapse] = useState(false); // default member collapse
  const [contentCollapse, setContentCollapse] = useState(false); // default tasks collapse
  const [tabClicked, setTabClicked] = useState("");

  useEffect(() => {
    if (!isMenuCollapsed) {
      setIsMenuCollapsedSidePanel(true);
    }
  }, [isMenuCollapsed]);

  // useEffect(() => {
  //   if (!isMenuCollapsed) {
  //     setIsMenuCollapsedSidePanel(false);
  //   }
  // }, []);

  useEffect(() => {
    menuCollapseHandler(!isMenuCollapsedSidePanel);
    // isMenuCollapsed is false reset the states
    if (isMenuCollapsedSidePanel) {
      tabsToCloseHandler();
    }
    // console.log("isMenuCollapsedSidePanel--->>", isMenuCollapsedSidePanel);
    tabsOpenCloseValidator();
  }, [tabClicked, isMenuCollapsedSidePanel]);

  const tabsOpenCloseValidator = () => {
    switch (tabClicked) {
      case "scanSession":
        if (sessionCollapse) {
          tabsToCloseHandler();
          setSessionCollapse(true);
        } else {
          setSessionCollapse(false);
        }
        break;

      case "links":
        if (linksCollapse) {
          tabsToCloseHandler();
          setLinksCollapse(true);
        } else {
          setLinksCollapse(false);
        }
        break;

      case "tasks":
        if (tasksCollapse) {
          tabsToCloseHandler();
          setTasksCollapse(true);
        } else {
          setTasksCollapse(false);
        }
        break;

      case "members":
        if (membersCollapse) {
          tabsToCloseHandler();
          setMembersCollapse(true);
        } else {
          setMembersCollapse(false);
        }
        break;

      case "content":
        if (contentCollapse) {
          tabsToCloseHandler();
          setContentCollapse(true);
        } else {
          setContentCollapse(false);
        }
        break;

      default:
        tabsToCloseHandler();
        break;
    }

    // console.log("sessionCollapse", sessionCollapse);
    // console.log("linksCollapse", linksCollapse);
    // console.log("tasksCollapse", tasksCollapse);
    // console.log("membersCollapse", membersCollapse);
    // console.log("contentCollapse", contentCollapse);
  };

  const tabsToCloseHandler = () => {
    setSessionCollapse(false);
    setLinksCollapse(false);
    setTasksCollapse(false);
    setMembersCollapse(false);
    setContentCollapse(false);
  };

  return (
    <View>
      <View
        style={
          isMenuCollapsed
            ? [styles.container, { width: 340, minHeight: "60vh" }]
            : [styles.container, { width: 60, minHeight: "60vh" }]
        }
      >
        {!isMenuCollapsedSidePanel ? (
          <ScanSessionSidePanel
            sessionData={sessionData}
            sessionId={sessionId}
            isSessionCompleted={isSessionCompleted}
            isMember={isMember}
            getCompleteSessionDetails={getCompleteSessionDetails}
            isLiveSession={isLiveSession}
            isCompletedSession={isCompletedSession}
            setSessionCollapse={setSessionCollapse}
            sessionCollapse={sessionCollapse}
            setTabClicked={setTabClicked}
          />
        ) : (
          <TouchableOpacity
            onPress={() => {
              // setScanSessionClicked(true);
              setIsMenuCollapsedSidePanel(false);
              tabsToCloseHandler();
              setSessionCollapse(true);
              setTabClicked("scanSession");
            }}
            style={styles.iconStylesContainer}
          >
            <Icon
              testID="qrCodePrimary"
              name={IconNames.qrCodePrimary}
              customStyle={styles.iconStyles}
            />
          </TouchableOpacity>
        )}

        {!isMenuCollapsedSidePanel ? (
          <ViewContentSidePanel
            sessionDetails={sessionData}
            isSessionCompleted={isSessionCompleted}
            setContentCollapse={setContentCollapse}
            contentCollapse={contentCollapse}
            setTabClicked={setTabClicked}
          />
        ) : (
          <TouchableOpacity
            onPress={() => {
              setIsMenuCollapsedSidePanel(false);
              tabsToCloseHandler();
              setContentCollapse(true);
              setTabClicked("content");
            }}
            style={styles.iconStylesContainer}
          >
            <View style={styles.paperClipContainer}>
              <Icon testID="paperClip" name={IconNames.paperClip} customStyle={styles.iconStyles} />
            </View>
          </TouchableOpacity>
        )}

        {!isMenuCollapsedSidePanel ? (
          <ViewMembersSidePanel
            sessionDetails={sessionData}
            sessionMembers={sessionMembers}
            sessionId={sessionId}
            isSessionCompleted={isSessionCompleted}
            getCompleteSessionDetails={getCompleteSessionDetails}
            setIsLoader={setIsLoader}
            setMembersCollapse={setMembersCollapse}
            membersCollapse={membersCollapse}
            setTabClicked={setTabClicked}
          />
        ) : (
          <TouchableOpacity
            onPress={() => {
              setIsMenuCollapsedSidePanel(false);
              tabsToCloseHandler();
              setMembersCollapse(true);
              setTabClicked("members");
            }}
            style={styles.iconStylesContainer}
          >
            <Icon
              testID="attendanceSecondary"
              name={IconNames.attendanceSecondary}
              customStyle={[styles.iconStyles, { marginLeft: 2 }]}
            />
          </TouchableOpacity>
        )}

        {!isMenuCollapsedSidePanel ? (
          <AddLinksSidePanel
            sessionLinks={sessionLinks}
            sessionId={sessionId}
            sessionDetails={sessionData}
            isSessionCompleted={isSessionCompleted}
            setLinksCollapse={setLinksCollapse}
            linksCollapse={linksCollapse}
            setTabClicked={setTabClicked}
            getCompleteSessionDetails={getCompleteSessionDetails}
          />
        ) : (
          <TouchableOpacity
            onPress={() => {
              setIsMenuCollapsedSidePanel(false);
              tabsToCloseHandler();
              setLinksCollapse(true);
              setTabClicked("links");
            }}
            style={styles.iconStylesContainer}
          >
            <Icon testID="links" name={IconNames.links} customStyle={styles.iconStyles} />
          </TouchableOpacity>
        )}
        {sessionData?.topicInfo?.task !== null &&
          sessionData?.topicInfo?.task?.status === "ACTIVE" && (
            <>
              {!isMenuCollapsedSidePanel ? (
                <TasksSidePanel
                  setTasksCollapse={setTasksCollapse}
                  tasksCollapse={tasksCollapse}
                  setTabClicked={setTabClicked}
                />
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    setIsMenuCollapsedSidePanel(false);
                    tabsToCloseHandler();
                    setTasksCollapse(true);
                    setTabClicked("tasks");
                  }}
                  style={styles.iconStylesContainer}
                >
                  <Icon
                    testID="taskSecondary"
                    name={IconNames.taskSecondary}
                    customStyle={[styles.iconStyles, { marginLeft: 2 }]}
                  />
                </TouchableOpacity>
              )}
            </>
          )}
      </View>
      {/* <View style={[styles.miniContainer]}>
        <TouchableOpacity
          onPress={() => {
            setIsMenuCollapsedSidePanel((prevState) => !prevState);
          }}
          style={{ margin: 10 }}
        >
          <Icon
            customStyle={styles.sidebarIconStyle}
            name={
              !isMenuCollapsedSidePanel ? IconNames.doubleArrowLeft : IconNames.doubleArrowRight
            }
          />
        </TouchableOpacity>
      </View> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: colorPallete.cordioRedDark2
  },
  // dividerStyles: {
  //   borderColor: colorPallete.lightGreyThree,
  //   borderStyle: "solid",
  //   borderWidth: 1
  // },
  // dividerStyles2: {
  //   borderColor: colorPallete.cordioRedDark2,
  //   borderStyle: "solid",
  //   borderWidth: 1,
  //   marginVertical: 3
  // },
  miniContainer: {
    // marginTop: 380,
    marginLeft: -25,
    marginTop: 380,
    backgroundColor: colorPallete.cordioRedDark2,
    width: 60,
    height: 60,
    borderRadius: 10,
    borderTopRightRadius: 30,
    transform: [{ rotate: "226deg" }],
    position: "absolute",
    zIndex: -1
  },
  sidebarIconStyle: {
    fontSize: 25,
    alignSelf: "flex-end",
    // margin: 10,
    color: colorPallete.white,
    transform: [{ rotate: "315deg" }]
  },
  iconStylesContainer: {
    marginTop: 10,
    marginBottom: 12
  },
  iconStyles: {
    fontSize: 20,
    color: colorPallete.cordioBeige
  },
  paperClipContainer: {
    transform: [{ rotate: "315deg" }]
  }
});
