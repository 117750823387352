import { Entity } from "@socion-cordio/common/src/models/registry/entity";
import { ENTITIES } from "@socion-cordio/common/src/modules/registry/entity/actions/actionTypes";

export interface IEntityLoading {
  getEntities: boolean;
  getAllEntities: boolean;
  getDocs: boolean;
  getEntity: boolean;
  getAdmins: boolean;
  getPrograms: boolean;
  getProgramPartners: boolean;
  getEntityPartners: boolean;
}

export interface IEntityError {
  getEntities: string;
  getAllEntities: string;
  getDocs: string;
  getEntity: string;
  getAdmins: string;
  getPrograms: string;
  getProgramPartners: string;
  getEntityPartners: string;
}

export interface IEntityState {
  entities: any;
  allEntities: any;
  docs: any;
  entity: any;
  admins: any;
  programs: any;
  programPartners: any;
  entityPartners: any;
  error: IEntityError;
  loading: IEntityLoading;
}

export const entitiesInitialState: IEntityState = {
  entities: null,
  allEntities: null,
  docs: null,
  entity: null,
  admins: null,
  programs: null,
  programPartners: null,
  entityPartners: null,
  error: {
    getEntities: "",
    getAllEntities: "",
    getDocs: "",
    getEntity: "",
    getAdmins: "",
    getPrograms: "",
    getProgramPartners: "",
    getEntityPartners: ""
  },
  loading: {
    getEntities: true,
    getAllEntities: true,
    getDocs: true,
    getEntity: true,
    getAdmins: true,
    getPrograms: true,
    getProgramPartners: true,
    getEntityPartners: true
  }
};

const EntitiesReducer = (
  state = entitiesInitialState,
  action: { payload: any; type: string }
): IEntityState => {
  switch (action.type) {
    case ENTITIES.GET_ENTITIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEntities: true },
        error: { ...state.error, getEntities: null }
      };
    case ENTITIES.GET_ENTITIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEntities: false },
        error: { ...state.error, getEntities: null },
        entities: action.payload
      };
    case ENTITIES.GET_ENTITIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEntities: false },
        error: { ...state.error, getEntities: action.payload }
      };
    case ENTITIES.GET_ALL_ENTITIES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAllEntities: true },
        error: { ...state.error, getAllEntities: null }
      };
    case ENTITIES.GET_ALL_ENTITIES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAllEntities: false },
        error: { ...state.error, getAllEntities: null },
        allEntities: action.payload
      };
    case ENTITIES.GET_ALL_ENTITIES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAllEntities: false },
        error: { ...state.error, getAllEntities: action.payload }
      };
    case ENTITIES.GET_ENTITY_DOCS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getDocs: true },
        error: { ...state.error, getDocs: null }
      };
    case ENTITIES.GET_ENTITY_DOCS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDocs: false },
        error: { ...state.error, getDocs: null },
        docs: action.payload
      };
    case ENTITIES.GET_ENTITY_DOCS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getDocs: false },
        error: { ...state.error, getDocs: action.payload }
      };
    case ENTITIES.GET_ENTITY.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEntity: true },
        error: { ...state.error, getEntity: null }
      };
    case ENTITIES.GET_ENTITY.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEntity: false },
        error: { ...state.error, getEntity: null },
        entity: action.payload
      };
    case ENTITIES.GET_ENTITY.ERROR:
      console.log("fcdvbfdsfbnvds", action);

      return {
        ...state,
        loading: { ...state.loading, getEntity: false },
        error: { ...state.error, getEntity: action.payload }
      };
    case ENTITIES.GET_ENTITY_ADMINS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getAdmins: true },
        error: { ...state.error, getAdmins: null }
      };
    case ENTITIES.GET_ENTITY_ADMINS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getAdmins: false },
        error: { ...state.error, getAdmins: null },
        admins: action.payload
      };
    case ENTITIES.GET_ENTITY_ADMINS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getAdmins: false },
        error: { ...state.error, getAdmins: action.payload }
      };
    case ENTITIES.GET_ENTITY_PROGRAMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: true },
        error: { ...state.error, getPrograms: null }
      };
    case ENTITIES.GET_ENTITY_PROGRAMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: false },
        error: { ...state.error, getPrograms: null },
        programs: action.payload
      };
    case ENTITIES.GET_ENTITY_PROGRAMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: false },
        error: { ...state.error, getPrograms: action.payload }
      };
    case ENTITIES.GET_ENTITY_PROGRAM_PARTNERS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getProgramPartners: true },
        error: { ...state.error, getProgramPartners: null }
      };
    case ENTITIES.GET_ENTITY_PROGRAM_PARTNERS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProgramPartners: false },
        error: { ...state.error, getProgramPartners: null },
        programPartners: action.payload
      };
    case ENTITIES.GET_ENTITY_PROGRAM_PARTNERS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProgramPartners: false },
        error: { ...state.error, getProgramPartners: action.payload }
      };
    case ENTITIES.GET_ENTITY_PARTNERS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEntityPartners: true },
        error: { ...state.error, getEntityPartners: null }
      };
    case ENTITIES.GET_ENTITY_PARTNERS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEntityPartners: false },
        error: { ...state.error, getEntityPartners: null },
        entityPartners: action.payload
      };
    case ENTITIES.GET_ENTITY_PARTNERS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEntityPartners: false },
        error: { ...state.error, getEntityPartners: action.payload }
      };
    default:
      return state;
  }
};
export default EntitiesReducer;
