import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Tooltip from "react-tooltip-lite";
import Moment from "moment";
import Rating from "@socion-cordio/common/src/components/atoms/rating";
import { Link } from "react-router-dom";
interface Props {
  programDetails: any;
}

export default function CompletedSessionPage(props: Props): ReactElement {
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [completedSessionResponse, setCompletedSessionResponse] = useState(null);
  const [user, setUser] = useState(null);
  const [userRolesData, setUserRolesData] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const history: any = useHistory();

  useEffect(() => {
    getSessionDetails();
  }, []);

  useEffect(() => {
    (async () => {
      let userData = await LocalStorage.getStorage("user");
      let tempUserRolesData: any = await LocalStorage.getStorage("userRoles");
      setUser(userData);
      setUserRolesData(tempUserRolesData?.userRolesData?.response);
    })();
  }, []);

  const getSessionDetails = async () => {
    try {
      const { program } = props?.programDetails;
      const newBody = {
        programId: program.id,
        sessionStatus: "COMPLETED"
      };
      //update api here getCompletedSessionDetails
      const response = await ApiClient.post(
        sessionPackEndPoints.getCompletedSessionDetails(),
        newBody
      );
      response?.response.forEach((session: any, index: number) => {
        session.serialNo = `${index + 1}.`;
        // session.session.formattedSessionStartDate = dataHelper.formatDate(
        //   session?.session?.sessionStartDate
        // );
        // session.session.formattedSessionEndDate = dataHelper.formatDate(
        //   session?.session?.sessionEndDate
        // );
        let startDate = new Date(Moment.utc(session?.session?.sessionStartDate));
        let endDate = new Date(Moment.utc(session?.session?.sessionEndDate));
        session.session.formattedSessionStartDate = dataHelper.formatDate(startDate);
        session.session.formattedSessionEndDate = dataHelper.formatDate(endDate);
        session.session.sessionRating = getRating(
          session?.session?.ratings / session?.session?.respondents
        );
        session.scanOuts =
          session?.session?.sessionScanMode === "SINGLE_SCAN"
            ? "NA"
            : session?.scanOuts
            ? session?.scanOuts
            : 0;
      });
      setCompletedSessionResponse(response?.response);
      setLoading(false);
      if (program?.paRatingsEnabled) {
        return setUpdatedColumns(columns);
      } else {
        let newColumns = columns.pop();
        return setUpdatedColumns(columns);
      }
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const viewSessionDetailsHandler = (data: any) => {
    // history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/completed`, {
    //   sessionData: data
    // });
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live/scanIn`, {
      sessionData: data,
      completed: true,
      redirectionFrom: "CompletedSession",
      sessionScanMode: data.session.sessionScanMode,
      scanType: "Scan In",
      isSessionNameClicked: true
    });
    defaultCollapseForLeftPanelHandler();
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const handleScanIn = (data: any) => {
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live/scanIn`, {
      sessionData: data,
      completed: true,
      redirectionFrom: "CompletedSession",
      sessionScanMode: data.session.sessionScanMode,
      scanType: "Scan In"
    });
    defaultCollapseForLeftPanelHandler();
  };

  const handleScanOut = (data: any) => {
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live/scanOut`, {
      sessionData: data,
      completed: true,
      redirectionFrom: "CompletedSession",
      sessionScanMode: data.session.sessionScanMode,
      scanType: "Scan Out"
    });
    defaultCollapseForLeftPanelHandler();
  };

  const defaultCollapseForLeftPanelHandler = () => {
    // Close the left side panel when on click of view session
    LocalStorage.setStorage("isSidePanelCollapsed", true);
  };

  const getRating = (data?: any) => {
    let value = data;
    if (value.toString().indexOf(".") == -1) return value;
    else return value.toFixed(1);
  };

  const renderName = (valueString: string) => (
    <a style={{ color: colorPallete.textBlack, fontFamily: FontFamily.Medium, fontSize: 12 }}>
      {valueString}
    </a>
  );

  const columns = [
    {
      Header: "Sr.No",
      accessor: "serialNo",
      width: 30,
      maxWidth: 30,
      disableSortBy: true,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("serialNo", style, session?.serialNo);
      }
    },

    {
      Header: "Session Name",
      accessor: "session.sessionName",
      width: 160,
      maxWidth: 160,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold
        };
        const onPress = () => {
          // session.memberRole ||
          // user?.userId == session?.sessionCreator?.userId ||
          // userRolesData.includes("ENTITY_ADMIN") ||
          // userRolesData.includes("PROGRAM_ADMIN")
          viewSessionDetailsHandler(session);
          // : null;
        };
        return (
          // cellData("name", style, session?.session.sessionName, onPress)
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText1"
            textSize={TextSize.Small}
            style={[styles.sessionName]}
            onPress={onPress}
          >
            {renderName(session?.session.sessionName)}
          </Text>
        );
      }
    },
    {
      Header: "Start",
      accessor: "session.sessionStartDate",
      width: 50,
      maxWidth: 50,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("start", style, session?.session?.formattedSessionStartDate);
      }
    },
    {
      Header: "End",
      accessor: "session.sessionEndDate",
      width: 50,
      maxWidth: 50,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("end", style, session?.session?.formattedSessionEndDate);
      }
    },
    {
      Header: "Scan In",
      accessor: "scanIns",
      width: 56,
      maxWidth: 56,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular
          // fontWeight: FontFamily.Bold,
        };
        const onPress = () => {
          // session.memberRole ||
          // user?.userId == session?.sessionCreator?.userId ||
          // userRolesData.includes("ENTITY_ADMIN") ||
          // userRolesData.includes("PROGRAM_ADMIN")
          //   ? handleScanIn(session)
          //   : null;
          handleScanIn(session);
        };
        const scanIns = session?.scanIns ? session?.scanIns : 0;
        // return cellData("sessionCreator", style, scanIns, onPress);
        return (
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText1"
            textSize={TextSize.Small}
            style={[styles.sessionName]}
            onPress={onPress}
          >
            {renderName(scanIns)}
          </Text>
        );
        // : (
        //   cellData("sessionCreator", style, scanIns, onPress)
        // );
      }
    },
    {
      Header: "Scan Out",
      accessor: "scanOuts",
      width: 56,
      maxWidth: 56,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular
          // fontWeight: FontFamily.Bold,
        };
        const onPress = () => {
          // session.memberRole ||
          // user?.userId == session?.sessionCreator?.userId ||
          // userRolesData.includes("ENTITY_ADMIN") ||
          // userRolesData.includes("PROGRAM_ADMIN")
          //   ? handleScanOut(session)
          //   : null;
          handleScanOut(session);
        };
        const scanOuts = session?.scanOuts;
        // return cellData("sessionCreator", style, scanOuts, onPress);
        return (
          <>
            {scanOuts === "NA" ? (
              <Text
                fontWeight={FontWeight.Thin}
                testId="headerText1"
                textSize={TextSize.Small}
                style={styles.scanOutText}
              >
                {renderName(scanOuts)}
              </Text>
            ) : (
              <Text
                fontWeight={FontWeight.Thin}
                testId="headerText1"
                textSize={TextSize.Small}
                style={styles.scanOutText}
                onPress={onPress}
              >
                {renderName(scanOuts)}
              </Text>
            )}
          </>
        );
        // : (
        //   cellData("sessionCreator", style, scanOuts, scanOuts !== "NA" && onPress)
        // );
      }
    },
    {
      Header: "Venue",
      accessor: "session.address",
      width: 56,
      maxWidth: 56,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return session?.session?.address.length < 10 ? (
          cellData("venue", style, session?.session?.address)
        ) : (
          <Tooltip
            styles={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inherit",
              fontFamily: FontFamily.Regular,
              fontSize: 12,
              color: colorPallete.textBlack
            }}
            tipContentClassName=""
            distance={10}
            direction="bottom"
            background={colorPallete.cordioBeigeLight1}
            color="black"
            padding={10}
            content={
              <View style={{ width: 250 }}>
                <Text
                  testId="testId"
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.Small}
                  style={{
                    fontFamily: FontFamily.Regular,
                    fontSize: 12
                  }}
                >
                  {session?.session?.address}
                </Text>
              </View>
            }
          >
            {session?.session?.address}
          </Tooltip>
        );
      }
    },
    {
      Header: "Rating",
      accessor: "session.respondents",
      width: 85,
      maxWidth: 85,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        let rating =
          session?.session?.ratings === null || session?.session?.ratings === 0 ? (
            "NA"
          ) : (
            <View style={styles.ratingsContainer}>
              <Rating data={session?.session?.sessionRating} />
              <View style={{ marginLeft: 8 }}>
                <Text
                  testId="testId"
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.Small}
                  style={[styles.overallRating, { marginBottom: 1 }]}
                >
                  {`(${session?.session?.respondents})`}
                </Text>
              </View>
            </View>
          );
        return cellData("rating", style, rating);
      }
    }
  ];

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          <Table
            columns={updatedColumns}
            eventAnalytics="Completed_Session"
            data={completedSessionResponse}
            noDataMessage="No sessions completed under this program"
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  sessionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  overallRating: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    color: colorPallete.cordioGray
  },
  ratingsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  scanOutText: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontSize: 12
  }
});
