import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Program } from "@socion-cordio/common/src/models/program";
import { EntityInvite } from "@socion-cordio/common/src/models/entityInvite";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  programEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { LicenseAndPricing } from "@socion-cordio/common/src/models/licensePricing";
import { LicenseAndPricingCredits } from "@socion-cordio/common/src/models/licenseModelCredits";
import { LicenseAndPricingAll } from "@socion-cordio/common/src/models/licenseModelAll";

class ProgramRepository {
  getPrograms = async (): Promise<Program> => {
    let data = await ApiClient.get(springshedEndPoints.getPrograms());
    // const programsData: Program = deserialize(Program, data);
    return data;
  };

  getEntityInvite = async (): Promise<EntityInvite> => {
    let data = await ApiClient.get(springshedEndPoints.inviteEntity());
    // if (data.responseCode === 200) {
    //   data = deserialize(EntityInvite, data);
    //   return data;
    // }
    return data;
  };

  getLicenseAndPricing = async (programId: any): Promise<LicenseAndPricing> => {
    let data = await ApiClient.get(programEndPoints.getLicenseAndPricing(programId));
    return data;
  };

  getLicenseAndPricingCredits = async (licenseId: any): Promise<LicenseAndPricingCredits> => {
    let data = await ApiClient.get(programEndPoints.getLicenseAndPricingCredits(licenseId));
    return data;
  };

  getLicenseAndPricingDebits = async (licenseId: any): Promise<LicenseAndPricingCredits> => {
    let data = await ApiClient.get(programEndPoints.getLicenseAndPricingDebits(licenseId));
    return data;
  };

  getLicenseAndPricingAll = async (licenseId: any): Promise<LicenseAndPricingAll> => {
    let data = await ApiClient.get(programEndPoints.getLicenseAndPricingAll(licenseId));
    return data;
  };

  getUserPrivileges = async (programId: number): Promise<any> => {
    let data = await await ApiClient.get(programEndPoints.getSuperAdminPrivileges(programId));
    return data;
  };

  deleteSelectedLicense = async (licenseId: any): Promise<any> => {
    let data = await await ApiClient.delete(programEndPoints.deleteSelectedLicense(licenseId));
    return data;
  };
}
const programRepository = new ProgramRepository();
export { programRepository as ProgramRepository };
