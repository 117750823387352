import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import jsonToCsv from "@socion-cordio/common/src/utils/jsonToCsvDownload";
import { useTranslation } from "react-i18next";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";

interface Props {
  topicDetails?: any;
  topicName?: string;
  topicId?: any;
}

export default function TopicTrainer(props: Props): ReactElement {
  const { topicDetails, topicName, topicId } = props;
  const { t } = useTranslation();
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(false);

  useEffect(() => {
    if (viewButtonDetails) disableScroll();
    else enableScroll();
  }, [viewButtonDetails]);

  useEffect(() => {
    formatHeaderData(topicDetails?.badgeData);
  }, []);

  useEffect(() => {
    leftNavCollapseHandler();
  });

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  const formatHeaderData = (data: any) => {
    console.log(data);
    let columnsList = [...columns];
    columnsList.splice(7);
    data?.forEach((participationBadge: any, index: number) => {
      console.log("participationBadge", participationBadge);
      columnsList.push({
        Header: `${participationBadge.badgeType} Badge`,
        accessor: participationBadge.badgeType,
        width: 150,
        maxWidth: 150,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const newBadgeData: any = null;
          const newbadgeList: any = [];
          topic.badgeDetails?.forEach((badge: any, index: number) => {
            if (topic.badgeDetails[index]?.badgeTypeId == participationBadge.badgeTypeId) {
              newBadgeData == topic.badgeDetails[index];
              newbadgeList.push(topic.badgeDetails[index]);
            }
          });
          const assignedBadges = auxiliaryMethods.getAssignedBadges(newbadgeList) || "None";
          return cellData("badgeDetails", style, assignedBadges);
        }
      });
    });
    setUpdatedColumns(columnsList);
    return columnsList;
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, topic?.serialNo);
        }
      },
      {
        Header: "Trainer Name",
        accessor: "name",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(topic)}>
                {topic?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Base Location",
        accessor: "formattedBaseLocation",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(topic) !== "None" ? (
                <CustomTooltip
                  hoverValue={topic.formattedBaseLocation}
                  textValue={auxiliaryMethods.getUserFirstLocationName(topic)}
                />
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Establishment",
        accessor: "establishmentName",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const establishment = topic?.establishmentName ? topic?.establishmentName : "None";
          return cellData("establishment", style, establishment);
        }
      },
      {
        Header: "Verified Role",
        accessor: "verifiedRole",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("role", style, topic?.verifiedRole);
        }
      },
      {
        Header: "Verified Qualification",
        accessor: "verifiedQualification",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("qualification", style, topic?.verifiedQualification);
        }
      },
      {
        Header: "Attestation Received",
        accessor: "attestations",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("attestations", style, topic?.attestations);
        }
      }
    ],
    []
  );

  const downloadCsvList = async (data: any) => {
    setLoading(true);
    let payload = {
      topicIds: [topicId],
      userType: "TRAINER",
      timezone: auxiliaryMethods.getLocalTimezone()
    };
    const response = await ApiClient.post(topicEndPoints.getParticipantListCSV(), payload);
    if (response) {
      const program: any = await LocalStorage.getStorage("programDetails");
      const { name } = program.program;
      auxiliaryMethods.handleDownload(response, `${name}-Trainers`);
      setLoading(false);
    }
  };

  return (
    <View>
      {loading ? (
        <Loader />
      ) : (
        <View
          style={leftSidePanelCollapse ? styles.parentTableContainer : styles.parentTableContainer2}
        >
          <Table
            columns={updatedColumns}
            data={topicDetails}
            paginationAndSearch={true}
            eventAnalytics="TopicsTrainer"
            showPaginationField={true}
            noDataMessage="No Trainer List(s) under this Topic."
            download={
              <TouchableOpacity onPress={() => downloadCsvList(topicDetails)}>
                <View style={{ flexDirection: "row" }}>
                  <Icon name={IconNames.downloadList} customStyle={styles.iconStyle} />
                  <View>
                    <Text
                      testId="testId"
                      fontWeight={FontWeight.Bold}
                      textSize={TextSize.Small}
                      style={[
                        styles.textAlignment,
                        {
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }
                      ]}
                    >
                      {t("table:download")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            }
          />
          {viewButtonDetails && (
            <View>
              <SocionModal
                modalVisible={viewButtonDetails}
                setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                component={
                  <ViewUserDetails
                    onClose={() => {
                      setViewButtonDetails(false);
                    }}
                    selectedUserDetails={selectedUserDetails}
                    piiFilter={false}
                  />
                }
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 25
  },
  customWidthStyle: {
    width: 400
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  headerAlignment: {
    paddingHorizontal: 25
  },
  parentTableContainer: {
    display: "flex",
    width: "calc(100vw - 100px)"
  },
  parentTableContainer2: {
    display: "flex",
    width: "calc(100vw - 290px)"
  }
});
