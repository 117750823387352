import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";

import _ from "lodash";
import { TABS, REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import {
  dispatchEntityProgramPartners,
  getSelectedEntityDetails,
  getSelectedEntityProgramDetails
} from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";

import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import AddPartnerModal from "@socion-cordio/common/src/components/organisms/registry/entity/addPartnerModal";
import { PartnersRepository } from "@socion-cordio/common/src/repositories/registry/entity/partners";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { allRoutesNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";
import { toast } from "react-toastify";

interface IProps {}

const ProgramPartnersList = (props: IProps) => {
  const location: any = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [showCreateProgramPartnerModal, setShowCreateProgramPartnerModal] = useState(false);
  const [showRemoveProgramPartnerModal, setShowRemoveProgramPartnerModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [status, setStatus] = useState(null);
  const history = useHistory();
  const [showList, setShowList] = useState(null);
  const [entity, setEntity] = useState(null);
  const [programData, setProgramData] = useState(null);
  const [partnersList, setPartnersList] = useState(null);
  let isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));

  const {
    programPartners: programPartners,
    loading: { getProgramPartners: loading },
    error: { getProgramPartners: error }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    setIsLoading(true);
    dispatchEntityProgramPartners(dispatch);
    getSelectedEntityData();
    const selectedProgram = location?.state?.programData
      ? location?.state?.programData
      : getSelectedEntityProgramDetails();

    setProgramData(selectedProgram);
  }, []);

  const getSelectedEntityData = async () => {
    const selectedEntity = await getSelectedEntityDetails();
    setEntity(selectedEntity);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setShowList(false);
      setPartnersList([]);
    }
  }, [error]);

  useEffect(() => {
    if (programPartners !== null) {
      setShowList(programPartners.length > 0);
      setPartnersList(programPartners);
      console.log("programPartnersprogramPartners", programPartners);

      setIsLoading(false);
    }
  }, [programPartners]);

  const toggleActivate = async (partner: any, activate?: boolean) => {
    setShowRemoveProgramPartnerModal(false);
    const payload = {
      entityId: programData?.parentId,
      programId: programData?.osid,
      updatedBy: UserHelper.getUserName(),
      updatedById: UserHelper.getUserId()
    };

    console.log("actiavavvavavva", status, partner, activate);

    const res = await PartnersRepository.toggleEntityProgramPartner(
      payload,
      partner?.osid,
      !activate
    ).catch((err) => {
      console.log("Error", error);
      const msg = !activate ? MESSAGES.ERROR.DEACTIVATE : MESSAGES.ERROR.ACTIVATE;
      toast.error(
        dataHelper.replaceText(msg, {
          ["{type}"]: "Partner",
          ["{name}"]: partner?.name
        })
      );
    });

    if (res) {
      // dispatchEntityAdmins(dispatch);

      let data = [...partnersList];
      data = data.map((pgm) => {
        if (pgm?.partnerId === partner?.partnerId) {
          pgm.active = !activate;
        }
        return pgm;
      });
      setPartnersList(data);
      const msg = !activate ? MESSAGES.SUCCESS.DEACTIVATE : MESSAGES.SUCCESS.ACTIVATE;
      toast.success(
        dataHelper.replaceText(msg, {
          ["{type}"]: "Partner",
          ["{name}"]: partner?.name
        })
      );
    }
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          textSize: TextSize.Small,
          fontWeight: FontFamily.Light
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 40,
        maxWidth: 40,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          return cellData("serialNo", style, data.serialNo);
        }
      },
      {
        Header: "Partner Name",
        accessor: "name",
        width: 300,
        maxWidth: 300,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          return cellData("name", style, data?.name);
        }
      },
      {
        Header: "Partner Role",
        accessor: "role",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          return cellData("role", style, data?.role);
        }
      },
      {
        Header: "Partner Since",
        accessor: "partnerSince",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          const date = data?.partnerSince ? dataHelper.formatDate(data?.partnerSince) : "";
          return cellData("partnerSince", style, date);
        }
      },
      {
        Header: "Add/Remove",
        accessor: "active",
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          return (
            <View>
              {!isSuperAdmin && entity?.active && programData?.active && (
                <>
                  {!data?.active && (
                    <TouchableOpacity
                      style={{ alignItems: "center", opacity: 0.6 }}
                      onPress={() => {
                        setSelectedPartner(data);
                        setStatus("activate");
                        setShowRemoveProgramPartnerModal(!showRemoveProgramPartnerModal);
                      }}
                    >
                      <Image
                        testId="secondaryAddImg"
                        source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                        imageStyle={{
                          width: 20,
                          height: 20
                        }}
                      />
                    </TouchableOpacity>
                  )}
                  {!isSuperAdmin && data?.active && (
                    <TouchableOpacity
                      style={{ alignItems: "center" }}
                      onPress={() => {
                        setStatus("deactivate");
                        setSelectedPartner(data);
                        setShowRemoveProgramPartnerModal(!showRemoveProgramPartnerModal);
                      }}
                    >
                      <Image
                        testId="remove"
                        source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                        imageStyle={{
                          width: 20,
                          height: 20
                        }}
                      />
                    </TouchableOpacity>
                  )}
                </>
              )}
            </View>
          );
        }
      }
    ],
    [entity, programData]
  );

  const getCreateProgramPartnerModal = () => {
    return (
      <View>
        <SocionModal
          modalVisible={showCreateProgramPartnerModal}
          setModalVisible={() => setShowCreateProgramPartnerModal(!showCreateProgramPartnerModal)}
          component={
            <AddPartnerModal
              type={REGISTRY_TYPE.entity}
              programData={programData}
              onClose={() => {
                setShowCreateProgramPartnerModal(false);
              }}
              extPartnersList={partnersList}
            />
          }
        />
      </View>
    );
  };

  const getRemoveProgramPartnerModal = () => {
    return (
      <View>
        <SocionModal
          modalVisible={showRemoveProgramPartnerModal}
          setModalVisible={() => setShowRemoveProgramPartnerModal(!showRemoveProgramPartnerModal)}
          component={
            <RemoveLinkModal
              modalVisible={showRemoveProgramPartnerModal}
              selectedData={() => {
                toggleActivate(selectedPartner, selectedPartner?.active);
              }}
              textValue={
                selectedPartner?.active
                  ? "Are you sure you want to deactivate this Partner?"
                  : "Are you sure you want to reactivate this Partner?"
              }
              deleteButtonText={selectedPartner?.active ? "Deactivate" : "Reactivate"}
              setModalVisible={() =>
                setShowRemoveProgramPartnerModal(!showRemoveProgramPartnerModal)
              }
            />
          }
        />
      </View>
    );
  };

  const redirectionHandler = async () => {
    history.push(`${allRoutesNames.app}${allRoutesNames.PROGRAM}`);
  };

  const getPageTitle = () => {
    return (
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <TouchableOpacity onPress={() => redirectionHandler()}>
          <Icon testID="close" name={IconNames.leftArrow} customStyle={styles.iconStyle} />
        </TouchableOpacity>

        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText, { paddingLeft: 10 }]}
        >
          {programData?.name}
        </Text>

        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText, { opacity: 0.6 }]}
        >
          {"   >   Partner List"}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <View style={styles.dataContainer}>
              {getPageTitle()}
              {showList !== null && !showList && (
                <>
                  <View style={styles.search}>
                    <View style={styles.searchContainer}>
                      <SearchFilterSeondary
                        // customWidthStyle={styles.searchContainer}
                        editable={true}
                        placeholder={"Search"}
                      />
                    </View>
                    {!isSuperAdmin && entity?.active && programData?.active && (
                      <TouchableOpacity
                        onPress={() =>
                          setShowCreateProgramPartnerModal(!showCreateProgramPartnerModal)
                        }
                      >
                        <Image
                          testId="primaryAddImg"
                          source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                          imageStyle={{
                            width: 30,
                            height: 30
                          }}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                  <View>
                    <Text
                      fontWeight={FontWeight.Bold}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                    >
                      No Program Partners Available
                    </Text>
                  </View>
                </>
              )}
              {showList && (
                <View>
                  <Table
                    columns={columns}
                    data={partnersList}
                    showSearchField={true}
                    customSearchBarContainerStyle={{ marginLeft: -20 }}
                    paginationAndSearch={true}
                    addIcon={
                      !isSuperAdmin &&
                      entity?.active &&
                      programData?.active && (
                        <TouchableOpacity
                          onPress={() =>
                            setShowCreateProgramPartnerModal(!showCreateProgramPartnerModal)
                          }
                        >
                          <Image
                            testId="primaryAddImg"
                            source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                            imageStyle={{
                              width: 30,
                              height: 30
                            }}
                          />
                        </TouchableOpacity>
                      )
                    }
                  />
                </View>
              )}
            </View>

            {showCreateProgramPartnerModal && getCreateProgramPartnerModal()}
            {showRemoveProgramPartnerModal && getRemoveProgramPartnerModal()}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    padding: 20,
    paddingTop: 18,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    padding: 18,
    borderRadius: 10,
    backgroundColor: colorPallete.white,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },

  dataContainer: {
    padding: 18,
    marginBottom: 180
  },
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 25,
    width: "100%"
  },
  searchContainer: {
    width: 535
  },

  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },

  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  }
});

export default ProgramPartnersList;
