import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Linking } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Header from "@socion-cordio/common/src/components/organisms/accomplishment/components/header";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/accomplishment/components/programDropdown";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { toast } from "react-toastify";
import {
  accomplishments,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { widthPercentageToDP } from "react-native-responsive-screen";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import axios from "axios";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useTranslation } from "react-i18next";

export default function ArtefactDetails(): ReactElement {
  const location: any = useLocation();
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [details, setDetails] = useState(null);
  const [showArtefactsAttachment, setShowArtefactsAttachment] = useState(["true"]);
  const [showArtefactsSubmitters, setShowArtefactsSubmitters] = useState(["true"]);
  const [showArtefactsHistory, setShowArtefactsHistory] = useState(["true"]);
  const [historyData, setHistoryData] = useState(null);
  const history: any = useHistory();
  const { t } = useTranslation();
  const { artefactDetails, programData, userId, programOptions, accessToken, mainProgramList } =
    location.state;

  useEffect(() => {
    getArtefactDetailsData();
    getSelectedProgram();
    getArtefactHistory();
  }, []);

  useEffect(() => {
    if (selectedProgram?.length != 0) {
      LocalStorage.setStorage("selectedProgram", selectedProgram);
      getArtefactDetailsData();
      getArtefactHistory();
    }
  }, [selectedProgram]);

  const getSelectedProgram = async () => {
    let program = await LocalStorage.getStorage("selectedProgram");
    setSelectedProgram(program);
  };

  const getArtefactHistory = async () => {
    const headers = {
      "access-token": accessToken,
      "Content-Type": "application/json"
    };

    const getArtefactHistoryResponse: any = await axios.get(
      `https://pda-a.socion.io/api/v1/workflowhistory/${artefactDetails.artefact_meta_id}`,
      { headers: headers }
    );
    setHistoryData(getArtefactHistoryResponse?.data);
  };

  const getArtefactDetailsData = async () => {
    const payload = {
      programId: [selectedProgram.value],
      user_id: userId,
      artefact_meta_id: artefactDetails.artefact_meta_id,
      artefact_type_id: artefactDetails.artefact_type_id,
      includeFields: [
        "approved_by",
        "user_id",
        "badgerole",
        "submitted_date",
        "approved_date",
        "status",
        "country",
        "state",
        "district",
        "sub_district",
        "city",
        "artefact_type_id",
        "submitters",
        "docs",
        "updated_at",
        "created_at"
      ]
    };

    const headers = {
      "access-token": accessToken,
      "Content-Type": "application/json"
    };

    const getArtefactDetailsDataResponse: any = await axios.post(
      "https://pda-stage.socion.io/api/v1/artefact/list",
      payload,
      { headers: headers }
    );
    setDetails(getArtefactDetailsDataResponse?.data?.data[0]);
  };

  const handleBack = () => {
    history.push(
      `${routeNames.app}${routeNames.ACCOMPLESMENTARTEFACT}/${selectedProgram.value}/${userId}/${accessToken}`,
      {
        programData: selectedProgram,
        programOptions: programOptions,
        userId: userId,
        accessToken: accessToken,
        mainProgramList: mainProgramList
      }
    );
  };

  const handleAttchmentOpen = (index: number) => {
    let temp = [...showArtefactsAttachment];
    temp[index] = !temp[index];
    setShowArtefactsAttachment(temp);
  };

  const handleSubmittersOpen = (index: number) => {
    let temp = [...showArtefactsSubmitters];
    temp[index] = !temp[index];
    setShowArtefactsSubmitters(temp);
  };

  const handleHistoryOpen = (index: number) => {
    let temp = [...showArtefactsHistory];
    temp[index] = !temp[index];
    setShowArtefactsHistory(temp);
  };

  const getFileName = (url: string) => {
    let name = url.split("/");
    return name[name.length - 1];
  };

  const getFilePath = (name: string) => {
    let path = name.substring(0, name.lastIndexOf("/"));
    console.log("path", path);
    return path;
  };

  const handleDownload = async (type: string, name: string, filePath: string, opType: string) => {
    let content = null;
    const bucketname = "artefact";
    const response = await ApiClient.get(
      accomplishments.getDocumentPresignedUrl(filePath, name, bucketname, opType)
    );
    if (response) {
      var link = document.createElement("a");
      link.href = response;
      link.download = "Download.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      content = null;
    }
  };

  const handleView = async (type: string, name: string, filePath: string, opType: string) => {
    const bucketname = "artefact";
    const response = await ApiClient.get(
      accomplishments.getDocumentPresignedUrl(filePath, name, bucketname, opType)
    );
    if (response) {
      Linking.openURL(response);
    }
  };

  return (
    <>
      <View style={styles.body}>
        <ProgramDropdown
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          options={location?.state?.programOptions}
        />
        <View style={{ zIndex: -1 }}>
          <View style={styles.headingContainer}>
            <View style={styles.mt2}>
              <TouchableOpacity onPress={handleBack}>
                <Icon name={IconNames.leftArrow} customStyle={styles.leftarrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.ml20}>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.headerText]}
              >
                {details?.artefact_name}
              </Text>
            </View>
          </View>
          <View style={styles.artefactContainer1}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ paddingRight: 36 }}>{t("accomplishment:location")}</View>
              <View style={{ width: "74%" }}>
                {auxiliaryMethods.fromatBaseLocationSecondary(details)}
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
              <View style={{ paddingRight: 59 }}>{t("accomplishment:date")}</View>
              <View>{dataHelper.formatDate(details?.created_at)}</View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
              <View style={{ paddingRight: 50 }}>{t("accomplishment:status")}</View>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={
                    details?.status === "APPROVED"
                      ? [styles.status, { color: colorPallete.cordioGreen }]
                      : details?.status === "REVIEW IN PROGRESS"
                      ? [styles.status, { color: colorPallete.yellowOne }]
                      : details?.status === "REJECTED"
                      ? [styles.status, { color: colorPallete.cordioRed }]
                      : [styles.status, { color: colorPallete.cordioBlue1 }]
                  }
                >
                  {details?.status}
                </View>
                <View style={{ paddingLeft: 10 }}>
                  (
                  {details?.status === "APPROVED"
                    ? dataHelper.formatDate(details?.approved_date)
                    : details?.status === "SUBMITTED"
                    ? dataHelper.formatDate(details?.submitted_date)
                    : details?.status === "REJECTED"
                    ? dataHelper.formatDate(details?.updated_at)
                    : dataHelper.formatDate(details?.updated_at)}
                  )
                </View>
              </View>
            </View>
          </View>
          <View style={{ marginBottom: 14 }}>
            <View style={styles.attachmentHeader}>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.headerText, { marginBottom: 10 }]}
              >
                {t("accomplishment:attchment")}
              </Text>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.cardHeader, { paddingLeft: 10 }]}
              >
                ({details?.docs.length} {t("accomplishment:documents")})
              </Text>

              <View
                style={
                  showArtefactsAttachment[0]
                    ? styles.alignLeftContainer
                    : [styles.alignLeftContainer, styles.mr12]
                }
              >
                <TouchableOpacity onPress={() => handleAttchmentOpen(0)}>
                  <Icon
                    name={IconNames.down}
                    size={10}
                    customStyle={
                      showArtefactsAttachment[0] ? styles.cardIconInverted : styles.cardIcon
                    }
                  />
                </TouchableOpacity>
              </View>
            </View>
            {showArtefactsAttachment[0] && (
              <View style={styles.artefactContainer2}>
                {details?.docs.length > 0 &&
                  details?.docs.map((i: any, key: number, index: number) => (
                    <>
                      <View
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <View style={{ width: "65%" }}>
                          <TouchableOpacity
                            onPress={() => {
                              handleView(
                                i?.mimetype,
                                getFileName(i?.artefact_url),
                                getFilePath(i?.filename),
                                "view"
                              );
                            }}
                          >
                            <Text
                              testId="qualificationPack"
                              fontWeight={FontWeight.Medium}
                              textSize={TextSize.Small}
                              style={[styles.contentname]}
                            >
                              {i.display_filename}
                            </Text>
                          </TouchableOpacity>
                          <Text
                            testId="qualificationPack"
                            fontWeight={FontWeight.Regular}
                            textSize={TextSize.Small}
                            style={[styles.contentSize]}
                          >
                            112.0 KB
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "28%"
                          }}
                        >
                          <Icon
                            name={IconNames.shareSecondary}
                            size={16}
                            customStyle={styles.share}
                          />
                          <Icon
                            name={IconNames.downloadContent}
                            size={20}
                            customStyle={styles.download}
                            onPress={() =>
                              handleDownload(
                                i?.mimetype,
                                getFileName(i?.artefact_url),
                                getFilePath(i?.filename),
                                "download"
                              )
                            }
                          />
                        </View>
                      </View>
                      <View
                        style={
                          key === details?.docs.length - 1 ? { display: "none" } : styles.divider
                        }
                      ></View>
                    </>
                  ))}
              </View>
            )}
          </View>
          <View style={{ marginBottom: 14 }}>
            <View style={styles.attachmentHeader}>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.headerText, { marginBottom: 10 }]}
              >
                {t("accomplishment:submitters")}
              </Text>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.cardHeader, { paddingLeft: 10 }]}
              >
                ({details?.submitters.length} {t("accomplishment:members")})
              </Text>

              <View
                style={
                  showArtefactsSubmitters[0]
                    ? styles.alignLeftContainer
                    : [styles.alignLeftContainer, styles.mr12]
                }
              >
                <TouchableOpacity onPress={() => handleSubmittersOpen(0)}>
                  <Icon
                    name={IconNames.down}
                    size={10}
                    customStyle={
                      showArtefactsSubmitters[0] ? styles.cardIconInverted : styles.cardIcon
                    }
                  />
                </TouchableOpacity>
              </View>
            </View>
            {showArtefactsSubmitters[0] && (
              <View style={styles.artefactContainer3}>
                {details?.submitters.length > 0 &&
                  details?.submitters.map((i: any, key: number) => (
                    <>
                      <View
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <View style={{ width: "65%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <Image
                                testId="roleName"
                                imageStyle={styles.image}
                                source={
                                  i?.profilepicurl ||
                                  require("@socion-cordio/common/src/assets/images/user_circle.svg")
                                }
                              />
                            </View>
                            <View>
                              <Text
                                testId="qualificationPack"
                                fontWeight={FontWeight.Medium}
                                textSize={TextSize.Small}
                                style={[styles.contentname]}
                              >
                                {i.name}
                              </Text>
                              <Text
                                testId="qualificationPack"
                                fontWeight={FontWeight.Regular}
                                textSize={TextSize.Small}
                                style={[styles.contentSize]}
                              >
                                {/* {i.role} */}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View
                        style={
                          key === details?.submitters.length - 1
                            ? { display: "none" }
                            : styles.divider
                        }
                      ></View>
                    </>
                  ))}
              </View>
            )}
          </View>
          <View>
            <View style={styles.attachmentHeader}>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.headerText, { marginBottom: 10 }]}
              >
                {t("accomplishment:history")}
              </Text>

              <View
                style={
                  showArtefactsHistory[0]
                    ? styles.alignLeftContainer
                    : [styles.alignLeftContainer, styles.mr12]
                }
              >
                <TouchableOpacity onPress={() => handleHistoryOpen(0)}>
                  <Icon
                    name={IconNames.down}
                    size={10}
                    customStyle={
                      showArtefactsHistory[0] ? styles.cardIconInverted : styles.cardIcon
                    }
                  />
                </TouchableOpacity>
              </View>
            </View>
            {showArtefactsHistory[0] && (
              <View style={styles.artefactContainer4}>
                {historyData?.data.length > 0 &&
                  historyData?.data.map((i: any, key: number) => (
                    <>
                      <View
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        {i.previous_status === "" ? (
                          <View style={{ width: "65%" }}>
                            <Text
                              testId="qualificationPack"
                              fontWeight={FontWeight.Medium}
                              textSize={TextSize.Small}
                              style={[styles.contentname]}
                            >
                              {t("accomplishment:StatusGotChangedTo", {
                                current_status: i.current_status,
                                actedby: i.actedby
                              })}
                            </Text>
                          </View>
                        ) : (
                          <View style={{ width: "65%" }}>
                            <Text
                              testId="qualificationPack"
                              fontWeight={FontWeight.Medium}
                              textSize={TextSize.Small}
                              style={[styles.contentname]}
                            >
                              {t("accomplishment:StatusChangedFrom", {
                                previous_status: i.previous_status,
                                current_status: i.current_status,
                                actedby: i.actedby
                              })}
                            </Text>
                          </View>
                        )}
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "28%"
                          }}
                        >
                          <Text
                            testId="qualificationPack"
                            fontWeight={FontWeight.Regular}
                            textSize={TextSize.Small}
                            style={[styles.historyDate]}
                          >
                            {dataHelper.formatDate(i.actedon)}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={
                          key === historyData?.data.length - 1
                            ? { display: "none" }
                            : styles.divider
                        }
                      ></View>
                    </>
                  ))}
              </View>
            )}
          </View>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: colorPallete.cordioBeige,
    paddingHorizontal: 16,
    paddingBottom: 50,
    minHeight: "100vh"
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20
  },
  ml20: {
    marginLeft: 20
  },
  mt2: {
    marginTop: 2
  },
  headerText: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 24,
    color: colorPallete.cordioTaupe
  },
  leftarrow: {
    color: colorPallete.cordioTaupe,
    width: 6,
    height: 12
  },
  artefactContainer1: {
    backgroundColor: colorPallete.white,
    height: 120,
    marginTop: 20,
    padding: 14,
    marginBottom: 14
  },
  artefactContainer2: {
    backgroundColor: colorPallete.white,
    // height: "100%",
    padding: 14,
    borderRadius: 10
    // marginTop: 14
  },
  artefactContainer3: {
    backgroundColor: colorPallete.white,
    padding: 14,
    borderRadius: 10
    // height: "100%"
    // marginTop: 14
  },
  artefactContainer4: {
    backgroundColor: colorPallete.white,
    padding: 14,
    borderRadius: 10
    // height: "100%"
    // marginTop: 14
  },
  container: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 25
  },
  cardHeader: {
    fontFamily: FontFamily.Medium,
    fontSize: 14,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
    // marginBottom:9
  },
  contentname: {
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    lineHeight: 22,
    color: colorPallete.textBlack
  },
  contentSize: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  historyDate: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 22,
    color: colorPallete.cordioRed,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  alignLeftContainer: {
    marginLeft: "auto",
    paddingTop: 8
  },
  mr12: {
    marginRight: 12
  },
  cardIcon: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6
  },
  share: {
    color: colorPallete.cordioTaupe
    // height: 12,
    // width: 6
  },
  download: {
    color: colorPallete.cordioTaupe
    // height: 12,
    // width: 6
  },
  cardIconInverted: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6,
    transform: [{ rotate: "180deg" }]
  },
  attachmentHeader: {
    flexDirection: "row"
    // marginTop: 14
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  divider: {
    border: "1px solid #675850",
    opacity: 0.1,
    marginTop: 10,
    marginBottom: 10
  },
  status: {
    fontFamily: FontFamily.Regular
    // fontSize: 12,
    // lineHeight: 14
    // color: colorPallete.cordioRed
  }
});
