import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import {
  BADGE_SETTING_TYPES,
  BADGE_TYPE_STATUS,
  HTTP_STATUS_CODES
} from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import {
  RadioButton,
  RadioButtonType
} from "@socion-cordio/common/src/components/atoms/radioButton";
import { BadgesRepository } from "@socion-cordio/common/src/repositories/badges/badges";
import { useDispatch, useSelector } from "react-redux";
import { BadgesActions } from "@socion-cordio/common/src/modules/badges/actions/actions";

interface Props {
  onClose: Function;
  programDetails: any;
  isEditClicked?: boolean;
  selectedBadgeType?: any;
}

export default function AddBadgeTypes(props: Props): ReactElement {
  const { onClose, programDetails, isEditClicked, selectedBadgeType } = props;
  const { program } = programDetails;

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [badgeType, setBadgeType] = useState("");
  const [badgeTypeDescription, setBadgeTypeDescription] = useState("");
  const [assignedDeclaredSetting, setAssignedDeclaredSetting] = useState({
    assigned: true,
    selfDeclared: false
  });
  const [timeOrOpenSetting, setTimeOrOpenSetting] = useState({
    timeBound: true,
    openEnded: false
  });
  const [multipleOrSingleSetting, setMultipleOrSingleSetting] = useState({
    multipleValues: false,
    singleValues: true
  });
  // const [mandatoryOrOptionalSetting, setMandatoryOrOptionalSetting] = useState({
  //   mandatory: false,
  //   optional: true
  // });
  const [badgeTypeError, setBadgeTypeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [displaySelfDeclaredToggle, setDisplaySelfDeclaredToggle] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    checkIsEditClicked();
  }, []);

  useEffect(() => {
    setDisplaySelfDeclaredToggle(program?.paSelfDeclaredBadgeEnabled);
  }, [programDetails]);

  const checkIsEditClicked = () => {
    if (isEditClicked) {
      setBadgeType(selectedBadgeType.badgeClassName);
      setBadgeTypeDescription(selectedBadgeType.description);
      setAssignedDeclaredSetting({
        assigned:
          selectedBadgeType.declaredType === BADGE_SETTING_TYPES.ASSIGNED ||
          selectedBadgeType.declaredType === BADGE_SETTING_TYPES.ASSIGN
            ? true
            : false,
        selfDeclared:
          selectedBadgeType.declaredType === BADGE_SETTING_TYPES.SELF_DECLARED ? true : false
      });

      setTimeOrOpenSetting({
        timeBound: selectedBadgeType.validity,
        openEnded: !selectedBadgeType.validity
      });

      setMultipleOrSingleSetting({
        multipleValues:
          selectedBadgeType.valueType === BADGE_SETTING_TYPES.MULTIPLE_VALUES ||
          selectedBadgeType.valueType === BADGE_SETTING_TYPES.MULTIPLE
            ? true
            : false,
        singleValues:
          selectedBadgeType.valueType === BADGE_SETTING_TYPES.SINGLE_VALUES ||
          selectedBadgeType.valueType === BADGE_SETTING_TYPES.SINGLE
            ? true
            : false
      });

      // setMandatoryOrOptionalSetting({
      //   mandatory: selectedBadgeType.mandatory,
      //   optional: !selectedBadgeType.mandatory
      // });
    }
  };

  const submitBadgeTypeHandler = () => {
    if (badgeType === "" && badgeTypeDescription === "") {
      setBadgeTypeError(true);
      setDescriptionError(true);
      return;
    }
    if (badgeType === "") {
      setBadgeTypeError(true);
      return;
    }
    if (badgeTypeDescription === "") {
      setDescriptionError(true);
      return;
    }
    let payload = {
      programId: program.id,
      badgeClassName: badgeType?.trim(),
      description: badgeTypeDescription?.trim(),
      declaredType: assignedDeclaredSetting.assigned
        ? BADGE_SETTING_TYPES.ASSIGNED
        : BADGE_SETTING_TYPES.SELF_DECLARED,
      valueType: multipleOrSingleSetting.multipleValues
        ? BADGE_SETTING_TYPES.MULTIPLE_VALUES
        : BADGE_SETTING_TYPES.SINGLE_VALUES,
      validity: timeOrOpenSetting.timeBound ? true : false,
      // mandatory: mandatoryOrOptionalSetting.mandatory ? true : false,
      mandatory: false
    };
    isEditClicked ? updateBadgeTypeApiCallHandler(payload) : submitBadgeTypeApiCallHandler(payload);
  };

  const submitBadgeTypeApiCallHandler = (payload: Object) => {
    setLoading(true);
    const response = BadgesRepository.addBadgeType(payload);
    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setLoading(false);
          dispatch(BadgesActions.getBadgeTypes(program.id));
          onClose();
          toast.success("Badge Type added successfully");
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const updateBadgeTypeApiCallHandler = (payload: Object) => {
    setLoading(true);
    const response = BadgesRepository.updateBadgeType(
      // { ...payload, badgeClassId: selectedBadgeType.badgeClassId },
      payload,
      selectedBadgeType.badgeClassId
    );
    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setLoading(false);
          dispatch(BadgesActions.getBadgeTypes(program.id));
          onClose();
          toast.success("Badge Type details updated successfully");
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const renderRadioButton = (
    textValue: string,
    onPress: any,
    isSelected: boolean,
    isTouchableDisabled?: boolean
  ) => (
    <View style={styles.radionButtonContainer}>
      <RadioButton
        testId=""
        selected={isSelected}
        type={RadioButtonType.Round}
        text={textValue}
        textSize={TextSize.Small}
        textFontWeight={FontWeight.Regular}
        textStyle={isSelected ? styles.checkboxTextBold : styles.checkboxText}
        onPress={onPress}
        iconSize={20}
        renderOnlyRadioButtonClickToucable={false}
        isTouchableDisabled={isTouchableDisabled}
      />
    </View>
  );

  const showTextError = (errorMessage: string) => (
    <Text
      fontWeight={FontWeight.Regular}
      testId="errorText"
      textSize={TextSize.Small}
      textStyle={styles.errorText}
    >
      {errorMessage}
    </Text>
  );

  return (
    <View>
      <ToastContainer />
      {loading ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {isEditClicked ? "Edit Badge Type" : "Add Badge Type"}
            </Text>
            <View>
              <Icon
                testID="close"
                name={IconNames.crossCircle}
                customStyle={styles.crossCircle}
                onPress={() => onClose()}
              />
            </View>
          </View>
          <View style={styles.subContainer}>
            <View style={{ height: 65 }}>
              <UserInputSecondary
                textValue="Badge Type"
                handleChange={(value: string) => {
                  setBadgeType(value);
                  setBadgeTypeError(false);
                }}
                handleBlur={() => {}}
                value={badgeType}
                textInputContainerStyles={{ fontFamily: FontFamily.Regular }}
                placeholder="Enter Badge type"
                name="badgeType"
                id="badgeType"
                userStyle={styles.inputStylingAlignment}
                userStyleText={styles.textHeaderField}
                titleInputContainerStyles={{ marginTop: 0 }}
                noFormik
                editable={selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED ? false : true}
              />
              {badgeTypeError && (
                <View style={{ position: "absolute", top: 41, left: 143 }}>
                  {showTextError("Please enter Badge Type")}
                </View>
              )}
            </View>
            <View style={{ height: 90 }}>
              <UserInputTextArea
                textValue="Description"
                label="Description"
                placeholder="Write a description"
                name="description"
                id="description"
                handleChange={(value: string) => {
                  setBadgeTypeDescription(value);
                  setDescriptionError(false);
                }}
                handleBlur={() => {}}
                value={badgeTypeDescription}
                numberOfLines={3}
                multiline={true}
                userStyle={styles.inputStylingAlignment}
                userStyleText={styles.textHeaderField}
                textFieldContianerAlign={{ marginLeft: 0 }}
                titleInputContainerStyles={{ marginTop: 0 }}
                noFormik
              />
              {descriptionError && (
                <View style={{ position: "absolute", top: 71, left: 143 }}>
                  {showTextError("Please enter Badge description")}
                </View>
              )}
            </View>
            <View style={styles.settingsContainer}>
              <View style={styles.settingsHeader}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="internal"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText2]}
                >
                  {"Settings"}
                </Text>
              </View>
              <View style={styles.checkBoxContainer}>
                {/* Assigned or Self declared */}
                {displaySelfDeclaredToggle && (
                  <View style={styles.checkBoxRow}>
                    <View style={styles.radioButtonContainer}>
                      {renderRadioButton(
                        BADGE_SETTING_TYPES.ASSIGNED,
                        () => {
                          setAssignedDeclaredSetting({
                            assigned: true,
                            selfDeclared: false
                          });

                          // make optional field as true
                          // setMandatoryOrOptionalSetting({
                          //   mandatory: false,
                          //   optional: true
                          // });
                        },
                        assignedDeclaredSetting.assigned,
                        selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED ? true : false
                      )}
                    </View>

                    <View>
                      {renderRadioButton(
                        // BADGE_SETTING_TYPES.SELF_DECLARED,
                        "Self-Declared",
                        () => {
                          setAssignedDeclaredSetting({
                            assigned: false,
                            selfDeclared: true
                          });

                          // make open ended true
                          setTimeOrOpenSetting({
                            timeBound: false,
                            openEnded: true
                          });
                        },
                        assignedDeclaredSetting.selfDeclared,
                        selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED ? true : false
                      )}
                    </View>
                  </View>
                )}

                {/* Time Bound or Open ended */}
                <View style={styles.checkBoxRow}>
                  <View style={styles.radioButtonContainer}>
                    {renderRadioButton(
                      BADGE_SETTING_TYPES.TIME_BOUND,
                      () => {
                        setTimeOrOpenSetting({
                          timeBound: true,
                          openEnded: false
                        });
                      },
                      timeOrOpenSetting.timeBound,
                      selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED
                        ? true
                        : assignedDeclaredSetting.selfDeclared
                        ? true
                        : false
                    )}
                  </View>
                  <View>
                    {renderRadioButton(
                      BADGE_SETTING_TYPES.OPEN_ENDED,
                      () => {
                        setTimeOrOpenSetting({
                          timeBound: false,
                          openEnded: true
                        });
                      },
                      timeOrOpenSetting.openEnded,
                      selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED
                        ? true
                        : assignedDeclaredSetting.selfDeclared
                        ? true
                        : false
                    )}
                  </View>
                </View>

                {/* Mulitple or single values */}
                <View style={styles.checkBoxRow}>
                  <View style={styles.radioButtonContainer}>
                    {renderRadioButton(
                      BADGE_SETTING_TYPES.SINGLE_VALUES,
                      () => {
                        setMultipleOrSingleSetting({
                          multipleValues: false,
                          singleValues: true
                        });
                      },
                      multipleOrSingleSetting.singleValues,
                      selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED ? true : false
                    )}
                  </View>
                  <View>
                    {renderRadioButton(
                      BADGE_SETTING_TYPES.MULTIPLE_VALUES,
                      () => {
                        setMultipleOrSingleSetting({
                          multipleValues: true,
                          singleValues: false
                        });
                      },
                      multipleOrSingleSetting.multipleValues,
                      selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED ? true : false
                    )}
                  </View>
                </View>

                {/* Mandatory or Optional  */}
                {/* <View style={styles.checkBoxRow}>
                  <View style={styles.radioButtonContainer}>
                    {renderRadioButton(
                      BADGE_SETTING_TYPES.OPTIONAL,
                      () => {
                        setMandatoryOrOptionalSetting({
                          mandatory: false,
                          optional: true
                        });
                      },
                      mandatoryOrOptionalSetting.optional,
                      selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED
                        ? true
                        : assignedDeclaredSetting.assigned
                    )}
                  </View>
                  <View>
                    {renderRadioButton(
                      BADGE_SETTING_TYPES.MANDATORY,
                      () => {
                        setMandatoryOrOptionalSetting({
                          mandatory: true,
                          optional: false
                        });
                      },
                      mandatoryOrOptionalSetting.mandatory,
                      selectedBadgeType?.status === BADGE_TYPE_STATUS.PUBLISHED
                        ? true
                        : assignedDeclaredSetting.assigned
                    )}
                  </View>
                </View> */}
              </View>
            </View>
          </View>
          <View style={styles.submitButtonContainer}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.submitbutton}
              title={isEditClicked ? "Save" : "Add"}
              onPress={() => submitBadgeTypeHandler()}
              // disabled={!formikProps.dirty}
            />
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 630
  },
  customLoadingContainer: {
    width: 630,
    height: 400
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerText2: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textLight,
    fontSize: 14
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginTop: 15,
    marginBottom: 15
  },
  inputStylingAlignment: {
    width: "100%",
    fontFamily: FontFamily.Regular,
    borderColor: colorPallete.lightGreyThree
  },
  textHeaderField: {
    flex: 23
  },
  errorText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.cordioRed
  },
  settingsContainer: {
    flexDirection: "row"
  },
  settingsHeader: {
    flex: 23
  },
  checkBoxContainer: {
    flex: 80
  },
  checkboxText: {
    paddingLeft: 10,
    fontSize: 14,
    marginVertical: 8,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textLight
  },
  checkboxTextBold: {
    paddingLeft: 10,
    fontSize: 14,
    marginVertical: 8,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textLight,
    fontWeight: "bold"
  },
  checkBoxRow: {
    flexDirection: "row",
    width: 345,
    // marginLeft: 30,
    borderStyle: "solid",
    borderColor: colorPallete.lightGreyThree,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10
  },
  radioButtonContainer: {
    width: 160,
    marginLeft: 18
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 15
  },
  submitbutton: {
    width: 140,
    height: 50,
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  radionButtonContainer: {
    paddingVertical: 2
  }
});
