import React, { useState, useEffect } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { REGISTRY_STATUS } from "@socion-cordio/common/src/network/constants";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import FilePicker from "@socion-cordio/common/src/components/molecules/filePicker";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import { isEmpty } from "lodash";
import { ProgramsRepository } from "@socion-cordio/common/src/repositories/registry/entity/programs";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import { UploadFileHelper } from "@socion-cordio/common/src/utils/registryHelpers/uploadFileHelper";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";

interface Props {
  onClose: Function;
  parent?: any;
  parentType?: string;
  dispatchEvent?: Function;
  programNames: string[];
}

export default function CreateProgramModal(props: Props) {
  const { onClose, parent, parentType, dispatchEvent } = props;
  const [allDocs, setAllDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [programName, setProgramName] = useState(null);
  const [isProgramNameExists, setIsProgramNameExists] = useState(null);

  const formValidaionSchema = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required")
  });

  const handleCreateProgram = async (values: any) => {
    setDisable(true);
    const createEntityProgramRes = await ProgramsRepository.createEntityProgram(values).catch(
      (err) => {
        setDisable(false);
        console.log("Error", err);
        setLoading(false);
        if (err.errmsg && err.errmsg.toLowerCase().includes("program exist with same name")) {
          toast.error(err.errmsg);
        } else {
          toast.error(
            dataHelper.replaceText(MESSAGES.ERROR.CREATE, {
              ["{type}"]: "Entity Program",
              ["{name}"]: values?.name
            })
          );
        }
      }
    );
    console.log("created Program Response", createEntityProgramRes);
    if (!isEmpty(createEntityProgramRes) && allDocs?.length > 0) {
      const programId = createEntityProgramRes[0].osid || createEntityProgramRes;
      const parentId = parentType.toLowerCase() === "entity" ? parent?.osid : "";
      console.log("programIdprogramIdprogramId", createEntityProgramRes);

      const uploadRes = await UploadFileHelper.upload(
        `${parentType.toLowerCase()}/${parentId}/${REGISTRY_TYPE.entity_program}/${programId}`,
        allDocs,
        programId,
        REGISTRY_TYPE.entity_program,
        parentId
      ).catch((err) => {
        console.log("Error while Uploading Docs", err);
        toast.error(
          dataHelper.replaceText(MESSAGES.ERROR.DOC_ADD, {
            ["{type}"]: "Doc"
          })
        );
        closePopup();
      });
      console.log("entityDocumentsentityDocumentsentityDocuments", uploadRes);
      if (uploadRes) {
        toast.success(
          dataHelper.replaceText(MESSAGES.SUCCESS.DOC_ADD, {
            ["{type}"]: "Doc"
          })
        );
        closePopup();
      }
    } else {
      closePopup();
    }
  };

  const closePopup = () => {
    setLoading(false);
    onClose();
    dispatchEvent(parentType);
  };

  const handleCustomChange = async (e: any, setFieldValue: any) => {
    let tempArray: any = [];
    allDocs.map((i) => {
      tempArray.push(i.name);
    });
    if (tempArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["mp4", "mpeg", "mov", "flv", "png", "jpg", "jpeg", "pdf"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    let filesArray = [...allDocs];
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setLoading(true);
      filesArray.push(e?.currentTarget?.files[0]);
      setAllDocs(filesArray);
      setLoading(false);
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const handleDeleteDocument = (index: number) => {
    let tempArray = [...allDocs];
    tempArray.splice(index, 1);
    setAllDocs(tempArray);
  };

  return (
    <>
      {loading ? (
        <Loader customLoadingContainer={styles.loaderContiner} />
      ) : (
        <View style={styles.container}>
          <ToastContainer />
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addEmailText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {"Add Program"}
            </Text>
            <View>
              <Pressable onPress={() => onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>

          <Formik
            initialValues={{
              name: undefined,
              description: undefined,
              startDate: undefined,
              endDate: undefined,
              sdgMapping: undefined,
              logo: undefined,
              status: REGISTRY_STATUS.UNVERIFIED,
              parentId: parentType?.toLowerCase() === REGISTRY_TYPE.entity ? parent?.osid : "",
              parentName: parentType?.toLowerCase() === REGISTRY_TYPE.entity ? parent?.name : "",
              parentType: parentType.toLowerCase(),
              createdBy: UserHelper.getUserName(),
              userId: UserHelper.getUserId()
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleCreateProgram(values);
            }}
            validationSchema={formValidaionSchema}
            enableReinitialize={true}
          >
            {(
              formikProps: FormikProps<{
                name: string;
                description: string;
                startDate: string;
                endDate: string;
                sdgMapping: string;
                logo: string;
                status: string;
                parentId: string;
                parentType: string;
                createdBy: string;
                userId: string;
              }>
            ) => (
              <Form>
                <View>
                  <View style={styles.titleContainer}>
                    <View style={styles.textFieldContianer}>
                      <View style={styles.labelTextContainer}>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="programNameTitleText"
                          textSize={TextSize.Small}
                          textStyle={styles.subheaderText}
                        >
                          Program Name
                        </Text>
                      </View>
                      <View style={styles.widthContainer}>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={(value: any) => {
                              formikProps.handleChange("name");
                              formikProps.setFieldValue("name", value);

                              setProgramName(value);
                              if (value && props.programNames.includes(value.toLowerCase())) {
                                formikProps.setFieldError("name", "Entity with same name exists");
                                setIsProgramNameExists(true);
                              } else {
                                setIsProgramNameExists(false);
                              }

                              console.log(
                                "err.errmsgerr.errmsgerr.errmsg",
                                value,
                                props.programNames,
                                formikProps
                              );
                            }}
                            handleBlur={() => {}}
                            value={formikProps.values.name}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter program name"
                            name="name"
                            id="name"
                          />
                          {programName &&
                            props.programNames.includes(programName.toLowerCase()) && (
                              <Text
                                fontWeight={FontWeight.Regular}
                                testId="qualificationTitleText"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.subheaderText,
                                  { color: "red", marginTop: 2, marginLeft: 10 }
                                ]}
                              >
                                Program name already exists
                              </Text>
                            )}
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={[styles.titleContainer, { minHeight: 100 }]}>
                    <View style={styles.textFieldContianer}>
                      <View style={styles.labelTextContainer}>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="qualificationTitleText"
                          textSize={TextSize.Small}
                          textStyle={styles.subheaderText}
                        >
                          Description
                        </Text>
                      </View>
                      <View style={styles.widthContainer}>
                        <View style={styles.contentContainer}>
                          <TextArea
                            label=""
                            handleChange={formikProps.handleChange("description")}
                            handleBlur={() => {}}
                            value={formikProps.values.description}
                            inputStyle={[styles.textAreaALign, styles.entityContainer]}
                            placeholder="Enter description"
                            name="description"
                            id="description"
                            numberOfLines={4}
                            multiline={true}
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.containerSecondary}>
                    <View style={{}}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelSecondaryContainer}>
                          <View style={[styles.labelTextSecondaryContainer, { marginRight: 10 }]}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="startDateTitleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText, { marginTop: 11 }]}
                            >
                              Start Date
                            </Text>
                          </View>
                          <View style={styles.contentSecondaryContainer}>
                            <DatePicker
                              // min={dataHelper.formatDate(`${new Date()}`, "YYYY-MM-DD")}
                              handleBlur={() => {}}
                              onChange={formikProps.handleChange("startDate")}
                              value={formikProps.values.startDate}
                              name="startDate"
                              id="startDate"
                            />
                          </View>
                        </View>
                      </View>
                    </View>

                    <View style={{}}>
                      <View style={[styles.textFieldContianer]}>
                        <View style={styles.labelSecondaryContainer}>
                          <View style={[styles.labelTextSecondaryContainer, { marginStart: 10 }]}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="endDateTitleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText, { marginTop: 11 }]}
                            >
                              End Date
                            </Text>
                          </View>
                          <View style={[styles.contentSecondaryContainer]}>
                            <DatePicker
                              min={formikProps.values.startDate}
                              max="2050-12-31"
                              handleBlur={() => {}}
                              onChange={formikProps.handleChange("endDate")}
                              value={formikProps.values.endDate}
                              name="endDate"
                              id="endDate"
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <FilePicker
                    contentText={"Uploading supporting documents"}
                    onChange={async (e) => {
                      formikProps.handleChange(e);
                      await handleCustomChange(e, formikProps.setFieldValue);
                    }}
                  />

                  <View style={styles.docsContainer}>
                    <ScrollContainer height="100px" scrollable={allDocs?.length > 1 ? true : false}>
                      {allDocs.map((data, index: number) => (
                        <View style={styles.qualificationContainer} key={index}>
                          <View style={styles.docNameContainer}>
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="qualificationTitleText"
                              textSize={TextSize.Small}
                              textStyle={styles.qualificationTitle}
                            >
                              {data?.name}
                            </Text>
                          </View>
                          <Icon
                            testID="close"
                            name={IconNames.deleteFile}
                            customStyle={styles.uploadDocIcon}
                            onPress={() => handleDeleteDocument(index)}
                          />
                        </View>
                      ))}
                    </ScrollContainer>
                  </View>

                  <View style={styles.buttonContainer}>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Submit"
                      onPress={() => {
                        if (!isProgramNameExists) {
                          formikProps.handleSubmit();
                        }
                      }}
                      disabled={loading || disable}
                    />
                  </View>
                </View>
              </Form>
            )}
          </Formik>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "655px"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 25
  },
  headerText: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },

  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 50,
    minHeight: 70
  },
  containerSecondary: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 45
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  textFieldContianer: {
    flexDirection: "row",
    width: "100%",
    display: "flex"
  },
  labelTextContainer: {
    flex: 18
  },
  contentContainer: {
    flex: 85
  },
  labelSecondaryContainer: {
    flexDirection: "row"
  },
  labelTextSecondaryContainer: {
    // flex: 28
    marginRight: 8
  },
  contentSecondaryContainer: {
    // flex: 58
  },

  inputMobile: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "100%"
  },
  entityContainer: {
    fontFamily: FontFamily.Medium,
    fontWeight: "400",
    padding: 0
  },

  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },

  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.5rem"
  },

  qualificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    height: "80px",
    marginRight: "5px",
    padding: 15,
    marginTop: 20,
    borderColor: "#3FA202",
    borderStyle: "solid",
    borderWidth: 1
  },

  textAreaALign: {
    borderRadius: 5,
    padding: 7,
    paddingTop: 10,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "100%"
  },

  uploadDocIcon: {
    opacity: 0.5
  },
  loaderContiner: {
    width: 655,
    height: 438
  },

  widthContainer: {
    width: "82%"
  },

  docsContainer: {
    marginTop: 10
  },
  docNameContainer: {
    width: 600
  },
  qualificationTitle: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  }
});
