import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { toast } from "react-toastify";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import TableSecondary from "@socion-cordio/web/src/components/molecules/table/tableSecondary";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FilterQualificationPackModal from "@socion-cordio/common/src/components/organisms/qualification-packs/filterQualificationPackModal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import CancelButton from "@socion-cordio/common/src/components/atoms/cancelButton";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import _, { includes } from "lodash";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import Tooltip from "react-tooltip-lite";

interface Props {
  topicsList?: any;
  memberList?: any;
  filteredDataHandler: Function;
  downloadCsvList: Function;
  setSelectionListNumber: Function;
  csvLoader: boolean;
}

export default function QualiParticipantMappingTable(props: Props): ReactElement {
  const { t } = useTranslation();
  const {
    topicsList,
    memberList,
    filteredDataHandler,
    downloadCsvList,
    setSelectionListNumber,
    csvLoader
  } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [selectionList, setSelectionList] = useState([]);
  const [memberListData, setMemberListData] = useState([]);
  const [globalList, setGlobalList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [programDetails, setProgramDetails] = useState(null);
  const [locationList, setLocationList] = useState(undefined);
  const [selectedLocationList, setSelectedLocationList] = useState([]);
  const [badgeRoleList, setBadgeRoleList] = useState(undefined);
  const [selectedBadgeRoleList, setSelectedBadgeRoleList] = useState([]);
  const [locationListButton, setLocationListButton] = useState([]);
  const [badgeRoleListButton, setBadgeRoleListButton] = useState([]);
  const [resultBadgeRole, setResultBadgeRole] = useState([]);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [expandedList, setExpandedList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isLocationFliterApplied, setIsLocationFilterApplied] = useState(false);
  const [isBadgeFliterApplied, setIsBadgeFilterApplied] = useState(false);
  const [noneLocationSelected, setNoneLocationSelected] = useState(false);
  const [noAssignedBadgesSelected, setNoAssignedBadgesSelected] = useState(false);
  const [allExpandedNodes, setAllExpandedNodes] = useState([]);
  const [locationListSecondry, setLocationListSecondry] = useState([]);
  const [checkedListCopy, setCheckedListCopy] = useState([]);
  const [checkedListCopyBadges, setCheckedListCopyBadges] = useState([]);
  // const [selectedBadgeRoleListData, setSelectedBadgeRoleListData] = useState([]);
  // Will have previous data to check for any changes
  const [commonDataCheck, setCommonDataCheck] = useState({
    badgeIdsCheck: [],
    locationsCheck: []
  });
  const [commonData, setCommonData] = useState({
    badgeIds: [],
    locations: []
  });

  const history: any = useHistory();

  useEffect(() => {
    checkDetails();
  }, [topicsList, memberList]);

  useEffect(() => {
    if (viewButtonDetails || openFilter) disableScroll();
    else enableScroll();
  }, [viewButtonDetails, openFilter]);

  const checkDetails = () => {
    if (topicsList.length !== 0) {
      formatSelectionList(topicsList);
      setLoading(false);
    }
    if (topicsList.length === 0 && memberList.length == 0) {
      setLoading(false);
    }
    setMemberListData(memberList);
  };

  useEffect(() => {
    getProgramDetails();
  }, []);

  useEffect(() => {
    noneLocationSelected === true ? setLocationListButton([]) : null;
  }, [noneLocationSelected]);

  useEffect(() => {
    noAssignedBadgesSelected === true ? setBadgeRoleListButton([]) : null;
  }, [noAssignedBadgesSelected]);

  const getProgramDetails = async () => {
    const programDetails = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };

  const formatSelectionList = (list: any[]) => {
    const output: any = Object.values(
      list.reduce((acc, cur) => {
        acc[cur.selectionId] = acc[cur.selectionId] || {
          selectionId: cur.selectionId,
          topics: []
        };
        acc[cur.selectionId].topics.push({ topicName: cur.topicName, topicId: cur.topicId });
        return acc;
      }, {})
    );
    setSelectionListNumber(output);
    mergeLists(output);
    setSelectionList(output);
  };

  const mergeLists = (list: any) => {
    let userList: any = [...memberListData];
    userList.forEach((user: any, index: number) => {
      user.serialNo = `${index + 1}.`;
      if (user.badgeTitles.length > 0) {
        user.assignedBadgeTitles = auxiliaryMethods.getVerifiedQualification(user.badgeTitles);
      }
      user.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(user);
    });
    setTableData(userList);
  };

  const getUserFirstLocationName = (data: any) => {
    let userLocation = "";
    if (data?.city !== "") {
      userLocation = data?.city;
      return userLocation;
    }
    if (data?.subDistrict !== "") {
      userLocation = data?.subDistrict;
      return userLocation;
    }
    if (data?.district !== "") {
      userLocation = data?.district;
      return userLocation;
    }
    if (data?.state !== "") {
      userLocation = data?.state;
      return userLocation;
    }
    if (data?.country !== "") {
      userLocation = data?.country;
      return userLocation;
    }
    return "None";
  };

  const columnAdd: any = (data: any) => {
    if (data.length !== 0) {
      let output: any = [];
      output = [
        ...data.map((item: any, index: number) => {
          let child: any = {
            Header: "Selection " + item.selectionId,
            accessor: "accessor_name" + item.selectionId,
            width: 120,
            maxWidth: 120,
            disableSortBy: true,
            columns: []
          };
          child.columns = [
            ...item.topics.map((subItem: any, subIndex: number) => {
              return {
                Header: subItem.topicName,
                accessor: "accessor_name" + item.selectionId + subIndex,
                width: subItem.topicName.length >= 8 ? 120 : 100,
                maxWidth: subItem.topicName.length >= 8 ? 120 : 100,
                Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => (
                  <View>
                    {topicsList.length !== 0 && (
                      <View>{checkData(subItem.topicId, qualiPack)}</View>
                    )}
                  </View>
                )
              };
            })
          ];
          return child;
        })
      ];
      return output;
    }
  };

  const checkData = (currTopicId: number, qualiPack: any) => {
    const array1 = qualiPack?.topicData.filter((ele: any) => ele.id === currTopicId);
    if (array1.length !== 0) {
      let value = (
        <View style={styles.mainTickContainer}>
          <View style={[styles.tickContainer, styles.tickContainerColor]}>
            <Icon name={IconNames.tick} customStyle={styles.iconStyleTick} />
          </View>
        </View>
      );
      return value;
    }
  };

  const openFilterHandler = () => {
    setOpenFilter(true);
    if (selectedLocationList.length === 0 && selectedBadgeRoleList.length === 0) {
      getFilterLocations();
      getBadgeRoles();
    } else {
      // Object overwritten, (doubt)
      // checkArraysForChanges(locationList, badgeRoleList);
      const listLoc = [...locationList];
      setLocationList(listLoc);
      const listBadge = [...badgeRoleList];
      setBadgeRoleList(listBadge);
    }
  };

  const checkArraysForChanges = (locationListArray: any, badgeRoleListArray: any) => {
    // It stores the previous values of the data.
    let tempArrayLocation = Array.from(locationListArray);
    let newListLocation = tempArrayLocation?.filter((x: any) => x.isSelected === true);
    let tempArrayBadgeRole = Array.from(badgeRoleListArray);
    let newListBadgeRole = tempArrayBadgeRole?.filter((x: any) => x.isSelected === true);
    setCommonDataCheck({ locationsCheck: newListLocation, badgeIdsCheck: newListBadgeRole });
  };

  const getFilterLocations = async () => {
    try {
      const programId = programDetails.program.id;
      const payload = {
        programIds: [programId],
        locationType: "BASE_LOCATION"
      };
      const response = await ApiClient.post(
        qualificationPackEndPoints.getFilterLocations(programId),
        {}
      );
      const responseData = response?.response?.programData[programId];
      // console.log("responseData::", responseData);
      responseData?.forEach((data: any, index: number) => {
        data.serialNo = `${index + 1}`;
        data.formattedBaseLocation = auxiliaryMethods.fromatBaseLocation(data);
        data.isSelected = false;
        data.bundleCompletedDate = dataHelper.formatDate(data.bundleCompletedDate);
      });
      // console.log("Object_DUMMY:::::::::", Object_DUMMY);
      setLocationList(responseData);
      // filterLocationByGroups(responseData);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      toast.error("Something went wrong.");
      setLocationList([]);
    }
  };

  const getBadgeRoles = async () => {
    try {
      const programId = programDetails.program.id;
      const response = await ApiClient.get(
        qualificationPackEndPoints.getBadgeRoles(),

        { id: programId }
      );
      const responseData = response?.response[programId];
      responseData?.forEach((data: any, index: number) => {
        data.serialNo = `${index + 1}.`;
        // data.isSelected = false;
      });
      setBadgeRoleList(responseData);
    } catch (error) {
      toast.error("Something went wrong.");
      setBadgeRoleList([]);
    }
  };

  const getSelectedLocationList = (list: any, globalList: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    setSelectedLocationList(list);
    let result = locationDataConvert(newList);
    setCommonData({
      ...commonData,
      locations: result
    });
  };

  const locationDataConvert = (newList: any) => {
    return newList.map(({ city, subDistrict, district, state, country }: any) => ({
      city,
      subDistrict,
      district,
      state,
      country
    }));
  };

  const getSelectedRoleBadgeList = (list: any) => {
    let newList = list.filter((x: any) => x.isSelected === true);
    // setBadgeRoleListButton(newList); // added while applied button onClick
    let result = newList.map((a: any) => a.badgeId);
    setCommonData({
      ...commonData,
      badgeIds: result
    });
  };

  const removeLocationList = (data: any) => {
    // find element in the all locations list, locationListSecondry: true list
    const list = locationListSecondry.filter((item: any) =>
      item.formattedBaseLocation.includes(data)
    );
    // mark the removed element it as false
    let checkList = [...checkedList];
    list.forEach((element: any) => {
      element.isSelected = false;
      const index = checkList.indexOf(element.serialNo);
      let data = checkList.splice(index, 1);
    });
    setCheckedList([...checkList]);
    // remove the location from display
    const updatedLocList = locationListButton.filter((item: any) => item !== data);
    setLocationListButton(updatedLocList);
    LocalStorage.setStorage("globalNameList", updatedLocList);
    let resultFinalBadgeRole = [...badgeRoleListButton];
    filteredDataHandler({
      locations: filterListToTrue(locationListSecondry),
      badgeIds: resultFinalBadgeRole.map((a: any) => a.badgeId),
      isNoneLocationSet: noneLocationSelected,
      isNoBadgeAssignSet: noAssignedBadgesSelected,
      locationNameList: updatedLocList,
      badgeList: badgeRoleList
    });
  };

  const removeFilteredListButton = (data: any, value: string) => {
    data.isSelected = !data.isSelected;
    let resultBadge = [];
    let result = [];
    let resultFinalLocation = [...locationListButton];
    let resultFinalBadgeRole = [...badgeRoleListButton];
    let resultFinalBadgeRoleTemp = [];

    if (value === "location") {
      // Updating only when location is removed from the filter list.
      let newList = locationListButton.filter((x: any) => x.isSelected === true);
      setLocationListButton(newList);
      result = locationDataConvert(newList);
      // setResultLocation(result); // previous result
      resultFinalLocation = result;
      checkListHandler(data, checkedList);
      resultFinalBadgeRoleTemp = resultFinalBadgeRole.map((a: any) => a.badgeId);

      filteredDataHandler({
        locations: resultFinalLocation,
        badgeIds: resultFinalBadgeRoleTemp,
        isNoneLocationSet: noneLocationSelected,
        noAssignedBadgesSelected: noAssignedBadgesSelected,
        locationNameList: newList
      });
    }

    if (value === "badgeRole") {
      // Updating only when a badge is removed from the filter list.
      let temp = { ...commonData };
      let newListBadge = badgeRoleListButton.filter((x: any) => x.isSelected === true);
      setBadgeRoleListButton(newListBadge);
      resultBadge = newListBadge.map((a: any) => a.badgeId);
      resultFinalBadgeRole = resultBadge;
      setResultBadgeRole(resultBadge);
      let newArray = temp.badgeIds.filter((i) => i !== data.badgeId);
      setSelectedBadgeRoleList(newArray);
      setCommonData({ ...commonData, badgeIds: newArray });
      filteredDataHandler({
        // locations: resultFinalLocation,
        locations: filterListToTrue(locationListSecondry),
        badgeIds: resultFinalBadgeRole,
        isNoneLocationSet: noneLocationSelected,
        isNoBadgeAssignSet: noAssignedBadgesSelected,
        // new global list names
        locationNameList: locationListButton,
        badgeList: badgeRoleList
      });

      // setLocationListSecondry(globalList);
    }

    if (value === "noLocation") {
      setNoneLocationSelected(false);
      filteredDataHandler({
        locations: resultFinalLocation,
        badgeIds: resultFinalBadgeRole.map((a: any) => a.badgeId),
        isNoneLocationSet: false,
        isNoBadgeAssignSet: noAssignedBadgesSelected,
        locationNameList: [],
        badgeList: badgeRoleList
      });
      LocalStorage.removeStorage("noneLocationListSet");
    }

    if (value === "noAssignedBadge") {
      // setLocationListSecondry(globalList);
      setNoAssignedBadgesSelected(false);
      filteredDataHandler({
        // locations: resultFinalLocation,
        locations: filterListToTrue(locationListSecondry),
        badgeIds: resultFinalBadgeRole,
        isNoneLocationSet: noneLocationSelected,
        isNoBadgeAssignSet: false,
        locationNameList: locationListButton,
        badgeList: []
      });
      LocalStorage.removeStorage("noAssignedBadgesSelected");
    }
  };

  const checkListHandler = (data: any, checkedListItems: any) => {
    const newCheckedList = checkedListItems.filter(
      (item: any) =>
        // item.includes(data.serialNo) ? false : true
        item !== data.serialNo
    );
    setCheckedList(newCheckedList);
  };

  const dataFilteredHandler = async (data: any) => {
    if (data.isFiltered === true) {
      commonData.badgeIds.length === 0 &&
      commonData.locations.length === 0 &&
      data.isNoneLocationSet === null &&
      data.isNoBadgeAssignSet === null
        ? [setIsLocationFilterApplied(false), setIsBadgeFilterApplied(false)]
        : null;

      const locList = [...locationList];
      // let newList = locList.filter((x: any) => x.isSelected === true);
      let newList = filterListToTrue(locList);
      setLocationListSecondry(locList);
      // setLocationListButton(newList);
      setLocationListButton(globalList);

      const badgeList = [...badgeRoleList];
      // let newBadgeList = badgeList.filter((x: any) => x.isSelected === true);
      let newBadgeList = filterListToTrue(badgeList);
      setCheckedListCopyBadges(newBadgeList);

      setNoneLocationSelected(data.isNoneLocationSet === null ? false : true);
      setNoAssignedBadgesSelected(data.isNoBadgeAssignSet === null ? false : true);

      filteredDataHandler({
        ...commonData,
        locations: newList,
        isNoneLocationSet: data.isNoneLocationSet,
        isNoBadgeAssignSet: data.isNoBadgeAssignSet,
        badgeList: badgeRoleList,
        locationNameList: globalList
      });
      setLoading(true);
    }
  };

  const filterListToTrue = (list: any) => {
    return list.filter((x: any) => x.isSelected === true);
  };

  const clearAllFilter = (isModalClosed: boolean = true) => {
    isModalClosed ? setLoading(true) : null;

    const locList = locationList.forEach((element: any) => {
      element.isSelected = false;
    });
    const badgeList = badgeRoleList.forEach((element: any) => {
      element.isSelected = false;
    });

    setLocationList(locList);
    setBadgeRoleList(badgeList);

    // setLoading(true);
    setSelectedLocationList([]);
    setSelectedBadgeRoleList([]);
    setLocationListButton([]);
    setBadgeRoleListButton([]);
    filteredDataHandler({
      locations: [],
      badgeIds: [],
      isNoneLocationSet: null,
      isNoBadgeAssignSet: null
    });
    setExpandedList([]);
    setCheckedList([]);
    setIsLocationFilterApplied(false);
    setIsBadgeFilterApplied(false);
    removeLocationNoneSelectedLocalStorage();

    setCommonData({
      badgeIds: [],
      locations: []
    });

    getFilterLocations();
    getBadgeRoles();

    setCheckedListCopy([]);
    setCheckedListCopyBadges([]);
  };

  const closeModalHandler = () => {
    // setIsLocationFilterApplied(false);
    // setIsBadgeFilterApplied(false);
    // setLocationListButton([]);
    // setBadgeRoleListButton([]);
  };

  useEffect(() => {
    // Clear local storage when one first enters this page.
    removeLocationNoneSelectedLocalStorage();
  }, []);

  const removeLocationNoneSelectedLocalStorage = () => {
    setNoneLocationSelected(false);
    setNoAssignedBadgesSelected(false);
    LocalStorage.removeStorage("noneLocationListSet");
    LocalStorage.removeStorage("noAssignedBadgesSelected");
    LocalStorage.removeStorage("globalNameList");
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const onDownloadPress = (url: any, name: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
      })
      .catch(console.error);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Light}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        style,
        { fontSize: 12, fontFamily: FontFamily.Regular, color: colorPallete.textBlack }
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        sticky: "left",
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, qualiPack?.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 120,
        maxWidth: 120,
        sticky: "left",
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          return (
            <TouchableOpacity onPress={() => displayUserDetails(qualiPack)}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="participantName"
                textSize={TextSize.Large}
                style={{
                  fontSize: 12,
                  fontFamily: FontFamily.Medium,
                  color: colorPallete.textBlack
                }}
              >
                {qualiPack?.name}
              </Text>
            </TouchableOpacity>
          );
        }
      },
      {
        Header: "Location",
        accessor: "formattedBaseLocation",
        width: 100,
        maxWidth: 100,
        sticky: "left",
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          // let location = auxiliaryMethods.fromatBaseLocation(qualiPack);
          // if (
          //   auxiliaryMethods.fromatBaseLocation(qualiPack) === "" ||
          //   auxiliaryMethods.fromatBaseLocation(qualiPack) === null ||
          //   auxiliaryMethods.fromatBaseLocation(qualiPack).includes(null)
          // ) {
          //   location = "None";
          // }

          return (
            <>
              {getUserFirstLocationName(qualiPack) !== "None" ? (
                <Tooltip
                  styles={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inherit",
                    fontFamily: FontFamily.Regular,
                    fontSize: 12,
                    color: colorPallete.textBlack
                  }}
                  tipContentClassName=""
                  distance={10}
                  direction="bottom"
                  background={colorPallete.cordioBeigeLight1}
                  color="black"
                  padding={10}
                  content={
                    <View style={{ width: 250 }}>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.Small}
                        style={{
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }}
                      >
                        {qualiPack?.formattedBaseLocation}
                      </Text>
                    </View>
                  }
                >
                  {getUserFirstLocationName(qualiPack)}
                </Tooltip>
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Badges",
        accessor: "assignedBadgeTitles",
        width: 150,
        maxWidth: 150,
        sticky: "left",
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          let badgeTitles = qualiPack.badgeTitles;
          if (badgeTitles.length === 0) {
            qualiPack.assignedBadgeTitles = "None";
          }
          return cellData("assignedBadges", style, qualiPack?.assignedBadgeTitles);
        }
      },
      {
        Header: "Completed On",
        accessor: "bundleCompletedDate",
        width: 142,
        maxWidth: 142,
        sticky: "left",
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          return (
            <View>
              {qualiPack.bundleCompletedDate === null && <View></View>}
              {qualiPack.bundleCompletedDate && (
                <View style={styles.completedOnontainer}>
                  <Text
                    onPress={() => {}}
                    fontWeight={FontWeight.Regular}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextSupport]}
                  >
                    {dataHelper.formatDate(qualiPack?.bundleCompletedDate)}
                  </Text>
                  {qualiPack?.userQrCodeUrl && qualiPack?.published && (
                    <Icon
                      testID="close"
                      name={IconNames.downloadContent}
                      size={20}
                      onPress={() => onDownloadPress(qualiPack?.userQrCodeUrl, qualiPack?.name)}
                      color={"#675850"}
                      customStyle={{
                        paddingHorizontal: 14,
                        borderRightColor: "#675850"
                      }}
                    />
                  )}
                </View>
              )}
            </View>
          );
        }
      },
      {
        Header: "Selections Completed",
        accessor: "completedSelectionCount",
        width: 110,
        maxWidth: 110,
        sticky: "left",
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          return cellData("completedSelectionCount", style, qualiPack?.completedSelectionCount);
        }
      },
      {
        Header: "Selections",
        accessor: "columnsNew",
        width: 70,
        maxWidth: 70,
        disableSortBy: true,
        columns: columnAdd(selectionList)
      }
    ],
    [selectionList]
  );

  const filterButtonsList = () => (
    <>
      {(isLocationFliterApplied || isBadgeFliterApplied) &&
        (noneLocationSelected ||
          noAssignedBadgesSelected ||
          locationListButton.length !== 0 ||
          badgeRoleListButton.length !== 0) && (
          <View style={styles.filteredButtonsContainer}>
            {isLocationFliterApplied &&
              locationListButton?.map((prop, key) => {
                return (
                  <View key={key}>
                    <CancelButton
                      data={prop}
                      functionOfButtonProp={"location"}
                      functionOfButton={() => removeLocationList(prop)}
                      // textValue={prop.formattedBaseLocation}
                      textValue={prop}
                    />
                  </View>
                );
              })}
            {isBadgeFliterApplied &&
              badgeRoleListButton?.map((prop, key) => {
                return (
                  <View key={key}>
                    <CancelButton
                      data={prop}
                      functionOfButtonProp={"badgeRole"}
                      functionOfButton={() => removeFilteredListButton(prop, "badgeRole")}
                      textValue={prop.name}
                    />
                  </View>
                );
              })}
            {noneLocationSelected && (
              <CancelButton
                data={[]}
                functionOfButtonProp={"noLocation"}
                functionOfButton={() => removeFilteredListButton([], "noLocation")}
                textValue={"No Location"}
              />
            )}
            {noAssignedBadgesSelected && (
              <CancelButton
                data={[]}
                functionOfButtonProp={"noAssignedBadge"}
                functionOfButton={() => removeFilteredListButton([], "noAssignedBadge")}
                textValue={"No Assigned Badges"}
              />
            )}
            {(isLocationFliterApplied || isBadgeFliterApplied) &&
              (noneLocationSelected ||
                noAssignedBadgesSelected ||
                locationListButton.length !== 0 ||
                badgeRoleListButton.length !== 0) && (
                <TouchableOpacity onPress={() => clearAllFilter(true)}>
                  <Text
                    testId="testId"
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.Small}
                    style={[styles.clearAllButtonText]}
                  >
                    {"Clear All"}
                  </Text>
                </TouchableOpacity>
              )}
          </View>
        )}
    </>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View style={styles.noFailedDataContainer}>Failed to load data</View>
      ) : (
        <View>
          {tableData.length === 0 && (
            <View>
              <View style={styles.searchBarContainer}>
                <View pointerEvents={"none"}>
                  <SearchFilterSeondary
                    customWidthStyle={styles.customWidthStyle}
                    editable={false}
                  />
                </View>
                <View style={styles.filterIconContainer}>
                  <TouchableOpacity onPress={openFilterHandler}>
                    <View style={styles.filterContainer}>
                      <Icon name={IconNames.filter} customStyle={styles.iconStyleFilter} />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              <View>
                <View>{filterButtonsList()}</View>
              </View>
            </View>
          )}
          {csvLoader ? (
            <Loader />
          ) : (
            <TableSecondary
              columns={columns}
              data={tableData}
              removeFirstHeaderList={true}
              noData={
                tableData.length == 0
                  ? "No users mapped to the selected Role Badge and/or Location"
                  : ""
              }
              download={
                <TouchableOpacity onPress={() => downloadCsvList()}>
                  <View style={{ flexDirection: "row" }}>
                    <Icon
                      name={IconNames.downloadList}
                      customStyle={styles.iconStyle}
                      onPress={() => downloadCsvList()}
                    />
                    <View>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Bold}
                        textSize={TextSize.Small}
                        style={[styles.textAlignment]}
                      >
                        {t("table:download")}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              }
              filterIcon={
                <TouchableOpacity onPress={openFilterHandler}>
                  <View style={styles.filterContainer}>
                    <Icon name={IconNames.filter} customStyle={styles.iconStyleFilter} />
                  </View>
                </TouchableOpacity>
              }
              filterButtons={<View>{filterButtonsList()}</View>}
            />
          )}
          {/* {openFilter && (
            <FilterQualificationPackModal
              modalVisible={openFilter}
              locationList={locationList}
              badgeRoleList={badgeRoleList}
              programDetails={programDetails}
              dataFilteredHandler={dataFilteredHandler}
              getSelectedLocationList={getSelectedLocationList}
              getSelectedRoleBadgeList={getSelectedRoleBadgeList}
              setModalVisible={() => setOpenFilter(!openFilter)}
              setExpandedList={setExpandedList}
              expandedList={expandedList}
              setCheckedList={setCheckedList}
              checkedList={checkedList}
              clearAllFilter={clearAllFilter}
              setIsLocationFilterApplied={setIsLocationFilterApplied}
              setIsBadgeFilterApplied={setIsBadgeFilterApplied}
              closeModalHandler={closeModalHandler}
              setGlobalList={setGlobalList}
              globalList={globalList}
            />
          )} */}
          {openFilter && (
            <View>
              <SocionModal
                modalVisible={openFilter}
                setModalVisible={() => setOpenFilter(!openFilter)}
                component={
                  <FilterQualificationPackModal
                    modalVisible={openFilter}
                    locationList={locationList}
                    badgeRoleList={badgeRoleList}
                    programDetails={programDetails}
                    dataFilteredHandler={dataFilteredHandler}
                    getSelectedLocationList={getSelectedLocationList}
                    getSelectedRoleBadgeList={getSelectedRoleBadgeList}
                    setModalVisible={() => setOpenFilter(!openFilter)}
                    setExpandedList={setExpandedList}
                    expandedList={expandedList}
                    setCheckedList={setCheckedList}
                    checkedList={checkedList}
                    clearAllFilter={clearAllFilter}
                    setIsLocationFilterApplied={setIsLocationFilterApplied}
                    setIsBadgeFilterApplied={setIsBadgeFilterApplied}
                    closeModalHandler={closeModalHandler}
                    setGlobalList={setGlobalList}
                    globalList={globalList}
                    setAllExpandedNodes={setAllExpandedNodes}
                    allExpandedNodes={allExpandedNodes}
                    locationListButton={locationListButton}
                    setCheckedListCopy={setCheckedListCopy}
                    checkedListCopy={checkedListCopy}
                    setCheckedListCopyBadges={setCheckedListCopyBadges}
                    checkedListCopyBadges={checkedListCopyBadges}
                    selectedBadgeRoleList={selectedBadgeRoleList}
                    setBadgeRoleListButton={setBadgeRoleListButton}
                    setSelectedBadgeRoleList={setSelectedBadgeRoleList}
                    noAssignedBadgesSelected={noAssignedBadgesSelected}
                  />
                }
              />
            </View>
          )}

          {viewButtonDetails && (
            <View>
              <SocionModal
                modalVisible={viewButtonDetails}
                setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                component={
                  <ViewUserDetails
                    onClose={() => {
                      setViewButtonDetails(false);
                    }}
                    selectedUserDetails={selectedUserDetails}
                    piiFilter={true}
                  />
                }
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  addRoleButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  noFailedDataContainer: {
    padding: 25
  },
  mainTickContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16
  },
  tickContainer: {
    width: 18,
    height: 18,
    backgroundColor: colorPallete.textLight,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50
  },
  tickContainerColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  iconStyleTick: {
    fontSize: 8,
    color: colorPallete.white
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    textDecorationLine: "underline"
  },
  filterContainer: {
    width: 50,
    height: 42,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.cordioTaupeLight4,
    justifyContent: "center",
    alignItems: "center"
  },
  iconStyleFilter: {
    color: colorPallete.textBlack,
    fontSize: 14
  },
  // Filter Buttons
  filteredButtonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 18,
    paddingTop: 0
  },
  clearAllButtonText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    textDecorationLine: "underline"
  },

  // Search bar disabled
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 18
  },
  customWidthStyle: {
    width: 400
  },
  filterIconContainer: {
    marginLeft: 10
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium
  },
  headerTextSupport: {
    marginLeft: 10
  },
  completedOnontainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }
});
const Object_DUMMY = [
  {
    country: "Zambia",
    district: "Lusaka",
    state: "Lusaka Province",
    city: "Lusaka",
    subDistrict: "",
    count: 8
  },
  {
    country: "India",
    district: "Bengaluru Urban District",
    state: "Karnataka",
    city: "Bengaluru",
    subDistrict: "Bengaluru North",
    count: 46
  },
  {
    country: "India",
    district: "East Khasi Hills District",
    state: "Meghalaya",
    city: "Shillong",
    subDistrict: "Mylliem",
    count: 3
  },
  {
    country: "India",
    district: "East Jaintia Hills District",
    state: "Meghalaya",
    city: "Lum Puthoi",
    subDistrict: "Khliehriat",
    count: 26
  },
  {
    country: "India",
    district: "Gautambuddha Nagar District",
    state: "Uttar Pradesh",
    city: "Noida",
    subDistrict: "Dadri",
    count: 5
  },
  {
    country: "India",
    district: "Mumbai District",
    state: "Maharashtra",
    city: "Mumbai",
    subDistrict: "Mumbai",
    count: 7
  },
  {
    country: "India",
    district: "Kanchipuram District",
    state: "Tamil Nadu",
    city: "Chennai",
    subDistrict: "Sholinganallur",
    count: 14
  },
  {
    country: "India",
    district: "Palakkad District",
    state: "Kerala",
    city: "Muthalamada 1",
    subDistrict: "Chittur",
    count: 89
  },
  {
    country: "India",
    district: "Ghaziabad District",
    state: "Uttar Pradesh",
    city: "Ghaziabad",
    subDistrict: "Ghaziabad",
    count: 61
  },
  {
    country: "India",
    district: "East Khasi Hills District",
    state: "Meghalaya",
    city: "Shillong",
    subDistrict: "Mawlai",
    count: 41
  },
  {
    country: "India",
    district: "Bengaluru Urban District",
    state: "Karnataka",
    city: "Bengaluru",
    subDistrict: "Bengaluru East",
    count: 3
  },
  {
    country: "India",
    district: "Chittoor District",
    state: "Andhra Pradesh",
    city: "Tirupati",
    subDistrict: "Tirupati Urban",
    count: 3
  },
  {
    country: "India",
    district: "Khordha District",
    state: "Odisha",
    city: "Bhubaneswar",
    subDistrict: "Bhubaneswar M Corp",
    count: 8
  },
  {
    country: "India",
    district: "Anand District",
    state: "Gujarat",
    city: "Anand",
    subDistrict: "Anand",
    count: 2
  },
  {
    country: "India",
    district: "Chitradurga District",
    state: "Karnataka",
    city: "",
    subDistrict: "Challakere",
    count: 13
  },
  {
    country: "India",
    district: "Azamgarh District",
    state: "Uttar Pradesh",
    city: "Begpur Aima",
    subDistrict: "Nizamabad",
    count: 1
  },
  {
    country: "Tanzania",
    district: "Kinondoni",
    state: "Dar es Salam",
    city: "Dar es Salaam",
    subDistrict: "",
    count: 1
  },
  {
    country: "India",
    district: "Virudhunagar District",
    state: "Tamil Nadu",
    city: "Aruppukkottai",
    subDistrict: "Aruppukkottai",
    count: 3
  },
  {
    country: "India",
    district: "Balangir District",
    state: "Odisha",
    city: "Kantabanji",
    subDistrict: "Kantabanji",
    count: 3
  },
  {
    country: "India",
    district: "Bengaluru Urban District",
    state: "Karnataka",
    city: "Bengaluru",
    subDistrict: "Yelahanka",
    count: 15
  },
  {
    country: "India",
    district: "Lucknow District",
    state: "Uttar Pradesh",
    city: "Lucknow",
    subDistrict: "Sadar",
    count: 95
  },
  {
    country: "India",
    district: "Junagadh District",
    state: "Gujarat",
    city: "Sasan",
    subDistrict: "Mendarda",
    count: 168
  },
  {
    country: "India",
    district: "Azamgarh District",
    state: "Uttar Pradesh",
    city: "Begpur Khalsa",
    subDistrict: "Nizamabad",
    count: 1
  },
  {
    country: "India",
    district: "Bengaluru Urban District",
    state: "Karnataka",
    city: "Bengaluru",
    subDistrict: "Bengaluru South",
    count: 496
  },
  {
    country: "Uganda",
    district: "Kampala",
    state: "Central Region",
    city: "Kampala",
    subDistrict: "",
    count: 6
  },
  {
    country: "India",
    district: "Bangalore Urban District",
    state: "Karnataka",
    city: "Bengaluru",
    subDistrict: "Bengaluru North",
    count: 10
  },
  {
    country: "India",
    district: "Nayagarh District",
    state: "Odisha",
    city: "Nayagarh",
    subDistrict: "Nayagarh",
    count: 3
  }
];
