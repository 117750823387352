import { ActionHelper } from "@socion-cordio/common/src/utils/actionHelper";

const prefix = "entities";

const ENTITIES = {
  GET_ENTITIES: ActionHelper.actionTypesCreator(prefix, "GET_ENTITIES"),
  GET_ALL_ENTITIES: ActionHelper.actionTypesCreator(prefix, "GET_ALL_ENTITIES"),
  GET_ENTITY_DOCS: ActionHelper.actionTypesCreator(prefix, "GET_ENTITY_DOCS"),
  GET_ENTITY: ActionHelper.actionTypesCreator(prefix, "GET_ENTITY"),
  GET_ENTITY_ADMINS: ActionHelper.actionTypesCreator(prefix, "GET_ENTITY_ADMINS"),
  GET_ENTITY_PROGRAMS: ActionHelper.actionTypesCreator(prefix, "GET_ENTITY_PROGRAMS"),
  GET_ENTITY_PROGRAM_PARTNERS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_ENTITY_PROGRAM_PARTNERS"
  ),
  GET_ENTITY_PARTNERS: ActionHelper.actionTypesCreator(prefix, "GET_ENTITY_PARTNERS")
};

export { ENTITIES };
