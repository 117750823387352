import React, { ReactElement, useState } from "react";
import {
  Modal as RNModal,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle
} from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";

interface Props {
  modalVisible?: boolean;
  setModalVisible?: Function;
  component?: ReactElement;
  customContainer?: StyleProp<ViewStyle>;
}

export default function DisabledLoader(props: Props) {
  return (
    <View style={styles.centeredView}>
      <RNModal animationType="fade" transparent={true}>
        <TouchableOpacity style={styles.centeredView} activeOpacity={1}>
          <Loader />
        </TouchableOpacity>
      </RNModal>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  modalView: {
    margin: 20,
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    padding: 25,
    alignItems: "center",
    shadowColor: colorPallete.black,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  }
});
