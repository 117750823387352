import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import LiveSessionPage from "@socion-cordio/common/src/components/organisms/session/liveSessionPage";
import UpcomingSessionPage from "@socion-cordio/common/src/components/organisms/session/upcomingSessionPage";
import CompletedSessionPage from "@socion-cordio/common/src/components/organisms/session/completedSessionPage";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useTranslation } from "react-i18next";
import Rating from "@socion-cordio/common/src/components/atoms/rating";
import { useLocation, useHistory, Link } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import {
  programEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { toast } from "react-toastify";
import DisabledLoader from "@socion-cordio/common/src/components/atoms/disabledLoader";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useSelector } from "react-redux";
import { StoreProviderService } from "@socion-cordio/common/src/services/StoreProviderService";
import { ProgramActions } from "@socion-cordio/common/src/modules/program/actions/actions";

interface Props {}

export default function SessionPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const location: any = useLocation();
  const history: any = useHistory();
  const [isLiveSession, setIsLiveSession] = useState<boolean>(true);
  const [isUpcomingSession, setIsUpcomingSession] = useState<boolean>(false);
  const [isCompletedSession, setIsCompletedSession] = useState<boolean>(false);
  const [programDetails, setProgramDetails] = useState(null);
  const [rating, setRating] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [ratingsEnabled, setRatingsEnabled] = useState(false);
  const [programRatings, setProgramRatings] = useState(null);
  const selectedProgramDetails = useSelector(
    (state: any) => state?.programs?.selectedProgramDetails
  );
  const [isCreateSessionBlocked, setIsCreateSessionBlocked] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const userRoleTrainer = "TRAINER";

  useEffect(() => {
    getProgramDetails();
    getRating();
  }, []);

  useEffect(() => {
    setIsCreateSessionBlocked(selectedProgramDetails?.isCreateSessionBlocked || false);
  }, [selectedProgramDetails]);

  useEffect(() => {
    (async () => {
      await getProgramDetails();
      await processActiveTab();
    })();
  }, [location?.state?.activeTab]);

  const {
    userRolesData: userRoles,
    loading: { getUserRolesProgram: loading }
  } = useSelector((state: any) => state?.userRolesProgram);

  const processActiveTab = () => {
    if (location?.state?.activeTab === "UPCOMING") {
      upcomingSessionButtonSelection();
    } else if (location?.state?.activeTab === "COMPLETED") {
      completedSessionButtonSelection();
    }
  };

  const checkExpiryStatus = async () => {
    setIsLoading(true);
    try {
      const res = await ApiClient.get(
        programEndPoints.getProgramStatusEP(programDetails?.program?.id)
      );

      const data = res?.response;

      setIsLoading(false);
      if (data === null || data?.blockSessionCreation === false) {
        googleWebAnalytics("Session_Live_Create_Session", "Button", "Session");
        history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.CREATENEWSESSION}`);
      } else {
        setIsCreateSessionBlocked(true);
        toast.error("Unable to create session!");
        StoreProviderService.dispatch(
          ProgramActions.getSelectedProgramData({
            ...selectedProgramDetails,
            isCreateSessionBlocked: true
          })
        );
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };
  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    let overallRating =
      programDetails?.program?.ratings === null
        ? "0"
        : programDetails?.program?.ratings / programDetails?.program?.respondents;
    // let totalResponse =
    //   programDetails?.program?.respondents === null ? "0" : programDetails?.program?.respondents;
    setRating(overallRating);
    // setTotalCount(totalResponse);
    setRatingsEnabled(programDetails?.program?.ratingsEnabled);
    setProgramDetails(programDetails);
  };

  const getRating = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const programId = programDetails?.program?.id;
    const getProgramRatingsResponse = await ApiClient.get(
      springshedEndPoints.getProgramRatings(programId)
    );
    let ratings = getProgramRatingsResponse.response.ratings;
    let respondents = getProgramRatingsResponse.response.respondents;
    let totalResponse = respondents === null ? "0" : respondents;
    setTotalCount(totalResponse);
    let value = ratings === null ? 0 : ratings / respondents;
    if (value?.toString()?.indexOf(".") == -1) setProgramRatings(value);
    else setProgramRatings(value?.toFixed(1));
    // let value = rating;
    // if (value?.toString()?.indexOf(".") == -1) return value;
    // else return value?.toFixed(1);
  };

  useEffect(() => {
    validateTabSwitchHandler();
  }, [location]);

  const validateTabSwitchHandler = () => {
    if (location.state !== undefined) {
      if (location.state.locationData.pathname.includes("live")) {
        liveSessionButtonSelection();
      }
      if (location.state.locationData.pathname.includes("upcoming")) {
        upcomingSessionButtonSelection();
      }
      if (location.state.locationData.pathname.includes("completed")) {
        completedSessionButtonSelection();
      }
    }
  };

  const liveSessionButtonSelection = async () => {
    await getProgramDetails();
    setIsLiveSession(true);
    setIsUpcomingSession(false);
    setIsCompletedSession(false);
  };

  const upcomingSessionButtonSelection = async () => {
    await getProgramDetails();
    setIsLiveSession(false);
    setIsUpcomingSession(true);
    setIsCompletedSession(false);
  };

  const completedSessionButtonSelection = async () => {
    await getProgramDetails();
    setIsLiveSession(false);
    setIsUpcomingSession(false);
    setIsCompletedSession(true);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.toggleContainer}>
          <View style={{ flexDirection: "inherit" }}>
            {/* <ButtonGroup/> */}
            <ButtonTab
              type={isLiveSession ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
              buttonStyles={[styles.toggleButton, styles.borderLeftRadius]}
              textStyles={styles.textStyle}
              title={t("session:live")}
              onPress={() => {
                liveSessionButtonSelection();
                googleWebAnalytics("Session_Live_Tab", "Button", "Session");
              }}
            />
            <ButtonTab
              type={isUpcomingSession ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
              buttonStyles={[styles.toggleButton]}
              textStyles={styles.textStyle}
              title={t("session:upcoming")}
              onPress={() => {
                upcomingSessionButtonSelection();
                googleWebAnalytics("Session_Upcoming_Tab", "Button", "Session");
              }}
            />
            <ButtonTab
              type={
                isCompletedSession ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
              }
              buttonStyles={[styles.toggleButton, styles.borderRightRadius]}
              textStyles={styles.textStyle}
              title={t("session:completed")}
              onPress={() => {
                completedSessionButtonSelection();
                googleWebAnalytics("Session_Completed_Tab", "Button", "Session");
              }}
            />
          </View>
          {programDetails?.program?.paRatingsEnabled && (
            <View style={isLiveSession ? styles.ratingMainContainer : styles.ratingMainContainer2}>
              <View style={styles.flexContainer}>
                <Text
                  testId="testId"
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.Small}
                  style={[styles.overallRating]}
                >
                  Overall Rating
                </Text>
                <View style={{ marginLeft: 24 }}>
                  <Text
                    testId="testId"
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.Small}
                    style={[styles.overallRating]}
                  >
                    : {programRatings}/5
                  </Text>
                </View>
                <View style={{ marginLeft: 14, marginTop: -3 }}>
                  <Rating data={programRatings} />
                </View>
                <View style={{ marginLeft: 8 }}>
                  <Text
                    testId="testId"
                    fontWeight={FontWeight.Regular}
                    textSize={TextSize.Small}
                    style={[styles.overallRating]}
                  >
                    {`(${totalCount})`}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {
            <View>
              {userRoles?.response.includes(userRoleTrainer) && isLiveSession && (
                <TouchableOpacity
                  disabled={isCreateSessionBlocked}
                  onPress={() => checkExpiryStatus()}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <Image
                      testId="primaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                      imageStyle={{
                        width: 30,
                        height: 30,
                        marginRight: 10,
                        opacity: isCreateSessionBlocked ? 0.2 : 1
                      }}
                    />
                    <Text
                      fontWeight={FontWeight.Light}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.buttonText,
                        {
                          color: isCreateSessionBlocked ? "#D3D3D3" : colorPallete.cordioRed
                        }
                      ]}
                    >
                      Create Session
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          }
        </View>
        {programDetails && isLiveSession ? (
          <LiveSessionPage programDetails={programDetails} />
        ) : isUpcomingSession ? (
          <UpcomingSessionPage programDetails={programDetails} />
        ) : isCompletedSession ? (
          <CompletedSessionPage programDetails={programDetails} />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    minHeight: "calc(100vh - 100px)"
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    padding: 18,
    paddingBottom: 0,
    display: "flex",
    justifyContent: "space-between"
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  borderLeftRadius: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5
  },
  borderRightRadius: {
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5
  },
  ratingMainContainer: {
    // marginLeft: "auto",
    top: 8
  },
  ratingMainContainer2: {
    // marginLeft: "auto",
    top: 8,
    right: 36
  },
  totalRatings: {
    fontSize: 10,
    fontStyle: "italic",
    fontFamily: FontFamily.Regular,
    color: colorPallete.cordioGray
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  overallRating: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    color: colorPallete.cordioGray
  },
  buttonText: {
    color: colorPallete.cordioRed,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    textDecorationLine: "underline",
    fontFamily: "Montserrat-Bold"
  }
});
