import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { FontFamily, TextSize } from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";

interface Props {
  filterList?: Function;
  editable?: boolean;
  customWidthStyle?: any;
  placeholder?: string;
}

const SearchFilterSeondary = (props: Props) => {
  const { filterList, editable, customWidthStyle, placeholder } = props;
  return (
    <View style={styles.searchBarContainer}>
      <Icon name={IconNames.search} customStyle={styles.iconStyle} />
      <TextField
        label=""
        name="searchBar"
        id="searchBar"
        handleChange={(value: any) => {
          filterList(value);
        }}
        placeholder={`${placeholder ? placeholder : "Search"}`}
        inputStyle={[styles.searchBar, customWidthStyle]}
        noFormik
        handleBlur={() => {}}
        editable={editable}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    // justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight4,
    borderRadius: 5
  },
  searchBar: {
    color: colorPallete.textBlack,
    border: "none",
    height: 40,
    width: 400,
    fontFamily: FontFamily.Medium,
    fontWeight: FontFamily.Bold,
    textSize: TextSize.Small,
    fontSize: 14
  },
  iconStyle: {
    marginLeft: 15,
    marginRight: 5,
    opacity: 0.5,
    fontSize: 16
  }
});

export default SearchFilterSeondary;
