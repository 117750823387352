import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import DeleteAccountOtp from "@socion-cordio/common/src/components/organisms/deleteAccountOtp";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useSelector, useDispatch } from "react-redux";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";

interface Props {
  expandDeleteAccount?: any;
  setExpandPushNotification?: Function;
  setExpandChangePassword?: Function;
  setExpandDeleteAccount?: Function;
}
export default function DeleteAccount(props: Props): ReactElement {
  const {
    expandDeleteAccount,
    setExpandPushNotification,
    setExpandChangePassword,
    setExpandDeleteAccount
  } = props;
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    const userData = await LocalStorage.getStorage("user");
    setProfileData(userData);
  };

  const handleConfirm = (): any => {
    // setIsnfoEnabled((isInfoEnabled) => !isInfoEnabled);
    // hanldlePIConsent(!isInfoEnabled);
    // setConfirmationModal(false);
    setOtpModal(true);
  };

  const cutomClickHandler = () => {
    setExpandPushNotification(false);
    setExpandDeleteAccount(true);
    setExpandChangePassword(false);
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <View>
      <CollapseItem
        headerStyles={
          !isExpanded
            ? [styles.collapseHeaderContainer]
            : [
                styles.collapseHeaderContainer,
                {
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  borderRadius: 0
                }
              ]
        }
        isExpanded={expandDeleteAccount}
        headerComponent={"Delete My Account"}
        accordionStyles={styles.accordionStyles}
        cutomClickHandler={cutomClickHandler}
        children={
          <>
            {loading ? (
              <Loader />
            ) : (
              <View style={{ width: 548, backgroundColor: "rgba(238, 229, 218, 0.5)" }}>
                <View style={{ marginTop: 15 }}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="signup"
                    textSize={TextSize.Regular}
                    style={styles.consentText}
                  >
                    You are about to submit a request for us to permanently delete your PDA account
                    and delete your data.
                  </Text>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="signup"
                    textSize={TextSize.Regular}
                    style={styles.consentText}
                  >
                    Once you delete your account, you will not be able to login to PDA
                    Application(s) and access your attestations anymore.
                  </Text>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="signup"
                    textSize={TextSize.Regular}
                    style={styles.consentText}
                  >
                    Your profile and participation data will be permanently erased from our system.
                  </Text>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="signup"
                    textSize={TextSize.Regular}
                    style={styles.consentTextBold}
                  >
                    You will not be able to Undo this.
                  </Text>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="signup"
                    textSize={TextSize.Regular}
                    style={styles.consentText}
                  >
                    Are you sure you want to Delete your Account?
                  </Text>
                </View>
                <View style={[styles.header, styles.contentConatiner]}>
                  <View style={styles.buttonContainer1}>
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Cancel"
                      onPress={() => setOtpModal(false)}
                    />
                  </View>
                  <View style={styles.buttonContainer2}>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.button}
                      textStyles={styles.buttonFont}
                      title="Confirm"
                      onPress={handleConfirm}
                    />
                  </View>
                </View>
              </View>
            )}{" "}
          </>
        }
      />
      {otpModal && (
        <Modal
          modalVisible={otpModal}
          setModalVisible={() => setOtpModal(!otpModal)}
          component={
            <>
              <View style={styles.modalContainer}>
                <DeleteAccountOtp
                  onClose={() => setOtpModal(false)}
                  modalTitle="Delete My Account"
                  headerText=""
                  showEmail
                  isValidatePhoneEnabled={true}
                  profileData={profileData}
                />
              </View>
            </>
          }
        />
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colorPallete.white,
    padding: 20,
    height: "100vh",
    borderRadius: 20,
    margin: 20
    // minHeight: "calc(100vh - 50px)"
  },
  collapseHeaderContainer: {
    backgroundColor: "rgba(238, 229, 218, 0.5)",
    fontSize: 14,
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Bold,
    fontWeight: "700",
    padding: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // elevation: 4,
    shadowColor: colorPallete.lightGrey,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 1,
    lineHeight: 17
  },
  accordionStyles: {
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowColor: colorPallete.white,
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0
  },
  consentText: {
    flex: 0.8,
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontSize: 14,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 50,
    fontWeight: 400
  },
  consentTextBold: {
    flex: 0.8,
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 50,
    fontWeight: 400
  },
  container: {
    width: 600
  },
  header: {
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  contentConatiner: {
    marginLeft: 20,
    marginBottom: 20
  },
  button: {
    width: "167px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 14
  },
  buttonContainer1: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    marginLeft: 15,
    width: 140,
    height: 50
  },
  buttonContainer2: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    marginLeft: 40,
    width: 140,
    height: 50
  },
  modalContainer: {
    width: 680
  }
});
