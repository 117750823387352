import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { useSelector, useDispatch } from "react-redux";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import { ToastContainer, toast } from "react-toastify";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";

interface Props {
  expandPushNotification?: any;
  setExpandPushNotification?: Function;
  setExpandChangePassword?: Function;
  setExpandDeleteAccount?: Function;
}
export default function PushNotification(props: Props): ReactElement {
  const {
    expandPushNotification,
    setExpandPushNotification,
    setExpandChangePassword,
    setExpandDeleteAccount
  } = props;
  const [loading, setLoading] = useState(false);
  const profileState = useSelector((state: any) => state?.profile?.userProfileData?.response);
  const [status, setStatus] = useState(profileState?.pushNotificationStatus);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setStatus(profileState?.pushNotificationStatus);
  }, [profileState]);

  const handleToggle = () => {
    updateStatus();
  };

  const updateStatus = async () => {
    const response = await ApiClient.put(profileEndPoints.pushNotification(!status));
    if (response.responseCode == HTTP_STATUS_CODES.ok) {
      await dispatch(ProfileActions.getUserProfile());
      toast.success(response.message);
      googleWebAnalytics(
        status ? "Settings_On_Push_Notification" : "Settings_Off_Push_Notification",
        "Button",
        "Settings"
      );
    }
  };

  const cutomClickHandler = () => {
    setExpandPushNotification(true);
    setExpandDeleteAccount(false);
    setExpandChangePassword(false);
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <View>
      <CollapseItem
        headerStyles={
          !isExpanded
            ? [styles.collapseHeaderContainer]
            : [
                styles.collapseHeaderContainer,
                {
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  borderRadius: 0
                }
              ]
        }
        isExpanded={expandPushNotification}
        headerComponent={"Push Notification"}
        accordionStyles={styles.accordionStyles}
        cutomClickHandler={cutomClickHandler}
        children={
          <>
            {loading ? (
              <Loader />
            ) : (
              <View style={{ width: 548, backgroundColor: "rgba(238, 229, 218, 0.5)" }}>
                <View style={styles.consent}>
                  <View style={{ flex: 50 }}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="notification"
                      textSize={TextSize.Regular}
                      textStyle={styles.consentText}
                    >
                      Mobile push notifications
                    </Text>
                  </View>
                  <View style={{ flex: 50 }}>
                    <Switch
                      isOn={status}
                      onColor="#B7504A"
                      offColor="#F1DCDB"
                      labelStyle={{ color: "black", fontWeight: "900" }}
                      size="medium"
                      onToggle={handleToggle}
                    />
                  </View>
                </View>
              </View>
            )}{" "}
          </>
        }
      />
    </View>
  );
}
const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colorPallete.white,
    padding: 20,
    height: "100vh",
    borderRadius: 20,
    margin: 20
    // minHeight: "calc(100vh - 50px)"
  },
  collapseHeaderContainer: {
    backgroundColor: "rgba(238, 229, 218, 0.5)",
    fontSize: 14,
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Bold,
    fontWeight: "700",
    padding: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // elevation: 4,
    shadowColor: colorPallete.lightGrey,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 1,
    lineHeight: 17
  },
  accordionStyles: {
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowColor: colorPallete.white,
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0
  },
  consent: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    margin: "1rem"
  },
  consentText: {
    flex: 0.8,
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontSize: 14,
    fontWeight: 400
  }
});
