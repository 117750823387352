import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Entity } from "@socion-cordio/common/src/models/registry/entity";
import { RegistryApiClient } from "@socion-cordio/common/src/network/registryApiClient";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  registryEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";

class ProgramsRepository {
  getEntityProgramsList = async (reqBody: any): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.entityProgramsList(), reqBody);
    console.log("req bosy from program", reqBody);
    console.log("program list api", data);
    return data?.result || [];
  };
  createEntityProgram = async (reqBody: {}): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.createEntityProgram(), reqBody);
    return data?.result || [];
  };
  toggleEntityProgram = async (
    reqBody: {},
    programId: string,
    isActivate: boolean
  ): Promise<any> => {
    const url = isActivate
      ? registryEndPoints.activateEntityProgram(programId)
      : registryEndPoints.deactivateEntityProgram(programId);
    let data = await RegistryApiClient.patch(url, reqBody);
    return data?.result || [];
  };
  readProgram = async (programId: string): Promise<any> => {
    let data = await RegistryApiClient.get(registryEndPoints.readEntityProgram(programId));
    console.log("readEntityreadEntity", data);
    return data?.result || [];
  };
  updateEntityProgram = async (reqBody: {}, programId: string): Promise<any> => {
    let data = await RegistryApiClient.patch(
      registryEndPoints.updateEntityProgram(programId),
      reqBody
    );
    return data?.result || [];
  };
}
const programsRepository = new ProgramsRepository();
export { programsRepository as ProgramsRepository };
