import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import Participant from "@socion-cordio/common/src/components/organisms/participation-details/participant";
import Trainer from "@socion-cordio/common/src/components/organisms/participation-details/trainer";
import TrainerByTopic from "@socion-cordio/common/src/components/organisms/participation-details/trainerByTopic";
import ParticipantAttestation from "@socion-cordio/common/src/components/organisms/participation-details/participantAttestation";
import TrainerAttestation from "@socion-cordio/common/src/components/organisms/participation-details/trainerAttestation";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useLocation } from "react-router-dom";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {}

export default function ParticipantDetailsPage(props: Props): ReactElement {
  const location: any = useLocation();
  const [isParticipant, setIsParticipant] = useState<boolean>(true);
  const [isTrainer, setIsTrainer] = useState<boolean>(false);
  const [isTrainerByTopic, setIsTrainerByTopic] = useState<boolean>(false);
  const [isParticipantAttestation, setIsParticipantAttestation] = useState<boolean>(false);
  const [isTrainerAttestation, setIsTrainerAttestation] = useState<boolean>(false);
  const [programDetails, setProgramDetails] = useState(null);

  useEffect(() => {
    getProgramDetails();
    processActiveTab();
  }, [location?.state?.activeTab]);

  const getProgramDetails = async () => {
    const programDetails = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };

  const processActiveTab = () => {
    if (location?.state?.activeTab === "Participants") {
      setIsParticipant(true);
      setIsTrainer(false);
      setIsTrainerByTopic(false);
      setIsParticipantAttestation(false);
      setIsTrainerAttestation(false);
    }
    if (location?.state?.activeTab === "Trainers") {
      setIsParticipant(false);
      setIsTrainer(true);
      setIsTrainerByTopic(false);
      setIsParticipantAttestation(false);
      setIsTrainerAttestation(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.toggleContainer}>
          {/* <ButtonGroup/> */}
          <ButtonTab
            type={isParticipant ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[
              styles.toggleButton,
              { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
            ]}
            textStyles={styles.textStyle}
            title="Participant"
            onPress={() => {
              LocalStorage.removeStorage("noneLocationListSet");
              LocalStorage.removeStorage("noAssignedBadgesSelected");
              setIsParticipant(true);
              setIsTrainer(false);
              setIsTrainerByTopic(false);
              setIsParticipantAttestation(false);
              setIsTrainerAttestation(false);
              googleWebAnalytics(
                "Participation_Details_Participation_Tab",
                "Button",
                "Participation_Details"
              );
            }}
          />
          {/* <ButtonTab
            type={
              isParticipantAttestation ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
            }
            buttonStyles={[styles.toggleButton]}
            textStyles={styles.textStyle}
            title="Participant Attestation"
            onPress={() => {
              setIsParticipant(false);
              setIsTrainer(false);
              setIsTrainerByTopic(false);
              setIsParticipantAttestation(true);
              setIsTrainerAttestation(false);
            }}
          /> */}
          <ButtonTab
            type={isTrainer ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[styles.toggleButton]}
            textStyles={styles.textStyle}
            title="Trainer"
            onPress={() => {
              LocalStorage.removeStorage("noneLocationListSet");
              LocalStorage.removeStorage("noAssignedBadgesSelected");
              setIsParticipant(false);
              setIsTrainer(true);
              setIsTrainerByTopic(false);
              setIsParticipantAttestation(false);
              setIsTrainerAttestation(false);
              googleWebAnalytics(
                "Participation_Details_Trainer_Tab",
                "Button",
                "Participation_Details"
              );
            }}
          />
          {/* <ButtonTab
            type={
              isTrainerAttestation ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
            }
            buttonStyles={[styles.toggleButton]}
            textStyles={styles.textStyle}
            title="Trainer Attestation"
            onPress={() => {
              setIsParticipant(false);
              setIsTrainer(false);
              setIsTrainerByTopic(false);
              setIsParticipantAttestation(false);
              setIsTrainerAttestation(true);
            }}
          /> */}
          <ButtonTab
            type={isTrainerByTopic ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[
              styles.toggleButton,
              { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
            ]}
            textStyles={styles.textStyle}
            title="Trainer by topic"
            onPress={() => {
              LocalStorage.removeStorage("noneLocationListSet");
              LocalStorage.removeStorage("noAssignedBadgesSelected");
              setIsParticipant(false);
              setIsTrainer(false);
              setIsTrainerByTopic(true);
              setIsParticipantAttestation(false);
              setIsTrainerAttestation(false);
              googleWebAnalytics(
                "Participation_Details_Trainer_By_Topic",
                "Button",
                "Participation_Details"
              );
            }}
          />
        </View>
        {programDetails && isParticipant ? (
          <Participant programDetails={programDetails} />
        ) : isParticipantAttestation ? (
          <ParticipantAttestation programDetails={programDetails} />
        ) : isTrainer ? (
          <Trainer programDetails={programDetails} />
        ) : isTrainerAttestation ? (
          <TrainerAttestation programDetails={programDetails} />
        ) : isTrainerByTopic ? (
          <TrainerByTopic programDetails={programDetails} />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  }
});
