import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement } from "react";
import { View, StyleSheet, StyleProp, ImageStyle, ViewStyle } from "react-native";
import TextArea from "@socion-cordio/common/src/components/atoms/textArea";

interface IProps {
  icon?: IconNames;
  label?: string;
  textValue?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  id?: string;
  handleChange?: Function;
  handleBlur?: Function;
  handleKeyPress?: Function;
  userStyle?: any;
  userStyleText?: any;
  noFormik?: boolean;
  numberOfLines?: number;
  multiline?: boolean;
  titleInputContainerStyles?: StyleProp<ViewStyle>;
  textFieldContianerAlign?: StyleProp<ViewStyle>;
  formikPropsTouched?: boolean;
  customChange?: Function;
  // isSecure?: boolean;
}

export default function UserInputTextArea(props: IProps): ReactElement {
  const {
    icon,
    label,
    placeholder,
    textValue,
    name,
    value,
    id,
    handleChange,
    handleBlur,
    userStyle,
    handleKeyPress,
    userStyleText,
    noFormik,
    numberOfLines,
    multiline,
    titleInputContainerStyles,
    textFieldContianerAlign,
    formikPropsTouched,
    customChange
    // isSecure
  } = props;

  return (
    <View style={[styles.titleInputContainer, titleInputContainerStyles]}>
      {textValue && (
        <Text
          fontWeight={FontWeight.Regular}
          testId="internal"
          textSize={TextSize.Small}
          textStyle={[styles.subheaderText, styles.alignHeaderText, userStyleText]}
        >
          {textValue}
        </Text>
      )}
      <View
        style={[
          styles.textFieldContianer,
          styles.textFieldContianerAlignment,
          textFieldContianerAlign
        ]}
      >
        <TextArea
          value={value}
          inputStyle={[styles.inputStyling, styles.inputStylingAlignment, userStyle]}
          numberOfLines={numberOfLines}
          multiline={true}
          label=""
          handleChange={handleChange}
          handleBlur={handleBlur}
          placeholder={placeholder}
          name={name}
          id={id}
          noFormik={noFormik}
          formikPropsTouched={formikPropsTouched}
          customChange={customChange}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  titleInputContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 100,
    marginTop: 10
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  alignHeaderText: {
    // width:'20%'
    flex: 20
  },
  textFieldContianer: {
    // flex: 40,
    // width:"15%",
    marginLeft: 15,
    borderColor: colorPallete.cordioTaupe
  },
  textFieldContianerAlignment: {
    flex: 80
  },
  inputStyling: {
    // height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    fontWeight: "400"
  },
  inputStylingAlignment: {
    width: "35%"
  }
});
