import React, { ReactElement, useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import _ from "lodash";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import FilterLocationPage from "@socion-cordio/common/src/components/organisms/qualification-packs/filterLocationPage";
import FilterBadgeRolePage from "@socion-cordio/common/src/components/organisms/qualification-packs/filterBadgeRolePage";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {
  modalVisible: boolean;
  setModalVisible?: Function;
  getSelectedLocationList: Function;
  getSelectedRoleBadgeList: Function;
  dataFilteredHandler: Function;
  locationList: any[];
  badgeRoleList: any[];
  programDetails: any;
  setExpandedList: Function;
  expandedList: any;
  setCheckedList: Function;
  checkedList: any;
  clearAllFilter: Function;
  setIsLocationFilterApplied: Function;
  setIsBadgeFilterApplied: Function;
  closeModalHandler: Function;
  setGlobalList?: Function;
  globalList: any;
  setAllExpandedNodes?: any;
  allExpandedNodes?: any;
  customHeaderTitle?: string;
  customSubHeaderTitle?: string;
  applyButtonText?: string;
  toCheckErrorMessageOnDownload?: boolean;
  locationListButton?: any;
  setCheckedListCopy?: Function;
  checkedListCopy?: any;
  setCheckedListCopyBadges?: Function;
  checkedListCopyBadges?: any;
  setBadgeRoleList?: Function;
  selectedBadgeRoleList?: any;
  setSelectedBadgeRoleList?: any;
  setBadgeRoleListButton?: any;
  noAssignedBadgesSelected?: any;
}
export default function FilterQualificationPackModal(props: Props): ReactElement {
  const {
    modalVisible,
    setModalVisible,
    locationList,
    getSelectedLocationList,
    getSelectedRoleBadgeList,
    dataFilteredHandler,
    programDetails,
    badgeRoleList,
    setExpandedList,
    expandedList,
    setCheckedList,
    checkedList,
    clearAllFilter,
    setIsLocationFilterApplied,
    setIsBadgeFilterApplied,
    closeModalHandler,
    setGlobalList,
    globalList,
    setAllExpandedNodes,
    allExpandedNodes,
    customHeaderTitle,
    customSubHeaderTitle,
    applyButtonText,
    toCheckErrorMessageOnDownload = true,
    locationListButton,
    setCheckedListCopy,
    checkedListCopy,
    setCheckedListCopyBadges,
    checkedListCopyBadges,
    selectedBadgeRoleList,
    setSelectedBadgeRoleList,
    setBadgeRoleListButton,
    noAssignedBadgesSelected
  } = props;
  const [isLocationSelect, setIsLocationSelect] = useState(true);
  const [isBadgeRoleSelect, setIsBadgeRoleSelect] = useState(false);
  const [updatedLocationList, setUpdatedLocationList] = useState([]);
  const [errorFilterMessage, setErrorFilterMessage] = useState(false);
  const [isNoLocationSelected, setIsNoLocationSelected] = useState(false);
  const [isNoBadgeSelected, setIsNoBadgeSelected] = useState(false);
  const [badgeRoleListsArray, setBadgeRoleListsArray] = useState<Array<Object>>([]);
  const [checkedBadges, setCheckedBadges] = useState([]);

  useEffect(() => {
    if (toCheckErrorMessageOnDownload) {
      validateCheckedListHandler();
    }
  }, []);

  useEffect(() => {
    handleBadgeData();
  }, [badgeRoleList]);

  const validateCheckedListHandler = async () => {
    setCheckedList(checkedListCopy);
    badgeRoleList?.forEach((element: any) => {
      element.isSelected = false;
    });
    const newList: any[] = checkedListCopyBadges?.forEach((element: any) => {
      element.isSelected = true;
    });
    if (badgeRoleList !== undefined) {
      const list1 = badgeRoleList.map((element: any) => {
        const tempList = checkedListCopyBadges.filter(
          (item: any) => item.badgeId === element.badgeId
        );
        if (tempList.length !== 0) {
          element.isSelected = tempList[0].isSelected;
        }
      });
    }
  };

  const updatedFilteredLocationList = (locationList: any, bool: any) => {
    setUpdatedLocationList(locationList);
  };

  const handleBadgeData = () => {
    const sortedVal = _.mapValues(_.groupBy(badgeRoleList, "badgeClassName"), (clist) =>
      clist.map((badge) => _.omit(badge, "badgeClassName"))
    );
    let output = Object.entries(sortedVal).map(([badgeClass, children]) => ({
      badgeClass,
      children
    }));
    let tempoArray: any = [];
    if (noAssignedBadgesSelected) {
      setBadgeRoleListsArray(output);
      getSelectedRoleBadgeList([]);
      return;
    }
    output.forEach((badgeClassObj: any) => {
      let allChildrenSelected = true;
      badgeClassObj.children.forEach((childBadgeObj: any) => {
        if (selectedBadgeRoleList?.includes(childBadgeObj.badgeId)) {
          tempoArray.push(childBadgeObj);
          childBadgeObj.isSelected = true;
        } else {
          allChildrenSelected = false;
        }
      });
      if (allChildrenSelected) {
        badgeClassObj.isSelected = true;
      }
    });
    setCheckedBadges(tempoArray);
    setBadgeRoleListsArray(output);
  };

  const applyFilterHandler = async () => {
    const isError =
      checkedList?.length === 0 &&
      checkedBadges.length === 0 &&
      !isNoLocationSelected &&
      !isNoBadgeSelected &&
      toCheckErrorMessageOnDownload;
    if (isError) {
      //display error
      setErrorFilterMessage(true);
      return;
    }
    if (checkedList !== undefined && toCheckErrorMessageOnDownload) {
      setCheckedListCopy(checkedList);
    }

    LocalStorage.setStorage("globalNameList", globalList);
    // setIsLocationFilterApplied(true);
    // setIsBadgeFilterApplied(true);

    // getSelectedLocationList(updatedLocationList);
    // getSelectedRoleBadgeList(updatedBadgeList);
    if (badgeRoleList !== undefined && toCheckErrorMessageOnDownload) {
      let tempList = checkedBadges.map((badge) => badge.badgeId);
      setBadgeRoleListButton(checkedBadges);
      setSelectedBadgeRoleList(tempList);
    }

    const isNoneLocationSet = await LocalStorage.getStorage("noneLocationListSet");
    const isNoBadgeAssignSet = await LocalStorage.getStorage("noAssignedBadgesSelected");

    dataFilteredHandler({
      isFiltered: true,
      isNoneLocationSet: isNoneLocationSet,
      isNoBadgeAssignSet: isNoBadgeAssignSet
    });
    setModalVisible();

    setIsLocationFilterApplied(true);
    setIsBadgeFilterApplied(true);
    googleWebAnalytics("QP_User_List_Apply_Filter", "Button", "Qualification_Pack");
  };

  return (
    <View>
      {/* <Modal
        modalVisible={modalVisible}
        setModalVisible={() => setModalVisible()}
        component={ */}
      <View style={styles.container}>
        <View style={styles.header}>
          {customHeaderTitle ? (
            <View style={styles.customHeaderContainer}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.alignCustomHeaderTitle]}
              >
                {customHeaderTitle}
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[
                  styles.headerText
                  //  { fontFamily: FontFamily.Regular }
                ]}
              >
                {`( ${customSubHeaderTitle} )`}
              </Text>
            </View>
          ) : (
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              Filter
            </Text>
          )}
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              // onPress={() => [setModalVisible(), dataFilteredHandler(true)]}
              onPress={() => [setModalVisible(), closeModalHandler()]}
            />
          </View>
        </View>
        <View style={styles.subContainer}>
          <View style={styles.leftContainer}>
            <View>
              <TouchableOpacity
                onPress={() => {
                  setIsLocationSelect(true);
                  setIsBadgeRoleSelect(false);
                }}
              >
                <View style={styles.alignLeftContainer}>
                  <View>
                    <Icon
                      testID="baseLocation"
                      name={IconNames.baseLocation}
                      customStyle={
                        isLocationSelect ? styles.leftIconContainerDark : styles.leftIconContainer
                      }
                    />
                  </View>
                  <Text
                    fontWeight={FontWeight.Light}
                    // fontWeight={FontWeight.Bold}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={
                      isLocationSelect
                        ? [styles.headerText, styles.headerTextPrimary]
                        : [styles.headerTextSecondary]
                    }
                  >
                    Location
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setIsLocationSelect(false);
                  setIsBadgeRoleSelect(true);
                }}
              >
                <View style={styles.alignLeftContainer}>
                  <View>
                    <Icon
                      testID="establishment"
                      name={IconNames.badges}
                      customStyle={
                        isBadgeRoleSelect ? styles.leftIconContainerDark : styles.leftIconContainer
                      }
                    />
                  </View>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={
                      isBadgeRoleSelect
                        ? [styles.headerText, styles.headerTextPrimary]
                        : [styles.headerTextSecondary]
                    }
                  >
                    Badge
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.dividerContainer}></View>
          <View style={styles.rightContainer}>
            {errorFilterMessage && (
              <View style={{ position: "absolute", top: -10 }}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, { color: colorPallete.cordioRed }]}
                >
                  {"Please select either locations or the badges to apply the filters!"}
                </Text>
              </View>
            )}

            {programDetails && isLocationSelect ? (
              <FilterLocationPage
                getSelectedLocationList={getSelectedLocationList}
                locationList={locationList}
                programDetails={programDetails}
                setExpandedList={setExpandedList}
                expandedList={expandedList}
                setCheckedList={setCheckedList}
                checkedList={checkedList}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsLocationFilterApplied={setIsLocationFilterApplied}
                updatedFilteredLocationList={updatedFilteredLocationList}
                setGlobalList={setGlobalList}
                globalList={globalList}
                setAllExpandedNodes={setAllExpandedNodes}
                allExpandedNodes={allExpandedNodes}
                setErrorFilterMessage={setErrorFilterMessage}
                setIsNoLocationSelected={setIsNoLocationSelected}
              />
            ) : isBadgeRoleSelect ? (
              <FilterBadgeRolePage
                getSelectedRoleBadgeList={getSelectedRoleBadgeList}
                badgeRoleList={badgeRoleList}
                programDetails={programDetails}
                dataFilteredHandler={dataFilteredHandler}
                setModalVisible={setModalVisible}
                setIsBadgeFilterApplied={setIsBadgeFilterApplied}
                setErrorFilterMessage={setErrorFilterMessage}
                setIsNoBadgeSelected={setIsNoBadgeSelected}
                checkedBadges={checkedBadges}
                setCheckedBadges={setCheckedBadges}
                badgeRoleListsArrayData={badgeRoleListsArray}
              />
            ) : null}
          </View>
        </View>
        <View style={[styles.mainButtonContainer]}>
          <View style={styles.buttonContainer}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.button}
              textStyles={styles.buttonFont}
              title={applyButtonText ? "Download" : "Apply"}
              onPress={() => applyFilterHandler()}
              disabled={
                locationList === null ||
                locationList === undefined ||
                badgeRoleList === null ||
                badgeRoleList === undefined
              }
            />
          </View>
        </View>
      </View>
      {/* }
      ></Modal> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655,
    height: 450
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  customHeaderContainer: {
    flexDirection: "row"
  },
  alignCustomHeaderTitle: {
    marginRight: 10
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextSecondary: {
    fontSize: 12,
    marginLeft: 15,
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  headerTextPrimary: {
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 15
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginTop: 15,
    marginBottom: 15,
    flexDirection: "row"
  },

  //left container
  leftContainer: {
    flex: 20,
    padding: 10,
    paddingLeft: 0
  },
  alignLeftContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 25
  },
  // alignLabelContainer: {
  //   flexDirection: "column",
  //   justifyContent: "space-between",
  //   height: "95%"
  // },
  leftIconContainer: {
    fontSize: 16,
    color: colorPallete.textLight
  },
  leftIconContainerDark: {
    fontSize: 16,
    color: colorPallete.textBlack
  },

  //divider
  dividerContainer: {
    flex: 1,
    minHeight: 450,
    borderRightColor: colorPallete.cordioTaupe,
    borderRightWidth: 1,
    borderStyle: "solid",
    opacity: 0.1
  },

  //right container
  rightContainer: {
    flex: 79,
    padding: 10,
    paddingLeft: 25
  },
  //text field
  inputStyling: {
    height: 40,
    // outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400"
  },
  inputStylingAlignment: {},
  //checkBox
  checkBoxContainer: {
    marginTop: 27
  },
  textStyleCheckBox: {
    marginStart: 20
  },
  mainButtonContainer: {
    position: "absolute",
    flexDirection: "row",
    bottom: 0,
    right: 0
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  }
});
