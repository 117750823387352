import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { useDispatch, useSelector } from "react-redux";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import { ToastContainer, toast } from "react-toastify";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import ProfileConfirmationModal from "@socion-cordio/common/src/components/organisms/common-modals/profileConfirmationModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import OtpValidate from "@socion-cordio/common/src/components/molecules/otpValidate";
import PasswordInput from "@socion-cordio/common/src/components/organisms/passwordInput";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import AddEmail from "@socion-cordio/common/src/components/organisms/addEmail";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { ProfileRepository } from "@socion-cordio/common/src/repositories/profile/profile";
import TextField from "@socion-cordio/common/src/components/atoms/textField";

interface Props {
  setLoader?: Function;
}

export default function PersonalInfo(props: Props): ReactElement {
  const { setLoader } = props;
  const [name, setName] = useState("");
  const [editable, setEditable] = useState(false);
  const [userProfileData, setUserProfileData] = useState(null);
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isInfoEnabled, setIsnfoEnabled] = useState(false);
  const [emailmodalVisible, setEmailModalVisible] = useState(false);
  const [mobilemodalVisible, setMobileModalVisible] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const data: any = useSelector((state) => state);

  const processUserProfileData = () => {
    if (data?.profile?.userProfileData?.responseCode === 200) {
      setUserProfileData(data?.profile?.userProfileData.response);
      setIsnfoEnabled(data?.profile?.userProfileData?.response?.piiInfo);
      setName(data?.profile?.userProfileData?.response?.name);
      setphoneNumber(data?.profile?.userProfileData?.response?.phoneNumber);
      setEmail(data?.profile?.userProfileData?.response?.emailId);
    } else if (data?.profile?.userProfileData?.message) {
      toast.error(data?.profile?.userProfileData?.message);
    } else if (data?.profile?.error?.userProfileGet) {
      toast.error("Error occured");
    }
  };

  const handleToggle = () => {
    !isInfoEnabled && setIsnfoEnabled((isInfoEnabled) => !isInfoEnabled);
    !isInfoEnabled && hanldlePIConsent(!isInfoEnabled);
    isInfoEnabled && setConfirmationModal(true);
  };

  const handleConfirm = (): any => {
    setIsnfoEnabled((isInfoEnabled) => !isInfoEnabled);
    hanldlePIConsent(!isInfoEnabled);
    setConfirmationModal(false);
    googleWebAnalytics("Profile_Withdraw_Consent_Info", "Icon", "Profile");
  };

  const hanldlePIConsent = async (piiStatus: boolean) => {
    const params = {
      osId: userProfileData?.osid,
      enable: piiStatus
    };
    const piConsentResponse = await ApiClient.put(
      profileEndPoints.updatePersonalInfo(),
      {},
      params
    );
    if (piConsentResponse.responseCode === HTTP_STATUS_CODES.ok) {
      const user: any = await LocalStorage.getStorage("user");
      user.piiInfo = piiStatus;
      LocalStorage.setStorage("user", user);
      toast.success(piConsentResponse.message);
      dispatch(ProfileActions.getUserProfile());
      updateTelemetryConsent(piiStatus);
    } else {
      toast.error(piConsentResponse.message);
    }
    googleWebAnalytics("Profile_Share_Consent_Info", "Icon", "Profile");
  };

  const updateTelemetryConsent = async (piiStatus: boolean) => {
    let eventType = "";
    piiStatus === true ? (eventType = "Consent PII") : (eventType = "Withdraw Consent PII");
    let eventAnalytics = piiStatus ? "Consent_PII" : "Withdraw_Consent_PII";
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      createdAt: Moment(),
      deleted: "false",
      updatedAt: Moment(),
      eventType: eventType
    };
    AddTelemetryService(body, undefined, user);
    googleWebAnalytics(eventAnalytics, "Telemetry_Event", "Profile");
  };

  useEffect(() => {
    processUserProfileData();
  }, [data?.profile?.userProfileData.response]);

  const handleSubmit = async () => {
    if (name == "") {
      toast.error("Name is required");
    } else {
      setLoader(true);
      let response = ProfileRepository.updateUserName(name);
      response
        .then((res: any) => {
          if (res.responseCode === HTTP_STATUS_CODES.ok) {
            dispatch(ProfileActions.getUserProfile());
            setEditable((editable) => !editable);
            updateTelemetry();
            updeteUserName(name);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(res.message);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong");
          setLoader(false);
        });
    }
    googleWebAnalytics("Profile__Clicked_Tick_Name", "Icon", "Profile");
  };

  const updeteUserName = async (name: string) => {
    const user: any = await LocalStorage.getStorage("user");
    const updatedProfileInfo: any = await LocalStorage.getStorage("updatedProfileInfo");
    const newUser = { ...user };
    const newUserUpdated = { ...updatedProfileInfo };
    newUser.name = name;
    newUserUpdated.name = name;
    LocalStorage.setStorage("user", newUser);
    LocalStorage.setStorage("updatedProfileInfo", newUserUpdated);
  };

  const updateTelemetry = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      createdAt: Moment(),
      deleted: "false",
      updatedAt: Moment(),
      eventType: "Profile edit-Name change",
      establishmentName: user.establishmentName
    };
    const userDetails = user;
    AddTelemetryService(body, undefined, userDetails);
    googleWebAnalytics("Profile_Edit_Name", "Telemetry_Event", "Profile");
  };

  useEffect(() => {
    if (confirmationModal || mobilemodalVisible || emailmodalVisible) disableScroll();
    else enableScroll();
  }, [confirmationModal, mobilemodalVisible, emailmodalVisible]);

  const renderEmptyPhoneNumber = () => (
    <>
      <View style={[styles.view, styles.customInput]}>
        <View style={[styles.label, styles.labelAlign]}>
          <Icon testID="home" name={IconNames.mobile} customStyle={[styles.icon]} />
          <Text
            fontWeight={FontWeight.Regular}
            testId="internal"
            textSize={TextSize.Small}
            style={[styles.textIcon, styles.textIconAlign]}
          >
            {"Mobile"}
          </Text>
        </View>
        <View style={styles.textField}>
          <TextField
            isSecure={false}
            inputStyle={[styles.input, styles.inputAlign1]}
            name={name}
            placeholder={"placeholder"}
            label={""}
            value={userProfileData?.countryCode}
            id={"textField"}
            handleBlur={() => {}}
            noFormik
            editable={false}
          ></TextField>
          <TextField
            isSecure={false}
            inputStyle={[styles.input, styles.inputAlign2]}
            name={name}
            placeholder={"placeholder"}
            label={""}
            value={"Enter mobile number"}
            id={"textField"}
            handleBlur={() => {}}
            noFormik
            editable={false}
          ></TextField>
          <View style={styles.iconContainer}>
            <TouchableOpacity>
              <Icon
                name={IconNames.edit}
                customStyle={styles.iconStyle}
                onPress={() => setMobileModalVisible(true)}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  );

  return (
    <>
      <View style={styles.personalInfo}>
        <View style={styles.headerContainer}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Personal Information
          </Text>
        </View>
        <View>
          <View>
            <UserInput
              userStyle={styles.customInput}
              textIcon={styles.textIcon}
              input={styles.input}
              view={styles.view}
              labelStyle={styles.label}
              textField={styles.textField}
              iconStyle={styles.icon}
              label=""
              handleChange={(name: string) => {
                setName(name);
              }}
              handleBlur={() => {}}
              icon={IconNames.userName}
              textValue="Name"
              name="name"
              placeholder="Enter name"
              value={name}
              id="name"
              noFormik
              editable={editable}
              setName={() => setName(data.profile.userProfileData.response.name)}
              onPress={() => {
                setEditable((editable) => !editable);
                googleWebAnalytics("Profile_Edit_Name", "Icon", "Profile");
              }}
              onsubmit={() => handleSubmit()}
            />
          </View>
          <View>
            {phoneNumber !== "" ? (
              <UserInput
                userStyle={styles.customInput}
                textIcon={styles.textIcon}
                input={styles.input}
                view={styles.view}
                labelStyle={styles.label}
                textField={styles.textField}
                iconStyle={styles.icon}
                label=""
                handleChange={(phoneNumber: string) => {
                  setphoneNumber(phoneNumber);
                }}
                handleBlur={() => {}}
                icon={IconNames.mobile}
                textValue="Mobile"
                name="Mobile"
                placeholder="Enter mobile number"
                value={`${userProfileData?.countryCode} ${phoneNumber}`}
                id="Mobile"
                noFormik
                editable={false}
                onPress={() => {
                  setMobileModalVisible(true);
                  googleWebAnalytics("Profile_Edit_Number", "Icon", "Profile");
                }}
              />
            ) : (
              <>{renderEmptyPhoneNumber()}</>
            )}
            {mobilemodalVisible && (
              <Modal
                modalVisible={mobilemodalVisible}
                setModalVisible={() => setMobileModalVisible(!mobilemodalVisible)}
                component={
                  email ? (
                    <View style={styles.modalContainer}>
                      <OtpValidate
                        onClose={() => {
                          setMobileModalVisible(false);
                          googleWebAnalytics("Profile_Close_Number_Modal", "Icon", "Profile");
                        }}
                        modalTitle={
                          phoneNumber !== "" ? "Change mobile number" : "Enter mobile number"
                        }
                        headerText="Please enter the code that was sent to the registered email"
                        subHeaderText="Haven’t received an email?"
                        showEmail={false}
                      />
                    </View>
                  ) : (
                    <View style={styles.modalContainer}>
                      <PasswordInput
                        onClose={() => {
                          setMobileModalVisible(false);
                          googleWebAnalytics("Profile_Close_Number_Modal", "Icon", "Profile");
                        }}
                        modalTitle="Change mobile number"
                        headerText="Please enter your current password to continue"
                        showEmail={false}
                        setPhoneNumberPopup={true}
                      />
                    </View>
                  )
                }
              />
            )}
          </View>
          <UserInput
            userStyle={styles.customInput}
            textIcon={styles.textIcon}
            input={styles.input}
            view={styles.view}
            labelStyle={styles.label}
            textField={styles.textField}
            iconStyle={styles.icon}
            label=""
            handleChange={(email: string) => {
              setEmail(email);
            }}
            handleBlur={() => {}}
            icon={IconNames.email}
            textValue="E-mail"
            name="email"
            placeholder="Enter email"
            value={email}
            id="email"
            noFormik
            editable={false}
            onPress={() => {
              setEmailModalVisible(true);
              googleWebAnalytics("Profile_Edit_Email", "Icon", "Profile");
            }}
          />
          {emailmodalVisible && (
            <Modal
              modalVisible={emailmodalVisible}
              setModalVisible={() => setEmailModalVisible(!emailmodalVisible)}
              component={
                // <View style={styles.modalContainer}>
                //   <OtpValidate
                //     onClose={() => setEmailModalVisible(false)}
                //     modalTitle="Change email address"
                //     headerText="Please enter the code sent to the registered mobile number"
                //     showEmail
                //   />
                // </View>
                <>
                  {phoneNumber ? (
                    <View style={styles.modalContainer}>
                      <OtpValidate
                        onClose={() => setEmailModalVisible(false)}
                        modalTitle="Change email address"
                        headerText="Please enter the code that was sent to the registered mobile number"
                        showEmail
                        isValidatePhoneEnabled={true}
                      />
                      {/* <AddEmail
                        onClose={() => setEmailModalVisible(false)}
                        isEmailChangeClicked={true}
                        userProfileData={userProfileData}
                      /> */}
                      {/* <PasswordInput
                    onClose={() => setEmailModalVisible(false)}
                    modalTitle="Change email address"
                    headerText="Please enter your current password to continue"
                    showEmail
                    setPhoneNumberPopup={false}
                  /> */}
                    </View>
                  ) : (
                    <View style={styles.modalContainer}>
                      <PasswordInput
                        onClose={() => setEmailModalVisible(false)}
                        modalTitle="Change email address"
                        headerText="Please enter your current password to continue"
                        showEmail
                        setPhoneNumberPopup={false}
                      />
                    </View>
                  )}
                </>
              }
            />
          )}
        </View>
      </View>
      <View style={styles.consent}>
        <View style={{ flex: 30 }}>
          <Switch
            isOn={isInfoEnabled}
            onColor="#B7504A"
            offColor="#F1DCDB"
            labelStyle={{ color: "black", fontWeight: "900" }}
            size="medium"
            onToggle={handleToggle}
          />
        </View>
        <View style={{ flex: 72 }}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="signup"
            textSize={TextSize.Regular}
            style={styles.consentText}
          >
            {isInfoEnabled
              ? "You can withdraw your consent to share this information with the programs you are associated with."
              : "You can grant your consent to share this information with the programs you are associated with."}
          </Text>
        </View>
      </View>
      {confirmationModal && (
        <View>
          <SocionModal
            modalVisible={confirmationModal}
            setModalVisible={() => setConfirmationModal(!confirmationModal)}
            component={
              <ProfileConfirmationModal
                onClose={() => setConfirmationModal(false)}
                handleConfirm={handleConfirm}
                message="Are you sure you want to withdraw your consent to share this information?"
              />
            }
          />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  personalInfo: {
    marginTop: 25
  },
  userInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "flex-start"
  },
  consent: {
    display: "flex",
    flexDirection: "row",
    marginTop: "1rem"
  },
  consentText: {
    flex: 0.79,
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular,
    fontSize: 12
  },
  location: {
    marginTop: "2rem"
  },
  modalContainer: {
    width: 680
  },
  customInput: {
    flexDirection: "row",
    flex: 100,
    display: "flex"
  },
  input: {
    marginRight: 8,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textBlack,
    fontSize: 12,
    width: 260,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.lightGreyThree
  },
  inputAlign1: {
    width: 55,
    height: 40,
    borderRadius: 0,
    marginRight: 0,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  inputAlign2: {
    width: 205,
    height: 40,
    borderRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    color: colorPallete.textLight
  },
  view: {
    zIndex: -1,
    display: "flex",
    flexDirection: "row",
    marginTop: "0.5rem",
    justifyContent: "space-between"
  },
  label: {
    flex: 30,
    marginBottom: 0,
    marginLeft: 0
  },
  labelAlign: {
    flexDirection: "row",
    alignItems: "center"
  },
  textField: {
    flex: 72,
    flexDirection: "row"
  },
  icon: {
    flexDirection: "row",
    color: colorPallete.textLight,
    fontSize: 17
  },
  textIcon: {
    fontSize: 12,
    marginLeft: 10,
    fontFamily: FontFamily.Regular
  },
  textIconAlign: {
    color: colorPallete.textLight,
    marginLeft: 13
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },
  headerContainer: {
    marginBottom: 5
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    fontSize: 17
  }
});
