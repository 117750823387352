import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  BADGE_SETTING_TYPES,
  BADGE_TYPE_STATUS,
  HTTP_STATUS_CODES
} from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import {
  RadioButton,
  RadioButtonType
} from "@socion-cordio/common/src/components/atoms/radioButton";
import { BadgesRepository } from "@socion-cordio/common/src/repositories/badges/badges";
import { useDispatch, useSelector } from "react-redux";
import { BadgesActions } from "@socion-cordio/common/src/modules/badges/actions/actions";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import AddBadgeTypes from "@socion-cordio/common/src/components/organisms/badges/badge-types/addBadgeTypes";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";

interface Props {
  onClose: Function;
  programDetails: any;
  selectedBadgeType: any;
  onPublishClickedHandler?: Function;
  isUserProgramAdmin?: boolean;
}

export default function ViewBadgeTypes(props: Props): ReactElement {
  const {
    onClose,
    programDetails,
    selectedBadgeType,
    onPublishClickedHandler,
    isUserProgramAdmin
  } = props;
  const { program } = programDetails;

  const [isEditClicked, setIsEditClicked] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [isBadgeTypePublished, setBadgeTypePublished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublishClicked, setIsPublishClicked] = useState(false);
  const [displaySelfDeclaredToggle, setDisplaySelfDeclaredToggle] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    validateBadgeTypePublishHandler();
  }, []);

  useEffect(() => {
    setDisplaySelfDeclaredToggle(program?.paSelfDeclaredBadgeEnabled);
  }, [programDetails]);

  const validateBadgeTypePublishHandler = () => {
    if (selectedBadgeType) {
      selectedBadgeType.status === BADGE_TYPE_STATUS.DEACTIVATED
        ? setBadgeTypePublished(false)
        : setBadgeTypePublished(true);
    }
  };

  const publishUnpublishBadgeTypeHandler = () => {
    let response = null;
    isBadgeTypePublished
      ? [(response = BadgesRepository.deactivateBadgeType(selectedBadgeType.badgeClassId))]
      : [(response = BadgesRepository.activateBadgeType(selectedBadgeType.badgeClassId))];
    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          dispatch(BadgesActions.getBadgeTypes(program.id));
          onClose();
          toast.success(
            `Badge Type ${isBadgeTypePublished ? "unpublished" : "published"} successfully`
          );
          updateTelemetryBadgeTypeEvent(
            isBadgeTypePublished ? "Badge Type Unpublished" : "Badge Type Published",
            res.response
          );
          setBadgeTypePublished((prevState) => !prevState);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const editBadgeTypeHandler = () => {
    setIsEditClicked(true);
  };

  const renderRadioButton = (textValue: string, isSelected: boolean) => (
    <RadioButton
      testId=""
      selected={isSelected}
      type={RadioButtonType.Round}
      text={textValue}
      textSize={TextSize.Small}
      textFontWeight={FontWeight.Regular}
      textStyle={isSelected ? styles.checkboxTextBold : styles.checkboxText}
      onPress={() => {}}
      iconSize={20}
      renderOnlyRadioButtonClickToucable={false}
      isTouchableDisabled={true}
      iconSelectedColor={colorPallete.cordioTaupeLight3}
    />
  );

  const deleteHandler = (value: boolean) => {
    if (value) {
      setIsLoading(true);
      const response = BadgesRepository.deactivateBadgeType(selectedBadgeType.badgeClassId);
      response
        .then((res) => {
          if (res.responseCode === HTTP_STATUS_CODES.ok) {
            setIsLoading(false);
            onClose();
            dispatch(BadgesActions.getBadgeTypes(program.id));
            toast.success("Badge Type deleted successfully");
          } else {
            setIsLoading(false);
            toast.error(res.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Something went wrong");
        });
    }
  };

  const publishHandler = (value: boolean) => {
    const { program } = props?.programDetails;
    if (value) {
      setIsLoading(true);
      const response = BadgesRepository.publishBadgeType(selectedBadgeType.badgeClassId);
      response
        .then((res) => {
          dispatch(BadgesActions.getBadgeTypes(program.id));
          setIsLoading(false);
          onClose();
          onPublishClickedHandler(res.response);
          toast.success("Badge Type published successfully");
          updateTelemetryBadgeTypeEvent("Badge Type Published", res.response);
        })
        .catch((error) => {
          toast.error("Something went wrong");
          setIsLoading(false);
        });
    }
  };

  const updateTelemetryBadgeTypeEvent = async (eventType: string, selectedBadge: any) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      updatedBy: user.userId,
      eventType: eventType,
      badgeType: selectedBadge.badgeClassName,
      badgeTypeId: selectedBadge.badgeClassId,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programDetails, user, false);
  };

  const renderEditBadgeType = () => (
    <>
      <AddBadgeTypes
        onClose={() => onClose()}
        programDetails={programDetails}
        isEditClicked={true}
        selectedBadgeType={selectedBadgeType}
      />
    </>
  );

  const renderPublishUnpublishContainer = (message: string, value: string) => (
    <View style={styles.rowContainer}>
      <View style={{ flex: 52 }}>
        <Text
          fontWeight={FontWeight.Regular}
          testId="publishUnpublishValueMessage"
          textSize={TextSize.Small}
          textStyle={[styles.headerText2]}
        >
          {message}
        </Text>
      </View>
      <View style={[styles.alignTextContainer, { flex: 48 }]}>
        <Text
          fontWeight={FontWeight.Regular}
          testId="publishUnpublishValue"
          textSize={TextSize.Small}
          textStyle={styles.headerText3}
        >
          {value}
        </Text>
      </View>
    </View>
  );

  const renderViewBadgeType = () => (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text
          fontWeight={FontWeight.Bold}
          testId="addRoleText"
          textSize={TextSize.Small}
          textStyle={styles.headerText}
        >
          {"Badge type details"}
        </Text>
        <View>
          <Icon
            testID="close"
            name={IconNames.crossCircle}
            customStyle={styles.crossCircle}
            onPress={() => onClose()}
          />
        </View>
      </View>
      <View style={styles.dividerLine} />
      {selectedBadgeType.status === BADGE_TYPE_STATUS.DRAFT && isUserProgramAdmin ? (
        <View style={styles.iconsContainer}>
          <TouchableOpacity
            onPress={() => {
              setIsDeleteClicked(true);
            }}
            style={{ marginLeft: 30 }}
          >
            <Icon
              testID="delete"
              name={IconNames.deleteFile}
              customStyle={styles.alignIconsButton}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginLeft: 30 }}
            onPress={() => {
              // onPublishClickedHandler();
              setIsPublishClicked(true);
            }}
          >
            <Icon testID="publish" name={IconNames.publish} customStyle={styles.alignIconsButton} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => editBadgeTypeHandler()} style={{ marginLeft: 30 }}>
            <Icon testID="edit" name={IconNames.penPaper} customStyle={styles.alignIconsButton} />
          </TouchableOpacity>
        </View>
      ) : selectedBadgeType.status === BADGE_TYPE_STATUS.PUBLISHED && isUserProgramAdmin ? (
        <View style={styles.iconsContainer}>
          <TouchableOpacity onPress={() => editBadgeTypeHandler()} style={{ marginLeft: 30 }}>
            <Icon testID="edit" name={IconNames.penPaper} customStyle={styles.alignIconsButton} />
          </TouchableOpacity>
        </View>
      ) : (
        <View style={{ height: 15 }} />
      )}

      {/* isUserProgramAdmin */}

      <View style={styles.subContainer}>
        <View style={(styles.rowContainer, styles.alignRowContainer)}>
          <View style={styles.headerTextContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="internal"
              textSize={TextSize.Small}
              textStyle={[styles.headerText2]}
            >
              {"Badge type"}
            </Text>
          </View>
          <View style={[styles.headerValueContainer, styles.alignTextContainer]}>
            <ScrollContainer
              height="70px"
              scrollable={selectedBadgeType.badgeType?.length > 150 ? true : false}
            >
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {selectedBadgeType.badgeClassName}
              </Text>
            </ScrollContainer>
          </View>
        </View>

        <View style={[styles.rowContainer, styles.alignRowContainer]}>
          <View style={styles.headerTextContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="internal"
              textSize={TextSize.Small}
              textStyle={[styles.headerText2]}
            >
              {"Description"}
            </Text>
          </View>
          <View style={[styles.headerValueContainer, styles.alignTextContainer]}>
            <ScrollContainer
              height="70px"
              scrollable={selectedBadgeType.description?.length > 150 ? true : false}
            >
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.headerText2, styles.headerTextAlign]}
              >
                {selectedBadgeType.description}
              </Text>
            </ScrollContainer>
          </View>
        </View>

        <View style={styles.rowContainer}>
          <View style={styles.headerTextContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="internal"
              textSize={TextSize.Small}
              textStyle={[styles.headerText2]}
            >
              {"Settings"}
            </Text>
          </View>
          <View style={styles.headerValueContainer}>
            {/* Assigned or Self declared */}
            {displaySelfDeclaredToggle && (
              <View style={styles.checkBoxRow}>
                <View style={styles.radioButtonContainer}>
                  {renderRadioButton(
                    BADGE_SETTING_TYPES.ASSIGNED,
                    selectedBadgeType.declaredType === BADGE_SETTING_TYPES.ASSIGNED ||
                      selectedBadgeType.declaredType === BADGE_SETTING_TYPES.ASSIGN
                      ? true
                      : false
                  )}
                </View>

                <View>
                  {renderRadioButton(
                    // BADGE_SETTING_TYPES.SELF_DECLARED,
                    "Self-Declared",
                    selectedBadgeType.declaredType === BADGE_SETTING_TYPES.SELF_DECLARED
                      ? true
                      : false
                  )}
                </View>
              </View>
            )}

            {/* Time Bound or Open ended */}
            <View style={styles.checkBoxRow}>
              <View style={styles.radioButtonContainer}>
                {renderRadioButton(BADGE_SETTING_TYPES.TIME_BOUND, selectedBadgeType.validity)}
              </View>
              <View>
                {renderRadioButton(BADGE_SETTING_TYPES.OPEN_ENDED, !selectedBadgeType.validity)}
              </View>
            </View>

            {/* Mulitple or single values */}
            <View style={styles.checkBoxRow}>
              <View style={styles.radioButtonContainer}>
                {renderRadioButton(
                  BADGE_SETTING_TYPES.SINGLE_VALUES,
                  selectedBadgeType.valueType === BADGE_SETTING_TYPES.SINGLE_VALUES ||
                    selectedBadgeType.valueType === BADGE_SETTING_TYPES.SINGLE
                    ? true
                    : false
                )}
              </View>
              <View>
                {renderRadioButton(
                  BADGE_SETTING_TYPES.MULTIPLE_VALUES,
                  selectedBadgeType.valueType === BADGE_SETTING_TYPES.MULTIPLE_VALUES ||
                    selectedBadgeType.valueType === BADGE_SETTING_TYPES.MULTIPLE
                    ? true
                    : false
                )}
              </View>
            </View>

            {/* Mandatory or Optional  */}
            {/* <View style={styles.checkBoxRow}>
              <View style={styles.radioButtonContainer}>
                {renderRadioButton(BADGE_SETTING_TYPES.OPTIONAL, !selectedBadgeType.mandatory)}
              </View>
              <View>
                {renderRadioButton(BADGE_SETTING_TYPES.MANDATORY, selectedBadgeType.mandatory)}
              </View>
            </View> */}
          </View>
        </View>
        <View style={styles.rowContainer}>
          <View style={styles.headerTextContainer}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="internal"
              textSize={TextSize.Small}
              textStyle={[styles.headerText2]}
            >
              {"Status"}
            </Text>
          </View>
          <View style={[styles.headerValueContainer, styles.alignTextContainer]}>
            {selectedBadgeType.status === BADGE_TYPE_STATUS.DRAFT ? (
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.headerText2, styles.alignTextItalics]}
              >
                {selectedBadgeType.status}
              </Text>
            ) : (
              <View style={styles.switchContainer}>
                <Switch
                  isOn={isBadgeTypePublished}
                  onColor="#B7504A"
                  offColor="#F1DCDB"
                  labelStyle={{ color: "black", fontWeight: "900" }}
                  size="medium"
                  onToggle={() => {
                    if (isUserProgramAdmin) {
                      // setBadgeTypePublished((prevState) => !prevState);
                      publishUnpublishBadgeTypeHandler();
                    }
                  }}
                />
                <View style={styles.switchTextContainer}>
                  {
                    // selectedBadgeType.status === BADGE_TYPE_STATUS.DEACTIVATED
                    !isBadgeTypePublished ? (
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="published"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText2]}
                      >
                        {BADGE_TYPE_STATUS.UNPUBLISHED}
                      </Text>
                    ) : (
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="unpublished"
                        textSize={TextSize.Small}
                        textStyle={[styles.checkboxTextBold, styles.textColor]}
                      >
                        {BADGE_TYPE_STATUS.PUBLISHED}
                      </Text>
                    )
                  }
                </View>
              </View>
            )}
          </View>
        </View>

        {selectedBadgeType.status !== BADGE_TYPE_STATUS.DRAFT && (
          <>
            <View style={styles.flexDirectionRow}>
              <View style={styles.publishedUnpublishedContainer}>
                {renderPublishUnpublishContainer(
                  "Published on",
                  selectedBadgeType?.publishedDate
                    ? dataHelper.formatDate(selectedBadgeType?.publishedDate, "DD MMM YYYY")
                    : "NA"
                )}
              </View>
              <View style={styles.publishedUnpublishedContainer}>
                {renderPublishUnpublishContainer(
                  "Published by",
                  selectedBadgeType?.publishedByName ?? "NA"
                )}
              </View>
            </View>
            {selectedBadgeType.status !== BADGE_TYPE_STATUS.PUBLISHED && (
              <View style={styles.flexDirectionRow}>
                <View style={styles.publishedUnpublishedContainer}>
                  {renderPublishUnpublishContainer(
                    "Unpublished on",
                    selectedBadgeType?.unPublishedDate
                      ? dataHelper.formatDate(selectedBadgeType?.unPublishedDate, "DD MMM YYYY")
                      : "NA"
                  )}
                </View>
                <View style={styles.publishedUnpublishedContainer}>
                  {renderPublishUnpublishContainer(
                    "Unpublished by",
                    selectedBadgeType?.unPublishedByName ?? "NA"
                  )}
                </View>
              </View>
            )}
          </>
        )}
      </View>
    </View>
  );

  const renderDeleteBadgeType = () => (
    <>
      {isLoading ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : (
        <RemoveLinkModal
          modalVisible={isDeleteClicked}
          selectedData={deleteHandler}
          textValue={`Are you sure you want to delete "${selectedBadgeType?.badgeClassName}" Badge Type?`}
          deleteButtonText={"Ok"}
          setModalVisible={() => setIsDeleteClicked(!isDeleteClicked)}
        />
      )}
    </>
  );

  const renderPublishBadgeType = () => (
    <>
      {isLoading ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : (
        <RemoveLinkModal
          modalVisible={isPublishClicked}
          selectedData={publishHandler}
          textValue={`Do you want to publish "${selectedBadgeType?.badgeClassName}" Badge Type?`}
          deleteButtonText={"Ok"}
          setModalVisible={() => {
            setIsPublishClicked(!isPublishClicked);
          }}
        />
      )}
    </>
  );

  return (
    <View>
      <ToastContainer />
      {isDeleteClicked ? (
        <>{renderDeleteBadgeType()}</>
      ) : isPublishClicked ? (
        <>{renderPublishBadgeType()}</>
      ) : (
        <>{isEditClicked ? <>{renderEditBadgeType()}</> : <>{renderViewBadgeType()}</>}</>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 630
  },
  customLoadingContainer: {
    width: 630,
    height: 180
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  dividerLine: {
    borderWidth: 0.5,
    borderColor: colorPallete.lightGreyThree,
    borderStyle: "solid",
    marginVertical: 10,
    marginTop: 15
  },
  iconsContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 10
  },
  alignIconsButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  alignTextContainer: {
    alignSelf: "center"
  },
  headerText2: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textLight,
    fontSize: 14
  },
  alignTextItalics: {
    fontStyle: "italic"
  },
  headerText3: {
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontWeight: "bold"
  },
  headerTextAlign: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginLeft: 45,
    marginBottom: 20
  },
  inputStylingAlignment: {
    width: "100%",
    fontFamily: FontFamily.Regular
  },
  textHeaderField: {
    flex: 23
  },
  errorText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.cordioRed
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 10
  },
  alignRowContainer: {
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 20
  },
  headerTextContainer: {
    flex: 24
  },
  headerValueContainer: {
    flex: 76
  },
  checkboxText: {
    paddingLeft: 10,
    fontSize: 14,
    marginVertical: 8,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textLight
  },
  checkboxTextBold: {
    paddingLeft: 10,
    fontSize: 14,
    marginVertical: 8,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontWeight: "bold"
  },
  textColor: {
    color: colorPallete.textLight
  },
  checkBoxRow: {
    flexDirection: "row",
    width: 285
  },
  radioButtonContainer: {
    width: 170
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 10
  },
  submitbutton: {
    width: 140,
    height: 50,
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  switchTextContainer: {
    marginLeft: 10
  },
  publishedUnpublishedContainer: {
    width: 280
  },
  flexDirectionRow: {
    flexDirection: "row"
  }
});
