import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { useDispatch, useSelector } from "react-redux";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { ParticipantDetailsActions } from "@socion-cordio/common/src/modules/participationDetails/actions/actions";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { participationDetailsEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";

interface Props {
  programDetails: any;
}

export default function TrainerByTopic(props: Props): ReactElement {
  const { programDetails } = props;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [trainerByTopicData, setTrainerByTopicData] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(false);
  const dispatch = useDispatch();
  let participationDetails: any = useSelector((state: any) => state?.participationDetails);

  useEffect(() => {
    // getTrainerAttestationData();
    dispatch(
      ParticipantDetailsActions.getTrainerByTopic({ programId: programDetails?.program?.id })
    );
  }, []);

  useEffect(() => {
    if (viewButtonDetails) disableScroll();
    else enableScroll();
  }, [viewButtonDetails]);

  useEffect(() => {
    processTrainerAttestatinData();
  }, [participationDetails]);

  useEffect(() => {
    leftNavCollapseHandler();
  });

  const leftNavCollapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  const trainerByTopicDetailsCSV = async () => {
    const trainerByTopicListCSVResponse = await ApiClient.post(
      participationDetailsEndPoints.getTrainerByTopicListCSV(programDetails?.program?.id),
      {}
    );
    setLoading(false);
    return trainerByTopicListCSVResponse;
  };

  const downloadCsvList = async () => {
    setLoading(true);
    const data = await trainerByTopicDetailsCSV();
    auxiliaryMethods.handleDownload(
      data,
      `${programDetails?.program?.name}-Trainer-TrainersByTopic`
    );
    auxiliaryMethods.updateTelemetryEvent("Download Trainer List");
    googleWebAnalytics("Trainer_By_Topic_DownloadCsv", "Button", "Participation_Details");
  };

  const processTrainerAttestatinData = () => {
    if (participationDetails?.trainerByTopicData?.response) {
      let badgeDataList: any = [];
      let uniqueBadgeDataList;
      let data = participationDetails?.trainerByTopicData?.response;
      data?.forEach((attestationData: any, index: number) => {
        attestationData.serialNo = `${index + 1}.`;
        attestationData.formattedBaseLocation =
          auxiliaryMethods.fromatBaseLocation(attestationData);
        attestationData.verifiedRole = auxiliaryMethods.getVerifiedRole(
          attestationData.roleDetails
        );
        attestationData.verifiedQualification = auxiliaryMethods.getVerifiedQualification(
          attestationData.qualificationTitle
        );
        attestationData.topicName = auxiliaryMethods.getTopics(attestationData.topicData) || "None";
        attestationData?.badgeDetails?.forEach((badge: any, index: number) => {
          attestationData[badge.badgeType] = auxiliaryMethods.getAssignedBadges(
            attestationData?.badgeDetails
          );
          badgeDataList.push({
            badgeType: badge.badgeType,
            badgeTypeId: badge.badgeTypeId
          });
        });
      });
      uniqueBadgeDataList = [
        ...new Map(badgeDataList.map((item: any) => [item["badgeTypeId"], item])).values()
      ];
      data.badgeData = uniqueBadgeDataList;
      formatHeaderData(data?.badgeData);
      setTrainerByTopicData(data);
      setLoading(false);
    }
  };

  const formatHeaderData = (data: any) => {
    let columnsList = [...columns];
    columnsList.splice(7);
    data?.forEach((participationBadge: any, index: number) => {
      columnsList.push({
        Header: `${participationBadge.badgeType} Badge`,
        accessor: participationBadge.badgeType,
        width: 150,
        maxWidth: 150,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const newBadgeData: any = null;
          const newbadgeList: any = [];
          topic.badgeDetails?.forEach((badge: any, index: number) => {
            if (topic.badgeDetails[index]?.badgeTypeId == participationBadge.badgeTypeId) {
              newBadgeData == topic.badgeDetails[index];
              newbadgeList.push(topic.badgeDetails[index]);
            }
          });
          const assignedBadges = auxiliaryMethods.getAssignedBadges(newbadgeList) || "None";
          return cellData("badgeDetails", style, assignedBadges);
        }
      });
    });
    setUpdatedColumns(columnsList);
    return columnsList;
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({ row: { original: trainer } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, trainer?.serialNo);
        }
      },

      {
        Header: "Trainer Name",
        accessor: "name",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: trainer } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Regular,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(trainer)}>
                {trainer?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Base Location",
        accessor: "formattedBaseLocation",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: trainer } }: { row: { original: any } }) => {
          const style = {};
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(trainer) !== "None" ? (
                <CustomTooltip
                  hoverValue={trainer.formattedBaseLocation}
                  textValue={auxiliaryMethods.getUserFirstLocationName(trainer)}
                />
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Establishment Name",
        accessor: "establishmentName",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: trainer } }: { row: { original: any } }) => {
          const style = {};
          let establishmentName: string = trainer?.establishmentName;
          if (trainer?.establishmentName === "") {
            establishmentName = "None";
          }
          return cellData("establishmentName", style, establishmentName);
        }
      },
      {
        Header: "Verified Role",
        accessor: "verifiedRole",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const verifiedRole = auxiliaryMethods.getVerifiedRole(participant.roleDetails);
          return cellData("verifiedRole", style, participant?.verifiedRole);
        }
      },
      {
        Header: "Verified Qualification",
        accessor: "verifiedQualification",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          const verifiedQualification = auxiliaryMethods.getVerifiedQualification(
            participant.qualificationTitle
          );
          return cellData("verifiedQualification", style, participant?.verifiedQualification);
        }
      },
      {
        Header: "Topic Name",
        accessor: "topicName",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: participant } }: { row: { original: any } }) => {
          const style = {};
          // const assignedBadges = auxiliaryMethods.getTopics(participant.topicData) || "None";
          return cellData("topicName", style, participant?.topicName);
        }
      }
    ],
    []
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (trainerByTopicData && trainerByTopicData.length) > 0 ? (
        <View
          style={leftSidePanelCollapse ? styles.parentTableContainer : styles.parentTableContainer2}
        >
          <Table
            eventAnalytics="TrainerByTopic"
            columns={updatedColumns}
            data={trainerByTopicData}
            noDataMessage="No Trainer(s) under this Program"
            download={
              <TouchableOpacity onPress={() => downloadCsvList()}>
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name={IconNames.downloadList}
                    customStyle={styles.iconStyle}
                    onPress={() => downloadCsvList()}
                  />
                  <View>
                    <Text
                      testId="testId"
                      fontWeight={FontWeight.Bold}
                      textSize={TextSize.Small}
                      style={[
                        styles.textAlignment,
                        {
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }
                      ]}
                    >
                      {"Download"}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            }
          />
          {viewButtonDetails && (
            <View>
              <SocionModal
                modalVisible={viewButtonDetails}
                setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
                component={
                  <ViewUserDetails
                    onClose={() => {
                      setViewButtonDetails(false);
                    }}
                    selectedUserDetails={selectedUserDetails}
                    piiFilter={false}
                  />
                }
              />
            </View>
          )}
        </View>
      ) : (
        <>
          <View style={styles.noDataContainer}>
            <Text
              testId="testId"
              fontWeight={FontWeight.Bold}
              textSize={TextSize.Small}
              style={[styles.alignNoDataFound]}
            >
              {"No Trainer(s) under this Program."}
            </Text>
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  sessionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  noDataContainer: {
    height: 100
  },
  alignNoDataFound: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    padding: 18
  },
  parentTableContainer: {
    display: "flex",
    width: "calc(100vw - 100px)"
  },
  parentTableContainer2: {
    display: "flex",
    width: "calc(100vw - 290px)"
  }
});
