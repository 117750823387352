import { createIconSetFromIcoMoon } from "react-native-vector-icons";
import icoMoonConfig from "@socion-cordio/common/src/assets/icons/selection.json";
const CustomIcon = createIconSetFromIcoMoon(icoMoonConfig);
import React, { ReactElement } from "react";
import { View } from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
interface IProps {
  name: IconNames;
  customStyle?: any;
  size?: number;
  testID?: string;
  color?: string;
  onPress?: () => void;
}
export enum IconNames {
  checkBoxTicked = "checkBoxTicked",
  checkBoxUnTicked = "checkBoxUnTicked",
  indeterminateCheckBox = "indeterminateCheckBox",
  uploadImage = "uploadImg",
  fileVideo = "file-video",
  reports = "reports",
  tipping = "tipping",
  tick = "tick",
  back = "back",
  up = "up",
  down = "down",
  expandAll = "expandAll",
  close = "close",
  watchVideo = "watch",
  download = "download",
  rev = "rev",
  resources = "resources",
  calender = "calender",
  askTell = "askTell",
  home = "home",
  preview = "preview",
  crossCircle = "crossCircle",
  hamBurger = "hamBurger",
  personalInfo = "profile",
  changePassword = "changePassword",
  about = "about",
  privacyPolicy = "privacyPolicy",
  termsOfUse = "termsOfUse",
  settings = "settings",
  help = "help",
  notification = "notification",
  externalApps = "externalApps",
  cordioLogo = "cordioLogo",
  mobile = "mobile",
  userName = "userName",
  password = "password",
  baseLocation = "baseLocation",
  edit = "edit",
  email = "email",
  establishment = "establishment",
  share = "share",
  profileDownload = "profileDownload",
  sortIcon = "Arrow",
  search = "search",
  entity = "entity",
  program = "program",
  topics = "topics",
  session = "session",
  badges = "badges",
  verify = "verify",
  dashboard = "dashboard",
  qualificationPacks = "qualificationPacks",
  addRoleQualification = "addRoleQualification",
  deleteFile = "deleteFile",
  uploadFile = "uploadFile",
  uploadProfile = "uploadProfile",
  backToWorkspace = "backToWorkspace",
  refresh = "refresh",
  downloadList = "download",
  downloadListTwo = "downloadList",
  link = "link",
  penPaper = "penPaper",
  trainee = "trainee",
  paginationDown = "PaginationDown",
  paginationLeft = "PaginationLeft",
  paginationRight = "PaginationRight",
  secondaryAdd = "SecondaryAdd",
  primaryAdd = "PrimaryAdd",
  downArrow = "downArrow",
  leftArrow = "leftArrow",
  rightArrow = "rightArrow",
  circleTick = "circleTick",
  tickOnly = "tickOnly",
  filter = "filter",
  doubleArrowLeft = "doubleArrowLeft",
  doubleArrowRight = "doubleArrowRight",
  passwordEye = "passwordEye",
  schedule = "schedule",
  addMember = "Add-member",
  rectangle = "rectangle",
  imageFile = "imageFile",
  documentFile = "documentFile",
  videoFile = "videoFile",
  arrowOutlined = "arrowOutlined",
  arrowDropDown = "arrowDropDown",
  contentCopy = "contentCopy",
  socion = "socion",
  videoIcon = "videoIcon",
  upArrow = "upArrow",
  attachment = "attachment",
  smallAdd = "smallAdd",
  redAddCircle = "redAddCircle",
  downloadContent = "downloadContent",
  radioButtonChecked = "radio-button-checked",
  radioButtonUnChecked = "radio-button-unchecked",
  checkboxTick = "checkboxTick",
  editSdg = "editSdg",
  starOutline = "starOutline",
  fullColoredStar = "fullColoredStar",
  halfColoredStar = "halfColoredStar",
  fullColoredStar2 = "fullColoredStar2",
  warning = "warning",
  close2 = "close2",
  artifacts = "artifacts",
  tasks = "tasks",
  attendance = "attendance",
  files = "files",
  shareSecondary = "shareSecondary",
  publish = "publish",
  scanAttendance = "scanAttendance",
  sessionIcon = "sessionIcon",
  taskIcon = "taskIcon",
  attestationIcon = "attestationIcon",
  qpIcon = "qpIcon",
  attendanceSecondary = "attendanceSecondary",
  taskSecondary = "taskSecondary",
  qualificationPack = "qualificationPack",
  artefact = "artefact",
  defaultImage = "defaultImage",
  qrScan = "qrScan",
  paperClip = "paperClip",
  links = "links",
  qrCodePrimary = "qrCodePrimary",
  passwordEyeFilled = "passwordEyeFilled",
  scheduleWithDotClock = "scheduleWithDotClock",
  scheduleClock = "scheduleClock",
  clockGreenColour = "clockGreenColour",
  clockRedColour = "clockRedColour",
  clockGreenColourTwo = "clockGreenColourTwo",
  clockRedColourTwo = "clockRedColourTwo",
  logOut = "logOut",
  editSecondary = "editSecondary",
  additionalProfile = "additionalProfile",
  UserAccount = "UserAccount",
  exclamationCircle = "exclamationCircle",
  assessmentIcon = "assessmentIcon",
  administrator = "administrator",
  partnership = "partnership"
}
export default function Icon(props: IProps): ReactElement {
  const { name, onPress, customStyle = {}, size = 20, color = "black" } = props;
  return (
    <View>
      {/* Font awesome is setup */}
      {/* <FontAwesomeIcon icon={faCoffee} /> */}
      <CustomIcon onPress={onPress} size={size} name={name} color={color} style={customStyle} />
    </View>
  );
}
