import { EntityActions } from "@socion-cordio/common/src/modules/registry/entity/actions/actions";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

export const getSelectedEntityDetails = async () => {
  const entity = await LocalStorage.getStorage("selectedEntity");
  if (entity) {
    return entity;
  }
  return;
};

export const getSelectedEntityId = async () => {
  const entity: any = await getSelectedEntityDetails();
  console.log("getSelectedEntityDetails", entity);

  if (entity) {
    return entity?.osid;
  }
  return;
};

export const getSelectedEntityProgramDetails = async () => {
  const program = await LocalStorage.getStorage("selectedEntityProgram");
  if (program) {
    return program;
  }
  return;
};

export const getSelectedEntityProgramId = async () => {
  const program: any = await getSelectedEntityProgramDetails();
  if (program) {
    return program?.osid;
  }
  return;
};

export const dispatchEntityList = (dispatch: any) => {
  dispatch(EntityActions.getEntities({ userId: UserHelper.getUserId() }));
};

export const dispatchAllEntityList = (dispatch: any) => {
  dispatch(EntityActions.getAllEntities({ userId: UserHelper.getUserId() }));
};

export const dispatchEntityDetails = async (dispatch: any, entity?: any) => {
  const selectedEntityId = await getSelectedEntityId();
  const entityId = entity ? entity?.osid : selectedEntityId;
  if (entityId) {
    dispatch(EntityActions.getEntity(entityId));
  }
};

export const dispatchEntityAdmins = async (dispatch: any, entity?: any) => {
  const selectedEntity: any = await getSelectedEntityDetails();
  const entityId = entity ? entity?.osid : selectedEntity?.osid || selectedEntity?.entityId;
  if (entityId) {
    dispatch(EntityActions.getEntityAdmins({ entityId, userId: selectedEntity.userId }));
  }
};

export const dispatchEntityPrograms = async (dispatch: any, entity?: any) => {
  const selectedEntityId = await getSelectedEntityId();
  const entityId = entity ? entity?.osid : selectedEntityId;
  if (entityId) {
    dispatch(
      EntityActions.getEntityPrograms({ parentId: entityId, parentType: REGISTRY_TYPE.entity })
    );
  }
};

export const dispatchEntityProgramPartners = async (dispatch: any, selectedProgramId?: string) => {
  const selectedEntityId = await getSelectedEntityId();
  const selProgramId = await getSelectedEntityProgramId();
  const programId = selectedProgramId ? selectedProgramId : selProgramId;
  if (selectedEntityId && programId) {
    dispatch(
      EntityActions.getEntityProgramPartners({
        entityId: selectedEntityId,
        partnerType: REGISTRY_TYPE.entity,
        programId: programId
      })
    );
  }
};

export const dispatchEntityPartners = async (dispatch: any, entity?: any) => {
  const selectedEntityId = await getSelectedEntityId();
  const entityId = entity ? entity?.osid : selectedEntityId;
  if (entityId) {
    dispatch(
      EntityActions.getEntityPartners({ entityId: entityId, partnerType: REGISTRY_TYPE.entity })
    );
  }
};

export const EntityHelper = {
  getSelectedEntityDetails,
  getSelectedEntityId,
  dispatchEntityList,
  dispatchAllEntityList,
  dispatchEntityDetails,
  dispatchEntityPrograms,
  dispatchEntityProgramPartners,
  dispatchEntityPartners
};
