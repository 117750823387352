import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import Moment from "moment";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import {
  qualificationPackEndPoints,
  programEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { GenerateQR } from "@socion-cordio/common/src/components/atoms/generateQR";
import { DownloadQR } from "@socion-cordio/common/src/components/atoms/downloadQR";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { useLocation, useHistory } from "react-router-dom";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useDispatch, useSelector } from "react-redux";
import { QualificationPackActions } from "@socion-cordio/common/src/modules/qualificationPacks/actions/actions";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import PublishRoleBadge from "@socion-cordio/common/src//components/organisms/qualification-packs/publishRoleBadge";
import PublishedRoleBadge from "@socion-cordio/common/src//components/organisms/qualification-packs/publishedRoleBadge";
import _ from "lodash";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {}

const OPTIONS_SELECTION_NAME = [
  {
    label: "Session",
    value: "Session"
  },
  {
    label: "Self Learning",
    value: "Self Learning"
  }
];

export default function ViewQualificationPackDetailsPage(props: Props): ReactElement {
  const [packDescription, setPackDescription] = useState("");
  const [qualificationPackName, setQualificationPackName] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [selectionList, setSelectionList] = useState([]);
  const [newSelectionList, setNewSelectionList] = useState([]);
  const [isEditSelectionNameEnabled, setIsEditSelectionNameEnabled] = useState(false);
  const [selectionListCopy, setSelectionListCopy] = useState([]);
  const [qpNameCheckValue, setQpNameCheckValue] = useState(null);
  const [temp, setTemp] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [chipRemove, setChipRemove] = useState(false);
  const [qpNameCheck, setQpNameCheck] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(true);
  const [selectedItems, setSelectedItems] = useState(false);
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [isTopicDeleted, setIsTopicDeleted] = useState(false);
  const [emptyListCheck, setEmptyListCheck] = useState(false);
  const [removeSelection, setRemoveSelection] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [duplicateTopicCheck, setDuplicateTopicCheck] = useState(false);
  const [isCancelSelectionClicked, setIsCancelSelectionClicked] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showPublishedModal, setShowPublishedModal] = useState(false);
  const [bundleId, setBundleId] = useState(null);
  const [qualificationPackDetails, setQualificationPackDetails] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));

  const qrCodeRef: any = useRef();
  const history: any = useHistory();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const {
    qualificationPackData: qpList,
    bundleData: qpDetails,
    loading: { getBundle: loading }
  } = useSelector((state: any) => state.bundleData);
  // const qualificationPackDetails = qpDetails?.response;
  const qualificationPackList = location.state.qualificationPackList;
  const userRolesProgram: any = useSelector((state: any) => state?.userRolesProgram);
  const userRoles = userRolesProgram?.userRolesData?.response;

  useEffect(() => {
    // setQualificationPackName(qualificationPackDetails?.bundle?.name);
    // setPackDescription(qualificationPackDetails?.bundle?.description);
  }, [isEditDetails, qualificationPackDetails]);

  useEffect(() => {
    getQualificationPackList();
    getQualificationBundle();
  }, []);

  useEffect(() => {
    if (showRemoveModal || showPublishModal || showPublishedModal) disableScroll();
    else enableScroll();
  }, [showRemoveModal, showPublishModal, showPublishedModal]);

  const getQualificationPackList = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const { program } = programDetails;
    setSelectedProgram(program);
    dispatch(QualificationPackActions.getQualficationPackList(program.id));
  };

  const getQualificationBundle = async () => {
    // dispatch(QualificationPackActions.getBundle(location.state.qpBundleId));
    try {
      const response = await ApiClient.get(
        qualificationPackEndPoints.getQualificationPackBundleDetails(location.state.qpBundleId)
      );
      if (response.response) {
        formatSelectionList(response.response.bundleTopiMapping);
        setQualificationPackName(response.response.bundle.name);
        setPackDescription(response.response.bundle.description);
        setBundleId(response.response.bundle.id);
        setQualificationPackDetails(response?.response);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const editTopicDetialsHandler = () => {
    // setIsEditable(true);
    setIsEditDetails(true);
  };

  const cancelDetailsHandler = () => {
    // setIsEditable(false);
    setIsEditDetails(false);
    setQpNameCheck(false);
  };

  const handleDropdown = (item: any, id: number, e: any) => {
    const SELECTACTION = "select-option";
    const REMOVEVALUE = "remove-value";
    setTemp(true);
    setOpenDropdown(false);
    let temp = [];
    // temp = [...selectionList];
    temp = [...newSelectionList];
    if (e.action === SELECTACTION) {
      if (item) {
        let flag = 0;
        temp.map((listId: any) => {
          listId.selectionId === id
            ? listId.topics.map((listItem: any) => {
                if (listItem.topicName === e.option.label) {
                  flag = 1;
                }
              })
            : listId;
        });
        temp.map((listId: any) => {
          listId.selectionId === id && flag === 0
            ? listId.topics.push({
                topicName: e.option.label,
                topicId: e.option.value,
                label: e.option.label,
                value: e.option.value,
                isTopicActive: true
              })
            : listId;
        });
        // setSelectionList(temp);
        setNewSelectionList(temp);
      }
      setSelectionListCheckFlagToFalse();
    }
    // if (e.action === REMOVEVALUE)
    else {
      const selectedTopic = e.removedValue;
      removeChip(selectedTopic, id);
      setSelectionListCheckFlagToFalse();
    }
    //  else {
    //   /* diplay error */
    //   // toast.error("Something went wrong.");
    // }
    setOpenDropdown(true);
  };

  const formatSelectionList = (list: any) => {
    // setIsCancelSelectionClicked(true);
    if (list === undefined) return setNewSelectionList([]); // setSelectionList([]);
    if (list !== undefined && list.length != 0) {
      const output = Object.values(
        list?.reduce((acc: any, cur: any) => {
          acc[cur.selectionId] = acc[cur.selectionId] || {
            selectionId: cur.selectionId,
            topics: [],
            name: cur.selectionName,
            selectionType: [{ label: cur.selectionType, value: cur.selectionType }]
          };
          acc[cur.selectionId].topics.push({
            topicName: cur.topicName,
            topicId: cur.topicId,
            isTopicActive: cur.isTopicActive,
            label: cur.topicName,
            value: cur.topicId
          });
          return acc;
        }, {})
      );
      // setSelectionList(output);
      setNewSelectionList(output);
      setIsCancelSelectionClicked(false);
    } else {
      // setSelectionList([]);
      setNewSelectionList([]);
      setIsCancelSelectionClicked(false);
    }
  };

  const isTopicPresentHandler = (topicList: any) => {
    let bundleTopicList: any = [];
    // setSelectionList([]);
    setNewSelectionList([]);
    bundleTopicList = qualificationPackDetails?.bundleTopiMapping;
    const temp: any = [];
    const list = bundleTopicList?.forEach((element: any) => {
      element.isTopicActive = false;
      topicList?.forEach((ele: any) => {
        if (element.topicId === ele.id) {
          element.isTopicActive = true;
        }
      });
    });
    setSelectionListCopy(bundleTopicList);
    formatSelectionList(bundleTopicList);
  };

  const handleOptions = async () => {
    const payload = {
      programId: programDetails.program.id
    };
    // all topic lists
    // setSelectionList([]);
    setNewSelectionList([]);
    let topicListResponse = await ApiClient.post(
      qualificationPackEndPoints.getBundleTopicList(),
      payload
    );
    if (topicListResponse.responseCode === HTTP_STATUS_CODES.ok) {
      const listData = topicListResponse.response.map((list: any, index: number) => ({
        value: list.id,
        label: list.name
      }));
      setTopicList(listData);
      setLoadingMain(false);
      qualificationPackDetails !== undefined
        ? isTopicPresentHandler(topicListResponse.response)
        : null;
    } else {
      toast.error(topicListResponse.message);
      setLoadingMain(false);
      setFailed(true);
    }
  };

  useEffect(() => {
    if (qualificationPackDetails !== null) {
      handleOptions();
    }
  }, [qualificationPackDetails, qualificationPackList]);

  const isDeletedTopicHandler = (detailsList: any) => {
    const list = detailsList.map((item: any) => item.topics);
    const flattenedList = [].concat.apply([], list);
    const filterList = flattenedList.map((item: any) => item.isTopicActive);
    const isFalsePresent = filterList.includes(false);
    return isFalsePresent;
  };

  const handleUpdateSelections = async () => {
    const emptyCheckList = isEmptyArrayExists(newSelectionList);
    const isDuplicatePresent = checkDuplicateTopic(newSelectionList);
    const isDeleteTopicPresent = isDeletedTopicHandler(newSelectionList);

    if (!isDeleteTopicPresent) {
      // Is the selectionList has topic any deleted topic
      if (emptyCheckList.length === 0) {
        // when selection list has empty topics array
        if (isDuplicatePresent) {
          // duplicate topicIDs
          setDuplicateTopicCheck(true);
          setEmptyListCheck(false);
          setIsTopicDeleted(false);
        } else {
          // No duplicate topicIDs and
          // list has no empty selectionList content
          setIsCancelSelectionClicked(true);
          setSelectionListCheckFlagToFalse();
          submitSelectionListHandler();
        }
      } else {
        // list has empty selectionList
        setDuplicateTopicCheck(false);
        setEmptyListCheck(true);
        setIsTopicDeleted(false);
      }
    } else {
      // list which has topic which are deleted
      setDuplicateTopicCheck(false);
      setEmptyListCheck(false);
      setIsTopicDeleted(true);
    }
  };

  const setSelectionListCheckFlagToFalse = () => {
    setDuplicateTopicCheck(false);
    setEmptyListCheck(false);
    setIsTopicDeleted(false);
  };

  const isEmptyArrayExists = (list: any) => {
    return list.filter((item: any) => item.topics.length === 0);
  };

  const checkDuplicateTopic = (selectionList: any) => {
    //returns array of arrays of topicIds
    const filteredArray = selectionList.map((item: any) =>
      item.topics.map((ele: any) => ele.topicId)
    );
    // flattening the topicIds array
    var newArray = Array.prototype.concat.apply([], filteredArray);
    // finding if any duplicate present, returns true if yes
    const duplicateArr = newArray.some((val: any, i: any) => newArray.indexOf(val) !== i);
    return duplicateArr;
  };

  const submitSelectionListHandler = async () => {
    try {
      setIsCancelSelectionClicked(true);
      const selectionTempList: any = [];
      // selectionList
      newSelectionList.map((list: any) => {
        list.topics.map((value: any) =>
          selectionTempList.push({
            bundle_topic_mapping_id: 0,
            programId: programDetails.program.id,
            selectionId: list.selectionId,
            topicId: value.topicId,
            topicName: value.topicName,
            selectionType: selectedProgram.selfLearningEnabled
              ? list.selectionType[0].value
              : "Session",
            selectionName: list.name
          })
        );
      });
      const payload = [...selectionTempList];
      let updateResponse = await ApiClient.put(
        qualificationPackEndPoints.getQualificationPackBundleDetails(bundleId),
        payload
      );
      if (updateResponse.responseCode === HTTP_STATUS_CODES.ok) {
        updateTelemetry(
          qualificationPackDetails?.bundle,
          "QP Selection Edited",
          "QP_Selection_Edited"
        );
        toast.success("Selections updated successfully");
        setIsCancelSelectionClicked(false);
        getQualificationBundle();
        getQualificationPackList();
      } else {
        toast.error(updateResponse.message);
      }
      setTemp(false); // remove the cancel and save buttons
    } catch (error) {
      toast.error(error);
      setIsCancelSelectionClicked(false);
      setTemp(false); // remove the cancel and save buttons
    }
  };

  const handleUpdate = async () => {
    const qpNameList = qualificationPackList.map((item: any) => item.bundle.name);
    const nameExist = qpNameList.includes(qualificationPackName.trim());
    const nameExistDetails = qualificationPackList.filter(
      (item: any) => item.bundle.name === qualificationPackName.trim()
    );
    const currentName =
      qualificationPackName.trim() === qualificationPackDetails?.bundle?.name.trim();
    nameExist
      ? currentName
        ? [setQpNameCheck(false), submitHandler()] // No change reqd.
        : [
            setQpNameCheck(true),
            setQpNameCheckValue(nameExistDetails[0]?.bundle?.name.trim())
            // name exists
          ]
      : submitHandler(); // name change allowed
  };

  const submitHandler = async () => {
    const payload = {
      programId: programDetails.program.id,
      description: packDescription,
      name: qualificationPackName.trim()
    };
    let updateResponse = await ApiClient.put(
      qualificationPackEndPoints.updateQualificationPack(bundleId),
      payload
    );
    if (updateResponse.responseCode === HTTP_STATUS_CODES.ok) {
      updateTelemetry(updateResponse?.response, "QP Edited", "QP_Edited");
      toast.success("Qualification Pack details updated successfully");
      getQualificationPackList();
      getQualificationBundle();
      setLoadingMain(true);
      setIsEditDetails(false);
      setQpNameCheck(false);
    } else {
      toast.error(updateResponse.message);
      setLoadingMain(false);
      setIsEditDetails(false);
      setQpNameCheck(false);
    }
  };

  const handleDeleteBundle = async () => {
    try {
      setLoadingMain(true);
      let updateResponse = await ApiClient.delete(
        qualificationPackEndPoints.updateQualificationPack(bundleId),
        {}
      );
      const bundleData = qualificationPackDetails?.bundle;
      updateTelemetry(bundleData, "QP Deleted", "QP_Deleted");
      toast.success("Qualification Pack deleted successfully");
      setLoadingMain(false);
      history.push(`${routeNames.app}${routeNames.QUALIFICATIONPACKS}`, {});
    } catch (error) {
      toast.error(error);
      setShowRemoveModal(false);
      setLoadingMain(false);
    }
  };

  const handleAddSelection = () => {
    setTemp(true);
    selectionList.push({ selectionId: selectionList.length + 1, topics: [] });
    setSelectedItems(true);
    setSelectionListCheckFlagToFalse();
  };

  const handlerAddSelectionNew = () => {
    const list = [...newSelectionList];
    list.push({
      selectionId: newSelectionList.length + 1,
      name: "",
      updatedName: "",
      topics: [],
      isEditName: true,
      isEditClicked: false,
      selectionType: [OPTIONS_SELECTION_NAME[0]]
    });
    // setSelectionType([OPTIONS_SELECTION_NAME[0]]);
    setNewSelectionList(list);
    setTemp(true);
  };

  const addNewSelection = (item: any, key: number) => {
    const list = [...newSelectionList];
    list[key] = { ...list[key], isEditName: false };
    setNewSelectionList(list);
  };

  const selectionNameHandler = (name: string, id: number, item: any) => {
    const values = [...newSelectionList];
    if (item.isEditName && item.isEditClicked) {
      values[id] = { ...values[id], updatedName: name };
    } else {
      values[id] = { ...values[id], name: name };
    }
    setNewSelectionList(values);
  };

  const dropDownHandlerselectionType = (item: any, e: any, key: number) => {
    setTemp(true);
    const values = [
      {
        value: item.value,
        label: item.value
      }
    ];
    const list = [...newSelectionList];
    list[key] = { ...list[key], selectionType: values };
    setNewSelectionList(list);
  };

  const editSelectionHandlerName = (item: any, key: number) => {
    setIsEditSelectionNameEnabled(true);
    const list = [...newSelectionList];
    list[key] = { ...list[key], isEditName: true, isEditClicked: true, updatedName: item.name };
    setNewSelectionList(list);
  };

  const cancelEditNameHandler = (item: any, key: number) => {
    const list = [...newSelectionList];
    list[key] = { ...list[key], isEditName: false, isEditClicked: false, updatedName: "" };
    setNewSelectionList(list);
    setIsEditSelectionNameEnabled(false);
  };

  const editNewNameHandler = (item: any, key: number) => {
    setTemp(true);
    const list = [...newSelectionList];
    list[key] = {
      ...list[key],
      isEditName: false,
      isEditClicked: false,
      name: item.updatedName,
      updatedName: ""
    };
    setNewSelectionList(list);
    setIsEditSelectionNameEnabled(false);
  };

  const cancelSelectionHandler = () => {
    setIsCancelSelectionClicked(true);
    setTimeout(() => {
      formatSelectionList([]);
      selectionListCopy.length === 0 ? setIsCancelSelectionClicked(false) : null;
      const selectionCopiedList = [...selectionListCopy];
      formatSelectionList(selectionCopiedList);
      setTemp(false); // remove the cancel and save buttons
    }, 100);
  };

  const removeChip = (topic: any, selectionId: any) => {
    // let tempList: any = [...selectionList];
    let tempList: any = [...newSelectionList];
    let newList = tempList[selectionId - 1];
    let topicsList = newList.topics.filter((item: any) => item.value !== topic.value);
    newList.topics = topicsList;
    // tempList[selectionId - 1].topics=topicsList
    // setSelectionList(tempList);
    setNewSelectionList(tempList);
    setChipRemove(true);
    setTemp(true);
    setSelectionListCheckFlagToFalse();
  };

  const handleRemoveSelection = (selectionId: number) => {
    // let newList: any = [...selectionList];
    let newList: any = [...newSelectionList];
    newList.map((list: any, index: number) => {
      list.selectionId === selectionId ? newList.splice(index, 1) : list;
    });

    newList.map((list: any, index: number) => {
      index >= selectionId - 1 ? (newList[index].selectionId = index + 1) : list;
    });
    updateSelectedList(newList);
    setSelectionListCheckFlagToFalse();
  };

  const updateSelectedList = (newList: any) => {
    setTimeout(() => {
      // setSelectionList([]);
      // setSelectionList(newList);
      setNewSelectionList([]);
      setNewSelectionList(newList);
    }, 10);
    setRemoveSelection(true);
    setTemp(true);
  };

  useEffect(() => {
    setRemoveSelection(false);
  }, [removeSelection]);

  useEffect(() => {
    setSelectedItems(false);
  }, [selectedItems]);

  useEffect(() => {
    setChipRemove(false);
  }, [chipRemove]);

  const updateTelemetry = async (data: any, eventType: string, eventAnalytics: string) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,
      bundleId: data.id,
      bundleName: data.name,
      entityId: null,
      entityName: null,
      noOfSelections: selectionList.length
    };
    AddTelemetryService(body, programDetails, user, true);
    googleWebAnalytics(eventAnalytics, "Telemetry_Event", "Program");
  };

  const toastHandler = (message: string) => {
    toast.error(message);
  };

  const customStyles = {
    control: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: "white",
      marginTop: 2,
      border: "none",
      fontFamily: FontFamily.Medium,
      ":hover": {
        border: "none",
        marginTop: 2
      }
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: 150,
      fontFamily: FontFamily.Medium,
      "::-webkit-scrollbar": {
        width: 5
      },
      "::-webkit-scrollbar-track": {
        background: "#D1D1D1",
        borderRadius: 10
      },
      "::-webkit-scrollbar-thumb": {
        background: "#808080",
        borderRadius: 10
      }
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      paddingLeft: 0,
      fontFamily: FontFamily.Medium
    }),
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor:
          "isTopicActive" in data ? (data.isTopicActive ? "white" : "#B7504A") : "white",
        borderRadius: 50,
        border: "1px solid #B7504A",
        padding: 2,
        paddingRight: 6,
        maxWidth: 500,
        fontFamily: FontFamily.Medium
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      fontSize: 14,
      fontFamily: FontFamily.Medium
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
      ...styles,
      fontFamily: FontFamily.Medium
    }),
    multiValueRemove: (styles: any, { data }: any) =>
      userRoles.includes("PROGRAM_ADMIN")
        ? {
            ...styles,
            color: "white",
            backgroundColor: "black",
            borderRadius: 50,
            marginTop: 2,
            marginLeft: 7,
            marginRight: 3,
            height: 22
          }
        : { ...styles, display: "none" }
  };

  useEffect(() => {
    if (emptyListCheck) {
      setSelectionListCheckFlagToFalse();
      toast.error("Selection cannot be empty");
    }
  }, [emptyListCheck]);

  useEffect(() => {
    if (duplicateTopicCheck) {
      setSelectionListCheckFlagToFalse();
      toast.error("A topic can be tagged only once");
    }
  }, [duplicateTopicCheck]);

  useEffect(() => {
    if (isTopicDeleted) {
      setSelectionListCheckFlagToFalse();
      toast.error("One or more topics tagged in selections are deleted");
    }
  }, [isTopicDeleted]);

  const handlePublish = () => {
    setShowPublishModal(true);
  };

  const handleRoleBadges = () => {
    setShowPublishedModal(true);
  };

  const getFileName = (url: string) => {
    let name = url.split("/");
    return name[name.length - 1];
  };

  const onDownloadPress = async (url: any, name: string) => {
    // fetch(url)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.download = `QRCode${bundleId}`;
    //     link.click();
    //   })
    //   .catch(console.error);

    let fileName = getFileName(url);
    const response = await ApiClient.get(
      programEndPoints.getPresignedUrl("qualification-pack/qp", fileName)
    );
    if (response) {
      var link = document.createElement("a");
      link.href = response;
      link.download = `QRCode${bundleId}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderAddRemoveButton = (iconName: string, imageStyle: any) => (
    <Image
      testId="add"
      source={require(`@socion-cordio/common/src/assets/images/${iconName}.svg`)}
      imageStyle={imageStyle}
    />
  );

  return (
    <View>
      <ToastContainer />
      {loadingMain ? (
        <Loader customLoadingContainer={styles.loaderContainer} />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <View style={styles.headerContainer}>
              <View style={styles.header}>
                <View style={styles.iconContainerHeader}>
                  <TouchableOpacity>
                    <Icon
                      testID="down"
                      name={IconNames.down}
                      customStyle={styles.backArrowTitleIcon}
                      onPress={() => {
                        history.push(`${routeNames.app}${routeNames.QUALIFICATIONPACKS}`);
                      }}
                    />
                  </TouchableOpacity>
                </View>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={styles.headerText}
                >
                  Qualification pack details
                </Text>
                <Text
                  onPress={() => {}}
                  fontWeight={FontWeight.Regular}
                  testId="text"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerTextSupport, styles.alignIcon]}
                >
                  {">"}
                </Text>
                <Text
                  onPress={() => {}}
                  fontWeight={FontWeight.Regular}
                  testId="text"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.headerTextSupport]}
                >
                  {qualificationPackName}
                </Text>
              </View>
            </View>
            <View
              style={
                !isEditDetails
                  ? styles.topicDetailsContainer
                  : [styles.topicDetailsContainer, styles.greenBorderColor]
              }
            >
              {qualificationPackDetails?.bundle?.published && (
                <View style={styles.mr30}>
                  <Image
                    imageStyle={styles.qrCodeImage}
                    testId="qrCode"
                    source={qualificationPackDetails?.bundle?.bundleQrCodeImageUrl}
                  />
                  <TouchableOpacity>
                    <View style={styles.flexConatiner}>
                      <View style={styles.mr7}>
                        <Icon
                          testID="down"
                          name={IconNames.downloadListTwo}
                          customStyle={styles.download}
                          onPress={() =>
                            onDownloadPress(
                              qualificationPackDetails?.bundle?.bundleQrCodeImageUrl,
                              qualificationPackName
                            )
                          }
                        />
                      </View>
                      <View style={{ marginTop: -7 }}>
                        <Text
                          onPress={() => {}}
                          fontWeight={FontWeight.Regular}
                          testId="text"
                          textSize={TextSize.Small}
                          textStyle={styles.downloadQR}
                          onPress={() =>
                            onDownloadPress(
                              qualificationPackDetails?.bundle?.bundleQrCodeImageUrl,
                              qualificationPackName
                            )
                          }
                        >
                          Download QR
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              )}
              <View style={styles.qpDetials}>
                {!isEditDetails && (
                  <View>
                    <View style={styles.labelHeaderContainer}>
                      <View style={[styles.topicHeaderAlignmnet, styles.labelTextContainer]}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerAlignment, styles.fontSize16]}
                        >
                          {"Qualification Pack Name"}
                        </Text>
                      </View>

                      <View
                        style={[
                          styles.topicNameHeaderAlignment,
                          styles.labelTextValueContainer,
                          { flexDirection: "row", justifyContent: "space-between" }
                        ]}
                      >
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.fontSize14, styles.alignQpName]}
                        >
                          {qualificationPackName}
                        </Text>

                        {!isEditDetails && !temp && userRoles?.includes("PROGRAM_ADMIN") && (
                          <View style={{ flexDirection: "row" }}>
                            {!qualificationPackDetails?.bundle?.published && (
                              <View style={styles.deleteTopicContainerMain}>
                                <TouchableOpacity onPress={() => setShowRemoveModal(true)}>
                                  <View style={styles.deleteTopicContainer}>
                                    <Icon
                                      testID="delete"
                                      name={IconNames.deleteFile}
                                      customStyle={[styles.alignButton]}
                                    />
                                  </View>
                                </TouchableOpacity>
                              </View>
                            )}
                            {qualificationPackDetails?.bundleTopiMapping?.length > 0 &&
                              !qualificationPackDetails?.bundle?.published && (
                                <View style={styles.ml12}>
                                  <TouchableOpacity onPress={handlePublish}>
                                    <View>
                                      <Icon
                                        testID="delete"
                                        name={IconNames.publish}
                                        customStyle={[styles.alignButton]}
                                      />
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              )}
                            <View style={{}}>
                              {/* {!isEditable && ( */}
                              <TouchableOpacity
                                onPress={() => {
                                  editTopicDetialsHandler();
                                }}
                              >
                                <Icon
                                  testID="edit"
                                  name={IconNames.penPaper}
                                  customStyle={styles.penPaperIcon}
                                />
                              </TouchableOpacity>
                              {/* )} */}
                            </View>
                          </View>
                        )}
                      </View>
                    </View>

                    <View style={styles.labelHeaderContainer}>
                      <View style={[styles.topicHeaderAlignmnet, styles.labelTextContainer]}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerAlignment, styles.fontSize16]}
                        >
                          Description
                        </Text>
                      </View>
                      <View
                        style={[styles.topicNameHeaderAlignment, styles.labelTextValueContainer]}
                      >
                        <ScrollContainer
                          height="60px"
                          scrollable={
                            qualificationPackDetails?.bundle?.description?.length > 150
                              ? true
                              : false
                          }
                          width="80%"
                        >
                          <Text
                            fontWeight={FontWeight.Light}
                            testId="addRoleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.headerText, styles.fontSize14]}
                          >
                            {packDescription}
                          </Text>
                        </ScrollContainer>
                      </View>
                    </View>
                  </View>
                )}
                {isEditDetails && (
                  <View style={[styles.qpDetials]}>
                    <View style={[styles.userInputContainer]}>
                      <UserInputSecondary
                        textValue="Qualification Pack Name"
                        handleChange={(qualificationPackName: string) => {
                          setQualificationPackName(qualificationPackName);
                        }}
                        handleBlur={() => {}}
                        value={qualificationPackName}
                        placeholder="Qualification Pack Name"
                        name="enterQpName"
                        id="enterQpName"
                        noFormik
                        userStyle={[styles.inputStylingAlignment]}
                        textInputContainerStyles={styles.titleInputContainerStyles}
                        userStyleText={styles.textHeaderField}
                      />
                      {qpNameCheck && (
                        <View style={[styles.errorContainer, styles.alignErrorQpName]}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="internal"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, styles.errorText]}
                          >
                            {`Qualification Pack ${qpNameCheckValue} already exists!`}
                          </Text>
                        </View>
                      )}
                    </View>

                    <View style={styles.userInputContainer}>
                      <UserInputTextArea
                        textValue="Description"
                        label="Description"
                        placeholder="Description"
                        name="Description"
                        id="description"
                        handleChange={(description: string) => {
                          setPackDescription(description);
                        }}
                        handleBlur={() => {}}
                        value={packDescription}
                        numberOfLines={3}
                        multiline={true}
                        noFormik
                        userStyle={[styles.inputStylingAlignment]}
                        userStyleText={styles.textHeaderField}
                        titleInputContainerStyles={[styles.titleInputContainerStyles]}
                        textFieldContianerAlign={styles.textFieldContianerAlign}
                      />
                    </View>
                  </View>
                )}
                <View>
                  <View style={[styles.labelHeaderContainer, { marginVertical: 2 }]}>
                    <View
                      style={[
                        styles.topicHeaderAlignmnet,
                        [!isEditDetails ? styles.labelTextContainer : { flex: 31 }]
                      ]}
                    >
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerAlignment, styles.fontSize16]}
                      >
                        Created on
                      </Text>
                    </View>
                    <View style={[styles.labelTextValueContainer]}>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[
                          styles.headerText,
                          styles.headerAlignment,
                          styles.fontSize14,
                          styles.textColorBlack
                        ]}
                      >
                        {Moment(qualificationPackDetails?.bundle?.createdAt).format("DD MMM YY")}
                      </Text>
                    </View>
                  </View>
                </View>
                <View>
                  <View style={[styles.labelHeaderContainer, { marginTop: 24 }]}>
                    <View
                      style={[
                        styles.topicHeaderAlignmnet,
                        [!isEditDetails ? styles.labelTextContainer : { flex: 31 }]
                      ]}
                    >
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText, styles.headerAlignment, styles.fontSize16]}
                      >
                        Status
                      </Text>
                    </View>
                    <View style={[styles.labelTextValueContainer]}>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="addRoleText"
                          textSize={TextSize.Small}
                          textStyle={[styles.publishStatus, styles.textColorBlack]}
                        >
                          {qualificationPackDetails?.bundle?.published == true
                            ? "Published"
                            : "Draft"}
                        </Text>
                        {qualificationPackDetails?.bundle?.published && (
                          <>
                            <View style={styles.publishedDate}>
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="addRoleText"
                                textSize={TextSize.Small}
                                textStyle={[styles.publishDate]}
                              >
                                ({" "}
                                {Moment(qualificationPackDetails?.bundle?.publishedDate).format(
                                  "DD MMM YY"
                                )}{" "}
                                )
                              </Text>
                            </View>
                            <View style={styles.roleBadgesLink}>
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="addRoleText"
                                textSize={TextSize.Small}
                                textStyle={[styles.publishStatus, styles.textColorBlue]}
                                onPress={() => handleRoleBadges()}
                              >
                                Role Badges
                              </Text>
                            </View>
                          </>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.footerButtonsTopicDetailsContainer}>
                  <View style={styles.editButtonsContainer}>
                    {isEditDetails && (
                      <View style={styles.editIconContainer}>
                        <Button
                          type={ButtonType.Secondary}
                          buttonStyles={[styles.submitbutton, styles.alignEditButtons]}
                          textStyles={styles.fontSize14}
                          title="Cancel"
                          onPress={() => cancelDetailsHandler()}
                        />
                        <Button
                          type={ButtonType.Primary}
                          buttonStyles={[styles.submitbutton, styles.alignEditButtons]}
                          textStyles={styles.fontSize14}
                          title="Save"
                          onPress={() => handleUpdate()}
                          disabled={qualificationPackName === "" || packDescription === ""}
                        />
                      </View>
                    )}
                  </View>
                </View>
              </View>
            </View>

            {userRoles?.includes("PROGRAM_ADMIN") && (
              <View style={[styles.header, styles.alignAddSelectionsContainer]}>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.fontSize14]}
                >
                  {"Add Selections"}
                </Text>
                <View>
                  <TouchableOpacity
                    onPress={() => handlerAddSelectionNew()}
                    disabled={isEditSelectionNameEnabled}
                  >
                    {renderAddRemoveButton("primary_add", {
                      width: 30,
                      height: 30,
                      marginLeft: 10
                    })}
                  </TouchableOpacity>
                </View>
              </View>
            )}

            {isCancelSelectionClicked ? (
              <Loader customLoadingContainer={styles.customLoadingContainerSelectionList} />
            ) : (
              <>
                {/* <ScrollContainer
                  height="400px"
                  scrollable={newSelectionList?.length > 2 ? true : false}
                > */}
                {newSelectionList.map((element: any, key: number) => (
                  <View key={key} style={styles.selectionsContainer}>
                    {element.isEditName ? (
                      <View style={styles.selectionContainer}>
                        <UserInputSecondary
                          // textValue="Enter Selection name"
                          handleChange={(name: string) => {
                            selectionNameHandler(name, key, element);
                          }}
                          handleBlur={() => {}}
                          value={
                            element.isEditName && element.isEditClicked
                              ? element.updatedName
                              : element?.name || ""
                          }
                          placeholder="Enter Selection name"
                          name="enterSelectionName"
                          id="enterSelectionName"
                          noFormik
                          userStyle={[styles.inputStylingAlignment]}
                          textInputContainerStyles={{ marginTop: 0 }}
                        />
                        {element.isEditName && element.isEditClicked ? (
                          <View style={styles.selectionNameEditIconContainer}>
                            <TouchableOpacity
                              onPress={() => cancelEditNameHandler(element, key)}
                              disabled={element.updatedName === ""}
                            >
                              <Icon
                                testID="cancel"
                                name={IconNames.close}
                                customStyle={{
                                  fontSize: 16,
                                  opacity: element.updatedName === "" ? 0.2 : 0.6
                                }}
                              />
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => editNewNameHandler(element, key)}
                              disabled={element.updatedName === ""}
                            >
                              <Icon
                                testID="tick"
                                name={IconNames.tickOnly}
                                customStyle={{
                                  fontSize: 16,
                                  opacity: element.updatedName === "" ? 0.2 : 0.6
                                }}
                              />
                            </TouchableOpacity>
                          </View>
                        ) : (
                          <View style={styles.selectionsIconsContainer}>
                            <TouchableOpacity
                              onPress={() => {
                                addNewSelection(element, key);
                              }}
                              style={[
                                { marginLeft: 25 },
                                element.name === "" ? { opacity: 0.5 } : { opacity: 1 }
                              ]}
                              disabled={element.name === ""}
                            >
                              {renderAddRemoveButton("add", {
                                width: 20,
                                height: 20
                              })}
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => {
                                handleRemoveSelection(element.selectionId);
                              }}
                            >
                              {renderAddRemoveButton("remove", {
                                width: 20,
                                height: 20
                              })}
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                    ) : (
                      <View style={{}}>
                        <View style={styles.selectionHeaderContainer}>
                          <View style={styles.selectionNameContainer}>
                            <Text
                              fontWeight={FontWeight.Bold}
                              testId="name"
                              textSize={TextSize.Small}
                              textStyle={[styles.headerText]}
                            >
                              {element.name}
                            </Text>
                          </View>
                          <View style={styles.selectionSubHeaderContainer}>
                            <View style={{ width: 150 }}>
                              {selectedProgram?.selfLearningEnabled && (
                                <Select
                                  isClearable={false}
                                  value={element.selectionType}
                                  onChange={(item, e) => dropDownHandlerselectionType(item, e, key)}
                                  placeholder={"Select a Type"}
                                  options={OPTIONS_SELECTION_NAME}
                                  menuPortalTarget={document.body}
                                  isSearchable={false}
                                  styles={customStyles2}
                                  components={{
                                    IndicatorSeparator: () => null
                                  }}
                                  isDisabled={isEditSelectionNameEnabled}
                                />
                              )}
                            </View>

                            {userRoles.includes("PROGRAM_ADMIN") && (
                              <>
                                <TouchableOpacity
                                  onPress={() => editSelectionHandlerName(element, key)}
                                  disabled={isEditSelectionNameEnabled}
                                >
                                  <Icon
                                    testID="edit"
                                    name={IconNames.penPaper}
                                    customStyle={{
                                      fontSize: 20,
                                      opacity: isEditSelectionNameEnabled ? 0.2 : 0.6
                                    }}
                                  />
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    handleRemoveSelection(element.selectionId);
                                  }}
                                  disabled={isEditSelectionNameEnabled}
                                >
                                  {renderAddRemoveButton("remove", {
                                    width: 20,
                                    height: 20,
                                    opacity: isEditSelectionNameEnabled ? 0.2 : 1
                                  })}
                                </TouchableOpacity>
                              </>
                            )}
                          </View>
                        </View>

                        <View style={[styles.dropdownContainer, { marginBottom: 15 }]}>
                          <Select
                            isClearable={false}
                            defaultValue={element.topics}
                            removeSelected={true}
                            hideSelectedOptions={true}
                            onChange={(item, e) => {
                              handleDropdown(item, element.selectionId, e);
                            }}
                            placeholder="Enter a topic and press enter"
                            options={topicList}
                            openMenuOnClick={false}
                            isMulti={true}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            isDisabled={
                              isEditSelectionNameEnabled
                                ? true
                                : userRoles.includes("PROGRAM_ADMIN")
                                ? isEditable
                                  ? true
                                  : false
                                : true
                            }
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null
                            }}
                            onKeyDown={(e: any) => {
                              if (e.key === " " && !e.target.value) e.preventDefault();
                            }}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                ))}
                {/* </ScrollContainer> */}
                {temp && (
                  <View style={styles.footerButtonsTopicDetailsContainer}>
                    <View style={styles.editButtonsContainer}>
                      <View style={styles.editIconContainer}>
                        <Button
                          type={ButtonType.Secondary}
                          buttonStyles={styles.submitbutton}
                          title="Cancel"
                          onPress={() => {
                            cancelSelectionHandler();
                          }}
                          disabled={isEditSelectionNameEnabled}
                        />
                        <Button
                          type={ButtonType.Primary}
                          buttonStyles={styles.submitbutton}
                          title="Save"
                          onPress={() => {
                            handleUpdateSelections();
                          }}
                          disabled={isEditSelectionNameEnabled}
                        />
                      </View>
                    </View>
                  </View>
                )}
              </>
            )}

            {showRemoveModal && (
              <View>
                <SocionModal
                  modalVisible={showRemoveModal}
                  setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                  component={
                    <RemoveLinkModal
                      modalVisible={showRemoveModal}
                      selectedData={handleDeleteBundle}
                      textValue={"Are you sure you want to delete this Qualification Pack?"}
                      deleteButtonText={"Delete"}
                      setModalVisible={() => setShowRemoveModal(!showRemoveModal)}
                    />
                  }
                />
              </View>
            )}
            {showPublishModal && (
              <View>
                <SocionModal
                  modalVisible={showPublishModal}
                  setModalVisible={() => setShowPublishModal(!showPublishModal)}
                  component={
                    <PublishRoleBadge
                      programDetails={programDetails}
                      qualificationPackDetails={qualificationPackDetails}
                      setShowPublishModal={setShowPublishModal}
                      selectionList={selectionList}
                      getQualificationBundle={getQualificationBundle}
                    />
                  }
                />
              </View>
            )}
            {showPublishedModal && (
              <View>
                <SocionModal
                  modalVisible={showPublishedModal}
                  setModalVisible={() => setShowPublishedModal(!showPublishedModal)}
                  component={
                    <PublishedRoleBadge
                      programDetails={programDetails}
                      qualificationPackDetails={qualificationPackDetails}
                      setShowPublishedModal={setShowPublishedModal}
                      selectionList={selectionList}
                    />
                  }
                />
              </View>
            )}
            {/* </View> */}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    padding: 18,
    paddingHorizontal: 35,
    minHeight: "calc(100vh - 140px)"
  },
  headerContainer: {},
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 18
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextSupport: {
    // Due to font size, increasing the size.
    fontFamily: FontFamily.Regular,
    marginLeft: 10
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  alignQpName: {
    fontFamily: FontFamily.Bold,
    width: 370
  },
  alignIcon: {
    marginRight: 0,
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 20
  },
  backArrowTitleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  greenBorderColor: {
    borderColor: colorPallete.cordioGreen
  },
  dropdownContainer: {
    position: "relative",
    zIndex: 2,
    width: "100%",
    marginRight: 20
  },
  loaderContainer: {
    display: "flex",
    padding: 20,
    margin: 18,
    paddingTop: 15,
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10,
    minHeight: "calc(100vh - 140px)"
  },

  fontSizeText: {
    fontSize: 12
  },
  fontSize12: {
    fontSize: 12
  },
  fontSize9: {
    fontSize: 9
  },
  fontSize16: {
    fontSize: 16
  },
  fontSize14: {
    fontSize: 14
  },
  textColorBlack: {
    color: colorPallete.textBlack
  },

  // label and value
  labelHeaderContainer: {
    flexDirection: "row",
    marginVertical: 8
  },
  labelTextContainer: {
    flex: 30
  },
  labelTextValueContainer: {
    flex: 65
  },
  topicDetailsContainer: {
    flexDirection: "row",
    marginBottom: 15,
    borderColor: colorPallete.darkGreyOne,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    paddingRight: 25,
    paddingBottom: 20
  },
  alignAddSelectionsContainer: {
    justifyContent: "flex-end"
  },
  customLoadingContainerSelectionList: {
    height: 190
  },
  qpDetials: {
    flex: 80,
    marginRight: 10
  },
  userInputContainer: {
    marginTop: 0,
    marginBottom: 15
  },
  inputStylingAlignment: {
    width: "100%"
  },
  textHeaderField: {
    flex: 35,
    marginRight: 20
  },
  topicHeaderAlignmnet: {
    marginBottom: 0
  },
  topicNameHeaderAlignment: {
    marginBottom: 15
  },
  alignDescription: {
    marginBottom: 0
  },
  titleInputContainerStyles: {
    marginTop: 0,
    marginBottom: 5
  },
  textFieldContianerAlign: {
    marginLeft: 0
  },

  footerButtonsTopicDetailsContainer: {
    flexDirection: "row",
    alignSelf: "flex-end"
  },
  editButtonsContainer: {
    marginTop: 18
  },
  editIconContainer: {
    flexDirection: "row"
  },
  alignEditButtons: {
    marginLeft: 10
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginRight: 10,
    marginLeft: 10
  },
  alignButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginLeft: 15
    // marginLeft: 30
  },
  penPaperIcon: {
    fontSize: 15,
    opacity: 0.6,
    marginLeft: 25
  },

  deleteTopicContainerMain: {
    flexDirection: "row"
  },
  deleteTopicContainer: {
    flexDirection: "row",
    alignItems: "center"
  },

  // Error container
  errorContainer: {
    marginBottom: 7,
    left: 240
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  errorText: {
    justifyContent: "center",
    color: colorPallete.cordioRed,
    fontSize: 12,
    marginRight: 0
  },
  alignErrorQpName: {
    position: "absolute",
    marginTop: 50
  },
  ml12: {
    marginLeft: 12
  },
  status: {
    marginTop: 24,
    flexDirection: "row"
  },
  publishedDate: {
    marginLeft: 24
  },
  qrCodeImage: {
    height: 125,
    width: 125
  },
  mr30: {
    marginRight: 30
  },
  publishStatus: {
    fontSize: 14,
    fontFamily: FontFamily.Bold,
    fontStyle: "italic"
  },
  publishDate: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    color: "#646363"
  },
  roleBadgesLink: {
    marginLeft: 38
  },
  textColorBlue: {
    color: colorPallete.cordioBlue1,
    textDecorationLine: "underline"
  },
  downloadQR: {
    fontSize: 9,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack
  },
  download: {
    fontSize: 12
  },
  mr7: {
    marginRight: 7
  },
  flexConatiner: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 19
  },

  // Selections list container
  selectionsContainer: {
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: colorPallete.cordioLightGreyFive,
    borderStyle: "solid"
  },
  selectionContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15
  },
  selectionNameEditIconContainer: {
    flex: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 12,
    marginLeft: 12
  },
  selectionsIconsContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 10,
    justifyContent: "space-between",
    marginTop: 12
  },
  selectionHeaderContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 8
  },
  selectionNameContainer: {
    flex: 75
  },
  selectionSubHeaderContainer: {
    flex: 25,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center"
  }
});

const customStyles2 = {
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: "white",
    // border: "none",
    border: `1px solid ${colorPallete.lightGreyTwo}`,
    boxShadow: "none",
    fontFamily: FontFamily.Medium,
    fontSize: 14,
    minHeight: 25,

    ":hover": {
      border: `1px solid ${colorPallete.lightGreyTwo}`
    }
  }),
  menuList: (base: any) => ({
    ...base,
    fontFamily: FontFamily.Medium,
    backgroundColor: colorPallete.lightGrey,
    maxHeight: 150,
    "::-webkit-scrollbar": {
      width: 5
    },
    "::-webkit-scrollbar-track": {
      background: "#D1D1D1",
      borderRadius: 10
    },
    "::-webkit-scrollbar-thumb": {
      background: "#808080",
      borderRadius: 10
    }
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: isSelected ? colorPallete.lightGreyThree : colorPallete.lightGrey,
    color: colorPallete.textBlack,
    ":hover": {
      backgroundColor: colorPallete.lightGreyTwo
    }
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    fontSize: 14
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: 0,
    paddingRight: 8,
    color: colorPallete.textBlack
  })
};
