import { RegistryApiClient } from "@socion-cordio/common/src/network/registryApiClient";
import { registryEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

class DocsRepository {
  addDocs = async (reqBody: {}): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.addDoc(), reqBody);
    return data?.result || [];
  };

  deleteDoc = async (reqBody: {}): Promise<any> => {
    let data = await RegistryApiClient.delete(registryEndPoints.deleteDoc(), reqBody);
    return data?.result || [];
  };

  listDocs = async (reqBody: { id: string[]; type: string }): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.listDocs(), reqBody);
    return data?.result || [];
  };
}
const docsRepository = new DocsRepository();
export { docsRepository as DocsRepository };
