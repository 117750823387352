import { Entity } from "@socion-cordio/common/src/models/registry/entity";
import { ENTITIES } from "@socion-cordio/common/src/modules/registry/entity/actions/actionTypes";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";

const getEntities = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ENTITIES.LOADING,
    payload: data
  };
};

const getEntitiesSuccess = (data: any): IFluxStandardAction<any> => {
  data.forEach((entity: any, index: number) => {
    entity.serialNo = `${index + 1}.`;
  });
  return {
    type: ENTITIES.GET_ENTITIES.SUCCESS,
    payload: data
  };
};

const getEntitiesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ENTITIES.GET_ENTITIES.ERROR,
    payload: error
  };
};

const getAllEntities = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ALL_ENTITIES.LOADING,
    payload: data
  };
};

const getAllEntitiesSuccess = (data: any): IFluxStandardAction<any> => {
  data.forEach((entity: any, index: number) => {
    entity.serialNo = `${index + 1}.`;
  });
  return {
    type: ENTITIES.GET_ALL_ENTITIES.SUCCESS,
    payload: data
  };
};

const getAllEntitiesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ENTITIES.GET_ALL_ENTITIES.ERROR,
    payload: error
  };
};

const getEntityDocs = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ENTITY_DOCS.LOADING,
    payload: data
  };
};

const getEntityDocsSuccess = (data: any): IFluxStandardAction<any> => {
  data.forEach((entity: any, index: number) => {
    entity.serialNo = `${index + 1}.`;
  });
  return {
    type: ENTITIES.GET_ENTITY_DOCS.SUCCESS,
    payload: data
  };
};

const getEntityDocsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ENTITIES.GET_ENTITY_DOCS.ERROR,
    payload: error
  };
};

const getEntity = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ENTITY.LOADING,
    payload: data
  };
};

const getEntitySuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ENTITY.SUCCESS,
    payload: data
  };
};

const getEntityError = (error: Error): IFluxStandardAction<any> => {
  console.log("fdsfndbfnsdvfndsfvds", error);

  return {
    type: ENTITIES.GET_ENTITY.ERROR,
    payload: error
  };
};

const getEntityAdmins = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ENTITY_ADMINS.LOADING,
    payload: data
  };
};

const getEntityAdminsSuccess = (data: any): IFluxStandardAction<any> => {
  data.forEach((admin: any, index: number) => {
    admin.serialNo = `${index + 1}.`;
  });
  return {
    type: ENTITIES.GET_ENTITY_ADMINS.SUCCESS,
    payload: data
  };
};

const getEntityAdminsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ENTITIES.GET_ENTITY_ADMINS.ERROR,
    payload: error
  };
};

const getEntityPrograms = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ENTITY_PROGRAMS.LOADING,
    payload: data
  };
};

const getEntityProgramsSuccess = (data: any): IFluxStandardAction<any> => {
  data.forEach((program: any, index: number) => {
    program.serialNo = `${index + 1}.`;
  });
  return {
    type: ENTITIES.GET_ENTITY_PROGRAMS.SUCCESS,
    payload: data
  };
};

const getEntityProgramsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ENTITIES.GET_ENTITY_PROGRAMS.ERROR,
    payload: error
  };
};

const getEntityProgramPartners = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ENTITY_PROGRAM_PARTNERS.LOADING,
    payload: data
  };
};

const getEntityProgramPartnersSuccess = (data: any): IFluxStandardAction<any> => {
  data.forEach((program: any, index: number) => {
    program.serialNo = `${index + 1}.`;
  });
  return {
    type: ENTITIES.GET_ENTITY_PROGRAM_PARTNERS.SUCCESS,
    payload: data
  };
};

const getEntityProgramPartnersError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ENTITIES.GET_ENTITY_PROGRAM_PARTNERS.ERROR,
    payload: error
  };
};

const getEntityPartners = (data: any): IFluxStandardAction<any> => {
  return {
    type: ENTITIES.GET_ENTITY_PARTNERS.LOADING,
    payload: data
  };
};

const getEntityPartnersSuccess = (data: any): IFluxStandardAction<any> => {
  data.forEach((program: any, index: number) => {
    program.serialNo = `${index + 1}.`;
  });
  return {
    type: ENTITIES.GET_ENTITY_PARTNERS.SUCCESS,
    payload: data
  };
};

const getEntityPartnersError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: ENTITIES.GET_ENTITY_PARTNERS.ERROR,
    payload: error
  };
};

export const EntityActions = {
  getEntities,
  getEntitiesSuccess,
  getEntitiesError,
  getAllEntities,
  getAllEntitiesSuccess,
  getAllEntitiesError,
  getEntityDocs,
  getEntityDocsSuccess,
  getEntityDocsError,
  getEntity,
  getEntitySuccess,
  getEntityError,
  getEntityAdmins,
  getEntityAdminsSuccess,
  getEntityAdminsError,
  getEntityPrograms,
  getEntityProgramsSuccess,
  getEntityProgramsError,
  getEntityProgramPartners,
  getEntityProgramPartnersSuccess,
  getEntityProgramPartnersError,
  getEntityPartners,
  getEntityPartnersSuccess,
  getEntityPartnersError
};
