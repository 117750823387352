import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import BadgesTable from "@socion-cordio/common/src/components/organisms/badges/badgesTable";
import BadgeTypesTable from "@socion-cordio/common/src/components/organisms/badges/badge-types/badgeTypesTable";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";

interface Props {}

export default function BadgesPage(props: Props): ReactElement {
  const [programDetails, setProgramDetails] = useState(null);
  const [isBadges, setIsBadges] = useState(true);
  const [isBadgesType, setIsBadgesType] = useState(false);
  const [selectedBadgeType, setSelectedBadgeType] = useState(null);

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getProgramDetails = async () => {
    const programDetails = await LocalStorage.getStorage("programDetails");
    setProgramDetails(programDetails);
  };

  const viewSelectedBadgeTypeHandler = (badgeTypeSelected: any) => {
    setSelectedBadgeType(badgeTypeSelected);
    setIsBadges(true);
    setIsBadgesType(false);
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.toggleContainer}>
          <ButtonTab
            type={isBadges ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[
              styles.toggleButton,
              { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
            ]}
            textStyles={styles.textStyle}
            title="Badges"
            onPress={() => {
              setIsBadges(true);
              setIsBadgesType(false);
            }}
          />
          <ButtonTab
            type={isBadgesType ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary}
            buttonStyles={[
              styles.toggleButton,
              { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
            ]}
            textStyles={styles.textStyle}
            title="Badge Types"
            onPress={() => {
              setIsBadges(false);
              setIsBadgesType(true);
              setSelectedBadgeType(null);
            }}
          />
        </View>
        <View>
          {programDetails && isBadges ? (
            <BadgesTable
              programDetails={programDetails}
              selectedBadgeType={selectedBadgeType}
              setSelectedBadgeType={setSelectedBadgeType}
            />
          ) : isBadgesType ? (
            <BadgeTypesTable
              programDetails={programDetails}
              viewSelectedBadgeTypeHandler={viewSelectedBadgeTypeHandler}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    padding: 20,
    paddingLeft: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  }
});
