import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Tooltip from "react-tooltip-lite";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";

interface Props {
  textValue?: any;
  hoverValue?: string;
  hoverContainerStyle?: StyleProp<ViewStyle>;
  toShowTextEllipsesStyle?: boolean;
}

export const CustomTooltip = (props: Props) => {
  const { textValue, hoverValue, hoverContainerStyle, toShowTextEllipsesStyle = false } = props;

  const stylesPrimary = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inherit",
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    color: colorPallete.textBlack
  };
  const stylesSecondary = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inherit",
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    color: colorPallete.textBlack
  };

  return (
    <>
      <Tooltip
        styles={toShowTextEllipsesStyle ? stylesSecondary : stylesPrimary}
        zIndex={9999999}
        tipContentClassName=""
        distance={10}
        direction="bottom"
        background={colorPallete.cordioBeigeLight1}
        color={colorPallete.textBlack}
        padding={10}
        content={
          <View style={[styles.hoverContainer, hoverContainerStyle]}>
            <Text
              testId="hoverContainer"
              fontWeight={FontWeight.Regular}
              textSize={TextSize.Small}
              style={styles.textStyles}
            >
              {hoverValue}
            </Text>
          </View>
        }
      >
        {textValue}
      </Tooltip>
    </>
  );
};

const styles = StyleSheet.create({
  hoverContainer: {
    width: 250
  },
  textStyles: {
    fontFamily: FontFamily.Regular,
    fontSize: 12
  }
});
