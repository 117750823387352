import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { verifyEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import VerifyQualificationModal from "@socion-cordio/common/src/components/organisms/verify/verifyRoleQualificationModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { toast } from "react-toastify";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { VerifyRepository } from "@socion-cordio/common/src/repositories/verify/verify";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import axios from "axios";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";
import _ from "lodash";

interface Props {
  programDetails: any;
  userRoles: any[];
}

const STATUS_VALUES = {
  SUBMITTED: "SUBMITTED",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED"
};

export default function TaskVerification(props: Props): ReactElement {
  const { userRoles, programDetails } = props;
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [taskVerificationResponse, setTaskVerificationResponse] = useState([]);
  const [isVerifyTaskModal, setIsVerifyTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    getTaskVerification();
  }, []);

  useEffect(() => {
    if (isVerifyTaskModal) disableScroll();
    else enableScroll();
  }, [isVerifyTaskModal]);

  const getTaskVerification = async () => {
    setLoading(true);
    const { program } = programDetails;
    const accessToken = await LocalStorage.getStorage("accessToken");
    const baseUrl =
      "https://task-api-stage.socion.io/taskservice/api/v1/submissions/verification/list";

    axios({
      method: "post",
      url: baseUrl,
      headers: { "access-token": `${accessToken}` },
      data: {
        programId: program.id //521
      }
    })
      .then(function (response) {
        const { data } = response?.data?.result;
        if (response.data?.code === HTTP_STATUS_CODES.ok) {
          const newList: any[] = _.orderBy(data, "submissionTime", "desc");
          newList.forEach((role: any, index: number) => {
            role.serialNo = `${index + 1}.`;
            role.submittedOn = dataHelper.formatDate(role?.submissionTime);
            role.formattedLocation = auxiliaryMethods.fromatBaseLocation(role);
            role.verificationDate = role?.responseDate
              ? dataHelper.formatDate(role?.verifiedOn)
              : "";
          });
          setTaskVerificationResponse(newList);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        // console.log("error-->>>e,", error);
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const handleRowClick = (data: any) => {
    // Check if any status to check to open the modal?
    // if (data?.deleted === false) {
    setSelectedTask(data);
    setIsVerifyTaskModal(!isVerifyTaskModal);
    // }
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: task } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, task?.serialNo);
        }
      },

      {
        Header: "Name",
        accessor: "name",
        width: 50,
        maxWidth: 50,
        Cell: ({ row: { original: task } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return cellData("name", style, task?.name);
        }
      },
      {
        Header: "Task",
        accessor: "taskName",
        width: 73,
        maxWidth: 73,
        Cell: ({ row: { original: task } }: { row: { original: any } }) => {
          const style = {};
          return cellData("taskName", style, task?.taskName);
        }
      },
      {
        Header: "Submitted on",
        accessor: "submittedOn",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: task } }: { row: { original: any } }) => {
          const style = {};
          return cellData("submissionTime", style, dataHelper.formatDate(task?.submissionTime));
        }
      },
      {
        Header: "Location",
        accessor: "formattedLocation",
        width: 45,
        maxWidth: 45,
        Cell: ({ row: { original: task } }: { row: { original: any } }) => {
          const style = {};
          // return cellData("location", style, auxiliaryMethods.fromatBaseLocation(task));
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(task) !== "None" ? (
                <CustomTooltip
                  hoverValue={auxiliaryMethods.fromatBaseLocation(task)}
                  textValue={auxiliaryMethods.getUserFirstLocationName(task)}
                />
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Verification date",
        accessor: "verificationDate",
        width: 70,
        maxWidth: 70,
        Cell: ({ row: { original: task } }: { row: { original: any } }) => {
          const style = {};
          const date = task?.verifiedOn ? dataHelper.formatDate(task?.verifiedOn) : "None";
          return cellData("submissionTime1", style, date);
        }
      },
      {
        Header: "Verified by",
        accessor: "verifierName",
        width: 55,
        maxWidth: 55,
        Cell: ({ row: { original: task } }: { row: { original: any } }) => {
          const style = {};
          return cellData("approvedName", style, task?.verifierName ? task?.verifierName : "None");
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 55,
        maxWidth: 55,
        Cell: ({ row: { original: task } }: { row: { original: any } }) => (
          <View style={styles.statusContainer}>
            <View
              style={[
                styles.statusColor,
                task.status === STATUS_VALUES.APPROVED
                  ? styles.approvedColor
                  : task.status === STATUS_VALUES.SUBMITTED
                  ? styles.pendingColor
                  : task.status === STATUS_VALUES.REJECTED
                  ? styles.declineColor
                  : styles.approvedColor
              ]}
            ></View>
            <Text
              fontWeight={FontWeight.Light}
              testId="testId"
              textSize={TextSize.Small}
              textStyle={styles.textAlign}
              onPress={() => {}}
            >
              {task?.status}
            </Text>
          </View>
        )
      }
    ],
    []
  );

  const noDataFound = () => (
    <View style={styles.noData}>
      <View style={styles.noDataAlignContainer}>
        <Text
          fontWeight={FontWeight.Thin}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.headerAlignment]}
        >
          {"No Task Verification(s) under this Program."}
        </Text>
      </View>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          {isVerifyTaskModal && (
            <View>
              <SocionModal
                modalVisible={isVerifyTaskModal}
                setModalVisible={() => setIsVerifyTaskModal(!isVerifyTaskModal)}
                component={
                  <VerifyQualificationModal
                    onClose={() => setIsVerifyTaskModal(false)}
                    selectedTask={selectedTask}
                    programDetails={props?.programDetails}
                    getTaskVerification={getTaskVerification}
                    userRoles={userRoles}
                  />
                }
              />
            </View>
          )}
          {taskVerificationResponse.length == 0 ? (
            noDataFound()
          ) : (
            <Table
              columns={columns}
              data={taskVerificationResponse}
              handleRowClick={handleRowClick}
            />
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 12
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  headerAlignment: {
    paddingHorizontal: 25
  },
  noData: {
    height: "73vh"
  },
  noDataAlignContainer: {
    marginTop: 30
  }
});
