import React, { createRef, forwardRef, ReactElement, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useTranslation } from "react-i18next";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-checkbox-tree/src/scss/react-checkbox-tree.scss";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import _, { forEach } from "lodash";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {
  programDetails: any;
  locationList: any[];
  getSelectedLocationList: Function;
  setExpandedList: Function;
  expandedList: any;
  setCheckedList: Function;
  checkedList: any;
  dataFilteredHandler: Function;
  setModalVisible: Function;
  setIsLocationFilterApplied: Function;
  updatedFilteredLocationList: Function;
  setGlobalList?: Function;
  globalList: any;
  setAllExpandedNodes?: any;
  allExpandedNodes?: any;
  setErrorFilterMessage?: Function;
  setIsNoLocationSelected?: Function;
}

const FilterLocationPage = forwardRef((props: Props, ref): ReactElement => {
  const { t } = useTranslation();
  const {
    programDetails,
    locationList,
    getSelectedLocationList,
    setExpandedList,
    expandedList,
    setCheckedList,
    checkedList,
    dataFilteredHandler,
    setModalVisible,
    setIsLocationFilterApplied,
    updatedFilteredLocationList,
    setGlobalList,
    globalList,
    setAllExpandedNodes,
    allExpandedNodes,
    setErrorFilterMessage,
    setIsNoLocationSelected
  } = props;
  const [locationListsArray, setLocationListsArray] = useState([]);
  const [filterLocationList, setFilterLocationList] = useState([]);
  const [checkedNodeDetails, setCheckNodeDetails] = useState(null);
  const [noneSelect, setNoneSelect] = useState(false);
  const [locationFilteredList, setLocationFilteredList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // const [allExpandedNodes, setAllExpandedNodes] = useState([]);
  useEffect(() => {
    checkNonSelectedHandler();
  }, []);

  const checkNonSelectedHandler = async () => {
    const check = await LocalStorage.getStorage("noneLocationListSet");
    check
      ? [setNoneSelect(true), setIsNoLocationSelected(true)]
      : [setNoneSelect(false), setIsNoLocationSelected(false)];
  };

  useEffect(() => {
    let currentExpandedList1 = [...expandedList];
    setExpandedList(allExpandedNodes);
    const checkAllNodes = validateLocationHandler();
    setExpandedList(currentExpandedList1);
    // setGlobalList(checkAllNodes)
  }, [locationList]);

  // const capitalizeFirstLetter = (userString: string) => {
  //   let string = userString.toLowerCase();
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  const capitalizeFirstLetter = (input: any) => {
    if (input !== "") {
      let words = input.split(" ");
      let CapitalizedWords: any = [];
      words.forEach((element: any) => {
        CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
      });
      return CapitalizedWords.join(" ");
    }
  };

  const getSearchResult = () => {
    if (searchTerm !== "") {
      let result: any = [];
      filteredData &&
        filteredData.map((data, index) => {
          data.city = data.city.toLowerCase();
          data.subDistrict = data.subDistrict.toLowerCase();
          data.district = data.district.toLowerCase();
          data.state = data.state.toLowerCase();
          if (data.city.length !== 0 && data.city.includes(searchTerm)) {
            result.push(data.subDistrict);
            result.push(data.district);
            result.push(data.state);
            result.push(data.country);
          } else if (data.subDistrict.length !== 0 && data.subDistrict.includes(searchTerm)) {
            result.push(data.district);
            result.push(data.state);
            result.push(data.country);
          } else if (data.district.length !== 0 && data.district.includes(searchTerm)) {
            result.push(data.state);
            result.push(data.country);
          } else if (data.state.length !== 0 && data.state.includes(searchTerm)) {
            result.push(data.country);
          }
        });
      let final = [...new Set(result)];
      let final2 = [];
      for (let i = 0; i < final.length; i++) {
        final2.push(capitalizeFirstLetter(final[i]));
      }
      // console.log("final2:::::::", final2);
      // console.log("EXPANDED::::", allExpandedNodes);

      for (let i = 0; i < allExpandedNodes.length; i++) {
        for (let j = 0; j < final2.length; j++) {
          const name = allExpandedNodes[i].split("/")[0];
          // console.log("name::::::", name, final2[j]);
          if (name === final2[j]) {
            final2[j] = allExpandedNodes[i];
          }
        }
      }
      // console.log("final2:::::::", final2);
      // console.log("EXPANDED::::", allExpandedNodes);
      setExpandedList(final2);
    }
    // if(searchTerm===''){
    //   setExpandedList([]);
    // }
    // else setExpandedList([]);
  };

  useEffect(() => {
    getSearchResult();
  }, [filteredData, searchTerm]);

  const filterList = async (value: any) => {
    let str = value.toLowerCase();
    setSearchTerm(value);
    let newList: any[] = [];
    locationList
      .filter((item) => item.formattedBaseLocation.toLowerCase().includes(str))
      .map((name) => {
        return newList.push(name);
      });
    setFilteredData(newList);
  };

  const expandNodesToLevel = (nodes: any, targetLevel: any, currentLevel = 0) => {
    if (currentLevel > targetLevel) {
      return [];
    }
    let expanded: any = [];
    nodes.forEach((node: any) => {
      if (node.children) {
        expanded = [
          ...expanded,
          node.value,
          ...expandNodesToLevel(node.children, targetLevel, currentLevel + 1)
        ];
      }
    });
    // console.log("EXPANDED::::", expanded);
    setAllExpandedNodes(expanded);
    return expanded;
  };

  // const [state, setState] = useState({
  //   checked: [],
  //   expanded: [],
  //   keyword: ""
  // });

  // const onSearchInputChange = (data: any, searchedNodes: any) => {
  //   console.log("onSearchInputChange", data, searchedNodes);
  //   setState((prevState): any => {
  //     if (prevState.keyword && !data.value) {
  //       return {
  //         expanded: [],
  //         keyword: data
  //       };
  //     }
  //     console.log(
  //       "getAllValuesFromNodes(searchedNodes, true)",
  //       getAllValuesFromNodes(searchedNodes, true)
  //     );
  //     return {
  //       expanded: getAllValuesFromNodes(searchedNodes, true),
  //       keyword: data
  //     };
  //   });
  // };

  // const getAllValuesFromNodes: any = (nodes: any, firstLevel: any) => {
  //   if (firstLevel) {
  //     const values = [];
  //     for (let n of nodes) {
  //       values.push(n.value);
  //       console.log("NNNNNNNNNNNN", n);

  //       if (n.children) {
  //         values.push(...getAllValuesFromNodes(n.children, false));
  //       }
  //     }
  //     console.log("values::::1", values);
  //     return values;
  //   } else {
  //     const values = [];
  //     for (let n of nodes) {
  //       console.log("NNNNNNNNNNNN 222222222222", n);
  //       values.push(n.value);
  //       if (n.children) {
  //         console.log("NNNNNNNNNNNN 222222222222 CHILDRENNN", n);
  //         values.push(...getAllValuesFromNodes(n.children, false));
  //       }
  //     }
  //     console.log("values::::2", values);

  //     return values;
  //   }
  // };

  // const keywordFilter = (nodes: any, keyword: any) => {
  //   let newNodes = [];
  //   console.log("nodes:::::", nodes, "keyword", keyword);

  //   for (let n of nodes) {
  //     console.log("NNNN 3333333", n);

  //     if (n.children) {
  //       const nextNodes = keywordFilter(n.children, keyword);
  //       if (nextNodes.length > 0) {
  //         n.children = nextNodes;
  //       } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
  //         n.children = nextNodes.length > 0 ? nextNodes : [];
  //       }
  //       if (nextNodes.length > 0 || n.label.toLowerCase().includes(keyword.toLowerCase())) {
  //         // n.label = getHighlightText(n.label, keyword);
  //         newNodes.push(n);
  //       }
  //     } else {
  //       if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
  //         // n.label = getHighlightText(n.label, keyword);
  //         newNodes.push(n);
  //       }
  //     }
  //   }
  //   console.log("newNodes:::", newNodes);
  //   return newNodes;
  // };

  // const filterFunc = async () => {
  //   const nodesData: any = await LocalStorage.getStorage("qpfilterList");
  //   let searchedNodes = state.keyword
  //     ? keywordFilter(_.cloneDeep(nodesData), state.keyword)
  //     : nodesData;
  //   console.log("searchedNodes", searchedNodes);
  //   if (searchedNodes !== null) {
  //     setFilterLocationList(searchedNodes);
  //   }
  // };

  // useEffect(() => {
  //   // filterFunc();
  //   console.log("state:::::", state);
  // }, [state]);

  // const iterate = (obj: any) => {
  //   Object.keys(obj).forEach((key) => {
  //     console.log(`key: ${key}, value: ${obj[key]}`);

  //     if (typeof obj[key] === "object") {
  //       iterate(obj[key]);
  //     }
  //   });
  // };

  // const getHighlightText = (text: any, keyword: any) => {
  //   const startIndex = text.indexOf(keyword);
  //   return startIndex !== -1 ? (
  //     <span>
  //       {text.substring(0, startIndex)}
  //       <span style={{ color: "#2cb664" }}>
  //         {text.substring(startIndex, startIndex + keyword.length)}
  //       </span>
  //       {text.substring(startIndex + keyword.length)}
  //     </span>
  //   ) : (
  //     <span>{text}</span>
  //   );
  // };

  const groupBy = (array: any[], key: any) => {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
    }, {});
  };

  useEffect(() => {
    if (locationList !== undefined) {
      setFilterListHandler(locationList);
    }
  }, [locationList]);

  const setFilterListHandler = async (locationList: any) => {
    if (!localStorage["qpfilterList"]) {
      const country = convertLocationToArrayObject(locationList);
      const list = clean(country);
      // console.log("list:::::::::", list);
      setFilterLocationList(list);
      // setFilterLocationList(DUMMY_OBJECT);
      const nodeList = expandNodesToLevel(list, 5, 0);
      LocalStorage.setStorage("qpfilterList", list);
    } else {
      const locList: any[] = await LocalStorage.getStorage("qpfilterList");
      // const nodeList = expandNodesToLevel(locList, 5, 0);
      setFilterLocationList(locList);
      const nodeList = expandNodesToLevel(locList, 5, 0);
    }
  };

  const convertLocationToArrayObject = (locationList: any) => {
    let one: any;
    let country: any = [];
    one = groupBy(locationList, "country");
    Object.keys(one).map((key: any, index: number) => {
      if (one.hasOwnProperty(key)) {
        const two = groupBy(one[key], "state");
        country.push({
          label: fontSizeLabelHandler(key),
          value: key + "/" + Math.random(),
          children: []
        });

        Object.keys(two).map((key2: any, index2: number) => {
          if (two.hasOwnProperty(key2)) {
            const three = groupBy(two[key2], "district");
            country[index].children.push({
              label: fontSizeLabelHandler(key2),
              value: key2 + "/" + Math.random(),
              children: []
            });

            Object.keys(three).map((key3: any, index3: number) => {
              if (three.hasOwnProperty(key3)) {
                const four = groupBy(three[key3], "subDistrict");
                country[index].children[index2].children.push({
                  label: fontSizeLabelHandler(key3),
                  value: key3 + "/" + Math.random(),
                  children: []
                });

                Object.keys(four).map((key4: any, index4: number) => {
                  if (four.hasOwnProperty(key4)) {
                    const five = groupBy(four[key4], "city");
                    country[index].children[index2].children[index3].children.push({
                      label: fontSizeLabelHandler(key4),
                      value: key4 + "/" + Math.random(),
                      children: []
                    });

                    Object.keys(five).map((key5: any) => {
                      if (five.hasOwnProperty(key5)) {
                        country[index].children[index2].children[index3].children[
                          index4
                        ].children.push({
                          label: fontSizeLabelHandler(key5),
                          // value: key5 + "/" + Math.random(),
                          value: five[key5][0].serialNo,
                          // valueSrNo: five[key5][0].serialNo,
                          currentObj: five[key5][0]
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    // console.log(country, "country");
    return country;
  };

  const fontSizeLabelHandler = (key: any) => {
    return key;
    // return <span style={{ fontSize: 14, alignSelf: "center" }}> {key}</span>;
  };

  const clean = (data: any) =>
    data.flatMap((item: any) =>
      !item.children
        ? item
        : // : item.value && item.label // && item.label !== "null" && item.value !== "null"
        (item.value && item.label) || item.label !== ""
        ? { ...item, children: clean(item.children) }
        : clean(item.children)
    );

  const checkListHandler = (list: any[], checkedNodeData: any) => {
    setErrorFilterMessage(false);
    setNoneSelect(false);
    removeNoneSelectedHandlerLocalStorage();
    let locationLists = [...locationList];
    if (list.length === 0) {
      locationLists.forEach((element: any) => {
        element.isSelected = false;
      });
    } else {
      // expandNodesToLevel()

      locationLists.forEach((element: any) => {
        element.isSelected = false;
      });
      const newList = list.forEach((element: any) => {
        const listItem = locationLists.filter((item: any) => item.serialNo === element);
        listItem[0].isSelected = true;
        locationLists.length === 0
          ? [getSelectedLocationList(locationList)]
          : [getSelectedLocationList(locationLists, globalList)];
      });
    }
  };

  const clearLocationFilterHandler = () => {
    locationListFalseConverter();
    // setExpandedList([]);
    setCheckedList([]);
    // getSelectedLocationList([]);
    updatedFilteredLocationList([]);
    removeNoneSelectedHandlerLocalStorage();
    LocalStorage.removeStorage("globalNameList");
    googleWebAnalytics("QP_User_List_Clear_Location_Filter", "Button", "Qualification_Pack");
  };

  const noneSelectHandler = () => {
    locationListFalseConverter();
    setCheckedList([]);
    getSelectedLocationList([]);
    LocalStorage.setStorage("noneLocationListSet", true);
    setErrorFilterMessage(false);
    setIsNoLocationSelected((prevState: boolean) => !prevState);
  };

  const removeNoneSelectedHandlerLocalStorage = () => {
    setNoneSelect(false);
    LocalStorage.removeStorage("noneLocationListSet");
    setIsNoLocationSelected(false);
  };

  const locationListFalseConverter = () => {
    let list: any = [...locationList];
    const newList = list.forEach((element: any) => {
      element.isSelected = false;
    });
  };

  const myContainer: any = createRef();

  useEffect(() => {
    getGlobalLists();
  }, [checkedList, checkedNodeDetails]);

  const getGlobalLists = async () => {
    let locationList: any = [];

    locationList = validateLocationHandler();
    LocalStorage.setStorage("globalNameList", locationList);
    if (localStorage["globalNameList"]) {
      const globalNameKey: any = await LocalStorage.getStorage("globalNameList");
      let tempLocList1: any = [...globalNameKey];
      tempLocList1 = [...locationList, ...globalNameKey];
      let newList = [...new Set(tempLocList1)];
      // console.log("newList;:::", newList);
      const updatedLocationList = validateLocationHandler(); //again check in the tree
      // console.log("updatedLocationList--->>>", updatedLocationList);

      // let list1: any = await LocalStorage.getStorage("list1");
      // const list1Copy = [...list1];
      // list1.push(...updatedLocationList, ...list1Copy);
      // const listNew = [...new Set(list1)];
      // // console.log("list1-------->>>", listNew);
      // LocalStorage.setStorage("list1", listNew);

      LocalStorage.setStorage("globalNameList", updatedLocationList);
      let currentExpandedList1 = [...expandedList];
      setExpandedList(allExpandedNodes);
      const checkAllNodes = validateLocationHandler();
      setExpandedList(currentExpandedList1);

      if (checkedNodeDetails?.checked === false) {
        let list = newList.filter((item: any) => item !== checkedNodeDetails.label);
        LocalStorage.setStorage("globalNameList", list);
        const newLocationList = validateLocationHandler();
        // setGlobalList(list);

        let currentExpandedList = [...expandedList];
        setExpandedList(allExpandedNodes);
        const checkAllNodes = validateLocationHandler();
        LocalStorage.setStorage("globalNameList", checkAllNodes);
        setExpandedList(currentExpandedList);
        // setGlobalList(newLocationList);
        setGlobalList(checkAllNodes);
      } else {
        // LocalStorage.setStorage("globalNameList", newList);
        // console.log("ELSE------>>>>>", newList);
        // setGlobalList(newList);
        LocalStorage.setStorage("globalNameList", checkAllNodes);
        setGlobalList(checkAllNodes);
        // setGlobalList(updatedLocationList);
      }
      // setGlobalList(tempLocList1);
    } else {
      let tempLocList: any = [...globalList];
      tempLocList = [...locationList];
      setGlobalList(tempLocList);
    }
  };

  const validateLocationHandler = () => {
    let locationList: any = [];
    const tree: any = document.getElementById("react-tree-dynamic");
    const child: any = tree?.firstElementChild;
    const child2: any = document.getElementsByTagName("ol");

    let country = child2[0]?.childNodes;

    for (let i = 0; i < country?.length; i++) {
      const countryBool = country[i].firstChild.lastChild.childNodes[1].ariaChecked;
      const textCountry = country[i].firstChild.lastChild.childNodes[2].innerHTML;
      if (countryBool === "true") {
        // const obj = {
        //   location: "country",
        //   locationName: textCountry
        // };
        // locationList.push(obj);
        locationList.push(textCountry);
      } else {
        let state = country[i]?.childNodes[1]?.childNodes;
        for (let j = 0; j < state?.length; j++) {
          const stateBool = state[j].firstChild.lastChild.childNodes[1].ariaChecked;
          const textState = state[j].firstChild.lastChild.childNodes[2].innerHTML;
          if (stateBool === "true") {
            if (textState.includes("&")) {
              let text = textState.replace("&amp;", "&");
              locationList.push(text);
            } else {
              locationList.push(textState);
            }
          } else {
            let district = state[j]?.childNodes[1]?.childNodes;
            for (let k = 0; k < district?.length; k++) {
              const districtBool = district[k].firstChild.lastChild.childNodes[1].ariaChecked;
              const textDistrict = district[k].firstChild.lastChild.childNodes[2].innerHTML;
              let subDistrict = district[k]?.childNodes[1]?.childNodes;
              if (districtBool === "true") {
                locationList.push(textDistrict);
              } else {
                for (let l = 0; l < subDistrict?.length; l++) {
                  const subDistrictBool =
                    subDistrict[l].firstChild.lastChild.childNodes[1].ariaChecked;
                  const textsubDistrict =
                    subDistrict[l].firstChild.lastChild.childNodes[2].innerHTML;
                  let city = subDistrict[l]?.childNodes[1]?.childNodes;

                  if (subDistrictBool === "true") {
                    locationList.push(textsubDistrict);
                  } else {
                    for (let m = 0; m < city?.length; m++) {
                      const cityBool = city[m].firstChild.lastChild.childNodes[1].ariaChecked;
                      const textCity = city[m].firstChild.lastChild.childNodes[2].innerHTML;
                      if (cityBool === "true") {
                        locationList.push(textCity);
                      } else {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return locationList;
  };

  return (
    <View style={styles.mainContainer}>
      {locationList === null || locationList === undefined ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : dataHelper.isEmptyArray(locationList) ? (
        <View style={styles.noDataContainer}>
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            {t("common:noData")}
          </Text>
        </View>
      ) : (
        <View>
          <View style={styles.searchContainer}>
            <SearchFilterSeondary filterList={filterList} editable={true} />
          </View>
          <View>
            <View style={styles.locationListContainer}>
              <ScrollContainer height="290px">
                <CheckboxTree
                  id={"react-tree-dynamic"}
                  ref={myContainer}
                  nodes={filterLocationList}
                  checked={checkedList}
                  expanded={expandedList}
                  onCheck={(checkedNode, checked) => [
                    setCheckedList(checkedNode),
                    checkListHandler(checkedNode, checked),
                    setCheckNodeDetails(checked),
                    googleWebAnalytics(
                      "QP_User_List_Location_Filter",
                      "Button",
                      "Qualification_Pack"
                    )
                    // getGlobalLists()
                    // console.log("checkedNode", checkedNode),
                  ]}
                  onExpand={(expanded) => [setExpandedList(expanded)]}
                  showNodeIcon={false}
                  icons={{
                    check: (
                      <View style={{ marginHorizontal: 10 }}>
                        <Checkbox
                          testId={"checkBox"}
                          onPress={() => {}}
                          selected={true}
                          type={CheckboxType.Square}
                          text={""}
                          textSize={TextSize.Small}
                          textFontWeight={FontWeight.Regular}
                          textStyle={[styles.textStyleCheckBox, { position: "absolute" }]}
                          iconSize={16}
                        />
                      </View>
                    ),
                    uncheck: (
                      <View style={{ marginHorizontal: 10 }}>
                        <Checkbox
                          testId={"checkBox"}
                          onPress={() => {}}
                          selected={false}
                          type={CheckboxType.Square}
                          text={""}
                          textSize={TextSize.Small}
                          textFontWeight={FontWeight.Regular}
                          textStyle={[styles.textStyleCheckBox, { position: "absolute" }]}
                          iconSize={16}
                        />
                      </View>
                    ),
                    halfCheck: (
                      <View style={{ marginHorizontal: 10 }}>
                        <Checkbox
                          testId={"checkBox"}
                          onPress={() => {}}
                          selected={false}
                          type={CheckboxType.Square}
                          text={""}
                          textSize={TextSize.Small}
                          textFontWeight={FontWeight.Regular}
                          textStyle={[styles.textStyleCheckBox, { position: "absolute" }]}
                          iconSize={16}
                        />
                      </View>
                    ),
                    expandClose: (
                      <Icon
                        name={IconNames.arrowOutlined}
                        customStyle={[styles.titleIcon, styles.alignArrowOutline]}
                      />
                    ),
                    expandOpen: (
                      <Icon
                        name={IconNames.watchVideo}
                        customStyle={[styles.titleIcon, styles.alignExpandButton]}
                      />
                    ),
                    // expandAll: <span className="rct-icon rct-icon-expand-all" />,
                    // collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                    parentClose: "", // not needed
                    parentOpen: "", //not needed
                    leaf: ""
                  }}
                />
                <View style={styles.alignNoneContainer}>
                  <Checkbox
                    testId={"checkBox"}
                    onPress={() => [
                      noneSelectHandler(),
                      setNoneSelect(!noneSelect),
                      updatedFilteredLocationList([], noneSelect)
                    ]}
                    selected={noneSelect}
                    type={CheckboxType.Square}
                    text={"None"}
                    textSize={TextSize.Small}
                    textFontWeight={FontWeight.Regular}
                    textStyle={styles.textStyleCheckBox}
                    iconSize={16}
                  />
                </View>
              </ScrollContainer>
            </View>
            <View style={[styles.header]}>
              <View style={[styles.buttonContainer, styles.alignClearButton]}>
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Clear"
                  onPress={() => clearLocationFilterHandler()}
                />
              </View>
              {/* <View style={styles.buttonContainer}>
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Apply"
                  onPress={() => applyFilterHandler()}
                />
              </View>  */}
            </View>
          </View>
        </View>
      )}
    </View>
  );
});

export default FilterLocationPage;

const styles = StyleSheet.create({
  mainContainer: {},
  searchContainer: {
    marginBottom: 15
  },

  // Loader
  customLoadingContainer: {
    height: 350
  },

  // Search
  customStyle: {
    marginLeft: 0,
    marginTop: 0
  },

  // Check Box LocationList
  locationListContainer: {
    height: 300,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack
  },
  checkBoxContainer: {
    marginBottom: 8
  },
  textStyleCheckBox: {
    marginStart: 21,
    fontSize: 14,
    fontFamily: FontFamily.Regular
  },

  alignNoneContainer: {
    marginStart: 39,
    fontFamily: FontFamily.Regular
  },

  // No Data Container
  noDataContainer: {
    height: 200
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    fontWeight: "700"
  },

  titleIcon: {
    fontSize: 16
  },
  alignArrowOutline: {
    color: colorPallete.cordioTaupeLight3
  },
  alignExpandButton: {
    transform: [{ rotate: "90deg" }],
    fontSize: 8
  },

  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  alignClearButton: {
    marginRight: 110,
    top: 12
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  }
});
