import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, useWindowDimensions, TouchableOpacity } from "react-native";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast, ToastContainer } from "react-toastify";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import RemoveLinkModal from "@socion-cordio/common/src/components/organisms/common-modals/removeLinkModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
interface IProps {
  sessionLinks?: any;
  sessionId?: number;
  sessionDetails?: any;
  isSessionCompleted?: boolean;
}

export default function AdditionalLinksPage(props: IProps): ReactElement {
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const { sessionLinks, sessionId, sessionDetails, isSessionCompleted } = props;
  const [additionalLinksList, setAdditionalLinksList] = useState(sessionLinks || null);
  const [link, setLink] = useState(null);
  const [user, setUser] = useState(null);
  const [failed, setFailed] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [isDeleteLink, setIsDeteleLink] = useState(false);
  const [removeLinkData, setRemoveLinkData] = useState(null);
  const [sessionData, setSessionData] = useState(sessionDetails || null);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isUrlCorrect, setIsUrlCorrect] = useState(false);
  const [showAdditionalLinks, setShowAdditionLinks] = useState(true);
  const userRolesData: any = useSelector((state: any) => state?.userRolesProgram);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (isDeleteLink) disableScroll();
    else enableScroll();
  }, [isDeleteLink]);

  useEffect(() => {
    if (sessionLinks !== null) {
      setAdditionalLinksList(sessionLinks);
      isMemberAllowedToEdit(); // which means sessionDetails is present
    }
  }, [sessionLinks]);

  const getUserDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setUser(user);
  };

  const getSessionDetails = async () => {
    try {
      const response = await ApiClient.get(
        sessionPackEndPoints.getCompleteSessionDetails(sessionId)
      );
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        setLink("");
        setAdditionalLinksList(response?.response?.sessionLinks);
        isMemberAllowedToEdit();
        setLoadingList(false);
        setFailed(false);
      } else {
        toast.error(response?.message);
        setLoadingList(false);
        setFailed(true);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoadingList(false);
      setFailed(true);
    }
  };

  const isMemberAllowedToEdit = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const userId = user?.userId;
    const sessionMembers = sessionDetails.members;
    const isAlreadyExistsCheck = sessionMembers.find(
      (x: { userId: string }) => x.userId === userId
    );
    const sessionCreatorId = sessionDetails?.sessionCreatorProfile.userId;
    if (isAlreadyExistsCheck || userId === sessionCreatorId) {
      setIsAllowed(true);
    } else {
      setIsAllowed(false);
    }
  };

  const addAddtionalLinksHandler = async () => {
    const reg_pattern = /(http(s)?:\\)?([\w-]+\.)+[\w-]+[.com|.in|.org]+(\[\?%&=]*)?/;
    if (link === "" || link === null) {
      setIsUrlCorrect(true);
      return;
    }
    if (!reg_pattern.test(link)) {
      setIsUrlCorrect(true);
      return;
    }
    try {
      setLoadingList(true);
      const addResult = {
        url: link,
        userId: user.userId
      };
      const response = await ApiClient.post(
        sessionPackEndPoints.addSessionAddtionalLinks(sessionId),
        addResult
      );
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        getSessionDetails();
        toast.success(response.message);
        googleWebAnalytics("View_Session_Add_Additional_Link", "Button", "Session");
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoadingList(false);
      setFailed(true);
    }
  };

  const removeAddtionalLink = async (data: any) => {
    setRemoveLinkData(data);
    setIsDeteleLink(true);
  };

  const removeLinkHandler = async (value: any) => {
    try {
      setLoadingList(true);
      const sessionId = removeLinkData.sessionId;
      const linkId = removeLinkData.id;
      if (value === true) {
        const response = await ApiClient.delete(
          sessionPackEndPoints.deleteAdditionalLink(sessionId, linkId)
        );
        if (response?.responseCode === HTTP_STATUS_CODES.ok) {
          toast.success(response?.message);
          getSessionDetails();
          setIsDeteleLink(false);
        } else {
          toast.error(response?.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setIsDeteleLink(false);
      setLoadingList(false);
      setFailed(true);
    }
  };

  useEffect(() => {
    let temp;
    let membersArray = props?.sessionDetails?.members?.map((i: any) => {
      return i.userId;
    });
    if (
      userRolesData?.userRolesData?.response.includes("PROGRAM_ADMIN") ||
      userRolesData?.userRolesData?.response.includes("ENTITY_ADMIN")
    ) {
      temp = false;
      if (props?.sessionDetails?.isSessionCreator == true || membersArray?.includes(user?.userId)) {
        temp = true;
      }
    } else if (
      props?.sessionDetails?.isSessionCreator == true ||
      membersArray?.includes(user?.userId)
    ) {
      temp = true;
    }
    // else temp = true;
    setShowAdditionLinks(temp);
  }, [userRolesData, props?.sessionDetails, user]);

  const clearHandler = () => {
    setLink("");
    googleWebAnalytics("View_Session_Clear_Additional_Link", "Button", "Session");
  };

  return (
    <View style={styles.container}>
      <CollapseItem
        headerStyles={[styles.collapseHeaderContainer]}
        isExpanded={false}
        headerComponent={t("session:additionalLinks")}
        children={
          <View>
            {loadingList ? (
              <Loader customLoadingContainer={{ height: 250 }} />
            ) : failed ? (
              <View>Failed to load data</View>
            ) : (
              <View style={width > 960 ? styles.collapseContainer : { width: "100%", padding: 16 }}>
                {!isSessionCompleted && (
                  <View>
                    {isAllowed && (
                      <View style={styles.subCollapseContainer}>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between"
                          }}
                        >
                          <View
                            style={[
                              styles.textFieldContianer,
                              styles.textFieldContianerAlignment,
                              { flex: 90 }
                            ]}
                          >
                            <TextField
                              label=""
                              handleChange={(link: string) => {
                                [setLink(link), setIsUrlCorrect(false)];
                              }}
                              handleBlur={() => {}}
                              inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                              placeholder={t("session:addLinks")}
                              name="addLinks"
                              id="addLinks"
                              noFormik
                              value={link}
                            />
                          </View>
                          <View style={{ flex: 10, alignContent: "center", alignItems: "center" }}>
                            <View>
                              <View style={styles.iconMainContainer}>
                                <TouchableOpacity style={styles.iconContainer}>
                                  <View>
                                    <Icon
                                      name={IconNames.close}
                                      customStyle={styles.iconStyle}
                                      onPress={() => clearHandler()}
                                    />
                                  </View>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.iconContainer}>
                                  <View>
                                    <Icon
                                      name={IconNames.tick}
                                      customStyle={styles.iconStyle}
                                      onPress={() => addAddtionalLinksHandler()}
                                    />
                                  </View>
                                </TouchableOpacity>
                              </View>
                              {/* {isUrlCorrect && link !== null && link !== "" && (
                                <View style={[styles.iconMainContainer, { opacity: 0.2 }]}>
                                  <TouchableOpacity style={styles.iconContainer}>
                                    <View>
                                      <Icon name={IconNames.close} customStyle={styles.iconStyle} />
                                    </View>
                                  </TouchableOpacity>
                                  <TouchableOpacity style={styles.iconContainer}>
                                    <View>
                                      <Icon name={IconNames.tick} customStyle={styles.iconStyle} />
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              )} */}
                            </View>
                          </View>
                        </View>

                        {isUrlCorrect &&
                          ((link !== null && link !== "") || link === null || link === "") && (
                            <View>
                              <Text
                                fontWeight={FontWeight.Regular}
                                testId="internal"
                                textSize={TextSize.Small}
                                textStyle={[styles.headerText, styles.errorText]}
                              >
                                {t("session:validUrl")}
                              </Text>
                            </View>
                          )}
                      </View>
                    )}
                  </View>
                )}
                <View style={styles.linksListContainer}>
                  {additionalLinksList?.map((prop: any, key: React.Key) => {
                    return (
                      <View>
                        <View key={key} style={{ flexDirection: "row" }}>
                          <View style={[styles.linkListItem, { flex: 90 }]}>
                            <View>
                              <a
                                href={
                                  prop.sessionUrl.includes("http") ||
                                  prop.sessionUrl.includes("https")
                                    ? prop.sessionUrl
                                    : `//${prop.sessionUrl}`
                                }
                                target="_blank"
                              >
                                <Text
                                  fontWeight={FontWeight.Light}
                                  testId="addRoleText"
                                  textSize={TextSize.Small}
                                  textStyle={[styles.headerText]}
                                >
                                  {prop.sessionUrl}
                                </Text>
                              </a>
                            </View>
                            {!isSessionCompleted && showAdditionalLinks && (
                              <View>
                                <TouchableOpacity onPress={() => removeAddtionalLink(prop)}>
                                  <Image
                                    testId="remove"
                                    source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                                    imageStyle={{
                                      width: 20,
                                      height: 20
                                    }}
                                  />
                                </TouchableOpacity>
                              </View>
                            )}
                          </View>
                          <View style={{ flex: 10 }}></View>
                        </View>
                        <View
                          style={
                            key === additionalLinksList.length - 1
                              ? { display: "none" }
                              : styles.borderContainer
                          }
                        ></View>
                      </View>
                    );
                  })}
                  {additionalLinksList?.length === 0 && (
                    <View>
                      <Text
                        fontWeight={FontWeight.Light}
                        testId="text"
                        textSize={TextSize.Small}
                        textStyle={[styles.headerText]}
                      >
                        {t("session:noLinks")}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            )}
            {isDeleteLink && (
              <View>
                <SocionModal
                  modalVisible={isDeleteLink}
                  setModalVisible={() => setIsDeteleLink(!isDeleteLink)}
                  component={
                    <RemoveLinkModal
                      modalVisible={isDeleteLink}
                      selectedData={removeLinkHandler}
                      textValue={t("session:removeLink")}
                      setModalVisible={() => setIsDeteleLink(!isDeleteLink)}
                    />
                  }
                />
              </View>
            )}
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  collapseHeaderContainer: {},
  collapseContainer: {
    padding: 16,
    width: "60%"
  },
  subCollapseContainer: {
    minHeight: 60
  },

  //textField
  textFieldContianer: {
    // flex: 40,
    // width:"15%",
    marginLeft: 15,
    borderColor: colorPallete.cordioTaupe
  },
  textFieldContianerAlignment: {
    marginLeft: 0,
    flex: 100
  },
  inputStyling: {
    height: 40,
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Medium
  },
  inputStylingAlignment: {
    width: "100%"
  },
  addLinkIcon: {},

  //links List
  linksListContainer: {
    // marginTop: 5
  },
  linkListItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 15,
    paddingLeft: 15
  },
  borderContainer: {
    borderBottomColor: colorPallete.cordioTaupe,
    borderBottomWidth: 1,
    borderStyle: "solid",
    opacity: 0.1,
    width: "90%"
  },
  crossCircleIcon: {
    opacity: 0.5
  },

  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
    // marginBottom: 5
  },
  errorText: {
    // alignSelf: "center",
    color: colorPallete.cordioRed,
    marginTop: 5
  },
  iconMainContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: 12
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    fontSize: 17
  }
});
