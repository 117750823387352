import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { useHistory } from "react-router-dom";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Header from "@socion-cordio/common/src/components/organisms/accomplishment/components/header";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/accomplishment/components/programDropdown";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { toast } from "react-toastify";
import {
  accomplishments,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function Accomplishment(): ReactElement {
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [filteredSelectedProgram, setFilteredSelectedProgram] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [usersId, setUsersId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const history: any = useHistory();
  const [mainProgramList, setMainProgramList] = useState([]);
  const [dropDownSelectedProgram, setDropDownSelectedProgram] = useState([]);
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedProgram.length !== 0) {
      LocalStorage.setStorage("selectedProgram", selectedProgram);
      // setSelectedProgram(selectedProgram);
      let filteredProgram = mainProgramList?.filter(
        (k: any) => k.programId == selectedProgram.value
      );
      setFilteredSelectedProgram(filteredProgram[0]);
    }
  }, [selectedProgram]);

  useEffect(() => {
    getAllUserPrograms();
  }, []);

  const getAllUserPrograms = async () => {
    try {
      setIsLoading(true);
      const urlParams = window.location.pathname.split("/").reverse();
      const programId = urlParams[2];
      const userId: any = urlParams[1];
      const accessToken: any = urlParams[0];
      setUsersId(userId);
      setAccessToken(accessToken);

      const headers = {
        "access-token": accessToken,
        accept: "application/json"
      };

      try {
        const getUserDetailsResponse: any = await axios.get(
          springshedEndPoints.getProfileByUserId(userId),
          { headers: headers }
        );
        await LocalStorage.setStorage("user", getUserDetailsResponse?.data);
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }

      await LocalStorage.setStorage("accessToken", accessToken);

      try {
        const response = await ApiClient.post(accomplishments.getAllProgramsByUsers(), {});
        if (response?.response) {
          let optionsData = response?.response.map((i: any) => {
            return { label: i.programName, value: i.programId };
          });
          setOptions(optionsData);
          let filteredId = optionsData?.filter((k: any) => k.value == programId);
          let filteredProgram = response?.response?.filter((k: any) => k.programId == programId);
          setFilteredSelectedProgram(filteredProgram[0]);
          // setSelectedProgram(filteredId[0]);
          setDropDownSelectedProgram(filteredId[0]);
          setMainProgramList(response?.response);
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.");
      setIsLoading(false);
    }
  };

  const handleCardClick = (data: string) => {
    switch (data) {
      case "Qualification Packs":
        history.push(
          `${routeNames.app}${routeNames.ACCOMPLISHMENTQP}/${dropDownSelectedProgram.value}/${usersId}/${accessToken}`,
          {
            programOptions: options,
            programData: dropDownSelectedProgram,
            userId: usersId,
            accessToken: accessToken,
            mainProgramList: mainProgramList,
            isBackEnabled: true
          }
        );
        break;
      case "Tasks":
        history.push(
          `${routeNames.app}${routeNames.ACCOMPLISHMENTTASKS}/${dropDownSelectedProgram.value}/${usersId}/${accessToken}`,
          {
            programOptions: options,
            programData: dropDownSelectedProgram,
            userId: usersId,
            accessToken: accessToken,
            mainProgramList: mainProgramList,
            isBackEnabled: true
          }
        );
        break;
      case "Attendance":
        history.push(
          `${routeNames.app}${routeNames.ATTENDANCE}/${dropDownSelectedProgram.value}/${usersId}/${accessToken}`,
          {
            programOptions: options,
            programData: dropDownSelectedProgram,
            userId: usersId,
            accessToken: accessToken,
            isBackEnabled: true
          }
        );
        break;
      case "Artefact":
        history.push(
          `${routeNames.app}${routeNames.ACCOMPLESMENTARTEFACT}/${dropDownSelectedProgram.value}/${usersId}/${accessToken}`,
          {
            programOptions: options,
            programData: dropDownSelectedProgram,
            userId: usersId,
            accessToken: accessToken,
            mainProgramList: mainProgramList,
            isBackEnabled: true
          }
        );
        break;
    }
  };

  const getCards = (data: any, iconName: string) => {
    return (
      <TouchableOpacity onPress={() => handleCardClick(data)}>
        <View style={styles.card}>
          <View style={styles.flexContainer}>
            <Icon
              name={
                iconName == "files"
                  ? IconNames.qualificationPack
                  : iconName == "attendance"
                  ? IconNames.attendanceSecondary
                  : iconName == "tasks"
                  ? IconNames.taskSecondary
                  : IconNames.artefact
              }
              customStyle={styles.cardIcons}
            />
            <View style={styles.ml20}>
              <Text
                testId="accomplishment"
                fontWeight={FontWeight.Medium}
                textSize={TextSize.Small}
                style={[styles.cardHeader]}
              >
                {data}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <View style={styles.body}>
        <ProgramDropdown
          selectedProgram={dropDownSelectedProgram}
          setSelectedProgram={setSelectedProgram}
          setDropDownSelectedProgram={setDropDownSelectedProgram}
          options={options}
        />
        <View style={styles.cardsContainer}>
          {filteredSelectedProgram?.QPAccess &&
            getCards(t("accomplishment:qualificationPacks"), "files")}
          {getCards(t("accomplishment:attendance"), "attendance")}
          {filteredSelectedProgram?.TaskAccess && getCards(t("accomplishment:tasks"), "tasks")}
          {filteredSelectedProgram?.ArtefactAccess &&
            getCards(t("accomplishment:artefact"), "artifact")}
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  marginTop15: {
    marginTop: 15
  },
  body: {
    backgroundColor: colorPallete.cordioBeige,
    height: "100vh",
    paddingHorizontal: 16
  },
  cardsContainer: {
    marginTop: 12,
    zIndex: -1
  },
  card: {
    height: 50,
    width: "100%",
    backgroundColor: colorPallete.white,
    borderRadius: 5,
    marginBottom: 5,
    paddingVertical: 15,
    paddingLeft: 16,
    position: "relative"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  cardHeader: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    marginTop: 2
  },
  cardIcons: {
    color: "#646363"
  },
  ml20: {
    marginLeft: 20
  }
});
