import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  accomplishments,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Header from "@socion-cordio/common/src/components/organisms/accomplishment/components/header";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/accomplishment/components/programDropdown";
import { borderTopLeftRadius } from "html2canvas/dist/types/css/property-descriptors/border-radius";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { useLocation, useHistory } from "react-router-dom";
import { color } from "html2canvas/dist/types/css/types/color";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Moment from "moment";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function ATTENDANCE(): ReactElement {
  const [attendancedata, setAttendancedata] = useState({});
  const [selectedProgram, setSelectedProgram] = useState({});
  const [dropDownSelectedProgram, setDropDownSelectedProgram] = useState({});
  const history: any = useHistory();
  const location: any = useLocation();
  const [usersId, setUsersId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [mainProgramList, setMainProgramList] = useState([]);
  const [filteredSelectedProgram, setFilteredSelectedProgram] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [isBackEnabled, setIsBackEnabled] = useState(location?.state?.isBackEnabled ?? false);

  // const { isBackEnabled = false } = location.state;
  const { t } = useTranslation();

  useEffect(() => {
    // getSelectedProgram();
    // setSelectedProgram(programData);
    getAllUserPrograms();
  }, []);

  useEffect(() => {
    if (dropDownSelectedProgram) {
      LocalStorage.setStorage("selectedProgram", dropDownSelectedProgram);
      setDropDownSelectedProgram(dropDownSelectedProgram);
    }
  }, [dropDownSelectedProgram]);

  useEffect(() => {
    if (selectedProgram && selectedProgram.value) getAttendanceData();
  }, [dropDownSelectedProgram]);

  const getSelectedProgram = async () => {
    let program = await LocalStorage.getStorage("selectedProgram");
    setDropDownSelectedProgram(program);
  };

  const getAllUserPrograms = async () => {
    try {
      setIsLoading(true);
      const urlParams = window.location.pathname.split("/").reverse();
      const programId = urlParams[2];
      const userId: any = urlParams[1];
      const accessToken: any = urlParams[0];
      setUsersId(userId);
      setAccessToken(accessToken);

      await LocalStorage.setStorage("accessToken", accessToken);

      try {
        const response = await ApiClient.post(accomplishments.getAllProgramsByUsers(), {});
        if (response?.response) {
          setMainProgramList(response?.response);
          let optionsData = response?.response.map((i: any) => {
            return { label: i.programName, value: i.programId };
          });
          setOptions(optionsData);
          let filteredId = optionsData?.filter((k: any) => k.value == programId);
          let filteredProgram = response?.response?.filter((k: any) => k.programId == programId);
          console.log(filteredId[0], filteredProgram[0]);
          setFilteredSelectedProgram(filteredProgram[0]);
          setSelectedProgram(filteredId[0]);
          setDropDownSelectedProgram(filteredId[0]);
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.");
      setIsLoading(false);
    }
  };

  const getAttendanceData = async () => {
    try {
      setDropDownSelectedProgram(selectedProgram);
      const attendanceResponse = await ApiClient.post(
        accomplishments.getAtendanceList(selectedProgram.value, usersId),
        {}
      );
      setAttendancedata(attendanceResponse.response);
    } catch (error) {
      console.log(error);
    }
  };

  const renderMonthRow = (key: any, value: any) => {
    return (
      <View style={styles.tableMonthRow}>
        <Text
          fontWeight={FontWeight.Light}
          testId={key}
          textSize={TextSize.Small}
          textStyle={styles.secondaryHeaderText}
        >
          {Moment(key).format("MMMM YYYY")}
        </Text>
      </View>
    );
  };

  const renderAttendanceRow = (key: any, value: any) => {
    return (
      <View style={styles.tableAttendanceRow}>
        <Text
          fontWeight={FontWeight.Light}
          testId={value}
          textSize={TextSize.Small}
          textStyle={styles.secondaryHeaderText}
        >
          {value}
        </Text>
      </View>
    );
  };

  const handleBack = () => {
    history.push(
      `${routeNames.app}${routeNames.ACCOMPLISHMENT}/${selectedProgram.value}/${usersId}/${accessToken}`
    );
  };

  return (
    <>
      <View style={styles.body}>
        <ProgramDropdown
          selectedProgram={dropDownSelectedProgram}
          setSelectedProgram={setSelectedProgram}
          options={options}
          setDropDownSelectedProgram={setDropDownSelectedProgram}
        />
        <View style={{ zIndex: -1 }}>
          <View style={styles.headingContainer}>
            {isBackEnabled && (
              <View style={styles.mt2}>
                <TouchableOpacity onPress={handleBack}>
                  <Icon name={IconNames.leftArrow} customStyle={styles.leftarrow} />
                </TouchableOpacity>
              </View>
            )}
            <View style={isBackEnabled ? styles.ml20 : { marginLeft: 0 }}>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.AttendanceHeaderText]}
              >
                {t("accomplishment:attendance")}
              </Text>
            </View>
          </View>
          {Object.keys(attendancedata).length !== 0 ? (
            <View style={styles.tableContainer}>
              <View style={{ flex: 1 }}>
                <View style={styles.tableMonthHeader}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="rowText"
                    textSize={TextSize.Small}
                    textStyle={styles.headerText}
                  >
                    {t("accomplishment:month")}
                  </Text>
                </View>
                {Object.entries(attendancedata).map(([key, value]) => {
                  return renderMonthRow(key, value);
                })}
              </View>
              <View style={{ flex: 1 }}>
                <View style={styles.tableAttendanceHeader}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="rowText"
                    textSize={TextSize.Small}
                    textStyle={styles.headerText}
                  >
                    {t("accomplishment:sessionsattended")}
                  </Text>
                </View>
                {Object.entries(attendancedata).map(([key, value]) => {
                  return renderAttendanceRow(key, value);
                })}
              </View>
            </View>
          ) : (
            <View style={styles.emptyTable}>{t("common:noData")}</View>
          )}
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  marginTop15: {
    // marginTop: 20,
    color: colorPallete.cordioTaupe,
    marginLeft: 20,
    paddingTop: 17
  },
  body: {
    backgroundColor: colorPallete.cordioBeige,
    minHeight: "100vh",
    paddingHorizontal: 16
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20
  },
  ml20: {
    marginLeft: 20
  },
  mt2: {
    marginTop: 2
  },
  leftarrow: {
    color: colorPallete.cordioTaupe,
    width: 6,
    height: 12
  },
  tableContainer: {
    width: "100%",
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    marginTop: 20,
    display: "flex",
    flexDirection: "initial",
    zIndex: -1
  },
  emptyTable: {
    width: "100%",
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    marginTop: 20,
    display: "flex",
    flexDirection: "initial",
    padding: 10,
    zIndex: -1
  },
  tableMonthHeader: {
    height: 40,
    backgroundColor: colorPallete.lightGrey,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.lightGreyThree,
    borderTopLeftRadius: 10,
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 10
  },
  tableAttendanceHeader: {
    height: 40,
    backgroundColor: colorPallete.lightGrey,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.lightGreyThree,
    borderTopRightRadius: 10,
    alignItems: "center",
    justifyContent: "center"
  },
  tableMonthRow: {
    justifyContent: "center",
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.lightGreyThree,
    backgroundColor: colorPallete.white,
    alignItems: "flex-start",
    padding: 10
  },
  tableAttendanceRow: {
    justifyContent: "center",
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorPallete.lightGreyThree,
    backgroundColor: colorPallete.white,
    alignItems: "center"
  },
  secondaryHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  headerText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "bold"
  },
  AttendanceHeaderText: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 24,
    color: colorPallete.cordioTaupe
  },
  backArrowTitleIcon: {
    color: colorPallete.cordioTaupe,
    fontSize: 9,
    marginTop: 22,
    transform: [{ rotate: "90deg" }]
  }
});
