import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import EmailInput from "@socion-cordio/common/src/components/molecules/emailInput";
import EmailInputPrimary from "@socion-cordio/common/src/components/molecules/emailInputPrimary";
import { toast, ToastContainer } from "react-toastify";
import OtpValidate from "@socion-cordio/common/src/components/molecules/otpValidate";
import { ProfileRepository } from "@socion-cordio/common/src/repositories/profile/profile";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {
  onClose: Function;
  modalTitle?: string;
  isEmailChangeClicked?: boolean;
  userProfileData?: any;
  isChangeEmailClicked?: boolean;
}

export default function AddEmail(props: Props): ReactElement {
  const { isEmailChangeClicked, isChangeEmailClicked, userProfileData, onClose } = props;
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [incorrectEmailMsg, setIncorrectEmailMsg] = useState(null);
  const [isOtpSentToEmail, setIsOtpSentToEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [checkToCallResend, setCheckToCallResend] = useState(false);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setUserDetails(user);
  };

  const updateEmail = async () => {
    const emailPattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!email?.trim().replace(" ", "").match(emailPattern)) {
      setError(true);
      return;
    }
    if (isEmailChangeClicked || isChangeEmailClicked) {
      emailOtpHandler(false);
      setCheckToCallResend(true);
      return;
    }
    const updateEmailResponse = await ApiClient.get(
      profileEndPoints.updateEmail(email?.trim().replace(" ", ""))
    );
    if (updateEmailResponse.responseCode === HTTP_STATUS_CODES.ok) {
      setIsOtpSentToEmail(true);
      // setEmailSent(true);
      // toast.success("Email ID updated successfully");
    } else {
      toast.error(updateEmailResponse?.message);
    }
  };

  const emailOtpHandler = async (isResendClicked: boolean) => {
    const user: any = await LocalStorage.getStorage("updatedProfileInfo");
    const params = {
      emailId: email?.trim().replace(" ", ""), //user.phoneNumber ? user.phoneNumber : user.emailId,
      verificationType: user.phoneNumber ? "PHONE_NUMBER" : "EMAIL_ID"
    };
    setLoading(true);
    const updateEmailResponse = ProfileRepository.updateEmailOtp(params);
    updateEmailResponse
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setIsOtpSentToEmail(true);
          setLoading(false);
          isResendClicked && toast.success("OTP sent successfully");
        } else {
          setLoading(false);
          // toast.error(res.message);
          setIncorrectEmailMsg(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again later.");
        setLoading(false);
      });
  };

  const handleEmail = (email: string) => {
    // const emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    // const emailPattern =
    //   /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    // if (email.match(emailPattern)) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
    setEmail(email);
    setIncorrectEmailMsg(null);
  };
  return (
    <View>
      {!isOtpSentToEmail ? (
        <View>
          {emailSent ? (
            <View>
              <View style={styles.modalHeader}>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={styles.headerText}
                >
                  {props.modalTitle}
                </Text>
                <View>
                  <Pressable onPress={() => onClose()}>
                    <Icon testID="close" name={IconNames.crossCircle} />
                  </Pressable>
                </View>
              </View>
              <View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  style={{ fontSize: 14 }}
                >
                  Please click on the link sent to this updated email to complete the verification
                  process.
                </Text>
              </View>
            </View>
          ) : (
            <View>
              <ToastContainer />
              <EmailInputPrimary
                title={userDetails?.emailId ? "Change email address" : "Enter email address"}
                onClose={() => onClose()}
                testId="addEmailAddressText"
                label="Email"
                handleChange={(email: string) => {
                  handleEmail(email);
                  setError(false);
                }}
                value={email?.replace(/\s/g, "")}
                placeholder="Enter email"
                name="addEmail"
                id="addEmail"
                handleSubmit={() => updateEmail()}
                disabled={error || !email || loading}
                error={error}
                errorMessage="Please enter valid email"
                serverError={incorrectEmailMsg}
              />
            </View>
          )}
        </View>
      ) : (
        <View>
          <OtpValidate
            onClose={() => onClose()}
            modalTitle="Change email address"
            headerText="Please enter the code that was sent to the registered email"
            subHeaderText="Haven’t received an email?"
            showEmail={true}
            isEmailChangeClicked={isEmailChangeClicked}
            emailOtpHandler={emailOtpHandler}
            checkToCallResend={checkToCallResend}
            newEmail={email?.trim().replace(" ", "")}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  alignSubmitButton: {
    marginTop: 50
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  emailInputContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center"
  },
  subHeaderContainer: {
    marginTop: 10,
    marginRight: 15
  },
  inputRole: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "330px"
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  }
});
