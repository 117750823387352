import React, { useCallback, useEffect, useRef, useState } from "react";
import { Pressable, StyleSheet, TouchableOpacity, View } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import FilePicker from "@socion-cordio/common/src/components/molecules/filePicker";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import Moment from "moment";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { ProfileRepository } from "@socion-cordio/common/src/repositories/profile/profile";

interface Props {
  onClose: Function;
  profilePicture?: string;
}

export default function AddProfileModal(props: Props) {
  const [loading, setLoading] = useState(false);
  const [imageAdded, setImageAdded] = useState(false);
  const [fileName, setFileName] = useState();
  const { onClose, profilePicture } = props;
  const data: any = useSelector((state) => state);
  const dispatch = useDispatch();

  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 8 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const hiddenFileInput = React.useRef(null);

  const savePhoto = async (canvas: any, crop: any) => {
    setLoading(true);
    if (!crop || !canvas) {
      return;
    }
    const convasblob = await canvas.toBlob(
      async (blob: any) => {
        let formData: FormData = new FormData();
        formData.append("appType", "pda");
        formData.append("file", blob);
        formData.append("fileName", fileName);
        formData.append("fileType", "profile-picture");
        const response = await ApiClient.put(profileEndPoints.fileUpload(), formData);
        if (response.responseCode === HTTP_STATUS_CODES.ok) {
          await updateProfileApiCall(response?.response, false);
        } else {
          toast.error(response.message);
        }
        // setLoading(false);
      },
      "image/png",
      1
    );
    googleWebAnalytics("Profile_Photo_Save", "Icon", "Profile");
  };

  const handleFileSelect = async (e: any) => {
    const validTypes = ["png", "jpg", "jpeg"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    if (e.target.files && e.target.files.length > 0 && validTypes.includes(extension)) {
      setImageAdded(true);
      setFileName(e?.currentTarget?.files[0]?.name);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    } else {
      toast.error("Invalid File Type! Only Files with format of png, jpg, jpeg will be uploaded!!");
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const updateProfileApiCall = async (newProfileUrl: any, isPhotoRemoved: boolean) => {
    updatePhoto(isPhotoRemoved, newProfileUrl.url);
  };

  const updateTelemetry = (data: any) => {
    const body = {
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Profile edit-Photo change"
    };
    const userDetails = data?.response;
    AddTelemetryService(body, undefined, userDetails);
    googleWebAnalytics("Profile_Edit_Photo", "Telemetry_Event", "Profile");
  };

  const updatePhoto = async (isRemove: boolean, photoUploadUrl: string) => {
    setLoading(true);
    let queryParams = null;
    queryParams = isRemove
      ? {
          isRemovePhoto: isRemove
        }
      : {
          isRemovePhoto: isRemove,
          imageUrl: photoUploadUrl
        };
    let response = ProfileRepository.updateUserPhoto(queryParams);
    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          toast.success(res.message);
          dispatch(ProfileActions.getUserProfile());
          props.onClose();
          updateTelemetry(data?.profile?.userProfileData);
          setLoading(false);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const removePhoto = async () => {
    // setLoading(true);
    const newProfileUrl: any = { url: "" };
    await updateProfileApiCall(newProfileUrl, true);
    // setLoading(false);
    const user: any = await LocalStorage.getStorage("user");
    user.photo = "";
    LocalStorage.setStorage("user", user);
    googleWebAnalytics("Profile_Delete_Photo", "Icon", "Profile");
  };

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
    googleWebAnalytics("Profile_Upload_Change", "Icon", "Profile");
  };

  return (
    <>
      <ToastContainer />
      {loading && <Loader />}
      {!loading && (
        <View>
          <ToastContainer />
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addEmailText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {"Add Profile picture"}
            </Text>
            <View>
              <Pressable onPress={() => onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>

          {!imageAdded && profilePicture == "" && (
            <View style={styles.uploadPictureContainer}>
              <View style={styles.uploadPicture}>
                <View style={styles.uploadPictureText}>
                  <FilePicker
                    acceptedFormat=".png,.jpg,.jpeg"
                    onChange={async (e) => {
                      await handleFileSelect(e);
                    }}
                    contentText={`Upload your profile picture`}
                    profileUpload={true}
                  />
                  <View>
                    <Icon
                      testID="upload"
                      name={IconNames.uploadProfile}
                      customStyle={styles.customUpload}
                      onPress={handleClick}
                    />
                    <input
                      style={{
                        display: "none",
                        zIndex: -1
                      }}
                      ref={hiddenFileInput}
                      type="file"
                      onChange={async (e) => {
                        await handleFileSelect(e);
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
          )}
          {!imageAdded && profilePicture !== "" && (
            <>
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
                <View style={{ maxHeight: "500px", maxWidth: "500px" }}>
                  <Image
                    testId="socionProfileImg"
                    imageStyle={styles.socionProfileImg}
                    source={{ uri: profilePicture }}
                  />
                </View>
                <View style={styles.buttonContainer2}>
                  <View>
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.button}
                      title="Change image"
                      onPress={handleClick}
                    />
                    <input
                      style={{
                        display: "none",
                        zIndex: -1
                      }}
                      ref={hiddenFileInput}
                      type="file"
                      onChange={async (e) => {
                        await handleFileSelect(e);
                      }}
                    />
                  </View>
                  <View>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.savebutton}
                      title="Delete image"
                      onPress={() => removePhoto()}
                    />
                  </View>
                </View>
              </View>
            </>
          )}

          {imageAdded && (
            <>
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
                <View style={{ maxHeight: "500px", maxWidth: "500px" }}>
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c: any) => setCrop(c)}
                    onComplete={(c: any) => setCompletedCrop(c)}
                    keepSelection={true}
                  />
                </View>
                <View style={{ opacity: 0, height: 0 }}>
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0)
                    }}
                  />
                </View>
                <View style={styles.buttonContainer}>
                  <View>
                    {/* <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.savebutton}
                      title="Delete"
                      onPress={() => removePhoto()}
                    /> */}
                  </View>

                  <View>
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.button}
                      title="Change image"
                      // onPress={() => setImageAdded(false)}
                      onPress={handleClick}
                    />
                    <input
                      style={{
                        display: "none",
                        zIndex: -1
                      }}
                      ref={hiddenFileInput}
                      type="file"
                      onChange={async (e) => {
                        await handleFileSelect(e);
                      }}
                    />
                  </View>
                  <View>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.savebutton}
                      title="Save"
                      onPress={() => savePhoto(previewCanvasRef.current, completedCrop)}
                    />
                  </View>
                </View>
              </View>
            </>
          )}
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  uploadPictureContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "1rem"
  },
  uploadPicture: {
    width: "450px",
    height: "300px",
    backgroundColor: "#F8F8F8",
    borderRadius: 5
  },
  uploadPictureText: {
    margin: "auto",
    color: colorPallete.textBlack,
    fontSize: 14,
    opacity: 0.5
  },
  customUpload: {
    alignSelf: "center",
    // marginTop: 10,
    opacity: 0.8
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 20,
    marginRight: 20
  },
  savebutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: "auto"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem"
  },
  socionProfileImg: {
    width: 260,
    height: 260
  },
  buttonContainer2: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    marginRight: "20px"
  }
});
