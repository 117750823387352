import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import { FontFamily } from "@socion-cordio/common/src/components/atoms/text";
import RoleVerification from "@socion-cordio/common/src/components/organisms/verify/roleVerification";
import QualificationVerification from "@socion-cordio/common/src/components/organisms/verify/qualificationVerification";
import TaskVerification from "@socion-cordio/common/src/components/organisms/verify/taskVerification";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {}

export default function VerifyPage(props: Props): ReactElement {
  const [isRoleVerification, setIsRoleVerification] = useState<boolean>(true);
  const [isQualificationVerification, setIsQualificationVerification] = useState<boolean>(false);
  const [isTaskVerification, setIsTaskVerification] = useState<boolean>(false);
  const [isRolesEnabled, setIsRolesEnabled] = useState<boolean>(false);
  const [isQualificationEnabled, setIsQualificationEnabled] = useState<boolean>(false);
  const [isTasksEnabled, setIsTasksEnabled] = useState<boolean>(false);

  const [programData, setProgramData] = useState(undefined);
  const location: any = useLocation();

  const programDetails: any = JSON.parse(localStorage.getItem("programDetails"));

  let roles = useSelector((state: any) => state?.userRolesProgram);
  let userRoles = roles.userRolesData.response;

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getProgramDetails = async () => {
    const programDetail: any = await LocalStorage.getStorage("programDetails");
    setProgramData(programDetail);
  };

  useEffect(() => {
    (async () => {
      await processActiveTab();
    })();
  }, [location?.state?.activeTab]);

  useEffect(() => {
    validateAccessHandler();
  }, [programData]);

  const validateAccessHandler = () => {
    if (programData) {
      if (programData.program.acceptingRoles) {
        setIsRolesEnabled(true);
        // if roles is true then show roles table
        setIsRoleVerification(true);
        setIsQualificationVerification(false);
        setIsTaskVerification(false);
      }

      if (programData.program.acceptingQualifications) {
        setIsQualificationEnabled(true);
        // if quali true then show table
        if (!programData.program.acceptingRoles) {
          setIsRoleVerification(false);
          setIsQualificationVerification(true);
          setIsTaskVerification(false);
        }
      }

      if (programData.program.paTaskVerificationEnabled) {
        setIsTasksEnabled(true);
        if (!programData.program.acceptingRoles && !programData.program.acceptingQualifications) {
          setIsRoleVerification(false);
          setIsQualificationVerification(false);
          setIsTaskVerification(true);
        }
      }
    }
    processActiveTab();
  };

  const processActiveTab = () => {
    if (location?.state?.activeTab === "QUALIFICATION") {
      setIsRoleVerification(false);
      setIsQualificationVerification(true);
    } else if (location?.state?.activeTab === "ROLES") {
      setIsRoleVerification(true);
      setIsQualificationVerification(false);
    } else if (location?.state?.activeTab === "TASKS") {
      setIsRoleVerification(false);
      setIsQualificationVerification(false);
      setIsTaskVerification(true);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.toggleContainer}>
          {isRolesEnabled && (
            <ButtonTab
              type={
                isRolesEnabled && !isQualificationEnabled && !isTasksEnabled
                  ? ButtonTypeSecondary.Secondary
                  : isRoleVerification
                  ? ButtonTypeSecondary.Secondary
                  : ButtonTypeSecondary.Primary
              }
              buttonStyles={
                isRolesEnabled && (isQualificationEnabled || isTasksEnabled)
                  ? [styles.toggleButton, styles.borderLeftStyles]
                  : [styles.toggleButton, , { borderRadius: 5 }]
              }
              textStyles={styles.textStyle}
              title="Role verification"
              onPress={() => {
                setIsRoleVerification(true);
                setIsQualificationVerification(false);
                setIsTaskVerification(false);
              }}
            />
          )}
          {isQualificationEnabled && (
            <ButtonTab
              type={
                isQualificationVerification
                  ? ButtonTypeSecondary.Secondary
                  : ButtonTypeSecondary.Primary
              }
              buttonStyles={
                !isRolesEnabled && isQualificationEnabled && !isTasksEnabled
                  ? [styles.toggleButton, { borderRadius: 5 }]
                  : !isRolesEnabled && isQualificationEnabled && isTasksEnabled
                  ? [styles.toggleButton, styles.borderLeftStyles]
                  : isRolesEnabled && isQualificationEnabled && isTasksEnabled
                  ? [styles.toggleButton]
                  : [styles.toggleButton, styles.borderRightStyles]
              }
              textStyles={styles.textStyle}
              title="Qualification verification"
              onPress={() => {
                setIsRoleVerification(false);
                setIsQualificationVerification(true);
                setIsTaskVerification(false);
              }}
            />
          )}

          {isTasksEnabled && (
            <ButtonTab
              type={
                isTaskVerification ? ButtonTypeSecondary.Secondary : ButtonTypeSecondary.Primary
              }
              buttonStyles={
                (isRolesEnabled || isQualificationEnabled) && isTasksEnabled
                  ? [styles.toggleButton, styles.borderRightStyles]
                  : [styles.toggleButton, , { borderRadius: 5 }]
              }
              textStyles={styles.textStyle}
              title="Task verification"
              onPress={() => {
                setIsRoleVerification(false);
                setIsQualificationVerification(false);
                setIsTaskVerification(true);
              }}
            />
          )}
        </View>
        {isRoleVerification ? (
          <RoleVerification programDetails={programDetails} userRoles={userRoles} />
        ) : isQualificationVerification ? (
          <QualificationVerification programDetails={programDetails} userRoles={userRoles} />
        ) : isTaskVerification ? (
          <TaskVerification programDetails={programDetails} userRoles={userRoles} />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    fontSize: 15
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "20%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  textStyle: {
    fontSize: 12
  },
  alignHeader: {
    marginBottom: 30
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  iconStyle: {
    fontSize: 18,
    marginRight: 35
  },
  borderRightStyles: {
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5
  },
  borderLeftStyles: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5
  }
});
