import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement } from "react";
import { View, StyleSheet, StyleProp, TextStyle, ViewStyle, TouchableOpacity } from "react-native";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface IProps {
  onChange?: (value: any) => void;
  contentText?: string;
  profileUpload?: boolean;
  customDocsContainer?: any;
  acceptedFormat?: string;
  textStyles?: any;
}

export default function FilePicker(props: IProps): ReactElement {
  const {
    onChange,
    contentText,
    profileUpload,
    customDocsContainer,
    acceptedFormat = "",
    textStyles
  } = props;

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
    googleWebAnalytics("File_Upload", "Button", "Profile");
  };

  const handleOnClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <TouchableOpacity
      // style={{ zIndex: -1 }}
      onPress={handleClick}
    >
      <View style={[styles.uploadDocsContainer, customDocsContainer]}>
        <Text
          fontWeight={FontWeight.Regular}
          testId="addRoleTitleText"
          textSize={TextSize.Small}
          textStyle={[styles.uploadHeaderText, textStyles]}
        >
          <input
            style={{
              display: "none",
              zIndex: -1
            }}
            ref={hiddenFileInput}
            type="file"
            onChange={onChange}
            accept={acceptedFormat}
            onClick={handleOnClick}
          />
          {contentText || `Upload relevant content files`}
        </Text>
        {!profileUpload && (
          <Icon testID="close" name={IconNames.uploadFile} customStyle={styles.uploadDocIcon} />
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  uploadDocsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    borderRadius: 5,
    borderColor: "#F8F8F8",
    height: "64px",
    padding: 15,
    marginTop: 15
  },
  uploadDocIcon: {
    opacity: 0.5
  },
  uploadHeaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  }
});
