import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Switch } from "@socion-cordio/common/src/components/atoms/switches";
import { useSelector, useDispatch } from "react-redux";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ToastContainer, toast } from "react-toastify";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

export default function Settings(): ReactElement {
  const profileState = useSelector((state: any) => state?.profile?.userProfileData?.response);
  const [status, setStatus] = useState(profileState?.pushNotificationStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProfileActions.getUserProfile());
  }, []);

  useEffect(() => {
    setStatus(profileState?.pushNotificationStatus);
  }, [profileState]);

  const handleToggle = () => {
    updateStatus();
  };

  const updateStatus = async () => {
    const response = await ApiClient.put(profileEndPoints.pushNotification(!status));
    if (response.responseCode == HTTP_STATUS_CODES.ok) {
      await dispatch(ProfileActions.getUserProfile());
      toast.success(response.message);
      googleWebAnalytics(
        status ? "Settings_On_Push_Notification" : "Settings_Off_Push_Notification",
        "Button",
        "Settings"
      );
    }
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <Text
          fontWeight={FontWeight.Bold}
          testId="settings"
          textSize={TextSize.Large}
          textStyle={styles.heading}
        >
          Settings
        </Text>
        <View style={{ marginTop: 20, display: "flex", flexDirection: "row" }}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="notification"
            textSize={TextSize.Regular}
            textStyle={styles.heading}
          >
            Mobile push notifications
          </Text>
          <View style={{ marginLeft: 40 }}>
            <Switch
              isOn={status}
              onColor="#B7504A"
              offColor="#F1DCDB"
              labelStyle={{ color: "black", fontWeight: "900" }}
              size="medium"
              onToggle={handleToggle}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#F8F5F0",
    padding: 20,
    // height:'100vh'
    minHeight: "calc(100vh - 50px)"
  },
  container: {
    minHeight: "78vh",
    padding: 20,
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    height: "100%",
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  heading: {
    fontSize: 14
  }
});
