import React, { ReactElement, useEffect, useState } from "react";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { View, StyleSheet, TouchableOpacity, Linking } from "react-native";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import "react-datepicker/dist/react-datepicker.css";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";

interface IProps {
  noFormik?: boolean;
  sessionDetails?: any;
  isSessionCompleted?: any;
  setContentCollapse?: Function;
  contentCollapse?: boolean;
  setTabClicked?: Function;
}

export default function ViewContentSidePanel(props: IProps): ReactElement {
  const { t } = useTranslation();
  const { sessionDetails, isSessionCompleted, setContentCollapse, contentCollapse, setTabClicked } =
    props;
  const [contentList, setContentList] = useState([]);
  // const [contentCollapse, setContentCollapse] = useState(false); // default collapse
  const location: any = useLocation();

  useEffect(() => {
    if (sessionDetails !== undefined) {
      const { content } = sessionDetails?.topicInfo;
      setContentList(content);
    }
  }, []);

  const getFileName = (url: string) => {
    let name = url.split("/");
    return name[name.length - 1];
  };

  const validateSubContentUrl = (url: string) => {
    const urlCheck =
      url.includes("https://") || url.includes("http://")
        ? Linking.openURL(url)
        : Linking.openURL(`https://${url}`);
  };

  return (
    <View style={styles.container}>
      {/* <CollapseItem
        headerStyles={[styles.collapseHeaderContainer]}
        accordianIconStyle={styles.accordianIconStyle}
        isExpanded={false}
        bodyStyles={styles.bodyStyles}
        headerComponent={
          <View style={[styles.headerContainer, { marginLeft: 15 }]}>
            <Icon testID="badges" name={IconNames.badges} customStyle={styles.iconStyles} />
            <Text
              fontWeight={FontWeight.Medium}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={styles.textStyles}
            >
              Content
            </Text>
          </View>
        }
        children={ */}
      <TouchableOpacity
        onPress={() => {
          setContentCollapse((prevState) => !prevState);
          setTabClicked("content");
          auxiliaryMethods.scrollToTopHandler();
        }}
        style={styles.mainContainer}
      >
        <View style={[styles.headerContainer, { marginLeft: 15 }]}>
          <View style={{ marginLeft: -3, marginBottom: 5 }}>
            <View style={styles.paperClipContainer}>
              <Icon testID="paperClip" name={IconNames.paperClip} customStyle={styles.iconStyles} />
            </View>
          </View>
          <Text
            fontWeight={FontWeight.Medium}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={{ fontSize: 12, color: colorPallete.cordioBeige, marginTop: 4 }}
          >
            Content
          </Text>
        </View>
        <View>
          <Icon
            name={contentCollapse ? IconNames.up : IconNames.down}
            size={10}
            customStyle={[{ color: colorPallete.cordioBeige, fontSize: 8 }]}
          />
        </View>
      </TouchableOpacity>
      {contentCollapse && (
        <>
          <View style={styles.collapseContainer}>
            <View style={styles.contentListViewContainer}>
              <ScrollContainer
                height="500px"
                scrollable={contentList?.length > 5 ? true : false}
                style={styles.scrollContainer}
              >
                <div>
                  {contentList !== undefined &&
                    contentList.length !== 0 &&
                    contentList.map((prop, key: React.Key) => {
                      return (
                        <View key={key} style={styles.contentContainer}>
                          <View style={styles.miniContainer}>
                            <View style={styles.miniIconContainer}>
                              <Icon
                                testID="download"
                                name={
                                  mediaHelper.getIcons(prop?.contentType) === "image"
                                    ? IconNames.imageFile
                                    : mediaHelper.getIcons(prop?.contentType) === "video"
                                    ? IconNames.videoIcon
                                    : IconNames.documentFile
                                }
                                customStyle={[styles.alignContainerButton]}
                              />
                            </View>
                            <CollapseItem
                              headerStyles={[styles.subCollapseHeaderContainer]}
                              accordionStyles={styles.accordionStyles}
                              accordianIconStyle={styles.accordianIconStyleTwo}
                              bodyStyles={styles.bodyStylesTwo}
                              showAccordianIcon={prop.subContentList.length > 0 ? true : false}
                              isExpanded={false}
                              headerComponent={
                                <View style={styles.linkContainer}>
                                  <Text
                                    fontWeight={FontWeight.Medium}
                                    testId="headerText"
                                    textSize={TextSize.Small}
                                    textStyle={[
                                      styles.headerText,
                                      styles.miniContainerHeaderText,
                                      styles.headerAlign
                                      // { width: "12ch" }
                                    ]}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        prop.vimeo_url === null
                                          ? Linking.openURL(prop.url)
                                          : Linking.openURL(prop.vimeo_url);
                                      }}
                                    >
                                      <Text
                                        fontWeight={FontWeight.Medium}
                                        testId="headerText"
                                        textSize={TextSize.Small}
                                        textStyle={[
                                          styles.headerText,
                                          styles.miniContainerHeaderText,
                                          styles.headerAlign
                                          // { width: 200 }
                                        ]}
                                      >
                                        {prop.name}
                                      </Text>
                                    </TouchableOpacity>
                                  </Text>
                                </View>
                              }
                              children={
                                <View style={styles.subContentContainer}>
                                  {!dataHelper.isEmptyArray(prop?.subContentList) &&
                                    prop.subContentList.map((subContent: any) => (
                                      <Text
                                        fontWeight={FontWeight.Medium}
                                        testId="headerText"
                                        textSize={TextSize.Small}
                                        textStyle={[
                                          styles.headerText,
                                          // styles.miniContainerHeaderText,
                                          styles.headerAlign,
                                          styles.subContentTitleHeader
                                        ]}
                                      >
                                        <TouchableOpacity
                                          onPress={() => {
                                            validateSubContentUrl(subContent.url);
                                          }}
                                        >
                                          {subContent.title}
                                        </TouchableOpacity>
                                      </Text>
                                    ))}
                                </View>
                              }
                            />
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              mediaHelper.mediaDownloadHandler(
                                prop?.contentType,
                                getFileName(prop?.url)
                              );
                            }}
                          >
                            <View style={styles.miniIconContainer}>
                              <Icon
                                testID="download"
                                name={IconNames.downloadList}
                                customStyle={[styles.alignContainerButton]}
                              />
                            </View>
                          </TouchableOpacity>
                        </View>
                      );
                    })}
                </div>
              </ScrollContainer>

              {contentList.length === 0 && (
                <View style={styles.noContentContainer}>
                  <Text
                    fontWeight={FontWeight.Medium}
                    testId="text"
                    textSize={TextSize.Small}
                    textStyle={[styles.headerText, styles.headerTextTitleSupport]}
                  >
                    {t("session:noContentAvailable")}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </>
      )}
      {/* }
      /> */}
      <View style={styles.dividerStyles}></View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  mainContainer: {
    paddingTop: 10,
    paddingBottom: 2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dividerStyles: {
    borderColor: colorPallete.cordiolightGreyFour,
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 10
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  iconStyles: {
    fontSize: 20,
    color: colorPallete.cordioBeige,
    marginRight: 15
  },
  accordianIconStyle: {
    paddingHorizontal: 0,
    color: colorPallete.white,
    fontSize: 8,
    marginTop: -5
  },
  accordianIconStyleTwo: {
    color: colorPallete.black,
    paddingHorizontal: 5,
    fontSize: 6,
    marginTop: -4
  },
  childContainer: {
    backgroundColor: colorPallete.cordioRedDark2
  },
  collapseHeaderContainer: {
    backgroundColor: colorPallete.cordioRedDark2,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    paddingHorizontal: 0,
    paddingVertical: 10
  },
  collapseContainer: {
    // width: 200
    backgroundColor: colorPallete.cordioRedDark2,
    marginTop: 10
  },
  textStyles: {
    fontSize: 12,
    color: colorPallete.cordioBeige
  },
  subCollapseHeaderContainer: {
    padding: 0,
    backgroundColor: colorPallete.cordioBeigeLight2
  },
  alignContainer: {
    marginBottom: 15
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 20,
    color: colorPallete.white
  },
  noContentContainer: {
    marginLeft: 16,
    marginVertical: 10
  },
  headerAlign: {
    marginLeft: 5
  },
  alignNameWidth: {
    width: "20ch"
  },
  headerTextSupport: {
    fontSize: 10,
    lineHeight: 15,
    color: colorPallete.white
  },
  contentListViewContainer: {},
  scrollContainer: {
    backgroundColor: colorPallete.cordioRedDark2
  },
  contentContainer: {
    minHeight: 40,
    backgroundColor: colorPallete.cordioBeigeLight2,
    borderRadius: 5,
    marginBottom: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "center",
    padding: 5
  },
  miniContainer: {
    flexDirection: "row"
  },
  miniContainerHeaderText: {
    color: colorPallete.black,
    fontSize: 10,
    lineHeight: 15
    // width: "20ch"
  },
  subContentTitleHeader: {
    fontFamily: FontFamily.Regular,
    marginBottom: 5,
    textDecorationLine: "underline"
  },
  miniIconContainer: {
    marginTop: 8
  },
  alignContainerButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 5
  },
  descriptionContainer: {
    height: "100px",
    overflowY: "scroll"
  },
  linkContainer: {
    width: 210
  },
  subContentContainer: {
    marginTop: 5
  },
  collapseHeaderContainerTwo: {
    padding: 0,
    alignItems: "flex-start",
    shadowColor: "#ffffff0",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    backgroundColor: "#ffffff0",
    borderRadius: 0,
    // width: 550, // Collapsible width change,
    justifyContent: "flex-start",
    width: 140
  },
  accordionStyles: {
    shadowColor: "#ffffff0",
    backgroundColor: "#ffffff0",
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0,
    justifyContent: "center"
  },
  bodyStyles: {
    // backgroundColor: colorPallete.white,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  bodyStylesTwo: {
    backgroundColor: colorPallete.cordioBeigeLight2,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: 140
  },
  paperClipContainer: {
    transform: [{ rotate: "315deg" }]
  }
});
