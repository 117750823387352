export const serverErrors = {
  NOT_FOUND: "404 - PAGE NOT FOUND",
  SERVER_ERROR: "5XX - INTERNAL SERVER ERROR",
  CLIENT_ERROR: "-------"
};

export const HTTP_STATUS_CODES = {
  ok: 200,
  created: 201,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  unprocessableEntity: 422,
  internalServerError: 500,
  badGateway: 502,
  serviceUnavailable: 503,
  gatewayTimeout: 504,
  notModified: 304
};

export const BADGE_SETTING_TYPES = {
  ASSIGNED: "Assigned",
  ASSIGN: "ASSIGN",
  SELF_DECLARED: "self-declared",
  SELF_DECLARED_SECONDARY: "Self-Declared",
  TIME_BOUND: "Time Bound",
  OPEN_ENDED: "Open Ended",
  MULTIPLE_VALUES: "Multiple Values",
  SINGLE_VALUES: "Single Value",
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE",
  MANDATORY: "Mandatory",
  OPTIONAL: "Optional"
};

export const USER_ROLES = {
  PROGRAM_ADMIN: "PROGRAM_ADMIN",
  CONTENT_ADMIN: "CONTENT_ADMIN",
  TRAINER: "TRAINER",
  VERIFIER: "VERIFIER"
};

export const BADGE_TYPE_STATUS = {
  PUBLISHED: "Published",
  DRAFT: "Draft",
  DEACTIVATED: "Deactivated",
  UNPUBLISHED: "Unpublished"
};

export const BADGE_STATUS = {
  EXPIRED: "EXPIRED",
  ASSIGNED: "ASSIGNED",
  UNASSIGNED: "UNASSIGNED"
};

export const STATUS = {
  REJECTED: "REJECTED",
  SUBMITTED: "SUBMITTED",
  APPROVED: "APPROVED",
  PENDING_VERIFICATION: "PENDING VERIFICATION"
};

export const REGISTRY_STATUS = {
  VERIFIED: "verified",
  UNVERIFIED: "unverified",
  REJECTED: "rejected"
};

export const BADGE_STATUS_OBJECT = [
  {
    name: "ASSIGNED",
    id: 1,
    serialNo: "1."
  },
  {
    name: "EXPIRED",
    id: 2,
    serialNo: "2."
  }
];
