import React from "react";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import Workspace from "@socion-cordio/web/src/modules/workspace/index";
import { Roles } from "@socion-cordio/common/src/constants/roles";

const WorkspaceRoutes = () => [
  {
    path: routeNames.workspace,
    component: Workspace,
    isExact: false,
    permission: [Roles.Admin, Roles.AreaManager, Roles.StoreAccess]
  }
];

const WorkspaceRouter = () => {
  return WorkspaceRoutes();
};
export default WorkspaceRouter;
