import {
  View,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Easing,
  StyleProp,
  ViewStyle,
  LayoutChangeEvent,
  TextStyle
} from "react-native";
import React, { ReactElement, useState, useEffect, useRef } from "react";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
interface IProps {
  isExpanded: boolean;
  disabled?: boolean;
  headerComponent: React.ReactNode;
  children: React.ReactNode;
  headerStyles?: StyleProp<ViewStyle>;
  bodyStyles?: StyleProp<ViewStyle>;
  accordionStyles?: StyleProp<ViewStyle>;
  accordianIcon?: boolean;
  accordianIconStyle?: StyleProp<TextStyle>;
  handleAccordianClick?: boolean;
  showAccordianIcon?: boolean;
  cutomClickHandler?: Function;
}
export function CollapseItem(props: IProps): ReactElement {
  const {
    isExpanded,
    disabled = false,
    accordionStyles = {},
    headerStyles = {},
    bodyStyles = {},
    headerComponent,
    children,
    accordianIcon = true,
    accordianIconStyle = null,
    showAccordianIcon = true,
    cutomClickHandler
  } = props;
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(0);
  useEffect(() => {
    handleExpand(!isExpanded);
  }, [isExpanded]);
  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ["0rad", `${Math.PI}rad`]
  });
  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight]
  });
  const startAnimation = (toValue: number): void => {
    Animated.timing(animatedController, {
      duration: 300,
      toValue: toValue,
      easing: Easing.ease,
      useNativeDriver: false
    }).start();
  };
  const handleAnimation = (isAnimated: boolean): void => {
    if (isAnimated) {
      startAnimation(0);
    } else {
      startAnimation(1);
    }
  };
  const handleExpand = (isExpand: boolean): void => {
    setAccordionExpanded(isExpand);
    handleAnimation(isExpand);
  };
  const handleClick = (): void => {
    handleAnimation(!accordionExpanded);
    setAccordionExpanded(!accordionExpanded);
    cutomClickHandler && cutomClickHandler();
  };
  const onLayoutChange = (event: LayoutChangeEvent): void => {
    let { height } = event.nativeEvent.layout;
    setBodySectionHeight(height);
  };
  const accordianStyle = accordionExpanded ? accordionStyles : [styles.accordian, accordionStyles];
  return (
    <View style={accordianStyle}>
      <TouchableOpacity onPress={handleClick} disabled={disabled}>
        <View style={[styles.title, headerStyles]}>
          {headerComponent}
          {accordianIcon && showAccordianIcon && (
            <Animated.View style={{ transform: [{ rotateZ: arrowAngle }], marginTop: 5 }}>
              <Icon
                name={IconNames.down}
                size={10}
                customStyle={[styles.iconStyles, accordianIconStyle]}
              />
            </Animated.View>
          )}
        </View>
      </TouchableOpacity>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }, bodyStyles]}>
        <View style={styles.bodyContainer} onLayout={onLayoutChange}>
          {children}
        </View>
      </Animated.View>
    </View>
  );
}
const styles = StyleSheet.create({
  accordian: {
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowColor: colorPallete.black,
    shadowOpacity: 0.04,
    shadowRadius: 4,
    borderRadius: 8
    // marginBottom: 40
  },
  title: {
    fontSize: 12,
    backgroundColor: colorPallete.white,
    borderRadius: 8,
    padding: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // elevation: 4,
    shadowColor: colorPallete.lightGrey,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 1,
    fontFamily: FontFamily.Medium,
    fontWeight: "700",
    lineHeight: 17
    // paddingLeft: 30,
    // ...I18nService.select({
    //   rtl: { paddingEnd: 30 },
    //   ltr: { paddingStart: 30 }
    // })
  },
  bodyContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    flexWrap: "wrap"
  },
  bodyBackground: {
    backgroundColor: colorPallete.white,
    overflow: "hidden",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  iconStyles: {
    color: colorPallete.black,
    paddingHorizontal: 30,
    marginTop: 5
  }
});
export default CollapseItem;
