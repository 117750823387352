import React, { ReactElement, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import { QR } from "@socion-cordio/common/src/components/atoms/qrCode";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { useTranslation } from "react-i18next";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast, ToastContainer } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {
  onSubmit?: (data: any) => void;
  modalVisible: boolean;
  setModalVisible?: Function;
  selectedUserData: any;
  setUserMemberRole?: any;
  isEditButtonClicked?: any;
  isSave?: boolean;
  getCompleteSessionDetails?: Function;
}
export default function AddViewRemoveMemberModal(props: Props): ReactElement {
  const { t } = useTranslation();
  let aesUtil: AesUtil = new AesUtil();
  const {
    modalVisible,
    setModalVisible,
    selectedUserData,
    setUserMemberRole,
    isEditButtonClicked,
    isSave = false,
    getCompleteSessionDetails
  } = props;
  const [isUserTrainer, setIsUserTrainer] = useState(false || selectedUserData.roles.trainer);
  const [isUserAdmin, setIsUserAdmin] = useState(false || selectedUserData.roles.admin);
  const [isOtherRole, setIsOtherRole] = useState(false || selectedUserData.roles.other);
  const [isOtherValueEmpty, setIsOtherValueEmpty] = useState(true);
  const [otherRoleValue, setOtherRoleValue] = useState(
    null || selectedUserData.roles.otherRoleNames
  );

  const addSelectedMemberHandler = () => {
    const selectedRolesBody = {
      trainer: isUserTrainer,
      admin: isUserAdmin,
      other: isOtherRole
    };
    //Only send True values
    const selectedRolesBodyFiltered = Object.fromEntries(
      Object.entries(selectedRolesBody).filter(([key, value]) => value === true)
    );
    const list = Object.fromEntries(
      Object.entries(selectedRolesBody).filter(([key, value]) => isOtherRole === true)
    );
    if (JSON.stringify(list) !== "{}") {
      const list = Object.fromEntries(
        Object.entries(selectedRolesBody).filter(([key, value]) => value === true)
      );
      const newBody = { ...list, otherRoleNames: otherRoleValue };
      setUserMemberRole(newBody);
    } else {
      setUserMemberRole(selectedRolesBodyFiltered);
    }
  };

  const handleSaveMember = async () => {
    let payload = selectedUserData;
    payload.roles.trainer = isUserTrainer;
    payload.roles.admin = isUserAdmin;
    payload.roles.other = isOtherRole;
    payload.roles.otherRoleNames = otherRoleValue;
    console.log(payload, "payload");
    const response = await ApiClient.put(sessionPackEndPoints.updateSessionMember(), payload);
    if (response.responseCode === HTTP_STATUS_CODES.ok) {
      getCompleteSessionDetails();
    } else {
      toast.error(response.message);
    }
  };

  const checkRoleInputHandler = (data: string) => {
    if (data === null || data === "") {
      setIsOtherValueEmpty(true);
    } else {
      setIsOtherValueEmpty(false);
    }
  };

  const formatLocation = (data: any) => {
    let formattedLocation: string = "";
    // city, subdist, district, state, country
    if (data?.city) {
      formattedLocation += `${data?.city}, `;
    }
    if (data?.subDistrict) {
      formattedLocation += `${data?.subDistrict}, `;
    }
    if (data?.district) {
      formattedLocation += `${data?.district}, `;
    }
    if (data?.state) {
      formattedLocation += `${data?.state}, `;
    }
    if (data?.country) {
      formattedLocation += `${data?.country} `;
    }
    return formattedLocation;
  };

  return (
    <View>
      <Modal
        modalVisible={modalVisible}
        setModalVisible={() => setModalVisible()}
        component={
          <View style={styles.container}>
            <ToastContainer />
            <View style={styles.header}>
              <View>
                <Icon
                  testID="close"
                  name={IconNames.crossCircle}
                  customStyle={styles.crossCircle}
                  onPress={() => setModalVisible()}
                />
              </View>
            </View>
            <View style={styles.subContainer}>
              <View style={styles.profilePictureContainer}>
                <Image
                  testId="profilePicture"
                  imageStyle={styles.profilePicture}
                  source={
                    selectedUserData?.photo ||
                    require("@socion-cordio/common/src/assets/images/user_circle.svg")
                  }
                />
              </View>
              <View style={styles.memberInfoContainer}>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={styles.headerText}
                >
                  {selectedUserData?.name}
                </Text>
                <View style={styles.userDetailsContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText, styles.fontSize12]}
                  >
                    {"Contact Number : "}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText, styles.fontSize12]}
                  >
                    {selectedUserData.piiInfo
                      ? `${selectedUserData.countryCode} ${selectedUserData.phoneNumber}`
                      : `NA`}
                  </Text>
                </View>
                <View style={styles.userDetailsContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText, styles.fontSize12]}
                  >
                    {"Contact Email : "}
                  </Text>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.alignText, styles.fontSize12]}
                  >
                    {selectedUserData.piiInfo
                      ? selectedUserData.emailId !== ""
                        ? selectedUserData.emailId
                        : ""
                      : `NA`}
                  </Text>
                </View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.alignText, styles.fontFix]}
                >
                  {t("common:baseLocation")} :
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.alignText, styles.textColor]}
                >
                  {formatLocation(selectedUserData)}
                </Text>

                {/* Add Member Button container */}
                <View style={styles.checkBoxContainer}>
                  <View style={styles.alignCheckBoxContainer}>
                    <Checkbox
                      testId={"checkBoxTrainer"}
                      onPress={() => {
                        setIsUserTrainer(!isUserTrainer);
                        googleWebAnalytics(
                          "Create_Session_Add_Member_Trainer",
                          "Button",
                          "Session"
                        );
                      }}
                      selected={isUserTrainer}
                      type={CheckboxType.Square}
                      iconSize={20}
                      text={t("common:trainer")}
                      textSize={TextSize.Small}
                      textFontWeight={FontWeight.Regular}
                      textStyle={styles.textAlignCheckbox}
                    />
                  </View>
                  <View style={styles.alignCheckBoxContainer}>
                    <Checkbox
                      testId={"checkBoxAdmin"}
                      onPress={() => {
                        setIsUserAdmin(!isUserAdmin);
                        googleWebAnalytics("Create_Session_Add_Member_Admin", "Button", "Session");
                      }}
                      selected={isUserAdmin}
                      type={CheckboxType.Square}
                      iconSize={20}
                      text={t("common:administrator")}
                      textSize={TextSize.Small}
                      textFontWeight={FontWeight.Regular}
                      textStyle={styles.textAlignCheckbox}
                    />
                  </View>
                  <View style={styles.alignCheckBoxContainer}>
                    <Checkbox
                      testId={"checkBox"}
                      onPress={() => {
                        setIsOtherRole(!isOtherRole);
                        googleWebAnalytics("Create_Session_Add_Member_Other", "Button", "Session");
                      }}
                      selected={isOtherRole}
                      type={CheckboxType.Square}
                      iconSize={20}
                      text={t("common:other")}
                      textSize={TextSize.Small}
                      textFontWeight={FontWeight.Regular}
                      textStyle={styles.textAlignCheckbox}
                    />
                  </View>
                </View>

                {isOtherRole && (
                  <View style={styles.otherRoleContainer}>
                    <TextField
                      label=""
                      handleChange={(value: string) => {
                        [setOtherRoleValue(value), checkRoleInputHandler(value)];
                      }}
                      handleBlur={() => {}}
                      value={otherRoleValue}
                      inputStyle={[styles.inputStylings, styles.inputStylingAlignment]}
                      placeholder={t("common:roleDescirption")}
                      name="otherRoleName"
                      id="otherRoleName"
                      noFormik

                      // editable={editable}
                    />
                  </View>
                )}
              </View>
              <View style={styles.qrCodeContainer}>
                <View style={styles.qrCode}>
                  <QR value={aesUtil.encrypt(selectedUserData?.userId)} />
                </View>
              </View>
            </View>

            {/* Remove member buttons container */}
            {!isEditButtonClicked && (
              <View style={[styles.alignContainer]}>
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbutton}
                  title={t("common:addMember")}
                  onPress={() => addSelectedMemberHandler()}
                  textStyles={styles.buttonText}
                  // disabled={isOtherValueEmpty && isOtherRole}
                  disabled={
                    (!isUserTrainer && !isUserAdmin && !isOtherRole) ||
                    (isOtherValueEmpty && isOtherRole)
                  }
                />
              </View>
            )}
            {isEditButtonClicked && (
              <View style={[styles.alignContainer]}>
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.submitbutton}
                  title={t("common:save")}
                  onPress={() => (isSave ? handleSaveMember() : addSelectedMemberHandler())}
                  textStyles={styles.buttonText}
                  disabled={
                    (!isUserTrainer && !isUserAdmin && !isOtherRole) ||
                    (isOtherValueEmpty && isOtherRole)
                  }
                />
              </View>
            )}
          </View>
        }
      ></Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
    // fontFamily: FontFamily.Regular
    // marginBottom: 5
  },
  fontFix: {
    fontFamily: FontFamily.Regular
  },
  fontSize12: {
    fontSize: 12
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    flexDirection: "row",
    marginTop: 15,
    // marginBottom: 25
    minHeight: 210
  },
  profilePictureContainer: {
    flex: 25,
    alignItems: "center"
  },
  profilePicture: {
    borderRadius: 50,
    width: " 80px",
    height: "80px"
  },
  memberInfoContainer: {
    flex: 55,
    marginLeft: 15,
    marginRight: 15
  },
  checkBoxContainer: {
    marginTop: 30,
    flexDirection: "row"
  },
  alignCheckBoxContainer: {
    marginRight: 25
  },
  textAlignCheckbox: {
    marginStart: 8
  },
  inputStylings: {
    height: 40,
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Medium
  },
  otherRoleContainer: {
    marginTop: 7
  },
  inputStylingAlignment: {},
  qrCodeContainer: {
    flex: 25,
    alignItems: "center"
  },
  qrCode: {
    // backgroundColor: "#d8d8d8",
    height: "105px",
    width: "148px"
  },
  alignContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10
  },
  submitbutton: {
    width: "167px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  buttonText: {
    fontSize: 12
  },
  userDetailsContainer: {
    flexDirection: "row"
  }
});
