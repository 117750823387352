import React, { ReactElement, useEffect, useState, useCallback } from "react";
import { StyleSheet, View, Linking, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import TopicTaskPreview from "@socion-cordio/common/src/components/organisms/topic/topicTaskPreview";

interface Props {
  topicDetails: any;
  getTopicDetails: Function;
  userRoles: any[];
  isTaskAccessible: any;
  applicationId?: any;
  superAdmin?: any;
}

export default function TopicDetailsTasks(props: Props): ReactElement {
  const { topicDetails, getTopicDetails, userRoles, isTaskAccessible, applicationId, superAdmin } =
    props;
  const [isLinkDeleted, setIsLinkDeleted] = useState(false);
  const [topicStatus, setTopicStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskDetails, setTaskDetails] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateTask, setShowCreateTask] = useState(false);
  const [selectedTaskItem, setSelectedTaskItem] = useState(null);

  useEffect(() => {
    if (props?.topicDetails !== null) {
      getTaskDetails();
    }
  }, [window.location.pathname, topicDetails]);

  const getTaskDetails = async () => {
    try {
      setTaskDetails(null);
      setLoading(true);
      let response = await ApiClient.get(topicEndPoints.getTaskDetails(props?.topicDetails?.id));
      if (response.responseCode === HTTP_STATUS_CODES.ok) {
        let taskUrl = response?.response.url;
        let newTaskUrl = taskUrl + "&formType=Task";
        response.response.url = newTaskUrl;
        const res = response?.response;
        setTaskDetails(res);
        setLoading(false);
        setIsLoading(false);
        if (topicStatus) {
          setShowCreateTask(false);
          return;
        }
        for (let item of res) {
          if (item?.status === "DRAFT" || item?.status === "ACTIVE") {
            setShowCreateTask(false);
            return;
          }
        }
        setShowCreateTask(true);
      } else if (response.responseCode === HTTP_STATUS_CODES.notFound) {
        setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    topicStatusHandler(topicDetails);
  }, [topicDetails]);

  const topicStatusHandler = (topicDetails: any) => {
    let status = topicDetails?.status === "ACTIVE" ? true : false;
    setTopicStatus(status);
  };

  useEffect(() => {
    if (isLinkDeleted) disableScroll();
    else enableScroll();
  }, [isLinkDeleted]);

  // NEW REDIRECTION URLS WHEN ASSESSMENT IS DEPLOYED.
  const handleCreateCustom = () => {
    window.location.href = `https://pda-stage.socion.io/tasks/new/app/workspace/create?programId=${props?.topicDetails?.programId}&topicId=${props?.topicDetails?.id}&topicName=${props?.topicDetails?.name}`;
  };

  const handleEdit = (taskId: any) => {
    window.location.href = `https://pda-stage.socion.io/tasks/new/app/workspace/update?programId=${props?.topicDetails?.programId}&taskId=${taskId}&topicId=${props?.topicDetails?.id}&topicName=${props?.topicDetails?.name}`;
  };

  // OLD REDIRECTION URLS WHEN ASSESSMENT IS DEPLOYED.
  // const handleCreateCustom = () => {
  //   window.location.href = `https://pda-stage.socion.io/tasks/new/app/create-task?programId=${props?.topicDetails?.programId}&topicId=${props?.topicDetails?.id}&topicName=${props?.topicDetails?.name}`;
  // };
  // const handleEdit = (taskId: any) => {
  //   window.location.href = `https://pda-stage.socion.io/tasks/new/app/update-task?programId=${props?.topicDetails?.programId}&taskId=${taskId}`;
  // };

  const renderCreateTask = () => {
    return (
      <View style={styles.container}>
        <View style={styles.subContainerCreateTask}>
          <TouchableOpacity onPress={handleCreateCustom}>
            <View style={styles.createTaskContainer}>
              {" "}
              <Text
                fontWeight={FontWeight.Light}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={[styles.headerTextNormal, styles.fontSize14, styles.fontColorWhite]}
              >
                {"Create Task"}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.subContainerTwo}></View>
      </View>
    );
  };

  const renderTaskItem = (taskItem: any) => {
    return (
      taskDetails !== null && (
        <View style={[styles.container, styles.alignTaskLinkContainer]}>
          <View style={styles.subContainerOne}>
            <View style={styles.createCustomTaskContainer}>
              <View style={[styles.alignCreateTaskHeader, styles.alignTaskName]}>
                <TouchableOpacity
                  style={{ width: "86%" }}
                  onPress={() => {
                    if (taskItem?.taskType === "CUSTOM") {
                      setSelectedTaskItem(taskItem);
                      setShowPreview(true);
                    } else {
                      Linking.openURL(taskItem?.url);
                    }
                  }}
                >
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={[styles.fontSize14, styles.headerTextBold, styles.alignText]}
                  >
                    Task_{props?.topicDetails?.name}
                  </Text>
                </TouchableOpacity>
                <View style={{ alignSelf: "center" }}>
                  <Text
                    fontWeight={FontWeight.Light}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={styles.publishStatus}
                  >
                    {taskItem?.status === "ACTIVE" ? "PUBLISHED" : taskItem?.status}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {isTaskAccessible && topicStatus && !superAdmin && (
            <View style={styles.iconsContainer}>
              {taskItem?.taskType === "CUSTOM" && taskItem?.status !== "CLOSED" && (
                <TouchableOpacity onPress={() => handleEdit(taskItem?.taskTemplateId)}>
                  <Icon name={IconNames.penPaper} customStyle={styles.edit} />
                </TouchableOpacity>
              )}
              {taskItem?.taskType !== "CUSTOM" && (
                <TouchableOpacity onPress={() => setIsLinkDeleted(true)}>
                  <Image
                    testId="addSubcontent"
                    source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                    imageStyle={[styles.alignContainerButton]}
                  />
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
      )
    );
  };

  return loading ? (
    <Loader customLoadingContainer={styles.loader} />
  ) : (
    <>
      {taskDetails?.map((taskItem: Object) => renderTaskItem(taskItem))}
      {topicStatus && (taskDetails == null || showCreateTask) && renderCreateTask()}
      {showPreview && (
        <View>
          <SocionModal
            modalVisible={showPreview}
            customPadding={0}
            setModalVisible={() => setShowPreview(!showPreview)}
            component={
              <TopicTaskPreview url={selectedTaskItem?.url} setShowPreview={setShowPreview} />
            }
          />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10
  },
  subContainerOne: {
    flex: 90
  },
  subContainerCreateTask: {
    flex: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  subContainerTwo: {
    flex: 10,
    justifyContent: "center"
  },
  alignTaskLinkContainer: {
    marginTop: 15
  },

  // input field
  titleInputContainerStyles: {
    marginTop: 0
  },
  inputStylingAlignment: {
    width: "100%",
    fontSize: 14,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Regular
  },
  textHeaderField: {
    flex: 12,
    fontSize: 14
  },
  alignContainerButton: {
    fontSize: 17,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 25,
    width: 18,
    height: 18
  },

  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700"
  },
  headerTextNormal: {
    fontFamily: FontFamily.Medium,
    lineHeight: 17
    // marginBottom: 5
  },

  // font sizes
  fontSize14: {
    fontSize: 14
  },

  // font colors
  fontColorWhite: {
    color: colorPallete.white
  },

  headerTextBold: {
    fontFamily: FontFamily.Bold
  },
  orContainer: {
    marginVertical: 20,
    alignSelf: "center"
  },
  alignText: {
    marginVertical: 5
  },

  // Create Custome Container
  createCustomTaskContainer: {
    minHeight: 57,
    borderColor: colorPallete.cordioTaupe,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  alignCreateTaskHeader: {
    paddingHorizontal: 20,
    flex: 1,
    flexWrap: "wrap"
  },
  alignTaskName: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  createTaskContainer: {
    height: 49,
    width: 140,
    borderRadius: 5,
    backgroundColor: colorPallete.cordioRed,
    marginHorizontal: 4,
    justifyContent: "center",
    alignItems: "center"
  },
  iconMainContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  iconContainer: {
    justifyContent: "center",
    marginLeft: 12
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    fontSize: 17
  },

  // Loader
  loader: {
    height: 150
    // transform: [{ scale: 0.8 }]
  },
  edit: {
    color: colorPallete.cordioTaupe,
    fontSize: 17,
    marginLeft: 30
    // marginRight: 44
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  publishStatus: {
    fontSize: 14,
    fontFamily: FontFamily.Bold,
    fontStyle: "italic",
    color: colorPallete.textBlack
    // alignSelf: "center"
  }
});
