import React, { ReactElement, useState, useEffect } from "react";
import { ScrollView, StyleSheet, View, Linking, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import Modal from "@socion-cordio/common/src/components/atoms/modal";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import LoaderCircle from "@socion-cordio/common/src/components/atoms/loaderCircle";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { ProgramsRepository } from "@socion-cordio/common/src/repositories/registry/entity/programs";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";
interface Props {
  onSubmit?: (data: any) => void;
  modalVisible: boolean;
  setModalVisible?: Function;
  programId?: any;
  parentId?: any;
  parentType?: any;
  updatedBy?: any;
  updatedById?: any;
  programName?: any;
  getSdgByProgramId?: Function;
  selectedSdgs?: any;
  allSdgs?: any;
  selectedProgram?: any;
}

export default function AddSdgModal(props: Props): ReactElement {
  const {
    modalVisible,
    setModalVisible,
    programId,
    parentId,
    parentType,
    updatedBy,
    updatedById,
    programName,
    getSdgByProgramId,
    selectedSdgs,
    selectedProgram
    // apiResponse
  } = props;
  const [failed, setFailed] = useState<boolean>(false);
  const [mainLoader, setMainLoader] = useState<boolean>(true);
  const [sdgMainList, setSdgMainList] = useState([]);
  const [selectedImageObj, setSelectedImageObj] = useState([]);
  const [sdgList, setSdgList] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getSDGList(selectedSdgs);
    setSelectedImageObj(selectedSdgs);
    getSdgByProgramId();
  }, []);

  const getSDGList = async (sdgIdByProgram: any) => {
    try {
      const { response } = await ApiClient.get(springshedEndPoints.getSDGList());
      const [detailsResponse] = response;
      response.map((data: any) => {
        if (sdgIdByProgram.includes(data.sdgId)) {
          data["selected"] = true;
        } else {
          data["selected"] = false;
        }
      });
      setSdgList(response);
      setSdgMainList(response);
      setLoading(false);
      setMainLoader(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setMainLoader(false);
      setFailed(true);
    }
  };

  const updateSdgHandler = async () => {
    // const ids = [...updatedSdgList, selectedImageObj];
    console.log("slecletd", selectedImageObj);
    try {
      const payload = { ids: selectedImageObj, parentId, parentType, updatedBy, updatedById };
      console.log("programId--", programId);
      console.log("payload", payload);
      const addSdgResponse = await ProgramsRepository.updateEntityProgram(payload, programId).catch(
        (err) => {
          toast.error(
            dataHelper.replaceText(MESSAGES.ERROR.UPDATE, {
              ["{type}"]: "Program",
              ["{name}"]: programName
            })
          );
          console.log("update sdg error", err);
        }
      );

      if (addSdgResponse.responseCode === "OK") {
        selectedProgram.ids = selectedImageObj;
        localStorage.setItem("selectedEntityProgram", JSON.stringify(selectedProgram));
        setModalVisible(false);
        getSdgByProgramId();
        toast.success("SDG updated successfully.");
      }
      console.log("responsecod", addSdgResponse.responseCode);
      console.log("Http", HTTP_STATUS_CODES.ok);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const cancelHandler = () => {
    setSelectedImageObj([]);
    sdgMainList.map((data: any) => {
      data["selected"] = false;
    });
    let updatedSdgList = [...sdgMainList];
    setSdgMainList(updatedSdgList);
  };

  const onClickImage = (item: any) => {
    item["selected"] = !item.selected;
    let updatedSdgList = [...sdgMainList];
    setSdgMainList(updatedSdgList);
    if (item.selected && !selectedImageObj.includes(item.sdgId)) {
      selectedImageObj.push(item.sdgId);
    } else {
      if (selectedImageObj.includes(item.sdgId)) {
        var index = selectedImageObj.indexOf(item.sdgId);
        if (index !== -1) {
          selectedImageObj.splice(index, 1);
        }
      }
    }
  };

  const renderImage = (item: any, index: any) => {
    return (
      <View
        style={{ flexDirection: "row", marginRight: 13, marginBottom: 10, width: 85, height: 85 }}
      >
        <TouchableOpacity onPress={() => onClickImage(item)}>
          <View
            style={
              item.selected
                ? {
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: colorPallete.cordioRed,
                    borderRadius: 5,
                    padding: 3
                  }
                : null
            }
          >
            <Image
              source={{ uri: item.imageUrl }}
              testId={index}
              imageStyle={{ width: 95, height: 95, borderRadius: 5 }}
            />
            <View style={{ position: "absolute", bottom: 0, left: 70, marginBottom: 10 }}>
              {/* <Checkbox
              testId=""
              selected={item.selected}
              type={CheckboxType.Square}
              textSize={TextSize.Large}
              textFontWeight={FontWeight.Regular}
            /> */}
              {item.selected && (
                <View
                  style={{
                    height: 24,
                    width: 24,
                    borderRadius: 5,
                    backgroundColor: colorPallete.cordioRed,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Icon name={IconNames.tickOnly} customStyle={styles.checkIcon} />
                </View>
              )}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View>
      <Modal
        modalVisible={modalVisible}
        setModalVisible={() => setModalVisible()}
        component={
          <View style={styles.container}>
            {mainLoader ? (
              <Loader customLoadingContainer={{ width: 655, height: 450 }} />
            ) : failed ? (
              <View>Failed to load data</View>
            ) : (
              <>
                <ToastContainer />
                <View style={styles.header}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="addRoleText"
                    textSize={TextSize.Small}
                    textStyle={styles.headerText}
                  >
                    Select SDGs
                  </Text>
                  <View>
                    <Icon
                      testID="close"
                      name={IconNames.crossCircle}
                      customStyle={styles.crossCircle}
                      onPress={() => setModalVisible()}
                    />
                  </View>
                </View>

                <View style={{ marginHorizontal: 45 }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 20
                    }}
                  >
                    <View>
                      <Image
                        testId="socionSdg"
                        source={require("@socion-cordio/common/src/assets/images/socionSdg.png")}
                        imageStyle={{
                          width: 96,
                          height: 53
                        }}
                      />
                    </View>
                    <View style={styles.selectedImageCount}>
                      Selected
                      <Text
                        fontWeight={FontWeight.Bold}
                        testId="addRoleText"
                        textSize={TextSize.Small}
                        textStyle={styles.selectedCount}
                      >
                        {selectedImageObj.length} / {sdgList.length}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.topicDetailsContainer}>
                    {sdgMainList?.map((item: any, index: any) => {
                      return renderImage(item, index);
                    })}
                  </View>
                </View>
                <View style={styles.editIconContainer}>
                  <Button
                    type={ButtonType.Secondary}
                    buttonStyles={[styles.submitbutton, styles.alignEditButtons]}
                    textStyles={{ fontSize: 12 }}
                    title="Clear"
                    onPress={() => [cancelHandler()]}
                  />
                  <Button
                    type={ButtonType.Primary}
                    buttonStyles={[styles.submitbutton, styles.alignEditButtons]}
                    textStyles={{ fontSize: 12 }}
                    title="Save"
                    onPress={() => [updateSdgHandler()]}
                  />
                </View>
              </>
            )}
          </View>
        }
      ></Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 5
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    marginBottom: 10
  },
  selectedCount: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    marginRight: 16,
    marginLeft: 5
  },
  alignHeader: {
    marginBottom: 0,
    marginLeft: 10
  },
  headerAlignment: {
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular
  },
  fontSizeText: {
    fontSize: 10
  },
  crossCircle: {
    fontSize: 15
  },
  topicDetailsContainer: {
    flexDirection: "row",
    // marginTop: 15,
    marginBottom: 5,
    flexWrap: "wrap"
    // justifyContent: "space-between"
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  alignEditButtons: {
    marginLeft: 10
  },
  editIconContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 55
  },
  checkIcon: {
    color: colorPallete.white,
    fontSize: 14
  },
  selectedImageCount: {
    justifyContent: "flex-end",
    display: "inline",
    paddingTop: 33,
    fontSize: 12
  }
});
