import React, { useState, useEffect } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { EntityActions } from "@socion-cordio/common/src/modules/registry/entity/actions/actions";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Documents from "@socion-cordio/common/src/components/organisms/entity/documents";
import { REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";

interface Props {
  partnerEntity?: any;
  onClose?: Function;
}

export default function PartnerEntityDetailsModal(props: Props) {
  const { partnerEntity, onClose } = props;
  console.log("partnerEntitypartnerEntity", partnerEntity);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    entity: entity,
    loading: { getEntity: loading },
    error: { getEntity: error }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    dispatch(EntityActions.getEntity(partnerEntity?.entityId));
  }, []);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    console.log("entityentityentity", entity);

    // setIsLoading(loading);
  }, [entity]);

  return (
    <>
      {isLoading ? (
        <Loader customLoadingContainer={styles.loaderContiner} />
      ) : (
        <View style={styles.container}>
          <ToastContainer />
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addEmailText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {"Partner Entity Details"}
            </Text>
            <View>
              <Pressable onPress={() => onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <ScrollContainer height="500px">
            <View>
              {/* <View style={styles.titleContainer}> */}
              <View style={styles.textFieldContianer}>
                <View style={styles.labelTextContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="nameText"
                    textSize={TextSize.Small}
                    textStyle={[styles.subheaderText, , styles.textTitle]}
                  >
                    Entity Name
                  </Text>
                </View>
                <View style={styles.widthContainer}>
                  <View style={styles.contentContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="nameTextValue"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.subheaderText,
                        { marginTop: 2, marginLeft: 10 },
                        styles.valueData
                      ]}
                    >
                      {entity?.name}
                    </Text>
                  </View>
                </View>
              </View>
              {/* </View> */}

              {/* <View style={[styles.titleContainer]}> */}
              <View style={styles.textFieldContianer}>
                <View style={styles.labelTextContainer}>
                  <Text
                    fontWeight={FontWeight.Regular}
                    testId="countryText"
                    textSize={TextSize.Small}
                    textStyle={[styles.subheaderText, , styles.textTitle]}
                  >
                    Country of Incorporation
                  </Text>
                </View>
                <View style={styles.widthContainer}>
                  <View style={styles.contentContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="countryTextValue"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.subheaderText,
                        { marginTop: 2, marginLeft: 10 },
                        styles.valueData
                      ]}
                    >
                      {entity?.country}
                    </Text>
                  </View>
                </View>
              </View>
              {/* </View> */}

              {/* <View style={styles.containerSecondary}> */}

              <View style={styles.textFieldContianer}>
                <View style={styles.labelSecondaryContainer}>
                  <View style={[styles.labelTextSecondaryContainer, { marginRight: 10 }]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addressText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 11 }, styles.textTitle]}
                    >
                      Address
                    </Text>
                  </View>
                  <View style={styles.contentSecondaryContainer}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="addressTextValue"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 2 }, styles.valueData]}
                    >
                      {entity?.address}
                    </Text>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="address2TextValue"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 2 }, styles.valueData]}
                    >
                      {entity?.address2}
                    </Text>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="cityTextValue"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 2 }, styles.valueData]}
                    >
                      {entity?.city + ", " + entity?.pinCode}
                    </Text>

                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="stateTextValue"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 2 }, styles.valueData]}
                    >
                      {entity?.state + ", " + entity?.country}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.textFieldContianer]}>
                <View style={styles.labelSecondaryContainer}>
                  <View style={[styles.labelTextSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="websiteUrlText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 11 }, styles.textTitle]}
                    >
                      Website
                    </Text>
                  </View>
                  <View style={[styles.contentSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="websiteUrlTextValue"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.subheaderText,
                        { marginTop: 2, marginLeft: 10 },
                        styles.valueData
                      ]}
                    >
                      {entity?.websiteUrl}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.textFieldContianer]}>
                <View style={styles.labelSecondaryContainer}>
                  <View style={[styles.labelTextSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="legalStatusValueText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 11 }, styles.textTitle]}
                    >
                      Legal Status
                    </Text>
                  </View>
                  <View style={[styles.contentSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="legalStatusValue"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.subheaderText,
                        { marginTop: 2, marginLeft: 10 },
                        styles.valueData
                      ]}
                    >
                      {entity?.legalStatus}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.textFieldContianer]}>
                <View style={styles.labelSecondaryContainer}>
                  <View style={[styles.labelTextSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="registrationNumberText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 11 }, styles.textTitle]}
                    >
                      Registration Number
                    </Text>
                  </View>
                  <View style={[styles.contentSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="registrationNumberValue"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.subheaderText,
                        { marginTop: 2, marginLeft: 10 },
                        styles.valueData
                      ]}
                    >
                      {entity?.registrationNumber}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.textFieldContianer]}>
                <View style={styles.labelSecondaryContainer}>
                  <View style={[styles.labelTextSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="missionText"
                      textSize={TextSize.Small}
                      textStyle={[styles.subheaderText, { marginTop: 11 }, styles.textTitle]}
                    >
                      Mission
                    </Text>
                  </View>
                  <View style={[styles.contentSecondaryContainer]}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="missionTextValue"
                      textSize={TextSize.Small}
                      textStyle={[
                        styles.subheaderText,
                        { marginTop: 2, marginLeft: 10 },
                        styles.valueData
                      ]}
                    >
                      {entity?.mission}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.detailsSubContainerTwo}>
                {/* {selectedProgram && selectedProgram?.docs ? ( */}
                <Documents
                  ids={[]}
                  taggedId={entity?.osid}
                  selectedItem={entity}
                  itemType={REGISTRY_TYPE.entity}
                  showUploadIcon={false}
                  // uploadText={"Upload Program Documents"}
                />
                {/* ) : null} */}
              </View>
            </View>
          </ScrollContainer>
        </View>

        // </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "655px"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 25
  },
  headerText: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },

  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 50,
    minHeight: 70
  },
  containerSecondary: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 45
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  textFieldContianer: {
    flexDirection: "row",
    width: "100%",
    display: "flex",
    alignItems: "baseline"
  },
  labelTextContainer: {
    flex: 18,
    width: "50%"
  },
  contentContainer: {
    flex: 85
  },
  labelSecondaryContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "baseline"
  },
  labelTextSecondaryContainer: {
    // flex: 28
    width: "50%"
    // marginRight: 8
  },
  contentSecondaryContainer: {
    // flex: 58
    width: "50%"
  },

  inputMobile: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "100%"
  },
  entityContainer: {
    fontFamily: FontFamily.Medium,
    fontWeight: "400",
    padding: 0
  },

  textTitle: {
    color: colorPallete.black,
    fontWeight: "500",
    fontSize: 14
  },
  valueData: {
    fontWeight: "500",
    fontSize: 12
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },

  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.5rem"
  },

  qualificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    height: "80px",
    marginRight: "5px",
    padding: 15,
    marginTop: 20,
    borderColor: "#3FA202",
    borderStyle: "solid",
    borderWidth: 1
  },

  textAreaALign: {
    borderRadius: 5,
    padding: 7,
    paddingTop: 10,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "100%"
  },

  uploadDocIcon: {
    opacity: 0.5
  },
  loaderContiner: {
    width: 655,
    height: 438
  },

  widthContainer: {
    width: "50%"
  },

  docsContainer: {
    marginTop: 10
  },
  docNameContainer: {
    width: 600
  },
  qualificationTitle: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  detailsSubContainerTwo: {
    marginTop: 20
    // flex: 50
  }
});
