import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Header from "@socion-cordio/common/src/components/organisms/accomplishment/components/header";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/accomplishment/components/programDropdown";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { toast } from "react-toastify";
import {
  springshedEndPoints,
  accomplishments
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { artefactDetails } from "@socion-cordio/common/src/components/organisms/accomplishment/constants";
import { color } from "html2canvas/dist/types/css/types/color";
import axios from "axios";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useTranslation } from "react-i18next";

export default function Artefact(): ReactElement {
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [showTasks, setShowTasks] = useState([]);
  const history: any = useHistory();
  const location: any = useLocation();
  const [artefactList, setArtefactList] = useState([]);
  const [dropDownSelectedProgram, setDropDownSelectedProgram] = useState({});
  const [usersId, setUsersId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [mainProgramList, setMainProgramList] = useState([]);
  const [filteredSelectedProgram, setFilteredSelectedProgram] = useState(null);
  const [isBackEnabled, setIsBackEnabled] = useState(location?.state?.isBackEnabled ?? false);

  // const { isBackEnabled = false } = location.state;
  const { t } = useTranslation();

  useEffect(() => {
    // getSelectedProgram();
    // getArtefactData();
    // setSelectedProgram(programData);
    getAllUserPrograms();
  }, []);

  useEffect(() => {
    if (dropDownSelectedProgram?.length != 0) {
      LocalStorage.setStorage("selectedProgram", dropDownSelectedProgram);
      setDropDownSelectedProgram(dropDownSelectedProgram);
    }
  }, [dropDownSelectedProgram]);

  useEffect(() => {
    if (selectedProgram && selectedProgram.value) {
      getArtefactData();
    }
  }, [dropDownSelectedProgram]);

  const getAllUserPrograms = async () => {
    try {
      setIsLoading(true);
      const urlParams = window.location.pathname.split("/").reverse();
      const programId = urlParams[2];
      const userId: any = urlParams[1];
      const accessToken: any = urlParams[0];
      setUsersId(userId);
      setAccessToken(accessToken);

      await LocalStorage.setStorage("accessToken", accessToken);

      try {
        const response = await ApiClient.post(accomplishments.getAllProgramsByUsers(), {});
        if (response?.response) {
          setMainProgramList(response?.response);
          let optionsData = response?.response.map((i: any) => {
            return { label: i.programName, value: i.programId };
          });
          setOptions(optionsData);
          let filteredId = optionsData?.filter((k: any) => k.value == programId);
          let filteredProgram = response?.response?.filter((k: any) => k.programId == programId);
          console.log(filteredId[0], filteredProgram[0]);
          setFilteredSelectedProgram(filteredProgram[0]);
          setSelectedProgram(filteredId[0]);
          setDropDownSelectedProgram(filteredId[0]);
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.");
      setIsLoading(false);
    }
  };

  const getSelectedProgram = async () => {
    let program = await LocalStorage.getStorage("selectedProgram");
    setDropDownSelectedProgram(program);
  };

  const getArtefactData = async () => {
    setDropDownSelectedProgram(selectedProgram);
    const payload = {
      programId: [selectedProgram.value],
      user_id: usersId,
      includeFields: [
        "badgerole",
        "submitted_date",
        "approved_date",
        "country",
        "state",
        "district",
        "sub_district",
        "city",
        "updated_at"
      ]
    };

    const headers = {
      "access-token": accessToken,
      "Content-Type": "application/json"
    };

    // const getArtefactResponse = await ApiClient.post(accomplishments.getArtefacts(), payload);

    const getTaskResponse: any = await axios.post(
      "https://pda-stage.socion.io/api/v1/artefact/list",
      payload,
      {
        headers: headers
      }
    );
    let idArray = getTaskResponse?.data?.data.map((i: any) => {
      return i.artefact_type_id;
    });
    idArray = [...new Set(idArray)];
    let result: any = [];
    idArray.map((i: number) => {
      let temp = getTaskResponse?.data?.data?.filter((k: any) => k.artefact_type_id == i);
      if (temp.length > 0) {
        result.push(temp);
      }
    });
    getTaskResponse.data.formattedArtefactMapping = result;
    setArtefactList(getTaskResponse.data?.formattedArtefactMapping);
    if (getTaskResponse.data.formattedArtefactMapping?.length > 0) {
      setShowTasks(Array(getTaskResponse.data.formattedArtefactMapping?.length).fill(true));
    }
  };

  const handleBack = () => {
    history.push(
      `${routeNames.app}${routeNames.ACCOMPLISHMENT}/${selectedProgram.value}/${usersId}/${accessToken}`
    );
  };

  const handleOpen = (index: number) => {
    let temp = [...showTasks];
    temp[index] = !temp[index];
    setShowTasks(temp);
  };

  const handleTaskDetails = (data: any) => {
    let options = location?.state?.programOptions;
    history.push(`${routeNames.app}${routeNames.ACCOMPLESMENTARTEFACTDETAILS}`, {
      artefactDetails: data,
      programOptions: options,
      userId: usersId,
      accessToken: accessToken,
      mainProgramList: mainProgramList
    });
  };

  return (
    <>
      <View style={styles.body}>
        <ProgramDropdown
          selectedProgram={dropDownSelectedProgram}
          setSelectedProgram={setSelectedProgram}
          options={options}
          setDropDownSelectedProgram={setDropDownSelectedProgram}
        />
        <View style={{ zIndex: -1 }}>
          <View style={styles.headingContainer}>
            {isBackEnabled && (
              <View style={styles.mt2}>
                <TouchableOpacity onPress={handleBack}>
                  <Icon name={IconNames.leftArrow} customStyle={styles.leftarrow} />
                </TouchableOpacity>
              </View>
            )}
            <View style={isBackEnabled ? styles.ml20 : { marginLeft: 0 }}>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.headerText]}
              >
                {t("accomplishment:artefact")}
              </Text>
            </View>
          </View>
          <View style={styles.mt8}>
            {artefactList.length > 0 ? (
              artefactList.map((i, key: number) => (
                <View>
                  <View style={[styles.mt12, styles.flexContainer]}>
                    <Text
                      testId="qualificationPack"
                      fontWeight={FontWeight.ExtraBold}
                      textSize={TextSize.Large}
                      style={[styles.cardHeader]}
                    >
                      {i[0].artefact_type_title}
                    </Text>
                  </View>
                  <View style={[styles.mt8, styles.flexContainer]}>
                    <Text
                      testId="qualificationPack"
                      fontWeight={FontWeight.ExtraBold}
                      textSize={TextSize.Large}
                      style={[styles.cardSubHeader]}
                    >
                      ({i.length} {t("accomplishment:artefact")})
                    </Text>
                    <View
                      style={
                        setShowTasks[key]
                          ? styles.alignLeftContainer
                          : [styles.alignLeftContainer, styles.mr12]
                      }
                    >
                      <TouchableOpacity onPress={() => handleOpen(key)}>
                        <Icon
                          name={IconNames.down}
                          size={10}
                          customStyle={
                            setShowTasks[key] ? styles.cardIconInverted : styles.cardIcon
                          }
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  {showTasks[key] ? (
                    <View style={{ marginTop: 14 }}>
                      <View
                        style={{
                          borderRadius: 10,
                          backgroundColor: colorPallete.white,
                          width: "100%",
                          paddingHorizontal: 14,
                          paddingBottom: 30
                        }}
                      >
                        {i?.map((data: any, index: number) => (
                          <TouchableOpacity onPress={() => handleTaskDetails(i[index])}>
                            <View style={styles.pt14}>
                              <Text
                                testId="qualificationPack"
                                fontWeight={FontWeight.Light}
                                textSize={TextSize.Regular}
                                style={[styles.topicName]}
                              >
                                {data.artefact_name}
                              </Text>
                            </View>
                            <View style={styles.pt14}>
                              <Text
                                testId="qualificationPack"
                                fontWeight={FontWeight.Light}
                                textSize={TextSize.Regular}
                                style={[styles.baseLocationName]}
                              >
                                {auxiliaryMethods.fromatBaseLocationSecondary(data)}
                              </Text>
                            </View>
                            <View style={styles.dateContainer}>
                              <Text
                                testId="qualificationPack"
                                fontWeight={FontWeight.Light}
                                textSize={TextSize.Regular}
                                style={[styles.topicCompletionDate]}
                              >
                                {data.status === "APPROVED"
                                  ? dataHelper.formatDate(data.approved_date)
                                  : data.status === "SUBMITTED"
                                  ? dataHelper.formatDate(data.submitted_date)
                                  : data.status === "REJECTED"
                                  ? dataHelper.formatDate(data.updated_at)
                                  : dataHelper.formatDate(data.updated_at)}
                              </Text>
                              <Text
                                testId="qualificationPack"
                                fontWeight={FontWeight.Light}
                                textSize={TextSize.Regular}
                                style={
                                  data.status === "APPROVED"
                                    ? [styles.status, { color: colorPallete.cordioGreen }]
                                    : data.status === "REVIEW IN PROGRESS"
                                    ? [styles.status, { color: colorPallete.yellowOne }]
                                    : data?.status === "REJECTED"
                                    ? [styles.status, { color: colorPallete.cordioRed }]
                                    : [styles.status, { color: colorPallete.cordioBlue1 }]
                                }
                              >
                                {data.status}
                              </Text>
                            </View>
                            {index + 1 !== i.length ? <View style={styles.divider}></View> : null}
                          </TouchableOpacity>
                        ))}
                      </View>
                    </View>
                  ) : null}
                  <View style={styles.divider}></View>
                </View>
              ))
            ) : (
              <View style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {t("accomplishment:noData")}
              </View>
            )}
          </View>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: colorPallete.cordioBeige,
    paddingHorizontal: 16,
    paddingBottom: 200,
    minHeight: "100vh"
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20
  },
  ml20: {
    marginLeft: 20
  },
  mt2: {
    marginTop: 2
  },
  headerText: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 24,
    color: colorPallete.cordioTaupe
  },
  leftarrow: {
    color: colorPallete.cordioTaupe,
    width: 6,
    height: 12
  },
  tableHeaders: {
    marginTop: 28,
    display: "flex",
    flexDirection: "row"
  },
  tableText: {
    fontFamily: FontFamily.Regular,
    fontSize: 14,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  alignLeftContainer: {
    marginLeft: "auto"
  },
  cardHeader: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  cardSubHeader: {
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.cordioTaupe
  },
  cardIcon: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6
  },
  cardIconInverted: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6,
    transform: [{ rotate: "180deg" }]
  },
  mt8: {
    marginTop: 8
  },
  mt12: {
    marginTop: 12
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  mr12: {
    marginRight: 12
  },
  divider: {
    border: "1px solid #675850",
    opacity: 0.1,
    marginTop: 14
  },
  topicName: {
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.textBlack
  },
  baseLocationName: {
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.textLight
  },
  topicCompletionDate: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 14,
    color: colorPallete.cordioRed
  },
  mt14: {
    marginTop: 14
  },
  whiteCard: {
    borderRadius: 10,
    backgroundColor: colorPallete.white
  },
  orContainer: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 16,
    color: colorPallete.textBlack
  },
  pt14: {
    paddingTop: 14
  },
  dateContainer: {
    marginTop: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  status: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 14
    // color: colorPallete.cordioRed
  }
});
