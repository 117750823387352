import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
interface Props {
  url?: string;
  setShowPreview?: any;
}

export default function TopicTaskPreview(props: Props): ReactElement {
  const { url, setShowPreview } = props;
  return (
    <View style={{ width: 320 }}>
      <View style={{ backgroundColor: colorPallete.cordioRed, paddingTop: 10, paddingRight: 10 }}>
        <Icon
          name={IconNames.close}
          customStyle={styles.icon}
          onPress={() => setShowPreview(false)}
        />
      </View>
      <iframe src={url} height="600" width="320" frameBorder="0"></iframe>
    </View>
  );
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: "auto",
    marginBottom: 10,
    fontSize: 14
  }
});
