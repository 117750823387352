import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
interface Props {
  programDetails?: any;
  qualificationPackDetails?: any;
  setShowPublishedModal?: any;
  selectionList?: any[];
}

export default function PublishedRoleBadge(props: Props): ReactElement {
  const { programDetails, qualificationPackDetails, setShowPublishedModal, selectionList } = props;
  const [dropdownOption, setDropdownOptions] = useState([]);
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const userRolesProgram: any = useSelector((state: any) => state?.userRolesProgram);
  const userRoles = userRolesProgram?.userRolesData?.response;

  useEffect(() => {
    getRoleBadges();
    getTags();
  }, []);

  const getTags = async () => {
    try {
      setIsLoading(true);
      let response = await ApiClient.get(
        qualificationPackEndPoints.getTags(qualificationPackDetails?.bundle?.id)
      );
      if (response?.responseCode == HTTP_STATUS_CODES.ok) {
        let badges = response?.response?.map((data: any) => {
          return { value: data.badgeId, label: data.name };
        });
        setSelectedBadges(badges);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const getRoleBadges = async () => {
    let response = await ApiClient.get(
      qualificationPackEndPoints.getRoleBadges(programDetails?.program?.id)
    );
    if (response?.responseCode == HTTP_STATUS_CODES.ok) {
      let options = response?.response[programDetails?.program?.id].map((data: any) => {
        return { value: data.badgeId, label: data.name };
      });
      setDropdownOptions(options);
    }
  };

  const customStyles = {
    control: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: "white",
      marginTop: 2,
      boxShadow: "none",
      border: `1px solid silver !important`,
      ":hover": {
        border: `1px solid silver !important`,
        marginTop: 2
      }
    }),
    menuList: (base: any) => ({
      ...base,
      height: 150,
      "::-webkit-scrollbar": {
        width: 5
      },
      "::-webkit-scrollbar-track": {
        background: "#D1D1D1",
        borderRadius: 10
      },
      "::-webkit-scrollbar-thumb": {
        background: "#808080",
        borderRadius: 10
      }
    }),
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor:
          "isTopicActive" in data ? (data.isTopicActive ? "white" : "#B7504A") : "white",
        borderRadius: 50,
        border: "1px solid #B7504A",
        padding: 2,
        maxWidth: 500
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      fontSize: 14
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "black",
      borderRadius: 50,
      marginTop: 2,
      marginLeft: 7,
      marginRight: 3,
      height: 22,
      display: !userRoles?.includes("PROGRAM_ADMIN") ? "none" : "flex",
      ":hover": {
        backgroundColor: "black"
      }
    })
  };

  const handleDropdown = (data: any, event: any) => {
    let tempBadges = [...selectedBadges];
    setDisableSave(false);
    if (event.action == "select-option") {
      tempBadges.push(event.option);
      setSelectedBadges(tempBadges);
    } else {
      const removedProgram = event.removedValue;
      let index = tempBadges.findIndex((i) => i.value == removedProgram.value);
      tempBadges.splice(index, 1);
      setSelectedBadges(tempBadges);
    }
  };

  const updateTelemetry = async (data: any) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const body: any = {
      timestamp: moment().toLocaleString(),
      createdAt: moment().toLocaleString(),
      deleted: "false",
      updatedAt: moment().toLocaleString(),
      eventType: "QP Published",
      noOfSelections: selectionList.length,
      bundleId: data.id,
      bundleName: data.name,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programDetails, user, true);
    googleWebAnalytics("QP_Published", "Telemetry_Event", "Program");
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      let payload = {
        ids: selectedBadges.map((i) => {
          return i.value;
        })
      };
      let response = await ApiClient.put(
        qualificationPackEndPoints.getTags(qualificationPackDetails?.bundle?.id),
        payload
      );
      if (response?.responseCode == HTTP_STATUS_CODES.ok) {
        setIsLoading(false);
        setShowPublishedModal(false);
        updateTelemetry(qualificationPackDetails?.bundle);
        toast.success(response.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loader customLoadingContainer={styles.loader} />
  ) : (
    <View style={styles.mainContainer}>
      <View style={styles.flexContainer}>
        <Text
          fontWeight={FontWeight.Bold}
          testId="publishQualificationPack"
          textSize={TextSize.Regular}
          textStyle={[styles.header]}
        >
          Role Badges
        </Text>
        <View style={styles.closeIconContainer}>
          <TouchableOpacity onPress={() => setShowPublishedModal(false)}>
            <Icon testID="close" name={IconNames.crossCircle} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.mt60, styles.flexContainer]}>
        <View style={[styles.mt11, styles.mr14]}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="roleBadges"
            textSize={TextSize.Regular}
            textStyle={[styles.subHeader]}
          >
            Role badges
          </Text>
        </View>
        <View style={styles.dropdownContainer}>
          <Select
            isClearable={false}
            value={selectedBadges}
            removeSelected={true}
            hideSelectedOptions={true}
            onChange={(item, e) => [handleDropdown(item, e)]}
            placeholder={
              <Text
                fontWeight={FontWeight.Regular}
                testId="qualificationTitleText"
                textSize={TextSize.Small}
                textStyle={styles.placeholderText}
              >
                Select Role Badges
              </Text>
            }
            options={dropdownOption}
            openMenuOnClick={true}
            isMulti={true}
            menuPosition={"fixed"}
            styles={customStyles}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
            isDisabled={!userRoles?.includes("PROGRAM_ADMIN")}
          />
        </View>
      </View>
      <View style={styles.footerConatiner}>
        <View
          style={[styles.publish, { cursor: userRoles?.includes("PROGRAM_ADMIN") && "pointer" }]}
        >
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.buttonStyles}
            textStyles={styles.buttonText}
            title="Save"
            disabled={
              disableSave || selectedBadges?.length == 0 || !userRoles?.includes("PROGRAM_ADMIN")
            }
            onPress={() => handleSave()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    color: colorPallete.textBlack,
    fontSize: 14,
    fontFamily: FontFamily.Bold
  },
  mainContainer: {
    // height: 760,
    width: 680,
    cursor: "auto"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  closeIconContainer: {
    marginLeft: "auto"
  },
  mt20: {
    marginTop: 20
  },
  subHeader: {
    color: colorPallete.textLight,
    fontSize: 14,
    fontFamily: FontFamily.Regular
  },
  selectHeader: {
    color: colorPallete.textLight,
    fontSize: 14,
    fontFamily: FontFamily.Bold
  },
  mt60: {
    marginTop: 60
  },
  mt11: {
    marginTop: 11
  },
  mr14: {
    marginRight: 14
  },
  dropdownContainer: {
    width: "85%"
  },
  placeholderText: {
    color: colorPallete.textLight,
    fontSize: 14,
    fontFamily: FontFamily.Regular
  },
  footerConatiner: {
    marginTop: 200,
    zIndex: -1
  },
  note: {
    color: colorPallete.textLight,
    fontSize: 16,
    fontFamily: FontFamily.Medium,
    textAlign: "center",
    textDecorationLine: "underline"
  },
  subFooter: {
    marginTop: 17,
    marginHorizontal: 60
  },
  subFooterText: {
    color: colorPallete.textBlack,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    textAlign: "center"
  },
  publish: {
    marginTop: 50,
    alignItems: "center"
  },
  buttonStyles: {
    width: 140,
    height: 50
  },
  buttonText: {
    color: colorPallete.white,
    fontSize: 14,
    fontFamily: FontFamily.Regular
  },
  loader: {
    width: 680,
    height: 430
  }
});
