import React, { useState, useEffect } from "react";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import List from "@socion-cordio/common/src/components/molecules/List";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { applicationAccessEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function ProfileNavbar(props: any) {
  const DATA = [];
  DATA.push(
    {
      id: 1,
      title: "Entity Profile",
      iconName: IconNames.entity,
      path: `${routeNames.app}${routeNames.ENTITY}`
    },
    {
      id: 2,
      title: "Administrators",
      iconName: IconNames.administrator,
      path: `${routeNames.app}${routeNames.ADMINS}`
    },
    {
      id: 3,
      title: "My Programs",
      iconName: IconNames.program,
      path: `${routeNames.app}${routeNames.PROGRAM}`
    },
    {
      id: 4,
      title: "Partnerships",
      iconName: IconNames.partnership,
      path: `${routeNames.app}${routeNames.PARTNERSHIP}`
    }
  );
  return <List getSidePanelWidth={props.getSidePanelWidth} data={DATA} />;
}
