import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  programEndPoints,
  sessionPackEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import jsonToCsv from "@socion-cordio/common/src/utils/jsonToCsvDownload";
import { useDispatch, useSelector } from "react-redux";
import { TopicsActions } from "@socion-cordio/common/src/modules/topic/actions/actions";
import { useLocation } from "react-router-dom";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useTranslation } from "react-i18next";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { toast } from "react-toastify";
import { StoreProviderService } from "@socion-cordio/common/src/services/StoreProviderService";
import { ProgramActions } from "@socion-cordio/common/src/modules/program/actions/actions";

interface Props {
  programDetails: any;
}

export default function CreateNewSessionPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const location: any = useLocation();
  const history: any = useHistory();
  const dispatch = useDispatch();
  const [isCreateSessionBlocked, setIsCreateSessionBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    topicsData: topics,
    loading: { getTopics: loading }
  } = useSelector((state: any) => state.topics);
  const selectedProgramDetails = useSelector(
    (state: any) => state?.programs?.selectedProgramDetails
  );

  useEffect(() => {
    getProgramDetails();
  }, []);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const payload = {
      programId: programDetails?.program?.id
    };
    dispatch(TopicsActions.getTopics(payload));
    // setProgramDetails(programDetails);
  };

  const createNewSessionHandler = async (topicData: any) => {
    setIsLoading(true);
    googleWebAnalytics("Session_Create_Session_Topic_Selected", "Button", "Session");

    const programDetails: any = await LocalStorage.getStorage("programDetails");
    try {
      const res = await ApiClient.get(
        programEndPoints.getProgramStatusEP(programDetails?.program?.id)
      );
      const data = res?.response;
      setIsLoading(false);
      if (data === null || data?.blockSessionCreation === false) {
        history.push(
          `${routeNames.app}${routeNames.SESSION}${routeNames.CREATENEWSESSION}${routeNames.CREATESESSION}`,
          {
            sessionData: topicData
          }
        );
      } else {
        setIsCreateSessionBlocked(true);
        toast.error("Unable to create session!");
        history.goBack();
        StoreProviderService.dispatch(
          ProgramActions.getSelectedProgramData({
            ...selectedProgramDetails,
            isCreateSessionBlocked: true
          })
        );
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, topic.serialNo);
        }
      },
      {
        Header: "Topic name",
        accessor: "name",
        width: 175,
        maxWidth: 175,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };

          return cellData("name", style, topic.name);
        }
      },
      {
        Header: "Date of creation",
        accessor: "creationDate",
        width: 75,
        maxWidth: 75,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("creationDate", style, dataHelper.formatDate(topic.creationDate));
        }
      },
      {
        Header: "Sessions",
        accessor: "sessionCount",
        width: 50,
        maxWidth: 50,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          return cellData("sessionCount", style, topic.sessionCount);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 50,
        maxWidth: 50,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => (
          <Text
            fontWeight={FontWeight.Light}
            testId="deleted"
            textSize={TextSize.Small}
            style={{
              fontSize: 12,
              fontFamily: FontFamily.Regular,
              color: colorPallete.textBlack
            }}
          >
            <View
              style={[
                styles.status,
                topic.status === "ACTIVE"
                  ? styles.active
                  : topic.status === "DEACTIVATED"
                  ? styles.deactivated
                  : topic.status === "PENDING"
                  ? styles.pending
                  : null
              ]}
            />
            {dataHelper.capitalize(topic.status)}
          </Text>
        )
      },
      {
        Header: "Participants",
        accessor: "participantCount",
        width: 56,
        maxWidth: 56,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          return cellData("participantCount", style, topic.participantCount);
        }
      },
      {
        Header: "Create Session",
        accessor: "createSession",
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginRight: 10
            }}
          >
            <TouchableOpacity
              onPress={() => {
                createNewSessionHandler(topic);
              }}
            >
              <Image
                testId="secondaryAddImg"
                source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                imageStyle={{
                  width: 20,
                  height: 20
                }}
              />
            </TouchableOpacity>
          </View>
        )
      }
    ],
    []
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <View style={styles.subContainer}>
          <View style={styles.toggleContainer}>
            <View>
              <Icon
                testID="down"
                name={IconNames.down}
                customStyle={styles.iconStyle}
                onPress={() => {
                  history.push(`${routeNames.app}${routeNames.SESSION}`, {
                    locationData: { pathname: "live" }
                  });
                }}
              />
            </View>
            <Text
              fontWeight={FontWeight.Thin}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={[styles.headerText]}
            >
              {t("session:createSession")}
            </Text>
          </View>
        </View>
        <View>
          {loading ? (
            <Loader />
          ) : dataHelper.isEmptyArray(topics?.response) ? (
            <View style={styles.noDataContainer}>
              <Text
                fontWeight={FontWeight.Thin}
                testId="headerText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {"There are no topics created for this program"}
              </Text>
            </View>
          ) : (
            <Table
              columns={columns}
              data={topics?.response.filter((topic: any) => topic.status !== "DEACTIVATED")}
              createSessionHeaderText={
                <View>
                  <Text
                    testId="testId"
                    fontWeight={FontWeight.Bold}
                    textSize={TextSize.Small}
                    style={[
                      styles.textAlignment,
                      {
                        fontFamily: FontFamily.Regular,
                        fontSize: 12
                      }
                    ]}
                  >
                    {"createSessionHeaderText"}
                  </Text>
                </View>
              }
            />
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    padding: 20,
    backgroundColor: "#F8F5F0",
    minHeight: "calc(100vh - 50px)"
  },
  container: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    fontWeight: "700"
    // marginBottom: 5
  },
  subContainer: {},
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    padding: 18,
    alignItems: "center",
    paddingBottom: 0
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 9,
    marginRight: 20,
    transform: [{ rotate: "90deg" }]
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  noDataContainer: {
    height: "calc(100vh - 150px)",
    padding: 20
  }
});
