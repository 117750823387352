import imageCompression from "browser-image-compression";

export const getCompressedFile = async (targetFile: any) => {
  const file: any = {};
  file.modified = targetFile?.lastModified;
  file.modifiedDate = targetFile?.lastModifiedDate;
  file.fileName = targetFile?.name;
  file.fileSize = targetFile?.size;
  file.mimeType = targetFile?.type;
  file.relativePath = targetFile?.webkitRelativePath;
  console.log(`originalFile size ${file.fileSize / 1024 / 1024} MB`);
  const compressedFile = await imageCompression(targetFile, {
    maxSizeMB: 1,
    maxWidthOrHeight: 150
  });
  console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

  const url = await imageCompression.getDataUrlFromFile(compressedFile);

  return { file, url };
};

export const DocHelper = {
  getCompressedFile
};
