import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { useSelector, useDispatch } from "react-redux";
import MobileInput from "@socion-cordio/common/src/components/molecules/mobileInput";
import OtpValidate from "@socion-cordio/common/src/components/molecules/otpValidate";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { ProfileRepository } from "@socion-cordio/common/src/repositories/profile/profile";

interface Props {
  onClose: Function;
  countryCodeObj: any;
}

export default function AddMobile(props: Props): ReactElement {
  const profileState = useSelector((state: any) => state.profile);
  const {
    userProfileData: { response }
  } = profileState;
  const [otpHandle, setOtpHandle] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [countryCode, setCountryCode] = useState(response.countryCode);
  const [phoneNumberDetails, setPhoneNumberDetails] = useState(null);
  const [error, setError] = useState(false);
  const [incorrectMobileMsg, setIncorrectMobileMsg] = useState(null);
  const [customError, setCustomError] = useState("");
  const [validate, setValidate] = useState([
    {
      label: props.countryCodeObj.code,
      phoneNumberLength: props.countryCodeObj.phoneNumberLength,
      phoneNumberSizeMax: props.countryCodeObj.phoneNumberSizeMax,
      phoneNumberSizeMin: props.countryCodeObj.phoneNumberSizeMin,
      value: `+${props.countryCodeObj.code}`
    }
  ]);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);
  const [mobileNumberPopup, setMobileNumberPopup] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const history: any = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const user: any = await LocalStorage.getStorage("user");
    setUserDetails(user);
  };

  const sendOtpNewPhone = async (isResendClicked: boolean) => {
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    let errorMessage;
    if (!regex.test(mobileNumber)) {
      setError(true);
      errorMessage = "Invalid characters";
      setCustomError(errorMessage);
      return;
    } else if (minLength == maxLength && mobileNumber.length != 10) {
      setError(true);
      errorMessage = "The phone number should be of 10 digits";
      setCustomError(errorMessage);
      return;
    } else if (minLength !== maxLength && mobileNumber.length > 20) {
      setError(true);
      errorMessage = `The phone number should be between ${minLength} to ${maxLength} digits.`;
      setCustomError(errorMessage);
      return;
    } else {
      setError(false);
      errorMessage = "";
      setCustomError(errorMessage);
    }
    setIncorrectMobileMsg(null);
    let params = {
      phoneNumber: mobileNumber,
      countryCode: `+${countryCode}`
    };
    const response = ProfileRepository.sendOtpToNewPhone(params);
    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setOtpHandle(true);
          isResendClicked && toast.success("OTP sent successfully");
          setPhoneNumberDetails({ countryCode: countryCode, mobileNumber: mobileNumber });
        } else if (res.responseCode === HTTP_STATUS_CODES.created) {
          setOtpHandle(false);
          setMobileNumberPopup(false);
          toast.success("Mobile number updated successfully");
        } else if (res.responseCode === HTTP_STATUS_CODES.badRequest) {
          toast.error("This user is already registered in the system.");
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (validate[0]?.phoneNumberSizeMax != validate[0]?.phoneNumberSizeMin) {
      setMinLength(validate[0]?.phoneNumberSizeMin);
      setMaxLength(validate[0]?.phoneNumberSizeMax);
    } else {
      setMinLength(10);
      setMaxLength(10);
    }
  }, [validate]);

  const handleMobileNumber = (mobileNumber: string) => {
    // const mobilePattern = /^\d{10}$/;
    // const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    // let errorMessage;
    // if (!regex.test(mobileNumber)) {
    //   setError(true);
    //   errorMessage = "Invalid characters";
    // } else if (minLength == maxLength && mobileNumber.length != 10) {
    //   setError(true);
    //   errorMessage = "The phone number should be of 10 digits";
    // } else if (minLength !== maxLength && mobileNumber.length > 20) {
    //   setError(true);
    //   errorMessage = `The phone number should be between ${minLength} to ${maxLength} digits.`;
    // } else {
    //   setError(false);
    //   errorMessage = "";
    // }
    // setCustomError(errorMessage);
    setMobileNumber(mobileNumber);
    // setIncorrectMobileMsg(null);
  };

  const handleLogout = () => {
    setTimeout(() => {
      dispatch({ type: "USER_LOGOUT" });
      history.push("/new/iam/login");
      LocalStorage.removeStoredKeys();
    }, 1000);
  };

  return (
    <View>
      <ToastContainer />
      {!otpHandle && mobileNumberPopup && (
        <View>
          <View style={styles.header}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {userDetails?.phoneNumber !== "" ? "Change mobile number" : "Enter mobile number"}
            </Text>
            <View>
              <Pressable onPress={() => props.onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View style={styles.subContainerTwo}>
            <View style={styles.addNewNumberContainer}>
              <View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleTitleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.subheaderText, styles.mobileNumberHeaderText]}
                >
                  Mobile number
                </Text>
              </View>
              <MobileInput
                handleChange={(mobileNumber: string) => {
                  handleMobileNumber(mobileNumber);
                  setCustomError("");
                  setError(false);
                }}
                onChangeValue={(countryCode: number) => {
                  setCountryCode(countryCode);
                  setCustomError("");
                  setError(false);
                }}
                handleBlur={() => {}}
                inputStyle={styles.mobileInput}
                name="mobileNumber"
                placeholder="Enter number"
                id="mobileNumber"
                dropdownPlaceholder="+91"
                noFormik
                setValidate={setValidate}
                dropDownContainerStyle={styles.dropDownContainerStyle}
              />
            </View>
            {error && (
              <View style={styles.messageContainer}>
                <Text
                  testId="emailError"
                  textSize={TextSize.Small}
                  fontWeight={FontWeight.Regular}
                  textStyle={styles.error}
                >
                  {customError}
                </Text>
              </View>
            )}
            {!error && incorrectMobileMsg && (
              <Text
                testId="incorrectEmail"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                {incorrectMobileMsg}
              </Text>
            )}
            <View style={styles.submitButtonContainer}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.submitbutton}
                title="Save"
                onPress={() => sendOtpNewPhone(false)}
                disabled={error || !mobileNumber}
              />
            </View>
          </View>
        </View>
      )}
      {otpHandle && (
        <OtpValidate
          onClose={props.onClose}
          modalTitle={
            userDetails?.phoneNumber !== "" ? "Change mobile number" : "Enter mobile number"
          }
          headerText="Please enter the code that was sent to the new mobile number"
          newPhoneOtp={true}
          sendOtpNewPhone={() => sendOtpNewPhone(true)}
          phoneNumberDetails={phoneNumberDetails}
        />
      )}
      {!otpHandle && !mobileNumberPopup && (
        <View>
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {userDetails?.phoneNumber !== "" ? "Change mobile number" : "Enter mobile number"}
            </Text>
            <View>
              <Pressable
                onPress={() => {
                  handleLogout();
                }}
              >
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View>
            Your Phone Number has been changed successfully. You will be prompted to login with your
            new Phone Number
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 15
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainerTwo: {
    marginTop: 15
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10,
    zIndex: -1
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10
  },
  addNewNumberContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 100,
    justifyContent: "center"
  },
  mobileNumberHeaderText: {
    marginTop: 12,
    marginRight: 10
  },
  mobileInput: {
    width: "250px"
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  dropDownContainerStyle: {
    marginTop: 10,
    width: 303,
    zIndex: 10
  },
  messageContainer: {
    zIndex: -1,
    marginLeft: "auto",
    marginRight: "auto"
  }
});
