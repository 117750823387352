import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, FlatList } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Header from "@socion-cordio/common/src/components/organisms/accomplishment/components/header";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/accomplishment/components/programDropdown";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { toast } from "react-toastify";
import {
  qualificationPackEndPoints,
  accomplishments
} from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { useTranslation } from "react-i18next";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import { Image } from "@socion-cordio/common/src/components/atoms/image";

export default function QualificationPack(): ReactElement {
  const history: any = useHistory();
  const location: any = useLocation();
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [qpDetails, setQpDetails] = useState([]);
  const [qpSelfLearningDetails, setQpSelfLearningDetails] = useState([]);
  const [showSelections, setShowSelections] = useState([]);
  const [showSelfLearningSelections, setShowSelfLearningSelections] = useState([]);
  const [dropDownSelectedProgram, setDropDownSelectedProgram] = useState({});
  const [isSessionExpanded, setIsSessionExpanded] = useState(false);
  const [isSelfLearningExpanded, setSelfLearningExpanded] = useState(false);
  const [usersId, setUsersId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [mainProgramList, setMainProgramList] = useState([]);
  const [filteredSelectedProgram, setFilteredSelectedProgram] = useState(null);
  const [options, setOptions] = useState([]);
  const [isBackEnabled, setIsBackEnabled] = useState(location?.state?.isBackEnabled ?? false);

  const { t } = useTranslation();

  // const { isBackEnabled = false } = location.state;

  useEffect(() => {
    // getSelectedProgram();
    // setSelectedProgram(programData);
    getAllUserPrograms();
  }, []);

  useEffect(() => {
    if (dropDownSelectedProgram)
      LocalStorage.setStorage("selectedProgram", dropDownSelectedProgram);
  }, [dropDownSelectedProgram]);

  useEffect(() => {
    if (selectedProgram && selectedProgram.value) {
      getQualificationPackDetails();
    }
  }, [dropDownSelectedProgram]);

  const getAllUserPrograms = async () => {
    try {
      setIsLoading(true);
      const urlParams = window.location.pathname.split("/").reverse();
      const programId = urlParams[2];
      const userId: any = urlParams[1];
      const accessToken: any = urlParams[0];
      setUsersId(userId);
      setAccessToken(accessToken);

      await LocalStorage.setStorage("accessToken", accessToken);

      try {
        const response = await ApiClient.post(accomplishments.getAllProgramsByUsers(), {});
        if (response?.response) {
          setMainProgramList(response?.response);
          let optionsData = response?.response.map((i: any) => {
            return { label: i.programName, value: i.programId };
          });
          setOptions(optionsData);
          let filteredId = optionsData?.filter((k: any) => k.value == programId);
          let filteredProgram = response?.response?.filter((k: any) => k.programId == programId);
          setFilteredSelectedProgram(filteredProgram[0]);
          setSelectedProgram(filteredId[0]);
          setDropDownSelectedProgram(filteredId[0]);
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.");
      setIsLoading(false);
    }
  };

  const getSelectedProgram = async () => {
    let program = await LocalStorage.getStorage("selectedProgram");
    setDropDownSelectedProgram(program);
  };

  const getQualificationPackDetails = async () => {
    try {
      setDropDownSelectedProgram(selectedProgram);
      setIsLoading(true);
      const user: any = await LocalStorage.getStorage("user");
      const response = await ApiClient.get(
        qualificationPackEndPoints.getAccomplishmentQPdetails(selectedProgram.value)
      );
      if (response?.responseCode == HTTP_STATUS_CODES.ok) {
        if (response?.response[0]) {
          let newArray = response?.response[0]?.qpDetails.map((data: any, index: number) => {
            let dates: any = [];
            data.sessionSelectionDetails.map((i: any, index1: any) => {
              i?.selectionList.map((data: any, index2: number) => {
                dates.push(String(data.topicCompletionDate));
                let allDatesCheck = dates.every((k: string) => k === "null");
                if (allDatesCheck === false) {
                  if (i?.selectionList.length > 1) {
                    if (i?.selectionCompleted) {
                      let test = i?.selectionList.filter(
                        (m: any, n: number) => m.topicCompletionDate !== null
                      );
                      i.selectionList = test;
                    }
                  }
                }
              });
            });

            data.SelfLearningSelectionDetails.map((i: any, index1: any) => {
              i?.selectionList.map((data: any, index2: number) => {
                dates.push(String(data.topicCompletionDate));
                let allDatesCheck = dates.every((k: string) => k === "null");
                if (allDatesCheck === false) {
                  if (i?.selectionList.length > 1) {
                    if (i?.selectionCompleted) {
                      let test = i?.selectionList.filter(
                        (m: any, n: number) => m.topicCompletionDate !== null
                      );
                      i.selectionList = test;
                    }
                  }
                }
              });
            });
          });
          setQpDetails(response?.response[0]?.qpDetails);
          setQpSelfLearningDetails(response?.response[0]?.qpDetails);
          setShowSelfLearningSelections(
            Array(response?.response[0]?.qpDetails?.length).fill(false)
          );
          setShowSelections(Array(response?.response[0]?.qpDetails?.length).fill(false));
        } else {
          setQpDetails(response?.response);
          setQpSelfLearningDetails(response?.response);
        }
      } else {
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong.");
      setIsLoading(false);
    }
  };

  const handleOpen = (index: number) => {
    let temp = [...showSelections];
    temp[index] = !temp[index];
    setShowSelections(temp);
    setIsSessionExpanded((prevState) => false);
    setSelfLearningExpanded((prevState) => false);
  };

  const handleBack = () => {
    history.push(
      `${routeNames.app}${routeNames.ACCOMPLISHMENT}/${selectedProgram.value}/${usersId}/${accessToken}`
    );
  };

  const cutomClickSessionHandler = () => {
    setIsSessionExpanded((prevState) => !prevState);
  };

  const cutomClickSelfLearningHandler = () => {
    setSelfLearningExpanded((prevState) => !prevState);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <View style={[styles.body, { height: qpDetails?.length == 0 ? "100vh" : "" }]}>
        <ProgramDropdown
          selectedProgram={dropDownSelectedProgram}
          setSelectedProgram={setSelectedProgram}
          setDropDownSelectedProgram={setDropDownSelectedProgram}
          options={options}
        />
        <View style={{ zIndex: -1 }}>
          <View style={styles.headingContainer}>
            {isBackEnabled && (
              <View style={styles.mt2}>
                <TouchableOpacity onPress={handleBack}>
                  <Icon name={IconNames.leftArrow} customStyle={styles.leftarrow} />
                </TouchableOpacity>
              </View>
            )}
            <View style={isBackEnabled ? styles.ml20 : { marginLeft: 0 }}>
              <Text
                testId="qualificationPack"
                fontWeight={FontWeight.ExtraBold}
                textSize={TextSize.Large}
                style={[styles.headerText]}
              >
                {t("accomplishment:qualificationPacks")}
              </Text>
            </View>
          </View>

          <View style={styles.mt8}>
            {qpDetails?.length !== 0 ? (
              <View>
                <View style={styles.tableHeaders}>
                  <Text
                    testId="qualificationPack"
                    fontWeight={FontWeight.ExtraBold}
                    textSize={TextSize.Large}
                    style={[styles.tableText]}
                  >
                    {t("accomplishment:qualificationPack")}
                  </Text>
                  <View style={styles.alignLeftContainer}>
                    <Text
                      testId="qualificationPack"
                      fontWeight={FontWeight.ExtraBold}
                      textSize={TextSize.Large}
                      style={[styles.tableText]}
                    >
                      {t("accomplishment:progress")}
                    </Text>
                  </View>
                </View>
                <View style={styles.mt8}>
                  <View>
                    {qpDetails.length > 0 &&
                      qpDetails.map((i, key) => (
                        <>
                          {(i?.sessionSelectionDetails.length > 0 ||
                            i?.SelfLearningSelectionDetails.length > 0) && (
                            <View>
                              <View>
                                <View style={[styles.mt12, styles.flexContainer]}>
                                  <Text
                                    testId="qualificationPack"
                                    fontWeight={FontWeight.ExtraBold}
                                    textSize={TextSize.Large}
                                    style={[styles.cardHeader]}
                                  >
                                    {i.qpName}
                                  </Text>
                                </View>
                                <View style={[styles.mt8, styles.flexContainer]}>
                                  <Text
                                    testId="qualificationPack"
                                    fontWeight={FontWeight.ExtraBold}
                                    textSize={TextSize.Large}
                                    style={[styles.cardSubHeader]}
                                  >
                                    (
                                    {i?.totalSelectionCount == 1
                                      ? `${i?.totalSelectionCount} ${t("accomplishment:selection")}`
                                      : `${i?.totalSelectionCount} ${t(
                                          "accomplishment:selections"
                                        )}`}
                                    )
                                  </Text>
                                  <View
                                    style={
                                      showSelections[key]
                                        ? styles.alignLeftContainer
                                        : [styles.alignLeftContainer, styles.mr12]
                                    }
                                  >
                                    <TouchableOpacity onPress={() => handleOpen(key)}>
                                      <Icon
                                        name={IconNames.down}
                                        size={10}
                                        customStyle={
                                          showSelections[key]
                                            ? styles.cardIconInverted
                                            : styles.cardIcon
                                        }
                                      />
                                    </TouchableOpacity>
                                  </View>
                                </View>
                                {showSelections[key] ? (
                                  <View style={{ marginTop: 14 }}>
                                    {/* {SESSION-CARD} */}
                                    {i?.sessionSelectionDetails.length > 0 && (
                                      <View
                                        style={{
                                          borderRadius: 10,
                                          backgroundColor: colorPallete.white,
                                          width: "100%",
                                          paddingHorizontal: 14,
                                          paddingBottom: 15
                                        }}
                                      >
                                        <View style={[styles.flexContainer, { marginTop: 12 }]}>
                                          <Text
                                            testId="qualificationPack"
                                            fontWeight={FontWeight.ExtraBold}
                                            textSize={TextSize.Large}
                                            style={[styles.sessionHeaderText]}
                                          >
                                            {t("accomplishment:SessionParticipation")}
                                          </Text>
                                          <View style={styles.alignLeftContainer}>
                                            <Text
                                              testId="qualificationPack"
                                              fontWeight={FontWeight.ExtraBold}
                                              textSize={TextSize.Large}
                                              style={[styles.tableText1]}
                                            >
                                              {i.sessionCompletedSelectionCount}/
                                              {i.sessionSelectionDetails.length}
                                            </Text>
                                          </View>
                                        </View>
                                        <FlatList
                                          keyExtractor={(sessionItem) => sessionItem.index}
                                          data={i?.sessionSelectionDetails}
                                          renderItem={({ item: sessionItem, index }) =>
                                            sessionItem?.selectionList?.length > 1 ? (
                                              <>
                                                {index > 0 ? (
                                                  <View style={styles.divider} />
                                                ) : (
                                                  <></>
                                                )}
                                                <CollapseItem
                                                  headerStyles={styles.collapseHeaderContainer}
                                                  isExpanded={isSessionExpanded}
                                                  headerComponent={
                                                    <View
                                                      style={{
                                                        paddingVertical: 5
                                                      }}
                                                    >
                                                      <Text
                                                        testId="qualificationPack"
                                                        fontWeight={FontWeight.ExtraBold}
                                                        textSize={TextSize.Large}
                                                        style={[styles.tableText]}
                                                      >
                                                        {
                                                          sessionItem?.selectionList[0]
                                                            .selectionName
                                                        }
                                                      </Text>
                                                    </View>
                                                  }
                                                  accordionStyles={styles.accordionStyles}
                                                  cutomClickHandler={cutomClickSessionHandler}
                                                  accordianIconStyle={styles.iconStyles}
                                                  children={
                                                    <>
                                                      {sessionItem?.selectionList.map(
                                                        (x: any, key2: number) => (
                                                          <View style={{ paddingLeft: 15 }}>
                                                            <Text
                                                              testId="qualificationPack"
                                                              fontWeight={FontWeight.Light}
                                                              textSize={TextSize.Regular}
                                                              style={[styles.topicName]}
                                                            >
                                                              {x.topicName}
                                                            </Text>
                                                            <View style={{ marginTop: 5 }}>
                                                              <Text
                                                                testId="qualificationPack"
                                                                fontWeight={FontWeight.Light}
                                                                textSize={TextSize.Regular}
                                                                style={[styles.topicCompletionDate]}
                                                              >
                                                                {x.topicCompletionDate &&
                                                                  dataHelper.formatDate(
                                                                    x.topicCompletionDate,
                                                                    "DD-MMM-YYYY"
                                                                  )}
                                                              </Text>
                                                            </View>
                                                            {key2 !==
                                                              sessionItem?.selectionList.length -
                                                                1 && (
                                                              <View
                                                                style={{
                                                                  marginVertical: 5,
                                                                  marginLeft: "auto",
                                                                  marginRight: "auto"
                                                                }}
                                                              >
                                                                {x.topicCompletionDate === null && (
                                                                  <Text
                                                                    testId="qualificationPack"
                                                                    fontWeight={FontWeight.Light}
                                                                    textSize={TextSize.Regular}
                                                                    style={[styles.orContainer]}
                                                                  >
                                                                    OR
                                                                  </Text>
                                                                )}
                                                              </View>
                                                            )}
                                                          </View>
                                                        )
                                                      )}
                                                    </>
                                                  }
                                                />
                                              </>
                                            ) : (
                                              sessionItem?.selectionList?.length > 0 && (
                                                <FlatList
                                                  keyExtractor={(item, index) => index.toString()}
                                                  data={sessionItem?.selectionList}
                                                  renderItem={({ item, index: key1 }) => (
                                                    <View>
                                                      {index > 0 ? (
                                                        <View style={styles.divider} />
                                                      ) : (
                                                        <></>
                                                      )}
                                                      <CollapseItem
                                                        headerStyles={
                                                          styles.collapseHeaderContainer
                                                        }
                                                        isExpanded={isSessionExpanded}
                                                        headerComponent={
                                                          <View style={{ paddingVertical: 5 }}>
                                                            <Text
                                                              testId="qualificationPack"
                                                              fontWeight={FontWeight.ExtraBold}
                                                              textSize={TextSize.Large}
                                                              style={[styles.tableText]}
                                                            >
                                                              {item?.selectionName}
                                                            </Text>
                                                          </View>
                                                        }
                                                        accordionStyles={styles.accordionStyles}
                                                        cutomClickHandler={cutomClickSessionHandler}
                                                        accordianIconStyle={styles.iconStyles}
                                                        children={
                                                          <View style={{ paddingLeft: 15 }}>
                                                            <Text
                                                              testId="qualificationPack"
                                                              fontWeight={FontWeight.Light}
                                                              textSize={TextSize.Regular}
                                                              style={[styles.topicName]}
                                                            >
                                                              {item.topicName}
                                                            </Text>
                                                            <View style={{ marginTop: 5 }}>
                                                              <Text
                                                                testId="qualificationPack"
                                                                fontWeight={FontWeight.Light}
                                                                textSize={TextSize.Regular}
                                                                style={[styles.topicCompletionDate]}
                                                              >
                                                                {item.topicCompletionDate &&
                                                                  dataHelper.formatDate(
                                                                    item.topicCompletionDate,
                                                                    "DD-MMM-YYYY"
                                                                  )}
                                                              </Text>
                                                            </View>
                                                          </View>
                                                        }
                                                      />
                                                    </View>
                                                  )}
                                                />
                                              )
                                            )
                                          }
                                        />
                                      </View>
                                    )}
                                    {/* {SELF-LEARNING-CARD} */}
                                    {i?.SelfLearningSelectionDetails.length > 0 && (
                                      <View style={{ marginTop: 14 }}>
                                        <View
                                          style={{
                                            borderRadius: 10,
                                            backgroundColor: colorPallete.white,
                                            width: "100%",
                                            paddingHorizontal: 14,
                                            paddingBottom: 15
                                          }}
                                        >
                                          <View style={[styles.flexContainer, { marginTop: 12 }]}>
                                            <Text
                                              testId="qualificationPack"
                                              fontWeight={FontWeight.ExtraBold}
                                              textSize={TextSize.Large}
                                              style={[styles.sessionHeaderText]}
                                            >
                                              {t("accomplishment:SelfLearning")}
                                            </Text>
                                            <View style={styles.alignLeftContainer}>
                                              <Text
                                                testId="qualificationPack"
                                                fontWeight={FontWeight.ExtraBold}
                                                textSize={TextSize.Large}
                                                style={[styles.tableText1]}
                                              >
                                                {i.selfLearningCompletedSelectionCount}/
                                                {i.SelfLearningSelectionDetails.length}
                                              </Text>
                                            </View>
                                          </View>

                                          <FlatList
                                            keyExtractor={(selfLearningItem) =>
                                              selfLearningItem.index
                                            }
                                            data={i?.SelfLearningSelectionDetails}
                                            renderItem={({ item: selfLearningItem, index }) =>
                                              selfLearningItem?.selectionList?.length > 1 ? (
                                                <>
                                                  {index > 0 ? (
                                                    <View style={styles.divider} />
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <CollapseItem
                                                    headerStyles={styles.collapseHeaderContainer}
                                                    isExpanded={isSessionExpanded}
                                                    headerComponent={
                                                      <View
                                                        style={{
                                                          paddingVertical: 5,
                                                          flexDirection: "row"
                                                        }}
                                                      >
                                                        {selfLearningItem?.selectionCompleted && (
                                                          <Image
                                                            testId="primaryAddImg"
                                                            source={require("@socion-cordio/common/src/assets/images/selectionIcon.png")}
                                                            imageStyle={{
                                                              width: 10,
                                                              height: 10,
                                                              paddingRight: 6,
                                                              marginTop: 7
                                                            }}
                                                          />
                                                        )}
                                                        <Text
                                                          testId="qualificationPack"
                                                          fontWeight={FontWeight.ExtraBold}
                                                          textSize={TextSize.Large}
                                                          style={
                                                            selfLearningItem?.selectionCompleted
                                                              ? [
                                                                  styles.tableText,
                                                                  { paddingLeft: 7 }
                                                                ]
                                                              : [
                                                                  styles.tableText,
                                                                  { paddingLeft: 15 }
                                                                ]
                                                          }
                                                        >
                                                          {
                                                            selfLearningItem?.selectionList[0]
                                                              .selectionName
                                                          }
                                                        </Text>
                                                      </View>
                                                    }
                                                    accordionStyles={styles.accordionStyles}
                                                    cutomClickHandler={cutomClickSessionHandler}
                                                    accordianIconStyle={styles.iconStyles}
                                                    children={
                                                      <>
                                                        {selfLearningItem?.selectionList.map(
                                                          (x: any, key2: number) => (
                                                            <View
                                                              style={
                                                                x?.topicCompletionDate
                                                                  ? {
                                                                      paddingLeft: 10,
                                                                      flexDirection: "row"
                                                                    }
                                                                  : {
                                                                      paddingLeft: 22,
                                                                      flexDirection: "row"
                                                                    }
                                                              }
                                                            >
                                                              {x?.topicCompletionDate && (
                                                                <Icon
                                                                  name={IconNames.tick}
                                                                  size={8}
                                                                  color={colorPallete.cordioGreen}
                                                                  customStyle={styles.tickIcon}
                                                                />
                                                              )}
                                                              <Text
                                                                testId="qualificationPack"
                                                                fontWeight={FontWeight.Light}
                                                                textSize={TextSize.Regular}
                                                                style={[styles.topicName2]}
                                                              >
                                                                {x.topicName}
                                                              </Text>
                                                              {key2 !==
                                                                selfLearningItem?.selectionList
                                                                  .length -
                                                                  1 && (
                                                                <View
                                                                  style={{
                                                                    marginVertical: 5,
                                                                    marginLeft: "auto",
                                                                    marginRight: 155
                                                                  }}
                                                                >
                                                                  <Text
                                                                    testId="qualificationPack"
                                                                    fontWeight={FontWeight.Light}
                                                                    textSize={TextSize.Regular}
                                                                    style={[styles.orContainer]}
                                                                  >
                                                                    OR
                                                                  </Text>
                                                                </View>
                                                              )}
                                                            </View>
                                                          )
                                                        )}
                                                      </>
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                selfLearningItem?.selectionList?.length > 0 && (
                                                  <FlatList
                                                    keyExtractor={(item, index) => index.toString()}
                                                    data={selfLearningItem?.selectionList}
                                                    renderItem={({ item, index: key1 }) => (
                                                      <View>
                                                        {index > 0 ? (
                                                          <View style={styles.divider} />
                                                        ) : (
                                                          <></>
                                                        )}
                                                        <CollapseItem
                                                          headerStyles={
                                                            styles.collapseHeaderContainer
                                                          }
                                                          isExpanded={isSessionExpanded}
                                                          headerComponent={
                                                            <View
                                                              style={{
                                                                paddingVertical: 5,
                                                                flexDirection: "row"
                                                              }}
                                                            >
                                                              {selfLearningItem?.selectionCompleted && (
                                                                <Image
                                                                  testId="primaryAddImg"
                                                                  source={require("@socion-cordio/common/src/assets/images/selectionIcon.png")}
                                                                  imageStyle={{
                                                                    width: 10,
                                                                    height: 10,
                                                                    paddingRight: 6,
                                                                    marginTop: 7
                                                                  }}
                                                                />
                                                              )}
                                                              <Text
                                                                testId="qualificationPack"
                                                                fontWeight={FontWeight.ExtraBold}
                                                                textSize={TextSize.Large}
                                                                style={
                                                                  selfLearningItem?.selectionCompleted
                                                                    ? [
                                                                        styles.tableText,
                                                                        { paddingLeft: 7 }
                                                                      ]
                                                                    : [
                                                                        styles.tableText,
                                                                        { paddingLeft: 15 }
                                                                      ]
                                                                }
                                                              >
                                                                {item?.selectionName}
                                                              </Text>
                                                            </View>
                                                          }
                                                          accordionStyles={styles.accordionStyles}
                                                          cutomClickHandler={
                                                            cutomClickSessionHandler
                                                          }
                                                          accordianIconStyle={styles.iconStyles}
                                                          children={
                                                            <View
                                                              style={
                                                                item?.topicCompletionDate
                                                                  ? {
                                                                      paddingLeft: 10,
                                                                      flexDirection: "row"
                                                                    }
                                                                  : {
                                                                      paddingLeft: 22,
                                                                      flexDirection: "row"
                                                                    }
                                                              }
                                                            >
                                                              {item?.topicCompletionDate && (
                                                                <Icon
                                                                  name={IconNames.tick}
                                                                  size={8}
                                                                  color={colorPallete.cordioGreen}
                                                                  customStyle={styles.tickIcon}
                                                                />
                                                              )}
                                                              <Text
                                                                testId="qualificationPack"
                                                                fontWeight={FontWeight.Light}
                                                                textSize={TextSize.Regular}
                                                                style={[styles.topicName2]}
                                                              >
                                                                {item.topicName}
                                                              </Text>
                                                            </View>
                                                          }
                                                        />
                                                      </View>
                                                    )}
                                                  />
                                                )
                                              )
                                            }
                                          />
                                        </View>
                                      </View>
                                    )}
                                  </View>
                                ) : null}
                                <View style={styles.divider}></View>
                              </View>
                            </View>
                          )}
                        </>
                      ))}
                  </View>
                </View>
              </View>
            ) : (
              <View style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {t("accomplishment:noData")}
              </View>
            )}
          </View>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: colorPallete.cordioBeige,
    minHeight: "100vh",
    paddingHorizontal: 16,
    paddingBottom: 100
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20
  },
  ml20: {
    marginLeft: 20
  },
  mt2: {
    marginTop: 2
  },
  headerText: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 24,
    color: colorPallete.cordioTaupe
  },
  leftarrow: {
    color: colorPallete.cordioTaupe,
    width: 6,
    height: 12
  },
  tableHeaders: {
    marginTop: 28,
    display: "flex",
    flexDirection: "row"
  },
  tableText: {
    fontFamily: FontFamily.Medium,
    fontSize: 14,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  tableText1: {
    fontFamily: FontFamily.Medium,
    fontSize: 14,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  sessionHeaderText: {
    fontFamily: FontFamily.Bold,
    fontSize: 12,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  alignLeftContainer: {
    marginLeft: "auto"
  },
  cardHeader: {
    fontFamily: FontFamily.Bold,
    fontSize: 14,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  cardSubHeader: {
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.cordioTaupe
  },
  cardIcon: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6
  },
  cardIconInverted: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6,
    transform: [{ rotate: "180deg" }]
  },
  mt8: {
    marginTop: 8
  },
  mt12: {
    marginTop: 12
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  mr12: {
    marginRight: 12
  },
  divider: {
    border: "1px solid #675850",
    opacity: 0.1,
    marginTop: 14
  },
  topicName: {
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.textBlack
  },
  topicName2: {
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.textBlack,
    paddingLeft: 10
  },
  tickIcon: {
    marginTop: 3
  },
  topicCompletionDate: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 14,
    color: colorPallete.cordioGreen
  },
  mt14: {
    marginTop: 14
  },
  whiteCard: {
    borderRadius: 10,
    backgroundColor: colorPallete.white
  },
  orContainer: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 16,
    color: colorPallete.textBlack
  },
  collapseHeaderContainer: {
    backgroundColor: colorPallete.white,
    fontSize: 14,
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Bold,
    fontWeight: "700",
    // borderRadius: 8,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    shadowColor: colorPallete.white,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 1
    // elevation: 4,
    // shadowColor: colorPallete.lightGrey,
    // shadowOffset: { width: 0, height: 2 },
    // shadowOpacity: 0.8,
    // shadowRadius: 1,
    // lineHeight: 17
  },
  accordionStyles: {
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowColor: colorPallete.white,
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0
  },
  iconStyles: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6,
    paddingLeft: 0,
    paddingRight: 16,
    marginTop: 5
  }
});
