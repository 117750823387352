import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
import { Entity } from "@socion-cordio/common/src/models/registry/entity";
import { RegistryApiClient } from "@socion-cordio/common/src/network/registryApiClient";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  registryEndPoints,
  springshedEndPoints
} from "@socion-cordio/common/src/repositories/endPoints";

class AdminsRepository {
  getAdminsList = async (reqBody: any): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.entityAdminsList(), reqBody);
    return data?.result || [];
  };
  addEntityAdmin = async (reqBody: {}): Promise<any> => {
    let data = await RegistryApiClient.post(registryEndPoints.addEntityAdmin(), reqBody);
    return data?.result || [];
  };
  toggleEntityAdmin = async (reqBody: {}, adminId: string, isActivate: boolean): Promise<any> => {
    const url = isActivate
      ? registryEndPoints.activateEntityAdmin(adminId)
      : registryEndPoints.deactivateEntityAdmin(adminId);
    let data = await RegistryApiClient.patch(url, reqBody);
    return data?.result || [];
  };
}
const adminsRepository = new AdminsRepository();
export { adminsRepository as AdminsRepository };
