import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";
import Moment from "moment";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {
  onClose?: Function;
  assignBadgeData?: any;
  badgeDetailsState?: any;
  getBadgeUsersList: Function;
  selectedRows: any;
}
export default function UnassignBadgeModal(props: Props): ReactElement {
  const { onClose, assignBadgeData, badgeDetailsState, getBadgeUsersList, selectedRows } = props;
  const [memberList, setMemberList] = useState([]);
  const [isRemarkPresent, setIsRemarkPresent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reason, setReason] = useState(undefined);
  const [updatedList, setUpdatedList] = useState([]);
  const [showHeadersOnly, setShowHeadersOnly] = useState(true);
  const [unassignedUserList, setUnassignedUserList] = useState([]);

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  useEffect(() => {
    const list = JSON.parse(JSON.stringify(selectedRows));
    const selectedRowsUpdate = selectedRows.filter(
      (item: any) => item.status === "ASSIGNED" && item.deletedByUser !== true
    );
    setLoading(false);
    setMemberList(selectedRowsUpdate);
  }, []);

  const removeSelectedMember = (data: any) => {
    const userList = [...memberList];
    const removedUser = userList.filter((x) => x.listIndex === data.listIndex);
    removedUser[0].reason = "";
    let list = userList.filter((x) => x.listIndex !== data.listIndex);
    if (list?.length == 0) {
      list = [
        {
          city: "",
          country: "",
          district: "",
          endDate: "",
          name: "",
          reason: "",
          serialNo: "",
          startDate: "",
          state: "",
          subDistrict: ""
        }
      ];
      setShowHeadersOnly(false);
    }
    setMemberList(list);
  };

  const formatSelectedRows = (rows: any) => {
    let newRows: any = [];
    rows.forEach((row: any) => {
      let rowObject: any = {};
      rowObject.batchAssignedId = row.badgeAssignId;
      rowObject.status = "UNASSIGNED";
      rowObject.reason = row.reason;
      newRows.push(rowObject);
    });
    return newRows;
  };

  const saveSelectedBadgeDetailsHandler = async () => {
    const userList = [...memberList];
    const list = userList.filter((x) => x.reason === "");
    list.length === 0
      ? saveDetailsApiCallHandler()
      : toast.error("Please state the reason(s) for unassigning Badge");
    googleWebAnalytics("UnAssign_Badge_Modal_Save", "Button", "UnAssign_Badge");
  };

  const saveDetailsApiCallHandler = async () => {
    try {
      setLoading(true);
      const formattedSelectedRows = formatSelectedRows(memberList);
      const badgeId = badgeDetailsState.selectedBadge?.badgeId;
      const programId = badgeDetailsState.programId;
      const response = await ApiClient.put(
        badgesEndPoints.unassignBadgeUsers(programId),
        formattedSelectedRows
      );
      setUnassignedUserList(response?.response?.successRecords);
      let message =
        response.response.successRecords.length == 1
          ? `Selected member has been successfully unassigned the Badge ${badgeDetailsState.selectedBadge.name}`
          : `${response.response.successRecords.length} members have been successfully unassigned the Badge ${badgeDetailsState.selectedBadge.name}`;
      if (response.responseCode === 200) {
        toast.success(message);
        googleWebAnalytics("UnAssign_Badge_Member_Unassigned", "Button", "UnAssign_Badge");
      }
      // updateListTelemetry(response?.response?.successRecords);
      setLoading(false);
      onClose();
      getBadgeUsersList();
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong...!!!");
    }
  };

  const formatListForTelemetry = (list: any) => {
    let newRows: any = [];
    list.forEach((row: any) => {
      let rowObject: any = {};
      rowObject.batchAssignedId = row.badgeAssignedId;
      rowObject.badgeId = row.badge.badgeId;
      rowObject.eventType = "Badge UnAssigned";
      rowObject.userId = row.userId;
      rowObject.badgeName = row.badge.badgeName;
      newRows.push(rowObject);
    });
    return newRows;
  };

  const badgeReasonHandler = (row: any, reason: string, e: any) => {
    const userData: any[] = memberList.filter(
      (ele: any) => ele.listIndex === row.original.listIndex
    );
    userData[0].reason = reason.trim();
    row.values.reason = reason.trim();
    return row;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: 55,
        maxWidth: 55,
        disableSortBy: true,
        Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return cellData("name", style, badgeData?.name);
        }
      },
      {
        Header: "Base Location",
        accessor: "baseLocation",
        width: 65,
        maxWidth: 65,
        disableSortBy: true,
        Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(badgeData) !== "None" ? (
                <CustomTooltip
                  hoverValue={auxiliaryMethods.fromatBaseLocation(badgeData)}
                  textValue={auxiliaryMethods.getUserFirstLocationName(badgeData)}
                />
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        width: 50,
        maxWidth: 50,
        disableSortBy: true,
        Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
          const style = {};
          const startdate = badgeData?.startDate
            ? dataHelper.formatDate(badgeData?.startDate)
            : "NA";
          return cellData("startDate", style, startdate);
        }
      },
      {
        Header: "End Date",
        accessor: "endDate",
        width: 50,
        maxWidth: 50,
        disableSortBy: true,
        Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
          const style = {};
          const endDate = badgeData?.endDate ? dataHelper.formatDate(badgeData?.endDate) : "NA";
          return cellData("endDate", style, endDate);
        }
      },
      {
        Header: "Reason *",
        accessor: "reason",
        width: 150,
        maxWidth: 150,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <View>
              <TextField
                label=""
                handleChange={(reason: string, event: any) => {
                  badgeReasonHandler(row, reason, event);
                }}
                handleBlur={() => {
                  googleWebAnalytics("UnAssign_Badge_Modal_Reason", "Text_Field", "UnAssign_Badge");
                }}
                defaultValue={row.original.reason}
                value={reason}
                inputStyle={[styles.inputStylings, styles.inputStylingAlignment]}
                placeholder="Enter reason."
                name="reasonRow"
                id="textAreaId"
                multiline={true}
                numberOfLines={3}
                noFormik
                // editable={editable}
              />
            </View>
          );
        }
      },
      {
        Header: " ",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
          return (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginRight: 16
              }}
            >
              <View>
                <TouchableOpacity onPress={() => removeSelectedMember(badgeData)}>
                  <Image
                    testId="remove"
                    source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                    imageStyle={{
                      width: 18,
                      height: 18
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          );
        }
      }
    ],
    [memberList]
  );

  return (
    <View>
      <View style={styles.container}>
        <ToastContainer />
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Unassign Badge
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              onPress={() => {
                onClose();
                googleWebAnalytics("UnAssign_Badge_Close_Modal", "Button", "UnAssign_Badge");
              }}
            />
          </View>
        </View>
        <View style={styles.subContainer}>
          {loading ? (
            <Loader customLoadingContainer={styles.badgesModalLoader} />
          ) : (
            <View style={styles.tableContainerHeight}>
              <ScrollContainer height="650px" scrollable={memberList?.length > 2 ? true : false}>
                <Table
                  columns={columns}
                  data={memberList}
                  showSearchField={false}
                  isPaginationReqdForTable={false}
                  showPaginationField={false}
                  showSaveBadgeButton={true}
                  saveSelectedBadgeDetailsHandler={saveSelectedBadgeDetailsHandler}
                  isConflictPresentInList={false}
                  showHeadersOnly={showHeadersOnly}
                  eventAnalytics={"BadgeMembers"}
                  // isConflictPresentInList={!isRemarkPresent}
                />
              </ScrollContainer>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 920
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 25,
    paddingBottom: 0
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginTop: 15
  },
  alignContainer: {
    alignItems: "center",
    marginTop: 25,
    marginBottom: 0
  },
  submitbutton: {
    width: "167px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  inputStylingAlignment: {
    width: "94%"
  },
  inputStylings: {
    height: 35,
    borderRadius: 5,
    padding: 5,
    fontSize: 12,
    fontWeight: FontWeight.Light,
    fontFamily: FontFamily.Medium,
    width: "100%"
  },
  textHeaderField: {
    flex: 25
  },
  badgesModalLoader: {
    width: 920,
    height: 400
  },
  tableContainerHeight: {
    maxHeight: 470
  }
});
