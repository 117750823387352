import React, { useCallback, useEffect, useRef, useState } from "react";
import { Pressable, StyleSheet, TouchableOpacity, View } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import FilePicker from "@socion-cordio/common/src/components/molecules/filePicker";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { DocHelper } from "@socion-cordio/common/src/utils/registryHelpers/docHelper";
import { capitalize } from "lodash";

interface Props {
  onClose: Function;
  imgSrc?: string;
  handleCustomChange?: Function;
  handleChange?: Function;
  setLogoPreview?: Function;
  type?: string;
}

export default function ImageChangeModal(props: Props) {
  const [loading, setLoading] = useState(false);
  const [imageAdded, setImageAdded] = useState(false);
  const { onClose, imgSrc } = props;
  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 8 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const removePhoto = async () => {
    setLogoPreview("");
    props.setLogoPreview("");
    props.handleChange("", "logo", "title");
  };

  const handleClick = (event: any) => {
    // setImageAdded(false)
    hiddenFileInput.current.click();
  };

  const handleCustomChange = async (e: any) => {
    let fileNamesArray: any = [];
    if (fileNamesArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["png", "jpg", "jpeg", "svg"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setLoading(true);
      const { file, url } = await DocHelper.getCompressedFile(e?.target?.files[0]);
      props.setLogoPreview(url);
      setLogoPreview(url);
      props.handleChange(url, "logo", "title");
      setLoading(false);
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  return (
    <>
      {!loading && (
        <View>
          {/* <ToastContainer /> */}
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addEmailText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {`Add ${capitalize(props.type)} Logo`}
            </Text>
            <View>
              <Pressable onPress={() => onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>

          {!imageAdded && imgSrc == "" && (
            <View style={styles.uploadPictureContainer}>
              <View style={styles.uploadPicture}>
                <View style={styles.uploadPictureText}>
                  <FilePicker
                    acceptedFormat=".png,.jpg,.jpeg"
                    onChange={async (e) => {
                      await handleCustomChange(e);
                    }}
                    contentText={`Upload your profile picture`}
                    profileUpload={true}
                  />
                  <View>
                    <Icon
                      testID="upload"
                      name={IconNames.uploadProfile}
                      customStyle={styles.customUpload}
                      onPress={handleClick}
                    />
                    <input
                      style={{
                        display: "none",
                        zIndex: -1
                      }}
                      ref={hiddenFileInput}
                      type="file"
                      onChange={async (e) => {
                        // setImageAdded(true)
                        await handleCustomChange(e);
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
          )}
          {!imageAdded && imgSrc !== "" && (
            <>
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
                {imgSrc && (
                  <View style={{ maxHeight: "500px", maxWidth: "500px" }}>
                    <Image
                      testId="socionProfileImg"
                      imageStyle={styles.socionProfileImg}
                      source={{ uri: imgSrc }}
                    />
                  </View>
                )}{" "}
                {!imgSrc && (
                  <View>
                    {props.type === "entity" && (
                      <Icon
                        name={IconNames.entity}
                        color={"black"}
                        customStyle={{ fontSize: 70 }}
                      />
                    )}
                    {props.type === "program" && (
                      <Icon
                        name={IconNames.program}
                        color={"black"}
                        customStyle={{ fontSize: 70 }}
                      />
                    )}
                  </View>
                )}
                <View style={styles.buttonContainer2}>
                  <View>
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.button}
                      title="Change image"
                      onPress={handleClick}
                    />
                    <input
                      style={{
                        display: "none",
                        zIndex: -1
                      }}
                      ref={hiddenFileInput}
                      type="file"
                      onChange={async (e) => {
                        await handleCustomChange(e);
                      }}
                    />
                  </View>
                  <View>
                    <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.savebutton}
                      title="Delete image"
                      onPress={() => removePhoto()}
                    />
                  </View>
                </View>
              </View>
            </>
          )}

          {imageAdded && (
            <>
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
                <View style={{ maxHeight: "500px", maxWidth: "500px" }}>
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c: any) => setCrop(c)}
                    onComplete={(c: any) => setCompletedCrop(c)}
                    keepSelection={true}
                  />
                </View>
                <View style={{ opacity: 0, height: 0 }}>
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0)
                    }}
                  />
                </View>
                <View style={styles.buttonContainer}>
                  <View>
                    {/* <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.savebutton}
                      title="Delete"
                      onPress={() => removePhoto()}
                    /> */}
                  </View>

                  <View>
                    <Button
                      type={ButtonType.Secondary}
                      buttonStyles={styles.button}
                      title="Change image"
                      // onPress={() => }
                      onPress={handleClick}
                    />
                    <input
                      style={{
                        display: "none",
                        zIndex: -1
                      }}
                      ref={hiddenFileInput}
                      type="file"
                      onChange={async (e) => {
                        await handleCustomChange(e);
                      }}
                    />
                  </View>
                  <View>
                    {/* <Button
                      type={ButtonType.Primary}
                      buttonStyles={styles.savebutton}
                      title="Save"
                      onPress={() => savePhoto(previewCanvasRef.current, completedCrop)}
                    /> */}
                  </View>
                </View>
              </View>
            </>
          )}
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  uploadPictureContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "1rem"
  },
  uploadPicture: {
    width: "450px",
    height: "300px",
    backgroundColor: "#F8F8F8",
    borderRadius: 5
  },
  uploadPictureText: {
    margin: "auto",
    color: colorPallete.textBlack,
    fontSize: 14,
    opacity: 0.5
  },
  customUpload: {
    alignSelf: "center",
    // marginTop: 10,
    opacity: 0.8
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 20,
    marginRight: 20
  },
  savebutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: "auto"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem"
  },
  socionProfileImg: {
    width: 260,
    height: 260
  },
  buttonContainer2: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    marginRight: "20px"
  }
});
