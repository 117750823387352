import App from "./App";
import "./index.css";
import "@socion-cordio/common/src/assets/fonts/fonts";
import React from "react";
import ReactDOM from "react-dom";
// import firebase from "@socion-cordio/web/src/utils/firebaseConfig"
// import { analytics } from "./utils/firebaseConfig";
// import { getAnalytics, logEvent } from "firebase/analytics";
// import firebase from 'firebase/analytics'

// getAnalytics(analytics);
// // logEvent(analytics, 'notification_received');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

ReactDOM.render(
  <React.Suspense fallback="Loading...">
    <App />
  </React.Suspense>,
  document.getElementById("root")
);
