// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/extensions,import/no-unresolved
import { Storage } from "./index";
class StorageService {
  getStorage = async <T>(key: string): Promise<T | null> => {
    const value: string | null = await Storage.getItem(key);
    if (value) return JSON.parse(value);
    else return null;
  };

  async setStorage(key: string, value: any) {
    return await Storage.setItem(key, JSON.stringify(value));
  }

  clearStorage() {
    return Storage.clear();
  }

  removeStorage(key: string) {
    return Storage.removeItem(key);
  }

  removeStoredKeys() {
    Storage.removeItem("isSuperAdmin");
    Storage.removeItem("accessToken");
    Storage.removeItem("isSidePanelCollapsed");
    Storage.removeItem("user");
    Storage.removeItem("lang");
    Storage.removeItem("userRoles");
    Storage.removeItem("updatedProfileInfo");
    Storage.removeItem("isSuperAdmin");
    Storage.removeItem("programDetails");
    Storage.removeItem("isEntityDeleted");
    Storage.removeItem("noneLocationListSet");
    Storage.removeItem("noAssignedBadgesSelected");
    Storage.removeItem("programTabsOpen");
    Storage.removeItem("qpfilterList");
    Storage.removeItem("isBadgeClearAllClicked");
    Storage.removeItem("selectedEntity");
    Storage.removeItem("selectedEntityProgram");
  }
}
const storage = new StorageService();
export { storage as LocalStorage };
