import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import _ from "lodash";
import { TABS, STATUS } from "@socion-cordio/common/src/constants/registry";
import {
  dispatchEntityAdmins,
  getSelectedEntityDetails
} from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";
import AddAdminModal from "@socion-cordio/common/src/components/organisms/registry/entity/addAdminModal";
import RemoveAdminModal from "@socion-cordio/common/src/components/organisms/registry/entity/removeAdminModal";

import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { AdminsRepository } from "@socion-cordio/common/src/repositories/registry/entity/admins";
import { MESSAGES } from "@socion-cordio/common/src/constants/message";
import { toast } from "react-toastify";

const Administration = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateAdminModal, setShowCreateAdminModal] = useState(false);
  const [showRemoveAdminModal, setShowRemoveAdminModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [showList, setShowList] = useState(null);
  const [entity, setEntity] = useState(null);
  const [adminsList, setAdminsList] = useState(null);
  const dispatch = useDispatch();
  let isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));

  const {
    admins: admins,
    loading: { getAdmins: loading },
    error: { getAdmins: error }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    setIsLoading(true);
    dispatchEntityAdmins(dispatch);
    getSelectedEntityData();
  }, []);

  const getSelectedEntityData = async () => {
    const selectedEntity = await getSelectedEntityDetails();
    setEntity(selectedEntity);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setShowList(false);
      setAdminsList([]);
    }
  }, [error]);

  useEffect(() => {
    if (admins !== null) {
      setShowList(admins.length > 0);
      sortAdmins(admins);
      setIsLoading(false);
    }
  }, [admins]);

  const sortAdmins = (admins: any[]) => {
    const sorted: any = admins.sort((a: any, b: any) => {
      if (a.active && !b.active) {
        return -1;
      }
      // If a is inactive and b is active, b should come first.
      else if (!a.active && b.active) {
        return 1;
      }
      // If both are active or both are inactive, maintain the original order.
      return 0;
      // if (a.active === b.active) {
      //   // If both users have the same 'active' status, sort by 'created' in descending order.
      //   return new Date(b.created) - new Date(a.created);
      // } else {
      //   // Sort by 'active' status with active users first.
      //   return a.active ? -1 : 1;
      // }
    });

    setAdminsList(sorted);
  };

  const toggleActivate = async (admin: any, activate?: boolean) => {
    setShowRemoveAdminModal(false);

    const payload = {
      userName: admin?.name,
      entityId: entity?.osid,
      entityName: entity?.name,
      updatedBy: UserHelper.getUserName(),
      updatedById: UserHelper.getUserId()
    };

    const res = await AdminsRepository.toggleEntityAdmin(payload, admin?.osid, !activate).catch(
      (err) => {
        const msg = !activate ? MESSAGES.ERROR.DEACTIVATE : MESSAGES.ERROR.ACTIVATE;
        toast.error(
          dataHelper.replaceText(msg, {
            ["{type}"]: "Entity Admin",
            ["{name}"]: selectedUser?.name
          })
        );
        console.log("Error", error);
      }
    );
    if (res) {
      let data = [...adminsList];
      data = data.map((pgm) => {
        if (pgm?.userId === admin?.userId) {
          pgm.active = !activate;
        }
        return pgm;
      });
      sortAdmins(data);
      // setAdminsList(data);
      const msg = activate ? MESSAGES.SUCCESS.DEACTIVATE : MESSAGES.SUCCESS.ACTIVATE;
      toast.success(
        dataHelper.replaceText(msg, {
          ["{type}"]: "Entity Admin",
          ["{name}"]: selectedUser?.name
        })
      );
    }
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          textSize: TextSize.Small,
          fontWeight: FontFamily.Light
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const canAddOpacity = (data: any) => {
    if (
      // (data.userId === UserHelper.getUserId() || data?.adminId === UserHelper.getUserId()) ||
      data?.adminId !== UserHelper.getUserId() &&
      !data.active
    ) {
      return true;
    }
    return false;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 40,
        maxWidth: 40,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (canAddOpacity(data)) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          return cellData("serialNo", style, data.serialNo);
        }
      },
      {
        Header: "Name",
        accessor: "name",
        width: 200,
        maxWidth: 200,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (canAddOpacity(data)) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          return cellData("name", style, data?.name);
        }
      },
      {
        Header: "Email",
        accessor: "emailId",
        width: 200,
        maxWidth: 200,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (canAddOpacity(data)) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          return cellData("emailId", style, data?.emailId || "NA");
        }
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        width: 200,
        maxWidth: 200,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (canAddOpacity(data)) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          return cellData("phoneNumber", style, data?.phoneNumber || "NA");
        }
      },
      {
        Header: "Location",
        accessor: "location",
        width: 200,
        maxWidth: 200,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (canAddOpacity(data)) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }

          const location = data?.location.replaceAll(",", ", ");

          return cellData("location", style, location);
        }
      },
      {
        Header: "Add/Remove",
        accessor: "active",
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          return (
            <View>
              {!isSuperAdmin &&
                entity?.active &&
                data.userId !== entity.userId &&
                data?.adminId !== entity.userId && (
                  <>
                    {!data?.active && (
                      <TouchableOpacity
                        style={{ alignItems: "center", opacity: 0.6 }}
                        onPress={() => {
                          setSelectedUser(data);
                          setStatus("activate");
                          setShowRemoveAdminModal(!showRemoveAdminModal);
                        }}
                      >
                        <Image
                          testId="secondaryAddImg"
                          source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                          imageStyle={{
                            width: 20,
                            height: 20
                          }}
                        />
                      </TouchableOpacity>
                    )}
                    {data?.active && (
                      <TouchableOpacity
                        style={{ alignItems: "center" }}
                        onPress={() => {
                          setStatus("deactivate");
                          setSelectedUser(data);
                          setShowRemoveAdminModal(!showRemoveAdminModal);
                        }}
                      >
                        <Image
                          testId="remove"
                          source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                          imageStyle={{
                            width: 20,
                            height: 20
                          }}
                        />
                      </TouchableOpacity>
                    )}
                  </>
                )}
            </View>
          );
        }
      }
    ],
    [entity]
  );

  const getCreateAdminModal = () => {
    return (
      <View>
        <SocionModal
          modalVisible={showCreateAdminModal}
          setModalVisible={() => setShowCreateAdminModal(!showCreateAdminModal)}
          component={
            <AddAdminModal
              payload={entity ? { entityId: entity?.osid } : {}}
              onClose={() => {
                setShowCreateAdminModal(false);
              }}
              onSuccess={() => {
                setShowCreateAdminModal(false);
                dispatchEntityAdmins(dispatch);
              }}
            />
          }
        />
      </View>
    );
  };

  const getRemoveAdminModal = () => {
    console.log("selectedUserselectedUser", selectedUser);

    return (
      <View>
        <SocionModal
          modalVisible={showRemoveAdminModal}
          setModalVisible={() => setShowRemoveAdminModal(!showRemoveAdminModal)}
          component={
            <RemoveAdminModal
              userDetails={selectedUser}
              onClose={() => {
                setShowRemoveAdminModal(false);
              }}
              status={status}
              toggleActivate={(user: any) => {
                toggleActivate(user, user?.active);
              }}
            />
          }
        />
      </View>
    );
  };

  const getPageTitle = () => {
    return (
      <View>
        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText]}
        >
          Entity Administrators
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <View style={styles.dataContainer}>
              {getPageTitle()}
              {showList !== null && !showList && (
                <>
                  <View style={styles.search}>
                    <View style={styles.searchContainer}>
                      <SearchFilterSeondary
                        // customWidthStyle={styles.searchContainer}
                        editable={true}
                        placeholder={"Search"}
                      />
                    </View>
                    {!isSuperAdmin &&
                      entity?.active &&
                      (console.log("fdbsmnfbsd", entity),
                      (
                        <TouchableOpacity
                          onPress={() => setShowCreateAdminModal(!showCreateAdminModal)}
                        >
                          <Image
                            testId="primaryAddImg"
                            source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                            imageStyle={{
                              width: 30,
                              height: 30
                            }}
                          />
                        </TouchableOpacity>
                      ))}
                  </View>
                  <View>
                    <Text
                      fontWeight={FontWeight.Bold}
                      testId="headerText"
                      textSize={TextSize.Small}
                      textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                    >
                      No Admins Available
                    </Text>
                  </View>
                </>
              )}
              {showList && (
                <View>
                  {console.log("adminsListadminsList", entity)}
                  <Table
                    columns={columns}
                    data={adminsList}
                    showSearchField={true}
                    customSearchBarContainerStyle={{ marginLeft: -20 }}
                    paginationAndSearch={true}
                    addIcon={
                      !isSuperAdmin &&
                      entity?.active && (
                        <TouchableOpacity
                          onPress={() => setShowCreateAdminModal(!showCreateAdminModal)}
                        >
                          <Image
                            testId="primaryAddImg"
                            source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                            imageStyle={{
                              width: 30,
                              height: 30
                            }}
                          />
                        </TouchableOpacity>
                      )
                    }
                  />
                </View>
              )}
            </View>

            {showCreateAdminModal && getCreateAdminModal()}
            {showRemoveAdminModal && getRemoveAdminModal()}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    padding: 20,
    paddingTop: 18,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    padding: 18,
    borderRadius: 10,
    backgroundColor: colorPallete.white,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },

  dataContainer: {
    padding: 18,
    marginBottom: 180
  },
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 25,
    width: "100%"
  },
  searchContainer: {
    width: 535
    //
  },

  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  }
});

export default Administration;
