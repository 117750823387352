import { call, put, takeLatest } from "redux-saga/effects";
import { ENTITIES } from "@socion-cordio/common/src/modules/registry/entity/actions/actionTypes";
import { EntityActions } from "@socion-cordio/common/src/modules/registry/entity/actions/actions";
import { EntityRepository } from "@socion-cordio/common/src/repositories/registry/entity/entity";
import { DocsRepository } from "@socion-cordio/common/src/repositories/registry/docs/docs";
import { AdminsRepository } from "@socion-cordio/common/src/repositories/registry/entity/admins";
import { ProgramsRepository } from "@socion-cordio/common/src/repositories/registry/entity/programs";
import { PartnersRepository } from "@socion-cordio/common/src/repositories/registry/entity/partners";

import { Entity } from "@socion-cordio/common/src/models/registry/entity";
import { IFluxStandardAction } from "store/interfaces";

function* getEntitiesWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Entity = yield call(EntityRepository.getEntities, payload);
    yield put(EntityActions.getEntitiesSuccess(response));
  } catch (error) {
    yield put(EntityActions.getEntitiesError(error));
  }
}

function* getAllEntitiesWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Entity = yield call(EntityRepository.getAllEntities, payload);
    yield put(EntityActions.getAllEntitiesSuccess(response));
  } catch (error) {
    yield put(EntityActions.getAllEntitiesError(error));
  }
}

function* getEntityDocsWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Entity = yield call(DocsRepository.listDocs, payload);
    yield put(EntityActions.getEntityDocsSuccess(response));
  } catch (error) {
    yield put(EntityActions.getEntityDocsError(error));
  }
}

function* getEntityWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Entity = yield call(EntityRepository.readEntity, payload);
    yield put(EntityActions.getEntitySuccess(response));
  } catch (error) {
    yield put(EntityActions.getEntityError(error));
  }
}

function* getEntityAdminsWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Entity = yield call(AdminsRepository.getAdminsList, payload);
    yield put(EntityActions.getEntityAdminsSuccess(response));
  } catch (error) {
    yield put(EntityActions.getEntityAdminsError(error));
  }
}

function* getEntityProgramsWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Entity = yield call(ProgramsRepository.getEntityProgramsList, payload);
    yield put(EntityActions.getEntityProgramsSuccess(response));
  } catch (error) {
    yield put(EntityActions.getEntityProgramsError(error));
  }
}

function* getEntityProgramPartnersWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Entity = yield call(PartnersRepository.getEntityProgramPartnersList, payload);
    yield put(EntityActions.getEntityProgramPartnersSuccess(response));
  } catch (error) {
    yield put(EntityActions.getEntityProgramPartnersError(error));
  }
}

function* getEntityPartnersWorkerSaga(value: IFluxStandardAction<any>) {
  try {
    const { payload } = value;
    const response: Entity = yield call(PartnersRepository.getEntityPartnersList, payload);
    yield put(EntityActions.getEntityPartnersSuccess(response));
  } catch (error) {
    yield put(EntityActions.getEntityPartnersError(error));
  }
}

export default function* EntityWatcherSaga() {
  yield takeLatest(ENTITIES.GET_ENTITIES.LOADING, getEntitiesWorkerSaga);
  yield takeLatest(ENTITIES.GET_ALL_ENTITIES.LOADING, getAllEntitiesWorkerSaga);
  yield takeLatest(ENTITIES.GET_ENTITY_DOCS.LOADING, getEntityDocsWorkerSaga);
  yield takeLatest(ENTITIES.GET_ENTITY.LOADING, getEntityWorkerSaga);
  yield takeLatest(ENTITIES.GET_ENTITY_ADMINS.LOADING, getEntityAdminsWorkerSaga);
  yield takeLatest(ENTITIES.GET_ENTITY_PROGRAMS.LOADING, getEntityProgramsWorkerSaga);
  yield takeLatest(
    ENTITIES.GET_ENTITY_PROGRAM_PARTNERS.LOADING,
    getEntityProgramPartnersWorkerSaga
  );
  yield takeLatest(ENTITIES.GET_ENTITY_PARTNERS.LOADING, getEntityPartnersWorkerSaga);
}
