import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement } from "react";
import { View, StyleSheet } from "react-native";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";

export default function Tasks(): ReactElement {
  return (
    <View style={styles.container}>
      <CollapseItem
        headerStyles={[styles.collapseHeaderContainer]}
        isExpanded={false}
        headerComponent={"Tasks"}
        children={
          <View style={styles.textContainer}>
            A task (interaction record, survey, data collection etc.) is active for this session
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  collapseHeaderContainer: {},
  textContainer: {
    paddingLeft: 16,
    paddingBottom: 5,
    fontFamily: FontFamily.Regular,
    fontSize: 12
  }
});
