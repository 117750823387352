import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import AddMobile from "@socion-cordio/common/src/components/organisms/addMobile";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import { useSelector } from "react-redux";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import AddEmail from "@socion-cordio/common/src/components/organisms/addEmail";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";

interface Props {
  onClose: Function;
  showEmail?: boolean;
  modalTitle: string;
  headerText: string;
  setPhoneNumberPopup?: boolean;
}
export default function PasswordInput(props: Props): ReactElement {
  const [passwordHandler, setPasswordHandler] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState(null);
  const [countryCodeObj, setCountryCodeObj] = useState({});

  const profileState = useSelector((state: any) => state.profile);
  const {
    userProfileData: { response }
  } = profileState;

  useEffect(() => {
    async function setCountryCodeObject() {
      let countryCodeObject: any = await LocalStorage.getStorage("countryCodeObj");
      setCountryCodeObj(countryCodeObject);
    }
    setCountryCodeObject();
  }, []);

  const onSubmitForm = async () => {
    const aesUtil: AesUtil = new AesUtil();
    let IsEmailVerification = await LocalStorage.getStorage("IsEmailVerification");
    let payload: any = null;

    if (IsEmailVerification) {
      payload = {
        password: aesUtil.encrypt(password),
        userName: response.emailId ? response.emailId : response.phoneNumber,
        countryCode: response.countryCode
      };
    } else {
      payload = {
        password: aesUtil.encrypt(password),
        // when no phone number
        userName: response.phoneNumber ? response.phoneNumber : response.emailId,
        countryCode: response.countryCode
      };
    }

    const loginData = await ApiClient.post(loginEndPoints.postCredentials(), payload);
    if (loginData.responseCode === HTTP_STATUS_CODES.ok) {
      setPasswordHandler(true);
    } else {
      setLoginErrorMsg(loginData?.message);
    }
  };

  const handlePassword = (password: string) => {
    if (password) {
      setError(false);
    } else {
      setError(true);
    }
    setPassword(password);
    setLoginErrorMsg(null);
  };

  return (
    <View>
      {!passwordHandler && (
        <View>
          <View style={styles.header}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {props.modalTitle}
            </Text>
            <View>
              <Pressable onPress={() => props.onClose()}>
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View style={styles.subContainerTwo}>
            <View style={styles.titleContainer}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleTitleText"
                textSize={TextSize.Small}
                textStyle={styles.subheaderText}
              >
                {props.headerText}
              </Text>
            </View>
            <View style={styles.passwordInputContainer}>
              <UserInput
                isSecure
                label=""
                handleChange={(password: string) => {
                  handlePassword(password);
                }}
                handleBlur={() => {}}
                input={styles.inputRole}
                name="password"
                placeholder="Enter Password"
                value={password}
                id="password"
                noFormik
              />
            </View>
            <View style={styles.requiredMessageContainer}>
              {error && (
                <Text
                  testId="emailError"
                  textSize={TextSize.Small}
                  fontWeight={FontWeight.Regular}
                  textStyle={styles.error}
                >
                  Please enter password
                </Text>
              )}
              {!error && loginErrorMsg && (
                <Text
                  testId="incorrectEmail"
                  textSize={TextSize.Small}
                  fontWeight={FontWeight.Regular}
                  textStyle={styles.error}
                >
                  {loginErrorMsg}
                </Text>
              )}
            </View>
            <View style={[styles.submitButtonContainer, styles.alignSubmitButton]}>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.submitbutton}
                title="Continue"
                onPress={onSubmitForm}
                disabled={error || !password}
              />
            </View>
          </View>
        </View>
      )}
      {passwordHandler &&
        (props.setPhoneNumberPopup ? (
          <AddMobile onClose={props.onClose} countryCodeObj={countryCodeObj} />
        ) : (
          <AddEmail onClose={props.onClose} isChangeEmailClicked={true} />
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainerTwo: {
    marginTop: 15
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 100,
    textAlign: "center"
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 23,
    flex: 20
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  alignSubmitButton: {
    marginTop: 50
  },
  submitbutton: {
    width: "175px",
    height: "50px",
    borderRadius: 10
  },
  passwordInputContainer: {
    alignItems: "center"
  },
  inputRole: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: "330px"
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  requiredMessageContainer: {
    alignSelf: "center"
  }
});
