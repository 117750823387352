import profileRouter from "modules/profile/router/profileRouter";
import SpringshedRouter from "modules/springshed/router/springshedRouter";
import WorkspaceRouter from "modules/workspace/router/workspaceRouter";
import React, { ReactElement, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { getAllowedRoutes } from "utils/routeHelper";
import { allRoutesNames as routeNames } from "./allRouteNames";
import MapRoutes from "./mapRoutes";
import { Roles } from "@socion-cordio/common/src/constants/roles";
import ProfileNavbar from "@socion-cordio/common/src/components/organisms/profileNavbar";
import SpringshedNavbar from "@socion-cordio/common/src/components/organisms/springshedNavbar";
import Header from "@socion-cordio/common/src/components/organisms/topNavbar";
import ProgramDropdown from "@socion-cordio/common/src/components/organisms/programDropdown";
import EntityDropdown from "@socion-cordio/common/src/components/organisms/registry/entity/entityDropdown";

import { ToastContainer } from "react-toastify";
import { TouchableWithoutFeedback } from "react-native";
import Message from "@socion-cordio/common/src/components/atoms/message";

import Accomplishment from "@socion-cordio/common/src/components/organisms/accomplishment/accomplishment";
import QualificationPack from "@socion-cordio/common/src/components/organisms/accomplishment/qualificationPack";
import ATTENDANCE from "@socion-cordio/common/src/components/organisms/accomplishment/components/attendance";
import Tasks from "@socion-cordio/common/src/components/organisms/accomplishment/tasks";
import TaskDetails from "@socion-cordio/common/src/components/organisms/accomplishment/taskDetails";
import Artefact from "@socion-cordio/common/src/components/organisms/accomplishment/artefact";
import ArtefactDetails from "@socion-cordio/common/src/components/organisms/accomplishment/artefactDetails";
import AdditionalProfileInfo from "@socion-cordio/common/src/components/organisms/badges/mobile-badge-class/additionalProfileInfo";
import { useParams } from "react-router-dom";
import DisabledLoader from "@socion-cordio/common/src/components/atoms/disabledLoader";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { ProgramRepository } from "@socion-cordio/common/src/repositories/program/program";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { toast } from "react-toastify";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { useDispatch, useSelector } from "react-redux";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { StoreProviderService } from "@socion-cordio/common/src/services/StoreProviderService";
import { ProgramActions } from "@socion-cordio/common/src/modules/program/actions/actions";
import "navigation/privateRouter.scss";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { programEndPoints, topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import moment from "moment";
interface IProps {}

export interface IUserDetails {
  id: number;
  name: string;
  roles: Roles[];
  storeId: number;
}

const ERROR_TYPES = {
  ERROR: "error",
  WARNING: "warning"
};

export default function RegistryPrivateRouter(props: IProps): ReactElement {
  const allPrivateRoutes = [...profileRouter(), ...SpringshedRouter(), ...WorkspaceRouter()];
  // const allowedRoutes = getAllowedRoutes(allPrivateRoutes);
  const [isOpen, setIsOpen] = useState(null);
  const [widthCollapse, setWidthCollapse] = useState(undefined);
  const [entityName, setEntityName] = useState(null);
  const [open, setOpen] = useState(false);
  const [usersId, setUsersId] = useState(null);
  const [programId, setProgramId] = useState(null);
  const [isMainLoaderLoading, setIsMainLoaderLoading] = useState(false);
  const [programDataState, setProgramDataState] = useState<any>();
  const [displayErrorBanner, setDisplayErrorBanner] = useState({
    programExpiryAlertStatus: null,
    licenseExpiryAlertStatus: null,
    licenseExpiryDate: null,
    licensingOption: "N/A"
  });
  const programsState = useSelector((state: any) => state.programs);
  const {
    userRolesData: userRolesData,
    loading: { getUserRolesProgram: rolesLoader }
  } = useSelector((state: any) => state?.userRolesProgram);

  useEffect(() => {
    window.location.pathname == `${routeNames.app}${routeNames.workspace}` ||
    window.location.pathname == `${routeNames.app}${routeNames.ACCOMPLISHMENT}`
      ? [setIsOpen(false), setEntityName(null)]
      : setIsOpen(true);

    if (
      window.location.pathname !== `${routeNames.app}${routeNames.workspace}` &&
      window.location.pathname !== `${routeNames.app}${routeNames.ACCOMPLISHMENT}`
    ) {
      checkLocalStorageValueHandler();
    } else {
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (/profile|workspace|accomplishment/.test(window.location.pathname)) {
      StoreProviderService.dispatch(ProgramActions.getSelectedProgramData(null));
    } else {
      getProgramStatusInfo();
    }
  }, []);

  useEffect(() => {
    if (/profile|workspace|accomplishment/.test(window.location.pathname)) {
      // StoreProviderService.dispatch(ProgramActions.getSelectedProgramData(null));
    } else {
      getProgramStatusInfo();
    }
  }, [window.location.pathname, userRolesData]);

  useEffect(() => {
    if (/profile|workspace|accomplishment/.test(window.location.pathname)) {
      setDisplayErrorBanner({
        programExpiryAlertStatus: null,
        licenseExpiryAlertStatus: null,
        licenseExpiryDate: null,
        licensingOption: "N/A"
      });
    } else {
      errorWarningBannerHandler();
    }
  }, [programsState, window.location.pathname]);

  const getProgramStatusInfo = async () => {
    const { selectedProgramDetails } = programsState;
    const currentProgramDetails2: any = await LocalStorage.getStorage("programDetails");
    setProgramDataState(currentProgramDetails2);

    if (
      selectedProgramDetails &&
      window.location.pathname !== `${routeNames.app}${routeNames.SESSION}`
    ) {
      return;
    }
    let isSuperAdmin = await LocalStorage.getStorage("isSuperAdmin");
    let showBannerByRole =
      userRolesData?.response?.includes("PROGRAM_ADMIN") ||
      userRolesData?.response?.includes("ENTITY_ADMIN") ||
      isSuperAdmin;
    if (
      !showBannerByRole ||
      displayErrorBanner.programExpiryAlertStatus ||
      displayErrorBanner.licenseExpiryAlertStatus
    ) {
      return;
    }
    try {
      // const res = await ApiClient.get(
      //   programEndPoints.getProgramStatusEP(currentProgramDetails2?.program?.id)
      // );
      // const data = res?.response;
      // if (
      //   !res ||
      //   !(
      //     data?.programExpiryAlertStatus ||
      //     data?.licenseExpiryAlertStatus ||
      //     data?.blockSessionCreation
      //   )
      // ) {
      //   StoreProviderService.dispatch(ProgramActions.getSelectedProgramData(null));
      //   return;
      // }
      // StoreProviderService.dispatch(
      //   ProgramActions.getSelectedProgramData({
      //     programExpiryAlertStatus: data?.programExpiryAlertStatus,
      //     licenseExpiryAlertStatus: data?.licenseExpiryAlertStatus,
      //     isCreateSessionBlocked: data?.blockSessionCreation,
      //     licenseExpiryDate: data?.licenseDebitStartDate,
      //     licensingOption: data?.licensingOption || "N/A",
      //     status: data?.status || ""
      //   })
      // );
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const errorWarningBannerHandler = async () => {
    const { selectedProgramDetails } = programsState;
    if (programsState.selectedProgramDetails !== null) {
      setDisplayErrorBanner({ ...selectedProgramDetails });
    }
  };

  const cancelBannerHandler = async (bannerName: any) => {
    const { selectedProgramDetails } = programsState;
    if (bannerName === "licenseExpiry") {
      setDisplayErrorBanner({
        ...displayErrorBanner,
        licenseExpiryAlertStatus: null
      });
      StoreProviderService.dispatch(
        ProgramActions.getSelectedProgramData({
          ...selectedProgramDetails,
          licenseExpiryAlertStatus: null
        })
      );
    } else if (bannerName === "programExpiry") {
      setDisplayErrorBanner({
        ...displayErrorBanner,
        programExpiryAlertStatus: null
      });
      StoreProviderService.dispatch(
        ProgramActions.getSelectedProgramData({
          ...selectedProgramDetails,
          programExpiryAlertStatus: null
        })
      );
    } else {
      StoreProviderService.dispatch(ProgramActions.getSelectedProgramData(null));
    }
  };

  const getSidePanelWidth = (width: any, isExpanded: boolean) => {
    setWidthCollapse(width);
  };

  const callBackHandler = (data: any) => {
    setEntityName(data);
  };

  const checkLocalStorageValueHandler = async () => {
    const currentProgramDetails: any = await LocalStorage.getStorage("programDetails");
    if (currentProgramDetails !== null && !currentProgramDetails?.hasOwnProperty("entityData")) {
      setIsMainLoaderLoading(true);
      const entityId = currentProgramDetails?.program?.entity?.id;
      const programId = currentProgramDetails?.program?.id;
      const response = ProgramRepository.getPrograms();
      response
        .then((res) => {
          if (res.responseCode === HTTP_STATUS_CODES.ok) {
            const responseEntityList: any = res?.response?.filter(
              (element: any) => element.entity.id === entityId
            );
            const responseProgram = responseEntityList[0].programs?.filter(
              (element: any) => element.id === programId
            );
            const payload = { entityData: responseEntityList[0], program: responseProgram[0] };
            LocalStorage.setStorage("programDetails", payload);
            setIsMainLoaderLoading(false);
          } else {
            toast.error(res.message);
            setIsMainLoaderLoading(false);
          }
        })
        .catch((error) => {
          // toast.error("Something went wrong");
          setIsMainLoaderLoading(false);
        });
    }
  };

  const renderBanner = (bannerType: string) => {
    const isError =
      bannerType === "programExpiry"
        ? displayErrorBanner.programExpiryAlertStatus === "PROGRAM_EXPIRY_FINAL_TRIGGER" ||
          displayErrorBanner.programExpiryAlertStatus === "PROGRAM_EXPIRY_SECOND_TRIGGER"
        : displayErrorBanner.licenseExpiryAlertStatus === "LICENSE_EXPIRY_FINAL_TRIGGER" ||
          displayErrorBanner.licenseExpiryAlertStatus === "LICENSE_EXPIRY_SECOND_TRIGGER";

    let text1: string = "";

    const endDate =
      moment(programDataState?.program?.endDate).utc().format("DD-MM-YYYY") || "<Date>";
    if (bannerType === "programExpiry") {
      if (displayErrorBanner.programExpiryAlertStatus === "PROGRAM_EXPIRY_FINAL_TRIGGER") {
        text1 = `This program has ended on ${endDate} and is in Inactive status.If program wants to continue to use the PDA session services, please initiate the program extension process. Please ignore,if this is not relevant.`;
      } else if (displayErrorBanner.programExpiryAlertStatus === "PROGRAM_EXPIRY_SECOND_TRIGGER") {
        text1 = `This program is nearing its end date on ${endDate} (less than 1 month away!). If you need to continue to use the PDA services beyond this date, please initiate the program extension process. Please ignore,if this is not relevant.`;
      } else {
        text1 = `This program is nearing its end date on ${endDate} (less than 2 months away!). If you need to continue to use the PDA services beyond this date, please initiate the program extension process. Please ignore,if this is not relevant.`;
      }
    } else {
      if (displayErrorBanner.licenseExpiryAlertStatus === "LICENSE_EXPIRY_FINAL_TRIGGER") {
        text1 = `This program has utilized more than 100% of the allocated ${displayErrorBanner.licensingOption} licenses. Trainers are NOT permited to setup sessions for their trainings. If you would want to continue to use the PDA services, please plan for procurement of additional ${displayErrorBanner.licensingOption} licenses, as needed. `;
      } else if (displayErrorBanner.licenseExpiryAlertStatus === "LICENSE_EXPIRY_SECOND_TRIGGER") {
        text1 = `This program has utilized more than 100% of the allocated ${
          displayErrorBanner.licensingOption
        } licenses. Starting from ${
          moment(displayErrorBanner?.licenseExpiryDate).utc().format("DD-MM-YYYY") || "<Date>"
        } , Trainers will NOT be able to setup sessions for their trainings. To avoid any further interruption of services, please plan for procurement of additional ${
          displayErrorBanner.licensingOption
        } licenses, as needed. `;
      } else {
        text1 = `This  program has utilized more than 80% of the allocated ${displayErrorBanner.licensingOption} licenses. This is a notification to remind you of the need for any additional ${displayErrorBanner.licensingOption} licenses in the coming weeks/months. Please ignore if this is not relevant.`;
      }
    }
    return (
      <div className={`header-container ${isError ? "error-container" : "warning-container"}`}>
        <Icon
          testID="warning"
          name={IconNames.exclamationCircle}
          customStyle={{
            cursor: "default",
            fontSize: 16,
            color: isError ? colorPallete.white : colorPallete.textBlack
          }}
          onPress={null}
        />
        <p className={`bannerText ${isError ? "text-error" : "text-warning"}`}>{text1}</p>

        {!isError && (
          <div className={`header-cross-container`}>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={{ fontSize: 16 }}
              onPress={() => cancelBannerHandler(bannerType)}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <TouchableWithoutFeedback onPress={() => setOpen(false)}>
      {allPrivateRoutes?.some((route: { path: string }) => [
        window.location.pathname.includes(route.path)
      ]) ? (
        window.location.pathname.includes(
          `${routeNames.app}${routeNames.ACCOMPLESMENTARTEFACTDETAILS}`
        ) ? (
          <ArtefactDetails />
        ) : window.location.pathname.includes(`${routeNames.app}${routeNames.ACCOMPLISHMENTQP}`) ? (
          <QualificationPack />
        ) : window.location.pathname.includes(`${routeNames.app}${routeNames.ATTENDANCE}`) ? (
          <ATTENDANCE />
        ) : window.location.pathname.includes(
            `${routeNames.app}${routeNames.ACCOMPLISHMENTTASKSDETAILS}`
          ) ? (
          <TaskDetails />
        ) : window.location.pathname.includes(
            `${routeNames.app}${routeNames.ACCOMPLISHMENTTASKS}`
          ) ? (
          <Tasks />
        ) : window.location.pathname.includes(
            `${routeNames.app}${routeNames.ACCOMPLESMENTARTEFACT}`
          ) ? (
          <Artefact />
        ) : window.location.pathname.includes(`${routeNames.app}${routeNames.ACCOMPLISHMENT}`) ? (
          <Accomplishment />
        ) : window.location.pathname.includes(
            `${routeNames.app}${routeNames.ADDITIONALPROFILEINFO}`
          ) ? (
          <AdditionalProfileInfo />
        ) : (
          <div>
            <div>
              <Header currentEntityName={entityName} />
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                minHeight: "calc(100vh - 48px)", // To ajdust scroll bar, earlier 50 (50 -> 48)
                marginTop: 48, // To ajdust scroll bar, earlier 50 (50 -> 48)
                flexGrow: 1,
                overflow: "hidden"
              }}
            >
              {window.location.pathname == `${routeNames.app}${routeNames.workspace}` ||
              window.location.pathname.includes(`${routeNames.app}${routeNames.ATTENDANCE}`) ||
              window.location.pathname.includes(
                `${routeNames.app}${routeNames.ACCOMPLISHMENTTASKS}`
              ) ||
              window.location.pathname.includes(
                `${routeNames.app}${routeNames.ACCOMPLISHMENTTASKSDETAILS}`
              ) ||
              window.location.pathname.includes(
                `${routeNames.app}${routeNames.ACCOMPLESMENTARTEFACT}`
              ) ||
              window.location.pathname.includes(
                `${routeNames.app}${routeNames.ACCOMPLESMENTARTEFACTDETAILS}`
              ) ||
              window.location.pathname.includes(
                `${routeNames.app}${routeNames.ACCOMPLISHMENTQP}`
              ) ||
              window.location.pathname.includes(
                `${routeNames.app}${routeNames.ACCOMPLISHMENT}`
              ) ? null : (
                <div
                  style={
                    isOpen
                      ? {
                          // flex: 0.2,
                          width: 60,
                          position: "fixed",
                          zIndex: 1
                        }
                      : null
                  }
                >
                  {window.location.pathname.includes(routeNames.PROFILE) ? (
                    <ProfileNavbar getSidePanelWidth={getSidePanelWidth} />
                  ) : (
                    <SpringshedNavbar getSidePanelWidth={getSidePanelWidth} />
                  )}
                </div>
              )}
              <div
                style={
                  !isOpen
                    ? { width: "100%", flex: 1, backgroundColor: "#F8F5F0", flexGrow: 1 }
                    : {
                        marginLeft: widthCollapse,
                        width: "100%",
                        flex: 1,
                        backgroundColor: "#F8F5F0",
                        flexGrow: 1
                      }
                }
              >
                {displayErrorBanner.programExpiryAlertStatus && (
                  <>{renderBanner("programExpiry")}</>
                )}
                {displayErrorBanner.licenseExpiryAlertStatus && (
                  <>{renderBanner("licenseExpiry")}</>
                )}
                <ToastContainer />
                {!window.location.pathname.includes("/profile") && (
                  <div style={{ maxWidth: "75%" }}>
                    {isOpen && (
                      <EntityDropdown
                        isDropdownOpen={open}
                        setIsDropdownOpen={setOpen}
                        callbackFromParentHandler={callBackHandler}
                        isBanner={false}
                      />
                    )}
                  </div>
                )}
                {isMainLoaderLoading ? (
                  <>
                    <DisabledLoader />
                    <div
                      style={{
                        backgroundColor: colorPallete.white,
                        borderRadius: 10,
                        height: "calc(100vh - 140px)",
                        margin: 20,
                        marginTop: 18
                      }}
                    ></div>
                  </>
                ) : (
                  <>
                    <MapRoutes routes={allPrivateRoutes} basePath={routeNames.app} isAddNotFound />
                  </>
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <Route component={() => <div>Not Found</div>} />
      )}
    </TouchableWithoutFeedback>
  );
}
