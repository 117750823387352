import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { springshedEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import React, { ReactElement, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import AddSdgModal from "@socion-cordio/common/src/components/organisms/program/addSdgPopup";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";

interface IProps {
  programId?: string;
  programName?: string;
  parentId?: string;
  parentType?: string;
  programStatus?: Function;
  setLoading?: Function;
  selectedProgram?: any;
  sdgListByProgram?: any;
  sdgData?: any;
  selectedSdgs?: any;
  isSuperAdmin?: any;
  // getSdgByProgramId: Function;
  //apiResponse: any;
}

export default function SDGDetails(props: IProps): ReactElement {
  console.log("id---1", props.programId);
  const { programStatus, setLoading } = props;
  const [sdgListByProgram, setSdgListByProgram] = useState([]);
  const [addSdgModal, setAddSdgModal] = useState(false);
  const [sdgData, setsdgData] = useState([]);
  // const [sdgIdByProgram, setSdgIdByProgram] = useState([]);
  const [selectedSdgs, setSelectedSdgs] = useState([]);

  useEffect(() => {
    getSdgByProgramId();
  }, [props]);

  const updatedBy = UserHelper.getUserName();
  const updatedById = UserHelper.getUserId();
  const programId = props.programId;
  const ProgramName = props.programName;
  const parentId = props.parentId;
  const parentType = props.parentType;

  const getSdgByProgramId = async () => {
    try {
      const id = props.programId;
      const selectedProgram = props.selectedProgram;
      console.log("sdgidd", id);
      let sdgIdByProgram: any = [];
      const sdgResponse = selectedProgram?.ids || [];
      console.log("sdgResponse", sdgResponse);
      // const sdgResponse = await ApiClient.get(springshedEndPoints.getProgramsSdgList(id));
      const { response } = await ApiClient.get(springshedEndPoints.getSDGList());
      console.log("respon", response);
      if (response?.length > 0) {
        const filteredData = response.filter((obj: any) => sdgResponse.includes(obj.sdgId));

        console.log("filter", filteredData);
        setSdgListByProgram(sdgResponse);
        setsdgData(filteredData);
        sdgResponse.map((data: any) => {
          sdgIdByProgram.push(data);
        });
        let sanitisedSdgList = [...new Set(sdgIdByProgram)];
        // setSdgIdByProgram(sanitisedSdgList);
        setSelectedSdgs(sanitisedSdgList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (event: any) => {
    setAddSdgModal(true);
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text
          fontWeight={FontWeight.Light}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={styles.headerText}
        >
          Select SDG
        </Text>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {!props.isSuperAdmin && selectedSdgs.length !== 0 && (
            <TouchableOpacity onPress={handleClick}>
              <Icon testID="editSdg" name={IconNames.edit} customStyle={styles.editSdg} />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <View style={styles.listContainer}>
        {sdgListByProgram?.length !== 0 ? (
          <View>
            <View style={styles.sdgSubHeader}>
              <Text
                fontWeight={FontWeight.Light}
                testId="headerText"
                textSize={TextSize.Small}
                textStyle={styles.secondaryHeaderText}
              >
                Below are the selected SDGs
              </Text>
            </View>
            <View style={styles.sdgSelectedImagesContainer}>
              {sdgData.map((value: any) => (
                <View style={styles.sdgImages}>
                  <Image
                    testId="normImg"
                    imageStyle={{ width: 80, height: 80, borderRadius: 5 }}
                    source={{
                      uri: value.imageUrl
                    }}
                  />
                </View>
              ))}
            </View>
          </View>
        ) : (
          <View>
            <View style={styles.sdgSubHeader}>
              <Text
                fontWeight={FontWeight.Light}
                testId="headerText"
                textSize={TextSize.Small}
                textStyle={styles.secondaryHeaderText}
              >
                To add relevant SDGs, click on the below button
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity onPress={handleClick}>
                <Image
                  testId="primaryAddImg"
                  source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                  imageStyle={{
                    width: 28,
                    height: 28
                  }}
                />
              </TouchableOpacity>
              <Text
                fontWeight={FontWeight.Light}
                testId="headerText"
                textSize={TextSize.Small}
                textStyle={styles.buttonText}
              >
                Add SDG
              </Text>
            </View>
          </View>
        )}
        {addSdgModal && (
          <AddSdgModal
            modalVisible={true}
            setModalVisible={() => [setAddSdgModal(!addSdgModal), getSdgByProgramId()]}
            programId={programId}
            parentId={parentId}
            parentType={parentType}
            updatedBy={updatedBy}
            updatedById={updatedById}
            programName={ProgramName}
            // apiResponse={apiResponse}
            selectedProgram={props.selectedProgram}
            getSdgByProgramId={getSdgByProgramId}
            selectedSdgs={selectedSdgs}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: "rgba(103, 88, 80,0.1)",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    padding: 15
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  secondaryHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  buttonText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    display: "flex",
    alignItems: "center",
    marginLeft: 12
    // textDecorationLine: "underline"
  },
  listContainer: {
    marginTop: 20
  },
  sdgSubHeader: {
    marginBottom: 24.5
  },
  sdgSelectedImagesContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  sdgImages: {
    paddingRight: 6,
    paddingBottom: 6
  },
  editSdg: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 5
  }
});
