import React, { ReactElement, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Form, Formik, FormikProps } from "formik";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import UserInputTextArea from "@socion-cordio/common/src/components/molecules/userInputTextArea";
import UserInputSecondary from "@socion-cordio/common/src/components/molecules/userInputSecondary";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { BadgesRepository } from "@socion-cordio/common/src/repositories/badges/badges";

interface Props {
  onClose?: Function;
  getBadges?: Function;
  programDetails?: any;
  badgeTypeItems?: any;
  updateTelemetryBadgesEvent?: Function;
}
export default function AddBadgeModal(props: Props): ReactElement {
  const { onClose, getBadges, programDetails, updateTelemetryBadgesEvent } = props;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(null);
  const [items, setItems] = useState(props?.badgeTypeItems);
  const [badgeTypeValue, setBadgeTypeValue] = useState(null);
  const [showError, setShowError] = useState(false);

  const formValidaionSchema = Yup.object({
    badgeName: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    awardCriteria: Yup.string().required("Required")
  });

  const onSubmitForm = async (data: {
    badgeName: string;
    description: string;
    awardCriteria: string;
  }) => {
    if (badgeTypeValue === null) {
      setShowError(true);
      return;
    }
    setLoading(true);
    const payload = {
      name: data.badgeName,
      description: data.description,
      awardingCriteria: data.awardCriteria,
      // badgeTypeId: badgeTypeValue,
      badgeClassId: badgeTypeValue,
      programId: programDetails?.program?.id
    };
    const response = BadgesRepository.addBadge(payload);
    response
      .then((res: any) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          onClose();
          getBadges();
          toast.success(res.message);
          googleWebAnalytics("Badges_Submit_Badge", "Button", "Badge");
          setLoading(false);
          updateTelemetryBadgesEvent("Badge Activated", {
            ...res.response,
            name: res.response.badgeName,
            badgeClassId: res.response.badgeClass.badgeClassId,
            badgeClassName: res.response.badgeClass.badgeClassName
          });
        } else if (res.responseCode == HTTP_STATUS_CODES.conflict) {
          toast.error(res.message);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <View>
      <ToastContainer />
      {loading ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              Add Badge
            </Text>
            <View>
              <Icon
                testID="close"
                name={IconNames.crossCircle}
                customStyle={styles.crossCircle}
                onPress={() => onClose()}
              />
            </View>
          </View>
          <View style={styles.subContainer}>
            <Formik
              initialValues={{ badgeName: "", description: "", awardCriteria: "", badgeType: "" }}
              validationSchema={formValidaionSchema}
              onSubmit={(values) => {
                onSubmitForm(values);
              }}
            >
              {(
                formikProps: FormikProps<{
                  badgeName: string;
                  description: string;
                  awardCriteria: string;
                }>
              ) => (
                <Form>
                  <View>
                    <View>
                      <UserInputSecondary
                        textValue="Badge name"
                        handleChange={formikProps.handleChange("badgeName")}
                        handleBlur={() => {
                          formikProps.setFieldTouched;
                          googleWebAnalytics("Badges_Add_Badge_Name", "Button", "Badge");
                        }}
                        value={formikProps.values.badgeName}
                        // inputStyle={[styles.inputStyling, styles.inputStylingAlignment]}
                        textInputContainerStyles={{ marginLeft: 15 }}
                        placeholder="Enter Badge name"
                        name="badgeName"
                        id="badgeName"
                        userStyle={styles.inputStylingAlignment}
                        userStyleText={styles.textHeaderField}
                        formikPropsTouched={
                          formikProps.touched.badgeName === undefined
                            ? false
                            : formikProps.touched.badgeName
                        }
                        customChange={() => formikProps.setFieldTouched("badgeName", false)}
                      />
                    </View>
                    <View style={styles.titleInputContainer}>
                      <View>
                        <Text
                          fontWeight={FontWeight.Regular}
                          testId="addbadgeTitleText"
                          textSize={TextSize.Small}
                          textStyle={styles.subheaderText}
                        >
                          Badge Type
                        </Text>
                      </View>
                      <View style={{ width: "74.5%" }}>
                        <Dropdown
                          open={open}
                          dropdownValue={val}
                          items={items}
                          setOpen={setOpen}
                          setValue={setVal}
                          setItems={setItems}
                          containerWidthStyle={styles.containerStyle}
                          containerRadius={styles.dropDownContainerStyle}
                          closeAfterSelecting={true}
                          listItems={styles.listItems}
                          containerStyle={styles.ContainerStyle2}
                          pickerStyle={styles.pickerStyle}
                          onChangeValue={(val) => [
                            setBadgeTypeValue(val),
                            setShowError(false),
                            googleWebAnalytics("Badges_Add_Badge_Type", "Button", "Badge")
                          ]}
                        />
                        {showError && (
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="addbadgeTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.errorText}
                          >
                            Please select a badge type
                          </Text>
                        )}
                      </View>
                    </View>
                    <View>
                      <UserInputTextArea
                        textValue="Description"
                        label="Description"
                        placeholder="Write a description"
                        name="description"
                        id="description"
                        handleChange={formikProps.handleChange("description")}
                        handleBlur={() => {
                          formikProps.setFieldTouched;
                          googleWebAnalytics("Badges_Add_Badge_Description", "Button", "Badge");
                        }}
                        value={formikProps.values.description}
                        numberOfLines={5}
                        multiline={true}
                        userStyle={styles.inputStylingAlignment}
                        userStyleText={styles.textHeaderField}
                        formikPropsTouched={
                          formikProps.touched.description === undefined
                            ? false
                            : formikProps.touched.description
                        }
                        customChange={() => formikProps.setFieldTouched("description", false)}
                      />
                    </View>
                    <View>
                      {/* <UserInputTextArea
                        textValue="Awarding criteria"
                        label="Awarding criteria"
                        placeholder="Add criteria"
                        name="awardCriteria"
                        id="awardCriteria"
                        handleChange={formikProps.handleChange("awardCriteria")}
                        handleBlur={() => {
                          formikProps.setFieldTouched;
                          googleWebAnalytics(
                            "Badges_Add_Badge_Awarding_Criteria",
                            "Button",
                            "Badge"
                          );
                        }}
                        value={formikProps.values.awardCriteria}
                        numberOfLines={5}
                        multiline={true}
                        userStyle={styles.inputStylingAlignment}
                        userStyleText={styles.textHeaderField}
                        formikPropsTouched={
                          formikProps.touched.awardCriteria === undefined
                            ? false
                            : formikProps.touched.awardCriteria
                        }
                        customChange={() => formikProps.setFieldTouched("awardCriteria", false)}
                      /> */}
                      <UserInputSecondary
                        textValue="Awarding criteria"
                        handleChange={formikProps.handleChange("awardCriteria")}
                        handleBlur={() => formikProps.setFieldTouched}
                        value={formikProps.values.awardCriteria}
                        textInputContainerStyles={{ marginLeft: 15 }}
                        placeholder="Add criteria"
                        name="awardCriteria"
                        id="awardCriteria"
                        userStyle={styles.inputStylingAlignment}
                        userStyleText={styles.textHeaderField}
                        formikPropsTouched={
                          formikProps.touched.awardCriteria === undefined
                            ? false
                            : formikProps.touched.awardCriteria
                        }
                        customChange={() => formikProps.setFieldTouched("awardCriteria", false)}
                      />
                    </View>
                    <View style={[styles.alignContainer]}>
                      <Button
                        type={ButtonType.Primary}
                        buttonStyles={styles.submitbutton}
                        title="Add badge"
                        onPress={formikProps.submitForm}
                        disabled={!formikProps.dirty}
                      />
                    </View>
                  </View>
                </Form>
              )}
            </Formik>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 655
  },
  customLoadingContainer: {
    width: 655,
    height: 400
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginTop: 15,
    marginBottom: 15
  },
  alignContainer: {
    alignItems: "center",
    marginTop: 25,
    marginBottom: 0
  },
  submitbutton: {
    width: "167px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  inputStylingAlignment: {
    width: "100%"
  },
  textHeaderField: {
    flex: 25
  },
  listItems: {
    padding: 10
  },
  containerStyle: {
    width: "100%"
  },
  dropDownContainerStyle: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: colorPallete.lightGreyTwo
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13,
    flex: 20,
    paddingRight: 85
  },
  errorText: {
    fontWeight: "400",
    fontSize: 10,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.cordioRed,
    marginTop: 5
  },
  textFieldContianer: {
    // flex: 40,
    // width:"15%",
    marginLeft: 15,
    borderColor: colorPallete.cordioTaupe
  },
  titleInputContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 100,
    marginTop: 10,
    zIndex: 1
  },
  ContainerStyle2: {
    borderColor: colorPallete.cordioTaupe,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    minHeight: 40,
    marginBottom: 0
  },
  pickerStyle: {
    paddingBottom: 5
  }
});
