import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, View, Pressable, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Otp } from "@socion-cordio/common/src/components/atoms/otp";
import { useDispatch, useSelector } from "react-redux";
import { profileEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import AddEmail from "@socion-cordio/common/src/components/organisms/addEmail";
import AddMobile from "@socion-cordio/common/src/components/organisms/addMobile";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { toast, ToastContainer } from "react-toastify";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { ProfileRepository } from "@socion-cordio/common/src/repositories/profile/profile";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import _ from "lodash";
import { setNewBearerToken, setSuperAdmin } from "@socion-cordio/common/src/network/authHelper";
import Loader from "@socion-cordio/common/src/components/atoms/loader";

interface Props {
  onClose: Function;
  showEmail?: boolean;
  modalTitle: string;
  headerText: string;
  newPhoneOtp?: boolean;
  sendOtpNewPhone?: Function;
  subHeaderText?: string;
  phoneNumberDetails?: any;
  isEmailChangeClicked?: boolean;
  emailOtpHandler?: Function;
  isValidatePhoneEnabled?: boolean;
  checkToCallResend?: boolean;
  profileData?: any;
}

export default function DeleteAccountOtp(props: Props): ReactElement {
  const {
    subHeaderText = 'Once you press "Delete Account" button, and on successful verification of  code, your account will be deleted permanently',
    phoneNumberDetails,
    showEmail,
    isEmailChangeClicked,
    emailOtpHandler,
    onClose,
    isValidatePhoneEnabled,
    checkToCallResend,
    profileData
  } = props;
  const [otpHandle, setOtpHandle] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [accountDeleted, setAccountDeleted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [incorrectOtpMsg, setIncorrectOtpMsg] = useState(null);
  const [otp, setOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const profileState = useSelector((state: any) => state.profile);
  const {
    userProfileData: { response }
  } = profileState;
  const history: any = useHistory();
  const dispatch = useDispatch();
  const [countryCodeObj, setCountryCodeObj] = useState({});

  useEffect(() => {
    sendOtp(false);
  }, []);

  const sendOtp = async (isResendOtpClicked: boolean) => {
    const otpResponse = ProfileRepository.sendDeleteUserOtp();
    otpResponse
      .then((res) => {
        // console.log("res", res);
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          isResendOtpClicked && toast.success("OTP sent successfully");
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
    let countryCodeObject: any = await LocalStorage.getStorage("countryCodeObj");
    setCountryCodeObj(countryCodeObject);
  };

  const validateOtp = async () => {
    // setLoading(true);
    const aesUtil: AesUtil = new AesUtil();
    const payload = {
      userName: profileData?.userName,
      otp: aesUtil.encrypt(otp.toString()),
      typeOfOtp: "DELETE-OTP",
      countryCode: profileData?.countryCode,
      verificationType: profileData?.userName.includes("@") ? "EMAIL_ID" : "PHONE_NUMBER"
    };
    const validateOtpResponse = ProfileRepository.optValidate(payload);
    validateOtpResponse
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setOtpHandle(true);
          setOtpValidated(true);
          deleteAccountHandler();
          // toast.success("Mobile number updated successfully");
        } else {
          setLoading(false);
          // toast.error(res.message);
          setIncorrectOtpMsg(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  const handleOtp = (otp: string) => {
    // let isValidOtp = /^\d+$/.test(otp);
    // if (isValidOtp) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
    setOtp(otp);
    setIncorrectOtpMsg(null);
    handleValidate({ values: null });
    setError(false);
  };

  const deleteAccountHandler = () => {
    const aesUtil: AesUtil = new AesUtil();
    setLoading(true);
    const deleteAccountResponse = ProfileRepository.deleteUserAccount(profileData?.userId);
    deleteAccountResponse
      .then((res: any) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setAccountDeleted(true);
          LocalStorage.removeStoredKeys();
          setLoading(false);
        } else {
          toast.error(res.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  const handleLogout = () => {
    setTimeout(() => {
      dispatch({ type: "USER_LOGOUT" });
      history.push("/new/iam/login");
      LocalStorage.removeStoredKeys();
    }, 1000);
  };

  const handleValidate = (values: any) => {
    let errors: any = {};
    const regex = /^\d+$/;
    if (!values.otp || values?.otp === null || values?.otp?.length !== 6) {
      errors.otp = "Please enter otp";
    }
    if (values.otp && !regex.test(values?.otp)) {
      errors.otp = "Invalid characters";
    }
    setErrorMessage(errors?.otp);
    return errors;
  };

  const submitOtpHandler = () => {
    const valueErrorObject = handleValidate({ otp: otp });
    if (valueErrorObject.otp !== null || valueErrorObject.otp !== "") {
      setError(true);
      if (_.isEmpty(valueErrorObject)) {
        setError(false);
        validateOtp();
      }
    }
  };

  const encodeEmailHandler = (email: string) => {
    let newEmail = email.split("@");
    let str = newEmail[0].substr(0, newEmail[0].length - 1);
    let updateEmail = str.replace(/./g, "*") + newEmail[0].slice(-1) + "@" + newEmail[1];
    return updateEmail;
  };

  return (
    <View>
      <ToastContainer />
      {loading ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : (
        !otpHandle && (
          <View>
            <View style={styles.modalHeader}>
              <Text
                fontWeight={FontWeight.Bold}
                testId="addRoleText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {props.modalTitle}
              </Text>
              <View>
                <Pressable onPress={() => props.onClose()}>
                  <Icon testID="close" name={IconNames.crossCircle} />
                </Pressable>
              </View>
            </View>
            <View style={styles.headerContainer}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleTitleText"
                textSize={TextSize.Small}
                textStyle={styles.subheaderEnterOtpText}
              >
                {"Enter OTP"}
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleTitleText"
                textSize={TextSize.Small}
                textStyle={styles.subheaderText}
              >
                {profileData?.phoneNumber && profileData?.emailId
                  ? `Please enter the code that was sent to ${profileData?.phoneNumber
                      ?.replace(/\d(?=\d{4})/g, "*")
                      .replace("+", "")} / ${encodeEmailHandler(profileData?.emailId)}`
                  : !profileData?.emailId
                  ? `Please enter the code that was sent to ${profileData?.phoneNumber
                      ?.replace(/\d(?=\d{4})/g, "*")
                      .replace("+", "")}`
                  : `Please enter the code that was sent to ${encodeEmailHandler(
                      profileData?.emailId
                    )}`}
              </Text>
            </View>
            <View style={styles.otpContainer}>
              <Otp
                id="otp"
                name="otp"
                value={otp}
                handleTextChange={(otp: string) => handleOtp(otp)}
                noFormik
              />
            </View>
            <View style={styles.requiredMessageContainer}>
              {error && (
                <Text
                  testId="otpError"
                  textSize={TextSize.Small}
                  fontWeight={FontWeight.Regular}
                  textStyle={styles.error}
                >
                  {errorMessage}
                </Text>
              )}
              {!error && incorrectOtpMsg && (
                <Text
                  testId="otpError"
                  textSize={TextSize.Small}
                  fontWeight={FontWeight.Regular}
                  textStyle={styles.error}
                >
                  {incorrectOtpMsg}
                </Text>
              )}
            </View>
            <View style={styles.resendContainer}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="addRoleTitleText"
                textSize={TextSize.Small}
                textStyle={[styles.subheaderText2, styles.subheaderResendText]}
              >
                {subHeaderText}
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="signup"
                textSize={TextSize.Regular}
                style={styles.OtpText}
              >
                Didn't receive a OTP?
              </Text>
              <TouchableOpacity>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="addRoleTitleText"
                  textSize={TextSize.Small}
                  textStyle={[styles.subheaderText, styles.resendText]}
                  onPress={() => sendOtp(true)}
                >
                  Resend OTP
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.submitButtonContainer}>
              <View style={styles.buttonContainer1}>
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Cancel"
                  onPress={() => onClose(false)}
                />
              </View>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.submitbutton}
                title="Delete Account"
                onPress={() => {
                  submitOtpHandler();
                }}
                disabled={loading}
                // disabled={error || otp?.length !== 6 || loading}
              />
            </View>
          </View>
        )
      )}
      {otpValidated && accountDeleted && (
        <View>
          <View style={styles.modalHeader}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="addRoleText"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {"Your Account Deleted!"}
            </Text>
            <View>
              <Pressable
                onPress={() => {
                  handleLogout();
                }}
              >
                <Icon testID="close" name={IconNames.crossCircle} />
              </Pressable>
            </View>
          </View>
          <View>
            <Text
              fontWeight={FontWeight.Bold}
              testId="signup"
              textSize={TextSize.Regular}
              style={styles.consentText}
            >
              Your account is deleted from PDA system.
            </Text>
            <Text
              fontWeight={FontWeight.Bold}
              testId="signup"
              textSize={TextSize.Regular}
              style={styles.consentText}
            >
              Sad to see you go!
            </Text>
            <Text
              fontWeight={FontWeight.Bold}
              testId="signup"
              textSize={TextSize.Regular}
              style={styles.consentText}
            >
              If you want to use PDA system in future, you can sign up again using your mobile
              number or email.
            </Text>
          </View>
          <View style={styles.submitButtonContainer}>
            <Button
              type={ButtonType.Primary}
              buttonStyles={styles.submitbutton}
              title="Close"
              onPress={() => {
                handleLogout();
              }}
              disabled={loading}
              // disabled={error || otp?.length !== 6 || loading}
            />
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 15,
    marginLeft: 256
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    color: colorPallete.cordioRedDark5
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  subheaderEnterOtpText: {
    fontWeight: "600",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 40,
    flex: 20
  },
  subheaderText2: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20,
    textAlign: "center"
  },
  headerContainer: {
    alignItems: "center"
  },
  requiredMessageContainer: {
    alignSelf: "center"
  },
  otpContainer: {
    marginTop: 30,
    marginBottom: 0
  },
  submitButtonContainer: {
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "center"
  },
  resendContainer: {
    alignItems: "center"
  },
  subheaderResendText: {
    marginTop: 20
  },
  resendText: {
    color: "#B7504A",
    textDecorationLine: "underline",
    marginTop: 3,
    marginBottom: 10
  },
  submitbutton: {
    width: "140px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 14
  },
  buttonContainer1: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 15,
    marginLeft: 15,
    width: 140,
    height: 50
  },
  consentText: {
    flex: 0.8,
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontSize: 14,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 50,
    textAlign: "center"
  },
  customLoadingContainer: {
    width: 680,
    height: 510
  },
  OtpText: {
    marginTop: 20,
    flex: 0.8,
    color: colorPallete.textLight,
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    // paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 19,
    textAlign: "center"
  }
});
