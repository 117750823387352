import { Link } from "react-router-dom";
import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import * as React from "react";
import MapRoutes from "./mapRoutes";
import { ReactElement } from "react";
import SignupOtp from "@socion-cordio/common/src/components/organisms/signup/signupOtp";
import ForgotPasswordOtp from "@socion-cordio/common/src/components/organisms/forgot-password/forgotPasswordOtp";
import ForgotPassword from "@socion-cordio/common/src/components/organisms/forgot-password/forgotPassword";
import SignupDetails from "@socion-cordio/common/src/components/organisms/signup/signupDetails";
import ResetPassword from "@socion-cordio/common/src/components/organisms/forgot-password/resetPassword";
import LoginRouter from "modules/login/navigation/loginRouter";
import TermsOfUse from "@socion-cordio/common/src/components/organisms/termsOfUse";
import PrivacyPolicy from "@socion-cordio/common/src/components/organisms/privacyPolicy";
import AttestationDetails from "@socion-cordio/common/src/components/organisms/attestation/attestationDetails";
import QPCompletion from "@socion-cordio/common/src/components/organisms/qpWebView/completion/qpCompletion";
import QPScanner from "@socion-cordio/common/src/components/organisms/qpWebView/scanner/qpScanner";
import SignupFormNewOtp from "@socion-cordio/common/src/components/organisms/signup/signupNew/signupFormNewOtp";
import SignupFormNewDetails from "@socion-cordio/common/src/components/organisms/signup/signupNew/signupFormNewDetails";
import LoginOtpForm from "@socion-cordio/common/src/components/organisms/login/loginOtpForm";
import ForgotPasswordNew from "@socion-cordio/common/src/components/organisms/forgot-password/forgot-password/forgotPasswordNew";
import ForgotPasswordNewOtp from "@socion-cordio/common/src/components/organisms/forgot-password/forgot-password/forgotPasswordNewOtp";
import SetForgotPasswordNewOtp from "@socion-cordio/common/src/components/organisms/forgot-password/forgot-password/setForgotPasswordNew";
import { useHistory } from "react-router-dom";

export const publicRoutes = () => {
  const history: any = useHistory();
  return [
    {
      path: routeNames.CONTACT,
      component: () => <div>Contact</div>,
      isExact: true
    },
    {
      path: routeNames.HEALTHCHECK,
      component: () => <div>HEALTHCHECK</div>,
      isExact: true
    },
    {
      path: routeNames.SIGNUPOTP,
      // component: () => <SignupOtp />,
      component: () => <SignupFormNewOtp />,
      isExact: true
    },
    {
      path: routeNames.LOGINOTP,
      component: () => <LoginOtpForm />,
      isExact: true
    },
    {
      path: routeNames.ABOUTUS,
      component: () => <div>About</div>,
      isExact: true
    },

    {
      path: routeNames.FORGOTPASSWORDOTP,
      // component: () => <ForgotPasswordOtp />,
      component: () => <ForgotPasswordNewOtp />,
      isExact: true
    },
    {
      path: routeNames.FORGOTPASSWORD,
      // component: () => <ForgotPassword />,
      component: () => <ForgotPasswordNew />,
      isExact: true
    },
    {
      path: routeNames.SIGNUPDETAILS,
      // component: () => <SignupDetails />,
      component: () => <SignupFormNewDetails />,
      isExact: true
    },
    {
      path: routeNames.TERMSOFUSE,
      component: () => <TermsOfUse />,
      isExact: true
    },
    {
      path: routeNames.PRIVACYPOLICY,
      component: () => <PrivacyPolicy />,
      isExact: true
    },
    {
      path: routeNames.RESETPASSWORD,
      // component: () => <ResetPassword />,
      component: () => <SetForgotPasswordNewOtp />,
      isExact: true
    },
    {
      path: `${routeNames.ATTESTATION}/:sessionId/:userId/:role`,
      component: () => <AttestationDetails />,
      isExact: false
    },
    {
      path: `${routeNames.QUALIFICATIONPACKCOMPLETION}/:bundleId/:userId`,
      component: () => <QPCompletion />,
      isExact: true
    },
    {
      path: `${routeNames.QUALIFICATIONPACKSCANNER}/:bundleId`,
      component: () => <QPScanner />,
      isExact: true
    },
    // {
    //   path: routeNames.HOME,
    //   component: () => (
    //     <React.Fragment>
    //       <div>
    //         Go to route <Link to="/app/profile">PROFILE</Link>
    //       </div>
    //     </React.Fragment>
    //   ),
    //   isExact: true
    // },
    {
      path: "",
      component: () => (
        <div
          onClick={() => {
            history.push("/new/iam/signup");
          }}
        >
          Not Found Of Public Routes <br />{" "}
          <a href="javascript:void(0);">
            <h3>Click here for login page</h3>
          </a>
        </div>
      )
    }
  ];
};
export default function PublicRouter(): ReactElement {
  let finalPublicRoutes: Array<any> = [...LoginRouter(), ...publicRoutes()];
  return <MapRoutes routes={finalPublicRoutes} basePath="" />;
}
