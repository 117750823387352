import React, { ReactElement, useState, useEffect } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { toast, ToastContainer } from "react-toastify";

interface Props {
  onClose: Function;
  message?: string;
  handleConfirm?: any;
}

export default function ProfileConfirmationModal(props: Props): ReactElement {
  const { message, onClose, handleConfirm } = props;

  return (
    <>
      <ToastContainer />
      <View style={styles.container}>
        <View style={styles.header}>
          <TouchableOpacity>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossIcon}
              onPress={() => onClose()}
            />
          </TouchableOpacity>
        </View>
        <Text
          fontWeight={FontWeight.Regular}
          testId="confirmText"
          textSize={TextSize.Regular}
          textStyle={[styles.alignText, styles.textColor]}
        >
          {message}
        </Text>
      </View>
      <View style={[styles.header, styles.contentConatiner]}>
        <View style={styles.buttonContainer}>
          <Button
            type={ButtonType.Secondary}
            buttonStyles={styles.button}
            textStyles={styles.buttonFont}
            title="Cancel"
            onPress={() => onClose()}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.button}
            textStyles={styles.buttonFont}
            title="Confirm"
            onPress={handleConfirm}
          />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 600
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  contentConatiner: {
    marginLeft: 20
  },
  alignText: {
    fontSize: 14,
    color: colorPallete.textLight,
    FontFamily: FontFamily.Medium,
    fontWeight: "400",
    marginBottom: 20,
    marginLeft: 25
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  button: {
    width: "167px",
    height: "50px",
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 14
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    marginLeft: 15
  },
  crossIcon: {
    fontSize: 15,
    marginLeft: 700
  }
});
