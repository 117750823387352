import React, { useEffect, useMemo, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import _ from "lodash";
import { TABS, REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import {
  dispatchEntityPartners,
  getSelectedEntityDetails,
  getSelectedEntityId
} from "@socion-cordio/common/src/utils/registryHelpers/entityHelper";
import PartnerEntityDetailsModal from "@socion-cordio/common/src/components/organisms/registry/entity/partnerEntityDetailsModal";
import PartnerProgramDetailsModal from "@socion-cordio/common/src/components/organisms/registry/entity/partnerProgramDetailsModal";

import ButtonTab, {
  ButtonTypeSecondary
} from "@socion-cordio/common/src/components/atoms/buttonTab";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import { EntityRepository } from "@socion-cordio/common/src/repositories/registry/entity/entity";
import { ProgramsRepository } from "@socion-cordio/common/src/repositories/registry/entity/programs";

interface IProps {}

const EntityPartnersList = (props: IProps) => {
  const [activeTab, setActiveTab] = useState(TABS.entities);
  const [isLoading, setIsLoading] = useState(true);
  const [showList, setShowList] = useState(null);
  const [entity, setEntity] = useState(null);
  const [selectedPartnerEntity, setSelectedPartnerEntity] = useState(null);
  const [selectedPartnerProgram, setSelectedPartnerProgram] = useState(null);

  const dispatch = useDispatch();
  const [entityPartnersList, setEntityPartnersList] = useState(null);
  const [showPartnerEntityModal, setShowPartnerEntityModal] = useState(false);
  const [showPartnerProgramModal, setShowPartnerProgramModal] = useState(false);

  const {
    entityPartners: entityPartners,
    loading: { getEntityPartners: loading },
    error: { getEntityPartners: error }
  } = useSelector((state: any) => state.entities);

  useEffect(() => {
    setIsLoading(true);
    dispatchEntityPartners(dispatch);
    getSelectedEntityData();
  }, []);

  const getSelectedEntityData = async () => {
    const selectedEntity = await getSelectedEntityDetails();
    setEntity(selectedEntity);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setShowList(false);
      setEntityPartnersList([]);
    }
  }, [error]);

  useEffect(() => {
    if (entityPartners !== null) {
      setShowList(entityPartners.length > 0);
      setEntityPartnersList(entityPartners);
      setIsLoading(false);
    }
  }, [entityPartners]);

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          textSize: TextSize.Small,
          fontWeight: FontFamily.Light
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 40,
        maxWidth: 40,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          // if (!data.active) {
          //   style = {
          //     opacity: 0.6
          //   };
          // } else {
          style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          // }

          return cellData("serialNo", style, data.serialNo);
        }
      },
      {
        Header: "Partner Entity",
        accessor: "entityName",
        width: 300,
        maxWidth: 300,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              ...style,
              textDecorationLine: "underline",
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }

          return (
            <TouchableOpacity
              onPress={() => {
                setSelectedPartnerEntity(data);
                // await getPartnerEntityDetails(data?.entityId)
                setShowPartnerEntityModal(!showPartnerEntityModal);
              }}
            >
              {cellData("entityName", style, data?.entityName || "")}
            </TouchableOpacity>
          );
        }
      },
      {
        Header: "Partner Program",
        accessor: "programName",
        width: 300,
        maxWidth: 300,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              ...style,
              textDecorationLine: "underline",
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }

          return (
            <TouchableOpacity
              onPress={() => {
                setSelectedPartnerProgram(data);
                // await getPartnerProgramEntityDetails(data.programId)
                setShowPartnerProgramModal(!showPartnerProgramModal);
              }}
            >
              {cellData("programName", style, data?.programName || "")}
            </TouchableOpacity>
          );
        }
      },
      {
        Header: "My Role",
        accessor: "role",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          return cellData("role", style, data?.role);
        }
      },
      {
        Header: "Partner Since",
        accessor: "partnerSince",
        width: 80,
        maxWidth: 80,
        disableSortBy: true,
        Cell: ({ row: { original: data } }: { row: { original: any } }) => {
          let style = {};
          if (!data.active) {
            style = {
              opacity: 0.6
            };
          } else {
            style = {
              fontFamily: FontFamily.Medium,
              fontWeight: FontFamily.Bold
            };
          }
          const date = data?.partnerSince ? dataHelper.formatDate(data?.partnerSince) : "";
          return cellData("partnerSince", style, date);
        }
      }
    ],
    [entity]
  );

  const getPartnerEntityDetailsModal = () => {
    return (
      <View>
        {selectedPartnerEntity && (
          <SocionModal
            modalVisible={showPartnerEntityModal}
            setModalVisible={() => setShowPartnerEntityModal(!showPartnerEntityModal)}
            component={
              <PartnerEntityDetailsModal
                partnerEntity={selectedPartnerEntity}
                onClose={() => setShowPartnerEntityModal(false)}
                // setModalVisible={setModalVisible}
              />
            }
          />
        )}
      </View>
    );
  };

  const getPartnerProgramDetailsModal = () => {
    return (
      <View>
        {selectedPartnerProgram && (
          <SocionModal
            modalVisible={showPartnerProgramModal}
            setModalVisible={() => setShowPartnerProgramModal(!showPartnerProgramModal)}
            component={
              <PartnerProgramDetailsModal
                partnerProgram={selectedPartnerProgram}
                onClose={() => setShowPartnerProgramModal(false)}
                // setModalVisible={setModalVisible}
              />
            }
          />
        )}
      </View>
    );
  };

  const getPartnerEntities = () => {
    return (
      <>
        <View style={styles.dataContainer}>
          {showList !== null && !showList && (
            <>
              <View style={styles.search}>
                <View style={styles.searchContainer}>
                  <SearchFilterSeondary
                    // customWidthStyle={styles.searchContainer}
                    editable={true}
                    placeholder={"Search"}
                  />
                </View>
              </View>
              <View>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.miniContainerHeaderText]}
                >
                  No Partner Entities Available
                </Text>
              </View>
            </>
          )}

          {showList && (
            <View>
              <Table
                columns={columns}
                data={entityPartnersList}
                showSearchField={true}
                customSearchBarContainerStyle={{ marginLeft: -20 }}
                paginationAndSearch={true}
              />
            </View>
          )}
        </View>
      </>
    );
  };

  const getPartnerNetworks = () => {
    return (
      <View>
        <Text
          fontWeight={FontWeight.Bold}
          testId="headerText"
          textSize={TextSize.Small}
          textStyle={[styles.headerText, styles.miniContainerHeaderText]}
        >
          No Partner Networks Available
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.subContainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <View style={styles.toggleContainer}>
              <ButtonTab
                type={
                  activeTab === TABS.entities
                    ? ButtonTypeSecondary.Secondary
                    : ButtonTypeSecondary.Primary
                }
                buttonStyles={[styles.toggleButton, styles.buttonLeftRadius]}
                textStyles={styles.textStyle}
                title={TABS.entities}
                onPress={() => {
                  setActiveTab(TABS.entities);
                  //   dispatchTabEvent(TABS.entities, false);
                }}
              />
              <ButtonTab
                type={
                  activeTab === TABS.networks
                    ? ButtonTypeSecondary.Secondary
                    : ButtonTypeSecondary.Primary
                }
                buttonStyles={[styles.toggleButton, styles.buttonRightRadius]}
                textStyles={styles.textStyle}
                title={TABS.networks}
                onPress={() => {
                  //   dispatchTabEvent(TABS.Network);
                  setActiveTab(TABS.networks);
                }}
              />
            </View>

            {showPartnerEntityModal && getPartnerEntityDetailsModal()}
            {showPartnerProgramModal && getPartnerProgramDetailsModal()}

            <View style={styles.dataContainer}>
              {activeTab === TABS.entities && getPartnerEntities()}
              {activeTab === TABS.networks && getPartnerNetworks()}
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    padding: 20,
    paddingTop: 18,
    backgroundColor: colorPallete.cordioBeigeLight2,
    minHeight: "calc(100vh - 50px)"
  },
  subContainer: {
    padding: 18,
    borderRadius: 10,
    backgroundColor: colorPallete.white,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },

  toggleContainer: {
    flexDirection: "row",
    padding: 18,
    paddingBottom: 0
  },
  toggleButton: {
    width: 180
  },
  buttonRightRadius: {
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5
  },
  buttonLeftRadius: {
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5
  },
  textStyle: {
    fontSize: 12
  },
  dataContainer: {
    padding: 10,
    marginBottom: 180
  },
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 25,
    width: "100%"
  },
  searchContainer: {
    width: 535
  },

  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  }
});

export default EntityPartnersList;
