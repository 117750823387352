import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import jsonToCsv from "@socion-cordio/common/src/utils/jsonToCsvDownload";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useHistory } from "react-router-dom";
import { UserRolesProgramActions } from "@socion-cordio/common/src/modules/user-role/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Tooltip from "react-tooltip-lite";
import Moment from "moment";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
interface Props {
  programDetails: any;
}

export default function LiveSessionPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const [loadingState, setLoadingState] = useState(true);
  const [failed, setFailed] = useState(false);
  const [tableHeader, setTableHeader] = useState(null);
  const [user, setUser] = useState(null);
  const [userRolesData, setUserRolesData] = useState(null);
  const [liveSessionResponse, setLiveSessionResponse] = useState(null);
  const history: any = useHistory();
  const dispatch = useDispatch();
  const userRoleTrainer = "TRAINER";

  useEffect(() => {
    (async () => {
      let userData = await LocalStorage.getStorage("user");
      let tempUserRolesData: any = await LocalStorage.getStorage("userRoles");
      setUser(userData);
      setUserRolesData(tempUserRolesData?.userRolesData?.response);
    })();
  }, []);

  const {
    userRolesData: userRoles,
    loading: { getUserRolesProgram: loading }
  } = useSelector((state: any) => state?.userRolesProgram);

  useEffect(() => {
    getSessionDetails();
  }, []);

  const convertUtctoLocal = (date: any) => {
    let datetime = new Date(Moment.utc(date));
    console.log(datetime, "datetime");
    // console.log(dataHelper.formatDate(datetime),"dataHelper.formatDate(datetime)")
    // return dataHelper.formatDate(datetime);
  };

  const getSessionDetails = async () => {
    try {
      const { program } = props?.programDetails;
      const newBody = {
        programId: program.id,
        sessionStatus: "LIVE"
      };
      const response = await ApiClient.post(sessionPackEndPoints.getSessionDetails(), newBody);
      response?.response.forEach((session: any, index: number) => {
        session.serialNo = `${index + 1}.`;
        // session.session.formattedSessionStartDate = dataHelper.formatDate(
        //   session?.session?.sessionStartDate
        // );
        // session.session.formattedSessionEndDate = dataHelper.formatDate(
        //   session?.session?.sessionEndDate
        // );
        let startDate = new Date(Moment.utc(session?.session?.sessionStartDate));
        let endDate = new Date(Moment.utc(session?.session?.sessionEndDate));
        session.session.formattedSessionStartDate = dataHelper.formatDateTime(startDate);
        session.session.formattedSessionEndDate = dataHelper.formatDateTime(endDate);
        session.scanOuts =
          session?.session?.sessionScanMode === "SINGLE_SCAN"
            ? "NA"
            : session?.scanOuts
            ? session?.scanOuts
            : 0;
      });
      setLiveSessionResponse(response?.response);
      const payload = {
        programId: program.id
      };
      dispatch(UserRolesProgramActions.getUserRolesProgram(payload));
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      setFailed(true);
    }
  };

  const createNewSessionHandler = () => {
    // history.push(`${routeNames.app}${routeNames.TOPICS}`, {
    //   programId: program.id
    // });
  };

  const downloadCsvList = (data: any) => {
    const header = {
      srNo: "Sr.No",
      topicName: "Topic Name",
      start: "Start Date",
      end: "End Date",
      session: "Session Creator",
      scanIn: "Scan Ins",
      scanOut: "Scan Outs",
      venue: "Venue"
    };
    const tableData = data.map((element: any) => ({
      srNo: element?.serialNo,
      topicName: element?.session?.sessionName,
      start: dataHelper.formatDate(element?.session?.sessionStartDate),
      end: dataHelper.formatDate(element?.session?.sessionEndDate),
      session: element?.sessionCreator?.name,
      scanIn: element?.scanIns,
      scanOut: element?.scanOuts,
      venue: element?.session?.address
    }));
    jsonToCsv(header, tableData, props?.programDetails?.program.name + "_Live_Session");
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const viewSessionDetailsHandler = (data: any) => {
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live`, {
      sessionData: data,
      redirectionFrom: "Live Session",
      sessionScanMode: data.session.sessionScanMode,
      scanType: "Scan In",
      completed: false
    });
  };

  const refreshListHandler = () => {
    getSessionDetails();
    setLoadingState(true);
    googleWebAnalytics("Session_Live_Refresh_List", "Button", "Session");
  };

  const handleScanIn = (data: any) => {
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live/scanIn`, {
      sessionData: data,
      redirectionFrom: "Live Session",
      sessionScanMode: data.session.sessionScanMode,
      scanType: "Scan In",
      completed: false
    });
  };

  const handleScanOut = (data: any) => {
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/live/scanOut`, {
      sessionData: data,
      redirectionFrom: "Live Session",
      sessionScanMode: data.session.sessionScanMode,
      scanType: "Scan Out",
      completed: false
    });
  };

  const renderName = (valueString: string) => (
    <a style={{ color: colorPallete.textBlack, fontFamily: FontFamily.Medium, fontSize: 12 }}>
      {valueString}
    </a>
  );

  const columns = [
    {
      Header: "Sr.No",
      accessor: "serialNo",
      width: 30,
      maxWidth: 30,
      disableSortBy: true,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("serialNo", style, session?.serialNo);
      }
    },

    {
      Header: "Session Name",
      accessor: "session.sessionName",
      width: 150,
      maxWidth: 150,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium
          // fontWeight: FontFamily.Bold
        };
        const onPress = () => {
          session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData?.includes("ENTITY_ADMIN") ||
          userRolesData?.includes("PROGRAM_ADMIN")
            ? viewSessionDetailsHandler(session)
            : null;
          // Close the left side panel when on click of view session
          LocalStorage.setStorage("isSidePanelCollapsed", true);
        };
        return session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData?.includes("ENTITY_ADMIN") ||
          userRolesData?.includes("PROGRAM_ADMIN") ? (
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText1"
            textSize={TextSize.Small}
            style={[styles.sessionName]}
            onPress={onPress}
          >
            <a
              style={{ color: colorPallete.textBlack, fontFamily: FontFamily.Medium, fontSize: 12 }}
            >
              {session?.session.sessionName}
            </a>
          </Text>
        ) : (
          cellData("name", style, session?.session.sessionName)
        );
      }
    },
    {
      Header: "Contents",
      accessor: "contents",
      width: 55,
      maxWidth: 55,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("content", style, session?.contents);
      }
    },
    {
      Header: "Start",
      accessor: "session.sessionStartDate",
      width: 45,
      maxWidth: 45,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("start", style, session?.session?.formattedSessionStartDate);
      }
    },
    {
      Header: "End",
      accessor: "session.sessionEndDate",
      width: 45,
      maxWidth: 45,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("end", style, session?.session?.formattedSessionEndDate);
      }
    },
    {
      Header: "Session Creator",
      accessor: "sessionCreator.name",
      width: 75,
      maxWidth: 75,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        const sessionCreator = session?.sessionCreator?.name ? session.sessionCreator.name : "None";
        return cellData("sessionCreator", style, sessionCreator);
      }
    },
    {
      Header: "Scan In",
      accessor: "scanIns",
      width: 53,
      maxWidth: 53,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular
          // fontWeight: FontFamily.Bold,
        };
        const onPress = () => {
          session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData.includes("ENTITY_ADMIN") ||
          userRolesData.includes("PROGRAM_ADMIN")
            ? handleScanIn(session)
            : null;
          // Close the left side panel when on click of view session
          LocalStorage.setStorage("isSidePanelCollapsed", true);
        };
        const scanIns = session?.scanIns ? session?.scanIns : 0;
        // return cellData("scanIns", style, scanIns, onPress);
        return session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData?.includes("ENTITY_ADMIN") ||
          userRolesData?.includes("PROGRAM_ADMIN") ? (
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText1"
            textSize={TextSize.Small}
            style={styles.scanOutText}
            onPress={onPress}
          >
            {scanIns}
          </Text>
        ) : (
          cellData("sessionCreator", style, scanIns, onPress)
        );
      }
    },
    {
      Header: "Scan Out",
      accessor: "scanOuts",
      width: 53,
      maxWidth: 53,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Regular
          // fontWeight: FontFamily.Bold,
        };
        const onPress = () => {
          session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData.includes("ENTITY_ADMIN") ||
          userRolesData.includes("PROGRAM_ADMIN")
            ? handleScanOut(session)
            : null;
          // Close the left side panel when on click of view session
          LocalStorage.setStorage("isSidePanelCollapsed", true);
        };
        const scanOuts = session?.scanOuts;
        // return cellData("sessionCreator", style, scanOuts, onPress);
        return session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData?.includes("ENTITY_ADMIN") ||
          userRolesData?.includes("PROGRAM_ADMIN") ? (
          <>
            {scanOuts === "NA" ? (
              <Text
                fontWeight={FontWeight.Thin}
                testId="headerText1"
                textSize={TextSize.Small}
                style={styles.scanOutText}
              >
                {scanOuts}
              </Text>
            ) : (
              <Text
                fontWeight={FontWeight.Thin}
                testId="headerText1"
                textSize={TextSize.Small}
                style={styles.scanOutText}
                onPress={onPress}
              >
                {scanOuts}
              </Text>
            )}
          </>
        ) : (
          cellData("sessionCreator", style, scanOuts, scanOuts !== "NA" && onPress)
        );
      }
    },
    {
      Header: "Venue",
      accessor: "session.address",
      width: 50,
      maxWidth: 50,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return session?.session?.address.length < 10 ? (
          cellData("venue", style, session?.session?.address)
        ) : (
          <Tooltip
            styles={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inherit",
              fontFamily: FontFamily.Regular,
              fontSize: 12,
              color: colorPallete.textBlack
            }}
            tipContentClassName=""
            distance={10}
            direction="bottom"
            background={colorPallete.cordioBeigeLight1}
            color="black"
            padding={10}
            content={
              <View style={{ width: 250 }}>
                <Text
                  testId="testId"
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.Small}
                  style={{
                    fontFamily: FontFamily.Regular,
                    fontSize: 12
                  }}
                >
                  {session?.session?.address}
                </Text>
              </View>
            }
          >
            {session?.session?.address}
          </Tooltip>
        );
      }
    }
  ];

  const searchBarAndAddSession = () => (
    <View>
      <View style={styles.searchBarContainer}>
        <View pointerEvents={"none"}>
          <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
        </View>
        <View style={styles.buttonsContainer}>
          <View>
            <TouchableOpacity
              style={styles.refreshButtonContainer}
              onPress={() => refreshListHandler()}
            >
              <Icon
                name={IconNames.refresh}
                customStyle={styles.iconStyle}
                onPress={() => refreshListHandler()}
              />
              <Text
                fontWeight={FontWeight.Thin}
                testId="headerText1"
                textSize={TextSize.Small}
                style={[styles.refreshButton]}
              >
                Refresh List
              </Text>
            </TouchableOpacity>
          </View>
          {/* <View>
            {!loading && userRoles?.response.includes(userRoleTrainer) && (
              <TouchableOpacity onPress={() => createNewSessionHandler()}>
                <Link to={`${routeNames.app}${routeNames.SESSION}${routeNames.CREATENEWSESSION}`}>
                  <Image
                    testId="primaryAddImg"
                    source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                    imageStyle={{
                      width: 30,
                      height: 30
                    }}
                  />
                </Link>
              </TouchableOpacity>
            )}
          </View> */}
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={[styles.headerText, styles.headerAlignment]}
      >
        {"No Live sessions scheduled under this program"}
      </Text>
    </View>
  );

  return (
    <View>
      {loadingState ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          {liveSessionResponse?.length === 0 ? (
            <View>{searchBarAndAddSession()}</View>
          ) : (
            liveSessionResponse && (
              <Table
                columns={columns}
                data={liveSessionResponse}
                eventAnalytics="LiveSession"
                // addIcon={
                //   <View>
                //     {!loading && userRoles?.response.includes(userRoleTrainer) && (
                //       <TouchableOpacity onPress={() => createNewSessionHandler()}>
                //         <Link
                //           to={`${routeNames.app}${routeNames.SESSION}${routeNames.CREATENEWSESSION}`}
                //         >
                //           <Image
                //             testId="primaryAddImg"
                //             source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                //             imageStyle={{
                //               width: 30,
                //               height: 30
                //             }}
                //           />
                //         </Link>
                //       </TouchableOpacity>
                //     )}
                //   </View>
                // }
                refreshList={
                  <TouchableOpacity onPress={() => {}}>
                    <Icon
                      name={IconNames.refresh}
                      customStyle={styles.iconStyle}
                      onPress={() => refreshListHandler()}
                    />
                  </TouchableOpacity>
                }
                refreshListHandler={refreshListHandler}
              />
            )
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  },
  // Search bar disabled
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 25
  },
  customWidthStyle: {
    width: 400
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  headerAlignment: {
    paddingHorizontal: 25
  },
  buttonsContainer: {
    // flexDirection: "row",
    // alignItems: "center"
  },
  refreshButtonContainer: {
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center"
    // marginRight: 40
  },
  refreshButton: {
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    textDecorationLine: "underline"
  },
  sessionName: {
    color: "black"
  },
  scanOutText: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontSize: 12
  }
});
