import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { useTranslation } from "react-i18next";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {
  programDetails: any;
  badgeRoleList: any[];
  dataFilteredHandler: Function;
  setModalVisible: any;
  setIsBadgeFilterApplied: Function;

  eventAnalytics?: string;
  setErrorFilterMessage?: Function;
  setIsNoBadgeSelected?: Function;
  selectedBadgeRoleList?: any;
  getSelectedRoleBadgeList?: any;
  noAssignedBadgesSelected?: any;
  checkedBadges?: any;
  setCheckedBadges?: any;
  badgeRoleListsArrayData?: any;
  setBadgeRoleListsArrayData?: any;
}

const BadgeFilter = forwardRef((props: Props, ref): ReactElement => {
  const { t } = useTranslation();
  const {
    programDetails,
    badgeRoleList,
    dataFilteredHandler,
    setModalVisible,
    setIsBadgeFilterApplied,

    eventAnalytics,
    setErrorFilterMessage,
    setIsNoBadgeSelected,
    selectedBadgeRoleList,
    getSelectedRoleBadgeList,
    noAssignedBadgesSelected,
    checkedBadges,
    setCheckedBadges,
    badgeRoleListsArrayData,
    setBadgeRoleListsArrayData
  } = props;

  const [badgeRoleListsArray, setBadgeRoleListsArray] = useState([]);
  const [noBadgesSelect, setNoBadgesSelect] = useState(false);
  const [displayBadgeSwitch, setDisplayBadgeSwitch] = useState(true);
  const [expandedIndexArr, setExpandedIndexArr] = useState<Set<any>>(new Set());

  useEffect(() => {
    setBadgeRoleListsArray(badgeRoleListsArrayData);
  }, [badgeRoleListsArray, badgeRoleListsArrayData]);

  useEffect(() => {}, [checkedBadges, badgeRoleListsArray]);

  useEffect(() => {
    checkNoAssignedBagedHandler();
  }, []);

  const toggleSelection = (data: any) => {
    if (data.isSelected == undefined) {
      data.isSelected = true;
    } else {
      data.isSelected = !data.isSelected;
    }
  };

  const selectedCheckBoxHandler = (data: any, parentBadgeClass?: any) => {
    let tempArray = [...checkedBadges];
    if (parentBadgeClass) {
      if (tempArray.includes(data)) {
        tempArray.splice(tempArray.indexOf(data), 1);
      } else {
        tempArray.push(data);
      }
      toggleSelection(data);
      setCheckedBadges(tempArray);
      let allChildrenBadgesSelected = true;
      parentBadgeClass.children.forEach((child: any) => {
        if (!child.isSelected) {
          allChildrenBadgesSelected = false;
        }
      });
      parentBadgeClass.isSelected = allChildrenBadgesSelected;
    } else {
      if (data.isSelected) {
        data.children.forEach((data: any) => {
          data.isSelected = false;
          if (tempArray.includes(data)) {
            tempArray.splice(tempArray.indexOf(data), 1);
          }
        });
      } else {
        data.children.forEach((data: any) => {
          if (!data.isSelected) {
            toggleSelection(data);
            tempArray.push(data);
          }
        });
      }
      setCheckedBadges(tempArray);
      toggleSelection(data);
    }
    if (noBadgesSelect) {
      setNoBadgesSelect(false);
      removeNoAssignedBadgesLocalStorage();
    }
    getSelectedRoleBadgeList(tempArray);
    googleWebAnalytics(`${eventAnalytics}_Badge_Filter`, "Button", "Participant_list");
    setErrorFilterMessage(false);
  };

  const checkNoAssignedBagedHandler = async () => {
    const checkClearButton = await LocalStorage.getStorage("isBadgeClearAllClicked");
    if (checkClearButton) {
      setIsNoBadgeSelected(false);
      setNoBadgesSelect(false);
    } else {
      const check = await LocalStorage.getStorage("noAssignedBadgesSelected");
      check
        ? [setNoBadgesSelect(true), setIsNoBadgeSelected(true)]
        : [setNoBadgesSelect(false), setIsNoBadgeSelected(false)];
    }
  };

  const filterList = (value: any) => {
    let str = value.toLowerCase();
    let newList: any[] = [];
    let tempSet = new Set();
    badgeRoleList
      .filter((item) => item.name.toLowerCase().includes(str))
      .map((name) => {
        return newList.push(name);
      });
    newList.forEach((item) => {
      tempSet.add(item.badgeClassName);
    });
    if (str.length === 0) {
      setExpandedIndexArr(new Set());
    } else {
      setExpandedIndexArr(tempSet);
    }

    const NO_BADGE_ASSIGNED = "No Assigned Badges";
    NO_BADGE_ASSIGNED.toLowerCase().includes(value.toLowerCase())
      ? setDisplayBadgeSwitch(true)
      : setDisplayBadgeSwitch(false);
    if (newList?.length === 0) setDisplayBadgeSwitch(true);
    // setBadgeRoleListsArray(newList);
  };

  const noBadgesSelectHandler = () => {
    let badgeList = [...badgeRoleListsArray];
    badgeList.forEach((item: any) => {
      item.isSelected = false;
      item.children.forEach((child: any) => {
        child.isSelected = false;
      });
    });
    setCheckedBadges([]);
    LocalStorage.setStorage("noAssignedBadgesSelected", true);
    // setBadgeRoleListsArray(list);
    setErrorFilterMessage(false);
    setIsNoBadgeSelected((prevState: boolean) => !prevState);
  };

  const clearFilter = () => {
    LocalStorage.setStorage("isBadgeClearAllClicked", true);

    let badgeList = [...badgeRoleListsArray];
    badgeList.forEach((item: any) => {
      item.isSelected = false;
      item.children.forEach((singleBadge: any) => {
        singleBadge.isSelected = false;
      });
    });
    setNoBadgesSelect(false);
    // removeNoAssignedBadgesLocalStorage();
    googleWebAnalytics(`${eventAnalytics}_Clear_Badge_Filter`, "Button", "Participant_list");
    setBadgeRoleListsArray(badgeList);
    setCheckedBadges([]);
  };

  const removeNoAssignedBadgesLocalStorage = () => {
    setNoBadgesSelect(false);
    LocalStorage.removeStorage("noAssignedBadgesSelected");
    setIsNoBadgeSelected(false);
  };

  return (
    <View style={styles.mainContainer}>
      {badgeRoleList === null || badgeRoleList === undefined ? (
        <Loader customLoadingContainer={styles.customLoadingContainer} />
      ) : dataHelper.isEmptyArray(badgeRoleList) ? (
        <View style={styles.noDataContainer}>
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            {t("common:noData")}
          </Text>
        </View>
      ) : (
        <View>
          <View style={styles.searchContainer}>
            <SearchFilterSeondary filterList={filterList} />
          </View>
          <View>
            <View style={styles.locationListContainer}>
              <ScrollContainer height="290px">
                {badgeRoleListsArray?.map((parentProp: any, parentKey: any) => {
                  return (
                    <View key={parentKey}>
                      <View style={styles.checkBoxContainer}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                          {!expandedIndexArr.has(parentProp.badgeClass) ? (
                            <Icon
                              //closed , outline arrow, item is not presnt in expandedIndexArr
                              name={IconNames.arrowOutlined}
                              customStyle={[styles.titleIcon, styles.alignArrowOutline]}
                              onPress={() => {
                                let expandedIndexArrNewCopy = new Set(expandedIndexArr);
                                expandedIndexArrNewCopy.add(parentProp.badgeClass);
                                setExpandedIndexArr(expandedIndexArrNewCopy);
                              }}
                            />
                          ) : (
                            <Icon
                              name={IconNames.watchVideo}
                              customStyle={[styles.titleIcon, styles.alignExpandButton]}
                              onPress={() => {
                                let expandedIndexArrTempCopy = new Set(expandedIndexArr);
                                expandedIndexArrTempCopy.delete(parentProp.badgeClass);
                                setExpandedIndexArr(expandedIndexArrTempCopy);
                              }}
                            />
                          )}
                          <Checkbox
                            testId={"checkBox"}
                            onPress={() => selectedCheckBoxHandler(parentProp)}
                            selected={parentProp.isSelected}
                            type={CheckboxType.Square}
                            text={parentProp.badgeClass}
                            textSize={TextSize.Small}
                            textFontWeight={FontWeight.Regular}
                            textStyle={styles.textStyleCheckBox}
                            iconSize={18}
                            noOfLines={1}
                          />
                        </View>
                        <View style={{ marginLeft: 50 }}>
                          {expandedIndexArr.has(parentProp.badgeClass) &&
                            parentProp.children.map((childProp: any, childKey: any) => {
                              return (
                                <View key={childKey} style={styles.checkBoxContainer}>
                                  <Checkbox
                                    testId={"checkBox"}
                                    onPress={() => selectedCheckBoxHandler(childProp, parentProp)}
                                    selected={childProp.isSelected}
                                    type={CheckboxType.Square}
                                    text={childProp.name}
                                    textSize={TextSize.Small}
                                    textFontWeight={FontWeight.Regular}
                                    textStyle={styles.textStyleCheckBox}
                                    iconSize={18}
                                    noOfLines={1}
                                  />
                                </View>
                              );
                            })}
                        </View>
                      </View>
                    </View>
                  );
                })}
                {displayBadgeSwitch && (
                  <View style={{ marginLeft: 26, marginTop: 10 }}>
                    <Checkbox
                      testId={"checkBox"}
                      onPress={() => [noBadgesSelectHandler(), setNoBadgesSelect(!noBadgesSelect)]}
                      selected={noBadgesSelect}
                      type={CheckboxType.Square}
                      text={"No Assigned Badges"}
                      textSize={TextSize.Small}
                      textFontWeight={FontWeight.Regular}
                      textStyle={styles.textStyleCheckBox}
                      iconSize={18}
                      noOfLines={1}
                    />
                  </View>
                )}
              </ScrollContainer>
            </View>
            <View style={[styles.header]}>
              <View style={[styles.buttonContainer, styles.alignClearButton]}>
                <Button
                  type={ButtonType.Secondary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Clear"
                  onPress={() => clearFilter()}
                />
              </View>
              {/* <View style={styles.buttonContainer}>
                <Button
                  type={ButtonType.Primary}
                  buttonStyles={styles.button}
                  textStyles={styles.buttonFont}
                  title="Apply"
                  onPress={() => [applyFilter()]}
                />
              </View>  */}
            </View>
          </View>
        </View>
      )}
    </View>
  );
});

export default BadgeFilter;

const styles = StyleSheet.create({
  mainContainer: {},
  searchContainer: {
    marginBottom: 15
  },

  // Loader
  customLoadingContainer: {
    height: 300
  },

  // Check Box LocationList
  locationListContainer: {
    height: 300
  },
  checkBoxContainer: {
    marginTop: 6
  },
  textStyleCheckBox: {
    marginStart: 15
  },

  // No Data Container
  noDataContainer: {
    height: 200
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    fontWeight: "700"
    // marginBottom: 5
  },

  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  alignClearButton: {
    marginRight: 110,
    marginTop: 11
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },
  titleIcon: {
    fontSize: 16,
    marginRight: 10
  },
  alignArrowOutline: {
    color: colorPallete.cordioTaupeLight3
  },
  alignExpandButton: {
    transform: [{ rotate: "90deg" }],
    fontSize: 8,
    marginLeft: 5,
    marginRight: 15
  }
});
