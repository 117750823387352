import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { toast } from "react-toastify";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
// import QualificationPackDetailsModal from "@socion-cordio/common/src/components/organisms/qualificationPackDetailsModal";
// import AddQualificationPackModal from "@socion-cordio/common/src/components/organisms/addQualificationPackModal";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { useDispatch, useSelector } from "react-redux";
import { QualificationPackActions } from "@socion-cordio/common/src/modules/qualificationPacks/actions/actions";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";

interface Props {
  programDetails?: any;
  userRoles?: any;
}

export default function QualificationPackTable(props: Props): ReactElement {
  const [qualificationPack, setQualificationPack] = useState([]);
  const [addQualification, setAddQualification] = useState(false);
  const [packCompletion, setPackCompletion] = useState(false);
  const [bundleId, setBundleId] = useState(null);
  const [qualificationPackDetails, setQualificationPackDetails] = useState(null);
  const { programDetails, userRoles } = props;
  const history: any = useHistory();
  const dispatch = useDispatch();

  const {
    qualificationPackData: qpList,
    loading: { getQualificationPackList: loading }
  } = useSelector((state: any) => state.bundleData);

  useEffect(() => {
    getQualificationPackList();
  }, []);

  useEffect(() => {
    getBadgesTable();
  }, [qpList]);

  const getQualificationPackList = () => {
    const { program } = programDetails;
    dispatch(QualificationPackActions.getQualficationPackList(program.id));
  };

  const getBadgesTable = async () => {
    const newList = await sortHandler(qpList?.response);
    newList?.forEach((qualiPack: any, index: number) => {
      qualiPack.serialNo = `${index + 1}.`;
      qualiPack.status = qualiPack?.bundle?.published ? "Published" : "Draft";
      qualiPack.bundle.dateOfCreation = dataHelper.formatDate(qualiPack?.bundle?.createdAt);
    });
    setQualificationPack(newList);
  };

  const sortHandler = (list: any) => {
    // sort by date
    list?.sort((a: any, b: any) => {
      return b.bundle.createdAt - a.bundle.createdAt;
    });
    return list;
  };

  useEffect(() => {
    if (bundleId) getQualificationPackData();
  }, [bundleId]);

  const viewQualificationPackHandler = (qpBundleId: number) => {
    history.push(
      `${routeNames.app}${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWQUALIFICATIONPACKDETAILS}`,
      {
        qpBundleId: qpBundleId,
        qualificationPackDetails: qualificationPackDetails,
        qualificationPackList: qpList?.response,
        userRoles: userRoles
      }
    );
    googleWebAnalytics("QP_View_QP_Details", "Button", "Qualification_Pack");
  };

  const getQualificationPackData = async () => {
    const qualificationPackResponse = await ApiClient.get(
      qualificationPackEndPoints.getQualificationPackBundleDetails(bundleId)
    );
    try {
      setQualificationPackDetails(qualificationPackResponse?.response);
    } catch (error) {
      toast.error(qualificationPackResponse.message);
    }
  };
  const navigivateViewDetailsHandler = async (qualificationPack: any) => {
    const getQpAccessResponse = await ApiClient.get(
      qualificationPackEndPoints.getQPAccessStatus(qualificationPack.bundle.id)
    );
    if (getQpAccessResponse) {
      history.push(
        `${routeNames.app}${routeNames.QUALIFICATIONPACKS}${routeNames.VIEWQUALIFICATIONPACKS}`,
        {
          packDetails: qualificationPack
        }
      );
      googleWebAnalytics("QP_View_QP_Completion_Details", "Button", "Qualification_Pack");
    } else {
      toast.error("Processingg QP.Try again after sometime");
    }
  };

  const addQualficationPack = () => {
    history.push(
      `${routeNames.app}${routeNames.QUALIFICATIONPACKS}${routeNames.ADDQUALIFICATIONPACK}`,
      {
        qualificationPackList: qualificationPack,
        qpAllList: qpList.response
      }
    );
    googleWebAnalytics("QP_Add_Qualification_Pack", "Button", "Qualification_Pack");
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          textSize: TextSize.Small,
          color: colorPallete.textBlack,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, qualiPack?.serialNo);
        }
      },
      {
        Header: "Qualification Pack",
        accessor: "bundle.name",
        width: 225,
        maxWidth: 225,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          return cellData("name", style, qualiPack?.bundle?.name, () => {
            // handleQualificationPack(qualiPack?.bundle?.id);
            viewQualificationPackHandler(qualiPack?.bundle?.id);
          });
        }
      },
      {
        Header: "Date of creation",
        accessor: "bundle.createdAt",
        width: 85,
        maxWidth: 85,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};

          return cellData("createdAt", style, qualiPack?.bundle?.dateOfCreation);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 85,
        maxWidth: 85,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};
          return (
            <View style={styles.flexContainer}>
              {qualiPack?.bundle?.published ? (
                <View style={styles.publish}></View>
              ) : (
                <View style={styles.draft}></View>
              )}
              <Text
                fontWeight={FontWeight.Regular}
                testId="headerText"
                textSize={TextSize.Small}
                textStyle={styles.status}
              >
                {qualiPack?.status}
              </Text>
            </View>
          );
        }
      },
      {
        Header: "Selections",
        accessor: "selectionCount",
        width: 75,
        maxWidth: 75,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {};

          return cellData("createdAt", style, qualiPack?.selectionCount);
        }
      },
      {
        Header: "Pack completion",
        accessor: "packCompletion",
        width: 85,
        maxWidth: 85,
        disableSortBy: true,
        Cell: ({ row: { original: qualiPack } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = () => {
            navigivateViewDetailsHandler(qualiPack);
          };
          const qpSelectionCount = qualiPack?.selectionCount;
          const viewDetails = qpSelectionCount !== 0 ? "View Details" : null;
          return cellData("name", style, viewDetails, onPress);
        }
      }
    ],
    []
  );

  const searchbarAndAddQp = () => (
    <View style={styles.mainSearchBarContainer}>
      <View style={styles.searchBarContainer}>
        <View pointerEvents={"none"}>
          <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
        </View>
        <View>
          {userRoles?.includes("PROGRAM_ADMIN") && (
            <TouchableOpacity onPress={() => addQualficationPack()}>
              <Image
                testId="primaryAddImg"
                source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                imageStyle={{
                  width: 30,
                  height: 30
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={styles.headerText}
      >
        {"There are no Qualification Pack created for this program"}
      </Text>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : (
        <>
          {qualificationPack?.length === 0 ? (
            <View>{searchbarAndAddQp()}</View>
          ) : (
            <View>
              <Table
                columns={columns}
                eventAnalytics="QualPack"
                data={qualificationPack !== undefined ? qualificationPack : []}
                addIcon={
                  userRoles?.includes("PROGRAM_ADMIN") && (
                    <TouchableOpacity onPress={() => addQualficationPack()}>
                      <Image
                        testId="primaryAddImg"
                        source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                        imageStyle={{
                          width: 30,
                          height: 30
                        }}
                      />
                    </TouchableOpacity>
                  )
                }
              />
            </View>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  addRoleButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  noFailedDataContainer: {
    padding: 25
  },
  customFontAlign: {},

  // Search bar disabled
  mainSearchBarContainer: {
    height: "calc(100vh - 150px)",
    padding: 25
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 25
  },
  customWidthStyle: {
    width: 400
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  status: {
    fontFamily: FontFamily.Regular,
    fontSize: 12,
    color: colorPallete.textBlack
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  publish: {
    backgroundColor: "#3FA202",
    height: 8,
    width: 8,
    borderRadius: 10,
    marginRight: 8,
    marginTop: 8
  },
  draft: {
    backgroundColor: "#DB4437",
    height: 8,
    width: 8,
    borderRadius: 10,
    marginRight: 8,
    marginTop: 8
  }
});
