import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  FontFamily,
  FontWeight,
  Text,
  TextSize
} from "@socion-cordio/common/src/components/atoms/text";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { registryEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { EntityActions } from "@socion-cordio/common/src/modules/registry/entity/actions/actions";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { TABS, STATUS, REGISTRY_TYPE } from "@socion-cordio/common/src/constants/registry";
import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import Moment from "moment";
import { isEmpty } from "lodash";
import { EntityRepository } from "@socion-cordio/common/src/repositories/registry/entity/entity";
import { UserHelper } from "@socion-cordio/common/src/utils/userHelper";
import { UploadFileHelper } from "@socion-cordio/common/src/utils/registryHelpers/uploadFileHelper";
import { DocsRepository } from "@socion-cordio/common/src/repositories/registry/docs/docs";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import Dropdown from "@socion-cordio/common/src/components/molecules/dropdown";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import {
  PDACountryList,
  PDAEmployeeCountList,
  PDALegalStatusList,
  PDAStateList
} from "@socion-cordio/common/src/constants/country";

interface Props {
  onClose: Function;
  dispatchEvent?: Function;
  selectedInvite?: any;
  // entityNames: string[];
}

export default function CreateEntityModal(props: Props) {
  let countryList = PDACountryList || [];

  let stateList = PDAStateList || [];

  let legalStatusList = PDALegalStatusList || [];

  const { onClose, dispatchEvent } = props;
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const [docsArray, setDocsArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entityDocuments, setEntityDocuments] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isEmpDropdownOpen, setIsEmpDropdownOpen] = useState(false);
  const [isLegalStatusDropdownOpen, setIsLegalStatusDropdownOpen] = useState(false);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [empItems, setEmpItems] = useState(PDAEmployeeCountList);
  const [countryItems, setCountryItems] = useState(countryList);
  const [legalStatusItems, setLegalStatusItems] = useState(legalStatusList);
  const [stateItems, setStateItems] = useState(stateList);
  const [val, setVal] = useState("1");
  const [contryvalue, setCountryValue] = useState("1");
  const [legalStatusValue, setLegalStatusValue] = useState("1");
  const [stateValue, setStateValue] = useState("1");
  const [containerWidth, setContainerWidth] = useState(null);
  const [entityName, setEntityName] = useState(null);
  const [isEntityNameExists, setIsEntityNameExists] = useState(null);
  const [entityNames, setEntityNames] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  let formInitValues: any = {
    name: "",
    registrationNumber: "",
    taxRegNumber: "",
    legalStatus: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    websiteUrl: "",
    noOfEmployees: "",
    mission: "",
    status: "active",
    createdBy: UserHelper.getUserName(),
    userId: UserHelper.getUserId()
  };

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const formValidaionSchema = Yup.object({
    name: Yup.string().required("Required"),
    registrationNumber: Yup.string().required("Required"),
    taxRegNumber: Yup.string().required("Required"),
    legalStatus: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    pinCode: Yup.string().required("Required").length(6)
  });

  useEffect(() => {
    getExistingEntities();
  }, []);

  const getExistingEntities = async () => {
    const listData = await EntityRepository.getAllEntities().catch((err) => {
      console.log("Error while fetching all entities", err);
    });
    const items: any[] = listData || [];
    const names = items.map((item) => item.name.toLowerCase());
    setEntityNames(names);
    setInitialValues(formInitValues);
  };

  const handleInvite = async (values: any) => {
    setSaveLoader(true);

    const createEntityRes = await EntityRepository.createEntity(values).catch((err) => {
      if (err.errmsg && err.errmsg.toLowerCase() === "entity exist with same name") {
        toast.error(err.errmsg);
        for (let key in values) {
          formInitValues[key] = values[key];
        }
        setInitialValues(formInitValues);
      } else {
        closePopup();
      }
      setSaveLoader(false);
    });

    if (!isEmpty(createEntityRes) && docsArray?.length > 0) {
      const entityId = createEntityRes?.osid;

      console.log("entityDocsuemnsams", entityDocuments, docsArray);
      const uploadRes = await UploadFileHelper.upload(
        `${REGISTRY_TYPE.entity}/${entityId}`,
        docsArray,
        entityId,
        REGISTRY_TYPE.entity
      ).catch((err) => {
        console.log("Error while Uploading Docs", err);
        closePopup();
      });

      if (uploadRes) {
        closePopup();
      }
    } else {
      closePopup();
    }
  };

  const closePopup = () => {
    setSaveLoader(false);
    onClose();
    dispatchEvent(TABS.Entity);
  };

  const handleCustomChange = async (e: any) => {
    let fileNamesArray: any = [];
    docsArray.map((i) => {
      fileNamesArray.push(i.name);
    });
    if (fileNamesArray.includes(e.target.files[0].name)) {
      toast.error("Document already added");
      return;
    }
    const validTypes = ["mp4", "mpeg", "mov", "flv", "png", "jpg", "jpeg", "pdf"];
    const splitFileName = e?.currentTarget?.files[0]?.name.split(".");
    const extension = splitFileName[splitFileName.length - 1].toLowerCase();
    if (e.target.validity.valid && validTypes.includes(extension)) {
      setLoading(true);
      let docs = [...docsArray];
      docs.push(e?.currentTarget?.files[0]);
      setDocsArray(docs);
      setLoading(false);
    } else {
      toast.error(
        "Invalid File Type! Only Files with format of mp4, mpeg, mov, flv, png, jpg, jpeg, pdf will be uploaded!!"
      );
    }
  };

  const getDocName = (document: string) => {
    let docName = undefined;
    if (document.includes("!-!-")) {
      let splitDocName = document.split("!-!-");
      docName = splitDocName[splitDocName.length - 1];
    } else if (document.includes("Qualification-Docs")) {
      let splitDocName = document.split("Qualification-Docs/");
      docName = splitDocName[splitDocName.length - 1];
    } else {
      let splitDocName = document.split("--._.--");
      docName = splitDocName[splitDocName.length - 1];
    }
    return docName;
  };

  const handleDeleteDocument = (index: any) => {
    let tempArray = [...docsArray];
    tempArray.splice(index, 1);
    entityDocuments.splice(index, 1);
    setDocsArray(tempArray);
  };

  const handleOnClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const getDate = (date?: Date) => {
    const fDate: Date = date ? date : new Date();
    return fDate.toISOString().toString().replace("T", " ").replace("Z", "");
  };

  const onChangeValue = async (formik: any) => {
    if (empItems !== null || empItems?.length > 0) {
      const selectedEmpCount = empItems.filter((item: any) => item?.label === val);
      if (selectedEmpCount?.length > 0) {
        // formik.handleChange("noOfEmployees");
        setContainerWidth(selectedEmpCount[0].label.length);
        formik.setFieldValue("noOfEmployees", selectedEmpCount[0].label);
        formik.setFieldTouched("noOfEmployees", true);
      }
    }
  };

  const onCountryChangeValue = async (formik: any) => {
    if (countryItems !== null || countryItems?.length > 0) {
      const selectedCountryCount = countryItems.filter((item: any) => item?.label === contryvalue);
      if (selectedCountryCount?.length > 0) {
        setContainerWidth(selectedCountryCount[0].label.length);
        formik.setFieldValue("country", selectedCountryCount[0].label);
        formik.setFieldTouched("country", true);

        if (formik.values["registrationNumber"]) {
          formik.setFieldValue("registrationNumber", "");
          formik.setFieldTouched("registrationNumber", true);
        }
        if (formik.values["taxRegNumber"]) {
          formik.setFieldValue("taxRegNumber", "");
          formik.setFieldTouched("taxRegNumber", true);
        }
        if (formik.values["address"]) {
          formik.setFieldValue("address", "");
          formik.setFieldTouched("address", true);
        }

        if (formik.values["address2"]) {
          formik.setFieldValue("address2", "");
          formik.setFieldTouched("address2", true);
        }

        if (formik.values["city"]) {
          formik.setFieldValue("city", "");
          formik.setFieldTouched("city", true);
        }

        if (formik.values["state"]) {
          formik.setFieldValue("state", "");
          formik.setFieldTouched("state", true);
        }

        if (formik.values["country"]) {
          formik.setFieldValue("country", "");
          formik.setFieldTouched("country", true);
        }

        if (formik.values["pinCode"]) {
          formik.setFieldValue("pinCode", "");
          formik.setFieldTouched("pinCode", true);
        }

        if (formik.values["legalStatus"]) {
          formik.setFieldValue("legalStatus", "");
          formik.setFieldTouched("legalStatus", true);
        }
      }
    }
  };

  const onLegalStatusChangeValue = async (formik: any) => {
    if (legalStatusItems !== null || legalStatusItems?.length > 0) {
      const selectedLegalStatusCount = legalStatusItems.filter(
        (item: any) => item?.label === legalStatusValue
      );
      if (selectedLegalStatusCount?.length > 0) {
        setContainerWidth(selectedLegalStatusCount[0].label.length);
        formik.setFieldValue("legalStatus", selectedLegalStatusCount[0].label);
        formik.setFieldTouched("legalStatus", true);
        console.log("formikformik", formik);
      }
    }
  };

  const onStateChangeValue = async (formik: any) => {
    if (stateItems !== null || stateItems?.length > 0) {
      const selectedStateCount = stateItems.filter((item: any) => item?.label === stateValue);
      if (selectedStateCount?.length > 0) {
        setContainerWidth(selectedStateCount[0].label.length);
        formik.setFieldValue("state", selectedStateCount[0].label);
        formik.setFieldTouched("state", true);
      }
    }
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{
        width: 550,
        height: 500
      }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <>
        {saveLoader ? (
          <Loader customLoadingContainer={styles.fullModalLoader} />
        ) : (
          <View style={styles.container}>
            <ToastContainer />
            <View style={styles.modalHeader}>
              <Text
                fontWeight={FontWeight.Medium}
                testId="addEmailText"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {"Register Entity"}
              </Text>
              <View>
                <Pressable onPress={() => onClose()}>
                  <Icon testID="close" name={IconNames.crossCircle} />
                </Pressable>
              </View>
            </View>

            <Formik
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                console.log("PG_HOST valuesvalues", values);

                if (!isEntityNameExists) {
                  handleInvite(values);
                }
              }}
              validationSchema={formValidaionSchema}
              enableReinitialize={true}
            >
              {(
                formikProps: FormikProps<{
                  name: string;
                  registrationNumber: string;
                  taxRegNumber: string;
                  legalStatus: string;
                  address: string;
                  address2: string;
                  city: string;
                  state: string;
                  country: string;
                  pinCode: string;
                  websiteUrl: string;
                  noOfEmployees: string;
                  mission: string;
                  status: string;
                  createdBy: string;
                  userId: string;
                }>
              ) => (
                <Form>
                  <View>
                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Entity Name
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={(value: any) => {
                              formikProps.handleChange("name");
                              formikProps.setFieldValue("name", value);
                              setEntityName(value);
                              if (value && entityNames.includes(value.toLowerCase())) {
                                formikProps.setFieldError("name", "Entity with same name exists");
                                setIsEntityNameExists(true);
                              } else {
                                setIsEntityNameExists(false);
                              }
                            }}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.name}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter entity name"
                            name="name"
                            id="name"
                            editable={true}
                            formikPropsTouched={
                              formikProps.touched.name === undefined
                                ? false
                                : formikProps.touched.name
                            }
                            customChange={() => {
                              formikProps.setFieldTouched("name", false);
                            }}
                          />
                          {entityName && entityNames.includes(entityName.toLowerCase()) && (
                            <Text
                              fontWeight={FontWeight.Regular}
                              testId="qualificationTitleText"
                              textSize={TextSize.Small}
                              textStyle={[styles.subheaderText, { color: "red" }]}
                            >
                              Entity name already exists
                            </Text>
                          )}
                        </View>
                      </View>
                    </View>
                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Country of Incorporation
                          </Text>
                        </View>

                        <View
                          style={[
                            styles.contentContainer,
                            { marginBottom: 11.5 },
                            styles.alignAddressText
                          ]}
                        >
                          <Dropdown
                            open={isCountryDropdownOpen}
                            dropdownValue={contryvalue}
                            items={countryItems}
                            setOpen={setIsCountryDropdownOpen}
                            setValue={setCountryValue}
                            setItems={setCountryItems}
                            dropdownPlaceholder={"Select the country"}
                            onChangeValue={(value) => onCountryChangeValue(formikProps)}
                            containerWidthStyle={[
                              styles.containerStyle,
                              containerWidth <= 50
                                ? { width: "44ch" }
                                : containerWidth > 100 && containerWidth <= 200
                                ? { width: "92ch" }
                                : { width: "90%" }
                            ]}
                            containerRadius={styles.dropDownContainerStyle}
                            pickerStyle={styles.alignTextHeader}
                            labelStyles={styles.labelStyles}
                            placeholderStyle={styles.placeholderStyle}
                            customItemContainerStyle={{
                              ...styles.customItemContainerStyle,
                              backgroundColor: isCountryDropdownOpen ? "#fff" : "transparent"
                            }}
                            listItems={styles.listItems}
                            listItemLabelStyle={styles.listItemLabelStyle}
                            arrowIconStyle={styles.arrowIconStyle}
                            closeAfterSelecting={true}
                            // programDropDown={true}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -1 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, { marginTop: 4 }]}
                          >
                            Business Registration No.
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("registrationNumber")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.registrationNumber}
                            inputStyle={[
                              styles.inputMobile,
                              styles.entityContainer,
                              { width: "100%" }
                            ]}
                            placeholder={
                              contryvalue === "India"
                                ? "Enter PAN number of your entity"
                                : "Enter Busisness registration number"
                            }
                            // placeholder="Enter Business registration number"
                            name="registrationNumber"
                            id="registrationNumber"
                            formikPropsTouched={
                              formikProps.touched.registrationNumber === undefined
                                ? false
                                : formikProps.touched.registrationNumber
                            }
                            customChange={() =>
                              formikProps.setFieldTouched("registrationNumber", false)
                            }
                          />
                        </View>
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -1 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, { marginTop: 4 }]}
                          >
                            Tax Registration No.
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("taxRegNumber")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.taxRegNumber}
                            inputStyle={[
                              styles.inputMobile,
                              styles.entityContainer,
                              { width: "100%" }
                            ]}
                            placeholder={
                              contryvalue === "India"
                                ? "Enter GSTN number of your entity"
                                : "Enter tax registration number"
                            }
                            name="taxRegNumber"
                            id="taxRegNumber"
                            formikPropsTouched={
                              formikProps.touched.taxRegNumber === undefined
                                ? false
                                : formikProps.touched.taxRegNumber
                            }
                            customChange={() => formikProps.setFieldTouched("taxRegNumber", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -1 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText]}
                          >
                            Legal Status
                          </Text>
                        </View>
                        {contryvalue === "India" ? (
                          <View
                            style={[
                              styles.contentContainer,
                              { marginBottom: 12 },
                              styles.alignAddressText
                            ]}
                          >
                            <Dropdown
                              open={isLegalStatusDropdownOpen}
                              dropdownValue={legalStatusValue}
                              items={legalStatusItems}
                              setOpen={setIsLegalStatusDropdownOpen}
                              setValue={setLegalStatusValue}
                              setItems={setLegalStatusItems}
                              dropdownPlaceholder={"Select Legal Status"}
                              onChangeValue={(value) => onLegalStatusChangeValue(formikProps)}
                              containerWidthStyle={[
                                styles.containerStyle,
                                containerWidth <= 50
                                  ? { width: "44ch" }
                                  : containerWidth > 100 && containerWidth <= 200
                                  ? { width: "92ch" }
                                  : { width: "90%" }
                              ]}
                              containerRadius={styles.dropDownContainerStyle}
                              pickerStyle={styles.alignTextHeader}
                              labelStyles={styles.labelStyles}
                              placeholderStyle={styles.placeholderStyle}
                              customItemContainerStyle={{
                                ...styles.customItemContainerStyle,
                                backgroundColor: isLegalStatusDropdownOpen ? "#fff" : "transparent"
                              }}
                              listItems={styles.listItems}
                              listItemLabelStyle={styles.listItemLabelStyle}
                              arrowIconStyle={styles.arrowIconStyle}
                              closeAfterSelecting={true}
                              // programDropDown={true}
                            />
                          </View>
                        ) : (
                          <View style={styles.contentContainer}>
                            <TextField
                              label=""
                              handleChange={formikProps.handleChange("legalStatus")}
                              handleBlur={() => formikProps.setFieldTouched}
                              value={formikProps.values.legalStatus}
                              inputStyle={[styles.inputMobile, styles.entityContainer]}
                              placeholder="Enter legal status"
                              name="legalStatus"
                              id="legalStatus"
                              formikPropsTouched={
                                formikProps.touched.legalStatus === undefined
                                  ? false
                                  : formikProps.touched.legalStatus
                              }
                              customChange={() => formikProps.setFieldTouched("legalStatus", false)}
                            />
                          </View>
                        )}
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -2 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Address Line 1
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("address")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.address}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter address"
                            name="address"
                            id="address"
                            formikPropsTouched={
                              formikProps.touched.address === undefined
                                ? false
                                : formikProps.touched.address
                            }
                            customChange={() => formikProps.setFieldTouched("address", false)}
                          />
                        </View>
                      </View>
                    </View>
                    <View style={[styles.titleContainer, { zIndex: -2 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Address Line 2
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("address2")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.address2}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter address2"
                            name="address2"
                            id="address2"
                            formikPropsTouched={
                              formikProps.touched.address2 === undefined
                                ? false
                                : formikProps.touched.address2
                            }
                            customChange={() => formikProps.setFieldTouched("address2", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -2 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            City
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("city")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.city}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter city"
                            name="city"
                            id="city"
                            formikPropsTouched={
                              formikProps.touched.city === undefined
                                ? false
                                : formikProps.touched.city
                            }
                            customChange={() => formikProps.setFieldTouched("city", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={styles.titleContainer}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, { marginRight: 55 }]}
                          >
                            State
                          </Text>
                        </View>
                        {contryvalue === "India" ? (
                          <View
                            style={[
                              styles.contentContainer,
                              { marginBottom: 12 },
                              styles.alignAddressText
                            ]}
                          >
                            <Dropdown
                              open={isStateDropdownOpen}
                              dropdownValue={stateValue}
                              items={stateItems}
                              setOpen={setIsStateDropdownOpen}
                              setValue={setStateValue}
                              setItems={setStateItems}
                              dropdownPlaceholder={"Select State"}
                              onChangeValue={(value) => onStateChangeValue(formikProps)}
                              containerWidthStyle={[
                                styles.containerStyle,
                                containerWidth <= 50
                                  ? { width: "44ch" }
                                  : containerWidth > 100 && containerWidth <= 200
                                  ? { width: "92ch" }
                                  : { width: "90%" }
                              ]}
                              containerRadius={styles.dropDownContainerStyle}
                              pickerStyle={styles.alignTextHeader}
                              labelStyles={styles.labelStyles}
                              placeholderStyle={styles.placeholderStyle}
                              customItemContainerStyle={{
                                ...styles.customItemContainerStyle,
                                backgroundColor: isStateDropdownOpen ? "#fff" : "transparent"
                              }}
                              listItems={styles.listItems}
                              listItemLabelStyle={styles.listItemLabelStyle}
                              arrowIconStyle={styles.arrowIconStyle}
                              closeAfterSelecting={true}
                              // programDropDown={true}
                            />
                          </View>
                        ) : (
                          <View style={styles.contentContainer}>
                            <TextField
                              label=""
                              handleChange={formikProps.handleChange("state")}
                              handleBlur={() => formikProps.setFieldTouched}
                              value={formikProps.values.state}
                              inputStyle={[styles.inputMobile, styles.entityContainer]}
                              placeholder="Enter state"
                              name="state"
                              id="state"
                              formikPropsTouched={
                                formikProps.touched.state === undefined
                                  ? false
                                  : formikProps.touched.state
                              }
                              customChange={() => formikProps.setFieldTouched("state", false)}
                            />
                          </View>
                        )}
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -1 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={[styles.subheaderText, { marginRight: 10 }]}
                          >
                            Pin/Zip code
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("pinCode")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.pinCode}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter pin/zip code"
                            name="pinCode"
                            id="pinCode"
                            formikPropsTouched={
                              formikProps.touched.pinCode === undefined
                                ? false
                                : formikProps.touched.pinCode
                            }
                            customChange={() => formikProps.setFieldTouched("pinCode", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -1 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Website
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("websiteUrl")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.websiteUrl}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter website url"
                            name="websiteUrl"
                            id="websiteUrl"
                            formikPropsTouched={
                              formikProps.touched.websiteUrl === undefined
                                ? false
                                : formikProps.touched.websiteUrl
                            }
                            customChange={() => formikProps.setFieldTouched("websiteUrl", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -1 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Number of Employees
                          </Text>
                        </View>

                        <View
                          style={[
                            styles.contentContainer,
                            { marginBottom: 11.5 },
                            styles.alignAddressText
                          ]}
                        >
                          <Dropdown
                            open={isEmpDropdownOpen}
                            dropdownValue={val}
                            items={empItems}
                            setOpen={setIsEmpDropdownOpen}
                            setValue={setVal}
                            setItems={setEmpItems}
                            dropdownPlaceholder={"Number of Employees"}
                            onChangeValue={(value) => onChangeValue(formikProps)}
                            containerWidthStyle={[
                              styles.containerStyle,
                              containerWidth <= 50
                                ? { width: "44ch" }
                                : containerWidth > 100 && containerWidth <= 200
                                ? { width: "92ch" }
                                : { width: "90%" }
                            ]}
                            containerRadius={styles.dropDownContainerStyle}
                            pickerStyle={styles.alignTextHeader}
                            labelStyles={styles.labelStyles}
                            placeholderStyle={styles.placeholderStyle}
                            customItemContainerStyle={styles.customItemContainerStyle}
                            listItems={styles.listItems}
                            listItemLabelStyle={styles.listItemLabelStyle}
                            arrowIconStyle={styles.arrowIconStyle}
                            closeAfterSelecting={true}
                            // programDropDown={true}
                          />
                          {/* <TextField
                            label=""
                            handleChange={formikProps.handleChange("noOfEmployees")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.noOfEmployees}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter Number of Employees"
                            name="noOfEmployees"
                            id="noOfEmployees"
                            formikPropsTouched={
                              formikProps.touched.noOfEmployees === undefined
                                ? false
                                : formikProps.touched.noOfEmployees
                            }
                            customChange={() => formikProps.setFieldTouched("noOfEmployees", false)}
                          /> */}
                        </View>
                      </View>
                    </View>

                    <View style={[styles.titleContainer, { zIndex: -2 }]}>
                      <View style={styles.textFieldContianer}>
                        <View style={styles.labelTextContainer}>
                          <Text
                            fontWeight={FontWeight.Regular}
                            testId="qualificationTitleText"
                            textSize={TextSize.Small}
                            textStyle={styles.subheaderText}
                          >
                            Mission
                          </Text>
                        </View>
                        <View style={styles.contentContainer}>
                          <TextField
                            label=""
                            handleChange={formikProps.handleChange("mission")}
                            handleBlur={() => formikProps.setFieldTouched}
                            value={formikProps.values.mission}
                            inputStyle={[styles.inputMobile, styles.entityContainer]}
                            placeholder="Enter Mission"
                            name="mission"
                            id="mission"
                            formikPropsTouched={
                              formikProps.touched.mission === undefined
                                ? false
                                : formikProps.touched.mission
                            }
                            customChange={() => formikProps.setFieldTouched("mission", false)}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={[styles.lineThrough, { zIndex: -2 }]}></View>
                    <View style={[styles.flexContainer, { zIndex: -1 }]}>
                      <Text
                        fontWeight={FontWeight.Regular}
                        testId="qualificationTitleText"
                        textSize={TextSize.Small}
                        textStyle={[styles.uploadHeaderText, { fontSize: 10 }]}
                      >
                        Documents
                      </Text>
                      <TouchableOpacity style={styles.addDocument} onPress={handleClick}>
                        <Icon
                          testID="primaryAddImg"
                          name={IconNames.uploadFile}
                          customStyle={styles.uploadDocIcon}
                        />
                        {/* <Image
                          testId="primaryAddImg"
                          source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                          imageStyle={{
                            width: 28,
                            height: 28
                          }}
                        /> */}
                        <input
                          style={{
                            display: "none",
                            zIndex: -1
                          }}
                          ref={hiddenFileInput}
                          type="file"
                          onChange={async (e) => {
                            await handleCustomChange(e);
                          }}
                          onClick={handleOnClick}
                        />
                      </TouchableOpacity>
                    </View>
                    {docsArray.length > 0 && (
                      <View style={styles.docsContainer}>
                        {loading ? (
                          <Loader customLoadingContainer={styles.loaderContiner} />
                        ) : (
                          <ScrollContainer
                            height="100px"
                            scrollable={docsArray.length > 1 ? true : false}
                          >
                            {docsArray?.map((item: any, index: number) => (
                              <View style={styles.qualificationContainer} key={index}>
                                <View style={styles.docNameContainer}>
                                  <Text
                                    fontWeight={FontWeight.Regular}
                                    testId="qualificationTitleText"
                                    textSize={TextSize.Small}
                                    textStyle={styles.qualificationTitle}
                                  >
                                    {getDocName(item?.name)}
                                  </Text>
                                </View>
                                <Icon
                                  testID="close"
                                  name={IconNames.deleteFile}
                                  customStyle={styles.uploadDocIcon}
                                  onPress={() => handleDeleteDocument(index)}
                                />
                              </View>
                            ))}
                          </ScrollContainer>
                        )}
                      </View>
                    )}
                    <View style={[styles.buttonContainer, { zIndex: -2 }]}>
                      <Button
                        type={ButtonType.Primary}
                        buttonStyles={styles.button}
                        textStyles={styles.buttonFont}
                        // disabled={
                        //   isEmpty(formikProps.touched) || !isEmpty(formikProps.errors || disable)
                        // }
                        title="Save"
                        onPress={() => {
                          formikProps.handleSubmit();
                        }}
                      />
                    </View>
                  </View>
                </Form>
              )}
            </Formik>
          </View>
        )}
      </>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    // width: 655,
    width: 550
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 25
  },
  headerText: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17
  },
  uploadPictureContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
    // marginTop: "1rem"
  },
  uploadPicture: {
    width: "450px",
    height: "300px",
    backgroundColor: "#F8F8F8",
    borderRadius: 5
  },
  uploadPictureText: {
    margin: "auto",
    color: colorPallete.textBlack,
    fontSize: 14,
    opacity: 0.5
  },
  customUpload: {
    alignSelf: "center",
    opacity: 0.8
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 50,
    minHeight: 65,
    marginBottom: 8
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight,
    marginTop: 13
  },
  textFieldContianer: {
    flexDirection: "row",
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  labelTextContainer: {
    flex: 1,
    marginBottom: 10
    // flex: 30
  },
  contentContainer: {
    marginBottom: 2,
    flex: 2.5
    // flex: 70
  },
  labelSecondaryContainer: {
    flexDirection: "row"
  },
  labelTextSecondaryContainer: {
    flex: 28
  },
  contentSecondaryContainer: {
    flex: 58
  },
  labelTextSmallContainer: {
    flex: 26
  },
  contentSmallContainer: {
    flex: 50
  },
  textFieldContainer: {
    flex: 36
  },
  textInputContainer: {
    flex: 40,
    alignItems: "center"
  },
  flex50Container: {
    flex: 50
  },
  alignContentContainer: {
    flex: 80,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  subTextFieldContianer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  inputMobile: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textBlack,
    // fontWeight: "400",
    width: "100%"
  },
  entityContainer: {
    fontFamily: FontFamily.Medium,
    fontWeight: "400",
    padding: 0
  },
  alignInputFieldMedium: {
    width: "70%"
  },
  alignHeader: {
    marginTop: 13
  },
  uploadHeaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textLight
  },
  button: {
    width: "140px",
    height: "50px",
    borderRadius: 10
  },
  disabledButton: {},
  buttonFont: {
    fontSize: 12
  },
  alignAddressText: {
    paddingLeft: 5,
    marginTop: 5,
    borderRadius: 5,
    borderColor: "#c1bcb9",
    borderStyle: "solid",
    borderWidth: 1
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "12px"
  },
  iconStyle: {
    color: colorPallete.cordioTaupe,
    marginLeft: 5,
    marginRight: 5,
    fontSize: 15
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  memberInfoContainer: {
    flex: 80
  },
  alignText: {
    fontSize: 12,
    color: colorPallete.textLight,
    marginTop: 10,
    FontFamily: FontFamily.Medium,
    // fontWeight:'900'
    fontWeight: "400"
  },
  textColor: {
    color: colorPallete.textBlack,
    fontFamily: FontFamily.Regular,
    fontWeight: "700"
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 25,
    width: "680px"
  },
  listMemberContainer: {},
  addDocument: {
    alignSelf: "flex-end",
    float: "right",
    marginLeft: "auto"
  },
  uploadDocIcon: {
    opacity: 0.5
  },
  docsContainer: {
    marginBottom: 10
  },
  qualificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 5,
    // height: "80px",
    marginRight: "5px",
    padding: 15,
    marginTop: 10,
    borderColor: "#3FA202",
    borderStyle: "solid",
    borderWidth: 1
  },
  qualificationTitle: {
    fontWeight: "700",
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  loaderContiner: {
    width: 655,
    height: 100
  },
  lineThrough: {
    width: 655,
    height: 0,
    opacity: 0.1,
    border: "1px solid #675850",
    marginBottom: "10px"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px"
  },
  fullModalLoader: {
    // width: 655,
    // height: 482,
    height: "50%"
  },
  docNameContainer: {
    width: 600
  },
  containerStyle: {
    marginBottom: 0,
    alignItems: "flex-start"
    // zIndex: 9999
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "center",
    marginBottom: 0
    // zIndex: 9999
    // backgroundColor: 'white'
  },

  alignTextHeader: {
    // paddingTop: 25,
    marginLeft: 0,
    marginRight: 0,
    padding: 5
  },
  labelStyles: {
    paddingLeft: 0,
    fontFamily: FontFamily.Regular,
    fontWeight: FontWeight.Regular,
    color: colorPallete.black,
    maxWidth: 1200,
    flex: 1
  },
  placeholderStyle: {
    // fontFamily: FontFamily.Regular,
    // fontWeight: FontWeight.Regular,
    color: "#747574",
    fontSize: 13
    // fontWeight: FontWeight.Regular
  },
  customItemContainerStyle: {
    padding: 5,
    backgroundColor: "white"
    // zIndex: 9999,
  },
  listItems: {
    padding: 2,
    fontFamily: FontFamily.Regular,
    fontWeight: FontWeight.Regular,
    color: colorPallete.black
    // zIndex: 9999
  },
  listItemLabelStyle: {
    fontFamily: FontFamily.Regular,
    fontWeight: FontWeight.Regular,
    color: colorPallete.black
    // zIndex: 9999
  },
  arrowIconStyle: {
    width: 25,
    height: 19,
    marginLeft: 5
  }
});
