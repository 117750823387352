import React, { ReactElement, useEffect, useState } from "react";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import "react-datepicker/dist/react-datepicker.css";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
interface IProps {
  setTasksCollapse?: Function;
  tasksCollapse?: boolean;
  setTabClicked?: Function;
}

export default function TasksSidePanel(props: IProps): ReactElement {
  const { setTasksCollapse, tasksCollapse, setTabClicked } = props;
  // const [tasksCollapse, setTasksCollapse] = useState(false); // default collapse

  return (
    <View style={styles.container}>
      {/* <CollapseItem
        headerStyles={[styles.collapseHeaderContainer]}
        accordianIconStyle={styles.accordianIconStyle}
        isExpanded={false}
        bodyStyles={styles.bodyStyles}
        headerComponent={
          <View style={[styles.headerContainer, { marginLeft: 15 }]}>
            <Icon testID="topics" name={IconNames.topics} customStyle={styles.iconStyles} />
            <Text
              fontWeight={FontWeight.Medium}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={styles.textStyles}
            >
              Tasks
            </Text>
          </View>
        }
        children={ */}
      <TouchableOpacity
        onPress={() => {
          setTasksCollapse((prevState) => !prevState);
          setTabClicked("tasks");
          auxiliaryMethods.scrollToTopHandler();
        }}
        style={styles.mainContainer}
      >
        <View style={[styles.headerContainer, { marginLeft: 15 }]}>
          <Icon
            testID="taskSecondary"
            name={IconNames.taskSecondary}
            customStyle={styles.iconStyles}
          />
          <Text
            fontWeight={FontWeight.Medium}
            testId="headerText"
            textSize={TextSize.Small}
            textStyle={{ fontSize: 12, color: colorPallete.cordioBeige, marginTop: 2 }}
          >
            Tasks
          </Text>
        </View>
        <View>
          <Icon
            name={tasksCollapse ? IconNames.up : IconNames.down}
            size={10}
            customStyle={[{ color: colorPallete.cordioBeige, fontSize: 8 }]}
          />
        </View>
      </TouchableOpacity>
      {tasksCollapse && (
        <View style={styles.childContainer}>
          <View style={styles.textContainer}>
            <Text
              fontWeight={FontWeight.Medium}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={styles.textStyles}
            >
              A task (interaction record, survey, data collection etc.) is active for this session
            </Text>
          </View>
        </View>
      )}
      {/* }
      /> */}
      <View style={styles.dividerStyles}></View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  mainContainer: {
    paddingTop: 10,
    paddingBottom: 2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  dividerStyles: {
    borderColor: colorPallete.cordiolightGreyFour,
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 10
  },
  iconStyles: {
    fontSize: 20,
    color: colorPallete.cordioBeige,
    marginRight: 15,
    marginLeft: 2
  },
  accordianIconStyle: {
    paddingHorizontal: 0,
    color: colorPallete.white,
    fontSize: 8,
    marginTop: -5
  },
  bodyStyles: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  childContainer: {
    backgroundColor: colorPallete.cordioRedDark2,
    marginTop: 10
  },
  collapseHeaderContainer: {
    backgroundColor: colorPallete.cordioRedDark2,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    paddingHorizontal: 0,
    paddingVertical: 10
  },
  collapseContainer: {},
  textStyles: {
    fontSize: 12,
    color: colorPallete.cordioBeige
  },
  textContainer: {
    paddingLeft: 16,
    paddingBottom: 5,
    fontFamily: FontFamily.Regular,
    fontSize: 12
  }
});
