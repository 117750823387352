export const allRoutesNames = {
  HOME: "/",
  IAM: {
    root: "/new/iam",
    login: "/login",
    signup: "/signup"
  },
  login: "/login",
  signup: "/signup",
  OLDPATHS: {
    LOGIN: "/oauth/login",
    SIGNUP: "/oauth/signup",
    SIGNUP_REQ_OTP: "/oauth/signup-request-otp/"
  },
  CONTACT: "/new/contact",
  HEALTHCHECK: "/health",
  app: "/new/app",
  SIGNUPOTP: "/new/signup/otp",
  LOGINOTP: "/new/login/otp",
  ABOUTUS: "/new/aboutUs",
  /* PROFILE ROUTES START*/
  PROFILE: "/profile",
  PERSONALINFO: "/personal-info",
  CHANGEPASSWORD: "/change-password",
  MYACCOUNT: "/account",
  ABOUT: "/about",
  PRIVACYPOLICY: "/new/privacy-policy",
  TERMSOFUSE: "/new/terms-of-use",
  SETTINGS: "/settings",
  ADDITIONALPROFILEINFO: "/additionalProfileInfo",
  /* PROFILE ROUTES END */
  /* SPRINGSHED ROUTES START */
  ENTITY: "/entity",
  PROGRAM: "/program",
  PROGRAM_DETAILS: "/program/details",
  TOPICS: "/topics",
  ADMINS: "/admins",
  PARTNERS: "/partners",
  PARTNERSHIP: "/partnership",
  TOPICPARTIPANTDETAIL: "/topicparticipantdetail",
  SESSION: "/session",
  PARTICIPATIONDETAILS: "/participationDetails",
  PARTICIPATIONATTESTATION: "/participationAttestation",
  ATTESTATION: "/new/attestation",
  ACCOMPLISHMENT: "/accomplishment",
  ATTENDANCE: "/accomplishment/attendance",
  ACCOMPLISHMENTQP: "/accomplishment/qualification-packs",
  ACCOMPLISHMENTTASKS: "/accomplishment/tasks",
  ACCOMPLISHMENTTASKSDETAILS: "/accomplishment/tasks/details",
  ACCOMPLESMENTARTEFACT: "/accomplishment/artefact",
  ACCOMPLESMENTARTEFACTDETAILS: "/accomplishment/artefact/details",
  BADGES: "/badges",
  VERIFY: "/verify",
  DASHBOARD: "/dashboard",
  QUALIFICATIONPACKS: "/qualification-packs",
  /* SPRINGSHED ROUTES END */
  SIGNUP: "/signup",
  FORGOTPASSWORDOTP: "/new/forgot-password/otp",
  FORGOTPASSWORD: "/new/forgot-password",
  SIGNUPDETAILS: "/new/signup/details",
  RESETPASSWORD: "/new/forgot-password/reset-password",
  workspace: "/workspace",
  QUALIFICATIONPACKCOMPLETION: "/qp-user-qr",
  QUALIFICATIONPACKSCANNER: "/qp-qr",
  rev: {
    root: "/rev",
    dashboards: {
      root: `/dashboard/:comp`,
      urlParams: {
        selfCalibrationsList: `selfCalibrations`,
        reportsList: `reports`,
        actionPlansList: `actionPlans`,
        appealsList: `appeals`
      }
    },
    testAudit: `/testAudit`,
    detailedReport: `/detailedReport`,
    detailedActionPlan: `/detailedActionPlan`
  },
  CREATENEWSESSION: "/create-session",
  CREATESESSION: "/create",
  VIEWSESSION: "/view-session",
  PARTICIPATIONLIST: "/participation-list",
  ASSIGNBADGE: "/assign",
  UNASSIGNBADGE: "/unassign",
  VIEWQUALIFICATIONPACKS: "/view-pack-details",
  VIEWPARTICIPANTMAPPING: "/participant-mapping",
  TOPICDETAILS: "/topic-details",
  ADDQUALIFICATIONPACK: "/add-qualification-pack",
  VIEWQUALIFICATIONPACKDETAILS: "/view-qualification-pack"
};
