import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import Tooltip from "react-tooltip-lite";
import Moment from "moment";
interface Props {
  programDetails: any;
}

export default function UpcomingSessionPage(props: Props): ReactElement {
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [upcomingSessionResponse, setUpcomingSessionResponse] = useState([]);
  const [user, setUser] = useState(null);
  const [userRolesData, setUserRolesData] = useState(null);
  const history: any = useHistory();

  useEffect(() => {
    getSessionDetails();
  }, []);

  useEffect(() => {
    (async () => {
      let userData = await LocalStorage.getStorage("user");
      let tempUserRolesData: any = await LocalStorage.getStorage("userRoles");
      setUser(userData);
      setUserRolesData(tempUserRolesData?.userRolesData?.response);
    })();
  }, []);

  const getSessionDetails = async () => {
    try {
      const { program } = props?.programDetails;
      const newBody = {
        programId: program.id,
        sessionStatus: "UPCOMING"
      };
      const response = await ApiClient.post(sessionPackEndPoints.getSessionDetails(), newBody);
      response?.response.forEach((session: any, index: number) => {
        session.serialNo = `${index + 1}.`;
        // session.session.formattedSessionStartDate = dataHelper.formatDate(
        //   session?.session?.sessionStartDate
        // );
        // session.session.formattedSessionEndDate = dataHelper.formatDate(
        //   session?.session?.sessionEndDate
        // );
        let startDate = new Date(Moment.utc(session?.session?.sessionStartDate));
        let endDate = new Date(Moment.utc(session?.session?.sessionEndDate));
        session.session.formattedSessionStartDate = dataHelper.formatDateTime(startDate);
        session.session.formattedSessionEndDate = dataHelper.formatDateTime(endDate);
      });
      setUpcomingSessionResponse(response?.response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const viewSessionDetailsHandler = (data: any) => {
    history.push(`${routeNames.app}${routeNames.SESSION}${routeNames.VIEWSESSION}/upcoming`, {
      sessionData: data
    });
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = [
    {
      Header: "Sr.No",
      accessor: "serialNo",
      width: 30,
      maxWidth: 30,
      disableSortBy: true,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("serialNo", style, session?.serialNo);
      }
    },

    {
      Header: "Session Name",
      accessor: "session.sessionName",
      width: 220,
      maxWidth: 220,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold
        };
        const onPress = () => {
          session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData.includes("ENTITY_ADMIN") ||
          userRolesData.includes("PROGRAM_ADMIN")
            ? viewSessionDetailsHandler(session)
            : null;
        };
        // return cellData("name", style, session?.session?.sessionName, onPress);
        return session.memberRole ||
          user?.userId == session?.sessionCreator?.userId ||
          userRolesData?.includes("ENTITY_ADMIN") ||
          userRolesData?.includes("PROGRAM_ADMIN") ? (
          <Text
            fontWeight={FontWeight.Thin}
            testId="headerText1"
            textSize={TextSize.Small}
            style={[styles.sessionName]}
            onPress={onPress}
          >
            <a
              style={{ color: colorPallete.textBlack, fontFamily: FontFamily.Medium, fontSize: 12 }}
            >
              {session?.session.sessionName}
            </a>
          </Text>
        ) : (
          cellData("name", style, session?.session.sessionName)
        );
      }
    },
    {
      Header: "Contents",
      accessor: "content",
      width: 55,
      maxWidth: 55,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("content", style, session?.contents);
      }
    },
    {
      Header: "Start",
      accessor: "session.sessionStartDate",
      width: 55,
      maxWidth: 55,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("start", style, session?.session?.formattedSessionStartDate);
      }
    },
    {
      Header: "End",
      accessor: "session.sessionEndDate",
      width: 55,
      maxWidth: 55,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return cellData("end", style, session?.session?.formattedSessionEndDate);
      }
    },
    {
      Header: "Session Creator",
      accessor: "sessionCreator.name",
      width: 85,
      maxWidth: 85,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        const sessionCreator = session?.sessionCreator?.name ? session.sessionCreator.name : "None";
        return cellData("sessionCreator", style, sessionCreator);
      }
    },
    {
      Header: "Venue",
      accessor: "session.address",
      width: 55,
      maxWidth: 55,
      Cell: ({ row: { original: session } }: { row: { original: any } }) => {
        const style = {};
        return session?.session?.address.length < 10 ? (
          cellData("venue", style, session?.session?.address)
        ) : (
          <Tooltip
            styles={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inherit",
              fontFamily: FontFamily.Regular,
              fontSize: 12,
              color: colorPallete.textBlack
            }}
            tipContentClassName=""
            distance={10}
            direction="bottom"
            background={colorPallete.cordioBeigeLight1}
            color="black"
            padding={10}
            content={
              <View style={{ width: 250 }}>
                <Text
                  testId="testId"
                  fontWeight={FontWeight.Regular}
                  textSize={TextSize.Small}
                  style={{
                    fontFamily: FontFamily.Regular,
                    fontSize: 12
                  }}
                >
                  {session?.session?.address}
                </Text>
              </View>
            }
          >
            {session?.session?.address}
          </Tooltip>
        );
      }
    }
  ];

  const searchBarAndAddSession = () => (
    <View>
      <View style={styles.searchBarContainer}>
        <View pointerEvents={"none"}>
          <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={[styles.headerText, styles.headerAlignment]}
      >
        {"No Upcoming sessions scheduled under this program"}
      </Text>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : upcomingSessionResponse?.length === 0 ? (
        <View>{searchBarAndAddSession()}</View>
      ) : (
        <View>
          <Table
            columns={columns}
            data={upcomingSessionResponse}
            eventAnalytics="UpcomingSession"
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  sessionName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 25
  },
  customWidthStyle: {
    width: 400
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700"
  },
  headerAlignment: {
    paddingHorizontal: 25
  }
});
