import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, View, TouchableOpacity, FlatList } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  Label,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import * as Yup from "yup";
import MobileInput from "@socion-cordio/common/src/components/molecules/mobileInput";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Geolocation from "react-native-geolocation-service";
import axios from "axios";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { SignupRepository } from "@socion-cordio/common/src/repositories/signup/signup";
import WrapperForm from "@socion-cordio/common/src/components/organisms/signup/signupNew/wrapperForm";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { useTranslation } from "react-i18next";
import { telemetryToTrackMayMyIndia } from "@socion-cordio/common/src/utils/mapMyIndiaTelemetryHelper";

interface Props {
  fetchSignup?: Function;
  updateSignup?: Function;
  isCaptchaDisabled?: boolean;
  setIsCaptchaDisabled?: Function;
  setSelectedCountryObject?: Function;
  setEmailVerification?: Function;
  selectedCountryObject?: any;
}

export const SignupFormNew = React.forwardRef((props: Props, ref: any): ReactElement => {
  const { setIsCaptchaDisabled, isCaptchaDisabled } = props;
  const { t } = useTranslation();
  const siteKey = "6Lc76q4ZAAAAAC9ar-nPZhWwrLcnNuZas1NHulv_";
  const [captchaValue, setCaptchaValue] = useState(null);
  const [validate, setValidate] = useState([
    {
      label: "+91",
      phoneNumberLength: 10,
      phoneNumberSizeMax: 10,
      phoneNumberSizeMin: 10,
      value: 91
    }
  ]);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);
  const [IslocationAccess, setIslocationAccess] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [showCountryCodeList, setShowCountryCodeList] = useState(false);
  const [buttonTitleValue, setbuttonTitleValue] = useState("Select your country");
  const [isEmailVerification, setisEmailVerification] = useState(false);
  const [isPhoneVerification, setisPhoneVerification] = useState(false);
  const [dropdownVal, setdropdownVal] = useState(0);
  const [updateddropdownCountryCode, setUpdateddropdownCountryCode] = useState(91);
  const [selectedCountryObject, setSelectedCountryObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);

  const history: any = useHistory();
  const formikRef = useRef(null);

  const saveSignup = (
    values: {
      name: string;
      countryCode: number;
      phoneNumber: number;
      email: string;
    },
    isMobileVerificationType: boolean
  ) => {
    setLoading(true);
    let payload = {
      name: values.name,
      countryCode: `${selectedCountryObject?.code}`,
      grecaptchaResponse: captchaValue,
      verificationType: isMobileVerificationType ? "PHONE_NUMBER" : "EMAIL_ID",
      userName: values.phoneNumber
        ? values?.phoneNumber?.toString().replace(/[^0-9]/g, "")
        : values.email
    };
    let response = SignupRepository.registerNewUser(payload);
    response
      .then((res: any) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setLoading(false);
          // toast.success(res?.message);
          history.push("/new/signup/otp", {
            name: values.name,
            userName: values.phoneNumber ? values.phoneNumber : values.email,
            countryCode: `${selectedCountryObject?.code}`,
            verificationType: isMobileVerificationType ? "PHONE_NUMBER" : "EMAIL_ID",
            registrationKey: res.response,
            signupPayload: payload,
            selectedCountryObject: selectedCountryObject,
            isEmailVerification: isEmailVerification
          });
        } else {
          if (res?.message === "User already exists") {
            toast.error("This user is already registered in the system.");
          } else {
            toast.error(res?.message);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    async function fetchData() {
      let countryCode: any = await LocalStorage.getStorage("countryCode");
      let countryCodeObj: any = await LocalStorage.getStorage("countryCodeObj");

      countryCode === null || countryCodeObj === null
        ? [onPressForLocation(), console.log("No cc in local", countryCode)]
        : [serCountryCodeFromLocalStorage()];
    }
    fetchData();
    // onPressForLocation();
  }, []);

  useEffect(() => {
    if (validate[0]?.phoneNumberSizeMax != validate[0]?.phoneNumberSizeMin) {
      setMinLength(validate[0]?.phoneNumberSizeMin);
      setMaxLength(validate[0]?.phoneNumberSizeMax);
    } else {
      setMinLength(10);
      setMaxLength(10);
    }
  }, [validate]);

  useEffect(() => {
    getCountryodeList();
  }, []);

  const serCountryCodeFromLocalStorage = async () => {
    let countryCodeObject: any = await LocalStorage.getStorage("countryCodeObj");
    let countryCodeList: any = await LocalStorage.getStorage("countryCodeList");
    const countryData = countryCodeList.filter(
      (element: any) => element?.sequence === countryCodeObject?.sequence
    );
    actionOnRow(countryData[0]);
  };

  const onPressForLocation = (toggle = false) => {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (navigator.permissions.query({ name: "geolocation" })) {
        if (result.state == "granted") {
          Geolocation.getCurrentPosition(
            (position: any) => {
              const params = {
                lat: position.coords.latitude,
                long: position.coords.longitude,
                lang: "en"
              };
              setIslocationAccess(true);
              setDefaultLocation(params);
            },
            (error: any) => {
              console.log(error);
            },
            {
              enableHighAccuracy: true,
              timeout: 15000,
              maximumAge: 10000,
              showLocationDialog: false
            }
          );
        } else if (result.state == "prompt") {
          navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
        } else if (result.state == "denied") {
          setIslocationAccess(false);
        } else {
          setIslocationAccess(false);
        }
      }
    });
  };

  const revealPosition = () => {
    onPressForLocation();
  };

  const positionDenied = () => {
    setIslocationAccess(false);
  };

  const setDefaultLocation = async (params: any) => {
    try {
      const response: any = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&latlng=${params.lat},${params.long}&language=${params.lang}`
      );
      const result = response.data.results[0];
      await telemetryToTrackMayMyIndia("REVERSE_GEO_CODE", {
        lat: params.lat,
        lng: params.long,
        language: params.lang
      });
      let countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
      setCountryCodeList(countryCodeListResoponse.response);
      result.address_components.map((item: any) => {
        if (item.types[0] === "country") {
          const shortName = item.short_name;

          const IfCountryExist = countryCodeListResoponse.response.filter(
            (element: any) => element.isoShortCode === shortName
          );
          actionOnRow(IfCountryExist[0]);
        }
      });
    } catch (error) {}
  };

  const getCountryodeList = async () => {
    let countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
    setCountryCodeList(countryCodeListResoponse.response);
    LocalStorage.setStorage("countryCodeList", countryCodeListResoponse.response);
    return countryCodeListResoponse;
  };

  const getCountry = (data: any, IslocationAccess: boolean) => {
    setIslocationAccess(IslocationAccess);
  };

  const actionOnRow = (item: any) => {
    if (item !== undefined) {
      formikRef?.current?.resetForm({
        values: {
          name: "",
          phoneNumber: "",
          email: "",
          countryCode: +91
        }
      }); //reset formik values
      let validationCountryCodeObject: any = [];
      validationCountryCodeObject.push(item);
      setShowCountryCodeList(false);
      setbuttonTitleValue(`${item.country}(${item.code})`);
      if (item.verificationPriority == "SMS") {
        setisPhoneVerification(true);
        setisEmailVerification(false);
        props.setEmailVerification(false);
      } else {
        setisEmailVerification(true);
        props.setEmailVerification(true);
        setisPhoneVerification(false);
      }
      setValidate(validationCountryCodeObject);
      // setisEmailVerification(item.emailVerification);
      // props.setEmailVerification(item.emailVerification);
      // setisPhoneVerification(item.smsVerification);
      setIslocationAccess(true);
      props.setSelectedCountryObject(item);
      setdropdownVal(item.code.replace(/[\D]/g, ""));
      setUpdateddropdownCountryCode(item.code.replace(/[\D]/g, ""));
      setSelectedCountryObject(item);
    }
  };

  const validateEmail = (email: string) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleValidate = (values: any) => {
    let errors: any = {};
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (!values.countryCode) {
      errors.countryCode = "Country code required";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Please enter a valid mobile number";
    }
    if (values?.phoneNumber) {
      if (
        minLength == maxLength &&
        (values?.phoneNumber?.replace(/[^0-9]/g, "").length > 10 ||
          values?.phoneNumber?.replace(/[^0-9]/g, "").length < 10)
      )
        errors.phoneNumber = "The phone number should be of 10 digits";
      else if (minLength !== maxLength && values?.phoneNumber?.replace(/[^0-9]/g, "").length > 20)
        errors.phoneNumber = `The phone number should be between ${minLength} to ${maxLength} digits.`;
    }
    if (!values.name) {
      errors.name = `Please enter your name`;
    }
    return errors;
  };

  const handleValidateEmail = (values: any) => {
    let errors: any = {};
    if (!values.email) {
      errors.email = "Email Id is required";
    }
    if (
      !validateEmail(values?.email?.trim().replace(" ", "")) &&
      values?.email?.trim().replace(" ", "")
    ) {
      errors.email = "Email entered is not valid";
    }
    return errors;
  };

  const onChangeCaptchaValue = (value: any) => {
    setCaptchaValue(value);
    setIsCaptchaDisabled(false);
  };

  const captchaHandler = () => {
    // on error from captcha, disable button, display error
    setIsCaptchaDisabled(true);
    toast.error("Something went wrong.");
  };

  const renderHeaderComponent = () => (
    <>
      <View style={styles.mainHeading}>
        <Text
          fontWeight={FontWeight.Bold}
          testId="program"
          textSize={TextSize.Regular}
          textStyle={styles.subHeading}
        >
          {t("common:pdaProgram")}
        </Text>
        <View style={{ marginTop: 10 }}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="login"
            textSize={TextSize.Large}
            textStyle={{ fontSize: 26 }}
          >
            {t("common:signUpSecondary")}
          </Text>
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <Text
          fontWeight={FontWeight.Regular}
          testId="internal"
          textSize={TextSize.Small}
          style={[styles.selectCountryCodeText]}
        >
          {t("common:selectYouCountryCode")}
        </Text>
        <Button
          type={ButtonType.Secondary}
          buttonStyles={
            !showCountryCodeList
              ? styles.button
              : [styles.button, { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }]
          }
          title={buttonTitleValue}
          onPress={() => {
            setShowCountryCodeList(!showCountryCodeList);
          }}
        />
        {showCountryCodeList && (
          <View style={styles.flatListContainer}>
            <FlatList
              data={countryCodeList}
              renderItem={({ item }) => (
                <TouchableOpacity onPress={() => actionOnRow(item)}>
                  <View style={styles.flatview}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="forgot"
                      textSize={TextSize.Small}
                      textStyle={styles.forgot}
                    >
                      {item.country}({item.code})
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item.sequence}
              extraData={selectedSequence}
              contentContainerStyle={{}}
            />
          </View>
        )}
      </View>
    </>
  );

  return (
    <View>
      {IslocationAccess ? (
        <WrapperForm
          footerText={t("common:alreadyHaveAnAccount")}
          footerTextType={t("common:loginText")}
          onPressFooterTextType={() => history.push("/new/iam/login")}
          component={
            <View style={styles.mainContainer}>
              <View style={styles.subContainer}>
                <>{renderHeaderComponent()}</>

                {isPhoneVerification ? (
                  <View style={[styles.subFormContainer, { marginTop: 140 }]}>
                    <Formik
                      initialValues={{
                        name: "",
                        phoneNumber: "",
                        email: "",
                        countryCode: +91
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        saveSignup(values, true);
                        setSubmitting(false);
                        resetForm({
                          values: {
                            name: "",
                            phoneNumber: "",
                            email: "",
                            countryCode: +91
                          }
                        });
                      }}
                      // validationSchema={formValidaionSchema}
                      validate={handleValidate}
                      enableReinitialize={false}
                      innerRef={formikRef}
                    >
                      {(
                        formikProps: FormikProps<{
                          name: string;
                          countryCode: number;
                          phoneNumber: string;
                          email: string;
                        }>
                      ) => (
                        <Form>
                          <View style={styles.actionContainer}>
                            <View style={{ height: 98 }}>
                              <UserInput
                                label=""
                                handleChange={formikProps.handleChange("name")}
                                handleBlur={() => formikProps.setFieldTouched}
                                userStyle={[styles.userInputStyle]}
                                textIcon={styles.userInputStyleHeader}
                                icon={IconNames.userName}
                                textValue="Name"
                                name="name"
                                placeholder="Enter name"
                                value={formikProps.values.name}
                                id="name"
                                handleKeyPress={(e: any) => {
                                  if (e.key === "Enter") {
                                    formikProps.handleSubmit();
                                  }
                                }}
                                onChange={(e: any) => {
                                  setName(e.target.value);
                                }}
                                formikPropsTouched={
                                  formikProps.touched.name === undefined
                                    ? false
                                    : formikProps.touched.name
                                }
                                customChange={() => formikProps.setFieldTouched("name", false)}
                              />
                            </View>
                            <MobileInput
                              label=""
                              dropdownPlaceholder="+91"
                              handleChange={formikProps.handleChange("phoneNumber")}
                              onChangeValue={(value: number) => [
                                (formikProps.values.countryCode = value),
                                formikProps.handleChange("countryCode")
                              ]}
                              handleBlur={() => formikProps.setFieldTouched}
                              // inputStyle={styles.mobile}
                              userInputStyleHeader={styles.userInputStyleHeader}
                              icon={IconNames.mobile}
                              textValue="Mobile number"
                              name="phoneNumber"
                              placeholder="Enter number"
                              dropdownValue={formikProps.values.countryCode}
                              value={formikProps?.values?.phoneNumber
                                ?.toString()
                                .replace(/\D/g, "")}
                              id="phoneNumber"
                              handleKeyPress={(e: any) => {
                                if (e.key === "Enter") {
                                  formikProps.handleSubmit();
                                }
                              }}
                              dropDownContainerStyle={styles.dropDownContainerStyle}
                              containerStyle={styles.containerStyles}
                              setValidate={setValidate}
                              disableDropDown={true}
                              dropdownVal={dropdownVal}
                              updateddropdownCountryCode={updateddropdownCountryCode}
                              formikPropsTouched={
                                formikProps.touched.phoneNumber === undefined
                                  ? false
                                  : formikProps.touched.phoneNumber
                              }
                              customChange={() => formikProps.setFieldTouched("phoneNumber", false)}
                              isFormikReinitialised={true}
                            />
                            <ReCAPTCHA
                              sitekey={siteKey}
                              ref={ref}
                              onChange={onChangeCaptchaValue}
                              onExpired={() => setIsCaptchaDisabled(true)}
                              onErrored={captchaHandler}
                            />
                            <Button
                              type={ButtonType.Primary}
                              buttonStyles={styles.signUpButton}
                              title="Get verification code"
                              onPress={() => {
                                formikProps.handleSubmit();
                              }}
                              disabled={
                                isCaptchaDisabled ||
                                loading ||
                                name === null ||
                                formikProps?.values?.phoneNumber?.toString().replace(/\D/g, "") ===
                                  null ||
                                name === "" ||
                                formikProps?.values?.phoneNumber?.toString().replace(/\D/g, "") ===
                                  ""
                              }
                            />
                            <View style={{ alignItems: "center", marginTop: 5 }}>
                              <TouchableOpacity
                                onPress={() => {
                                  setisPhoneVerification(false);
                                }}
                              >
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="login"
                                  textSize={TextSize.Small}
                                  textStyle={[styles.forgot, { textDecorationLine: "underline" }]}
                                >
                                  {"Signup using email"}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </Form>
                      )}
                    </Formik>
                  </View>
                ) : (
                  <View style={[styles.subFormContainer, { marginTop: 140 }]}>
                    <Formik
                      initialValues={{
                        name: "",
                        phoneNumber: "",
                        email: "",
                        countryCode: +91
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        saveSignup(values, false);
                        setSubmitting(false);
                        resetForm({
                          values: {
                            name: "",
                            phoneNumber: "",
                            email: "",
                            countryCode: +91
                          }
                        });
                      }}
                      // validationSchema={formValidaionSchema}
                      validate={handleValidateEmail}
                      enableReinitialize={false}
                      innerRef={formikRef}
                    >
                      {(
                        formikProps: FormikProps<{
                          name: string;
                          email: string;
                          countryCode: number;
                          phoneNumber: string;
                        }>
                      ) => (
                        <Form>
                          <View style={styles.actionContainer}>
                            <View style={{ height: 98 }}>
                              <UserInput
                                label=""
                                handleChange={formikProps.handleChange("name")}
                                handleBlur={() => formikProps.setFieldTouched}
                                userStyle={styles.userInputStyle}
                                textIcon={styles.userInputStyleHeader}
                                icon={IconNames.userName}
                                textValue="Name"
                                name="name"
                                placeholder="Enter name"
                                value={formikProps.values.name}
                                id="name"
                                handleKeyPress={(e: any) => {
                                  if (e.key === "Enter") {
                                    formikProps.handleSubmit();
                                  }
                                }}
                                formikPropsTouched={
                                  formikProps.touched.name === undefined
                                    ? false
                                    : formikProps.touched.name
                                }
                                customChange={() => formikProps.setFieldTouched("name", false)}
                                onChange={(e: any) => {
                                  setName(e.target.value);
                                }}
                              />
                            </View>
                            <View style={{ height: 103 }}>
                              <UserInput
                                label=""
                                handleChange={formikProps.handleChange("email")}
                                handleBlur={() => formikProps.setFieldTouched}
                                userStyle={styles.userInputStyle}
                                textIcon={styles.userInputStyleHeader}
                                icon={IconNames.email}
                                textValue="Email"
                                name="email"
                                placeholder="Enter Email"
                                value={formikProps?.values?.email?.replace(/\s/g, "")}
                                id="Email"
                                handleKeyPress={(e: any) => {
                                  if (e.key === "Enter") {
                                    formikProps.handleSubmit();
                                  }
                                }}
                                formikPropsTouched={
                                  formikProps.touched.email === undefined
                                    ? false
                                    : formikProps.touched.email
                                }
                                customChange={() => formikProps.setFieldTouched("email", false)}
                                onChange={(e: any) => {
                                  setEmail(e.target.value);
                                }}
                              />
                            </View>
                            <ReCAPTCHA
                              sitekey={siteKey}
                              ref={ref}
                              onChange={onChangeCaptchaValue}
                              onExpired={() => setIsCaptchaDisabled(true)}
                              onErrored={captchaHandler}
                            />
                            <Button
                              type={ButtonType.Primary}
                              buttonStyles={styles.signUpButton}
                              title="Get verification code"
                              onPress={() => formikProps.handleSubmit()}
                              disabled={
                                isCaptchaDisabled ||
                                loading ||
                                name === null ||
                                email === null ||
                                name === "" ||
                                email === ""
                              }
                            />
                            <View style={{ alignItems: "center", marginTop: 5, height: 23 }}>
                              {selectedCountryObject?.smsVerification && (
                                <TouchableOpacity onPress={() => setisPhoneVerification(true)}>
                                  <Text
                                    fontWeight={FontWeight.Regular}
                                    testId="login"
                                    textSize={TextSize.Small}
                                    textStyle={[styles.forgot, { textDecorationLine: "underline" }]}
                                  >
                                    {"Signup using Mobile Number"}
                                  </Text>
                                </TouchableOpacity>
                              )}
                            </View>
                          </View>
                        </Form>
                      )}
                    </Formik>
                  </View>
                )}
              </View>
            </View>
          }
        />
      ) : (
        <WrapperForm
          footerText={t("common:alreadyHaveAnAccount")}
          footerTextType={t("common:loginText")}
          onPressFooterTextType={() => history.push("/new/iam/login")}
          component={
            <View style={styles.mainContainer}>
              <View style={styles.mainContainerSecondary}>
                <View style={styles.subContainer}>
                  <>{renderHeaderComponent()}</>
                </View>
              </View>
            </View>
          }
        />
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: "center",
    backgroundColor: colorPallete.white,
    width: "100%",
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  mainContainerSecondary: {
    height: 550
  },
  subContainer: {
    marginTop: 20,
    alignItems: "center"
  },
  subHeading: {
    fontSize: 19
  },
  mainHeading: {
    alignItems: "center"
    // marginTop: 50,
    // marginLeft: 80
  },
  formContainer: {
    width: 480,
    backgroundColor: colorPallete.cordioBeige,
    // position: "absolute",
    borderRadius: 20,
    // border: "1px solid rgb(194, 188, 185)",
    alignItems: "center",
    borderColor: colorPallete.cordioRed,
    borderStyle: "solid",
    borderWidth: 1
  },
  formContainerSecondary: {
    width: 480,
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    alignItems: "center",
    borderColor: colorPallete.cordioRed,
    borderStyle: "solid",
    borderWidth: 1
  },
  buttonContainer: {
    // marginLeft: 80,
    position: "absolute",
    top: 98
    // top: 90
  },
  selectCountryCodeText: {
    color: colorPallete.textLight,
    marginBottom: 10,
    marginTop: 5,
    alignSelf: "center",
    fontFamily: FontFamily.Regular
  },
  heading: {
    // marginTop: 50,
    // marginLeft: 80,
    fontSize: 26,
    marginBottom: 140
  },
  button: {
    marginTop: 0,
    height: 40,
    width: 304,
    zIndex: -1,
    borderRadius: 10
  },
  signUpButton: {
    marginTop: 15,
    height: 50,
    width: 304,
    zIndex: -1,
    borderRadius: 10
  },
  actionContainer: {
    // marginTop: 50,
    // marginLeft: 80
    marginBottom: 10
  },
  userInputStyle: {
    marginBottom: 20
  },
  userInputStyleHeader: {
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    marginLeft: 5
  },
  forgot: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1
  },
  footer: {
    // marginStart: 155,
    // zIndex: -1,
    // marginVertical: 40
    marginVertical: 7
  },
  login: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1,
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  subFormContainer: {
    zIndex: -1,
    alignItems: "center"
    // marginTop: 50
  },
  dropDownContainerStyle: {
    marginTop: 10,
    width: 303,
    zIndex: 10
  },
  containerStyles: {
    borderColor: colorPallete.lightGreyTwo,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    height: 40,
    marginBottom: 30,
    borderRightWidth: 0
  },
  flatview: {
    paddingTop: 0,
    borderRadius: 2,
    paddingLeft: 10
  },

  flatListContainer: {
    borderWidth: 1,
    height: 300,
    width: 304,
    borderTopWidth: 0,
    borderRadius: 0,
    top: -3,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: colorPallete.cordioRed,
    backgroundColor: colorPallete.white
  }
});
export default SignupFormNew;
