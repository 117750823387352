import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import AddBadgeModal from "@socion-cordio/common/src/components/organisms/badges/addBadgeModal";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import BadgeDetailsModal from "@socion-cordio/common/src/components/organisms/badges/badgeDetailsModals";
import { useHistory } from "react-router-dom";
import { allRoutesNames } from "../../../../../web/src/navigation/allRouteNames";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { BadgesActions } from "@socion-cordio/common/src/modules/badges/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import DropdownPicker from "@socion-cordio/common/src/components/molecules/dropdownPicker";
import { BadgesRepository } from "@socion-cordio/common/src/repositories/badges/badges";
import {
  BADGE_SETTING_TYPES,
  BADGE_TYPE_STATUS
} from "@socion-cordio/common/src/network/constants";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";

interface Props {
  programDetails?: any;
  selectedBadgeType?: any;
  setSelectedBadgeType?: Function;
}

export default function BadgesTable(props: Props): ReactElement {
  const { programDetails, selectedBadgeType, setSelectedBadgeType } = props;
  const history: any = useHistory();
  const [badgesResponse, setBadgesResponse] = useState(null); // manitain filtered list
  const [badgesList, setBadgesList] = useState([]);
  const [badgesTypesList, setBadgtTypesList] = useState([]); // maintain badge types list
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [addBadgeModal, setAddBadgeModal] = useState(false);
  const [viewBadgeModal, setViewBadgeModal] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(undefined);
  const [badgeTypeItems, setBadgeTypeItems] = useState([]);
  const [dropdownArray, setDropdownArray] = useState({
    value: [],
    optionsList: []
  });
  const isEntityEnabled = JSON.parse(localStorage.getItem("isEntityDeleted"));
  const isProgramEnabled = props?.programDetails?.program?.deleted;

  const dispatch = useDispatch();

  const {
    badgeTypesData: badgeTypesData
    // loading: { getBadgeTypes: loading }
  } = useSelector((state: any) => state.badges);

  useEffect(() => {
    getBadgesTable();
    getBadgeType();
  }, []);

  useEffect(() => {
    setDropdownArray({
      value: [{ value: "All Badge Types", label: "All Badge Types" }],
      optionsList: badgesTypesList
    });
  }, [badgesTypesList]);

  useEffect(() => {
    validateAndFilterBadgeType();
  }, [selectedBadgeType, badgesTypesList, badgesList]);

  useEffect(() => {
    getBadgeTypeItems();
  }, [badgeTypesData]);

  useEffect(() => {
    if (addBadgeModal) disableScroll();
    else enableScroll();
  }, [addBadgeModal]);

  useEffect(() => {
    if (viewBadgeModal) disableScroll();
    else enableScroll();
  }, [viewBadgeModal]);

  const getBadgesTable = async () => {
    try {
      const { program } = props?.programDetails;
      // const response = BadgesRepository.getBadges(584);
      // response
      //   .then((res) => {
      //     console.log("res------->>>", res);
      //   })
      //   .catch((error) => {
      //     console.log("error-->>", error);
      //     toast.error("Something went wrong");
      //   });

      const badgesResponse = await ApiClient.get(badgesEndPoints.getBadgesTable(program.id));
      const list = listManipulationHandler(badgesResponse?.response?.[program.id]);
      let allSelectorValue = [{ label: "All Badge Types", value: "All Badge Types" }];
      setBadgesList(list);
      setBadgesResponse(list);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
      setBadgesList([]);
      setBadgesResponse([]);
    }
  };

  const listManipulationHandler = (list: any[]) => {
    const newList = _.orderBy(list, "badgeClassName");
    newList?.forEach((badge: any, index: number) => {
      badge.serialNo = `${index + 1}.`;
      badge.createdAt = dataHelper.formatDate(badge.createdAt);
      badge.value = badge.badgeClassId;
      badge.label = badge.badgeClassName;
    });
    return newList;
  };

  const validateAndFilterBadgeType = () => {
    if (selectedBadgeType) {
      const payload = {
        ...selectedBadgeType,
        value: selectedBadgeType.badgeClassId,
        label: selectedBadgeType.badgeClassName
      };
      setDropdownArray({
        ...dropdownArray,
        value: [payload]
      });
      onDropdownValueChange(payload);
      // Filter badgesResponse with badgeClassId and display in dropdown
    }
  };

  const getBadgeTypeItems = () => {
    if (badgeTypesData !== null && badgeTypesData?.response?.length !== 0) {
      let result = badgeTypesData?.response.filter(
        (item: any) => item.status === BADGE_TYPE_STATUS.PUBLISHED
      );
      const list: any = result?.map((data: any, id: any) => {
        return { label: data.badgeClassName, value: parseInt(data.badgeClassId) };
      });
      getBadgeTypeFilterList(badgeTypesData?.response);
      setBadgeTypeItems(list);
    } else {
      setBadgeTypeItems([]);
    }
  };

  const getBadgeTypeFilterList = (list: any[]) => {
    let newList = listManipulationHandler(list);
    let allSelectorValue = [{ label: "All Badge Types", value: "All Badge Types" }];
    setBadgtTypesList(allSelectorValue.concat(newList));
  };

  const getBadgeType = async () => {
    const { program } = props?.programDetails;
    dispatch(BadgesActions.getBadgeTypes(program.id));
  };

  const onDropdownValueChange = (selectedItem: any, e?: any) => {
    setDropdownArray({
      value: [{ value: selectedItem.badgeClassId, label: selectedItem.label }],
      optionsList: badgesTypesList
    });

    if (selectedItem.value === "All Badge Types") {
      //display full list (reset)
      let list = [...badgesList];
      list = listManipulationHandler(list); //.slice(1)
      setBadgesResponse(list);
    } else {
      let list = badgesList?.filter(
        (item: any) => item.badgeClassId === selectedItem?.badgeClassId
      );
      list = listManipulationHandler(list);
      setBadgesResponse(list);
    }
  };

  const unassignBadgeNavigateHandler = (data: any) => {
    history.push(`${allRoutesNames.app}${allRoutesNames.BADGES}${allRoutesNames.UNASSIGNBADGE}`, {
      selectedBadge: data,
      programId: programDetails?.program?.id
    });
    googleWebAnalytics("Badge_Number_Issued", "Button", "Badge");
  };

  const onCloseAddBadgeHandler = () => {
    setAddBadgeModal(false);
    setSelectedBadgeType(null);
    setDropdownArray({
      value: [{ value: "All Badge Types", label: "All Badge Types" }],
      optionsList: badgesTypesList
    });
  };

  const handleRowClick = (data: any) => {
    setSelectedBadge(data);
    setViewBadgeModal(!viewBadgeModal);
    googleWebAnalytics("Badges_View_Badge_Modal", "Button", "Badge");
  };

  const updateTelemetryBadgesEvent = async (eventType: string, selectedBadge: any) => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    const user: any = await LocalStorage.getStorage("user");
    const body: any = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      updatedBy: user.userId,
      eventType: eventType,
      badgeId: selectedBadge.badgeId,
      badgeName: selectedBadge.name,
      badgeType: selectedBadge.badgeClassName,
      badgeTypeId: selectedBadge.badgeClassId,
      entityId: null,
      entityName: null
    };
    AddTelemetryService(body, programDetails, user, false);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 30,
        maxWidth: 30,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, badge?.serialNo);
        }
      },
      {
        Header: "Badge Name",
        accessor: "name",
        width: 140,
        maxWidth: 140,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold
          };
          const onPress = () => {
            handleRowClick(badge);
          };
          return cellData("name", style, badge?.name, onPress);
        }
      },
      {
        Header: "Badge Type",
        accessor: "badgeClassName",
        width: 60,
        maxWidth: 60,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return (
            <>
              {badge.badgeClassName.length > 14 ? (
                <CustomTooltip
                  hoverValue={badge.badgeClassName}
                  textValue={badge.badgeClassName}
                  toShowTextEllipsesStyle={true}
                />
              ) : (
                cellData("badgeClassName", style, badge.badgeClassName)
              )}
            </>
          );
        }
      },
      {
        Header: "Assigned/Declared",
        accessor: "declaredType",
        width: 82,
        maxWidth: 82,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          let declaredType =
            badge.declaredType === BADGE_SETTING_TYPES.SELF_DECLARED ||
            badge.declaredType === "Self-Declared"
              ? "Self-Declared"
              : badge.declaredType === BADGE_SETTING_TYPES.ASSIGN
              ? BADGE_SETTING_TYPES.ASSIGNED
              : auxiliaryMethods.capitalizeFirstLetter(badge?.declaredType);
          return cellData("declaredType", style, declaredType);
        }
      },
      {
        Header: "Date of creation",
        accessor: "createdAt",
        width: 65,
        maxWidth: 65,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {};
          return cellData("createdAt", style, badge.createdAt);
        }
      },
      {
        Header: "Status",
        accessor: "status",
        width: 48,
        maxWidth: 48,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => (
          <View>
            {badge.status == "ACTIVE" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.activeColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {"Active"}
                </Text>
              </View>
            ) : badge.status == "DEACTIVATED" ? (
              <View style={styles.statusContainer}>
                <View style={[styles.statusColor, styles.inactiveColor]}></View>
                <Text
                  fontWeight={FontWeight.Light}
                  testId="testId"
                  textSize={TextSize.Small}
                  textStyle={styles.textAlign}
                  onPress={() => {}}
                >
                  {"Inactive"}
                </Text>
              </View>
            ) : null}
          </View>
        )
      },
      {
        Header: "No. issued",
        accessor: "badgeIssued",
        width: 53,
        maxWidth: 53,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => {
          const style = {
            fontFamily: FontFamily.Medium,
            fontWeight: FontFamily.Bold,
            textDecorationLine: "underline"
          };
          const onPress = () => {
            unassignBadgeNavigateHandler(badge);
          };
          return cellData("name", style, badge?.badgeIssued, onPress);
        }
      },
      {
        Header: "Assign Badge",
        width: 55,
        maxWidth: 55,
        disableSortBy: true,
        Cell: ({ row: { original: badge } }: { row: { original: any } }) => (
          <View>
            {(badge.declaredType === BADGE_SETTING_TYPES.ASSIGNED ||
              badge.declaredType === BADGE_SETTING_TYPES.ASSIGN) && (
              <View style={styles.assignBadgeContainer}>
                {badge.status === "ACTIVE" && (
                  <TouchableOpacity
                    onPress={() => {
                      history.push(
                        `${allRoutesNames.app}${allRoutesNames.BADGES}${allRoutesNames.ASSIGNBADGE}`,
                        {
                          selectedBadge: badge,
                          programId: programDetails?.program?.id
                        }
                      );
                      googleWebAnalytics("Badges_Assign_Badge", "Button", "Badges");
                    }}
                  >
                    <Image
                      testId="secondaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/secondary_add.svg")}
                      imageStyle={{
                        width: 20,
                        height: 20
                      }}
                    />
                  </TouchableOpacity>
                )}
              </View>
            )}
          </View>
        )
      }
    ],
    []
  );

  const searchbarAndAddBadge = () => (
    <View>
      <View style={styles.searchBarContainer}>
        <View style={styles.alignSearchBarDropdownContainer}>
          <View pointerEvents={"none"}>
            <SearchFilterSeondary customWidthStyle={styles.customWidthStyle} editable={false} />
          </View>
          <View style={styles.dropdownContainer}>
            <DropdownPicker
              customStyles={customDropdownStyles}
              optionsList={dropdownArray.optionsList}
              value={dropdownArray.value}
              onChange={onDropdownValueChange}
            />
          </View>
        </View>
        <View>
          {!isEntityEnabled && !isProgramEnabled && (
            <TouchableOpacity
              style={styles.addButtonIcon}
              onPress={() => {
                setAddBadgeModal(!addBadgeModal);
                googleWebAnalytics("Badges_Add_Badge_Modal", "Button", "Badge");
              }}
            >
              <Image
                testId="secondaryAddImg"
                source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                imageStyle={{
                  width: 30,
                  height: 30
                }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <Text
        fontWeight={FontWeight.Thin}
        testId="headerText"
        textSize={TextSize.Small}
        textStyle={styles.headerText}
      >
        {dropdownArray.optionsList.length > 1 && dropdownArray.value[0].label !== "All Badge Types"
          ? "There are no Badge(s) created for this Badge Type."
          : "There are no Badge(s) created for this program."}
      </Text>
    </View>
  );

  return (
    <View>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          {badgesResponse?.length == 0 ? (
            <View>{searchbarAndAddBadge()} </View>
          ) : (
            <Table
              columns={columns}
              data={badgesResponse !== null ? badgesResponse : []}
              searchBarCustomStyle={styles.customWidthStyle}
              displayDropdown={true}
              customDropdownStyles={customDropdownStyles}
              dropdownValue={dropdownArray.value}
              dropdownOptionsList={dropdownArray.optionsList}
              onDropdownValueChange={onDropdownValueChange}
              eventAnalytics="Badges"
              // noDataMessage="No Badge found"
              addIcon={
                !isEntityEnabled &&
                !isProgramEnabled && (
                  <TouchableOpacity onPress={() => setAddBadgeModal(!addBadgeModal)}>
                    <Image
                      testId="primaryAddImg"
                      source={require("@socion-cordio/common/src/assets/images/primary_add.svg")}
                      imageStyle={{
                        width: 30,
                        height: 30
                      }}
                    />
                  </TouchableOpacity>
                )
              }
            />
          )}

          {addBadgeModal && (
            <View>
              <SocionModal
                modalVisible={addBadgeModal}
                setModalVisible={() => setAddBadgeModal(!addBadgeModal)}
                component={
                  <AddBadgeModal
                    onClose={() => onCloseAddBadgeHandler()}
                    getBadges={getBadgesTable}
                    programDetails={programDetails}
                    badgeTypeItems={badgeTypeItems}
                    updateTelemetryBadgesEvent={updateTelemetryBadgesEvent}
                  />
                }
              />
            </View>
          )}

          {viewBadgeModal && (
            <View>
              <SocionModal
                modalVisible={viewBadgeModal}
                setModalVisible={() => setViewBadgeModal(!viewBadgeModal)}
                component={
                  <BadgeDetailsModal
                    onClose={() => {
                      setViewBadgeModal(false);
                      googleWebAnalytics("Badges_Close_View_Badge_Modal", "Button", "Badge");
                    }}
                    selectedBadge={selectedBadge}
                    getBadges={getBadgesTable}
                    programDetails={programDetails}
                    updateTelemetryBadgesEvent={updateTelemetryBadgesEvent}
                  />
                }
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  mainHeaderText: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    lineHeight: 17,
    fontWeight: "700",
    paddingLeft: 25,
    marginTop: 10,
    marginBottom: 10
  },
  addRoleButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  activeColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  inactiveColor: {
    backgroundColor: colorPallete.yellowOne
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 12
  },
  // Search bar disabled
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 18,
    marginBottom: 20
  },
  alignSearchBarDropdownContainer: {
    flexDirection: "row"
  },
  customWidthStyle: {
    width: 323,
    height: 38
  },
  addButtonIcon: {},
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 19.5,
    fontWeight: "700",
    paddingHorizontal: 25,
    marginBottom: 30
  },
  assignBadgeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginRight: 10
  },
  dropdownContainer: {
    marginLeft: 12
  }
});

const customDropdownStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: colorPallete.white,
    // border: "none",
    border: `1px solid ${colorPallete.cordioTaupeLight4}`,
    boxShadow: "none",
    fontFamily: FontFamily.Medium,
    fontSize: 14,
    width: 250,
    height: 40,

    ":hover": {
      border: `1px solid ${colorPallete.cordioTaupeLight4}`
    }
  }),
  menuList: (base: any) => ({
    ...base,
    fontFamily: FontFamily.Medium,
    backgroundColor: colorPallete.lightGrey,
    maxHeight: 150,
    "::-webkit-scrollbar": {
      width: 5
    },
    "::-webkit-scrollbar-track": {
      background: "#D1D1D1",
      borderRadius: 10
    },
    "::-webkit-scrollbar-thumb": {
      background: "#808080",
      borderRadius: 10
    }
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    // backgroundColor: isSelected ? colorPallete.lightGreyThree : colorPallete.lightGrey,
    backgroundColor: colorPallete.white,
    color: colorPallete.textBlack,
    ":hover": {
      backgroundColor: colorPallete.lightGreyThree
    }
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    fontSize: 14
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
};
