import React from "react";
import { StyleSheet, View } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";

interface Props {
  data?: any;
}

const Rating = (props: Props) => {
  const { data } = props;

  return (
    <View style={styles.mainContainer}>
      {[...Array(5)].map((star, index) => (
        <>
          <Icon
            name={
              index + 1 <= data
                ? IconNames.fullColoredStar
                : index + 1 <= Math.ceil(data)
                ? IconNames.halfColoredStar
                : IconNames.starOutline
            }
            customStyle={styles.colored}
          />
        </>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    flexDirection: "row"
  },
  colored: {
    color: "#675850"
  }
});

export default Rating;
