import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity, Linking } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { topicEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import { mediaHelper } from "@socion-cordio/common/src/utils/mediaHelperMethods";
import CollapseItem from "@socion-cordio/common/src/components/molecules/collapseItem";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
interface Props {
  onSubmit?: (data: { otp: number }) => void;
  sessionDetail?: any;
}

export default function CreateSessionSidePanelPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const [topicDescription, setTopicDescription] = useState(null);
  const [topicName, setTopicName] = useState(null);
  const [contentList, setContentList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (props?.sessionDetail !== null) {
      getTopicDetails();
    }
  }, [props.sessionDetail]);

  const getTopicDetails = async () => {
    try {
      const payload = {
        // 1323
        topicIds: [props?.sessionDetail?.id]
      };
      const detailsResponse = await ApiClient.post(topicEndPoints.getTopicDetails(), payload);
      setTopicName(detailsResponse?.response?.[0].name);
      setTopicDescription(detailsResponse?.response?.[0].description);
      setContentList(detailsResponse?.response?.[0].contentFiles);
      setLoading(false);
      setFailed(false);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  const getFileName = (url: string) => {
    let name = url.split("/");
    return name[name.length - 1];
  };

  const validateSubContentUrl = (url: string) => {
    const urlCheck =
      url.includes("https://") || url.includes("http://")
        ? Linking.openURL(url)
        : Linking.openURL(`https://${url}`);
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View style={styles.subContainer}>
          <View style={styles.alignContainer}>
            <Text
              fontWeight={FontWeight.Light}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerTextTitleSupport]}
            >
              {topicName}
            </Text>
          </View>
          <ScrollContainer
            height="100px"
            scrollable={topicDescription?.length > 120 ? true : false}
          >
            <View style={[styles.alignContainer]}>
              <Text
                onPress={() => {}}
                fontWeight={FontWeight.Regular}
                testId="text"
                textSize={TextSize.Small}
                textStyle={[styles.headerText, styles.headerTextSupport, { opacity: 0.6 }]}
              >
                {topicDescription}
              </Text>
            </View>
          </ScrollContainer>
          <View style={[styles.alignContainer, { marginTop: 25, marginBottom: 20 }]}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.headerText, styles.headerTextSupport, { fontWeight: "700" }]}
            >
              {t("common:content")}
            </Text>
          </View>

          <View style={styles.contentListViewContainer}>
            {contentList?.map((prop: any, key: any) => {
              return (
                <View key={key} style={styles.contentContainer}>
                  <View style={styles.miniContainer}>
                    <View style={styles.miniIconContainer}>
                      <Icon
                        testID="download"
                        name={
                          mediaHelper.getIcons(prop.fileType) === "image"
                            ? IconNames.imageFile
                            : mediaHelper.getIcons(prop.fileType) === "video"
                            ? IconNames.videoIcon
                            : IconNames.documentFile
                        }
                        customStyle={[styles.alignContainerButton]}
                      />
                    </View>
                    <CollapseItem
                      headerStyles={[styles.collapseHeaderContainer]}
                      accordionStyles={styles.accordionStyles}
                      accordianIconStyle={styles.accordianIconStyle}
                      bodyStyles={styles.bodyStyles}
                      showAccordianIcon={prop.subContentList.length > 0 ? true : false}
                      isExpanded={false}
                      headerComponent={
                        <View style={styles.linkContainer}>
                          <Text
                            fontWeight={FontWeight.Medium}
                            testId="headerText"
                            textSize={TextSize.Small}
                            textStyle={[
                              styles.headerText,
                              styles.miniContainerHeaderText,
                              styles.headerAlign
                            ]}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                prop.vimeoUrl === null
                                  ? Linking.openURL(prop.contentUrl)
                                  : Linking.openURL(prop.vimeoUrl);
                              }}
                            >
                              <Text
                                fontWeight={FontWeight.Medium}
                                testId="headerText"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.headerText,
                                  styles.miniContainerHeaderText,
                                  styles.headerAlign,
                                  styles.alignNameWidth
                                ]}
                              >
                                {prop.fileName}
                              </Text>
                            </TouchableOpacity>
                          </Text>
                        </View>
                      }
                      children={
                        <View style={styles.subContentContainer}>
                          {!dataHelper.isEmptyArray(prop?.subContentList) &&
                            prop.subContentList.map((subContent: any) => (
                              <Text
                                fontWeight={FontWeight.Medium}
                                testId="headerText"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.headerText,
                                  styles.miniContainerHeaderText,
                                  styles.headerAlign,
                                  styles.subContentTitleHeader
                                ]}
                              >
                                <TouchableOpacity
                                  onPress={() => {
                                    validateSubContentUrl(subContent.url);
                                  }}
                                >
                                  {subContent.title}
                                </TouchableOpacity>
                              </Text>
                            ))}
                        </View>
                      }
                    />
                  </View>
                  <TouchableOpacity
                    onPress={() => {
                      mediaHelper.mediaDownloadHandler(
                        prop?.fileType,
                        getFileName(prop?.contentUrl)
                      );
                    }}
                  >
                    <View style={styles.miniIconContainer}>
                      <Icon
                        testID="download"
                        name={IconNames.downloadList}
                        customStyle={[styles.alignContainerButton]}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              );
            })}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20
  },
  subContainer: {
    width: 205
  },
  alignContainer: {
    marginBottom: 15
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  headerTextTitleSupport: {
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 20,
    color: colorPallete.white
  },
  headerAlign: {
    marginLeft: 5
  },
  alignNameWidth: {
    width: "16ch"
  },
  headerTextSupport: {
    fontSize: 10,
    lineHeight: 15,
    color: colorPallete.white
  },
  contentListViewContainer: {},
  contentContainer: {
    minHeight: 40,
    backgroundColor: colorPallete.cordioBeigeLight2,
    borderRadius: 5,
    marginBottom: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "center",
    padding: 5
  },
  miniContainer: {
    flexDirection: "row"
  },
  miniContainerHeaderText: {
    color: colorPallete.black,
    fontSize: 10,
    lineHeight: 15
    // width: "20ch"
  },
  subContentTitleHeader: {
    fontFamily: FontFamily.Regular,
    marginBottom: 5,
    textDecorationLine: "underline"
  },
  miniIconContainer: {
    marginTop: 8
  },
  alignContainerButton: {
    fontSize: 15,
    color: colorPallete.cordioTaupe,
    marginRight: 5,
    marginLeft: 5
  },
  descriptionContainer: {
    height: "100px",
    overflowY: "scroll"
  },
  linkContainer: {
    width: 120
  },
  subContentContainer: {
    marginTop: 5
  },
  collapseHeaderContainer: {
    padding: 0,
    alignItems: "flex-start",
    shadowColor: "#ffffff0",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 0,
    backgroundColor: "#ffffff0",
    borderRadius: 0,
    // width: 550, // Collapsible width change,
    justifyContent: "flex-start",
    width: 140
  },
  accordionStyles: {
    shadowColor: "#ffffff0",
    backgroundColor: "#ffffff0",
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    borderRadius: 0,
    justifyContent: "center"
  },
  accordianIconStyle: {
    color: colorPallete.black,
    paddingHorizontal: 5,
    fontSize: 6
  },
  bodyStyles: {
    backgroundColor: colorPallete.cordioBeigeLight2,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: 140
  }
});
