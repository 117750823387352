import React, { ReactElement, useState } from "react";
import {
  Modal as RNModal,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle
} from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

interface Props {
  modalVisible: boolean;
  setModalVisible: Function;
  component: ReactElement;
  customContainer?: StyleProp<ViewStyle>;
  customPadding?: number;
  isCustomStyling?: boolean;
}

export default function Modal(props: Props) {
  const { customPadding = null, isCustomStyling = false } = props;
  return (
    <View style={isCustomStyling ? styles.centeredViewSecondary : styles.centeredView}>
      <RNModal
        animationType="fade"
        transparent={true}
        visible={props.modalVisible}
        onRequestClose={() => {
          props.setModalVisible();
        }}
      >
        <TouchableOpacity
          style={styles.centeredView}
          activeOpacity={1}
          // onPressOut={() => props.setModalVisible()}
        >
          <TouchableWithoutFeedback>
            <View
              style={[
                styles.modalView,
                props.customContainer,
                { padding: customPadding !== null ? customPadding : 25 }
              ]}
            >
              {props.component}
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </RNModal>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  centeredViewSecondary: {
    // flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    height: "100vh"
  },
  modalView: {
    margin: 20,
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    padding: 25,
    alignItems: "center",
    shadowColor: colorPallete.black,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  }
});
