import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
// import DatePicker from "@socion-cordio/common/src/components/molecules/datePicker";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
import { CustomTooltip } from "@socion-cordio/common/src/components/molecules/customTooltip";

interface Props {
  onClose?: Function;
  assignBadgeData?: any;
  formatSelectedRows: Function;
  badgeDetailsState?: any;
  getBadgeUsersList: Function;
  calendarEndDate: Function;
  calendarStartDate: Function;
}

const Styles = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: inline-grid;
  }
  .react-datepicker-popper {
    /* z-index: 9999 !important; */
    /* width: 200% !important; */
    /* margin-left: 16%; */
  }
  .react-datepicker__input-container {
    input {
      border: 1px solid lightgrey;
      border-radius: 5px;
      font-family: Montserrat-Medium;
      font-size: 12px;
      height: 40px;
      padding: 7px;
      font-weight: lighter;
      width: 92px;
    }
    input:focus {
      border: 1px solid lightgrey;
    }
  }
`;

export default function AssignBadgeModal(props: Props): ReactElement {
  const { onClose, assignBadgeData, formatSelectedRows, badgeDetailsState, getBadgeUsersList } =
    props;
  const [memberList, setMemberList] = useState([]);
  const [isConflictPresent, setIsConflictPresent] = useState(false);
  const [startDateValidity, setStartDateValidty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showHeadersOnly, setShowHeadersOnly] = useState(true);

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        },
        style
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  useEffect(() => {
    verifyData();
  }, [assignBadgeData]);

  const verifyData = () => {
    if (assignBadgeData !== null || assignBadgeData !== undefined) {
      let finalArray = assignBadgeData?.response?.map((i: any) => {
        return { ...i, startDate: new Date(i.startDate), endDate: new Date(i.endDate) };
      });
      setMemberList(finalArray);
      setLoading(false);
      checkIfConflictDataExists(assignBadgeData?.response);
    } else {
      setLoading(false);
    }
  };

  const checkIfConflictDataExists = (data: any) => {
    const userList = [...data];
    const list = userList.filter((x) => x.conflict === true);
    list.length !== 0 ? setIsConflictPresent(true) : setIsConflictPresent(false);
  };

  const removeSelectedMember = (data: any) => {
    const userList = [...memberList];
    let list = userList.filter((x) => x.userId !== data.userId);
    if (list?.length == 0) {
      list = [
        {
          city: "",
          country: "",
          district: "",
          endDate: "",
          name: "",
          reason: "",
          serialNo: "",
          startDate: "",
          state: "",
          subDistrict: ""
        }
      ];
      setShowHeadersOnly(false);
    }
    setMemberList(list);
    checkIfConflictDataExists(list);
  };

  const saveSelectedBadgeDetailsHandler = async () => {
    let flag = false;
    memberList.map((i) => {
      if (i.endDate === "") {
        flag = true;
        return;
      }
    });
    if (flag) toast.error("Please fill all the fields");
    else {
      try {
        setLoading(true);
        let formattedSelectedRows: any = [];
        formattedSelectedRows = memberList.map((row: any) => {
          return {
            endDate: badgeDetailsState?.selectedBadge?.validity
              ? Moment(row.endDate).format("YYYY-MM-DD")
              : //  new Date(row.endDate).toISOString().replace("T", " ").replace("Z", "")
                null,
            startDate: badgeDetailsState?.selectedBadge?.validity
              ? Moment(row.startDate).format("YYYY-MM-DD")
              : // new Date(row.startDate).toISOString().replace("T", " ").replace("Z", "")
                null,
            userId: row.userId
          };
        });
        const data = {
          badgeId: badgeDetailsState.selectedBadge?.badgeId,
          programId: badgeDetailsState.programId,
          badgeList: formattedSelectedRows
        };
        const response = await ApiClient.post(badgesEndPoints.assignBadgesUsers(), data);
        if (response.responseCode === 409) {
          toast.error(response.message);
          let result = response?.response?.map((i: any) => {
            return { ...i, startDate: new Date(i.startDate), endDate: new Date(i.endDate) };
          });
          setMemberList(result);
        }
        let message =
          response.response.successRecords.length == 1
            ? `Selected member has been successfully assigned the Badge ${badgeDetailsState.selectedBadge.name}`
            : `${response.response.successRecords.length} members have been successfully assigned the Badge ${badgeDetailsState.selectedBadge.name}.`;
        if (response.responseCode === 200) {
          toast.success(message);
        }
        // updateListTelemetry(response?.response?.successRecords);
        setTimeout(() => {
          setLoading(false);
          onClose();
          props.calendarStartDate(null);
          props.calendarEndDate(null);
          getBadgeUsersList();
        }, 5000);
      } catch (error) {
        setLoading(false);
        // toast.error("Something went wrong...!!!");
      }
    }
  };

  const formatListForTelemetry = (list: any) => {
    let newRows: any = [];
    list.forEach((row: any) => {
      let rowObject: any = {};
      rowObject.batchAssignedId = row.badgeAssignedId;
      rowObject.badgeId = row.badge.badgeId;
      rowObject.eventType = "Badge Assigned";
      rowObject.userId = row.userId;
      rowObject.badgeName = row.badge.badgeName;
      newRows.push(rowObject);
    });
    return newRows;
  };

  // const updateListTelemetry = async (list: any) => {
  //   const programDetails: any = await LocalStorage.getStorage("programDetails");
  //   const user: any = await LocalStorage.getStorage("user");
  //   const body = {
  //     updatedBy: user.userId
  //   };
  //   const userList = formatListForTelemetry(list);
  //   AddTelemetryServiceBadges(body, programDetails, user, userList, true);
  // };

  const checkStartDate = (date: any, days: any) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    const newDate = new Date(result);
    return newDate;
    // setStartDateValidty(newDate);
  };

  const handleDateChange = (date: any, data: any, dateType: string) => {
    console.log(date, data, dateType);
    let updatedObj;
    let tempArray = [...memberList];
    let index = tempArray.findIndex((i) => i.userId === data.userId);
    if (dateType == "startDate") {
      updatedObj = { ...tempArray[index], startDate: date, endDate: "" };
    } else updatedObj = { ...tempArray[index], endDate: date };
    const updatedArray = [...tempArray.slice(0, index), updatedObj, ...tempArray.slice(index + 1)];
    setMemberList(updatedArray);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: 35,
      maxWidth: 35,
      disableSortBy: true,
      Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold
        };
        return cellData("name", style, badgeData?.userDetails?.name);
      }
    },
    {
      Header: "Base Location",
      accessor: "baseLocation",
      width: 30,
      maxWidth: 30,
      disableSortBy: true,
      Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
        const style = {
          fontFamily: FontFamily.Medium,
          fontWeight: FontFamily.Bold
        };
        return (
          <>
            {auxiliaryMethods.getUserFirstLocationName(badgeData?.userDetails) !== "None" ? (
              <CustomTooltip
                hoverValue={auxiliaryMethods.fromatBaseLocation(badgeData?.userDetails)}
                textValue={auxiliaryMethods.getUserFirstLocationName(badgeData?.userDetails)}
              />
            ) : (
              cellData("location", style, "None")
            )}
          </>
        );
      }
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      width: 34,
      maxWidth: 34,
      disableSortBy: true,
      Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
        const style = {};
        return (
          <>
            {badgeDetailsState?.selectedBadge?.validity ? (
              <Styles>
                <DatePicker
                  onChange={(date: any) => handleDateChange(date, badgeData, "startDate")}
                  selected={badgeData.startDate}
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="top"
                  placeholderText="dd/mm/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </Styles>
            ) : (
              cellData("startDate", style, "NA")
            )}
          </>
        );
      }
    },
    {
      Header: "End Date",
      accessor: "endDate",
      width: 34,
      maxWidth: 34,
      disableSortBy: true,
      Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
        const style = {};
        return (
          <>
            {badgeDetailsState?.selectedBadge?.validity ? (
              <Styles>
                <DatePicker
                  minDate={checkStartDate(badgeData.startDate, 1)}
                  onChange={(date: any) => [handleDateChange(date, badgeData, "endDate")]}
                  selected={badgeData.endDate}
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="top"
                  placeholderText="dd/mm/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </Styles>
            ) : (
              cellData("endDate", style, "NA")
            )}
          </>
        );
      }
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      width: 100,
      maxWidth: 100,
      disableSortBy: true,
      Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
        return (
          <View>
            {badgeData.conflict === true ? (
              <View>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="profileName"
                  textSize={TextSize.Large}
                  style={{
                    fontSize: 12,
                    fontFamily: FontFamily.Regular,
                    // fontWeight: FontWeight.Light,
                    color: colorPallete.textBlack
                  }}
                >
                  {"Already assigned Badge from : "}
                  {dataHelper.formatDate(badgeData?.conflictDates[0]?.startDate)}
                  {" to "}
                  {dataHelper.formatDate(badgeData?.conflictDates[0]?.endDate)}
                  {/* {startDate <= badgeData?.conflictDates[0].endDate && badgeData?.conflictDates[0].startDate <= endDate ? setIsConflictPresent(true) : setIsConflictPresent(false)} */}
                </Text>
              </View>
            ) : (
              <Text
                fontWeight={FontWeight.Bold}
                testId="profileName"
                textSize={TextSize.Large}
                style={{
                  fontSize: 12,
                  fontFamily: FontFamily.Regular,
                  // fontWeight: FontWeight.Light
                  color: colorPallete.textBlack
                }}
              >
                {" None "}
              </Text>
            )}
          </View>
        );
      }
    },
    {
      Header: " ",
      width: 24,
      maxWidth: 24,
      disableSortBy: true,
      Cell: ({ row: { original: badgeData } }: { row: { original: any } }) => {
        return (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginRight: 16
            }}
          >
            <View>
              <TouchableOpacity onPress={() => removeSelectedMember(badgeData)}>
                <Image
                  testId="remove"
                  source={require("@socion-cordio/common/src/assets/images/remove.svg")}
                  imageStyle={{
                    width: 18,
                    height: 18
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
        );
      }
    }
  ];

  return (
    <View>
      <View style={styles.container}>
        <ToastContainer />
        <View style={styles.header}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="addRoleText"
            textSize={TextSize.Small}
            textStyle={styles.headerText}
          >
            Assign Badge
          </Text>
          <View>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossCircle}
              onPress={() => onClose()}
            />
          </View>
        </View>
        <View style={styles.subContainer}>
          {loading ? (
            <Loader customLoadingContainer={styles.badgesModalLoader} />
          ) : (
            <View style={styles.tableContainerHeight}>
              <ScrollContainer height="650px" scrollable={memberList?.length > 2 ? true : false}>
                <Table
                  columns={columns}
                  data={memberList}
                  showSearchField={false}
                  isPaginationReqdForTable={false}
                  showPaginationField={false}
                  showSaveBadgeButton={true}
                  saveSelectedBadgeDetailsHandler={saveSelectedBadgeDetailsHandler}
                  isConflictPresentInList={isConflictPresent}
                  showHeadersOnly={showHeadersOnly}
                  eventAnalytics={"BadgeMembers"}
                />
              </ScrollContainer>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 920
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 25,
    paddingBottom: 0
  },
  headerText: {
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  alignText: {
    color: colorPallete.textLight,
    marginTop: 10
  },
  textColor: {
    color: colorPallete.textBlack
  },
  crossCircle: {
    fontSize: 15
  },
  subContainer: {
    marginTop: 15
  },
  alignContainer: {
    alignItems: "center",
    marginTop: 25,
    marginBottom: 0
  },
  submitbutton: {
    width: "167px",
    height: "50px",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10
  },
  inputStylingAlignment: {
    width: "100%"
  },
  textHeaderField: {
    flex: 25
  },
  badgesModalLoader: {
    width: 920,
    height: 400
  },
  tableContainerHeight: {
    maxHeight: 470
  }
});
