import React, { ReactElement } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
// import Moment from "moment";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import Moment from "moment-timezone";

interface Props {
  onClose?: Function;
  sessionData: any;
  carouselHandler: any;
  selectedTimeZoneName: any;
}

export default function ViewQrCodeDetails(props: Props): ReactElement {
  const { onClose, sessionData, carouselHandler, selectedTimeZoneName } = props;

  const convertUtctoLocal = (date: any) => {
    if (selectedTimeZoneName) {
      let abbr = Moment.tz(selectedTimeZoneName).zoneAbbr();
      let date_modified = Moment(date).tz(selectedTimeZoneName)._d.toString().split("GMT")[0];
      let formatted_date = Moment(date_modified).format("D-MMM-YYYY hh:mm A") + " " + abbr;
      return formatted_date;
    } else {
      const timeZone = auxiliaryMethods.getTimeZone();
      const timeZoneString = timeZone.split(" ")[1].replace(/[{()}]/g, "");
      let datetime = new Date(Moment.utc(date));
      const newDate = Moment(datetime).format("D-MMM-YYYY hh:mm A");
      return `${newDate} ${timeZoneString}`;
    }
  };

  const dateConvertHandler = (date: string) => {
    const timeZone = auxiliaryMethods.getTimeZone();
    const timeZoneString = timeZone.split(" ")[1].replace(/[{()}]/g, "");
    const dateString = convertUtctoLocal(date);
    const newDate = Moment(dateString).format("D-MMM-YYYY hh:mm A");
    return dateString;
  };

  return (
    <>
      <View style={styles.header}>
        <View>
          <TouchableOpacity>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossIcon}
              onPress={() => onClose()}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.subContainer}>
        <View>
          <Image
            testId="sessionQR"
            source={
              carouselHandler.left === true ? sessionData.startQrImage : sessionData.endQrImage
            }
            imageStyle={styles.qrCodeStyle}
          />
        </View>
        <View style={styles.headerContainer}>
          <View style={styles.headerTextContainer}>
            <View>
              <Text
                fontWeight={FontWeight.Bold}
                testId="uidHeading"
                textSize={TextSize.Small}
                textStyle={styles.headerText}
              >
                {`UID : ${
                  carouselHandler.left === true ? sessionData.startUid : sessionData.endUid
                }`}
              </Text>
            </View>
            <View>
              {sessionData.sessionScanMode === "SINGLE_SCAN" ? (
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="heading"
                  textSize={TextSize.Small}
                  textStyle={[styles.headerText, styles.textAlign]}
                >
                  {"SINGLE QR"}
                </Text>
              ) : null}
            </View>
          </View>
          <View style={styles.datesContainer}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="sessionDates"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {`${
                sessionData.sessionScanMode === "SINGLE_SCAN"
                  ? `START DATE : ${dateConvertHandler(
                      sessionData?.sessionStartDate
                    )} \nEND DATE : ${dateConvertHandler(sessionData?.sessionEndDate)}`
                  : carouselHandler.left === true
                  ? `START QR (${dateConvertHandler(sessionData?.sessionStartDate)})`
                  : `END QR (${dateConvertHandler(sessionData?.sessionEndDate)})`
              }`}
            </Text>
            <Text
              fontWeight={FontWeight.Bold}
              testId="sessionName"
              textSize={TextSize.Small}
              textStyle={styles.headerText}
            >
              {sessionData?.sessionName}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17,
    marginHorizontal: 5,
    marginBottom: 3
  },
  textAlign: {
    marginLeft: 50
  },
  qrCodeStyle: {
    height: 300,
    width: 300
  },
  subContainer: {
    width: 388,
    alignItems: "center"
  },
  crossIcon: {
    fontSize: 15
  },
  headerContainer: {
    width: 300
  },
  headerTextContainer: {
    flexDirection: "row"
  },
  datesContainer: {
    backgroundColor: colorPallete.lightGreyThree,
    minHeight: 65
  }
});
