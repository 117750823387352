import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Table from "@socion-cordio/web/src/components/molecules/table/table";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { useTranslation } from "react-i18next";
import { auxiliaryMethods } from "@socion-cordio/common/src/utils/auxiliaryMethods";
import TableThird from "@socion-cordio/web/src/components/molecules/table/tableThird";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ViewUserDetails from "@socion-cordio/common/src/components/organisms/common-modals/viewUserDetailsModal";
import { participationDetailsEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import Tooltip from "react-tooltip-lite";

interface Props {
  sessionDetails?: any;
  getTableColumnsCount?: Function;
}

export default function ViewSessionOthersPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { sessionDetails, getTableColumnsCount } = props;
  const [loadingState, setLoadingState] = useState(true);
  const [failed, setFailed] = useState(false);
  const [sessionTrainerList, setSessionTrainerList] = useState(null);
  const [sessionTrainerData, setSessionTrainerData] = useState(null);
  const [viewButtonDetails, setViewButtonDetails] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [updatedColumns, setUpdatedColumns] = useState([]);

  useEffect(() => {
    if (sessionDetails !== null) {
      getSessionTrainerDetails();
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (viewButtonDetails) disableScroll();
    else enableScroll();
  }, [viewButtonDetails]);

  const getSessionTrainerDetails = async () => {
    let badgeDataList: any = [];
    let uniqueBadgeDataList;
    try {
      const sessionId = sessionDetails?.sessionId;
      const newBody = {
        sessionIds: [sessionId],
        userType: "ADMIN",
        sessionStatus: "UPCOMING"
      };
      const newBody1 = {
        sessionIds: [sessionId],
        userType: "OTHER",
        sessionStatus: "UPCOMING"
      };
      const response = await ApiClient.post(
        sessionPackEndPoints.getSessionParticipationList(),
        newBody
      );
      const response1 = await ApiClient.post(
        sessionPackEndPoints.getSessionParticipationList(),
        newBody1
      );
      if (
        response?.responseCode === HTTP_STATUS_CODES.ok &&
        response1?.responseCode === HTTP_STATUS_CODES.ok
      ) {
        setSessionTrainerData(response?.response);
        const list = response?.response[0]?.sessionData[sessionId];
        const list1 = response1?.response[0]?.sessionData[sessionId];
        let newList = list.concat(list1);
        // let uniqueList = [...new Map(newList.map((item: any) => [item["userId"], item])).values()];
        newList.forEach((session: any, index: number) => {
          session.serialNo = `${index + 1}.`;
          session.formattedLocation =
            auxiliaryMethods.fromatBaseLocation(session) !== ""
              ? auxiliaryMethods.fromatBaseLocation(session)
              : "None";
          session.verifiedRole =
            session?.roleDetails.length !== 0
              ? auxiliaryMethods.getVerifiedRole(session?.roleDetails)
              : "None";
          session.verifiedQualification =
            session?.qualificationTitle.length !== 0
              ? auxiliaryMethods.getVerifiedQualification(session?.qualificationTitle)
              : "None";
          session.assignedBadges =
            session?.badgeDetails.length !== 0
              ? auxiliaryMethods.getAssignedBadges(session?.badgeDetails)
              : "None";
          session?.badgeDetails.length !== 0
            ? session?.badgeDetails?.forEach((badge: any, index: number) => {
                session[badge.badgeType] = auxiliaryMethods.getAssignedBadges(
                  session?.badgeDetails
                );
                badgeDataList.push({
                  badgeType: badge.badgeType,
                  badgeTypeId: badge.badgeTypeId
                });
              })
            : session.push([]);
        });
        uniqueBadgeDataList = [
          ...new Map(badgeDataList.map((item: any) => [item["badgeTypeId"], item])).values()
        ];
        list.badgeData = uniqueBadgeDataList;
        formatHeaderData(list?.badgeData);
        setSessionTrainerList(newList);
        setLoadingState(false);
      }
    } catch (error) {
      setLoadingState(false);
      setFailed(true);
    }
  };

  const formatHeaderData = (data: any) => {
    let columnsList = [...columns];
    // columnsList.splice(5);
    data?.forEach((participationBadge: any, index: number) => {
      columnsList.push({
        Header: `${participationBadge.badgeType} Badge`,
        accessor: participationBadge.badgeType,
        width: 150,
        maxWidth: 150,
        Cell: ({ row: { original: topic } }: { row: { original: any } }) => {
          const style = {};
          const newBadgeData: any = null;
          const newbadgeList: any = [];
          topic.badgeDetails?.forEach((badge: any, index: number) => {
            if (topic.badgeDetails[index]?.badgeTypeId == participationBadge.badgeTypeId) {
              newBadgeData == topic.badgeDetails[index];
              newbadgeList.push(topic.badgeDetails[index]);
            }
          });
          const assignedBadges = auxiliaryMethods.getAssignedBadges(newbadgeList) || "None";
          return cellData("badgeDetails", style, assignedBadges);
        }
      });
    });
    getTableColumnsCount(columnsList?.length);
    setUpdatedColumns(columnsList);
    return columnsList;
  };

  const participantDetailsCSV = async (data: any) => {
    const response1 = await ApiClient.post(participationDetailsEndPoints.getParticipantListCSV(), {
      sessionIds: [sessionDetails.sessionId],
      userType: "ADMIN",
      sessionStatus: data.sessionStatus,
      timezone: auxiliaryMethods.getLocalTimezone()
    });
    const response2 = await ApiClient.post(participationDetailsEndPoints.getParticipantListCSV(), {
      sessionIds: [sessionDetails.sessionId],
      userType: "OTHER",
      sessionStatus: data.sessionStatus,
      timezone: auxiliaryMethods.getLocalTimezone()
    });
    if (response1.length > 173 && response2.length > 173) {
      let tempResponse = response2.split(",").slice(13);
      let temp = tempResponse[0].split("Attestation Received\r\n");
      tempResponse.shift();
      tempResponse.unshift(temp[1]);
      let result = tempResponse.toString();
      setLoadingState(false);
      return response1.concat(result);
    } else if (response1.length > 173) {
      setLoadingState(false);
      return response1;
    } else {
      setLoadingState(false);
      return response2;
    }
  };

  const downloadCsvList = async (data: any) => {
    setLoadingState(true);
    let result = await participantDetailsCSV({ sessionStatus: "UPCOMING" });
    auxiliaryMethods.handleDownload(
      result,
      `${sessionDetails.sessionName}-${sessionDetails.sessionId}-others`
    );
    auxiliaryMethods.updateTelemetryEvent("Download Participant List");
  };

  const displayUserDetails = (data: any) => {
    setViewButtonDetails(true);
    setSelectedUserDetails(data);
  };

  const cellData = (testId: string, style: any, data: string, onPress?: Function) => (
    <Text
      fontWeight={FontWeight.Bold}
      testId={testId}
      textSize={TextSize.Small}
      style={[
        style,
        {
          fontSize: 12,
          fontFamily: FontFamily.Regular,
          fontWeight: FontFamily.Light,
          textSize: TextSize.Small,
          color: colorPallete.textBlack
        }
      ]}
      onPress={onPress ? () => onPress() : null}
    >
      {data}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr.No",
        accessor: "serialNo",
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          return cellData("serialNo", style, session?.serialNo);
        }
      },
      {
        Header: "Member Name",
        accessor: "name",
        width: 135,
        maxWidth: 135,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          return (
            <Text
              fontWeight={FontWeight.Bold}
              testId="participantName"
              textSize={TextSize.Large}
              style={{
                fontSize: 12,
                fontFamily: FontFamily.Medium,
                color: colorPallete.textBlack
              }}
            >
              <TouchableOpacity onPress={() => displayUserDetails(session)}>
                {session?.name}
              </TouchableOpacity>
            </Text>
          );
        }
      },
      {
        Header: "Base Location",
        accessor: "formattedLocation",
        width: 135,
        maxWidth: 135,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          // return cellData("location", style, session?.formattedLocation);
          return (
            <>
              {auxiliaryMethods.getUserFirstLocationName(session) !== "None" ? (
                <Tooltip
                  styles={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: "inherit",
                    fontFamily: FontFamily.Regular,
                    fontSize: 12,
                    color: colorPallete.textBlack
                  }}
                  tipContentClassName=""
                  distance={10}
                  direction="bottom"
                  background={colorPallete.cordioBeigeLight1}
                  color="black"
                  padding={10}
                  content={
                    <View style={{ width: 250 }}>
                      <Text
                        testId="testId"
                        fontWeight={FontWeight.Regular}
                        textSize={TextSize.Small}
                        style={{
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }}
                      >
                        {session?.formattedLocation}
                      </Text>
                    </View>
                  }
                >
                  {auxiliaryMethods.getUserFirstLocationName(session)}
                </Tooltip>
              ) : (
                cellData("location", style, "None")
              )}
            </>
          );
        }
      },
      {
        Header: "Establishment Name",
        accessor: "establishmentName",
        width: 175,
        maxWidth: 175,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          const establishment = session?.establishmentName ? session?.establishmentName : "None";
          return cellData("establishment", style, establishment);
        }
      },
      {
        Header: "Verified Role",
        accessor: "verifiedRole",
        width: 125,
        maxWidth: 125,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          return cellData("phone", style, session?.verifiedRole);
        }
      },
      {
        Header: "Verified Qualification",
        accessor: "verifiedQualification",
        width: 175,
        maxWidth: 175,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          return cellData("email", style, session?.verifiedQualification);
        }
      },
      {
        Header: "Attestation Received",
        accessor: "attestations",
        width: 170,
        maxWidth: 170,
        Cell: ({ row: { original: session } }: { row: { original: any } }) => {
          const style = {};
          return cellData("email", style, session?.attestations);
        }
      }
    ],
    []
  );

  return (
    <View>
      {loadingState ? (
        <Loader />
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View>
          <TableThird
            columns={updatedColumns}
            data={sessionTrainerList}
            searchBarCustomStyle={{ width: 200 }}
            noData={"No users under this session for admin/others"}
            download={
              <TouchableOpacity onPress={() => downloadCsvList(sessionTrainerList)}>
                <View style={{ flexDirection: "row" }}>
                  <Icon
                    name={IconNames.downloadList}
                    customStyle={styles.iconStyle}
                    onPress={() => downloadCsvList(sessionTrainerList)}
                  />
                  <View>
                    <Text
                      testId="testId"
                      fontWeight={FontWeight.Bold}
                      textSize={TextSize.Small}
                      style={[
                        styles.textAlignment,
                        {
                          fontFamily: FontFamily.Regular,
                          fontSize: 12
                        }
                      ]}
                    >
                      {t("table:download")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            }
          />
        </View>
      )}

      {viewButtonDetails && (
        <View>
          <SocionModal
            modalVisible={viewButtonDetails}
            setModalVisible={() => setViewButtonDetails(!viewButtonDetails)}
            component={
              <ViewUserDetails
                onClose={() => {
                  setViewButtonDetails(false);
                }}
                selectedUserDetails={selectedUserDetails}
                piiFilter={false}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  roleName: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25 / 2,
    marginEnd: 10
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  active: {
    backgroundColor: colorPallete.cordioGreen
  },
  pending: {
    backgroundColor: colorPallete.cordioOrange
  },
  deactivated: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  textAlign: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    fontSize: 10
  },
  statusContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  statusColor: {
    height: 8,
    width: 8,
    borderRadius: 50,
    marginRight: 8
  },
  approvedColor: {
    backgroundColor: colorPallete.cordioGreen
  },
  pendingColor: {
    backgroundColor: colorPallete.cordioOrange
  },
  declineColor: {
    backgroundColor: colorPallete.cordioRedDark3
  },
  iconStyle: {
    color: colorPallete.textBlack,
    fontSize: 17,
    marginRight: 10
  },
  textAlignment: {
    textDecorationLine: "underline"
  }
});
