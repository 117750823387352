import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, TouchableWithoutFeedback, useWindowDimensions, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import SessionSideBarPanelPage from "@socion-cordio/common/src/components/organisms/session/sessionSideBarPanelPage";
import AdditionalLinksPage from "@socion-cordio/common/src/components/organisms/session/additionalLinksPage";
import AddMembersPage from "@socion-cordio/common/src/components/organisms/session/addMembersPage";
import ViewContentPage from "@socion-cordio/common/src/components/organisms/session/viewContentPage";
import { useLocation, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useDispatch, useSelector } from "react-redux";
import { SessionsActions } from "@socion-cordio/common/src/modules/session/actions/actions";
import SessionParticipantPage from "@socion-cordio/common/src/components/organisms/session/sessionParticipantPage";
import { useTranslation } from "react-i18next";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import Tasks from "@socion-cordio/common/src/components/organisms/session/tasks";
import SideBarPanelSession from "@socion-cordio/common/src/components/organisms/session/side-bar-panel/sideBarPanelSession";
import styled from "styled-components";

const Styles = styled.div`
  width: 100%;
`;
interface Props {
  onSubmit?: (data: { otp: number }) => void;
}

export default function ViewSessionPage(props: Props): ReactElement {
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const [sessionDetails, setsessionDetails] = useState(null);
  const [failed, setFailed] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [sessionLinks, setSessionLinks] = useState(null);
  const [programData, setProgramData] = useState(undefined);
  const [sessionMembers, setSessionMembers] = useState(null);
  const [sessionDataResponse, setSessionDataResponse] = useState(null);
  const [isSessionCompleted, setIsSessionCompleted] = useState(false);
  const [currentWindowData, setCurrentWindowData] = useState(null);
  const [isLiveSession, setIsLiveSession] = useState<boolean>(false);
  const [isCompletedSession, setIsCompletedSession] = useState<boolean>(false);
  const [isMember, setIsMember] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
  const [participationList, setParticipationList] = useState(null);
  const [leftSidePanelCollapse, setLeftSidePanelCollapse] = useState(false);
  const [isLoadingSessionParticipation, setLoadingSessionParticipation] = useState(true);
  const [tableColumnsList, setTableColumnsList] = useState(0);
  const dispatch = useDispatch();
  const history: any = useHistory();
  const location: any = useLocation();

  const {
    completeSessionData: sessionData,
    loading: { getCompleteSession: loading }
  } = useSelector((state: any) => state?.completeSessionData);

  useEffect(() => {
    collapseHandler();
  });

  const collapseHandler = async () => {
    let isSidePanelCollapsed: boolean = await LocalStorage.getStorage("isSidePanelCollapsed");
    setLeftSidePanelCollapse(isSidePanelCollapsed);
  };

  useEffect(() => {
    getCompleteSessionDetails();
    getPageUrl();
    getProgramDetails();
  }, []);

  useEffect(() => {
    setCurrentWindowData(location);
  }, [location]);

  useEffect(() => {
    procsessSessionData();
  }, [sessionData?.response?.sessionLinks]);

  useEffect(() => {
    checkIsMember(sessionMembers);
  }, [sessionMembers]);

  const getProgramDetails = async () => {
    const programDetails: any = await LocalStorage.getStorage("programDetails");
    setProgramData(programDetails);
  };

  const formatStringHandler = (str: string) => {
    if (str?.includes("%20")) {
      return str?.replaceAll("%20", " ").split("=")[1];
    }
    return str?.split("=")[1];
  };

  useEffect(() => {
    checkRouteUrl();
  }, [location.pathname]);

  const checkIsMember = async (data: any) => {
    const user: any = await LocalStorage.getStorage("user");
    const userId = user?.userId;
    let dataArray: any = [];
    data?.map((i: any) => {
      dataArray.push(i.userId);
    });
    if (dataArray.includes(userId)) {
      setIsMember(true);
    } else setIsMember(false);
  };

  const getPageUrl = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("completed")) {
      setIsSessionCompleted(true);
    } else {
      setIsSessionCompleted(false);
    }
  };

  const getCompleteSessionDetails = async () => {
    try {
      const payload = {
        sessionId: location?.state?.sessionData?.session?.id
      };
      setSessionId(location?.state?.sessionData?.session?.id);
      dispatch(SessionsActions.getCompleteSession(payload));
    } catch (error) {
      toast.error("Something went wrong.");
      setFailed(true);
    }
  };

  const procsessSessionData = () => {
    if (sessionData?.responseCode === 200) {
      setSessionLinks(sessionData?.response?.sessionLinks);
      setSessionMembers(sessionData?.response?.members);
      setSessionDataResponse(sessionData?.response);
    }
  };

  const navigateHandler = () => {
    if (window.location.href.includes("completed")) {
      history.push(`${routeNames.app}${routeNames.SESSION}`, {
        locationData: currentWindowData
      });
    } else if (window.location.href.includes("upcoming")) {
      history.push(`${routeNames.app}${routeNames.SESSION}`, {
        locationData: currentWindowData
      });
    } else {
      if (currentWindowData.state.completed === false) {
        history.push(`${routeNames.app}${routeNames.SESSION}`, {
          locationData: currentWindowData
        });
      } else if (
        currentWindowData.state.completed === true &&
        currentWindowData.state.redirectionFrom === "CompletedSession"
      ) {
        history.push(`${routeNames.app}${routeNames.SESSION}`, {
          locationData: { pathname: "completed" }
        });
      } else {
        history.goBack();
        setCurrentWindowData(null);
      }
    }
  };

  const checkRouteUrl = () => {
    if (location.pathname.includes("live")) {
      setIsLiveSession(true);
      setIsCompletedSession(false);
    }
    if (location.pathname.includes("upcoming")) {
      setIsLiveSession(false);
      setIsCompletedSession(false);
    }
    if (location.pathname.includes("completed")) {
      setIsLiveSession(false);
      setIsCompletedSession(true);
    }
  };

  const menuCollapseHandler = (value: boolean) => {
    //  true is close and false is open
    setIsMenuCollapsed(value);
  };

  const getSessionParticipationList = (value: []) => {
    // Checks participation list length and sets it
    setParticipationList(value.length);
  };

  const getTableColumnsCount = (value: number) => {
    setTableColumnsList(value);
  };

  return (
    <View nativeID={"mainContainerViewSession"} style={styles.mainContainer}>
      {loading || isLoader ? (
        <View style={styles.loadingCOntainer}>
          <View style={styles.subContainer}>
            <Loader />
          </View>
        </View>
      ) : failed ? (
        <View>Failed to load data</View>
      ) : (
        <View nativeID={"subContainerViewSession"} style={styles.container}>
          <TouchableWithoutFeedback onPress={() => setIsMenuCollapsed(false)}>
            <View style={styles.dummyContainer}></View>
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback onPress={() => setIsMenuCollapsed(false)}>
            <Styles>
              <View style={isLiveSession ? styles.rightContainer2 : styles.rightContainer}>
                <View
                  style={
                    isLiveSession
                      ? participationList !== null && participationList === 0
                        ? styles.subContainer
                        : [
                            styles.subContainer,
                            { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
                          ]
                      : styles.subContainer
                  }
                >
                  <View style={styles.subContainerOne}>
                    <View style={styles.headerContainer}>
                      <View style={styles.iconContainerHeader}>
                        <Icon
                          testID="down"
                          name={IconNames.down}
                          customStyle={styles.titleIcon}
                          onPress={() => {
                            navigateHandler();
                          }}
                        />
                      </View>
                      <View style={{ maxWidth: "85%" }}>
                        <Text
                          fontWeight={FontWeight.Light}
                          testId="text"
                          textSize={TextSize.Small}
                          textStyle={[
                            styles.headerText,
                            styles.headerTextTitleSupport,
                            {
                              flex: 1,
                              overflow: "hidden"
                            }
                          ]}
                        >
                          {sessionDataResponse?.sessionName}
                        </Text>
                      </View>
                      <View style={styles.viewSessionDetailsContainer}>
                        <Text
                          onPress={() => {}}
                          fontWeight={FontWeight.Regular}
                          testId="text"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, { marginLeft: 10, marginRight: 0 }]}
                        >
                          {">"}
                        </Text>
                        <Text
                          onPress={() => {}}
                          fontWeight={FontWeight.Regular}
                          testId="text"
                          textSize={TextSize.Small}
                          textStyle={[styles.headerText, styles.headerTextSupport]}
                        >
                          {t("session:viewSessionDetails")}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <SessionParticipantPage
                        sessionDetails={sessionDataResponse}
                        getSessionParticipationList={getSessionParticipationList}
                        setLoadingSessionParticipation={setLoadingSessionParticipation}
                        isLoadingSessionParticipation={isLoadingSessionParticipation}
                        getTableColumnsCount={getTableColumnsCount}
                      />
                    </View>
                  </View>
                </View>

                {isLiveSession ? null : (
                  <View style={styles.containerCollapse}>
                    <View style={styles.collapseContainer}>
                      <AdditionalLinksPage
                        sessionLinks={sessionLinks}
                        sessionId={sessionId}
                        sessionDetails={sessionDataResponse}
                        isSessionCompleted={isSessionCompleted}
                      />
                    </View>
                    {sessionDataResponse?.topicInfo?.task !== null &&
                      sessionDataResponse?.topicInfo?.task?.status === "ACTIVE" && (
                        <View style={styles.collapseContainer}>
                          <Tasks />
                        </View>
                      )}
                    <View
                      style={
                        width > 960
                          ? styles.memberViewCollapseContainer
                          : { flexDirection: "column" }
                      }
                    >
                      <View style={[styles.collapseContainer, { flex: 1, marginRight: 5 }]}>
                        <AddMembersPage
                          sessionDetails={sessionData?.response}
                          sessionMembers={sessionMembers}
                          sessionId={sessionId}
                          isSessionCompleted={isSessionCompleted}
                          getCompleteSessionDetails={getCompleteSessionDetails}
                          setIsLoader={setIsLoader}
                        />
                      </View>

                      <View style={[styles.collapseContainer, { flex: 1, marginLeft: 5 }]}>
                        <ViewContentPage
                          sessionDetails={sessionData?.response}
                          isSessionCompleted={isSessionCompleted}
                        />
                      </View>
                    </View>
                  </View>
                )}
              </View>
            </Styles>
          </TouchableWithoutFeedback>
          {/* <View style={styles.leftContainer}>
            <View style={{ marginTop: 60, minWidth: "100%" }}> */}
          {isLiveSession ? (
            <View style={[styles.leftContainer, { zIndex: 999, minHeight: "70vh" }]}>
              <View style={{ marginTop: 60 }}>
                <SideBarPanelSession
                  sessionData={sessionData?.response}
                  sessionId={sessionId}
                  isSessionCompleted={isSessionCompleted}
                  isMember={isMember}
                  getCompleteSessionDetails={getCompleteSessionDetails}
                  isLiveSession={isLiveSession}
                  isCompletedSession={isCompletedSession}
                  // <----For session scan details---->
                  sessionLinks={sessionLinks}
                  sessionDetails={sessionDataResponse}
                  // <----For members list---->
                  sessionMembers={sessionMembers}
                  setIsLoader={setIsLoader}
                  menuCollapseHandler={menuCollapseHandler}
                  isMenuCollapsed={isMenuCollapsed}
                />
              </View>
            </View>
          ) : (
            <View style={styles.leftContainer}>
              <View style={{ marginTop: 60, minWidth: "100%" }}>
                <SessionSideBarPanelPage
                  sessionData={sessionData?.response}
                  sessionId={sessionId}
                  isSessionCompleted={isSessionCompleted}
                  isMember={isMember}
                  getCompleteSessionDetails={getCompleteSessionDetails}
                  isLiveSession={isLiveSession}
                  isCompletedSession={isCompletedSession}
                />
              </View>
            </View>
          )}
          {/* </View>
          </View> */}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    height: "100%"
  },
  container: {
    flexDirection: "row",
    // position: "absolute",
    width: "100%",
    height: "100%"
  },
  dummyContainer: {
    height: 60,
    // backgroundColor: "red",
    position: "absolute",
    zIndex: 99999,
    width: "calc(100% - 700px)",
    left: 360,
    top: -45
  },
  rightContainer: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    backgroundColor: colorPallete.cordioBeigeLight2,
    // width: "calc(100% - 240px)"
    width: "100%"
  },
  rightContainer2: {
    display: "flex",
    padding: 20,
    paddingTop: 15,
    position: "absolute",
    backgroundColor: colorPallete.cordioBeigeLight2,
    width: "calc(100% - 60px)" // previous (79) 60 because to handle Table columns
  },
  leftContainer: {
    marginTop: -50,
    minHeight: "100vh",
    backgroundColor: colorPallete.cordioRedDark2,
    //new
    position: "relative",
    top: 0,
    right: 0
  },
  loadingCOntainer: {
    margin: 20,
    marginTop: 15
  },
  header: {
    display: "flex",
    flexDirection: "row"
  },
  iconContainer: {
    marginTop: 10
  },
  titleIcon: {
    color: colorPallete.textBlack,
    fontSize: 9,
    transform: [{ rotate: "90deg" }]
  },
  headerText: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    shadowColor: colorPallete.cordioRedDark1,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 10,
      width: 5
    },
    shadowRadius: 10
  },
  containerStyle: {
    width: "30%",
    marginLeft: 15
  },
  dropDownContainerStyle: {
    borderWidth: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  subContainerOne: {},

  //Header Container
  headerTextTitleSupport: {
    fontWeight: "700"
  },
  headerContainer: {
    flexDirection: "row",
    // marginBottom: 20,
    alignItems: "center",
    padding: 18,
    paddingBottom: 0
  },
  headerTextSupport: {
    // Due to font size, increasing the size.
    fontFamily: FontFamily.Regular,
    marginLeft: 10
  },
  iconContainerHeader: {
    marginRight: 20
  },

  // collapse Container
  containerCollapse: {
    marginTop: 30,
    flexDirection: "column"
  },
  memberViewCollapseContainer: {
    flexDirection: "row"
  },
  collapseContainer: {
    marginBottom: 20
  },
  viewSessionDetailsContainer: { flexDirection: "row", flex: 1 }
});
