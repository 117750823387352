import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, Pressable, ScrollView } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import TextField from "@socion-cordio/common/src/components/atoms/textField";

interface Props {
  title?: string;
  onClose?: Function;
  testId: string;
  label: string;
  handleChange: Function;
  value: string;
  placeholder?: string;
  name: string;
  id: string;
  handleSubmit: Function;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  serverError?: string;
  cancelPress?: Function;
  isCancelVisible?: Boolean;
  locationArray?: any;
  renderLocationSugetion?: Function;
  handleBlur?: Function;
}

function elevationShadowStyle(elevation: number) {
  return {
    elevation,
    shadowColor: "black",
    shadowOffset: { width: 3.5, height: 0.55 * elevation },
    shadowOpacity: 0.2,
    shadowRadius: 0.5 * elevation
  };
}

export default function EmailInput(props: Props): ReactElement {
  const {
    title,
    onClose,
    testId,
    label,
    handleChange,
    value,
    placeholder,
    name,
    id,
    handleSubmit,
    disabled,
    error,
    errorMessage,
    serverError,
    cancelPress,
    isCancelVisible,
    locationArray,
    renderLocationSugetion
  } = props;

  return (
    <View>
      <View style={styles.modalHeader}>
        <Text
          fontWeight={FontWeight.Bold}
          testId="addEmailText"
          textSize={TextSize.Small}
          textStyle={styles.headerText}
        >
          {title}
        </Text>
        <View>
          <Pressable onPress={() => onClose()}>
            <Icon testID="close" name={IconNames.crossCircle} />
          </Pressable>
        </View>
      </View>
      <View style={styles.searchContainer}>
        <View style={styles.emailInputContainer}>
          <View>
            <View style={styles.searchBarContainer}>
              <Icon name={IconNames.search} customStyle={[styles.iconStyleSearch]} />
              <TextField
                label=""
                handleChange={(value: any) => handleChange(value)}
                handleBlur={() => {
                  if (props.handleBlur) props.handleBlur();
                }}
                value={value}
                inputStyle={styles.inputRole}
                placeholder={placeholder}
                name={name}
                id={id}
                noFormik
              />
              {isCancelVisible && (
                <View style={styles.buttonContainer}>
                  <View style={styles.iconContainer}>
                    <Icon
                      name={IconNames.close}
                      customStyle={styles.iconStyle}
                      onPress={() => cancelPress()}
                    />
                  </View>
                  <View style={styles.iconContainer}>
                    <Icon
                      name={IconNames.tick}
                      customStyle={styles.iconStyle}
                      onPress={() => handleSubmit()}
                    />
                  </View>
                </View>
              )}
            </View>

            <Text
              fontWeight={FontWeight.Regular}
              testId="text"
              textSize={TextSize.Small}
              textStyle={[styles.alignText, styles.fontSize11, styles.alignTextSecondary]}
            >
              If the above is not accurate, please type in location of choice
            </Text>

            {!locationArray || locationArray?.length === 0 ? null : (
              <View style={[styles.searchBoxShadow, styles.box]}>
                <ScrollView
                  style={{
                    height: 110
                  }}
                  contentContainerStyle={{ flexGrow: 1 }}
                >
                  <View style={{ padding: 5, paddingBottom: 0, width: 630 }}>
                    {locationArray?.map(renderLocationSugetion)}
                  </View>
                  <View style={styles.alignGoogleText}>
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="signup"
                      textSize={TextSize.Small}
                      style={styles.credits}
                    >
                      {"powered by Google"}
                    </Text>
                  </View>
                </ScrollView>
              </View>
            )}

            {error && (
              <Text
                testId="emailError"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                {errorMessage}
              </Text>
            )}
            {!error && serverError && (
              <Text
                testId="incorrectEmail"
                textSize={TextSize.Small}
                fontWeight={FontWeight.Regular}
                textStyle={styles.error}
              >
                {serverError}
              </Text>
            )}
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.footerConsent}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="text"
            textSize={TextSize.Small}
            textStyle={[
              styles.alignText,
              styles.fontSize11
              // styles.flexLabel
            ]}
          >
            I consent to share my base location with the program(s) I am associated with.
          </Text>
        </View>
        <View style={[styles.submitButtonContainer, styles.alignSubmitButton]}>
          {/* <Button
            type={ButtonType.Secondary}
            buttonStyles={styles.submitbutton}
            title="Cancel"
            onPress={() => onClose()}
          /> */}
          <Button
            type={ButtonType.Primary}
            buttonStyles={styles.submitbutton}
            title="Save"
            onPress={() => handleSubmit()}
            disabled={disabled}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  headerText: {
    fontWeight: "700",
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 17
  },
  subheaderText: {
    fontWeight: "400",
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    lineHeight: 17,
    color: colorPallete.textBlack,
    marginTop: 13,
    flex: 20
  },
  submitButtonContainer: {
    alignItems: "center"
  },
  alignSubmitButton: {
    flexDirection: "row",
    zIndex: -1,
    flex: 50,
    justifyContent: "flex-end"
  },
  submitbutton: {
    width: 140,
    height: 50,
    borderRadius: 10,
    marginLeft: 20
  },
  buttonContainer: {
    flexDirection: "row"
  },
  searchContainer: {
    height: 190
  },
  emailInputContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center"
  },
  subHeaderContainer: {
    marginRight: 15
  },
  inputRole: {
    height: 40,
    outline: "none",
    borderRadius: 5,
    padding: 7,
    fontSize: 14,
    fontFamily: FontFamily.Regular,
    fontWeight: "400",
    width: 655,
    border: "none",
    textSize: TextSize.Small,
    paddingLeft: 50
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
  },
  iconStyle: {},
  iconContainer: {
    justifyContent: "center",
    marginLeft: 10
  },
  credits: {
    color: colorPallete.gray,
    textAlign: "right"
  },
  alignGoogleText: {
    marginRight: 10,
    marginBottom: 10
  },
  searchBoxShadow: {
    ...elevationShadowStyle(5),
    backgroundColor: colorPallete.white
  },
  box: {
    borderWidth: 0.5,
    borderColor: colorPallete.gray,
    marginTop: 0
  },

  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight4,
    borderRadius: 5
  },
  iconStyleSearch: {
    marginLeft: 15,
    marginRight: 5,
    opacity: 0.5,
    position: "absolute",
    top: -10
  },
  footer: {
    flexDirection: "row",
    flex: 1
  },
  footerConsent: {
    flex: 50,
    alignSelf: "center"
  },
  alignText: {
    fontFamily: FontFamily.Regular,
    color: colorPallete.textBlack,
    lineHeight: 13,
    width: "85%",
    fontWeight: "300"
  },
  alignTextSecondary: {
    position: "absolute",
    fontFamily: FontFamily.Regular,
    marginTop: 50,
    left: 1,
    lineHeight: 13,
    zIndex: -1
  },
  fontSize11: {
    fontSize: 11
  }
});
